/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import searchDeploymentsQuery from '@/components/StackDeploymentSearch/searchDeploymentsQuery'
import { getProfile } from '@/apps/userconsole/reducers'
import { getConfigForKey } from '@/selectors'
import { getStackDeploymentsFromSearch } from '@/reducers'
import { searchDeployments } from '@/actions/stackDeployments'
import hasResourcePermissions from '@/lib/rbacPermissions'

import OrganizationMembers from './OrganizationMembers'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { ConsumerProps, StateProps, DispatchProps } from './types'

const queryId = 'organization-members-deployments'

const mapStateToProps = (state: ReduxState, { organizationId }: ConsumerProps): StateProps => {
  const profile = getProfile(state)

  const { hasPermissions: hasSearchDeploymentsPermission } = hasResourcePermissions(state, [
    {
      organizationId,
      type: 'deployment',
      action: 'search',
    },
  ])

  const isAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`

  return {
    profile,
    permissions: {
      hasSearchDeploymentsPermission,
    },
    deployments: getStackDeploymentsFromSearch(state, queryId)?.deployments,
    isAdminconsole,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchOrganizationDeployments: () =>
    dispatch(
      searchDeployments({
        queryId,
        query: searchDeploymentsQuery(),
      }),
    ),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationMembers)
