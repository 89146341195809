/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { union } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { RegionInfo } from '@modules/cloud-api/v1/types'
import type { Runner } from '@modules/ui-types'

import { hostAllocatorUrl, hostProxyUrl, hostControlPlaneUrl } from './urlBuilder'

import type { ReactElement } from 'react'

type NamedRoles = {
  [key: string]: NamedRole
}

export type NamedRole = {
  id: string
  label: ReactElement
  description: ReactElement
  getUrl: (regionId: string, hostId: string) => string
}

type ObjectWithRoleId = {
  id: string
}

const builtInRoleIds = ['allocator', 'proxy']
const controlPlaneRoleIds = ['coordinator', 'director', 'constructor']

const namedRoles: NamedRoles = {
  allocator: {
    id: 'allocator',
    label: <FormattedMessage id='roles-form.allocator-role' defaultMessage='Allocator' />,
    description: (
      <FormattedMessage
        id='roles-form.allocator-role-description'
        defaultMessage='Provides computing resources to Elastic Stack deployments.'
      />
    ),
    getUrl: hostAllocatorUrl,
  },
  proxy: {
    id: 'proxy',
    label: <FormattedMessage id='roles-form.proxy-role' defaultMessage='Proxy' />,
    description: (
      <FormattedMessage
        id='roles-form.proxy-role-description'
        defaultMessage='Routes user requests to instances in Elastic Stack deployments.'
      />
    ),
    getUrl: hostProxyUrl,
  },
  coordinator: {
    id: 'coordinator',
    label: <FormattedMessage id='roles-form.controller-role' defaultMessage='Controller' />,
    description: (
      <FormattedMessage
        id='roles-form.controller-role-description'
        defaultMessage='Serves as a distributed coordination system and resource scheduler.'
      />
    ),
    getUrl: hostControlPlaneUrl,
  },
  director: {
    id: 'director',
    label: <FormattedMessage id='roles-form.director-role' defaultMessage='Director' />,
    description: (
      <FormattedMessage
        id='roles-form.director-role-description'
        defaultMessage='Directors manage the ZooKeeper datastore in the region. This role is typically shared with the controller role, but they can be separated for production deployments.'
      />
    ),
    getUrl: hostControlPlaneUrl,
  },
  constructor: {
    id: 'constructor',
    label: <FormattedMessage id='roles-form.constructor-role' defaultMessage='Constructor' />,
    description: (
      <FormattedMessage
        id='roles-form.constructor-role-description'
        defaultMessage='Constructors apply deployment configuration changes.'
      />
    ),
    getUrl: hostControlPlaneUrl,
  },
}

export function getNamedRole(roleId: string): NamedRole | null {
  if (!namedRoles.hasOwnProperty(roleId)) {
    return null
  }

  const namedRole = namedRoles[roleId]
  return namedRole
}

export function getNonControlPlaneBuiltInRoles<TRole extends ObjectWithRoleId>({
  roles,
}: {
  roles: TRole[]
}): TRole[] {
  const builtInRoles = roles.filter((role) => builtInRoleIds.includes(role.id))
  const sortedBuiltInRoles = builtInRoles.sort((a, b) =>
    builtInRoleIds.indexOf(a.id) > builtInRoleIds.indexOf(b.id) ? 1 : -1,
  )
  return sortedBuiltInRoles
}

export function getControlPlaneRoles<TRole extends ObjectWithRoleId>({
  roles,
}: {
  roles: TRole[]
}): TRole[] {
  const controlPlaneRoles = roles.filter((role) => controlPlaneRoleIds.includes(role.id))
  const sortedControlPlaneRoles = controlPlaneRoles.sort((a, b) =>
    controlPlaneRoleIds.indexOf(a.id) > controlPlaneRoleIds.indexOf(b.id) ? 1 : -1,
  )
  return sortedControlPlaneRoles
}

export function getCustomRoles<TRole extends ObjectWithRoleId>({
  roles,
}: {
  roles: TRole[]
}): TRole[] {
  const customRoles = roles.filter(
    (role) => !builtInRoleIds.includes(role.id) && !controlPlaneRoleIds.includes(role.id),
  )
  const sortedCustomRoles = customRoles.sort((a, b) => a.id.localeCompare(b.id))
  return sortedCustomRoles
}

export function hasAllocatorRole({ runner }: { runner?: Runner }): boolean {
  if (!runner) {
    return false
  }

  return runner.roles.some(
    (role) => role.role_name === 'allocator' || role.role_name.startsWith('allocator-'),
  )
}

export function hasProxyRole({ runner }: { runner?: Runner }): boolean {
  if (!runner) {
    return false
  }

  return runner.roles.some((role) => role.role_name === 'proxy')
}

export function hasControlPlaneRole({ runner }: { runner?: Runner }): boolean {
  if (!runner) {
    return false
  }

  return runner.roles.some((role) => controlPlaneRoleIds.includes(role.role_name))
}

export function countControlPlanes(regionInfo: RegionInfo) {
  const constructors = regionInfo.constructors.constructors || []
  const coordinators = regionInfo.coordinators.coordinators || []

  const happyConstructorIds = constructors
    .filter((constructor) => constructor.status.connected)
    .map((constructor) => constructor.constructor_id)

  const sadConstructorIds = constructors
    .filter((constructor) => !constructor.status.connected)
    .map((constructor) => constructor.constructor_id)

  const happyCoordinatorIds = coordinators.map((coordinator) => coordinator.name)
  const sadCoordinatorIds = []

  return {
    happy: union(happyConstructorIds, happyCoordinatorIds).length,
    sad: union(sadConstructorIds, sadCoordinatorIds).length,
  }
}

export function isDirectorHost({ runner }: { runner?: Runner }): boolean {
  if (!runner) {
    return false
  }

  const director = runner.roles.some((role) => role.role_name === `director`)

  return director
}
