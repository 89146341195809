/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { fetchSaasUser, setUserSearchTerm } from '@/actions/saasUsers'
import { fetchSaasUserRequest } from '@/reducers'
import { getUserSearchTerm } from '@/apps/adminconsole/reducers/searchUsers'

import SearchByUserId from './SearchByUserId'

import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (state: ReduxState): StateProps => ({
  fetchSaasUserRequest: (userId: string) => fetchSaasUserRequest(state, userId),
  searchId: getUserSearchTerm(state.searchUsers),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchSaasUser: (userId: string) => dispatch(fetchSaasUser(userId)),
  setSearchId: (searchId: string) => dispatch(setUserSearchTerm(searchId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withTransaction(`Users`, `search-by-user-id`)(SearchByUserId))
