/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

export default function OrganizationDomain({ domain }) {
  switch (domain) {
    case `aws`:
      return <FormattedMessage id='user-domain.aws' defaultMessage='AWS Marketplace' />

    case `heroku`:
      return <FormattedMessage id='user-domain.heroku' defaultMessage='Heroku' />

    case `found`:
      return <FormattedMessage id='user-domain.found' defaultMessage='Elastic Cloud' />

    case `azure`:
      return <FormattedMessage id='user-domain.azure' defaultMessage='Azure' />

    case `gcp`:
      return <FormattedMessage id='user-domain.gcp' defaultMessage='GCP' />

    default:
      return <span>{domain}</span>
  }
}
