/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiSelect } from '@elastic/eui'
import type { EuiSelectOption } from '@elastic/eui'

import type { WrappedComponentProps } from 'react-intl'

const messages = defineMessages({
  mins: {
    id: 'topologyElement-forecastWindow-minute',
    defaultMessage: '{amount} {amount, plural, one {minute} other {minutes}}',
  },
  hours: {
    id: 'topologyElement-forecastWindow-hour',
    defaultMessage: '{amount} {amount, plural, one {hour} other {hours}}',
  },
  days: {
    id: 'topologyElement-forecastWindow-day',
    defaultMessage: '{amount} {amount, plural, one {day} other {days}}',
  },
})

const SUPPORTED_FORECAST_WINDOWS = ['30 m', '1 h', '2 h', '3 h', '6 h', '12 h', '1 d']

export type Props = WrappedComponentProps & {
  forecastWindow: typeof SUPPORTED_FORECAST_WINDOWS[number] | undefined
  onChange: undefined | ((value: string) => void)
  disabled?: boolean
}

const ForecastWindow: React.FunctionComponent<Props> = ({
  intl: { formatMessage },
  forecastWindow,
  onChange,
  disabled,
}) => {
  const timeIntervals: EuiSelectOption[] = [
    { value: `30 m`, text: formatMessage(messages.mins, { amount: 30 }) },
    { value: `1 h`, text: formatMessage(messages.hours, { amount: 1 }) },
    { value: `2 h`, text: formatMessage(messages.hours, { amount: 2 }) },
    { value: `3 h`, text: formatMessage(messages.hours, { amount: 3 }) },
    { value: `6 h`, text: formatMessage(messages.hours, { amount: 6 }) },
    { value: `12 h`, text: formatMessage(messages.hours, { amount: 12 }) },
    { value: `1 d`, text: formatMessage(messages.days, { amount: 1 }) },
  ]

  // allow preservation of any non-standard value that may have been placed by an API call
  if (forecastWindow && !SUPPORTED_FORECAST_WINDOWS.includes(forecastWindow as string)) {
    timeIntervals.unshift({ value: forecastWindow, text: `` })
  }

  return (
    <EuiSelect
      data-test-subj='topologyElement-forecastWindow'
      options={timeIntervals}
      value={forecastWindow}
      disabled={disabled}
      onChange={(e) => onChange?.(e.target.value)}
    />
  )
}

export default injectIntl(ForecastWindow)
