/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { TrafficFilterRulesetRequest } from '@modules/cloud-api/v1/types'

const typeByProvider = {
  aws: 'vpce',
  azure: 'azure_private_endpoint',
  gcp: 'gcp_private_service_connect_endpoint',
}

export function isPrivateLinkRuleset(ruleset: TrafficFilterRulesetRequest): boolean {
  return Object.values(typeByProvider).includes(ruleset.type)
}

export function getPrivateLinkType(provider: string): string | undefined {
  return typeByProvider[provider]
}
