/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, values } from 'lodash'

import {
  EuiButton,
  EuiCode,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { AsyncRequestState, RegionId, SnapshotRepository } from '@modules/ui-types'
import { CuiAlert, CuiTable, CuiPermissibleControl, CuiRouterLinkButton } from '@modules/cui'
import history from '@modules/utils/history'
import Header from '@modules/cui/Header'

import DocLink from '../DocLink'
import { createSnapshotRepositoryUrl, editSnapshotRepositoryUrl } from '../../lib/urlBuilder'
import { snapshotRepositoriesCrumbs } from '../../lib/crumbBuilder'

import DeleteSnapshotRepositoryButton from './DeleteSnapshotRepositoryButton'

import type { FunctionComponent } from 'react'

import './snapshotRepositories.scss'

type Props = {
  regionId: RegionId
  snapshotRepositories: SnapshotRepository[]
  fetchSnapshotRepositoriesRequest: AsyncRequestState
  fetchSnapshotRepositories: (regionId: string) => void
}

type AddButtonProps = {
  regionId: RegionId
}

const AddButton: FunctionComponent<AddButtonProps> = ({ regionId }) => (
  <CuiPermissibleControl permissions={Permission.setSnapshotRepository}>
    <EuiButton
      data-test-id='snapshotRepositories-addBtn'
      onClick={() => history.push(createSnapshotRepositoryUrl(regionId))}
      fill={true}
    >
      <FormattedMessage
        id='snapshot-repository-management.add-repository'
        defaultMessage='Add repository'
      />
    </EuiButton>
  </CuiPermissibleControl>
)

class SnapshotRepositoryManagement extends Component<Props> {
  render() {
    const { regionId, snapshotRepositories } = this.props
    const snapshotsLoadedButEmpty = snapshotRepositories != null && !isEmpty(snapshotRepositories)

    return (
      <Fragment>
        <Header
          name={
            <FormattedMessage
              id='snapshot-repository-management.repositories'
              defaultMessage='Repositories'
            />
          }
          breadcrumbs={snapshotRepositoriesCrumbs({ regionId })}
        />

        <EuiFlexGroup gutterSize='m'>
          {snapshotRepositories && values(snapshotRepositories).length > 0 && (
            <EuiFlexItem grow={false}>
              <EuiText>{this.renderAboutRepositoriesDescription()}</EuiText>
            </EuiFlexItem>
          )}

          {snapshotsLoadedButEmpty && (
            <EuiFlexItem grow={false}>
              <AddButton regionId={regionId} />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        <EuiSpacer size='xl' />

        {this.renderTable()}
      </Fragment>
    )
  }

  renderTable() {
    const { regionId, fetchSnapshotRepositoriesRequest, snapshotRepositories } = this.props

    if (fetchSnapshotRepositoriesRequest.error) {
      return <CuiAlert type='error'>{fetchSnapshotRepositoriesRequest.error}</CuiAlert>
    }

    if (snapshotRepositories && values(snapshotRepositories).length === 0) {
      return this.renderNoRepositoriesScreen()
    }

    const columns = [
      {
        label: (
          <FormattedMessage id='snapshot-repository-management.row-name' defaultMessage='Name' />
        ),
        render: (row) => row.repository_name,
        sortKey: `repository_name`,
      },
      {
        label: (
          <FormattedMessage id='snapshot-repository-management.row-type' defaultMessage='Type' />
        ),
        render: (row) => <EuiCode>{row.config.type}</EuiCode>,
        sortKey: `config.type`,
      },
      {
        className: 'snapshotRepositories-actionCell',
        label: (
          <FormattedMessage
            id='snapshot-repository-management.row-actions'
            defaultMessage='Actions'
          />
        ),
        render: (row) => (
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem>
              <CuiRouterLinkButton
                size='s'
                className='snapshotRepositories-rowAction'
                to={editSnapshotRepositoryUrl(regionId, row.repository_name)}
              >
                <FormattedMessage
                  id='snapshot-repository-management.row-edit'
                  defaultMessage='Edit'
                />
              </CuiRouterLinkButton>
            </EuiFlexItem>

            <EuiFlexItem>
              <DeleteSnapshotRepositoryButton
                regionId={regionId}
                snapshotRepoId={row.repository_name}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
      },
    ]

    return (
      <CuiTable
        className='snapshotRepositories-table'
        rows={values(snapshotRepositories)}
        getRowId={(row) => row.repository_name}
        columns={columns}
        initialLoading={!snapshotRepositories}
      />
    )
  }

  renderNoRepositoriesScreen() {
    const { regionId } = this.props

    return (
      <EuiEmptyPrompt
        style={{ maxWidth: `50em` }}
        title={
          <h2>
            <FormattedMessage
              id='snapshot-repository-management.no-repositories-title'
              defaultMessage='You have no snapshot repositories'
            />
          </h2>
        }
        body={this.renderAboutRepositoriesDescription()}
        actions={<AddButton regionId={regionId} />}
      />
    )
  }

  renderAboutRepositoriesDescription() {
    return (
      <FormattedMessage
        id='snapshot-repository-management.about-repositories'
        defaultMessage='Snapshot repositories make it possible to back up data from your Elasticsearch clusters. You need to configure at least one snapshot repository and then tell your Elasticsearch clusters to use the repository when you create or edit the clusters. {learnMore}'
        values={{
          learnMore: (
            <DocLink link='manageSnapshotReposDocLink'>
              <FormattedMessage
                id='snapshot-repository-management.learn-more'
                defaultMessage='Learn more'
              />
            </DocLink>
          ),
        }}
      />
    )
  }
}

export default SnapshotRepositoryManagement
