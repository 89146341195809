/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiSwitch } from '@elastic/eui'

import { DeploymentRole } from './types'
import DeploymentRolePanel from './DeploymentRolePanel'
import messages from './messages'

import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'

type Props = {
  allDeploymentsRoleAssignment: DeploymentRole | null
  onChangeAllDeploymentsRoleAssignment: (role: DeploymentRole) => void
  onSwitchAllDeploymentsRoleAssignments: (checked: boolean) => void
} & WrappedComponentProps

const AllDeploymentsRoles: FunctionComponent<Props> = ({
  intl: { formatMessage },
  allDeploymentsRoleAssignment,
  onChangeAllDeploymentsRoleAssignment,
  onSwitchAllDeploymentsRoleAssignments,
}) => {
  const isAllDeploymentsRoleAssignmentsEnabled = allDeploymentsRoleAssignment !== null

  const deploymentRoles = [
    {
      role: DeploymentRole.DEPLOYMENT_ADMIN,
      title: formatMessage(messages.admin),
      description: formatMessage(messages.adminDescription),
    },
    {
      role: DeploymentRole.DEPLOYMENT_EDITOR,
      title: formatMessage(messages.editor),
      description: formatMessage(messages.editorDescription),
    },
    {
      role: DeploymentRole.DEPLOYMENT_VIEWER,
      title: formatMessage(messages.viewer),
      description: formatMessage(messages.viewerDescription),
    },
  ]

  return (
    <Fragment>
      <EuiSpacer size='l' />
      <EuiText size='s'>
        <EuiFlexGroup
          gutterSize='s'
          responsive={false}
          alignItems='baseline'
          justifyContent='spaceBetween'
        >
          <EuiFlexItem grow={false}>
            <EuiText size='s'>
              <strong>
                <FormattedMessage
                  id='roles-assignments.deployment-access.inner-title'
                  defaultMessage='Global role for all deployments'
                />
              </strong>
            </EuiText>
          </EuiFlexItem>

          <EuiFlexItem grow={false} component='span' style={{ alignSelf: `right` }}>
            <EuiSwitch
              checked={isAllDeploymentsRoleAssignmentsEnabled}
              onChange={() => {
                onSwitchAllDeploymentsRoleAssignments(!isAllDeploymentsRoleAssignmentsEnabled)
              }}
              label='Disable all deployments role assignments'
              aria-describedby='Disable all deployments role assignments'
              showLabel={false}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiText>

      <EuiSpacer size='m' />

      <EuiText size='s' color='subdued'>
        <FormattedMessage
          id='roles-assignments.deployment-access.description'
          defaultMessage='Grant the selected role on all existing and future deployments. To assign specific roles per deployment, disable the global role option first.'
        />
      </EuiText>

      <EuiSpacer size='s' />

      {deploymentRoles.map(({ role, title, description }) => (
        <Fragment key={role}>
          <EuiSpacer size='m' />

          <DeploymentRolePanel
            isChecked={allDeploymentsRoleAssignment === role}
            isDisabled={!isAllDeploymentsRoleAssignmentsEnabled}
            title={title}
            role={role}
            description={description}
            onChangeAllDeploymentsRoleAssignment={onChangeAllDeploymentsRoleAssignment}
          />
        </Fragment>
      ))}
    </Fragment>
  )
}

export default injectIntl(AllDeploymentsRoles)
