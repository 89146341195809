/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import { noop } from 'lodash'
import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiFormLabel,
  EuiFlyoutHeader,
  EuiFlyout,
  EuiFlyoutBody,
  EuiTitle,
  EuiFlyoutFooter,
} from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import SpinButton from '../../../../SpinButton'

interface Props {
  organizationId: string
  expirationDate: Date
  extendTrial: (date: string) => Promise<any>
  extendTrialRequest: AsyncRequestState
  resetExtendTrial: () => void
}

interface State {
  extendingTrial: boolean
  expirationDate: string
}

const messages = defineMessages({
  extendTrial: {
    id: `extend-trial.extend-trial`,
    defaultMessage: `Extend trial`,
  },
  extendedTrial: {
    id: `extend-trial.updated-trial`,
    defaultMessage: `Granted trial extension`,
  },
  cancel: {
    id: `extend-trial.cancel`,
    defaultMessage: `Cancel`,
  },
  save: {
    id: `extend-trial.save`,
    defaultMessage: `Extend trial`,
  },
})

class ExtendTrial extends Component<Props, State> {
  state: State = {
    extendingTrial: false,
    expirationDate: moment(this.props.expirationDate).format(`YYYY-MM-DD`),
  }

  componentWillUnmount() {
    this.props.resetExtendTrial()
  }

  render() {
    return (
      <Fragment>
        <EuiButton size='s' onClick={() => this.startExtension()}>
          <FormattedMessage {...messages.extendTrial} />
        </EuiButton>

        {this.renderExtendTrialFlyout()}
      </Fragment>
    )
  }

  renderExtendTrialFlyout() {
    const { extendTrialRequest } = this.props

    const { extendingTrial, expirationDate } = this.state

    const busy = extendTrialRequest.inProgress

    if (!extendingTrial) {
      return null
    }

    return (
      <EuiFlyout
        ownFocus={true}
        onClose={this.cancelChanges}
        size='s'
        aria-labelledby='extendTrialFlyoutTitle'
      >
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='s'>
            <h2 id='extendTrialFlyoutTitle'>
              <FormattedMessage id='extend-trial.flyout-title' defaultMessage='Extend trial' />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <div>
            <EuiFormLabel>
              <FormattedMessage
                id='extend-trial.expiration-date-label'
                defaultMessage='Expiration date'
              />
            </EuiFormLabel>

            <EuiFieldText
              type='date'
              value={expirationDate}
              onChange={(e) => this.setTrialExtension((e.target as HTMLInputElement).value)}
              readOnly={busy}
            />
          </div>

          {extendTrialRequest.error && (
            <Fragment>
              <EuiSpacer />

              <CuiAlert type='error'>{extendTrialRequest.error}</CuiAlert>
            </Fragment>
          )}
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup gutterSize='m' alignItems='center' justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty disabled={busy} onClick={() => this.cancelChanges()}>
                <FormattedMessage {...messages.cancel} />
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <SpinButton fill={true} spin={busy} onClick={this.saveTrialExtension}>
                <FormattedMessage {...messages.save} />
              </SpinButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  startExtension = () => {
    this.setState({
      extendingTrial: true,
      expirationDate: moment(this.props.expirationDate).format(`YYYY-MM-DD`),
    })
  }

  cancelChanges = () => {
    this.setState({ extendingTrial: false })
  }

  setTrialExtension = (date) => {
    this.setState({ expirationDate: date })
  }

  saveTrialExtension = () => {
    const { expirationDate } = this.state

    const { extendTrial } = this.props

    extendTrial(expirationDate)
      .then(() => this.cancelChanges())
      .catch(noop)
  }
}

export default ExtendTrial
