/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import { createInstanceConfigurationUrl } from '@modules/cloud-api/v1/urls'
import type { InstanceConfiguration, InstanceTypeResource } from '@modules/cloud-api/v1/types'
import type { InstanceType, NodeType } from '@modules/ui-types'
import { createCuiWizard } from '@modules/cui'

import ApiRequestExample from '../../../ApiRequestExample'
import schedule from '../../../../lib/schedule'
import { makeOuterClause } from '../../../../lib/allocatorFilters'
import { ramToStorageMultiplier } from '../../../../constants/fsMultiplier'
import { getDefaultNodeTypesForProductType } from '../../../../lib/sliders'

import { deserializeInstanceConfiguration, serializeInstanceConfiguration } from './helpers'
import { detailsStepDefinition } from './ConfigureDetailsStep'
import { multipliersStepDefinition } from './ConfigureMultipliersStep'
import { instanceTypesStepDefinition } from './ConfigureInstanceTypesStep'
import { allocatorsStepDefinition } from './ConfigureAllocatorsStep'

import type { FauxInstanceConfiguration, StepOwnProps } from './instanceConfigurationWizardTypes'
import type { IntlShape } from 'react-intl'
import type { ReactNode } from 'react'

type Props = {
  intl: IntlShape
  regionId: string
  instanceTypes: InstanceTypeResource[]
  instanceConfiguration?: InstanceConfiguration
  saveButtonLabel: ReactNode
  isSaving: boolean
  onSave: (instanceConfiguration: InstanceConfiguration) => void
  error?: Error | string
  prompt?: ReactNode
  fetchInstanceConfigurations: () => void
  fetchInstanceTypes: () => void
}

type State = {
  instanceConfiguration: FauxInstanceConfiguration
}

const WizardSteps = createCuiWizard<StepOwnProps>()

class InstanceConfigurationWizard extends Component<Props, State> {
  state: State = {
    instanceConfiguration: this.getInitialInstanceConfiguration(),
  }

  render() {
    const { prompt } = this.props

    return (
      <div>
        {prompt}

        {prompt && <EuiSpacer size='xl' />}

        {this.renderWizard()}
      </div>
    )
  }

  renderWizard() {
    const { intl, regionId, instanceTypes, saveButtonLabel, isSaving, error } = this.props

    const { instanceConfiguration } = this.state

    const steps = this.getSteps()

    const stepProps = {
      intl,
      regionId,
      instanceTypes,
      instanceConfiguration,
      updateInstanceConfiguration: this.updateInstanceConfiguration,
    }

    return (
      <WizardSteps
        steps={steps}
        stepProps={stepProps}
        completeButtonLabel={saveButtonLabel}
        completeButtonHelpComponent={
          <ApiRequestExample
            method='POST'
            endpoint={createInstanceConfigurationUrl({ regionId })}
            body={this.preparePayload()}
          />
        }
        onComplete={this.save}
        isBusyCompleting={isSaving}
        completionError={error}
      />
    )
  }

  getInitialInstanceConfiguration() {
    const { instanceConfiguration } = this.props

    const defaultInstanceConfiguration: FauxInstanceConfiguration = {
      name: ``,
      description: ``,
      clauses: [makeOuterClause()],
      instance_type: `elasticsearch` as InstanceType,
      node_types: getDefaultNodeTypesForProductType({
        sliderInstanceType: `elasticsearch`,
      }) as NodeType[],
      sizingUnit: undefined,
      ratio: ramToStorageMultiplier,
      memoryIncrements: undefined,
      memoryDefaultValue: undefined,
      storageIncrements: undefined,
      storageDefaultValue: undefined,
    }

    if (!instanceConfiguration) {
      return defaultInstanceConfiguration
    }

    return deserializeInstanceConfiguration({ instanceConfiguration })
  }

  getSteps() {
    const { instanceConfiguration } = this.props

    if (!instanceConfiguration) {
      return [
        allocatorsStepDefinition,
        instanceTypesStepDefinition,
        multipliersStepDefinition,
        detailsStepDefinition,
      ]
    }

    if (!instanceConfiguration.system_owned) {
      return [allocatorsStepDefinition, detailsStepDefinition]
    }

    return [allocatorsStepDefinition]
  }

  updateInstanceConfiguration = (changes) => {
    const { instanceConfiguration } = this.state

    const nextInstanceConfiguration = {
      ...instanceConfiguration,
      ...changes,
    }

    this.setState({
      instanceConfiguration: nextInstanceConfiguration,
    })
  }

  save = () => {
    const { onSave } = this.props

    onSave(this.preparePayload())
  }

  preparePayload = () => {
    const { regionId } = this.props
    const { instanceConfiguration } = this.state

    return serializeInstanceConfiguration({ regionId, instanceConfiguration })
  }
}

export default injectIntl(
  schedule(
    InstanceConfigurationWizard,
    ({ fetchInstanceTypes, fetchInstanceConfigurations }: Props) => {
      fetchInstanceTypes()
      fetchInstanceConfigurations()
    },
  ),
)
