/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages } from 'react-intl'

import {
  getDefaultValueAndIncrementsForSizingUnit,
  getDefaultValueForSizingUnit,
  getIncrementsForSizingUnit,
  SIZING_UNIT_MEMORY,
} from '../../../../lib/instanceConfigurations/multipliers'

import ChooseMultipliersStep, { validateMultipliers } from './ChooseMultipliersStep'
import { getInstanceBounds } from './helpers'

import type { StepDefinition, StepOwnProps } from './instanceConfigurationWizardTypes'

const messages = defineMessages({
  title: {
    id: `configure-sizes-step.step-title`,
    defaultMessage: `Sizes`,
  },
})

function ConfigureMultipliersStep(props) {
  const { instanceConfiguration } = props

  const {
    sizingUnit,
    memoryIncrements,
    memoryDefaultValue,
    storageIncrements,
    storageDefaultValue,
  } = instanceConfiguration

  const bounds = getInstanceBounds()
  const increments = getIncrementsForSizingUnit({ sizingUnit, memoryIncrements, storageIncrements })
  const defaultValue = getDefaultValueForSizingUnit({
    sizingUnit,
    memoryDefaultValue,
    storageDefaultValue,
  })

  const mangleThenUpdate = getUpdateInstanceConfiguration(props)

  return (
    <ChooseMultipliersStep
      bounds={bounds}
      increments={increments}
      defaultValue={defaultValue}
      {...props}
      updateInstanceConfiguration={mangleThenUpdate}
    />
  )
}

function getUpdateInstanceConfiguration({ instanceConfiguration, updateInstanceConfiguration }) {
  return mangleThenUpdate

  function mangleThenUpdate(changes) {
    const bounds = getInstanceBounds()

    const nextInstanceConfiguration = {
      ...instanceConfiguration,
      ...changes,
    }

    const derivedFields = getDefaultValueAndIncrementsForSizingUnit({
      prevRatio: instanceConfiguration.ratio,
      prevSizingUnit: instanceConfiguration.sizingUnit,
      bounds: bounds || undefined,
      sizingUnit: nextInstanceConfiguration.sizingUnit,
      ratio: nextInstanceConfiguration.ratio,
      memoryIncrements: nextInstanceConfiguration.memoryIncrements,
      storageIncrements: nextInstanceConfiguration.storageIncrements,
      memoryDefaultValue: nextInstanceConfiguration.memoryDefaultValue,
      storageDefaultValue: nextInstanceConfiguration.storageDefaultValue,
    })

    const mangledChanges = {
      ...changes,
      ...derivedFields,
    }

    return updateInstanceConfiguration(mangledChanges)
  }
}

function onBeforeEnter({ instanceConfiguration, updateInstanceConfiguration }: StepOwnProps) {
  const { sizingUnit, ratio } = instanceConfiguration

  if (sizingUnit != null) {
    return
  }

  const newSizingUnit = SIZING_UNIT_MEMORY

  const bounds = getInstanceBounds()

  updateInstanceConfiguration({
    sizingUnit: newSizingUnit,
    ...getDefaultValueAndIncrementsForSizingUnit({
      sizingUnit: newSizingUnit,

      // @ts-ignore as this appears to never be null in practice
      bounds,
      ratio,
    }),
  })
}

export const multipliersStepDefinition: StepDefinition = {
  title: messages.title,
  ConfigureStep: ConfigureMultipliersStep,
  onBeforeEnter,
  validateStep: validateMultipliers,
}
