/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, size } from 'lodash'

import { EuiSpacer } from '@elastic/eui'

import type { ContainerSetDetails } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, getFilterQueryString, setFilterQueryString } from '@modules/cui'
import type { ControlledFilterQuery, OnFilterChangeParams } from '@modules/cui'
import Header from '@modules/cui/Header'

import { containerSetsCrumbs } from '../../lib/crumbBuilder'

import ContainerSetsTable from './ContainerSetsTable'
import ContainerSetsSchedule from './ContainerSetsSchedule'
import ContainerSetsFilterContext from './ContainerSetsFilterContext'

type Props = {
  regionId: string
  containerSets: ContainerSetDetails[] | null
  fetchContainerSetsRequest: AsyncRequestState
  fetchContainerSets: () => void
}

type State = {
  query: ControlledFilterQuery
  queryResults: ContainerSetDetails[]
}

class ContainerSets extends Component<Props, State> {
  state: State = {
    query: getFilterQueryString({ storageKey: `ContainerSets` }),
    queryResults: [],
  }

  render() {
    const { regionId, fetchContainerSetsRequest } = this.props
    const isLoading = fetchContainerSetsRequest.inProgress

    return (
      <Fragment>
        <Header
          name={<FormattedMessage id='container-sets.title' defaultMessage='Container sets' />}
          breadcrumbs={containerSetsCrumbs({ regionId })}
        />

        <ContainerSetsSchedule search={this.search} busy={isLoading} />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const { containerSets, fetchContainerSetsRequest } = this.props
    const { query } = this.state
    const isLoading = fetchContainerSetsRequest.inProgress

    if (fetchContainerSetsRequest.error) {
      return <CuiAlert type='error'>{fetchContainerSetsRequest.error}</CuiAlert>
    }

    return (
      <Fragment>
        <ContainerSetsFilterContext
          query={query}
          onChange={this.onChange}
          containerSets={containerSets || undefined}
          isLoading={isLoading}
        />

        {this.renderContainerSetsTable()}
      </Fragment>
    )
  }

  renderContainerSetsTable() {
    const { regionId, containerSets } = this.props
    const { queryResults } = this.state

    const loadedButNoMatches = containerSets && isEmpty(queryResults)

    if (loadedButNoMatches) {
      return null
    }

    const totalCount = size(containerSets)

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <ContainerSetsTable
          regionId={regionId}
          containerSets={queryResults}
          totalCount={totalCount}
          initialLoading={!containerSets}
        />
      </Fragment>
    )
  }

  search = () => {
    const { fetchContainerSets } = this.props

    fetchContainerSets()
  }

  onChange = ({ queryText, queryResults }: OnFilterChangeParams<ContainerSetDetails>) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `ContainerSets`, queryText })
  }
}

export default ContainerSets
