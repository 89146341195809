/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState, Allocator, Runner } from '@modules/ui-types'

import { getAllocator, getAllocatorRequest, getRunner } from '../../../reducers'

import AllocatorOverview from './AllocatorOverview'

import type { ReduxState } from '@/types/redux'

interface StateProps {
  allocator?: Allocator
  allocatorRequest: AsyncRequestState
  runner: Runner
}

interface ConsumerProps {
  regionId: string
  hostId: string
}

const mapStateToProps = (state: ReduxState, { regionId, hostId }: ConsumerProps): StateProps => ({
  allocator: getAllocator(state, regionId, hostId),
  allocatorRequest: getAllocatorRequest(state, regionId, hostId),
  runner: getRunner(state, regionId, hostId)!,
})

export default connect<StateProps, unknown, ConsumerProps>(mapStateToProps)(AllocatorOverview)
