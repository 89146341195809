/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { uniqBy } from 'lodash'

import { ReduxState } from '@/types/redux'

import {
  ACTIVATE_MFA_DEVICE,
  DISABLE_MFA,
  ENABLE_MFA,
  ENROLL_MFA_DEVICE,
  FETCH_MFA_DEVICES,
  FETCH_MFA_STATUS,
  REMOVE_MFA_DEVICE,
} from '../../constants/actions'

import type { MfaAction, MfaDevice } from './types'

function makeDevice(device): MfaDevice {
  return {
    ...device,
    isActive: device.status === `ACTIVE`,
  }
}

export interface State {
  enabled?: boolean
  mfa_devices?: MfaDevice[]
}

const initialState = {}

export default function mfaReducer(state: State = initialState, action: MfaAction): State {
  const { error, payload, type, meta } = action

  if (
    [
      ACTIVATE_MFA_DEVICE,
      DISABLE_MFA,
      ENABLE_MFA,
      ENROLL_MFA_DEVICE,
      FETCH_MFA_DEVICES,
      FETCH_MFA_STATUS,
      REMOVE_MFA_DEVICE,
    ].includes(action.type) === false
  ) {
    return state
  }

  if (meta.state === `success`) {
    // optimistic updates
    if (type === ENABLE_MFA) {
      return {
        ...state,
        enabled: true,
      }
    }

    if (type === DISABLE_MFA) {
      return {
        ...state,
        enabled: false,
      }
    }
  }

  if (payload && !error) {
    if (type === FETCH_MFA_STATUS || type === ENABLE_MFA || type === DISABLE_MFA) {
      return {
        ...state,
        ...payload,
      }
    }

    if (type === FETCH_MFA_DEVICES) {
      return {
        ...state,
        mfa_devices: payload.mfa_devices.map(makeDevice),
      }
    }

    if (type === ENROLL_MFA_DEVICE || type === ACTIVATE_MFA_DEVICE) {
      return {
        ...state,
        mfa_devices: uniqBy(
          [
            // there can only be one of each device type
            makeDevice(payload),
            ...(state.mfa_devices || []),
          ],
          (v) => v.device_type,
        ),
      }
    }

    if (type === REMOVE_MFA_DEVICE) {
      const { device_id } = meta
      return {
        ...state,
        mfa_devices: state.mfa_devices?.filter((each) => each.device_id !== device_id),
      }
    }
  }

  return state
}

export function getMfaEnabled(state: ReduxState): boolean | null {
  return state.mfa?.enabled ?? null
}

export function getMfaDevices(state: ReduxState): MfaDevice[] | null {
  return state.mfa?.mfa_devices ?? null
}
