/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { injectIntl } from 'react-intl'
import { isEmpty, sum } from 'lodash'

import { EuiText, EuiSpacer, EuiIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { InstanceConfiguration } from '@modules/cloud-api/v1/types'
import type { EsNodeType } from '@modules/ui-types'

import { sortNodeTypes } from '@/lib/sliders/sorting'
import { hasTiebreakerMaster } from '@/lib/deployments/masters'
import {
  getInstanceType,
  getDeploymentArchitecturePrices,
} from '@/lib/deployments/deploymentArchitecture'
import { isMaster } from '@/lib/stackDeployments/selectors'
import { getTier } from '@/lib/stackDeployments/selectors/nodeRoles'
import { messages, dedicatedNodeTypeMessages } from '@/lib/deployments/messages'
import { getSliderInstanceColor, isSliderInstanceType, getSliderPrettyName } from '@/lib/sliders'
import { formatCenticent } from '@/lib/money'
import { getNodeTypeForArchitectureSummary } from '@/lib/deployments/nodeTypes'

import TiebreakerInfo from './TiebreakerInfo'
import PriceDisclaimer from './PriceDisclaimer'
import DeploymentArchitectureSummaryText from './DeploymentArchitectureSummaryText'

import type { DeploymentArchitectureSummaryProps as Props, ArchitectureItems } from '../types'

import '../deploymentArchitecture.scss'

type State = {
  isTiebreakerPopoverOpen: boolean
}

class DeploymentArchitectureSummary extends Component<Props, State> {
  state = {
    isTiebreakerPopoverOpen: false,
  }

  componentDidMount() {
    const { regionId, fetchBasePrices, level } = this.props

    if (this.shouldRenderPricing()) {
      fetchBasePrices({
        regionId,
        level,
      })
    }
  }

  render() {
    const { autoscalingEnabled, showPrices, hideDisclaimer } = this.props
    const pricesByNodeType = this.getPrices()
    const hourlyRate = sum(Object.values(pricesByNodeType))
    const shouldShowDisclaimer = showPrices && !hideDisclaimer

    return (
      <Fragment>
        {this.renderContent()}
        {shouldShowDisclaimer && (
          <PriceDisclaimer autoscalingEnabled={autoscalingEnabled} hourlyRate={hourlyRate} />
        )}
      </Fragment>
    )
  }

  renderContent() {
    const { showPrices, intl, hideColorIndicators } = this.props
    const { formatMessage } = intl
    const summary = this.getSummaryDetails().sort((a, b) => sortNodeTypes(a.type, b.type))

    const pricesByNodeType = showPrices ? this.getPrices() : {}

    return summary.map((each, i) => {
      const { type, title, description, color } = each

      return (
        <div data-test-id='architecture-item' key={i}>
          <EuiFlexGroup responsive={false} alignItems='center' gutterSize='s'>
            {!hideColorIndicators && (
              <EuiFlexItem grow={false}>
                <EuiIcon size='m' color={color || `primary`} type='dot' />
              </EuiFlexItem>
            )}
            <EuiFlexItem>
              <EuiFlexGroup responsive={false} alignItems='center' gutterSize='xs'>
                <EuiFlexItem grow={false}>
                  <EuiText
                    style={{ fontWeight: 600 }}
                    size='s'
                    data-test-id='architecture-item-title'
                  >
                    <p>{title}</p>
                  </EuiText>
                </EuiFlexItem>
                {type === `tiebreaker` && (
                  <EuiFlexItem grow={false}>
                    <TiebreakerInfo
                      onClick={() =>
                        this.setState({
                          isTiebreakerPopoverOpen: !this.state.isTiebreakerPopoverOpen,
                        })
                      }
                      isOpen={this.state.isTiebreakerPopoverOpen}
                      closePopover={() => this.setState({ isTiebreakerPopoverOpen: false })}
                    />
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
            {showPrices && (
              <EuiFlexItem data-test-id='architecture-item-price' grow={false}>
                {pricesByNodeType[type] === 0 ? (
                  <EuiText size='s' style={{ fontWeight: 600, textTransform: 'uppercase' }}>
                    <p>{formatMessage(messages.free)}</p>
                  </EuiText>
                ) : (
                  <EuiText size='s' style={{ fontWeight: 600 }} textAlign='right'>
                    <p style={{ marginBottom: 0 }}>{this.renderPrice(pricesByNodeType[type])}</p>
                  </EuiText>
                )}
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
          <EuiSpacer size='xs' />

          <EuiText
            style={{ paddingLeft: hideColorIndicators ? '0' : ' 24px' }}
            size='xs'
            data-test-id='architecture-item-description'
            color='subdued'
          >
            {description}
          </EuiText>

          <EuiSpacer size='m' />
        </div>
      )
    })
  }

  renderPrice(price) {
    const {
      intl: { formatMessage },
      priceViewSelected,
    } = this.props

    if (!price) {
      return
    }

    if (priceViewSelected === `hourly`) {
      const hourlySuffix = formatMessage(messages.hourlySuffix)

      return `${formatCenticent(price, 4)} / ${hourlySuffix}`
    }

    const monthlySuffix = formatMessage(messages.monthlySuffix)

    return `${formatCenticent(price * 24 * 30, 2)} / ${monthlySuffix}`
  }

  shouldRenderPricing() {
    const { showPrices, inTrial } = this.props

    if (!showPrices) {
      return false
    }

    return inTrial
  }

  getSummaryDetails(): ArchitectureItems {
    const { instanceConfigurations, nodeConfigurations } = this.props
    const hasTieBreaker = hasTiebreakerMaster(nodeConfigurations, instanceConfigurations)
    let tiebreakerDetails

    const summary = nodeConfigurations.map((nodeConfiguration) => {
      const instanceConfiguration = instanceConfigurations.find(
        (config) => config.id === nodeConfiguration.instance_configuration_id,
      ) as InstanceConfiguration
      const type = getNodeTypeForArchitectureSummary(
        nodeConfiguration,
        instanceConfiguration,
        hasTieBreaker,
      )
      const instanceType = getInstanceType({
        nodeConfig: nodeConfiguration,
        instanceConfigurations,
      })
      const isMasterNode = isMaster({ topologyElement: nodeConfiguration })
      const includeTiebreaker = isMasterNode && instanceType === `elasticsearch` && hasTieBreaker
      const color = getSliderInstanceColor(type)
      const tier = getTier(nodeConfiguration)
      const instanceTitle = this.getInstanceTitle(type)
      const zoneCount = nodeConfiguration.zone_count || 1
      const id = tier ? `storage` : `memory`
      const description = (
        <DeploymentArchitectureSummaryText
          instanceConfiguration={instanceConfiguration}
          resource='memory'
          nodeConfiguration={nodeConfiguration}
          size={nodeConfiguration.size?.value ?? 0}
          zoneCount={zoneCount}
        />
      )

      if (includeTiebreaker) {
        const tiebreakerSummary = this.getTieBreakerDetails(nodeConfiguration)

        tiebreakerDetails = { ...tiebreakerSummary }
      }

      return {
        type,
        title: instanceTitle,
        color,
        zoneCount,
        id,
        description,
      }
    })

    if (hasTieBreaker && !isEmpty(tiebreakerDetails)) {
      summary.push(tiebreakerDetails)
    }

    return summary
  }

  getInstanceTitle(node) {
    const {
      intl: { formatMessage },
      version,
    } = this.props
    const sliderInstanceType = isSliderInstanceType(node)
    const dedicatedNodeTypes: EsNodeType[] = [`master`, `ingest`]

    if (sliderInstanceType) {
      return formatMessage(getSliderPrettyName({ sliderInstanceType: node, version }))
    }

    const dedicatedNodeType = dedicatedNodeTypes.indexOf(node) > -1

    if (dedicatedNodeType || node === `tiebreaker`) {
      return formatMessage(dedicatedNodeTypeMessages[node])
    }

    if (
      node === `hot` ||
      node === `warm` ||
      node === `cold` ||
      node === `frozen` ||
      node === `ml`
    ) {
      return formatMessage(messages[node])
    }

    return node
  }

  getTieBreakerDetails(nodeConfiguration) {
    const {
      intl: { formatMessage },
      instanceConfigurations,
    } = this.props
    const instanceConfiguration = instanceConfigurations.find(
      (config) => config.id === nodeConfiguration.instance_configuration_id,
    ) as InstanceConfiguration

    return {
      type: `tiebreaker`,
      color: getSliderInstanceColor(`tiebreaker`),
      title: formatMessage(messages.tiebreaker),
      zoneCount: 1,
      id: `memory`,
      description: (
        <DeploymentArchitectureSummaryText
          instanceConfiguration={instanceConfiguration}
          resource='memory'
          nodeConfiguration={nodeConfiguration}
          size={1024}
          zoneCount={1}
          tiebreaker={true}
        />
      ),
    }
  }

  getPrices() {
    const {
      intl,
      basePrices,
      level,
      nodeConfigurations,
      instanceConfigurations,
      regionId,
      autoscalingEnabled,
      fetchBasePricesRequest,
    } = this.props

    if (
      !nodeConfigurations ||
      !instanceConfigurations ||
      fetchBasePricesRequest.inProgress ||
      !basePrices
    ) {
      return {}
    }

    return getDeploymentArchitecturePrices({
      intl,
      regionId,
      instanceConfigurations,
      nodeConfigurations,
      basePrices,
      level,
      isAutoscalingEnabled: autoscalingEnabled,
    })
  }
}

export default injectIntl(DeploymentArchitectureSummary)
