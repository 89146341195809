/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiText, EuiSpacer, EuiPopoverTitle, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { CuiHelpTipIcon } from '@modules/cui'

import { sliderActivityUrl } from '@/lib/urlBuilder'

import type { FunctionComponent } from 'react'

type Props = {
  displayName: string
  deploymentId: string
}

const KibanaStatusPopover: FunctionComponent<Props> = ({ displayName, deploymentId }) => (
  <EuiFlexGroup gutterSize='xs' alignItems='center' justifyContent='center' responsive={false}>
    <EuiFlexItem grow={false}>
      <EuiText className='kibana-link-disabled' size='s' color='subdued'>
        {displayName}
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <CuiHelpTipIcon iconType='iInCircle' anchorPosition='rightCenter' color='primary'>
        <Fragment>
          <EuiPopoverTitle>
            <FormattedMessage
              id='kibana-link.kibana-broken-title'
              defaultMessage='Kibana not available'
            />
          </EuiPopoverTitle>
          <EuiSpacer size='s' />
          <EuiText size='s'>
            <FormattedMessage
              id='kibana-link.kibana-broken'
              defaultMessage='Kibana is currently not available for this deployment. You can view its status on the {activity} page.'
              values={{
                activity: (
                  <Link to={sliderActivityUrl(deploymentId, 'kibana')}>
                    <FormattedMessage
                      id='kibana-link.kibana-broken-link'
                      defaultMessage='deployment activity'
                    />
                  </Link>
                ),
              }}
            />
          </EuiText>
        </Fragment>
      </CuiHelpTipIcon>
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default KibanaStatusPopover
