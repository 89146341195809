/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withSmallErrorBoundary } from '@modules/cui'

import { getUsername } from '@/lib/profile'

import { getProfile } from '../../apps/userconsole/reducers'
import { getCurrentUserUsername } from '../../reducers'
import { getConfigForKey } from '../../selectors'

import CustomerProfile from './CustomerProfile'

type StateProps = {
  username: string | null
}

interface DispatchProps {}

interface ConsumerProps {}

const mapStateToProps = (state): StateProps => {
  const isAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`
  const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`
  const profile = getProfile(state)
  return {
    username: isAdminconsole ? getCurrentUserUsername(state) : getUsername({ isHeroku, profile }),
  }
}

const mapDispatchToProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withSmallErrorBoundary(CustomerProfile))

export { default as CustomerLevel } from './CustomerLevel'
