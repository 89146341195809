/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { BillingSubscriptionLevel } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { fetchBasePricesIfNeeded } from '@/apps/userconsole/actions/pricing'
import { getProfile, fetchBasePricesRequest, getBasePrices } from '@/apps/userconsole/reducers'
import { isFeatureActivated, getConfigForKey } from '@/selectors'

import DeploymentArchitectureSummary from './DeploymentArchitectureSummary'

import type { ThunkDispatch } from '@/types/redux'
import type { ConsumerProps, DispatchProps, StateProps } from '../types'

const mapStateToProps = (state, { regionId, level }: ConsumerProps): StateProps => {
  const profile = getProfile(state)
  const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`
  const currentLevel = level || profile?.level || `standard`

  return {
    inTrial: profile?.inTrial,
    level: currentLevel,
    basePrices: getBasePrices(state, regionId, currentLevel),
    fetchBasePricesRequest: fetchBasePricesRequest(state, regionId, currentLevel),
    showPrices: !isHeroku && isFeatureActivated(state, Feature.showPrices),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchBasePrices: ({
    regionId,
    level,
    marketplace,
  }: {
    regionId: string
    level?: BillingSubscriptionLevel
    marketplace?: boolean
  }) => dispatch(fetchBasePricesIfNeeded({ regionId, level, marketplace })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentArchitectureSummary)
