/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { SaasOrganizationResponse } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { resetUpdateOrganizationRequest, updateOrganization } from '@/actions/organizations'
import { updateOrganizationRequest } from '@/reducers/asyncRequests/registry'

import OrganizationFeatures from './OrganizationFeatures'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { MapStateToProps } from 'react-redux'

interface StateProps {
  updateOrganizationRequest: AsyncRequestState
}

interface DispatchProps {
  setPluginsAndBundlesEnabled: (bundles: boolean, plugins: boolean) => void
  resetUpdateOrganization: () => void
}

interface ConsumerProps {
  saasOrganization: SaasOrganizationResponse
}

const mapStateToProps: MapStateToProps<StateProps, ConsumerProps, ReduxState> = (state, props) => ({
  updateOrganizationRequest: updateOrganizationRequest(
    state,
    props.saasOrganization.organization.organization_id,
  ),
})

const mapDispatchToProps: (dispatch: ThunkDispatch, props: ConsumerProps) => DispatchProps = (
  dispatch,
  { saasOrganization: { organization } },
) => ({
  setPluginsAndBundlesEnabled: (custom_bundles_allowed, custom_plugins_allowed) =>
    dispatch(
      updateOrganization({
        organizationId: organization.organization_id,
        organization: {
          custom_bundles_allowed,
          custom_plugins_allowed,
        },
      }),
    ),
  resetUpdateOrganization: () =>
    dispatch(resetUpdateOrganizationRequest(organization.organization_id)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationFeatures)
