/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'

import { getSnapshotStatus } from '../../../../reducers'
import { fetchSnapshotStatus } from '../../../../actions/snapshots'
import { fetchSnapshotStatusRequest } from '../../../../reducers/asyncRequests/registry'
import schedule from '../../../../lib/schedule'

import SnapshotInProgress from './SnapshotInProgress'

import type { StateProps, DispatchProps, ConsumerProps, AllProps } from './types'

const mapStateToProps = (state: ReduxState, { deployment, snapshotName }: ConsumerProps) => ({
  snapshotStatus: getSnapshotStatus(state, deployment.id, snapshotName),
  fetchSnapshotStatusRequest: fetchSnapshotStatusRequest(state, deployment.id, snapshotName),
})

const scheduledComponent = schedule<AllProps>(
  SnapshotInProgress,
  ({ deployment, snapshotName, fetchSnapshotStatus }) =>
    fetchSnapshotStatus({ deployment, snapshotName }),
)

export default connect<StateProps, DispatchProps, ConsumerProps>(mapStateToProps, {
  fetchSnapshotStatus,
})(scheduledComponent)
