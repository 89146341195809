/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getDeploymentResourceProxyRequestsUrl } from '@modules/cloud-api/v1/urls'
import type { StackDeployment } from '@modules/ui-types'

import { getFirstEsRefId } from '@/lib/stackDeployments/selectors/clusters'

import { FETCH_NODE_STATS } from '../../constants/actions'
import asyncRequest from '../asyncRequests'

export function fetchNodeStats(deployment: StackDeployment) {
  const { id: deploymentId } = deployment
  const refId = getFirstEsRefId({ deployment })

  const proxyPath = `_nodes/stats`

  const url = getDeploymentResourceProxyRequestsUrl({
    deploymentId,
    resourceKind: 'elasticsearch',
    refId,
    proxyPath,
  })

  return asyncRequest({
    type: FETCH_NODE_STATS,
    url,
    meta: { deploymentId },
    crumbs: [deploymentId],
    requestSettings: {
      request: {
        headers: {
          'X-Management-Request': true,
        },
      },
    },
    handleUnauthorized: true,
  })
}
