/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, uniq, sortBy, orderBy } from 'lodash'
import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import type { IconType } from '@elastic/eui'
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiIcon,
  EuiLink,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type { Runner } from '@modules/ui-types'
import { CuiLink, CuiTable } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'
import CopyButton from '@modules/cui/CopyButton'

import ResourceComments from '../../ResourceComments'
import RunnerLoggingSettingsButton from '../../Runners/RunnersTable/RunnerLoggingSettingsButton'
import RunnerBuild from '../../RunnerBuild'
import RunnerHealth from '../../RunnerHealth'
import { getNamedRole } from '../../../lib/hostRoles'
import { containerUrl, containerSetUrl } from '../../../lib/urlBuilder'

import type { ReactNode } from 'react'

interface Props {
  regionId: string
  hostId: string
  runner?: Runner
  containerSetsFeature: boolean
  startManagingRoles: () => void
}

type HardwareConfigurationItem = {
  id: string
  iconType: IconType
  title: ReactNode
  description: ReactNode
}

class RunnerOverview extends Component<Props> {
  render() {
    return (
      <Fragment>
        <EuiTitle>
          <h3>
            <FormattedMessage id='runner-overview.host-overview' defaultMessage='Host overview' />
          </h3>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText color='subdued'>
          <FormattedMessage
            id='runner-overview.host-description'
            defaultMessage='Hosts perform essential roles in your installation.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const { runner } = this.props

    if (!runner) {
      return <EuiSkeletonText />
    }

    return (
      <Fragment>
        {this.renderHostOverview()}

        <EuiSpacer size='xl' />

        <EuiFlexGroup gutterSize='xl' justifyContent='spaceBetween' alignItems='flexStart'>
          <EuiFlexItem>{this.renderLeftHandSections()}</EuiFlexItem>

          <EuiFlexItem>{this.renderRightHandSections()}</EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderHostOverview() {
    const { regionId, hostId, runner } = this.props

    const { zone, build_info } = runner!

    return (
      <EuiFlexGroup gutterSize='xl'>
        <EuiFlexItem grow={false}>
          <div>
            <EuiFormLabel>
              <FormattedMessage id='runner-overview.host-status' defaultMessage='Host status' />
            </EuiFormLabel>

            <EuiSpacer size='s' />

            <div>
              <RunnerHealth runner={runner!} />
            </div>
          </div>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <div>
            <EuiFormLabel>
              <FormattedMessage id='runner-overview.zone' defaultMessage='Zone' />
            </EuiFormLabel>

            <EuiSpacer size='s' />

            <div>{zone}</div>
          </div>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <div>
            <EuiFormLabel>
              <FormattedMessage id='runner-overview.build' defaultMessage='Build' />
            </EuiFormLabel>

            <EuiSpacer size='s' />

            <div>
              <RunnerBuild build={build_info} />
            </div>
          </div>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <div>
            <EuiFormLabel>
              <FormattedMessage
                id='runner-overview.logging-settings'
                defaultMessage='Logging settings'
              />
            </EuiFormLabel>

            <EuiSpacer size='s' />

            <div>
              <RunnerLoggingSettingsButton regionId={regionId} runnerId={hostId} asGear={true} />
            </div>
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderLeftHandSections() {
    return (
      <Fragment>
        {this.renderSection({
          title: (
            <FormattedMessage
              id='runner-overview.hardware-configuration-section'
              defaultMessage='Hardware configuration'
            />
          ),
          contents: this.renderHardwareConfiguration(),
        })}

        <EuiSpacer size='xl' />

        {this.renderSection({
          title: (
            <FormattedMessage
              id='runner-overview.container-sets-section'
              defaultMessage='Container sets'
            />
          ),
          description: (
            <FormattedMessage
              id='runner-overview.container-sets-section-description'
              defaultMessage='List of logically grouped containers available to this host.'
            />
          ),
          contents: this.renderContainerSets(),
        })}

        <EuiSpacer size='xl' />

        {this.renderSection({
          title: (
            <FormattedMessage id='runner-overview.containers-section' defaultMessage='Containers' />
          ),
          description: (
            <FormattedMessage
              id='runner-overview.containers-section-description'
              defaultMessage='Configured services deployed as containers on this host.'
            />
          ),
          contents: this.renderContainers(),
        })}
      </Fragment>
    )
  }

  renderRightHandSections() {
    const { startManagingRoles } = this.props

    return (
      <Fragment>
        {this.renderSection({
          title: <FormattedMessage id='runner-overview.roles-section' defaultMessage='Roles' />,
          description: (
            <FormattedMessage
              id='runner-overview.roles-section-description'
              defaultMessage='Roles assigned to this host.'
            />
          ),
          contents: this.renderHostRoles(),
          actions: (
            <EuiLink onClick={() => startManagingRoles()}>
              <FormattedMessage
                id='runner-overview.manage-roles-link'
                defaultMessage='Manage roles'
              />
            </EuiLink>
          ),
        })}

        <EuiSpacer size='xl' />

        {this.renderSection({
          title: <FormattedMessage id='runner-overview.tags-section' defaultMessage='Host tags' />,
          description: (
            <FormattedMessage
              id='runner-overview.tags-section-description'
              defaultMessage='Assigned during installation, tags let you filter hosts based on what computing resources they provide.'
            />
          ),
          contents: this.renderHostTags(),
        })}

        <EuiSpacer size='xl' />

        {this.renderSection({
          title: (
            <FormattedMessage
              id='runner-overview.features-section'
              defaultMessage='Host features'
            />
          ),
          contents: this.renderHostFeatures(),
        })}

        <EuiSpacer size='xl' />

        {this.renderSection({
          title: (
            <FormattedMessage id='runner-overview.comments-section' defaultMessage='Comments' />
          ),
          description: (
            <FormattedMessage
              id='runner-overview.comments-section-description'
              defaultMessage='Leave notes for yourself for later or for other system administrators.'
            />
          ),
          contents: this.renderHostComments(),
        })}
      </Fragment>
    )
  }

  renderSection({
    title,
    description,
    contents,
    actions,
  }: {
    title: ReactNode
    description?: ReactNode
    contents: ReactNode
    actions?: ReactNode
  }) {
    return (
      <Fragment>
        <EuiFlexGroup gutterSize='l' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiTitle size='s'>
              <h4>{title}</h4>
            </EuiTitle>
          </EuiFlexItem>

          {actions && (
            <EuiFlexItem grow={false}>
              <div>{actions}</div>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        {description && (
          <Fragment>
            <EuiSpacer size='s' />

            <EuiText color='subdued'>{description}</EuiText>
          </Fragment>
        )}

        <EuiSpacer size='m' />

        <div>{contents}</div>
      </Fragment>
    )
  }

  renderHardwareConfiguration() {
    const { runner } = this.props

    const { host_ip, public_hostname } = runner!

    const columns: Array<CuiTableColumn<HardwareConfigurationItem>> = [
      { id: 'icon', render: (item) => <EuiIcon type={item.iconType} />, width: '32px' },
      { id: 'title', render: (item) => item.title, width: '120px' },
      { id: 'description', render: (item) => item.description },
    ]

    const hardwareConfigurationItems: HardwareConfigurationItem[] = [
      {
        id: 'public_hostname',
        iconType: 'node',
        title: (
          <FormattedMessage id='runner-overview.hardware-host-name' defaultMessage='Host name' />
        ),
        description: public_hostname,
      },

      {
        id: 'host_ip',
        iconType: 'ip',
        title: (
          <FormattedMessage id='runner-overview.hardware-ip-address' defaultMessage='IP address' />
        ),
        description: host_ip,
      },
    ]

    return (
      <CuiTable<HardwareConfigurationItem>
        getRowId={(item) => item.id}
        columns={columns}
        rows={hardwareConfigurationItems}
        hasHeaderRow={false}
      />
    )
  }

  renderContainers() {
    const { regionId, runner, containerSetsFeature } = this.props
    const { containers } = runner!
    const hostContainers = sortBy(containers, `container_name`)

    if (isEmpty(hostContainers)) {
      return (
        <FormattedMessage
          id='runner-overview.host-has-no-containers'
          defaultMessage='This host has no containers.'
        />
      )
    }

    return (
      <EuiFlexGroup wrap={true} gutterSize='s'>
        {hostContainers.map(({ container_name, container_set_name }) => (
          <EuiFlexItem key={container_name} grow={false}>
            <EuiBadge color='hollow'>
              {containerSetsFeature ? (
                <CuiLink to={containerUrl(regionId, container_set_name, container_name)}>
                  {container_name}
                </CuiLink>
              ) : (
                container_name
              )}
            </EuiBadge>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    )
  }

  renderContainerSets() {
    const { regionId, runner, containerSetsFeature } = this.props
    const { containers } = runner!
    const hostContainerSetNames = uniq(
      containers.map((container) => container.container_set_name),
    ).sort()

    if (isEmpty(hostContainerSetNames)) {
      return (
        <FormattedMessage
          id='runner-overview.host-has-no-container-sets'
          defaultMessage='This host has no containers sets.'
        />
      )
    }

    return (
      <EuiFlexGroup wrap={true} gutterSize='s'>
        {hostContainerSetNames.map((containerSetName) => (
          <EuiFlexItem key={containerSetName} grow={false}>
            <EuiBadge color='hollow'>
              {containerSetsFeature ? (
                <CuiLink to={containerSetUrl(regionId, containerSetName)}>
                  {containerSetName}
                </CuiLink>
              ) : (
                containerSetName
              )}
            </EuiBadge>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    )
  }

  renderHostRoles() {
    const { regionId, hostId, runner } = this.props
    const { roles } = runner!

    if (isEmpty(roles)) {
      return (
        <FormattedMessage
          id='runner-overview.host-has-no-roles'
          defaultMessage='This host has no roles.'
        />
      )
    }

    const hostRoles = orderBy(
      roles.map(({ role_name: id }) => ({
        id,
        namedRole: getNamedRole(id),
      })),
      [
        (role) => (role.namedRole ? 0 : 1),
        (role) => (role.namedRole ? role.namedRole.label : role.id),
      ],
    )

    return (
      <EuiFlexGroup wrap={true} gutterSize='s'>
        {hostRoles.map(({ id, namedRole }) => (
          <EuiFlexItem key={id} grow={false}>
            <EuiBadge color='hollow' data-test-id='runner-role-tags'>
              {namedRole ? (
                <CuiLink to={namedRole.getUrl(regionId, hostId)}>{namedRole.label}</CuiLink>
              ) : (
                id
              )}
            </EuiBadge>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    )
  }

  renderHostTags() {
    const { runner } = this.props
    const { tags = {} } = runner!
    const hostTags = sortBy(Object.keys(tags))

    if (isEmpty(hostTags)) {
      return (
        <FormattedMessage
          id='runner-overview.host-has-no-tags'
          defaultMessage='This host has no tags.'
        />
      )
    }

    return (
      <EuiFlexGroup wrap={true} gutterSize='s'>
        {hostTags.map((tag, index) => (
          <EuiFlexItem key={index} grow={false}>
            <EuiFlexGroup responsive={false} gutterSize='s' alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiBadge color='hollow'>
                  <FormattedMessage
                    id='runner-overview.tag-value'
                    defaultMessage='{ key }: { value }'
                    values={{
                      key: tag,
                      value: tags[tag],
                    }}
                  />
                </EuiBadge>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <CopyButton size='s' value={tags[tag]} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    )
  }

  renderHostFeatures() {
    const { runner } = this.props
    const { features = {} } = runner!
    const hostFeatures = Object.keys(features).filter((feature) => features[feature])

    if (isEmpty(hostFeatures)) {
      return (
        <FormattedMessage
          id='runner-overview.host-has-no-features'
          defaultMessage='This host has no special features.'
        />
      )
    }

    return (
      <EuiFlexGroup wrap={true} gutterSize='s'>
        {hostFeatures.filter(Boolean).map((feature) => (
          <EuiFlexItem key={feature} grow={false}>
            <EuiBadge color='hollow'>{feature}</EuiBadge>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    )
  }

  renderHostComments() {
    const { regionId, hostId } = this.props

    return <ResourceComments resourceType='runner' regionId={regionId} resourceId={hostId} />
  }
}

export default RunnerOverview
