/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { Allocator } from '@modules/ui-types'

import { deleteAllocator } from '../../../../actions/allocators'
import { getDeleteAllocatorInformation } from '../../../../reducers'

import DeleteAllocatorModal from './DeleteAllocatorModal'

import type { ThunkDispatch, ReduxState, DeleteAllocatorState } from '@/types/redux'

type StateProps = {
  deleteAllocatorInformation?: DeleteAllocatorState
}

type DispatchProps = {
  deleteAllocator: (removeInstances: boolean) => void
}

type ConsumerProps = {
  allocator: Allocator
  close: () => void
}

const mapStateToProps = (
  state: ReduxState,
  { allocator: { regionId, id } }: ConsumerProps,
): StateProps => ({
  deleteAllocatorInformation: getDeleteAllocatorInformation(state, regionId, id),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { allocator: { regionId, id } }: ConsumerProps,
): DispatchProps => ({
  deleteAllocator: (removeInstances: boolean) =>
    dispatch(deleteAllocator(regionId, id, removeInstances)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteAllocatorModal)
