/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { Post, Event as UpcomingEvent } from '@modules/cloud-api/v1/types'

import { AsyncAction } from '@/types/redux'

import { FETCH_BLOGS, FETCH_UPCOMING_EVENTS } from '../../constants/actions'

export type State = {
  posts: Post[]
  upcomingEvents: UpcomingEvent[]
}

const initialState: State = {
  posts: [],
  upcomingEvents: [],
}

type FetchBlogsAction = AsyncAction<typeof FETCH_BLOGS, Post[]>
type FetchUpcomingEventsAction = AsyncAction<typeof FETCH_UPCOMING_EVENTS, any[]>

type Action = FetchBlogsAction | FetchUpcomingEventsAction

export default function cloudStatusReducer(state: State = initialState, action: Action): State {
  if (action.type === FETCH_BLOGS) {
    if (!action.error && action.payload) {
      return {
        ...state,
        posts: action.payload,
      }
    }
  }

  if (action.type === FETCH_UPCOMING_EVENTS) {
    if (!action.error && action.payload) {
      return {
        ...state,
        upcomingEvents: action.payload,
      }
    }
  }

  return state
}

export function getBlogs(state: State): Post[] {
  return state.posts
}

export function getUpcomingEvents(state: State): UpcomingEvent[] {
  return state.upcomingEvents
}
