/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { LoggingSettings } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, LoggerLevel } from '@modules/ui-types'

import ZkLoggingSettingsButton from '../ZkLoggingSettingsButton'
import {
  fetchAllocatorLoggingSettings,
  patchAllocatorLoggingSettings,
} from '../../actions/allocators'
import {
  getAllocatorLoggingSettings,
  fetchAllocatorLoggingSettingsRequest,
  patchAllocatorLoggingSettingsRequest,
} from '../../reducers'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

type StateProps = {
  loggingSettings: LoggingSettings | null
  fetchLoggingSettingsRequest: AsyncRequestState
  patchLoggingSettingsRequest: AsyncRequestState
}

type DispatchProps = {
  fetchLoggingSettings: () => void
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) => Promise<void>
}

type ConsumerProps = {
  regionId: string
  allocatorId: string
}

const mapStateToProps = (state, { regionId, allocatorId }: ConsumerProps): StateProps => ({
  loggingSettings: getAllocatorLoggingSettings(state, regionId, allocatorId),
  fetchLoggingSettingsRequest: fetchAllocatorLoggingSettingsRequest(state, regionId, allocatorId),
  patchLoggingSettingsRequest: patchAllocatorLoggingSettingsRequest(state, regionId, allocatorId),
})

const mapDispatchToProps = (dispatch, { regionId, allocatorId }: ConsumerProps): DispatchProps => ({
  fetchLoggingSettings: () => dispatch(fetchAllocatorLoggingSettings({ regionId, allocatorId })),
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) =>
    dispatch(patchAllocatorLoggingSettings({ regionId, allocatorId, ...settings })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ZkLoggingSettingsButton)
