/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getDeploymentResourceProxyRequestsUrl } from '@modules/cloud-api/v1/urls'
import type { StackDeployment } from '@modules/ui-types'

import { getFirstEsRefId } from '@/lib/stackDeployments/selectors/clusters'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { RETRY_FAILED_SHARD_ALLOCATIONS } from '../../constants/actions'

export function retryFailedShardAllocations(deployment: StackDeployment) {
  const { id: deploymentId } = deployment

  const proxyPath = `_cluster/reroute?retry_failed`
  const refId = getFirstEsRefId({ deployment })
  const url = getDeploymentResourceProxyRequestsUrl({
    deploymentId,
    resourceKind: `elasticsearch`,
    refId,
    proxyPath,
  })

  return asyncRequest({
    type: RETRY_FAILED_SHARD_ALLOCATIONS,
    method: `POST`,
    url,
    meta: { deploymentId },
    crumbs: [deploymentId],
    requestSettings: {
      request: {
        headers: {
          'X-Management-Request': true,
        },
      },
    },
  })
}

export const resetRetryFailedShardAllocationsRequest = (...crumbs: string[]) =>
  resetAsyncRequest(RETRY_FAILED_SHARD_ALLOCATIONS, crumbs)
