/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { KibanaResourceInfo } from '@modules/cloud-api/v1/types'
import type { Cluster, StackDeployment } from '@modules/ui-types'

import ExternalLink from '../../../components/ExternalLink'
import { getFirstSliderClusterFromGet } from '../../../lib/stackDeployments/selectors'
import { kibanaSecurityManagementUrl } from '../../../lib/serviceProviderDeepLinks'

import type { FunctionComponent } from 'react'

type Props = {
  deployment: StackDeployment
  cluster: Cluster
}

const KibanaSecurityLink: FunctionComponent<Props> = ({ deployment }) => {
  const resource = getFirstSliderClusterFromGet<KibanaResourceInfo>({
    deployment,
    sliderInstanceType: `kibana`,
  })

  return (
    <FormattedMessage
      id='kibana-security-link.make-changes'
      defaultMessage='Make security changes in {kibana}.'
      values={{
        kibana: (
          <ExternalLink
            href={kibanaSecurityManagementUrl({ resource })}
            data-test-id='kibana-security-link'
          >
            <FormattedMessage id='kibana-security-link.kibana-label' defaultMessage='Kibana' />
          </ExternalLink>
        ),
      }}
    />
  )
}

export default KibanaSecurityLink
