/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'

import {
  EuiDescribedFormGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageHeader,
  EuiSkeletonText,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import type { ProjectID } from '@modules/project-api/v1/types'
import { getGetProjectQueryHook } from '@modules/project-lib/hooks/get'
import { projectCrumbs } from '@modules/project-lib/breadcrumbs'
import { CuiAlert } from '@modules/cui'
import type { ProjectType } from '@modules/ui-types/projects'
import type { AnyApplicationKey } from '@modules/project-api/types'
import CopyButton from '@modules/cui/CopyButton'
import { BreadcrumbsContext } from '@modules/cui/Breadcrumbs'
import Breadcrumbs from '@modules/cui/Breadcrumbs/Breadcrumbs'
import { getDisplayProjectId } from '@modules/project-lib/display'

import { getOnlyRegionId, getPrettyCloudProvider, getPrettyProjectType } from '../helpers/pretty'
import genericMessages from '../helpers/genericMessages'

import EditableProjectName from './EditableProjectName'
import OpenProjectButton from './OpenProjectButton'
import DeleteProjectButton from './DeleteProjectButton'

const APPLICATIONS: AnyApplicationKey[] = ['elasticsearch', 'kibana', 'fleet']

const ProjectPage: React.FunctionComponent = () => {
  const { projectType, projectId } = useParams<{ projectType: ProjectType; projectId: ProjectID }>()
  const query = getGetProjectQueryHook(projectType)(projectId)

  const headerRightSideItems = query.data
    ? [<OpenProjectButton project={query.data} />, <DeleteProjectButton project={query.data} />]
    : []

  return (
    <div>
      <BreadcrumbsContext.Consumer>
        {({ breadcrumbsRef }) => (
          <Breadcrumbs
            breadcrumbsRef={breadcrumbsRef}
            breadcrumbs={projectCrumbs({
              projectType,
              projectId,
              projectName: query.data?.name,
            })}
          />
        )}
      </BreadcrumbsContext.Consumer>

      <EuiPageHeader
        bottomBorder={true}
        pageTitle={query.data?.name || getDisplayProjectId(projectId)}
        rightSideGroupProps={{ gutterSize: 'm' }}
        rightSideItems={headerRightSideItems}
      />

      <EuiSpacer />

      {renderContent()}
    </div>
  )

  function renderContent() {
    if (query.error) {
      return <CuiAlert type='danger'>{query.error}</CuiAlert>
    }

    if (query.isLoading) {
      return <EuiSkeletonText />
    }

    if (!query.isSuccess) {
      return null // discriminate
    }

    const { data } = query

    return (
      <div>
        <EuiTitle size='s'>
          <h2>
            <FormattedMessage
              id='project.heading.information'
              defaultMessage='Project information'
            />
          </h2>
        </EuiTitle>
        <EuiSpacer size='l' />
        <EuiDescribedFormGroup
          ratio='quarter'
          fullWidth={true}
          titleSize='xxs'
          title={
            <strong>
              <FormattedMessage id='project.label.name' defaultMessage='Project name' />
            </strong>
          }
        >
          <EditableProjectName project={data} />
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          ratio='quarter'
          fullWidth={true}
          titleSize='xxs'
          title={
            <strong>
              <FormattedMessage id='project.label.type' defaultMessage='Solution' />
            </strong>
          }
        >
          <FormattedMessage {...getPrettyProjectType(data.type)} />
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          ratio='quarter'
          fullWidth={true}
          titleSize='xxs'
          title={
            <strong>
              <FormattedMessage
                id='project.label.provider'
                defaultMessage='Cloud provider/region'
              />
            </strong>
          }
        >
          <span>
            <FormattedMessage {...getPrettyCloudProvider(data.region_id)} /> –{' '}
            {getOnlyRegionId(data.region_id)}
          </span>
        </EuiDescribedFormGroup>

        <EuiSpacer size='l' />

        <EuiTitle size='s'>
          <h2>
            <FormattedMessage id='project.heading.applications' defaultMessage='Applications' />
          </h2>
        </EuiTitle>
        <EuiSpacer size='l' />
        <EuiDescribedFormGroup
          ratio='quarter'
          fullWidth={true}
          titleSize='xxs'
          title={
            <strong>
              <FormattedMessage id='project.label.endpoints' defaultMessage='Endpoints' />
            </strong>
          }
        >
          {data.endpoints && (
            <EuiFlexGroup
              gutterSize='m'
              // manually gridding this since EuiFlexGrid insists on uniform width
              style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}
            >
              {APPLICATIONS.map(
                (application) =>
                  data.endpoints?.[application] && (
                    <Fragment key={application}>
                      <EuiFlexItem grow={false} style={{ marginRight: '1rem' }}>
                        <FormattedMessage {...genericMessages[application]} />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <CopyButton asLink={true} value={data.endpoints[application]}>
                          <FormattedMessage
                            id='project.copyEndpoint'
                            defaultMessage='Copy endpoint'
                          />
                        </CopyButton>
                      </EuiFlexItem>
                    </Fragment>
                  ),
              )}
            </EuiFlexGroup>
          )}
        </EuiDescribedFormGroup>
      </div>
    )
  }
}

export default ProjectPage
