/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { StackDeployment } from '@modules/ui-types'

import Monitoring from '../../../../components/StackDeployments/Monitoring'
import { getFirstSliderClusterFromGet } from '../../../../lib/stackDeployments/selectors'

import type { FunctionComponent } from 'react'

type Props = {
  deployment: StackDeployment
}

const LogsMetrics: FunctionComponent<Props> = ({ deployment }) => {
  if (!deployment) {
    return null
  }

  const cluster = getFirstSliderClusterFromGet({
    deployment,
    sliderInstanceType: `elasticsearch`,
  })

  if (!cluster) {
    return null
  }

  return (
    <div>
      <Monitoring
        deployment={deployment}
        deploymentId={deployment.id}
        regionId={cluster.region}
        clusterId={cluster.id}
      />
    </div>
  )
}

export default LogsMetrics
