/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { CuiTable, CuiLink } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import { deploymentUrl } from '@/lib/urlBuilder'

import messages from '../messages'

import type { DeploymentRow } from '@/components/Users/RoleAssignmentsPanel/SpecificDeploymentsRoles/types'
import type { WrappedComponentProps } from 'react-intl'

export type Props = {
  organizationId: string
  isLoading: boolean
  deploymentRows: DeploymentRow[]
} & WrappedComponentProps

class PermissionsDeploymentsTable extends Component<Props> {
  render() {
    return (
      <CuiTable<DeploymentRow>
        initialLoading={this.props.isLoading}
        pageSize={7}
        rows={this.props.deploymentRows}
        getRowId={({ id }) => id}
        columns={this.getColumns()}
        totalCount={this.props.deploymentRows.length}
      />
    )
  }

  getColumns(): Array<CuiTableColumn<DeploymentRow>> {
    const {
      intl: { formatMessage },
    } = this.props

    return [
      {
        label: (
          <FormattedMessage
            id='roles-assignments.deployment-access.specific-deployment-name-assigned'
            defaultMessage='Deployment name'
          />
        ),
        align: 'left',
        render: ({ id, name }) => (
          <CuiLink
            to={deploymentUrl(id)}
            title={name}
            css={css({
              display: '-webkit-box', // needed in combination with lineClamp for multiline ellipsis
              textOverflow: 'ellipsis',
              lineHeight: '2rem',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            })}
          >
            {name}
          </CuiLink>
        ),
        truncateText: false,
        width: '50%',
      },
      {
        label: (
          <FormattedMessage
            id='roles-assignments.deployment-access.specific-deployment-assigned-role'
            defaultMessage='Role'
          />
        ),
        align: 'left',
        render: ({ roleId }) => formatMessage(messages.label[roleId]),
        truncateText: false,
        width: '50%',
      },
    ]
  }
}

export default injectIntl(PermissionsDeploymentsTable)
