/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import { CuiPermissibleControl, CuiAlert } from '@modules/cui'

import SpinButton from '../../../SpinButton'

import type { AllProps } from './types'

interface State {
  showSuccess: boolean
  buttonDisabled: boolean
}

class TakeSnapshotButton extends Component<AllProps> {
  state: State = {
    buttonDisabled: false,
    showSuccess: false,
  }

  componentWillUnmount() {
    this.props.resetTakeSnapshotRequest()
  }

  render() {
    const {
      takeSnapshot,
      takeSnapshotRequest,
      children,
      onSnapshotTaken,
      fill = true,
      handleStatus,
      ...rest
    } = this.props

    /*
      Quick explanation about `handleStatus`. There are currently (October, 2022) 3 callers of <TakeSnapshotButton />, 2 of which are for older versions of ES:
      pre-SLM and migrating from App Search to Ent Search. Both of those are handling "status" on their own, but I felt it weird to handle the local status
      of a component outside of the component. By "status" I mean disabling the snapshot button and showing a success message.

      The 3rd caller is for SLM enabled deployments, and there was zero handling of status there. So it was a bit of a cluster.

      The code now has an optional `handleStatus` flag passed here which tells this component to handle its own success and disabling. I have _not_ re-written the
      code for the 2 legacy callers becasue we're just going to delete that code once we can get rid of 6.x anyways. And once we do that, we can kill off the
      optional `handleStatus` flag here and just alwasy make it true.
    */

    return (
      <CuiPermissibleControl permissions={Permission.postDeploymentResourceProxyRequests}>
        <Fragment>
          <SpinButton
            data-test-id='es-snapshot-take-now-btn'
            fill={fill}
            onClick={() => {
              takeSnapshot().then(({ payload }) => {
                if (onSnapshotTaken) {
                  // SLM response is `snapshot_name`; legacy is just `name`.
                  onSnapshotTaken({ snapshotName: payload.snapshot_name || payload.name })
                }

                if (handleStatus) {
                  this.setState({ showSuccess: true, buttonDisabled: true })
                  setTimeout(() => {
                    this.setState({
                      showSuccess: false,
                      buttonDisabled: false,
                    })
                  }, 15000)
                }
              })
            }}
            disabled={this.state.buttonDisabled}
            spin={takeSnapshotRequest.inProgress}
            {...rest}
          >
            {children || (
              <FormattedMessage id='take-snapshot-button' defaultMessage='Take snapshot now' />
            )}
          </SpinButton>
          {this.state.showSuccess && handleStatus && (
            <Fragment>
              <EuiSpacer />
              <CuiAlert type='success'>
                <FormattedMessage
                  id='take-snapshot-button-success'
                  defaultMessage='Elasticsearch is now attempting to take a snapshot. Please wait a bit before
          trying to take another one.'
                />
              </CuiAlert>
            </Fragment>
          )}
        </Fragment>
      </CuiPermissibleControl>
    )
  }
}

export default TakeSnapshotButton
