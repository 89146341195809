/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

import type { RoutableBreadcrumb } from '@modules/ui-types'
import { portalUrl } from '@modules/utils/rootUrls'

const messages = defineMessages({
  elasticCloud: {
    id: 'portal.crumbs.elasticCloud',
    defaultMessage: 'Cloud',
  },
  userSettings: {
    id: 'portal.crumbs.userSettings',
    defaultMessage: 'User profile',
  },
})

const rootPortalCrumbs = (): RoutableBreadcrumb[] => [
  {
    text: <FormattedMessage {...messages.elasticCloud} />,
  },
]

const rootEssCloudCrumbs = (): RoutableBreadcrumb[] => [
  {
    text: <FormattedMessage {...messages.elasticCloud} />,
  },
]

const portalCrumbs = (): RoutableBreadcrumb[] => [
  {
    to: portalUrl(),
    text: <FormattedMessage {...messages.elasticCloud} />,
  },
]

const rootEceCrumbs = (): RoutableBreadcrumb[] => [
  {
    text: (
      <FormattedMessage
        id='crumb-builder.admin-console-ece'
        defaultMessage='Elastic Cloud Enterprise'
      />
    ),
  },
]

const rootEssCrumbs = (): RoutableBreadcrumb[] => [
  {
    text: (
      <FormattedMessage id='crumb-builder.admin-console' defaultMessage='Elasticsearch Service' />
    ),
  },
]

export function getRootByApp({
  linkRoot,
  appName,
  appType,
  cloudPortalEnabled,
}: {
  linkRoot?: boolean
  appName: string
  appType: string
  cloudPortalEnabled: boolean
}): RoutableBreadcrumb[] {
  if (appName === `userconsole`) {
    if (cloudPortalEnabled) {
      return linkRoot ? portalCrumbs() : rootPortalCrumbs()
    }

    return rootEssCloudCrumbs()
  }

  if (appType === `cloud-enterprise-adminconsole`) {
    return rootEceCrumbs()
  }

  return rootEssCrumbs()
}
