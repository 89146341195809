/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiTabbedContentTab } from '@elastic/eui'
import { EuiSkeletonText, EuiSpacer, EuiTabbedContent } from '@elastic/eui'

import { CuiAlert } from '@modules/cui'
import history from '@modules/utils/history'
import Header from '@modules/cui/Header'

import {
  organizationBillingHistoryUrl,
  organizationBillingOverviewUrl,
  organizationDeploymentsUrl,
  organizationOverviewUrl,
  organizationUsageUrl,
  organizationMembersUrl,
  organizationBillingSubscriptionUrl,
} from '@/lib/urlBuilder'
import { viewOrganizationCrumbs } from '@/lib/crumbBuilder'
import { saasOrganizationToUserProfile } from '@/lib/profile'
import BillingOverview from '@/components/User/BillingOverviewV2'
import { isPrepaidConsumptionDirectCustomer } from '@/lib/billingDetails'

import BillingUsage from '../User/BillingUsage'
import BillingHistory from '../User/BillingHistory'
import BillingSubscription from '../User/BillingSubscription'

import OrganizationOverview from './OrganizationOverview'
import OrganizationDeployments from './OrganizationDeployments'
import OrganizationMembers from './OrganizationMembers'

import type { AllProps as Props } from './types'
import type { ReactElement } from 'react'

type OrganizationTab = EuiTabbedContentTab & { route: string }

class Organization extends Component<Props> {
  componentDidMount(): void {
    const {
      fetchSaasOrganization,
      fetchOrganizationMembers,
      fetchOrganizationInvites,
      saasOrganization,
      lookupSaasUsers,
    } = this.props

    if (lookupSaasUsers && !saasOrganization) {
      fetchSaasOrganization()
      fetchOrganizationMembers()
      fetchOrganizationInvites()
    }
  }

  render(): ReactElement | null {
    const {
      fetchSaasOrganizationRequest: { error },
      saasOrganization,
      searchResults,
      selectedTabId,
      lookupSaasUsers,
      organizationMemberRows,
      fetchOrganizationInvitationsRequest: {
        error: fetchInvitationsError,
        inProgress: isFetchingInvitations,
      },
      fetchOrganizationMembersRequest: { error: fetchMembersError, inProgress: isFetchingMembers },
    } = this.props

    const isLoading = isFetchingInvitations || isFetchingMembers

    const organizationError = fetchInvitationsError || fetchMembersError

    if (!lookupSaasUsers) {
      return null
    }

    if (error) {
      return <CuiAlert type='error'>{error}</CuiAlert>
    }

    if (!saasOrganization) {
      return <EuiSkeletonText />
    }

    const deploymentCount = searchResults ? searchResults.match_count : undefined

    const {
      organization: { organization_id: organizationId, organization_name: organizationName },
    } = saasOrganization

    const profile = saasOrganizationToUserProfile(saasOrganization)
    const organization = { id: organizationId, name: organizationName }

    const tabs: OrganizationTab[] = [
      {
        id: 'overview',
        name: (
          <FormattedMessage
            id='organizations.organization.overview-tab'
            defaultMessage='Overview'
          />
        ),
        route: organizationOverviewUrl(organizationId),
        content: (
          <Fragment>
            <EuiSpacer size='xxl' />

            <OrganizationOverview saasOrganization={saasOrganization} />
          </Fragment>
        ),
      },
      {
        id: 'members',
        name: (
          <FormattedMessage id='organizations.organization.members-tab' defaultMessage='Members' />
        ),
        route: organizationMembersUrl(organizationId),
        content: (
          <Fragment>
            <EuiSpacer size='xxl' />
            <OrganizationMembers
              organizationId={organizationId}
              organization={organization}
              organizationMemberRows={organizationMemberRows}
              isLoading={isLoading}
              error={organizationError}
            />
          </Fragment>
        ),
      },
      {
        id: 'deployments',
        name: (
          <Fragment>
            <FormattedMessage
              id='organizations.organization.deployments-tab'
              defaultMessage='Deployments'
            />

            {deploymentCount !== undefined && <span> ({deploymentCount})</span>}
          </Fragment>
        ),
        route: organizationDeploymentsUrl(organizationId),
        content: (
          <Fragment>
            <EuiSpacer size='xxl' />

            <OrganizationDeployments organizationId={organizationId} />
          </Fragment>
        ),
      },
      {
        id: 'billing-overview',
        name: (
          <FormattedMessage
            id='organizations.organization.billing-overview-tab'
            defaultMessage='Billing overview'
          />
        ),
        route: organizationBillingOverviewUrl(organizationId),
        content: (
          <Fragment>
            <EuiSpacer size='xxl' />

            <BillingOverview organizationId={organizationId} />
          </Fragment>
        ),
      },
      {
        id: 'usage',
        name: <FormattedMessage id='organizations.organization.usage-tab' defaultMessage='Usage' />,
        route: organizationUsageUrl(organizationId),
        content: (
          <Fragment>
            <EuiSpacer size='xxl' />

            <BillingUsage organizationId={organizationId} />
          </Fragment>
        ),
      },
      {
        id: 'billing-history',
        name: (
          <FormattedMessage
            id='organizations.organization.billing-history-tab'
            defaultMessage='Billing history'
          />
        ),
        route: organizationBillingHistoryUrl(organizationId),
        content: (
          <Fragment>
            <EuiSpacer size='xxl' />

            <BillingHistory profile={profile} />
          </Fragment>
        ),
      },
    ]

    if (this.shouldShowBillingSubscriptionTab(selectedTabId)) {
      tabs.push({
        id: 'billing-subscription',
        name: (
          <FormattedMessage
            id='organizations.organization.billing-subscription-tab'
            defaultMessage='Billing subscription'
          />
        ),
        content: (
          <Fragment>
            <EuiSpacer size='xxl' />

            <BillingSubscription
              redirectToWhenDisabled={organizationOverviewUrl(organizationId)}
              organizationId={organizationId}
            />
          </Fragment>
        ),
        route: organizationBillingSubscriptionUrl(organizationId),
      })
    }

    const selected = tabs.find((tab) => tab.id === selectedTabId) || tabs[0]

    return (
      <Fragment>
        <Header
          data-test-id='organizations.organization.header'
          name={organizationName}
          breadcrumbs={viewOrganizationCrumbs(organizationId, organizationName)}
          bottomBorder={false}
        />

        <EuiTabbedContent
          tabs={tabs}
          selectedTab={selected}
          onTabClick={(selectedTab: OrganizationTab) => history.replace(selectedTab.route)}
        />

        <EuiSpacer size='xl' />
      </Fragment>
    )
  }

  shouldShowBillingSubscriptionTab(currentTabId: string | null) {
    const { showPrepaidConsumptionUI, billingDetails } = this.props

    // if the new UI is not enabled, this tab should never be shown no matter what
    // this is already covered by the router, but this is an extra safety check.
    if (!showPrepaidConsumptionUI) {
      return false
    }

    // if the tab is currently selected for whatever reason we have no
    // other option but to show it. Child components might perform a
    // redirect if the user shouldn't see this tab.
    if (currentTabId === 'billing-subscription') {
      return true
    }

    // for now, only show the tab to prepaid consumption users, even if the flag is enabled
    if (billingDetails.data && isPrepaidConsumptionDirectCustomer(billingDetails.data)) {
      return true
    }

    return false
  }
}

export default Organization
