/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { keys, union, map, every, some } from 'lodash'

import type { CombinedPlatformData, NodeType } from './infrastructureVisualizationTypes'

export const roleColors = {
  named: {
    runner: `primary`,
    allocator: `success`,
    coordinator: `warning`,
    proxy: `accent`,
  },
  light: {
    runner: `#8ecce3`,
    allocator: `#72dad2`,
    coordinator: `#ddb17c`,
    proxy: `#edc6d9`,
  },
  dark: {
    runner: `#ebedee`,
    allocator: `#72dad2`,
    coordinator: `#c6b3ac`,
    proxy: `#edc6d9`,
  },
}

/**
  For the legend we want to show the existing node types for this particular region. This becomes a little bit more
  complex because while every node is a runner, we only want to show the runner color if that node isn't also something
  else. An allocator is technically a runner and an allocator, but we only want to show the allocator color. But if
  something is purely a runner then we need to show the runner color in the legend.
*/
export function getZoneRunnerRoles(zone: CombinedPlatformData[]): NodeType[] {
  const roles = union(...map(zone, getRunnerRoles))
  const hasDedicatedRunner = some(roles, isRunner)

  if (hasDedicatedRunner) {
    return roles.sort((a, b) => {
      if (a === 'runner' && b !== 'runner') {
        return 1
      }

      if (a !== 'runner' && b === 'runner') {
        return -1
      }

      return a.localeCompare(b)
    })
  }

  return roles.filter((r) => r !== 'runner')
}

export function getRunnerRoles(runner: CombinedPlatformData): NodeType[] {
  const roles = keys(runner.types) as NodeType[]
  const isDedicatedRunner = every(roles, isRunner)

  // If every role is 'runner', just return that
  if (isDedicatedRunner) {
    return ['runner']
  }

  // Otherwise filter out runner, because only the other roles are interesting
  return roles.filter((r) => r !== 'runner')
}

function isRunner(role: NodeType) {
  return role === `runner`
}
