/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isInteger as lodashIsInteger, mapValues, startsWith } from 'lodash'
import moment from 'moment'

import type {
  ClusterInstanceInfo,
  ClusterMetadataInfo,
  ClusterTopologyInfo,
  DeploymentGetResponse,
  ElasticsearchClusterInfo,
  ElasticsearchClusterPlansInfo,
  ElasticsearchClusterSecurityInfo,
  ElasticsearchMasterInfo,
  SnapshotStatusInfo,
} from '@modules/cloud-api/v1/types'
import type {
  ElasticsearchCluster,
  ElasticsearchClusterInstance,
  ElasticsearchId,
  RegionId,
  Url,
} from '@modules/ui-types'

import { getFirstSliderClusterFromGet, getRegionId } from '@/lib/stackDeployments/selectors'

import stringify from '../../lib/stringify'
import { isTopologySized } from '../../lib/deployments/deployment'
import { isEmptyDeployment } from '../../lib/deployments/conversion'
import { parseWeirdApiTimeAsMs } from '../../lib/weirdTime'
import { getPlatform, getPlatformInfoById } from '../../lib/platform'
import { mapInstanceConfigurations } from '../../lib/stackDeployments'
import { fsMultiplierDefault } from '../../constants/fsMultiplier'
import { getConfigForKey } from '../../store'

import type { RawMetadata } from './clusterTypes'

function isAllowedName(name: string) {
  return name != null && name.length > 0
}

function getClusterName(id: string, name: string) {
  return isAllowedName(name) ? name : id
}

function getEvents(source: ElasticsearchClusterInfo) {
  const events = source.system_alerts ?? []

  return {
    slain: events,
  }
}

function getCloudId(metadata: ClusterMetadataInfo): string | undefined {
  return metadata.cloud_id
}

const toNumber = (str: string | number | undefined): number | null => {
  if (str == null) {
    return null
  }

  if (isInteger(str)) {
    return str
  }

  const stringToNumber = parseInt(str, 10)

  if (isNaN(stringToNumber)) {
    return null
  }

  return stringToNumber

  // isInteger (either lodash's or Number's) doesn't have a type predicate
  function isInteger(s: string | number): s is number {
    return lodashIsInteger(s)
  }
}

export const getInstanceNumber = (rawId: string) => {
  const idArray = rawId.split(`-`).pop() || ``
  return parseInt(idArray, 10)
}

export const createInstanceDisplayName = (rawId: string, withoutHash?: boolean) => {
  const id = getInstanceNumber(rawId)

  if (startsWith(rawId, `tiebreaker`)) {
    return withoutHash ? `Tiebreaker ${id}` : `Tiebreaker #${id}`
  }

  return withoutHash ? `Instance ${id}` : `Instance #${id}`
}

function createInstance({
  clusterId,
  masterInfo,
  instance,
}: {
  clusterId: string
  masterInfo: ElasticsearchMasterInfo
  instance: ClusterInstanceInfo
}) {
  const nodeTypes = (instance.service_roles || []).reduce<Record<string, boolean>>(
    (result, role) => {
      result[role] = true
      return result
    },
    {},
  )

  // This is an optional field in the response, since for pending plans an instance
  // may not be available yet
  const instanceCapacity = instance?.memory?.instance_capacity ?? 0
  const instanceCapacityPlanned = instance?.memory?.instance_capacity_planned ?? 0

  // Check the list of masters to see if this instance is acting as a master
  const isMaster = masterInfo.masters.some(
    (master) => master.master_instance_name === instance.instance_name,
  )

  const elasticsearchClusterInstance: ElasticsearchClusterInstance = {
    kind: `elasticsearch`,
    clusterId,
    name: instance.instance_name,
    displayName: createInstanceDisplayName(instance.instance_name),
    capacity: {
      memory: instanceCapacity,
      memoryPlanned: instanceCapacityPlanned,
      storage: toNumber(instance?.disk?.disk_space_available),
    },
    storageMultiplier: toNumber(instance.disk?.storage_multiplier),
    status: {
      inMaintenanceMode: instance.maintenance_mode,
      isStarted: instance.container_started,
      isRunning: instance.service_running,
      diskSpaceUsed: toNumber(instance.disk?.disk_space_used),
      oldGenFillPercentage: toNumber(instance.memory?.memory_pressure),
      nativeFillPercentage: toNumber(instance.memory?.native_memory_pressure),
    },
    allocator: {
      id: instance.allocator_id ?? '',
      zone: instance.zone ?? '',
    },
    elasticsearch: {
      version: instance.service_version ?? '',
      nodeTypes,
    },
    serviceRoles: instance.service_roles,
    isMaster,
    instanceConfig: {
      id: instance.instance_configuration?.id ?? '',
      name: instance.instance_configuration?.name ?? '',
      resource: instance.instance_configuration?.resource ?? 'memory',
    },
  }

  return elasticsearchClusterInstance
}

function createUser(user: { valid_until: string }, username: string) {
  return {
    username,
    validUntil: user.valid_until,
  }
}

function createSecurityConfig(
  config: ElasticsearchClusterSecurityInfo,
  version: number | undefined,
) {
  const users = config.users ?? []
  const rolesPerUser = config.users_roles ?? []

  const mappedUsers: Record<string, string> = {}

  users.forEach((user) => {
    mappedUsers[user.username] = user.password_hash
  })

  const usersPerRole: Record<string, string[]> = {}

  rolesPerUser.forEach(({ username, roles }) => {
    roles.forEach((role) => {
      if (usersPerRole[role] == null) {
        usersPerRole[role] = []
      }

      usersPerRole[role].push(username)
    })
  })

  return {
    version,
    allowAnonymous: false, // `config.allow_anonymous` no longer exists, if it ever did
    roles: config.roles ?? {},
    users: mappedUsers,
    usersPerRole: mapValues(usersPerRole, (v: string[]) => v.join(`, `)),
  }
}

function createHrefs(selfUrlRaw: string) {
  const selfUrl = selfUrlRaw.replace(/\?.*/, ``) // strip any query params
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const oldApiUrl = useOldApi(selfUrl)

  const data = `${selfUrl}/metadata/raw`
  const comments = `${oldApiUrl}/comments`
  const comment = `${oldApiUrl}/comments/{commentId}`
  const plan = `${selfUrl}/plan`
  const planAttempts = `${selfUrl}/plan/activity?show_plan_defaults=true`
  const createKibana = `${selfUrl}/kibana`
  const clusterAcl = `${oldApiUrl}/acl?version={version}`
  const setMaintenanceMode = `${selfUrl}/instances/{instanceIds}/maintenance-mode/_{action}`
  const setInstanceStatus = `${selfUrl}/instances/{instanceIds}/_{action}`
  const resetPassword = `${oldApiUrl}/_reset_password`
  const cancelPlan = `${selfUrl}/plan/pending`
  const proxy = getProxy()

  return {
    data,
    comments,
    comment,
    plan,
    'cancel-plan': cancelPlan,
    'plan-attempts': planAttempts,
    'create-kibana': createKibana,
    'cluster-acl': clusterAcl,
    'set-maintenance-mode': setMaintenanceMode,
    'set-instance-status': setInstanceStatus,
    proxy,
    'reset-password': resetPassword,
  }

  function getProxy() {
    const baseProxyUrl = `${oldApiUrl}/proxy/_cluster`

    if (getConfigForKey(`APP_NAME`) !== `userconsole`) {
      return baseProxyUrl
    }

    const clusterPartsRegex = /regions\/([^/]+)\/clusters\/([^/]+)\/proxy\/_cluster$/

    return baseProxyUrl.replace(clusterPartsRegex, userconsoleProxyReplacer)

    function userconsoleProxyReplacer(_: string, regionId: string, clusterId: string) {
      return `clusters/${regionId}/${clusterId}/proxy`
    }
  }

  function useOldApi(link: string) {
    return link.replace(/api\/v1/, `api/v0.1`).replace(`clusters/elasticsearch`, `clusters`)
  }
}

function mapSnapshots(snapshots: SnapshotStatusInfo, data: RawMetadata | undefined) {
  const snapshotConfig = data?.snapshot || {}

  let snapshotsEnabled = true

  if (snapshotConfig.enabled != null) {
    snapshotsEnabled = snapshotConfig.enabled
  } else if (snapshotConfig.suspended != null) {
    snapshotsEnabled = Object.keys(snapshotConfig.suspended).length === 0
  }

  const hasRecentEnoughSuccess = getRecentSuccess()

  const nextSnapshotAt =
    snapshots.scheduled_time == null || snapshots.scheduled_time.match(/^1970-/)
      ? undefined
      : snapshots.scheduled_time

  const snapshotStatus = {
    enabled: snapshotsEnabled,
    healthy: snapshots.healthy,
    latest: {
      state: snapshots.latest_status,
      success: snapshots.latest_status === `SUCCESS`,
      time: snapshots.latest_end_time,
    },
    status: {
      currentStatusHealthy: snapshots.healthy,
      totalCount: snapshots.count,
      latestSuccessAt: snapshots.latest_successful_end_time,
      nextSnapshotAt,
      pendingInitialSnapshot:
        snapshots.latest_successful_end_time == null && snapshots.latest_end_time == null,
      hasRecentEnoughSuccess,
    },
    snapshotRepositoryId: data?.snapshot?.repository?.config?.repository_id,
  }

  return snapshotStatus

  function getRecentSuccess() {
    if (typeof snapshots.recent_success === `boolean`) {
      return snapshots.recent_success
    }

    // legacy code, scared of removing this, although fairly innocuous — @nico
    const snapshotInterval = snapshotConfig?.interval ?? 30 * 60 * 1000
    const recentEnoughFactor = snapshotConfig?.recent_enough_factor ?? 4
    const recentEnough = Date.now() - parseWeirdApiTimeAsMs(snapshotInterval) * recentEnoughFactor

    const recentSuccess =
      snapshots.latest_end_time != null && Date.parse(snapshots.latest_end_time) > recentEnough

    return recentSuccess
  }
}

function getAvailabilityZones(planInfo: ElasticsearchClusterPlansInfo) {
  const clusterTopology = planInfo.current?.plan?.cluster_topology

  if (clusterTopology) {
    const maxZoneCount = Math.max(...clusterTopology.map((t) => t.zone_count || 0))

    if (maxZoneCount > 0) {
      return maxZoneCount
    }
  }

  return Math.max(
    ...(planInfo.current?.plan?.cluster_topology.map((each) => each.zone_count ?? 0) || []),
  )
}

function mapPlan(
  planInfo: ElasticsearchClusterPlansInfo,
  { source }: { source: ElasticsearchClusterInfo },
) {
  let planAttemptId: string | undefined = undefined
  let planAttemptEndTime

  if (planInfo.current) {
    planAttemptId = planInfo.current.plan_attempt_id

    if (planInfo.current.attempt_end_time) {
      planAttemptEndTime = moment(planInfo.current.attempt_end_time).toDate()
    }
  } else if (planInfo.pending) {
    planAttemptId = planInfo.pending.plan_attempt_id
  }

  const currentVersion = planInfo.current?.plan?.elasticsearch.version
  const pendingPlan = planInfo.pending?.plan
  const pendingVersion = planInfo.pending?.plan?.elasticsearch.version
  const isPending = pendingVersion != null

  const planMessages = planInfo.pending?.plan_attempt_log ?? []

  const availabilityZones = getAvailabilityZones(planInfo)
  const isActive = currentVersion != null
  const initializing = isInitializing(source)
  const isCreating = initializing || (isPending && !isActive && !isEmptyDeployment(pendingPlan))

  return {
    isCreating,
    planAttemptId,
    planAttemptEndTime,
    version: currentVersion,
    availabilityZones,
    healthy: planInfo.healthy,
    isActive,
    isPending,
    waitingForPending: false,
    status: {
      failed: planMessages.filter((each) => each.status === `error`).length,
      messages: planMessages,
    },
    pending: {
      _source: stringify(planInfo.pending),
    },
  }
}

function mapSecurity(source: ElasticsearchClusterInfo) {
  const data = source.metadata.raw as RawMetadata | undefined

  const internalUsers = data?.shield?.found_users ?? {}
  const securityConfig = source.security ?? ({} as ElasticsearchClusterSecurityInfo)
  const aclVersion = source.security?.version

  return {
    isConfigured: data?.shielded ?? false,
    internalUsers: Object.keys(internalUsers).map((username) =>
      createUser(internalUsers[username], username),
    ),
    config: createSecurityConfig(securityConfig, aclVersion),
  }
}

function mapCuration(source: ElasticsearchClusterInfo) {
  return {
    plan: source.plan_info.current?.plan?.elasticsearch.curation ?? {},
    settings: source.settings?.curation ?? {},
  }
}

function mapInstances(
  clusterId: string,
  topology: ClusterTopologyInfo,
  planInfo: ElasticsearchClusterPlansInfo,
  masterInfo: ElasticsearchMasterInfo,
) {
  const instanceCapacity = planInfo.current?.plan?.cluster_topology?.[0]?.memory_per_node ?? 0
  const instanceCount = planInfo.current?.plan?.cluster_topology?.[0]?.node_count_per_zone ?? 0

  const { instances } = topology

  const runningInstances = instances.filter((instance) => instance.service_running).length
  const notRunningInstances = instances.filter((instance) => !instance.service_running).length

  return {
    healthy: topology.healthy,
    instanceCapacity,
    count: {
      expected: instanceCount,
      total: runningInstances + notRunningInstances,
      notRunning: notRunningInstances,
      running: runningInstances,
    },
    record: instances.map((instance) => createInstance({ clusterId, masterInfo, instance })),
  }
}

function isStopping(source: ElasticsearchClusterInfo) {
  if (!source.plan_info.pending) {
    return false
  }

  if (source.status === `stopping`) {
    return true
  }

  const clusterTopology = source.plan_info.pending.plan?.cluster_topology ?? []

  return !isTopologySized(clusterTopology)
}

function isRestarting(source: ElasticsearchClusterInfo) {
  return source.status === `restarting`
}

function isForceRestarting(planInfo: ElasticsearchClusterPlansInfo) {
  const reboot = planInfo.pending?.plan?.transient?.plan_configuration?.cluster_reboot
  return reboot === `forced`
}

function isInitializing(source: ElasticsearchClusterInfo) {
  return source.status === `initializing`
}

function getMonitoringInfo(
  elasticsearchMonitoringInfo: ElasticsearchClusterInfo['elasticsearch_monitoring_info'],
  regionId: string,
) {
  if (
    elasticsearchMonitoringInfo === undefined ||
    elasticsearchMonitoringInfo.destination_cluster_ids.length === 0
  ) {
    return {
      enabled: false,
      out: null,
    }
  }

  return {
    enabled: true,

    // we currently only support 1 destination cluster
    out: `${regionId}/${elasticsearchMonitoringInfo.destination_cluster_ids[0]}`,
  }
}

function getDeploymentTemplateId(planInfo: ElasticsearchClusterPlansInfo): string | undefined {
  let id = planInfo.pending?.plan?.deployment_template?.id

  if (!id) {
    id = planInfo.current?.plan?.deployment_template?.id
  }

  return id
}

function getRefId(stackDeployment?: DeploymentGetResponse) {
  if (!stackDeployment) {
    return `main-elasticsearch`
  }

  const esCluster = getFirstSliderClusterFromGet({
    deployment: stackDeployment,
    sliderInstanceType: `elasticsearch`,
  })

  if (!esCluster) {
    return `main-elasticsearch`
  }

  return esCluster.ref_id
}

export default function createCluster({
  regionId: consumerRegionId,
  clusterId,
  selfUrl,
  source,
  oldCluster,
  stackDeployment,
}: {
  regionId: RegionId
  clusterId: ElasticsearchId
  selfUrl: Url
  source: ElasticsearchClusterInfo
  oldCluster?: ElasticsearchCluster | null
  stackDeployment?: DeploymentGetResponse
}): ElasticsearchCluster {
  const {
    deployment_id,
    metadata,
    plan_info,
    cluster_name,
    elasticsearch,
    healthy,
    associated_kibana_clusters,
    associated_apm_clusters,
    snapshots,
    topology,
    external_links: externalLinks,
    elasticsearch_monitoring_info,
  } = source

  const regionId = stackDeployment ? getRegionId({ deployment: stackDeployment }) : consumerRegionId

  // N.B. there are no default metadata values at the moment - see #5358
  const data = ((metadata.raw as unknown) ?? {}) as RawMetadata

  const metadataSettings = source.settings?.metadata ?? {}

  const {
    healthy: shards_healthy,
    shards_status,
    master_info,
    cluster_blocking_issues,
  } = elasticsearch
  const { instances } = topology

  const kibana = associated_kibana_clusters?.[0]
  const apm = associated_apm_clusters?.[0]

  const proxyLogging = data?.proxy?.logging ?? true

  const userLevel = data.user_level

  // persist deleted flag to avoid a deleted cluster showing up again on a scheduled re-fetch
  const wasDeleted = oldCluster != null && oldCluster.wasDeleted === true

  const isStopped = instances.length === 0
  const platformId = getPlatform(regionId)
  const { iconType: platformLogo } = getPlatformInfoById(platformId)

  const name = stackDeployment ? stackDeployment.name : cluster_name
  const displayName = getClusterName(clusterId, name)
  const displayId = (stackDeployment ? stackDeployment.id : clusterId).substring(0, 6)

  const isAnyInstanceUnderMaintenance = instances.some((instance) => instance.maintenance_mode)
  const initializing = isInitializing(source)
  const isInitialPlanFailed = initializing && !plan_info.healthy
  const stackDeploymentId = getStackDeploymentId()

  return {
    id: clusterId,
    stackDeploymentId,
    platform: {
      id: platformId,
      logoString: platformLogo,
    },
    regionId,
    refId: getRefId(stackDeployment),
    name,
    displayId,
    cloudId: getCloudId(metadata),
    displayName,
    isHidden: metadataSettings?.hidden ?? false,
    isInitializing: initializing,
    isInitialPlanFailed,
    isStopped,
    isStopping: isStopping(source),
    isRestarting: isRestarting(source),
    isForceRestarting: isForceRestarting(plan_info),
    hiddenTimestamp: data.hidden_timestamp,
    healthy,

    user: {
      // deprecated, use `profile` where possible
      id: data.user_id as string,
      level: userLevel,
      isPremium: userLevel === `gold` || userLevel === `platinum`,
    },
    profile: {
      userId: metadataSettings?.owner_id ?? '',
      level: metadataSettings?.subscription_level ?? '',
    },
    plan: mapPlan(plan_info, { source }),
    master: {
      healthy: master_info.healthy,
      count: master_info.masters.filter((entry) => entry.master_node_id !== `null`).length,
      instancesWithNoMaster: master_info.instances_with_no_master,
    },
    shards: {
      healthy: shards_healthy,
      status: shards_status?.status,
    },
    instances: mapInstances(clusterId, topology, plan_info, master_info),
    instanceConfigurations: mapInstanceConfigurations(instances),
    snapshots: mapSnapshots(snapshots, data),
    kibana: {
      enabled: kibana?.enabled ?? false,
      id: kibana?.kibana_id,
    },
    apm: {
      enabled: apm?.enabled ?? false,
      id: apm?.apm_id,
    },
    marvel: getMonitoringInfo(elasticsearch_monitoring_info, regionId),
    monitoringInfo: elasticsearch_monitoring_info,
    security: mapSecurity(source),
    curation: mapCuration(source),
    externalLinks: externalLinks || [],
    events: getEvents(source),
    _raw: {
      plan: plan_info.current && plan_info.current.plan ? plan_info.current.plan : null,
      pendingPlan: plan_info.pending && plan_info.pending.plan ? plan_info.pending.plan : null,
      pendingSource:
        plan_info.pending && plan_info.pending.source ? plan_info.pending.source : null,

      // As cname is generated by the backend we must make sure to not keep it around
      // and potentially save it to the backend.
      data: metadata,
    },
    hrefs: createHrefs(selfUrl),
    isSystemOwned: source.settings?.metadata?.system_owned || false,
    proxyLogging,
    kind: `elasticsearch`,
    fsMultiplier: data?.overrides?.resources?.quota?.fs_multiplier ?? fsMultiplierDefault,
    isAnyInstanceUnderMaintenance,
    wasDeleted,
    deploymentTemplateId: getDeploymentTemplateId(plan_info),
    settings: source.settings || {},
    blockingIssues: {
      healthy: cluster_blocking_issues?.healthy,
      blocks: cluster_blocking_issues?.blocks || [],
    },
    cpuHardLimit: data?.resource?.cpu?.hard_limit ?? false,
    isLocked: Boolean(source.locked),
  }

  function getStackDeploymentId() {
    if (deployment_id) {
      return deployment_id
    }

    if (stackDeployment) {
      return stackDeployment.id
    }

    return null
  }
}
