/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText } from '@elastic/eui'

import type { SaasUserDomain } from '@modules/ui-types'

import ExternalLink from '@/components/ExternalLink'
import BillingHistoryPanel from '@/components/User/BillingHistory/BillingHistoryPanel'
import { marketplaceDisplayName, marketplaceConsoleLink } from '@/lib/marketPlace'

import type { FunctionComponent } from 'react'

interface Props {
  domain: SaasUserDomain
  hideTitle?: boolean
}

const MarketplaceUserContent: FunctionComponent<Props> = ({ domain, hideTitle }) => (
  <BillingHistoryPanel
    data-test-id='billing-history.marketplace-info'
    title={hideTitle ? null : undefined}
  >
    <EuiText size='s'>
      <FormattedMessage
        id='billing-statements.billing-history.marketplace-info'
        defaultMessage='To view your invoices and usage statements, go to your {link}.'
        values={{
          link: (
            <ExternalLink href={marketplaceConsoleLink(domain)}>
              <span>
                {marketplaceDisplayName(domain)}
                {` `}
              </span>
              <span>
                <FormattedMessage
                  id='billing-history.marketplace.account'
                  defaultMessage='Marketplace account'
                />
              </span>
            </ExternalLink>
          ),
        }}
      />
    </EuiText>
  </BillingHistoryPanel>
)

export default MarketplaceUserContent
