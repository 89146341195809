/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import {
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiIcon,
  EuiLink,
  EuiPopover,
  EuiPopoverTitle,
} from '@elastic/eui'

import type { HeapDump } from '@modules/ui-types/heapDump'

import type { IntlShape } from 'react-intl'

interface Props {
  intl: IntlShape
  heapDump: HeapDump
}

type State = {
  showPopover: boolean
}

const messages = defineMessages({
  capturing: {
    id: 'heap-dump-status.heap-dump-capturing',
    defaultMessage: 'Capturing',
    color: 'subdued',
  },
  compressing: {
    id: 'heap-dump-status.heap-dump-compressing',
    defaultMessage: 'Compressing',
    color: 'subdued',
  },
  complete: {
    id: 'heap-dump-status.heap-dump-completed',
    defaultMessage: 'Completed',
    color: 'success',
  },
  failed: {
    id: 'heap-dump-status.heap-dump-failed',
    defaultMessage: 'Failed',
    color: 'danger',
  },
  view: {
    id: 'heap-dump-status.heap-dump-view-failed',
    defaultMessage: 'View',
  },
  captureError: {
    id: 'heap-dump-status.heap-dump-failed-popover-title',
    defaultMessage: 'Capture error',
  },
})

class HeapDumpStatus extends Component<Props, State> {
  state: State = {
    showPopover: false,
  }

  render() {
    const {
      intl: { formatMessage },
      heapDump: { status, error },
    } = this.props

    const { showPopover } = this.state

    return (
      <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiHealth color={messages[status].color}>{formatMessage(messages[status])}</EuiHealth>
        </EuiFlexItem>
        {status === 'failed' && error && (
          <EuiPopover
            button={
              <EuiLink onClick={() => this.setState({ showPopover: !showPopover })}>
                {formatMessage(messages.view)}
              </EuiLink>
            }
            closePopover={() => this.setState({ showPopover: false })}
            isOpen={showPopover}
          >
            {this.renderPopover()}
          </EuiPopover>
        )}
      </EuiFlexGroup>
    )
  }

  renderPopover() {
    const {
      heapDump: { error },
    } = this.props

    return (
      <div style={{ maxWidth: `400px` }}>
        <EuiPopoverTitle>
          <EuiFlexGroup gutterSize='xs' alignItems='center'>
            <EuiFlexItem grow={false}>
              <EuiIcon type='alert' />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>Capture error</EuiFlexItem>
          </EuiFlexGroup>
        </EuiPopoverTitle>
        <EuiCodeBlock isCopyable={true} paddingSize='s'>
          {error}
        </EuiCodeBlock>
      </div>
    )
  }
}

export default injectIntl(HeapDumpStatus)
