/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { stringify } from 'query-string'
import { isEmpty, omit } from 'lodash'

import {
  EuiFieldText,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiAlert, CuiLink } from '@modules/cui'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import { initializeAuthForm } from '@/lib/authForm'
import { getConfigForKey } from '@/store'
import { forgotPasswordUrl } from '@/lib/urlBuilder'
import OAuthLoginError from '@/components/Login/OAuthLoginError'
import { buildSignInQuery } from '@/lib/urlUtils'

import GoogleSignUp from '../../../CreateAccountForm/GoogleSignUp'
import MicrosoftSignUp from '../../../CreateAccountForm/MicrosoftSignUp'
import SpinButton from '../../../SpinButton'

import type { Location } from 'history'
import type { ReactElement, ReactNode } from 'react'
import type { SignInQueryParams } from '@/lib/urlUtils'

export type Props = {
  location: Location<any>
  error: ReactNode | null
  username: string
  password: string
  updateUsername
  updatePassword
  onSubmit: (e: React.SyntheticEvent) => void
  inProgress: boolean
  registrationButtons: boolean
  openIdRedirectUrl?: string
  registerUrl?: string
  isGovCloud?: boolean
  hasOAuthLoginError?: boolean
}

type State = {
  hideOauthLoginError: boolean
  isSubmittable: boolean
}

const usernameText = <FormattedMessage id='login.username-text' defaultMessage='Username' />
const emailText = <FormattedMessage id='login.email-text' defaultMessage='Email' />
const passwordText = <FormattedMessage id='login.password-text' defaultMessage='Password' />

export class UsernameAndPasswordForm extends Component<Props, State> {
  state: State = {
    hideOauthLoginError: false,
    isSubmittable: false,
  }

  formRef: HTMLFormElement | null = null

  componentDidMount(): void {
    initializeAuthForm({
      form: this.formRef,
      onFormReady: (autoFillDetected) => {
        this.setState({
          isSubmittable: autoFillDetected,
        })
      },
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { username, password } = prevProps

    if (this.shouldUpdateSubmittableState(username, password)) {
      this.isSubmittable()
    }
  }

  render(): ReactElement {
    const {
      error,
      username,
      password,
      updateUsername,
      updatePassword,
      inProgress,
      registrationButtons,
      openIdRedirectUrl,
      registerUrl,
      isGovCloud,
      location: { search },
    } = this.props
    const { isSubmittable } = this.state

    const isEce = getConfigForKey(`CLOUD_UI_APP`) === `cloud-enterprise-adminconsole`
    const isPrivate = getConfigForKey(`APP_FAMILY`) === `essp`

    const query = buildSignInQuery({ search, withReferrer: true })

    const usernameLabel = isEce ? usernameText : emailText

    return (
      <form
        onSubmit={this.onSubmit}
        className='login-form-password'
        data-test-id='login-form-password'
        ref={(el) => {
          this.formRef = el
        }}
      >
        <PrivacySensitiveContainer>
          <EuiFormRow
            label={usernameLabel}
            className='login-form-email'
            data-test-id='username-form-row'
          >
            <EuiFieldText
              data-test-id='login-username'
              icon='user'
              value={username}
              onChange={updateUsername}
              name='email'
            />
          </EuiFormRow>

          <EuiSpacer size='l' />

          <EuiFormRow className='login-form-password-field' label={passwordText}>
            <EuiFieldPassword
              data-test-id='login-password'
              value={password}
              onChange={updatePassword}
              type='dual'
              name='password'
            />
          </EuiFormRow>
        </PrivacySensitiveContainer>

        <EuiSpacer />

        <SpinButton
          type='submit'
          data-test-id='login-button'
          className='cloud-landing-page-form-submit-button'
          color={error ? `danger` : `primary`}
          fill={true}
          spin={inProgress}
          disabled={!isSubmittable}
          buttonProps={{ fullWidth: true }}
        >
          <FormattedMessage id='login-login-form.log-in' defaultMessage='Log in' />
        </SpinButton>

        {registrationButtons && (
          <Fragment>
            <EuiSpacer />

            <EuiText textAlign='center' size='s' className='forgotPasswordLink'>
              <CuiLink
                to={this.buildForgotPasswordLinkUrl(omit(query, 'referrer'))}
                data-test-id='forgot-password-link'
              >
                <FormattedMessage
                  id='login-form.forgot-password'
                  defaultMessage='Forgot password?'
                />
              </CuiLink>
            </EuiText>
          </Fragment>
        )}

        {this.renderError()}

        {registrationButtons && registerUrl && !isPrivate && !isGovCloud && (
          <Fragment>
            <EuiSpacer />

            <div className='social-signup-form-separator'>
              <EuiText size='s'>
                <FormattedMessage id='login-form.or-google' defaultMessage='Or log in with' />
              </EuiText>
            </div>

            <EuiSpacer />

            {!isGovCloud && (
              <EuiFlexGroup alignItems='center' gutterSize='s'>
                <EuiFlexItem>
                  <GoogleSignUp fullText={false} redirectTo={openIdRedirectUrl} />
                </EuiFlexItem>

                <EuiFlexItem>
                  <MicrosoftSignUp fullText={false} redirectTo={openIdRedirectUrl} />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </Fragment>
        )}
      </form>
    )
  }

  renderError(): ReactNode | null {
    const {
      error,
      location: { state: locationState },
      hasOAuthLoginError,
    } = this.props
    const { hideOauthLoginError } = this.state

    if (!error && !hasOAuthLoginError) {
      return null
    }

    if (hasOAuthLoginError) {
      const oAuthLoginError = locationState.oAuthLoginError

      return (
        <Fragment>
          {error && this.renderErrorAlert(error)}

          <OAuthLoginError oAuthLoginError={oAuthLoginError} hidden={hideOauthLoginError} />
        </Fragment>
      )
    }

    return this.renderErrorAlert(error)
  }

  renderErrorAlert(error): ReactElement {
    return (
      <Fragment>
        <EuiSpacer />

        <CuiAlert data-test-id='login-error' type='error'>
          {error}
        </CuiAlert>
      </Fragment>
    )
  }

  buildForgotPasswordLinkUrl(query?: SignInQueryParams): string {
    const forgotPasswordLinkUrl = forgotPasswordUrl()

    if (!query || isEmpty(query)) {
      return forgotPasswordLinkUrl
    }

    return `${forgotPasswordLinkUrl}?${stringify(query)}`
  }

  shouldUpdateSubmittableState(prevUsername: string, prevPassword: string): boolean {
    const { username, password } = this.props
    return prevUsername !== username || prevPassword !== password
  }

  isSubmittable(): void {
    const { username, password } = this.props
    this.setState({
      isSubmittable: !!username.trim() && !!password.trim(),
    })
  }

  onSubmit = (e: React.SyntheticEvent): void => {
    const { hasOAuthLoginError, onSubmit } = this.props

    if (hasOAuthLoginError) {
      this.setState({ hideOauthLoginError: true })
    }

    onSubmit(e)
  }
}
