/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiSkeletonText, EuiPanel } from '@elastic/eui'

import type {
  AccountCosts,
  AccountCostsSummary,
  AsyncRequestState,
  UserProfile,
} from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import TrialNotStarted from '../TrialNotStarted'
import TrialStarted from '../TrialStarted'
import CostAnalysisWhenSubscribed from '../CostAnalysisWhenSubscribed'
import { isPrepaidConsumptionCustomer, isTrialNotStartedUser } from '../../../../lib/billing'

import type { ReactElement } from 'react'

export interface Props {
  accountCosts: AccountCosts
  accountCostOverview: AccountCostsSummary
  fetchAccountCostsOverviewIfNeeded: ({ organizationId }: { organizationId: string }) => void
  fetchAccountCostsOverviewRequest: AsyncRequestState
  fetchDeploymentsCostsRequest: AsyncRequestState
  profile: UserProfile
}

class CostOverview extends PureComponent<Props> {
  componentDidMount(): void {
    const { fetchAccountCostsOverviewIfNeeded, profile } = this.props
    const { organization_id } = profile

    if (!isTrialNotStartedUser(profile)) {
      fetchAccountCostsOverviewIfNeeded({ organizationId: organization_id! })
    }
  }

  render(): ReactElement {
    const { accountCostOverview, fetchAccountCostsOverviewRequest, profile } = this.props
    const { inTrial } = profile

    if (fetchAccountCostsOverviewRequest.error) {
      return <CuiAlert type='error'>{fetchAccountCostsOverviewRequest.error}</CuiAlert>
    }

    if (
      fetchAccountCostsOverviewRequest.inProgress ||
      (!accountCostOverview && !isTrialNotStartedUser(profile))
    ) {
      return (
        <EuiFlexGroup gutterSize='l'>
          <EuiFlexItem>{this.renderPanelLoading()}</EuiFlexItem>
          <EuiFlexItem>{this.renderPanelLoading()}</EuiFlexItem>
          {!inTrial && <EuiFlexItem>{this.renderPanelLoading()}</EuiFlexItem>}
        </EuiFlexGroup>
      )
    }

    return this.renderUserContextOverview()
  }

  renderPanelLoading(): ReactElement {
    return (
      <EuiPanel paddingSize='m'>
        <EuiSkeletonText lines={3} />
      </EuiPanel>
    )
  }

  renderUserContextOverview(): ReactElement {
    const { accountCostOverview, accountCosts, profile } = this.props

    if (isTrialNotStartedUser(profile)) {
      return (
        <TrialNotStarted
          isPrepaidConsumptionUser={isPrepaidConsumptionCustomer(profile)}
          days={profile.trial_length}
        />
      )
    }

    if (accountCostOverview.trials > 0 || profile.inTrial) {
      return (
        <TrialStarted
          isPrepaidConsumptionUser={isPrepaidConsumptionCustomer(profile)}
          deployments={accountCosts && accountCosts.deployments}
          accountCostOverview={accountCostOverview}
        />
      )
    }

    return (
      <CostAnalysisWhenSubscribed accountCostOverview={accountCostOverview} profile={profile} />
    )
  }
}

export default CostOverview
