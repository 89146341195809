/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { createQueryExecutor, CuiFilterContext } from '@modules/cui'
import type { ControlledFilterQuery } from '@modules/cui'

import { getQueryModel, schema, defaultFields } from './schema'
import { getFilters } from './filters'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  query: ControlledFilterQuery
  managedApiKeys?: any[]
  onChange: (q: any) => void
  tools?: any[]
}

const messages = defineMessages({
  searchBarPlaceholder: {
    id: `manage-api-keys-filter-context.placeholder`,
    defaultMessage: `Type the API key name`,
  },
})

const ManageApiKeysFilterContext: FunctionComponent<Props> = ({
  intl,
  query,
  onChange,
  managedApiKeys,
  tools,
}) => {
  const { formatMessage } = intl

  const executeQuery = createQueryExecutor({
    defaultFields,
    getQueryModel,
  })

  const filters = getFilters()

  const placeholder = formatMessage(messages.searchBarPlaceholder)

  return (
    <CuiFilterContext
      query={query}
      onChange={onChange}
      records={managedApiKeys ? managedApiKeys : []}
      schema={schema}
      filters={filters}
      executeQuery={executeQuery}
      placeholder={placeholder}
      tools={tools}
    />
  )
}

export default injectIntl(ManageApiKeysFilterContext)
