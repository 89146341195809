/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import type { AdminconsolesOverview, AdminconsoleInfo } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, CuiTable } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'
import Header from '@modules/cui/Header'

import AdminconsoleLoggingSettings from '../AdminconsoleOverview/AdminconsoleLoggingSettings'
import { adminconsolesCrumbs } from '../../lib/crumbBuilder'

type Props = {
  regionId: string
  adminconsoles?: AdminconsolesOverview
  fetchAdminconsolesRequest: AsyncRequestState
  fetchAdminconsoles: () => void
}

export default class Adminconsoles extends Component<Props> {
  componentDidMount() {
    const { fetchAdminconsoles } = this.props
    fetchAdminconsoles()
  }

  render() {
    const { regionId } = this.props

    return (
      <Fragment>
        <Header
          name={<FormattedMessage id='adminconsoles.page-title' defaultMessage='Admin consoles' />}
          breadcrumbs={adminconsolesCrumbs({ regionId })}
        />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const { regionId, adminconsoles, fetchAdminconsolesRequest } = this.props

    if (fetchAdminconsolesRequest.error) {
      return <CuiAlert type='error'>{fetchAdminconsolesRequest.error}</CuiAlert>
    }

    const columns: Array<CuiTableColumn<AdminconsoleInfo>> = [
      {
        label: (
          <FormattedMessage id='adminconsoles.adminconsole-id' defaultMessage='Admin console' />
        ),
        render: (adminconsole) => adminconsole.adminconsole_id,
        sortKey: `adminconsole_id`,
      },
      {
        render: (adminconsole) => (
          <AdminconsoleLoggingSettings
            regionId={regionId}
            adminconsoleId={adminconsole.adminconsole_id}
          />
        ),
        width: '40px',
      },
    ]

    return (
      <CuiTable<AdminconsoleInfo>
        rows={adminconsoles && adminconsoles.adminconsoles}
        columns={columns}
        getRowId={(adminconsole) => adminconsole.adminconsole_id}
      />
    )
  }
}
