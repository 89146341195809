/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { cloneDeep, isEmpty, without } from 'lodash'

import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type {
  BaseClause,
  ClauseConnector,
  InnerClause,
  OuterClause,
} from '@modules/ui-types/instanceConfigurationTypes'
import { CuiAlert } from '@modules/cui'

import FilterQueryExpression from '../../../components/FilterQueryExpression'
import MatchingAllocatorsTable from '../../../components/MatchingAllocatorsTable'
import { replaceIn } from '../../../../../lib/immutability-helpers'
import {
  clausesToExpression,
  makeInnerClause,
  makeOuterClause,
  removeMalformedClauses,
  validateClauses,
} from '../../../../../lib/allocatorFilters'
import DocLink from '../../../../DocLink'

import QueryBuilder from './QueryBuilder'

import type { StepOwnProps, StepProps } from '../instanceConfigurationWizardTypes'
import type { WrappedComponentProps } from 'react-intl'
import type { ReactNode } from 'react'

import './filterAllocatorsStep.scss'
import './filterAllocatorsStepDark.scss'

enum AllocatorFilterErrors {
  CLAUSE_ERRORS = 'CLAUSE_ERRORS',
}

interface Props extends WrappedComponentProps, StepProps {}

class FilterAllocatorsStep extends Component<Props> {
  constructor(props) {
    super(props)

    const {
      instanceConfiguration: { clauses },
      regionId,
      searchAllocatorsComplexQuery,
    } = props

    // Kick off initial search.
    searchAllocatorsComplexQuery(`topology-filter-allocators`, regionId, clauses)
  }

  render() {
    const { instanceConfiguration } = this.props
    const { clauses } = instanceConfiguration
    const clauseErrors = getClauseErrors({ instanceConfiguration })

    return (
      <Fragment>
        <EuiTitle>
          <h2 data-test-id='find-allocators'>
            <FormattedMessage
              id='instance-configuration-filter-allocators.filter-title'
              defaultMessage='Find allocators'
            />
          </h2>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText>
          <p>
            <FormattedMessage
              id='instance-configuration-filter-allocators.filter-description-1'
              defaultMessage='Filter allocators based on tags you assigned previously. Tags represent hardware characteristics such as memory or storage: use them to find the hardware you want.'
            />
          </p>

          <p>
            <FormattedMessage
              id='instance-configuration-filter-allocators.filter-description-2'
              defaultMessage="Can't find the right allocators? You might need to tag your allocators with additional hardware characteristics first. {learnMore}"
              values={{
                learnMore: (
                  <DocLink link='templatesDocLink'>
                    <FormattedMessage
                      id='instance-configuration-filter-allocators.filter-description-2-link'
                      defaultMessage='Learn more'
                    />
                  </DocLink>
                ),
              }}
            />
          </p>
        </EuiText>

        <EuiSpacer />

        <EuiTitle size='s'>
          <h3>
            <FormattedMessage
              id='instance-configuration-filter-allocators.inputHeading'
              defaultMessage='Input'
            />
          </h3>
        </EuiTitle>
        <EuiSpacer size='s' />

        {this.renderErrors()}

        <QueryBuilder
          clauses={clauses}
          errorsInClauses={clauseErrors}
          appendOuterClause={this.appendOuterClause}
          appendInnerClause={this.appendInnerClause}
          deleteOuterClause={this.deleteOuterClause}
          deleteInnerClause={this.deleteInnerClause}
          setOuterClauseConnector={this.setOuterClauseConnector}
          setInnerClauseConnector={this.setInnerClauseConnector}
          setTagKey={this.setTagKey}
          setTagValue={this.setTagValue}
        />

        <EuiSpacer />

        <EuiTitle size='s'>
          <h3>
            <FormattedMessage
              id='instance-configuration-filter-allocators.filter-expression'
              defaultMessage='Query'
            />
          </h3>
        </EuiTitle>

        <EuiSpacer size='s' />

        {this.renderQueryExpression()}

        <EuiSpacer size='l' />

        {this.renderSearchResultsList()}

        {this.renderEmptyMatchWarning()}

        <EuiSpacer size='l' />
      </Fragment>
    )
  }

  renderErrors = (): ReactNode => {
    const { pristine } = this.props

    if (pristine) {
      return null
    }

    return (
      <Fragment>
        {this.hasClauseErrors() ? (
          <EuiCallOut
            data-test-id='warning-clauses-have-errors'
            title={
              <FormattedMessage
                id='instance-configuration-filter-allocators.invalid-tags'
                defaultMessage='Tags must have both a key and a value'
              />
            }
            color='danger'
            iconType='cross'
          />
        ) : (
          <EuiCallOut
            data-test-id='query-is-valid'
            title={
              <FormattedMessage
                id='instance-configuration-filter-allocators.valid-tags'
                defaultMessage='Your query is valid'
              />
            }
            color='success'
            iconType='check'
          />
        )}

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderEmptyMatchWarning = (): ReactNode => {
    const allocators = this.getMatchingAllocators()

    if (!allocators || allocators.length > 0) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <EuiCallOut
          title={
            <Fragment>
              <FormattedMessage
                id='instance-configuration-filter-allocators.filter-none-match-warning'
                defaultMessage="Tags tell instance configurations where to deploy parts of the Elastic Stack by matching suitable allocators. If no allocators match, there won't be sufficient capacity available, and attempts to create or change clusters with templates that use these instance configurations will fail."
              />

              <EuiSpacer size='s' />

              <FormattedMessage
                id='instance-configuration-filter-allocators.filter-none-match-warning-line-2'
                defaultMessage='To avoid this scenario, make sure that you tag at least some allocators, so that there is sufficient capacity available to create or change deployments.'
              />
            </Fragment>
          }
          color='warning'
          iconType='cross'
        />
      </Fragment>
    )
  }

  renderSearchResultsList = (): ReactNode => {
    const {
      searchResults,
      searchAllocatorsRequest: { error, inProgress },
    } = this.props

    if (this.hasClauseErrors()) {
      return null
    }

    if (error) {
      return <CuiAlert type='error'>{error}</CuiAlert>
    }

    // Old search results aren't cleared from the store, so we need to check both of these conditions.
    if (inProgress || !searchResults) {
      return (
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size='l' />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>
                <FormattedMessage
                  id='instance-configuration-filter-allocators.search-results.pending'
                  defaultMessage='Searching …'
                />
              </p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    const allocators = this.getMatchingAllocators()
    const count = allocators!.length.toString()

    return (
      <Fragment>
        <FormattedMessage
          id='instance-configuration-filter-allocators.allocators-table-matches'
          defaultMessage='Your instance configuration will apply to { matches }.'
          values={{
            count,
            matches: (
              <strong>
                <FormattedMessage
                  id='instance-configuration-filter-allocators.allocators-table-count'
                  defaultMessage='{count} {count, plural, one {allocator} other {allocators}}'
                  values={{ count }}
                />
              </strong>
            ),
          }}
        />

        {allocators != null && allocators.length > 0 && (
          <Fragment>
            <EuiSpacer size='s' />

            <MatchingAllocatorsTable allocators={allocators} />
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderQueryExpression = (): ReactNode => {
    const {
      instanceConfiguration: { clauses },
    } = this.props

    if (this.hasClauseErrors()) {
      return (
        <EuiPanel>
          <EuiText color='subdued'>
            <FormattedMessage
              id='instance-configuration-filter-allocators.filter-expression.with-clause-errors'
              defaultMessage='The query is invalid'
            />
          </EuiText>
        </EuiPanel>
      )
    }

    return <FilterQueryExpression query={clausesToExpression(clauses)} />
  }

  appendOuterClause = (connector: ClauseConnector) => {
    const {
      instanceConfiguration: { clauses },
    } = this.props
    const updatedClauses = setConnectorForClauses<OuterClause>(clauses, connector)
    const newOuterClause = makeOuterClause(connector)
    const newOuterClauses = [...updatedClauses, newOuterClause]
    this.onClausesChange(newOuterClauses)
  }

  deleteOuterClause = (outerClause) => {
    const {
      instanceConfiguration: { clauses },
    } = this.props
    this.onClausesChange(without(clauses, outerClause))
  }

  appendInnerClause = (outerClause: OuterClause, connector: ClauseConnector) => {
    const updatedClauses = setConnectorForClauses<InnerClause>(outerClause.innerClauses, connector)
    const newInnerClause = makeInnerClause(connector)
    const newInnerClauses = [...updatedClauses, newInnerClause]
    this.setInnerClauses(outerClause, newInnerClauses)
  }

  deleteInnerClause = (outerClause: OuterClause, innerClause: InnerClause) => {
    const newInnerClauses = without(outerClause.innerClauses, innerClause)
    this.setInnerClauses(outerClause, newInnerClauses)
  }

  hasClauseErrors = (): boolean => {
    const { pristine, instanceConfiguration } = this.props
    const errors = validateAllocatorFilters({ instanceConfiguration } as StepOwnProps)
    return !pristine && !isEmpty(errors)
  }

  setInnerClauses = (outerClause: OuterClause, innerClauses: InnerClause[]) => {
    const {
      instanceConfiguration: { clauses },
    } = this.props
    const outerClauseIndex = clauses.indexOf(outerClause)
    const updatedClauses = replaceIn(
      clauses,
      [String(outerClauseIndex), `innerClauses`],
      innerClauses,
    )
    this.onClausesChange(updatedClauses)
  }

  setOuterClauseConnector = (connector: ClauseConnector) => {
    const {
      instanceConfiguration: { clauses },
    } = this.props
    const updatedClauses = setConnectorForClauses<OuterClause>(clauses, connector)
    this.onClausesChange(updatedClauses)
  }

  setInnerClauseConnector = (outerClause: OuterClause, connector: ClauseConnector) => {
    const updatedClauses = setConnectorForClauses<InnerClause>(outerClause.innerClauses, connector)
    this.setInnerClauses(outerClause, updatedClauses)
  }

  setTagKey = (outerClause: OuterClause, index: number, key: string) => {
    const path = [String(index), `key`]
    const newInnerClauses = replaceIn(outerClause.innerClauses, path, key)
    this.setInnerClauses(outerClause, newInnerClauses)
  }

  setTagValue = (outerClause: OuterClause, index: number, value: string) => {
    const path = [String(index), `value`]
    const newInnerClauses = replaceIn(outerClause.innerClauses, path, value)
    this.setInnerClauses(outerClause, newInnerClauses)
  }

  onClausesChange = (clauses: OuterClause[]) => {
    const { regionId, searchAllocatorsComplexQuery, updateInstanceConfiguration } = this.props

    updateInstanceConfiguration({ clauses })
    searchAllocatorsComplexQuery(`topology-filter-allocators`, regionId, clauses)
  }

  getMatchingAllocators() {
    const { searchResults, searchAllocatorsRequest } = this.props

    if (searchAllocatorsRequest.inProgress || !searchResults) {
      return null
    }

    return searchResults
  }
}

export default injectIntl(FilterAllocatorsStep)

function getClauseErrors({ instanceConfiguration }) {
  const { clauses } = instanceConfiguration
  const clausesErrors = validateClauses(removeMalformedClauses(clauses))

  return clausesErrors
}

export function validateAllocatorFilters({
  instanceConfiguration,
}: StepOwnProps): AllocatorFilterErrors[] {
  const errors = [] as AllocatorFilterErrors[]

  const clausesErrors = getClauseErrors({ instanceConfiguration })
  const hasClausesErrors = !isEmpty(clausesErrors)

  if (hasClausesErrors) {
    errors.push(AllocatorFilterErrors.CLAUSE_ERRORS)
  }

  return errors
}

function setConnectorForClauses<TClause extends BaseClause>(
  clauses: TClause[],
  connector: ClauseConnector,
): TClause[] {
  return clauses.map((clause) => {
    const newClause = cloneDeep(clause)
    newClause.connector = connector
    return newClause
  })
}
