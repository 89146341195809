/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { MetadataItem } from '@modules/cloud-api/v1/types'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { updateDeploymentTagsRequest } from '@/reducers'

import { updateDeploymentTags } from '../../../../actions/deploymentTags'
import { fetchDeployment } from '../../../../actions/stackDeployments'

import DeploymentTagsFlyout from './DeploymentTagsFlyout'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deploymentId }: ConsumerProps): StateProps => ({
  updateDeploymentTagsRequest: updateDeploymentTagsRequest(state, deploymentId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deploymentId }: ConsumerProps,
): DispatchProps => ({
  updateDeploymentTags: (tags: MetadataItem[]) =>
    dispatch(updateDeploymentTags({ deploymentId, tags })).then(() =>
      dispatch(fetchDeployment({ deploymentId })),
    ),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentTagsFlyout)
