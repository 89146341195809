/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiText,
} from '@elastic/eui'

import type { ElasticsearchClusterPlan } from '@modules/cloud-api/v1/types'

import NodeRolesWarning from '@/components/StackDeploymentEditor/EditStackDeploymentEditor/EditDeploymentFromTemplate/NodeRolesWarning'
import { getRegionId } from '@/lib/stackDeployments/selectors'
import {
  createUpdateRequestFromGetResponse,
  getBlankNodeConfigurationPerTemplate,
  sanitizeUpdateRequestBeforeSend,
} from '@/lib/stackDeployments'
import { setAutoscalingEnabled } from '@/lib/stackDeployments/autoscaling'

import type { AllProps as Props } from './types'

interface State {
  isModalOpen: boolean
}

class EnableAutoscalingModal extends PureComponent<Props, State> {
  mounted: boolean = false

  state = {
    isModalOpen: false,
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const { isButtonEmpty } = this.props
    const Button = isButtonEmpty ? EuiButtonEmpty : EuiButton
    const buttonProps = {
      onClick: this.onOpen,
      'data-test-id': 'autoscaling-available-button',
      ...(!isButtonEmpty && { fill: true }),
    }

    return (
      <Fragment>
        <Button {...buttonProps}>{this.getButtonText()}</Button>
        {this.renderModal()}
      </Fragment>
    )
  }

  renderModal() {
    const { deployment, updateDeploymentRequest } = this.props
    const { isModalOpen } = this.state

    if (!isModalOpen) {
      return null
    }

    return (
      <EuiOverlayMask>
        <EuiModal onClose={this.onClose} data-test-id='enable-autoscaling-modal'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='autoscaling-available-modal.title'
                defaultMessage='Enable autoscaling'
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiText>
              <NodeRolesWarning deploymentUnderEdit={deployment} />
              <p>
                <FormattedMessage
                  id='autoscaling-available-modal.body-intro'
                  defaultMessage='Autoscaling increases deployment storage capacity automatically. For ML nodes both storage capacity and RAM increase and decrease. Future capacity needs are forecasted based on past usage.'
                />
              </p>
              <p>
                <FormattedMessage
                  id='autoscaling-available-modal.body-second'
                  defaultMessage='You can set the maximum size per zone in order to avoid excess costs. For ML nodes you can set a maximum and minimum.'
                />
              </p>
            </EuiText>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={this.onClose} data-test-id='autoscaling-enable-modal-cancel'>
              <FormattedMessage id='autoscaling-available-modal.cancel' defaultMessage='Cancel' />
            </EuiButtonEmpty>

            <EuiButton
              onClick={this.enableAutoscaling}
              data-test-id='autoscaling-enable-modal-confirm'
              fill={true}
              isLoading={updateDeploymentRequest.inProgress}
            >
              <FormattedMessage
                id='autoscaling-available-modal.enable'
                defaultMessage='Enable autoscaling'
              />
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  enableAutoscaling = () => {
    const { deployment } = this.props
    this.updateDeployment(deployment)
  }

  updateDeployment(deployment) {
    const { updateDeployment, onEnableAutoscaling } = this.props
    const updatePayload = this.getUpdatePayload()

    updateDeployment({
      regionId: getRegionId({ deployment })!,
      deploymentId: deployment!.id,
      deployment: updatePayload,
    }).then(() => {
      if (this.mounted) {
        this.setState({ isModalOpen: false }, onEnableAutoscaling)
      }
    })
  }

  getButtonText() {
    const { buttonText } = this.props

    if (buttonText) {
      return buttonText
    }

    return (
      <FormattedMessage
        id='autoscaling-available.enable-autoscaling'
        defaultMessage='Enable autoscaling'
      />
    )
  }

  getUpdatePayload = () => {
    const { deployment, deploymentTemplate } = this.props
    const updateRequest = createUpdateRequestFromGetResponse({
      deployment,
      deploymentTemplate,
    })

    const blankTemplate = getBlankNodeConfigurationPerTemplate({
      sliderInstanceType: 'elasticsearch',
      deploymentTemplate: deploymentTemplate!,
    }) as ElasticsearchClusterPlan

    const updateRequestWithEnabledAutoscaling = setAutoscalingEnabled({
      deployment: updateRequest,
      blankTemplate,
    })

    return sanitizeUpdateRequestBeforeSend({ deployment: updateRequestWithEnabledAutoscaling })
  }

  onOpen = () => {
    this.setState({ isModalOpen: true })
  }

  onClose = () => {
    this.setState({ isModalOpen: false })
  }
}

export default EnableAutoscalingModal
