/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { Component, Fragment } from 'react'
import { css, jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCodeBlock,
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiIcon,
  EuiPanel,
} from '@elastic/eui'

import type { DeploymentUpdateRequest } from '@modules/cloud-api/v1/types'
import type { SliderInstanceType, AnyPlanConfiguration } from '@modules/ui-types'

import { getUserSettingsOverridesAsString } from '../../../../../lib/stackDeployments/selectors/stackDeployment'
import { getSupportedSliderInstanceTypes } from '../../../../../lib/sliders/support'
import { getSliderPlan } from '../../../../../lib/stackDeployments/selectors'
import { getSliderPrettyName } from '../../../../../lib/sliders'

import type { ReactNode } from 'react'

interface Props {
  deployment: DeploymentUpdateRequest
  updatePlan: (
    sliderInstanceType: SliderInstanceType,
    path: string | string[],
    value: AnyPlanConfiguration,
  ) => void
}

interface State {
  adminOverridesDisplayed: {
    [id: string]: boolean
  }
  componentOverridesCleared: string
}

interface PropsWithTheme extends Props {}

class ClearUserSettingsOverrides extends Component<PropsWithTheme, State> {
  state: State = {
    adminOverridesDisplayed: {},
    componentOverridesCleared: ``,
  }

  render(): ReactNode {
    const { deployment } = this.props

    const overrides: Array<{
      sliderInstanceType: SliderInstanceType
      userSettingOverrides: string
    }> = []
    getSupportedSliderInstanceTypes().forEach((sliderInstanceType) => {
      const plan = getSliderPlan({ deployment, sliderInstanceType })

      if (plan == null) {
        return
      }

      const userSettingOverrides = getUserSettingsOverridesAsString({ plan, sliderInstanceType })

      if (userSettingOverrides == null) {
        return
      }

      overrides.push({
        sliderInstanceType,
        userSettingOverrides,
      })
    })

    if (overrides.length === 0 && this.state.componentOverridesCleared === ``) {
      return null
    }

    return (
      <Fragment>
        {overrides.length > 0 && (
          <Fragment>
            <EuiSpacer size='xs' />
            <EuiCallOut data-test-subj='user-settings-override-present' color='warning'>
              <FormattedMessage
                id='deployment-version-upgrade.overrides-present'
                defaultMessage='Some user settings of your deployment are overridden by an administrator and might be incompatible with the version you want to upgrade to.'
              />

              <EuiSpacer size='m' />
              {this.renderEachComponentButton(overrides)}
            </EuiCallOut>
          </Fragment>
        )}

        {this.state.componentOverridesCleared && (
          <Fragment>
            <EuiSpacer size='s' />
            <EuiCallOut color='success'>
              <FormattedMessage
                id='deployment-version-upgrade.overrides-cleared-successfully'
                defaultMessage='Successfully cleared overrides for {sliderInstanceType}. Complete the upgrade process to save these changes.'
                values={{
                  sliderInstanceType: this.state.componentOverridesCleared,
                }}
              />
            </EuiCallOut>
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderEachComponentButton(overrides) {
    return (
      <Fragment>
        {overrides.map((override) => {
          const prettyName = getSliderPrettyName({
            sliderInstanceType: override.sliderInstanceType,
          }).defaultMessage

          const expandButtonIcon = this.state.adminOverridesDisplayed[override.sliderInstanceType]
            ? `arrowDown`
            : `arrowRight`

          return (
            <Fragment key={override.sliderInstanceType}>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    iconType={expandButtonIcon}
                    color='text'
                    data-test-subj={`user-settings-override-${prettyName}`}
                    onClick={() => {
                      const t = this.state.adminOverridesDisplayed
                      t[override.sliderInstanceType] =
                        !this.state.adminOverridesDisplayed[override.sliderInstanceType]
                      this.setState({
                        adminOverridesDisplayed: t,
                      })
                    }}
                  >
                    <FormattedMessage
                      id='deploymentInfrastructure-overrides-sectionTitle'
                      defaultMessage='{sliderInstanceType} overrides'
                      values={{
                        sliderInstanceType: prettyName,
                      }}
                    />
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
              {this.state.adminOverridesDisplayed[override.sliderInstanceType] &&
                this.renderEachComponentsOverrides(override, prettyName)}
              <EuiSpacer size='s' />
            </Fragment>
          )
        })}
      </Fragment>
    )
  }

  renderEachComponentsOverrides(override, prettyName) {
    const textStyle = css({
      marginTop: `-1rem`,
    })

    return (
      <EuiPanel>
        <EuiCodeBlock>{override.userSettingOverrides}</EuiCodeBlock>

        <div css={textStyle}>
          <EuiIcon type='alert' style={{ marginRight: `.5rem` }} />
          <FormattedMessage
            id='deploymentInfrastructure-clearOverrides-removeWarning'
            defaultMessage='Remove these overrides <bold>only</bold> if you are sure that they are no longer needed. Adding these overrides again requires opening a support ticket.'
            values={{
              bold: (content) => <b>{content}</b>,
            }}
          />
        </div>

        <EuiSpacer size='m' />
        <EuiButton
          onClick={() => this.clearOverrideSettings(override.sliderInstanceType, prettyName)}
          color='warning'
          size='s'
          data-test-subj={`user-settings-override-clear-btn-${prettyName}`}
        >
          <FormattedMessage
            id='deploymentInfrastructure-clearOverrides-sectionTitle'
            defaultMessage='Remove {sliderInstanceType} overrides'
            values={{
              sliderInstanceType: prettyName,
            }}
          />
        </EuiButton>
      </EuiPanel>
    )
  }

  clearOverrideSettings(sliderInstanceType, prettyName) {
    const { updatePlan, deployment } = this.props
    const currentValue = deployment.resources?.[sliderInstanceType][0].plan[sliderInstanceType]

    // Instead of keeping track of which one of these was set, we can just null both and let the API take care of it
    const newValue = {
      ...currentValue,
      user_settings_override_json: null,
      user_settings_override_yaml: null,
    }

    updatePlan(sliderInstanceType, [sliderInstanceType], newValue)
    this.setState({ componentOverridesCleared: prettyName })
  }
}

export default ClearUserSettingsOverrides
