/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import { ThunkDispatch } from '@/types/redux'
import { searchStackDeploymentsRequest } from '@/reducers/asyncRequests/registry'
import { searchDeployments } from '@/actions/stackDeployments'
import { getDeploymentByIdQuery } from '@/lib/deploymentQuery'
import { fetchSaasUser } from '@/actions/saasUsers'

import SearchByDeploymentId from './SearchByDeploymentId'

import type { ConsumerProps, DispatchProps, StateProps } from './types'

const queryId = `user-lookup-by-cluster`

const mapStateToProps = (state): StateProps => ({
  deploymentsRequest: searchStackDeploymentsRequest(state, queryId),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchDeployment: (deploymentId: string) =>
    dispatch(searchDeployments({ queryId, query: getDeploymentByIdQuery({ deploymentId }) })),
  fetchSaasUser: (userId: string) => dispatch(fetchSaasUser(userId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withTransaction(`Users`, `search-by-user-email`)(SearchByDeploymentId))
