/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFieldNumber } from '@elastic/eui'

import type { DeploymentCreateRequest } from '@modules/cloud-api/v1/types'

import { getDedicatedMasterThresholdFromTemplate } from '@/lib/stackDeployments/selectors'
import { DEFAULT_DEDICATED_MASTERS_THRESHOLD } from '@/lib/deploymentTemplates/deploymentTemplate'

import type { AllProps as TemplateTopologyElementProps } from './TemplateTopologyElement'

const DedicatedMasterThresholdField: React.FunctionComponent<TemplateTopologyElementProps> = ({
  deployment,
  onResourceChange,
  isReadOnly,
}) => {
  const value =
    getDedicatedMasterThresholdFromTemplate({
      // `deployment` will always be a CreateRequest, not an UpdateRequest, as this component is for editing templates.
      deploymentTemplate: deployment as DeploymentCreateRequest,
    }) || DEFAULT_DEDICATED_MASTERS_THRESHOLD

  return (
    <EuiFieldNumber
      data-test-subj='dedicated-masters-threshold'
      style={{ width: `initial` }}
      min={2}
      max={99}
      value={value}
      onChange={onChange}
      disabled={isReadOnly}
    />
  )

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (onResourceChange) {
      onResourceChange([`settings`, `dedicated_masters_threshold`], parseInt(e.target.value, 10))
    }
  }
}

export default DedicatedMasterThresholdField
