/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { getOrganizationMembers } from '@/reducers'

import { deleteOrganizationMemberships } from '../../../../actions/organizations'
import { deleteOrganizationMembershipsRequest } from '../../../../reducers/asyncRequests/registry'

import RemoveMemberModal from './RemoveMemberModal'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  { organization: { id: organizationId }, organizationMemberRow: { id: userId } }: ConsumerProps,
): StateProps => {
  const members = getOrganizationMembers(state, organizationId)
  const isLastMember = members.length === 1

  return {
    isLastMember,
    deleteOrganizationMembershipRequest: () =>
      deleteOrganizationMembershipsRequest(state, organizationId, userId),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organization: { id: organizationId }, organizationMemberRow: { id: userId } }: ConsumerProps,
): DispatchProps => ({
  deleteOrganizationMembership: () =>
    dispatch(deleteOrganizationMemberships({ organizationId, userIds: [userId] })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveMemberModal)
