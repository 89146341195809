/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { MessageDescriptor } from 'react-intl'
import type { Role } from '@/components/Users/RoleAssignmentsPanel/types'

const messages: {
  label: Record<Role | string, MessageDescriptor>
  description: Record<string, MessageDescriptor>
} = {
  label: defineMessages({
    'organization-admin': {
      id: 'organization.organization-member-role.organization-admin',
      defaultMessage: 'Organization owner',
    },
    'billing-admin': {
      id: 'organization.organization-member-role.billing-admin',
      defaultMessage: 'Billing admin',
    },
    deploymentAccess: {
      id: 'organization.organization-member-role.deployment-access',
      defaultMessage: 'Deployment access',
    },
    specificDeploymentsAccess: {
      id: 'organization.organization-member-role.specific-deployments-access',
      defaultMessage: 'Specific deployment access',
    },
    'deployment-admin': {
      id: 'organization.organization-member-role.deployment-admin',
      defaultMessage: 'Admin',
    },
    'deployment-editor': {
      id: 'organization.organization-member-role.deployment-editor',
      defaultMessage: 'Editor',
    },
    'deployment-viewer': {
      id: 'organization.organization-member-role.deployment-viewer',
      defaultMessage: 'Viewer',
    },
  }),
  description: defineMessages({
    'organization-admin': {
      id: 'organization.organization-admin.description',
      defaultMessage:
        'Highest role within the organization. Can manage all members, settings, and deployments.',
    },
    'billing-admin': {
      id: 'organization.billing-admin.description',
      defaultMessage: 'Can manage all invoices and payment methods. Can make subscription changes.',
    },
    'deployment-admin': {
      id: 'organization.deployment-admin.description',
      defaultMessage:
        'Can create deployments. Can manage general, hardware, security, and extension settings of assigned deployments and delete them.',
    },
    'deployment-editor': {
      id: 'organization.deployment-editor.description',
      defaultMessage:
        'Can create deployments. Can manage general and hardware settings of assigned deployments and delete them. Can view extensions and security settings.',
    },
    'deployment-viewer': {
      id: 'organization.deployment-viewer.description',
      defaultMessage: 'Can view deployment settings.',
    },
    deploymentAccess: {
      id: 'organization.view-permissions.all.description ',
      defaultMessage: 'Grant access to all current and future deployments',
    },
    specificDeploymentsAccess: {
      id: 'organization.view-permissions.specific.description ',
      defaultMessage: 'Grant access to one or more specific deployments.',
    },
  }),
}

export default messages
