/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { EuiPageTemplate_Deprecated as EuiPageTemplate } from '@elastic/eui'

import { BreadcrumbsContext } from '@modules/cui/Breadcrumbs'

import ChromeHeader from '../../ChromeHeader'
import { portalCrumbs } from '../../../lib/portalCrumbBuilder'

import type { FunctionComponent, ReactNode } from 'react'

import '../errorPages.scss'

type Props = {
  isPortalEnabled: boolean
  children: ReactNode
}

const HttpErrorPage: FunctionComponent<Props> = ({ isPortalEnabled, children }) => (
  <Fragment>
    <BreadcrumbsContext.Consumer>
      {({ breadcrumbsRef }) =>
        isPortalEnabled ? (
          <ChromeHeader
            ref={breadcrumbsRef}
            breadcrumbs={portalCrumbs()}
            hideTrialIndicator={true}
            showHelp={true}
          />
        ) : (
          <ChromeHeader ref={breadcrumbsRef} showBreadcrumbs={false} />
        )
      }
    </BreadcrumbsContext.Consumer>

    <EuiPageTemplate
      template='centeredBody'
      pageContentProps={{
        paddingSize: 'none',
      }}
      style={{ height: '90vh' }}
    >
      {children}
    </EuiPageTemplate>
  </Fragment>
)

export default HttpErrorPage
