/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'
import {
  fetchDeploymentCostItemsRequest,
  fetchStackDeploymentRequest,
  getDeploymentItemsCostsByDeployment,
  getStackDeployment,
  fetchDeploymentTemplateRequest,
  getCostsChartsByDeployment,
  fetchCostsChartsByDeploymentRequest,
} from '@/reducers'
import { fetchDeploymentCostItems, fetchCostsChartsByDeployment } from '@/actions/billing'
import { fetchDeployment } from '@/actions/stackDeployments'
import { fetchDeploymentTemplates } from '@/actions/topology/deploymentTemplates'
import { getVersion, maybeGetRegionId } from '@/lib/stackDeployments/selectors'
import { getDeploymentTemplateInfoForStackDeployment } from '@/lib/reduxShortcuts/deploymentTemplates'

import BillingUsageByDeployment from './BillingUsageByDeployment'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  { organizationId, deploymentFromSearch }: ConsumerProps,
): StateProps => {
  const { id: deploymentId } = deploymentFromSearch
  const deploymentTemplate = getDeploymentTemplateInfoForStackDeployment({
    deployment: deploymentFromSearch,
  })

  return {
    deploymentTemplate,
    deploymentTemplatesRequest: fetchDeploymentTemplateRequest(state),
    itemCosts: getDeploymentItemsCostsByDeployment(state, organizationId),
    itemCostsRequest: fetchDeploymentCostItemsRequest(state),
    deployment: getStackDeployment(state, deploymentFromSearch.id),
    deploymentRequest: fetchStackDeploymentRequest(state),
    costsChartsByDeployment: getCostsChartsByDeployment(state, organizationId, deploymentId),
    costsChartsByDeploymentRequest: fetchCostsChartsByDeploymentRequest(
      state,
      organizationId,
      deploymentId,
    ),
  }
}

const mapDispatchToProps = (
  dispatch,
  { organizationId, deploymentFromSearch }: ConsumerProps,
): DispatchProps => {
  const { id: deploymentId } = deploymentFromSearch
  const regionId = maybeGetRegionId({ deployment: deploymentFromSearch })
  const stackVersion = getVersion({ deployment: deploymentFromSearch })

  return {
    fetchDeploymentCostsItems: (timePeriod) =>
      dispatch(fetchDeploymentCostItems({ organizationId, timePeriod, deploymentId })),
    fetchStackDeployment: () => dispatch(fetchDeployment({ deploymentId })),
    fetchDeploymentTemplates: () => {
      if (regionId) {
        return dispatch(
          fetchDeploymentTemplates({
            regionId,
            stackVersion,
          }),
        )
      }
    },
    fetchCostsChartsByDeployment: ({ from, to, bucketingStrategy }) =>
      dispatch(
        fetchCostsChartsByDeployment({
          organizationId,
          deploymentId,
          from,
          to,
          bucketingStrategy,
        }),
      ),
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(BillingUsageByDeployment)
