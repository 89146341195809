/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState } from '@modules/ui-types'

import { resetUpdateOrganizationRequest, updateOrganization } from '@/actions/organizations'
import { updateOrganizationRequest } from '@/reducers/asyncRequests/registry'

import EnableDiskNotificationsButton from './EnableDiskNotificationsButton'

import type { ReduxState } from '@/types/redux'

interface StateProps {
  setDiskNotificationsRequest: AsyncRequestState
}

interface DispatchProps {
  setDiskNotifications: (enabled: boolean) => void
  resetSetDiskNotifications: () => void
}

interface ConsumerProps {
  organizationId: string
  enabled: boolean
}

const mapStateToProps = (state: ReduxState, props: ConsumerProps): StateProps => ({
  setDiskNotificationsRequest: updateOrganizationRequest(state, props.organizationId),
})

const mapDispatchToProps = (dispatch, { organizationId }: ConsumerProps): DispatchProps => ({
  setDiskNotifications: (enabled) =>
    dispatch(
      updateOrganization({
        organizationId,
        organization: {
          default_disk_usage_alerts_enabled: enabled,
        },
      }),
    ),
  resetSetDiskNotifications: () => dispatch(resetUpdateOrganizationRequest(organizationId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EnableDiskNotificationsButton)
