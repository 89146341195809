/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { findLast, groupBy, capitalize } from 'lodash'
import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiLink, EuiToolTip } from '@elastic/eui'

import type { TlsPublicCertChain, UpdatedTlsChain } from '@modules/cloud-api/v1/types'
import type {
  AsyncRequestState,
  License,
  Region,
  Runner,
  ZooKeeperNodeHealth,
} from '@modules/ui-types'
import { CuiLink } from '@modules/cui/Link'
import { CuiTimeAgo, formatDate } from '@modules/cui/TimeAgo'

import DocLink from '../../components/DocLink'
import { hostUrl } from '../urlBuilder'
import { getConfigForKey } from '../../store'
import { getCertificateServicePrettyName } from '../certificates'

import { isDismissedProblem, prepareProblems } from './problems'

import type { PreparedProblems, Problem, SeverityLevel } from './problems'
import type { ReactNode } from 'react'

export function getPlatformHealthProblems({
  region,
  license,
  fetchLicenseRequest,
  runners,
  uiTlsChain,
  proxyTlsChain,
  internalCasTlsCerts,
}: {
  region?: Region
  license: License | null
  fetchLicenseRequest: AsyncRequestState
  runners: Runner[] | null
  uiTlsChain?: TlsPublicCertChain
  proxyTlsChain?: TlsPublicCertChain
  internalCasTlsCerts?: TlsPublicCertChain
}): PreparedProblems {
  const problems: Problem[] = []

  problems.push(
    ...getTlsChainProblems({
      region,
      tlsChain: uiTlsChain,
      certificateTarget: { service: 'ui' },
    }),
  )

  problems.push(
    ...getTlsChainProblems({
      region,
      tlsChain: proxyTlsChain,
      certificateTarget: { service: 'proxy' },
    }),
  )

  problems.push(
    ...getTlsChainProblems({
      region,
      tlsChain: internalCasTlsCerts,
      certificateTarget: { service: 'internalca' },
    }),
  )

  if (!region) {
    return prepareProblems(problems)
  }

  problems.push(
    ...getLicenseProblems({
      license,
      fetchLicenseRequest,
    }),
  )

  problems.push(
    ...getZooKeeperHealthProblems({
      region,
    }),
  )

  problems.push(
    ...getRegionHealthProblems({
      region,
    }),
  )

  if (Array.isArray(runners)) {
    problems.push(
      ...getPoorTopologyChoiceProblems({
        region,
        runners,
      }),
    )
  }

  return prepareProblems(problems)
}

function getTlsChainProblems({
  region,
  tlsChain,
  certificateTarget,
}: {
  region?: Region
  tlsChain?: TlsPublicCertChain
  certificateTarget: UpdatedTlsChain
}): Problem[] {
  const platform = getConfigForKey(`APP_PLATFORM`)

  if (platform === 'saas') {
    // TLS ends at the edge (e.g. load balancer) in SaaS, so the cert chain is
    // irrelevant *at the ECE level*. Silence these
    // technically-correct-but-irrelevant-and-misleading errors.
    return []
  }

  const expirationThreshold = moment().add(1, `month`)
  const imminentExpirationThreshold = moment().add(5, `day`)

  const { service } = certificateTarget

  const problems: Problem[] = []

  if (region === undefined) {
    return problems
  }

  const regionId = region.id

  function composeProblem(
    level: `warning` | `danger`,
    message: JSX.Element,
    customCertificateRecommendation: boolean = false,
  ): Problem {
    const helpText = tlsChain?.api_managed ? (
      <FormattedMessage
        id='platform-health-problems.platform-tls-expiration-help'
        defaultMessage='Access could be interrupted, upload a new certificate chain. {uploadChainLink}'
        values={{
          uploadChainLink: (
            <CuiLink
              to={`/region/${regionId}/settings#tls`}
              onClick={() => {
                const target = document.querySelector('#tls')

                if (target) {
                  target.scrollIntoView()
                }
              }}
            >
              <FormattedMessage
                id='platform-health-problems.platform-tls-expiration-fix'
                defaultMessage='TLS settings'
              />
            </CuiLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id='platform-health-problems.platform-internal-tls-expiration-help'
        defaultMessage='Service could be interrupted, make sure internal certificates are properly rotated. {rotationProcedureLink}'
        values={{
          rotationProcedureLink: (
            <EuiLink href={`https://ela.st/ece-cert-rotation`}>
              <FormattedMessage
                id='platform-health-problems.platform-internal-tls-expiration-fix'
                defaultMessage='Rotation procedure'
              />
            </EuiLink>
          ),
        }}
      />
    )

    const recommendationText = (
      <FormattedMessage
        id='platform-health-problems.platform-tls-default-certificate-help'
        defaultMessage='Please upload a custom certificate. {uploadChainLink}'
        values={{
          uploadChainLink: (
            <CuiLink
              to={`/region/${regionId}/settings#tls`}
              onClick={() => {
                const target = document.querySelector('#tls')

                if (target) {
                  target.scrollIntoView()
                }
              }}
            >
              <FormattedMessage
                id='platform-health-problems.platform-tls-expiration-fix'
                defaultMessage='TLS settings'
              />
            </CuiLink>
          ),
        }}
      />
    )

    return {
      kind: `platform`,
      id: customCertificateRecommendation
        ? `platform-tls-requirements-${service}`
        : `platform-tls-expiration-${service}`,
      level,
      iconType: `clock`,
      message,
      helpText: customCertificateRecommendation ? recommendationText : helpText,
    }
  }

  if (tlsChain === undefined) {
    return problems
  }

  if (tlsChain.chain_status === undefined) {
    problems.push(
      composeProblem(
        `danger`,
        <FormattedMessage
          id='platform-health-problems.platform-tls-invalid'
          defaultMessage='{service} TLS certificate chain is invalid or has expired'
          values={{
            service,
          }}
        />,
      ),
    )
  } else {
    const expiration = tlsChain.chain_status.expiration_date
    const expires = moment(expiration)

    if (moment().isAfter(expires)) {
      problems.push(
        composeProblem(
          `danger`,
          <FormattedMessage
            id='platform-health-problems.platform-tls-expired'
            defaultMessage='{certificateService} TLS certificate chain expired {when}'
            values={{
              certificateService: (
                <FormattedMessage {...getCertificateServicePrettyName(certificateTarget)} />
              ),
              when: <CuiTimeAgo date={expires} longTime={true} shouldCapitalize={false} />,
            }}
          />,
        ),
      )
    } else if (expires.isBefore(expirationThreshold)) {
      problems.push(
        composeProblem(
          expires.isBefore(imminentExpirationThreshold) ? `danger` : `warning`,
          <FormattedMessage
            id='platform-health-problems.platform-tls-upcoming-expiration'
            defaultMessage='{certificateService} TLS certificate chain is expiring {when}'
            values={{
              certificateService: (
                <FormattedMessage {...getCertificateServicePrettyName(certificateTarget)} />
              ),
              when: <CuiTimeAgo date={expires} longTime={true} shouldCapitalize={false} />,
            }}
          />,
        ),
      )
    }
  }

  if (tlsChain?.api_managed && !tlsChain?.user_supplied) {
    problems.push(
      composeProblem(
        `warning`,
        <FormattedMessage
          id='platform-health-problems.platform-tls-default-certificate'
          defaultMessage='The initial installation TLS certificate chain is being used for {certificateService}'
          values={{
            certificateService: (
              <FormattedMessage {...getCertificateServicePrettyName(certificateTarget)} />
            ),
          }}
        />,
        true,
      ),
    )
  }

  return problems
}

function getLicenseProblems({
  license,
  fetchLicenseRequest,
}: {
  license: License | null | undefined
  fetchLicenseRequest: AsyncRequestState
}): Problem[] {
  const platform = getConfigForKey(`APP_PLATFORM`)
  const problems: Problem[] = []

  if (showLicenseExpiry({ fetchLicenseRequest, license })) {
    if (license === null) {
      // a `null` license means the license was deleted
      problems.push({
        kind: `platform`,
        id: `platform-license-missing`,
        level: `danger`,
        iconType: `bell`,
        message: (
          <FormattedMessage
            id='platform-health-problems.platform-license-missing'
            defaultMessage='Add a license to continue enjoying Elastic Cloud Enterprise'
          />
        ),
        helpText: platform === `ece` && (
          <FormattedMessage
            id='platform-health-problems.platform-license-missing-help'
            defaultMessage='Check out our {docLink}'
            values={{
              docLink: (
                <DocLink link='addEceLicenseDocLink'>
                  <FormattedMessage
                    id='platform-health-problems.platform-license-missing-link'
                    defaultMessage='instructions for adding a license'
                  />
                </DocLink>
              ),
            }}
          />
        ),
      })
    } else {
      const expiration = license!.expires
      const hasLicenseExpired = moment(expiration).isBefore(moment())

      problems.push({
        kind: `platform`,
        id: `platform-license-expiration`,
        level: hasLicenseExpired ? `danger` : `warning`,
        iconType: hasLicenseExpired ? `bell` : `clock`,
        message: (
          <EuiFlexGroup gutterSize='s' alignItems='center'>
            <EuiFlexItem grow={false}>
              <FormattedMessage
                id='platform-health-problems.update-platform-license'
                defaultMessage='Update the license to continue enjoying Elastic Cloud Enterprise'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {hasLicenseExpired ? (
                <EuiToolTip
                  content={
                    <FormattedMessage
                      id='platform-health-problems.platform-license-expired-when'
                      defaultMessage='The license expired {when}'
                      values={{
                        when: formatDate(expiration, { longTime: true }),
                      }}
                    />
                  }
                >
                  <EuiBadge color='danger'>
                    <FormattedMessage
                      id='platform-health-problems.platform-license-expired'
                      defaultMessage='License expired'
                    />
                  </EuiBadge>
                </EuiToolTip>
              ) : (
                <EuiBadge color='warning'>
                  <FormattedMessage
                    id='platform-health-problems.platform-license-expires-when'
                    defaultMessage='License expires {when}'
                    values={{
                      when: (
                        <CuiTimeAgo date={expiration} shouldCapitalize={false} longTime={true} />
                      ),
                    }}
                  />
                </EuiBadge>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
        helpText: platform === `ece` && (
          <FormattedMessage
            id='platform-health-problems.platform-license-expiration-help'
            defaultMessage='Check out our {docLink}'
            values={{
              docLink: (
                <DocLink link='addEceLicenseDocLink'>
                  <FormattedMessage
                    id='platform-health-problems.platform-license-expiration-link'
                    defaultMessage='instructions for updating the license'
                  />
                </DocLink>
              ),
            }}
          />
        ),
      })
    }
  }

  return problems
}

function getRegionHealthProblems({ region }: { region: Region }): Problem[] {
  const platform = getConfigForKey(`APP_PLATFORM`)
  const problems: Problem[] = []

  const zoneCount = region.allocators.zones.count?.total

  if (zoneCount && zoneCount < 2) {
    const addAllocatorText = (
      <FormattedMessage
        id='platform-health-problems.region-not-highly-available-link'
        defaultMessage='adding another allocator'
      />
    )

    problems.push({
      kind: `region`,
      id: `region-not-highly-available`,
      level: `warning`,
      iconType: `scale`,
      message: (
        <FormattedMessage
          id='platform-health-problems.region-not-highly-available'
          defaultMessage='Running on a single allocator could lead to data loss'
        />
      ),
      helpText: (
        <FormattedMessage
          id='platform-health-problems.region-not-highly-available-help'
          defaultMessage='Make this region highly available by {addAllocatorLink}'
          values={{
            addAllocatorLink:
              platform === `ece` ? (
                <DocLink link='addCapacityDocLink'>{addAllocatorText}</DocLink>
              ) : (
                addAllocatorText
              ),
          }}
        />
      ),
    })
  }

  return problems
}

function getZooKeeperHealthProblems({ region }: { region: Region }): Problem[] {
  const problems: Problem[] = []

  if (region.zookeeper.healthy) {
    return problems
  }

  const nodes = Object.keys(region.zookeeper.zookeeper)
  const healthGroups = groupBy(nodes, (node) => region.zookeeper.zookeeper[node])

  checkZooKeeperHealthGroup({
    level: `danger`,
    health: 'LOST',
    getHealthProblem: (nodeCount) => (
      <FormattedMessage
        id='platform-health-problems.zookeeper-node-connection-lost'
        defaultMessage='Lost connection to {nodeCount, plural, one {a ZooKeeper node} other {several ZooKeeper nodes}}'
        values={{ nodeCount }}
      />
    ),
  })

  checkZooKeeperHealthGroup({
    level: `warning`,
    health: 'READ_ONLY',
    getHealthProblem: (nodeCount) => (
      <FormattedMessage
        id='platform-health-problems.zookeeper-node-connection-read-only'
        defaultMessage='{nodeCount, plural, one {A ZooKeeper node is} other {Several ZooKeeper nodes are}} in read-only mode'
        values={{ nodeCount }}
      />
    ),
  })

  checkZooKeeperHealthGroup({
    level: `warning`,
    health: 'SUSPENDED',
    getHealthProblem: (nodeCount) => (
      <FormattedMessage
        id='platform-health-problems.zookeeper-node-connection-suspended'
        defaultMessage='Connection to {nodeCount, plural, one {a ZooKeeper node} other {several ZooKeeper nodes}} is suspended'
        values={{ nodeCount }}
      />
    ),
  })

  return problems

  function checkZooKeeperHealthGroup({
    level,
    health,
    getHealthProblem,
  }: {
    level: SeverityLevel
    health: ZooKeeperNodeHealth
    getHealthProblem: (nodeCount: number) => ReactNode
  }) {
    if (!(health in healthGroups)) {
      return
    }

    const affectedNodes = healthGroups[health]

    problems.push({
      kind: `zookeeper`,
      level,
      id: `zookeeper-connection-${health.toLowerCase()}`,
      iconType: `cloudStormy`,
      message: getHealthProblem(affectedNodes.length),
      helpText: (
        <EuiFlexGroup gutterSize='s' alignItems='center'>
          <EuiFlexItem grow={false}>
            <FormattedMessage
              id='platform-health-problems.zookeeper-affected-nodes'
              defaultMessage='Affects'
            />
          </EuiFlexItem>

          {affectedNodes.map((affectedNode) => (
            <EuiFlexItem grow={false} key={affectedNode}>
              <EuiBadge color='hollow'>{capitalize(affectedNode)}</EuiBadge>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      ),
    })
  }
}

function getPoorTopologyChoiceProblems({
  region,
  runners,
}: {
  region: Region
  runners: Runner[]
}): Problem[] {
  const platform = getConfigForKey(`APP_PLATFORM`)
  const problems: Problem[] = []

  for (const runner of runners) {
    const roles = runner.roles.map((r) => r.role_name)
    const isAllocator = roles.includes(`allocator`)
    const isCoordinator = roles.includes(`coordinator`)
    const isDirector = roles.includes(`director`)
    const isProxy = roles.includes(`proxy`)

    const isPoorChoice =
      (isAllocator && isCoordinator) || (isAllocator && isDirector) || (isCoordinator && isProxy)

    if (!isPoorChoice) {
      continue
    }

    const runnerLink = (
      <CuiLink to={hostUrl(region.id, runner.runner_id)}>{runner.runner_id}</CuiLink>
    )

    if (isAllocator && isCoordinator) {
      problems.push({
        kind: `runner`,
        id: `runner-poor-topology-choice-allocator-coordinator:${region.id}:${runner.runner_id}`,
        level: `info`,
        iconType: `alert`,
        message: (
          <FormattedMessage
            id='platform-health-problems.host-poor-topology-choice-allocator-coordinator'
            defaultMessage='Host {runnerLink} is both an allocator and a controller'
            values={{ runnerLink }}
          />
        ),
        dismissible: true,
        dismissGroup: `runner-poor-topology-choice:${region.id}`,
      })
    }

    if (isAllocator && isDirector) {
      problems.push({
        kind: `runner`,
        id: `runner-poor-topology-choice-allocator-director:${region.id}:${runner.runner_id}`,
        level: `info`,
        iconType: `alert`,
        message: (
          <FormattedMessage
            id='platform-health-problems.host-poor-topology-choice-allocator-director'
            defaultMessage='Host {runnerLink} is both an allocator and a director'
            values={{ runnerLink }}
          />
        ),
        dismissible: true,
        dismissGroup: `runner-poor-topology-choice:${region.id}`,
      })
    }

    if (isCoordinator && isProxy) {
      problems.push({
        kind: `runner`,
        id: `runner-poor-topology-choice-coordinator-proxy:${region.id}:${runner.runner_id}`,
        level: `info`,
        iconType: `alert`,
        message: (
          <FormattedMessage
            id='platform-health-problems.host-poor-topology-choice-coordinator-proxy'
            defaultMessage='Host {runnerLink} is both a controller and a proxy'
            values={{ runnerLink }}
          />
        ),
        dismissible: true,
        dismissGroup: `runner-poor-topology-choice:${region.id}`,
      })
    }
  }

  const lastVisibleProblem = findLast(problems, (problem) => !isDismissedProblem(problem))

  if (lastVisibleProblem) {
    const separateRolesText = (
      <FormattedMessage
        id='platform-health-problems.separate-roles-text'
        defaultMessage='separating control plane roles from allocators and proxies'
      />
    )

    lastVisibleProblem.helpText = (
      <FormattedMessage
        id='platform-health-problems.host-poor-topology-choice-help'
        defaultMessage='We strongly recommend {separateRolesLink}'
        values={{
          separateRolesLink:
            platform === `ece` ? (
              <DocLink link='separateRunnerRolesLink'>{separateRolesText}</DocLink>
            ) : (
              separateRolesText
            ),
        }}
      />
    )
  }

  return problems
}

function showLicenseExpiry({
  fetchLicenseRequest,
  license,
}: {
  fetchLicenseRequest: AsyncRequestState
  license: License | undefined | null
}) {
  if (license === null) {
    // a `null` license means the license was deleted
    return true
  }

  const requestSuccess = fetchLicenseRequest.isDone && !fetchLicenseRequest.error

  if (license === undefined) {
    // an `undefined` license means we're probably fetching
    return requestSuccess
  }

  const expiryIndicatorLimit = moment().add(1, `week`)
  const expires = moment(license.expires)

  return expires.isBefore(expiryIndicatorLimit)
}
