/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { isEqual, merge } from 'lodash'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiLink,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiToolTip,
} from '@elastic/eui'

import type { DeploymentUpdateRequest } from '@modules/cloud-api/v1/types'
import Permission from '@modules/cloud-api/v1/permissions'
import type { AsyncRequestState, StackDeployment } from '@modules/ui-types'
import { CuiAlert, CuiFieldEmpty, CuiPermissibleControl } from '@modules/cui'
import { prettyDuration } from '@modules/utils/prettyTime'

import { getSnapshotSettings } from '@/lib/stackDeployments/selectors/snapshots'
import { createUpdateRequestFromGetResponse } from '@/lib/stackDeployments'
import { getFirstEsCluster, getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors'

import SpinButton from '../../../SpinButton'
import DocLink from '../../../DocLink'
import toNumber, { toNumberOrElse } from '../../../../lib/toNumber'
import { parseWeirdApiTimeAsMs, parseWeirdApiTimeObject } from '../../../../lib/weirdTime'
import lightTheme from '../../../../lib/theme/light'
import { hasPermission, ifPermitted } from '../../../../lib/requiresPermission'

import messages from './messages'

import type { WrappedComponentProps } from 'react-intl'

const { euiBreakpoints } = lightTheme

export type StateProps = {
  updateDeploymentRequest: AsyncRequestState
}

export type DispatchProps = {
  updateDeployment: (payload: DeploymentUpdateRequest) => void
}

export type ConsumerProps = {
  deployment: StackDeployment
  isUserConsole: boolean
}

export type Props = StateProps & DispatchProps & ConsumerProps & WrappedComponentProps

interface SnapshotState {
  snapshots: number
  maxAge: string
  interval: {
    value: number
    unit: 'min' | 'h' | 'd' | 's'
    ms: number
  }
}

interface State {
  isModalOpen: boolean
  showIntervalWarning: boolean
  settings?: SnapshotState
}

class SnapshotSettings extends Component<Props, State> {
  state: State = {
    isModalOpen: false,
    showIntervalWarning: false,
  }

  componentDidMount() {
    if (this.props.deployment) {
      this.buildSnapshotState()
    }
  }

  componentDidUpdate(prevProps) {
    const current = getSnapshotSettings({ deployment: this.props.deployment })
    const previous = prevProps && getSnapshotSettings({ deployment: prevProps.deployment })

    if (!isEqual(current, previous)) {
      this.buildSnapshotState()
    }
  }

  render() {
    return (
      <Fragment>
        <EuiLink data-test-id='open-snapshot-settings-modal' onClick={this.openModal}>
          <FormattedMessage id='snapshot-settings.edit-link' defaultMessage='Edit settings' />
        </EuiLink>

        {this.renderModal()}
      </Fragment>
    )
  }

  renderModal() {
    const { isModalOpen, settings } = this.state

    if (!isModalOpen) {
      return null
    }

    const {
      intl: { formatMessage },
      updateDeploymentRequest,
    } = this.props

    const value = settings?.interval.value ?? ''
    const unit = settings?.interval.unit ?? ''

    return (
      <EuiOverlayMask>
        <EuiModal onClose={this.closeModal} style={{ width: euiBreakpoints.m }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage id='snapshot-settings.title' defaultMessage='Settings' />
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{this.renderModalBody()}</EuiModalBody>

          <EuiModalFooter>
            <div>
              <EuiFlexGroup gutterSize='m' justifyContent='flexEnd'>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty onClick={this.closeModal}>
                    <FormattedMessage id='snapshot-settings.close' defaultMessage='Close' />
                  </EuiButtonEmpty>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <div data-test-id='update-snapshot-repo-button'>
                    {ifPermitted(
                      Permission.updateDeployment,
                      () => (
                        <EuiToolTip
                          position='right'
                          content={formatMessage(messages.changeMessage)}
                        >
                          <SpinButton
                            disabled={value === '' || unit === ''}
                            fill={true}
                            onClick={() => this.updateSnapshotSettings()}
                            spin={updateDeploymentRequest.inProgress}
                          >
                            {formatMessage(messages.saveSettings)}
                          </SpinButton>
                        </EuiToolTip>
                      ),
                      () => (
                        <CuiPermissibleControl permissions={Permission.updateDeployment}>
                          <EuiButton>{formatMessage(messages.saveSettings)}</EuiButton>
                        </CuiPermissibleControl>
                      ),
                    )}
                  </div>
                </EuiFlexItem>
              </EuiFlexGroup>

              {updateDeploymentRequest.error && (
                <Fragment>
                  <EuiSpacer size='m' />
                  <CuiAlert size='s' type='error'>
                    {updateDeploymentRequest.error}
                  </CuiAlert>
                </Fragment>
              )}

              {updateDeploymentRequest.isDone && !updateDeploymentRequest.error && (
                <Fragment>
                  <EuiSpacer size='m' />
                  <EuiCallOut
                    size='s'
                    title={formatMessage(messages.snapshotSettingsRequestSuccess)}
                  />
                </Fragment>
              )}
            </div>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  renderModalBody() {
    const {
      intl: { formatMessage },
    } = this.props

    const { showIntervalWarning, settings } = this.state

    if (!settings) {
      return <EuiLoadingSpinner />
    }

    const { snapshots, maxAge } = settings!

    const retentionInMs = parseWeirdApiTimeAsMs(maxAge)
    const prettyRetentionTime = prettyDuration({ milliseconds: retentionInMs })
    const isUpdateDenied = !hasPermission(Permission.updateDeployment)

    return (
      <div data-test-id='snapshot-settings'>
        <EuiText>
          <FormattedMessage
            id='snapshot-settings.saas.settings'
            defaultMessage='Select the snapshot interval and the number of snapshots to save.'
          />
        </EuiText>

        <EuiSpacer size='m' />

        <EuiForm>
          <EuiFormRow
            label={formatMessage(messages.intervalLabel)}
            helpText={
              showIntervalWarning ? (
                <FormattedMessage
                  id='snapshot-settings.interval-help-warning'
                  defaultMessage='Time between snapshots. {intervalWarning}.'
                  values={{
                    intervalWarning: (
                      <EuiTextColor color='warning'>
                        <span data-test-id='cluster-update-snapshot-settings.interval-warning'>
                          <FormattedMessage
                            id='snapshot-settings.data-loss-warning'
                            defaultMessage='Long snapshot intervals between snapshots increase the {riskOfLosingData}'
                            values={{
                              riskOfLosingData: (
                                <DocLink link='snapshotRetentionDocLink'>
                                  <FormattedMessage
                                    id='snapshot-settings.data-loss-link'
                                    defaultMessage='risk of losing your most recent data'
                                  />
                                </DocLink>
                              ),
                            }}
                          />
                        </span>
                      </EuiTextColor>
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id='snapshot-settings.interval-help'
                  defaultMessage='Time between snapshots'
                />
              )
            }
          >
            {this.renderIntervalField()}
          </EuiFormRow>

          <div>
            <EuiFlexGroup gutterSize='m'>
              <EuiFlexItem grow={false}>
                <EuiFormRow
                  label={formatMessage(messages.numberOfSnapshotsLabel)}
                  helpText={formatMessage(messages.numberOfSnapshotsHelp)}
                >
                  <EuiToolTip position='right' content={formatMessage(messages.numberOfSnapshots)}>
                    <EuiFieldNumber
                      disabled={isUpdateDenied}
                      min={2}
                      max={100}
                      step={1}
                      name='number-of-snapshots'
                      value={snapshots || 0}
                      onChange={(e) => this.handleChange({ snapshots: toNumber(e.target.value) })}
                    />
                  </EuiToolTip>
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiFormRow label={formatMessage(messages.retentionPeriodLabel)}>
                  <CuiFieldEmpty>
                    <span>{prettyRetentionTime}</span>
                    <span>{` `}</span>
                    <EuiToolTip position='right' content={formatMessage(messages.retentionPeriod)}>
                      <EuiIcon type='iInCircle' />
                    </EuiToolTip>
                  </CuiFieldEmpty>
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        </EuiForm>
      </div>
    )
  }

  renderIntervalField() {
    const {
      isUserConsole,
      intl: { formatMessage },
    } = this.props

    const { value, unit } = this.getSnapshotInterval()

    const presetInterval = [
      { value: `min`, text: formatMessage(messages.minutes) },
      { value: `h`, text: formatMessage(messages.hours) },
      { value: `d`, text: formatMessage(messages.days) },
    ]

    const disabled = !hasPermission(Permission.updateDeployment)

    if (isUserConsole) {
      return (
        <EuiSelect
          disabled={disabled}
          options={[
            { value: `30`, text: formatMessage(messages.valueMinutes, { value: 30 }) },
            { value: String(4 * 60), text: formatMessage(messages.valueHours, { value: 4 }) },
            { value: String(24 * 60), text: formatMessage(messages.valueHours, { value: 24 }) },
          ]}
          onChange={(e) => this.handleChange({ interval: { value: e.target.value, unit: 'min' } })}
          value={value}
        />
      )
    }

    return (
      <EuiFlexGroup gutterSize='m'>
        <EuiFlexItem>
          <EuiFieldNumber
            disabled={disabled}
            onChange={(e) => this.handleChange({ interval: { value: e.target.value, unit } })}
            min={0}
            value={toNumberOrElse(value, 0)}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiSelect
            disabled={disabled}
            options={presetInterval}
            hasNoInitialSelection={value === '' || unit === ''}
            value={unit}
            data-test-id='update-snapshot-settings'
            onChange={(e) => this.handleChange({ interval: { value, unit: e.target.value } })}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  handleChange(val) {
    const { settings } = this.state
    const updatedSnapshotSettings = merge({}, settings, val)
    const { ms: intervalMs } = parseWeirdApiTimeObject(updatedSnapshotSettings.interval)
    const { snapshots, interval } = updatedSnapshotSettings
    const maxAge = `${snapshots * interval.value}${interval.unit}`
    this.setState({
      showIntervalWarning: this.checkShowIntervalWarning(intervalMs),
      settings: {
        ...updatedSnapshotSettings,
        maxAge,
      },
    })
  }

  updateSnapshotSettings(): void {
    const { updateDeployment, deployment: currentDeployment } = this.props
    const { settings } = this.state
    const { value, unit } = settings!.interval

    const currentSettings = getSnapshotSettings({ deployment: currentDeployment })

    if (
      currentSettings.interval.unit === unit &&
      currentSettings.interval.value === value &&
      currentSettings.snapshots === settings?.snapshots
    ) {
      // No-op if there are no changes.
      return
    }

    const deployment = createUpdateRequestFromGetResponse({ deployment: currentDeployment })

    const currentResource = getFirstEsClusterFromGet({ deployment: currentDeployment })
    const resource = getFirstEsCluster({ deployment })

    if (!resource) {
      return // sanity
    }

    resource.settings = {
      snapshot: merge(currentResource?.info.settings?.snapshot || {}, {
        interval: `${value}${unit}`,
        retention: {
          max_age: settings?.maxAge,
          snapshots: settings?.snapshots,
        },
      }),
    }

    updateDeployment(deployment)
  }

  getSnapshotInterval() {
    const { settings } = this.state
    const {
      interval: { value, unit },
    } = settings!

    if (unit.startsWith(`s`)) {
      return {
        value: String(Math.floor(value / 60)),
        unit: `m`,
      }
    }

    return {
      value: String(value),
      unit,
    }
  }

  buildSnapshotState() {
    const { deployment } = this.props
    const settings = getSnapshotSettings({ deployment })

    this.setState({
      showIntervalWarning: this.checkShowIntervalWarning(settings.interval.ms),
      settings: {
        snapshots: settings.snapshots,
        interval: settings.interval,
        maxAge: settings.maxAge,
      },
    })
  }

  checkShowIntervalWarning(intervalMs: number): boolean {
    const dayInMs = 60 * 60 * 24 * 1000
    return intervalMs >= dayInMs
  }

  openModal = () => {
    this.setState({ isModalOpen: true })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }
}

export default injectIntl(SnapshotSettings)
