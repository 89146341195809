/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import type { RegionInfo } from '@modules/cloud-api/v1/types'
import { CuiLink } from '@modules/cui'

import { hostsProxiesUrl } from '../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'

type Props = {
  region: RegionInfo
}

const RegionProxiesStatus: FunctionComponent<Props> = ({ region }) => {
  const { proxies } = region
  const proxiesCount = proxies.proxies_count
  const healthy = proxiesCount > 0 && proxies.healthy

  return (
    <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiIcon type='dot' color={healthy ? 'success' : 'warning'} />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <CuiLink to={hostsProxiesUrl(region.region_id)}>{proxiesCount}</CuiLink>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default RegionProxiesStatus
