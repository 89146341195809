/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { RoutableBreadcrumb } from '@modules/ui-types'
import type { ProjectType } from '@modules/ui-types/projects'
import type { ProjectID, ProjectName } from '@modules/project-api/v1/types'

import { projectPerformanceUrl, projectSecurityUrl, projectUrl, projectsUrl } from './urls'
import { getDisplayProjectId } from './display'

export const projectsCrumbs = (): RoutableBreadcrumb[] => [
  {
    to: projectsUrl(),
    text: <FormattedMessage id='crumb-builder.projects-crumbs' defaultMessage='Projects' />,
  },
]

export const projectCrumbs = ({
  projectType,
  projectId,
  projectName,
}: {
  projectType: ProjectType
  projectId: ProjectID
  projectName: ProjectName | undefined
}): RoutableBreadcrumb[] => [
  ...projectsCrumbs(),

  {
    to: projectUrl(projectType, projectId),
    text: projectName || getDisplayProjectId(projectId),
  },
]

export const projectPerformanceCrumbs = ({
  projectType,
  projectId,
  projectName,
}: {
  projectType: ProjectType
  projectId: ProjectID
  projectName: ProjectName | undefined
}): RoutableBreadcrumb[] => [
  ...projectCrumbs({ projectType, projectId, projectName }),

  {
    to: projectPerformanceUrl(projectType, projectId),
    text: (
      <FormattedMessage
        id='crumb-builder.project-performance-crumbs'
        defaultMessage='Performance'
      />
    ),
  },
]

export const projectSecurityCrumbs = ({
  projectType,
  projectId,
  projectName,
}: {
  projectType: ProjectType
  projectId: ProjectID
  projectName: ProjectName | undefined
}): RoutableBreadcrumb[] => [
  ...projectCrumbs({ projectType, projectId, projectName }),

  {
    to: projectSecurityUrl(projectType, projectId),
    text: <FormattedMessage id='crumb-builder.project-security-crumbs' defaultMessage='Security' />,
  },
]
