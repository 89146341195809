/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import { downloadClusterLogs, resetDownloadClusterLogsRequest } from '../../../actions/clusters'
import { getClusterLogs, downloadClusterLogsRequest } from '../../../reducers'
import { isFeatureActivated } from '../../../selectors'

import ClusterLogs from './ClusterLogs'

import type { ConsumerProps, StateProps, DispatchProps } from './ClusterLogs'

const mapStateToProps = (state, { deployment, resource }: ConsumerProps): StateProps => ({
  clusterLogs: getClusterLogs(state, deployment.id, resource.ref_id),
  downloadClusterLogsRequest: downloadClusterLogsRequest(state, deployment.id, resource.ref_id),
  isEnabled: isFeatureActivated(state, Feature.downloadClusterLogs),
})

const mapDispatchToProps: DispatchProps = {
  downloadClusterLogs,
  resetDownloadClusterLogsRequest,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ClusterLogs)
