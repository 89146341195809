/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiToolTip,
} from '@elastic/eui'

import type { AsyncRequestState, Runner } from '@modules/ui-types'
import { CuiAlert, CuiButtonEmpty, CuiButton } from '@modules/cui'

interface Props {
  regionId: string
  hostId: string
  runner?: Runner
  deleteRunnerAndRedirect: () => Promise<any>
  deleteRunnerRequest: AsyncRequestState
  onClose: () => void
}

class DeleteHost extends Component<Props> {
  render() {
    const { onClose, runner } = this.props

    return (
      <EuiOverlayMask>
        <EuiModal onClose={onClose}>
          <EuiModalHeader>
            <EuiTitle>
              <h3>
                <FormattedMessage id='delete-host.title' defaultMessage='Delete host?' />
              </h3>
            </EuiTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiText>
              <FormattedMessage
                id='delete-host.explanation'
                defaultMessage='You can delete a host if you are de-provisioning an instance.'
              />
            </EuiText>

            {runner && runner.connected && (
              <Fragment>
                <EuiSpacer size='m' />

                <EuiCallOut
                  color='warning'
                  title={
                    <FormattedMessage
                      id='delete-host.runner-is-connected'
                      defaultMessage='Disconnect this host before deleting it'
                    />
                  }
                />
              </Fragment>
            )}
          </EuiModalBody>

          <EuiModalFooter>{this.renderModalFooter()}</EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  renderModalFooter() {
    const { runner, deleteRunnerAndRedirect, deleteRunnerRequest, onClose } = this.props
    const cannotDeleteHost = !runner || runner.connected

    const deleteHostButton = (
      <CuiButton
        disabled={cannotDeleteHost}
        onClick={deleteRunnerAndRedirect}
        spin={deleteRunnerRequest.inProgress}
        color='danger'
        fill={true}
      >
        <FormattedMessage id='delete-host.delete' defaultMessage='Delete host' />
      </CuiButton>
    )

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' justifyContent='flexEnd'>
          <EuiFlexItem grow={false}>
            <CuiButtonEmpty onClick={() => onClose()}>
              <FormattedMessage id='delete-host.cancel' defaultMessage='Cancel' />
            </CuiButtonEmpty>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            {cannotDeleteHost ? (
              <EuiToolTip
                content={
                  <FormattedMessage
                    id='delete-host.cannot-delete-host'
                    defaultMessage='Disconnect this host before deleting it'
                  />
                }
                position='left'
              >
                {deleteHostButton}
              </EuiToolTip>
            ) : (
              deleteHostButton
            )}
          </EuiFlexItem>
        </EuiFlexGroup>

        {deleteRunnerRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{deleteRunnerRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default DeleteHost
