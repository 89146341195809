/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, withErrorBoundary } from '@modules/cui'

import DangerButton from '../../../DangerButton'

import type { FunctionComponent } from 'react'

interface Props {
  retryInvoices: () => void
  retryInvoicesRequest: AsyncRequestState
}

const RetryInvoicesButton: FunctionComponent<Props> = ({ retryInvoices, retryInvoicesRequest }) => (
  <Fragment>
    <DangerButton
      buttonType={EuiButtonEmpty}
      size='s'
      color='primary'
      fill={false}
      onConfirm={retryInvoices}
      spin={retryInvoicesRequest.inProgress}
    >
      <FormattedMessage
        id='organization.organization-overview.retry-invoices-button.button-text'
        defaultMessage='Retry past due invoices'
      />
    </DangerButton>

    {retryInvoicesRequest.error && (
      <Fragment>
        <EuiSpacer size='m' />

        <CuiAlert type='error'>{retryInvoicesRequest.error}</CuiAlert>
      </Fragment>
    )}
  </Fragment>
)

export default withErrorBoundary(RetryInvoicesButton)
