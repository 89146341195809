/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { InstanceConfiguration } from '@modules/cloud-api/v1/types'

import { fetchInstanceTypes } from '../../../../actions/topology/fetchInstanceTypes'
import { fetchInstanceConfigurations } from '../../../../actions/topology/instanceConfigurations'
import { getInstanceTypes } from '../../../../reducers'

import InstanceConfigurationWizard from './InstanceConfigurationWizard'

import type { ReactNode } from 'react'

type Props = {
  regionId: string
  instanceConfiguration?: InstanceConfiguration
  saveButtonLabel: ReactNode
  isSaving: boolean
  onSave: (instanceConfiguration: InstanceConfiguration) => void
  error?: Error | string
  prompt?: ReactNode
}

const mapStateToProps = (state, { regionId }: Props) => ({
  instanceTypes: getInstanceTypes(state, regionId),
})

const mapDispatchToProps = (dispatch, { regionId }: Props) => ({
  fetchInstanceTypes: () => dispatch(fetchInstanceTypes(regionId)),
  fetchInstanceConfigurations: () => dispatch(fetchInstanceConfigurations(regionId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InstanceConfigurationWizard)
