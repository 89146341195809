/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiSpacer,
  EuiHorizontalRule,
  EuiFlexGrid,
  EuiFlexItem,
  EuiStat,
  EuiText,
  EuiFlexGroup,
} from '@elastic/eui'

import type { AsyncRequestError } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import arrowIcon from '@/files/icon-long-arrow-right-24-sky-blue.svg'

import UsageStat from '../UsageStat'
import messages from '../../messages'

import { flipCardUsageRates } from './constants'

import type { FlipCardProps } from '../FlipCard/types'
import type { FunctionComponent } from 'react'
import type { CurrentRates } from './types'

import './styles.scss'

interface Props extends FlipCardProps {
  loading: boolean
  error?: AsyncRequestError
  currentRates: CurrentRates
}

const BackSide: FunctionComponent<Props> = ({ flipCard, loading, error, currentRates }) => (
  <EuiFlexGroup direction='column' gutterSize='none'>
    <EuiButtonEmpty
      className='flip-button'
      onClick={flipCard}
      iconSide='right'
      iconType={arrowIcon}
    >
      <FormattedMessage id='usage-flip-card.back.done' defaultMessage='Hide' />
    </EuiButtonEmpty>

    <EuiSpacer size='l' />

    <EuiText>
      <h2>
        <FormattedMessage id='usage-flip-card.back.title' defaultMessage='Current Usage Rates' />
      </h2>
    </EuiText>

    <EuiHorizontalRule margin='xl' />

    <EuiSpacer size='l' />

    {error && (
      <CuiAlert type='error' data-test-id='error-message'>
        <FormattedMessage {...messages.genericErrorMessage} />
      </CuiAlert>
    )}

    {!error && (
      <EuiFlexGrid columns={2} gutterSize='m' responsive={false} data-test-id='current-usage-rates'>
        {flipCardUsageRates.map(({ key, description, dataTestId }) => (
          <EuiFlexItem key={key}>
            <EuiStat
              isLoading={loading}
              title={
                <UsageStat title={currentRates[key].formatted_value} data-test-id={dataTestId} />
              }
              description={<EuiText size='s'>{description}</EuiText>}
              descriptionElement='div'
              titleSize='xs'
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    )}
  </EuiFlexGroup>
)

export default BackSide
