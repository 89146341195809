/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { difference, union, isEmpty, size } from 'lodash'

import {
  EuiBadge,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFilterSelectItem,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiCheckbox,
  EuiText,
  EuiTitle,
  htmlIdGenerator,
} from '@elastic/eui'

import { CuiLink } from '@modules/cui'

import { getNamedRole } from '../../../lib/hostRoles'
import { hostsAllocatorsUrl } from '../../../lib/urlBuilder'

import PlatformVisualization from './PlatformVisualization'
import { roleColors, getZoneRunnerRoles, getRunnerRoles } from './runnerRoles'

import type { CombinedPlatformData, NodeType } from './infrastructureVisualizationTypes'

const makeId = htmlIdGenerator()

interface Props {
  regionId: string
  zoneId: string
  zone: CombinedPlatformData[]
}

type HealthFilter = null | 'healthy' | 'unhealthy' | 'maintenance'

interface State {
  healthFilter: HealthFilter
  roleFilters: NodeType[]
  roleFilterExact: boolean
  isHealthPopoverOpen: boolean
  isRolesPopoverOpen: boolean
}

class PlatformVisualizationZone extends Component<Props, State> {
  state: State = {
    healthFilter: null,
    roleFilters: [],
    roleFilterExact: false,
    isHealthPopoverOpen: false,
    isRolesPopoverOpen: false,
  }

  render() {
    const { regionId, zoneId } = this.props
    const zone = this.applyFiltersToZone()
    const zoneParam = encodeURIComponent(zoneId)
    const allocatorsHref = hostsAllocatorsUrl(regionId)
    const allocatorsForZoneHref = `${allocatorsHref}?q=connected:y+zone:${zoneParam}`

    return (
      <EuiPanel className='platformVisualization-vizItemContainer'>
        <EuiTitle size='s'>
          <h4>
            <CuiLink to={allocatorsForZoneHref}>{zoneId}</CuiLink>
          </h4>
        </EuiTitle>

        <EuiSpacer size='l' />

        {this.renderFilters()}

        <EuiSpacer size='xl' />

        {isEmpty(zone) ? (
          <EuiText size='s' color='subdued'>
            <FormattedMessage
              id='platform-visualization.no-matches'
              defaultMessage='No matches for your current filters.'
            />
          </EuiText>
        ) : (
          <div
            id={`platformVisualization-vizItemInnerContainer-${zoneId}`}
            className='platformVisualization-vizItemInnerContainer'
          >
            <PlatformVisualization regionId={regionId} zone={zone} />
          </div>
        )}
      </EuiPanel>
    )
  }

  renderFilters() {
    const { healthFilter, roleFilters, roleFilterExact, isHealthPopoverOpen, isRolesPopoverOpen } =
      this.state

    const { zone } = this.props

    const roles = getZoneRunnerRoles(zone)

    return (
      <EuiFilterGroup compressed={true}>
        <EuiPopover
          id={makeId()}
          ownFocus={true}
          button={
            <EuiFilterButton
              iconType='arrowDown'
              isSelected={isHealthPopoverOpen}
              onClick={this.toggleHealthPopover}
              hasActiveFilters={healthFilter !== null}
            >
              {healthFilter === null ? (
                <FormattedMessage
                  id='platform-visualization.runner-health'
                  defaultMessage='Health'
                />
              ) : (
                this.renderHealthLabel(healthFilter)
              )}
            </EuiFilterButton>
          }
          isOpen={isHealthPopoverOpen}
          closePopover={this.closeHealthPopover}
          panelPaddingSize='none'
        >
          <div className='euiFilterSelect__items' style={{ width: 200 }}>
            <EuiFilterSelectItem
              checked={healthFilter === null ? `on` : undefined}
              onClick={() => this.setHealthFilter(null)}
            >
              {this.renderHealthLabel(null)}
            </EuiFilterSelectItem>

            <EuiFilterSelectItem
              checked={healthFilter === `healthy` ? `on` : undefined}
              onClick={() => this.setHealthFilter(`healthy`)}
            >
              {this.renderHealthLabel(`healthy`)}
            </EuiFilterSelectItem>

            <EuiFilterSelectItem
              checked={healthFilter === `unhealthy` ? `on` : undefined}
              onClick={() => this.setHealthFilter(`unhealthy`)}
            >
              {this.renderHealthLabel(`unhealthy`)}
            </EuiFilterSelectItem>

            <EuiFilterSelectItem
              checked={healthFilter === `maintenance` ? `on` : undefined}
              onClick={() => this.setHealthFilter(`maintenance`)}
            >
              {this.renderHealthLabel(`maintenance`)}
            </EuiFilterSelectItem>
          </div>
        </EuiPopover>

        <EuiPopover
          id={makeId()}
          ownFocus={true}
          button={
            <EuiFilterButton
              iconType='arrowDown'
              isSelected={isRolesPopoverOpen}
              onClick={this.toggleRolesPopover}
              hasActiveFilters={!isEmpty(roleFilters)}
              numFilters={size(roleFilters) || undefined}
            >
              <FormattedMessage
                id='platform-visualization.runner-roles-label'
                defaultMessage='Roles'
              />
            </EuiFilterButton>
          }
          isOpen={isRolesPopoverOpen}
          closePopover={this.closeRolesPopover}
          panelPaddingSize='none'
        >
          <div className='euiFilterSelect__items' style={{ width: 200 }}>
            <EuiFilterSelectItem
              checked={roleFilterExact && !isEmpty(roleFilters) ? `on` : undefined}
            >
              <EuiCheckbox
                id='host-roles-exact-match-checkbox'
                disabled={isEmpty(roleFilters)}
                label={
                  <FormattedMessage
                    id='platform-visualization.runner-roles-exact-match'
                    defaultMessage='Exact match'
                  />
                }
                checked={roleFilterExact}
                onChange={this.toggleRoleFilterExact}
              />
            </EuiFilterSelectItem>

            {roles.map((role) => {
              const color = roleColors.named[role]
              const namedRole = getNamedRole(role)

              const roleText =
                role === 'runner' ? (
                  <FormattedMessage id='platform-visualization.role-label-' defaultMessage='Host' />
                ) : (
                  role
                )

              return (
                <EuiFilterSelectItem
                  key={role}
                  checked={roleFilters.includes(role) ? `on` : undefined}
                  onClick={() => this.toggleRoleFilter(role)}
                >
                  <div>
                    <EuiBadge color={color}>{namedRole ? namedRole.label : roleText}</EuiBadge>
                  </div>
                </EuiFilterSelectItem>
              )
            })}
          </div>
        </EuiPopover>
      </EuiFilterGroup>
    )
  }

  renderHealthLabel(health: HealthFilter) {
    if (health === `maintenance`) {
      return (
        <FormattedMessage
          id='platform-visualization.maintenance-runners'
          defaultMessage='Maintenance'
        />
      )
    }

    if (health === `unhealthy`) {
      return (
        <FormattedMessage
          id='platform-visualization.unhealthy-runners'
          defaultMessage='Unhealthy'
        />
      )
    }

    if (health === `healthy`) {
      return (
        <FormattedMessage id='platform-visualization.healthy-runners' defaultMessage='Healthy' />
      )
    }

    return <FormattedMessage id='platform-visualization.all-runners' defaultMessage='All' />
  }

  applyFiltersToZone() {
    const { healthFilter, roleFilters, roleFilterExact } = this.state
    const { zone } = this.props

    const healthConditions = {
      healthy: (runner: CombinedPlatformData) => runner.healthy && !runner.isInMaintenanceMode,
      unhealthy: (runner: CombinedPlatformData) => !runner.healthy,
      maintenance: (runner: CombinedPlatformData) => runner.isInMaintenanceMode,
    }

    const filteredByHealth =
      healthFilter === null ? zone : zone.filter(healthConditions[healthFilter])

    const filteredByRole = isEmpty(roleFilters)
      ? filteredByHealth
      : filteredByHealth.filter(matchesRoleFilters)

    return filteredByRole

    function matchesRoleFilters(runner: CombinedPlatformData) {
      const roles = getRunnerRoles(runner)

      const matchesFilters = roleFilters.every((role) => roles.includes(role))
      const match = roleFilterExact
        ? matchesFilters && isEmpty(difference(roles, roleFilters))
        : matchesFilters

      return match
    }
  }

  setHealthFilter(healthFilter: HealthFilter) {
    this.setState({ healthFilter })
    this.closeHealthPopover()
  }

  toggleHealthPopover = () => {
    const { isHealthPopoverOpen } = this.state

    this.setState({ isHealthPopoverOpen: !isHealthPopoverOpen })
  }

  closeHealthPopover = () => {
    this.setState({ isHealthPopoverOpen: false })
  }

  toggleRoleFilter(role: NodeType) {
    const { roleFilters } = this.state

    const nextRoleFilters = roleFilters.includes(role)
      ? difference(roleFilters, [role])
      : union(roleFilters, [role])

    this.setState({ roleFilters: nextRoleFilters })
  }

  toggleRoleFilterExact = () => {
    const { roleFilterExact } = this.state

    this.setState({ roleFilterExact: !roleFilterExact })
  }

  toggleRolesPopover = () => {
    const { isRolesPopoverOpen } = this.state

    this.setState({ isRolesPopoverOpen: !isRolesPopoverOpen })
  }

  closeRolesPopover = () => {
    this.setState({ isRolesPopoverOpen: false })
  }
}

export default PlatformVisualizationZone
