/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  useEuiBackgroundColor,
} from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import type { FunctionComponent } from 'react'
import type { AllProps as Props } from './types'

const OktaGroups: FunctionComponent<Props> = ({
  fetchUserOktaGroups,
  postUserOktaGroups,
  postUserOktaGroupsRequest,
  fetchUserOktaGroupsRequest,
  userId,
  groups,
}) => {
  useEffect(() => {
    if (!groups) {
      fetchUserOktaGroups()
    }
  }, [userId])

  const groupNames = groups?.map(({ name }) => name).sort()
  const supportIndex = groupNames?.findIndex((g) => g === 'Support')
  const primaryColor = useEuiBackgroundColor('primary')
  const subduedColor = useEuiBackgroundColor('subdued')

  const renderError = (request: AsyncRequestState) => (
    <EuiFlexItem style={{ flexBasis: '100%' }} grow={true}>
      <EuiSpacer size='m' />
      <CuiAlert size='s' type='error'>
        {request.error}
      </CuiAlert>
    </EuiFlexItem>
  )

  const renderGroupBadge = (name: string, index: number) => (
    <EuiFlexItem key={index} grow={false}>
      <EuiBadge color={index === supportIndex ? primaryColor : subduedColor}>{name}</EuiBadge>
    </EuiFlexItem>
  )

  return (
    <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false} wrap={true}>
      {groupNames?.map((group, index) => renderGroupBadge(group, index))}
      {supportIndex === -1 ? (
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty size='xs' onClick={postUserOktaGroups}>
            <FormattedMessage id='user-okta-form.change' defaultMessage='Add to Support group' />
          </EuiButtonEmpty>
        </EuiFlexItem>
      ) : null}

      {fetchUserOktaGroupsRequest.error && renderError(fetchUserOktaGroupsRequest)}
      {postUserOktaGroupsRequest.error && renderError(postUserOktaGroupsRequest)}
    </EuiFlexGroup>
  )
}

export default OktaGroups
