/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep } from 'lodash'

import type { ClusterInstanceDiskInfo } from '@modules/cloud-api/v1/types'
import type { StackDeployment } from '@modules/ui-types'

import { getResourceByRefId } from '../../lib/stackDeployments/selectors'

import type { SetInstanceDiskQuotaAction } from '@/actions/stackDeployments/types'

export default function setStackDeploymentDiskQuota(
  deployment: StackDeployment,
  action: SetInstanceDiskQuotaAction,
) {
  const nextDeployment = cloneDeep(deployment)
  const { resourceKind, refId, instanceIds, previousDiskQuota, defaultDiskQuota } = action.meta
  const currentDiskQuota = action.payload.storage_multiplier

  const resource = getResourceByRefId({
    deployment: nextDeployment,
    resourceType: resourceKind,
    refId,
  })
  const instances = resource?.info.topology?.instances

  // We expect to find the instance by its ID
  const resourceInstances = instanceIds.map((id) => {
    const foundInstance = instances?.find((each) => each.instance_name === id)

    if (foundInstance == null) {
      throw new Error(`Failed to find instance by id [${id}]`)
    }

    return foundInstance
  })

  resourceInstances.forEach((instance) => {
    const previousCapacity = instance?.disk?.disk_space_available ?? 0
    const storagePerUnit = previousCapacity / previousDiskQuota

    const quota = currentDiskQuota === null ? defaultDiskQuota : currentDiskQuota

    if (!instance.disk) {
      instance.disk = {} as ClusterInstanceDiskInfo
    }

    instance.disk.disk_space_available = quota * storagePerUnit
  })

  return nextDeployment
}
