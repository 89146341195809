/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormLabel, EuiFormHelpText, EuiProgress } from '@elastic/eui'

import type { ContainerSetDetails } from '@modules/cloud-api/v1/types'

import type { FunctionComponent } from 'react'

type Props = {
  containerSet: ContainerSetDetails
}

const ContainerSetCounts: FunctionComponent<Props> = ({
  containerSet: {
    containers_started_count = 0, // sanity
    containers_created_count = 0, // sanity
    container_instances_running_count = 0, // sanity
  },
}) => (
  <Fragment>
    <EuiFormLabel>
      {containers_started_count === containers_created_count ? (
        <FormattedMessage
          id='container-set-counts.started-all-images'
          defaultMessage='{startedCount} started {startedCount, plural, one {image} other {images}}'
          values={{
            startedCount: containers_started_count,
          }}
        />
      ) : (
        <FormattedMessage
          id='container-set-counts.started-some-images'
          defaultMessage='{startedCount} started {startedCount, plural, one {image} other {images}}, {stoppedCount} stopped'
          values={{
            startedCount: containers_started_count,
            stoppedCount: containers_created_count - containers_started_count,
          }}
        />
      )}
    </EuiFormLabel>

    <EuiProgress value={containers_started_count} max={containers_created_count} />

    <EuiFormHelpText>
      <FormattedMessage
        id='container-set-counts.runnning-container-count'
        defaultMessage='Running {runningCount} {runningCount, plural, one {container} other {containers}}'
        values={{
          runningCount: container_instances_running_count,
        }}
      />
    </EuiFormHelpText>
  </Fragment>
)

export default ContainerSetCounts
