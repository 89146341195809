/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { ItemsCosts } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, TimePeriod } from '@modules/ui-types'

import { fetchAccountItemsCostsRequest, getDeploymentItemsCosts } from '../../../../reducers'
import {
  fetchAccountItemsCosts,
  resetFetchAccountItemsCostsRequest,
} from '../../../../actions/billing'

import CostsGrid from './CostsGrid'

import type { ThunkDispatch } from '@/types/redux'

interface StateProps {
  deploymentItemsCosts?: ItemsCosts
  fetchAccountItemsCostsRequest: AsyncRequestState
}

interface DispatchProps {
  fetchAccountItemsCosts: ({
    organizationId,
    timePeriod,
  }: {
    organizationId: string
    timePeriod: TimePeriod
  }) => Promise<any>
  resetFetchAccountItemsCostsRequest: () => void
}

interface ConsumerProps {}

const mapStateToProps = (state, ownProps): StateProps => {
  const {
    profile: { organization_id },
  } = ownProps

  return {
    deploymentItemsCosts: getDeploymentItemsCosts(state, organization_id),
    fetchAccountItemsCostsRequest: fetchAccountItemsCostsRequest(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchAccountItemsCosts: ({ organizationId, timePeriod }) =>
    dispatch(
      fetchAccountItemsCosts({
        organizationId,
        timePeriod,
      }),
    ),
  resetFetchAccountItemsCostsRequest: () => dispatch(resetFetchAccountItemsCostsRequest()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CostsGrid)
