/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import type { EuiComboBoxOptionOption } from '@elastic/eui'
import { EuiComboBox, EuiFormRow } from '@elastic/eui'

import { isHeroku } from '../../../../../../../lib/heroku'
import { getKeys, getRenderOptions } from '../helpers'
import {
  getSliderNodeTypeForTopologyElement,
  isDedicatedML,
} from '../../../../../../../lib/stackDeployments/selectors'

import OptionText from './OptionText'

import type { ReactElement } from 'react'
import type { WrappedComponentProps } from 'react-intl'
import type { Props } from './types'

type State = {
  isCpuPopoverOpen: boolean
}

class SizePicker extends Component<Props & WrappedComponentProps, State> {
  render = () => {
    const { size, disabled = false, options, helpText } = this.props

    const selectedOptions = options.filter((x) => x.value === size)

    return (
      <EuiFormRow helpText={helpText} data-test-subj='size-form-row' fullWidth={true}>
        <EuiComboBox
          fullWidth={true}
          isDisabled={disabled || !this.props.onChangeSize || isHeroku()}
          isClearable={false}
          singleSelection={{ asPlainText: true }}
          renderOption={this.renderOption}
          options={options}
          selectedOptions={selectedOptions}
          onChange={([selectedOption]) => this.props.onChangeSize?.(selectedOption.value || 0)}
          data-test-id={this.props[`data-test-id`] || `size-combo-box`}
          className='fs-unmask'
        />
      </EuiFormRow>
    )
  }

  renderOption = ({ value }: EuiComboBoxOptionOption<number>): ReactElement | null => {
    if (value === undefined) {
      return null // sanity
    }

    const {
      sliderInstanceType,
      topologyElement,
      resource: instanceResource,
      storageMultiplier,
      isBlobStorage,
      cpuMultiplier,
    } = this.props

    const sliderNodeType = getSliderNodeTypeForTopologyElement({ topologyElement })

    const { primaryKey, secondaryKey } = getKeys({
      sliderInstanceType,
      instanceResource,
      storageMultiplier,
      sliderNodeType,
    })

    const renderOptions = getRenderOptions({
      instanceResource,
      storageMultiplier,
      cpuMultiplier,
      value,
      primaryKey,
      secondaryKey,
      isBlobStorage,
      isMachineLearning: isDedicatedML({ topologyElement }),
    })

    return (
      <OptionText
        primaryOptionText={renderOptions.primary_text}
        secondaryOptionText={renderOptions.secondary_text}
        cpuText={renderOptions.cpu_text}
      />
    )
  }
}

export default injectIntl(SizePicker)
