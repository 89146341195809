/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { BillingSubscriptionLevel } from '@modules/ui-types'

import type { FunctionComponent } from 'react'

interface Props {
  isPrivate: boolean
  level: BillingSubscriptionLevel
}

const CustomerLevel: FunctionComponent<Props> = ({ isPrivate, level }) => {
  if (isPrivate) {
    return <FormattedMessage id='customer-level.private' defaultMessage='Private' />
  }

  if (level === `enterprise`) {
    return <FormattedMessage id='customer-level.enterprise' defaultMessage='Enterprise' />
  }

  if (level === `platinum`) {
    return <FormattedMessage id='customer-level.platinum' defaultMessage='Platinum' />
  }

  if (level === `gold`) {
    return <FormattedMessage id='customer-level.gold' defaultMessage='Gold' />
  }

  return <FormattedMessage id='customer-level.standard' defaultMessage='Standard' />
}

export default CustomerLevel
