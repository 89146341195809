/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { SearchRequest } from '@modules/cloud-api/v1/types'

import { searchDeployments } from '../../../../../../actions/stackDeployments'
import {
  getStackDeploymentsFromSearch,
  searchStackDeploymentsRequest,
} from '../../../../../../reducers'

import Monitoring from './Monitoring'

import type { StateProps, DispatchProps, OwnProps } from './Monitoring'

const queryId = `searchDeploymentsForEditMonitoring`

const mapStateToProps = (state): StateProps => ({
  searchResults: getStackDeploymentsFromSearch(state, queryId),
  searchResultsRequest: searchStackDeploymentsRequest(state, queryId),
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  searchDeployments: (query: SearchRequest) => dispatch(searchDeployments({ queryId, query })),
})

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Monitoring)
