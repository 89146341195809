/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { CuiBasicFilterContextProps } from '@modules/cui'

import { getSupportedSliderInstanceTypes, getSliderPrettyName } from '../../../lib/sliders'

import type { IntlShape } from 'react-intl'

const messages = defineMessages({
  healthLabel: {
    id: `allocator-instances-filter-context.health-label`,
    defaultMessage: `Health`,
  },
  healthyLabel: {
    id: `allocator-instances-filter-context.healthy-instance-label`,
    defaultMessage: `Healthy instance`,
  },
  unhealthyLabel: {
    id: `allocator-instances-filter-context.unhealthy-instance-label`,
    defaultMessage: `Unhealthy instance`,
  },
  healthyClusterLabel: {
    id: `allocator-instances-filter-context.healthy-cluster-label`,
    defaultMessage: `Healthy cluster`,
  },
  unhealthyClusterLabel: {
    id: `allocator-instances-filter-context.unhealthy-cluster-label`,
    defaultMessage: `Unhealthy cluster`,
  },
})

export function getFilters({ intl: { formatMessage } }: { intl: IntlShape }) {
  const filters: CuiBasicFilterContextProps['filters'] = [
    {
      name: formatMessage(messages.healthLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      multiSelect: false,
      autoClose: false,
      options: [
        {
          name: formatMessage(messages.healthyLabel),
          field: `healthy`,
          value: `y`,
        },
        {
          name: formatMessage(messages.healthyClusterLabel),
          field: `healthy_cluster`,
          value: `y`,
        },
        {
          name: formatMessage(messages.unhealthyLabel),
          field: `healthy`,
          value: `n`,
        },
        {
          name: formatMessage(messages.unhealthyClusterLabel),
          field: `healthy_cluster`,
          value: `n`,
        },
      ],
    },
    {
      type: `field_value_toggle_group`,
      field: `type`,
      items: getSupportedSliderInstanceTypes().map((sliderInstanceType) => ({
        name: formatMessage(getSliderPrettyName({ sliderInstanceType })),
        value: sliderInstanceType,
      })),
    },
  ]

  return filters
}
