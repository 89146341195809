/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AdminconsolesOverview } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { fetchAdminconsoles } from '../../actions/adminconsoles'
import { getAdminconsoles, fetchAdminconsolesRequest } from '../../reducers'

import Adminconsoles from './Adminconsoles'

import type { ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  regionId: string
  adminconsoles: AdminconsolesOverview | null
  fetchAdminconsolesRequest: AsyncRequestState
}

type DispatchProps = {
  fetchAdminconsoles: () => void
}

type QueryParams = {
  regionId: string
}

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  adminconsoles: getAdminconsoles(state, regionId),
  fetchAdminconsolesRequest: fetchAdminconsolesRequest(state, regionId),
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  fetchAdminconsoles: () => dispatch(fetchAdminconsoles(regionId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Adminconsoles)
