/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component } from 'react'

import { EuiButtonIcon } from '@elastic/eui'

import driftIcon from '@/files/driftchat-icon-blue.svg'

import { getPlaybookIdToOpen } from './playbookPredicates'

import type { Props } from './types'

import './driftChat.scss'

let chatWindow: Window | null = null

export function clearChatWindow() {
  chatWindow = null
}

export class DriftChatButton extends Component<Props> {
  driftButton: HTMLFormElement | null = null

  componentDidMount() {
    window.addEventListener('message', this.handleWindowMessage)
    const { fetchDriftJwt } = this.props
    fetchDriftJwt()
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleWindowMessage)
  }

  render() {
    const { fetchDriftJwtRequestState } = this.props

    if (fetchDriftJwtRequestState.inProgress || !this.checkIsChatAvailable()) {
      return null
    }

    return (
      <EuiButtonIcon
        aria-label='chat-button'
        display='empty'
        iconType={driftIcon}
        iconSize='original'
        onClick={this.handleButtonClick}
        buttonRef={(el) => {
          this.driftButton = el
        }}
        className='drift-button'
      />
    )
  }

  checkIsChatAvailable(): boolean {
    return Boolean(this.getPlaybookIdToOpen())
  }

  getPlaybookIdToOpen(): number | null {
    const { profile } = this.props

    return getPlaybookIdToOpen(profile, this.props)
  }

  handleButtonClick = (): void => {
    if (!chatWindow || chatWindow.closed) {
      const position = this.driftButton?.getBoundingClientRect()

      const left = position ? position.left - 130 : 0
      const top = position ? position.top - 500 : 0

      chatWindow = window.open(
        this.props.chatUrl,
        'chatWindow',
        `resizeable,width=380,height=530,left=${left},top=${top}`,
      )
    } else {
      chatWindow.focus()
    }
  }

  handleWindowMessage = (event: MessageEvent): void => {
    if (event.source !== chatWindow) {
      return
    }

    const message = event.data

    if (chatWindow && message.type === 'driftIframeReady') {
      const context = {
        window: {
          location: {
            hash: window.location.hash,
            host: window.location.host,
            hostname: window.location.hostname,
            href: window.location.href,
            origin: window.location.origin,
            pathname: window.location.pathname,
            port: window.location.port,
            protocol: window.location.protocol,
            search: window.location.search,
          },
          navigator: {
            language: window.navigator.language,
            userAgent: window.navigator.userAgent,
          },
          innerHeight: window.innerHeight,
          innerWidth: window.innerWidth,
        },
        document: {
          title: document.title,
          referrer: document.referrer,
        },
      }
      const userData = {
        id: this.props.profile.user_id,
        attributes: {
          email: this.props.profile.email,
        },
        jwt: this.props.profile.driftJwt,
      }
      chatWindow.postMessage(
        {
          type: 'driftSetContext',
          data: { context, user: userData, interactionId: this.getPlaybookIdToOpen() },
        },
        '*',
      )
    }
  }
}
