/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import type { InstanceConfiguration } from '@modules/cloud-api/v1/types'

import { CuiSliderLogo } from '@/components/SliderLogo'

import {
  getSliderPrettyName,
  getSliderTypesForInstanceConfiguration,
} from '../../../../../lib/sliders'

import type { FunctionComponent } from 'react'

type Props = {
  instanceConfiguration: InstanceConfiguration
}

const InstanceTypes: FunctionComponent<Props> = ({ instanceConfiguration }) => {
  const sliderTypes = getSliderTypesForInstanceConfiguration(instanceConfiguration)

  return (
    <Fragment>
      <EuiTitle size='m'>
        <h2>
          <FormattedMessage
            id='instance-configuration-instance-types'
            defaultMessage='Instance types'
          />
        </h2>
      </EuiTitle>

      <EuiSpacer size='s' />

      <EuiFlexGroup wrap={true} gutterSize='s'>
        {sliderTypes.map((sliderType, i) => (
          <EuiFlexItem key={i} grow={false}>
            <EuiPanel paddingSize='s'>
              <EuiFlexGroup alignItems='center' gutterSize='s' responsive={false}>
                <EuiFlexItem grow={false}>
                  <CuiSliderLogo {...sliderType} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <FormattedMessage {...getSliderPrettyName(sliderType)} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </Fragment>
  )
}

export default InstanceTypes
