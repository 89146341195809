/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { EuiCallOutProps } from '@elastic/eui'

import { getStoredProcedure } from '../../../reducers'

import StoredProcedureResult from './StoredProcedureResult'

import type { ProcedureState } from '@/types/redux'
import type { ReactNode } from 'react'

type StateProps = {
  procedure?: ProcedureState
}

interface DispatchProps {}

type ConsumerProps = {
  procedureId: string
  successMessage?: ReactNode
  successColor?: EuiCallOutProps['color']
}

const mapStateToProps = (state, { procedureId }: ConsumerProps): StateProps => ({
  procedure: getStoredProcedure(state, procedureId),
})

const mapDispatchToProps: DispatchProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(StoredProcedureResult)
