/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  getContainerSetsUrl,
  getContainerSetUrl,
  getContainerUrl,
} from '@modules/cloud-api/v1/urls'

import asyncRequest from '../asyncRequests'
import { FETCH_CONTAINER, FETCH_CONTAINER_SET, FETCH_CONTAINER_SETS } from '../../constants/actions'

export function fetchContainerSets({ regionId }: { regionId: string }) {
  return asyncRequest({
    type: FETCH_CONTAINER_SETS,
    method: `GET`,
    url: getContainerSetsUrl({ regionId }),
    crumbs: [regionId],
    meta: { regionId },
  })
}

export function fetchContainerSet({
  regionId,
  containerSetId,
}: {
  regionId: string
  containerSetId: string
}) {
  return asyncRequest({
    type: FETCH_CONTAINER_SET,
    method: `GET`,
    url: getContainerSetUrl({
      regionId,
      containersetId: containerSetId,
      include: `containers,events,inspect`,
    }),
    crumbs: [regionId, containerSetId],
    meta: { regionId, containerSetId },
  })
}

export function fetchContainer({
  regionId,
  containerSetId,
  containerId,
}: {
  regionId: string
  containerSetId: string
  containerId: string
}) {
  return asyncRequest({
    type: FETCH_CONTAINER,
    method: `GET`,
    url: getContainerUrl({
      regionId,
      containersetId: containerSetId,
      containerId,
      include: `inspect`,
    }),
    crumbs: [regionId, containerSetId, containerId],
    meta: { regionId, containerSetId, containerId },
  })
}
