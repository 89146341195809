/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiFormLabel,
  EuiFormRow,
  EuiSpacer,
  EuiTextColor,
  EuiTitle,
} from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, CuiButton, withErrorBoundary } from '@modules/cui'
import { isAjaxNotFound } from '@modules/utils/ajax'

import DocLink from '../../DocLink'

type Props = {
  apiBaseUrl: string | null
  fetchApiBaseUrl: () => void
  fetchApiBaseUrlRequest: AsyncRequestState
  updateApiBaseUrl: (payload: { apiBaseUrl: string }) => Promise<any>
  updateApiBaseUrlRequest: AsyncRequestState
}

type State = {
  editing: boolean
  loading: boolean
  apiBaseUrl: string
}

class EditApiBaseUrl extends Component<Props, State> {
  state: State = {
    editing: false,
    loading: true,
    apiBaseUrl: ``,
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    const { fetchApiBaseUrlRequest, apiBaseUrl } = nextProps

    if (!prevState.loading) {
      return null
    }

    if (fetchApiBaseUrlRequest.inProgress) {
      return null
    }

    const fetchWasEmpty = isAjaxNotFound(fetchApiBaseUrlRequest.error)
    const fetchError = fetchApiBaseUrlRequest.error && !fetchWasEmpty

    if (fetchError) {
      return null
    }

    if (fetchApiBaseUrlRequest.error && !fetchApiBaseUrlRequest.isDone) {
      return null
    }

    return { loading: false, apiBaseUrl: apiBaseUrl || window.location.origin }
  }

  componentDidMount() {
    const { fetchApiBaseUrl } = this.props
    fetchApiBaseUrl()
  }

  render() {
    const { updateApiBaseUrlRequest } = this.props
    const { editing, loading, apiBaseUrl } = this.state
    const validationError = this.getApiBaseUrlValidationError()

    return (
      <EuiDescribedFormGroup
        title={
          <EuiTitle size='xs'>
            <h3>
              <FormattedMessage id='edit-api-base-url.api-base-url' defaultMessage='API URL' />
            </h3>
          </EuiTitle>
        }
        description={
          <FormattedMessage
            id='edit-api-base-url.api-base-url-help-text'
            defaultMessage='Specify the HTTPS URL that points to the ECE API. Use a DNS hostname or an IP address. {learnMore}'
            values={{
              learnMore: (
                <DocLink link='changeEceApiUrl'>
                  <FormattedMessage
                    id='edit-api-base-url.change-api-base-url-learn-more'
                    defaultMessage='Learn more'
                  />
                </DocLink>
              ),
            }}
          />
        }
        fullWidth={true}
      >
        <EuiFormRow fullWidth={true}>
          <div>
            <EuiFormLabel htmlFor='apiBaseUrl'>
              <FormattedMessage id='edit-api-base-url.api-base-url' defaultMessage='API URL' />
            </EuiFormLabel>

            <EuiSpacer size='xs' />

            <EuiFlexGroup gutterSize='m' alignItems='flexStart'>
              <EuiFlexItem>
                <EuiFieldText
                  id='apiBaseUrl'
                  disabled={loading}
                  readOnly={!editing}
                  value={apiBaseUrl}
                  onChange={(e) => this.setState({ apiBaseUrl: e.target.value })}
                  fullWidth={true}
                />
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <div>{this.renderButtons()}</div>
              </EuiFlexItem>
            </EuiFlexGroup>

            {validationError && (
              <Fragment>
                <EuiSpacer size='xs' />

                <EuiFormHelpText>
                  <EuiTextColor color='danger'>{validationError}</EuiTextColor>
                </EuiFormHelpText>
              </Fragment>
            )}

            {updateApiBaseUrlRequest.error && (
              <Fragment>
                <EuiSpacer size='m' />

                <CuiAlert type='error'>{updateApiBaseUrlRequest.error}</CuiAlert>
              </Fragment>
            )}
          </div>
        </EuiFormRow>
      </EuiDescribedFormGroup>
    )
  }

  renderButtons() {
    const { apiBaseUrl } = this.props
    const { loading, editing } = this.state

    if (!editing) {
      return (
        <EuiFlexGroup gutterSize='s'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty disabled={loading} onClick={() => this.setState({ editing: true })}>
              <FormattedMessage id='edit-api-base-url.edit' defaultMessage='Edit' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    return (
      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem grow={false}>
          <div>{this.renderSaveButton()}</div>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            onClick={() =>
              this.setState({ editing: false, apiBaseUrl: apiBaseUrl || window.location.origin })
            }
          >
            <FormattedMessage id='edit-api-base-url.cancel' defaultMessage='Cancel' />
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderSaveButton() {
    const { apiBaseUrl: prevApiBaseUrl, updateApiBaseUrl, updateApiBaseUrlRequest } = this.props
    const { apiBaseUrl, loading } = this.state
    const validationError = this.getApiBaseUrlValidationError()

    return (
      <CuiButton
        color='primary'
        disabled={loading || Boolean(validationError)}
        spin={updateApiBaseUrlRequest.inProgress}
        onClick={() =>
          updateApiBaseUrl({ apiBaseUrl })
            .then(() => this.setState({ editing: false }))
            .catch(() =>
              this.setState({
                editing: false,
                apiBaseUrl: prevApiBaseUrl || window.location.origin,
              }),
            )
        }
        confirm={true}
        confirmModalProps={{
          title: (
            <FormattedMessage
              id='edit-api-base-url.confirm-title'
              defaultMessage='Change API URL?'
            />
          ),
          body: (
            <FormattedMessage
              id='edit-api-base-url.existing-deployments-warning'
              defaultMessage='For existing deployments, you must make a configuration change to use the new API URL.'
            />
          ),
          confirm: <FormattedMessage id='edit-api-base-url.confirm-button' defaultMessage='Save' />,
        }}
      >
        <FormattedMessage id='edit-api-base-url.change-api-url' defaultMessage='Update' />
      </CuiButton>
    )
  }

  getApiBaseUrlValidationError = () => {
    const { editing, apiBaseUrl } = this.state

    if (!editing) {
      return null
    }

    if (isEmpty(apiBaseUrl)) {
      return (
        <FormattedMessage
          id='edit-api-base-url.invalid-empty'
          defaultMessage='The ECE API URL cannot be empty.'
        />
      )
    }

    return null
  }
}

export default withErrorBoundary(EditApiBaseUrl)
