/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ClusterSnapshot, StackDeployment } from '@modules/ui-types'

import { FETCH_SNAPSHOTS, RESTORE_SNAPSHOT } from '../constants/actions'
import { replaceIn } from '../lib/immutability-helpers'

type FetchSnapshotsAction = {
  type: typeof FETCH_SNAPSHOTS
  meta: { deployment: StackDeployment }
  error?: any
  payload?: {
    snapshots: ClusterSnapshot[]
  }
}

type RestoreSnapshotAction = {
  type: typeof RESTORE_SNAPSHOT
  meta: { deployment: StackDeployment }
  error?: any
  payload?: {
    accepted?: true
    snapshot?: {
      snapshot: string
      indices: string[]
      shards: {
        total: number
        failed: number
        successful: number
      }
    }
  }
}

export type ClusterSnapshotAction = FetchSnapshotsAction | RestoreSnapshotAction

export interface State {
  snapshots: { [descriptor: string]: ClusterSnapshot[] }
  restores: { [descriptor: string]: { success: boolean; raw: any } }
}

const initialState: State = {
  snapshots: {},
  restores: {},
}

function createDescriptor(deployment: StackDeployment) {
  return deployment.id
}

export default function clusterSnapshotsReducer(
  state: State = initialState,
  action: ClusterSnapshotAction,
): State {
  if (action.type === FETCH_SNAPSHOTS) {
    if (!action.error && action.payload) {
      const { deployment } = action.meta
      const descriptor = createDescriptor(deployment)

      const snapshots = action.payload.snapshots.slice().reverse() // received order is oldest-first

      return replaceIn(state, [`snapshots`, descriptor], snapshots)
    }
  }

  if (action.type === RESTORE_SNAPSHOT) {
    if (!action.error && action.payload) {
      const { deployment } = action.meta
      const descriptor = createDescriptor(deployment)

      const success = action.payload.accepted || false

      return replaceIn(state, [`restores`, descriptor], {
        success,
        raw: action.payload,
      })
    }
  }

  return state
}

export function getClusterSnapshots(state: State, deploymentId: string): ClusterSnapshot[] | null {
  return state.snapshots[deploymentId]
}

export function getClusterSnapshotByName(
  state: State,
  deploymentId: string,
  name: string,
): ClusterSnapshot | undefined {
  const snapshots = getClusterSnapshots(state, deploymentId)
  return snapshots?.find(({ snapshot }) => snapshot === name)
}

export function getSnapshotRestore(state: State, deploymentId: string): any | null {
  return state.restores[deploymentId]
}
