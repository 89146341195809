/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { sortBy, unionBy } from 'lodash'

import type { MetadataItem } from '@modules/cloud-api/v1/types'
import { CuiExpansibleTagList } from '@modules/cui'

import AllocatorTag from '../Allocator/AllocatorTags/AllocatorTag'
import { prominentAllocatorTags } from '../../lib/allocator'

import type { FunctionComponent } from 'react'

type Props = {
  tags: MetadataItem[]
  active?: MetadataItem[]
  onTagClick?: (tag: MetadataItem) => void
}

type TagProps = {
  tag: MetadataItem
}

const maxInlineTags = 2

const AllocatorTags: FunctionComponent<Props> = ({ tags, active, onTagClick }) => {
  const sortedTags = sortBy(tags, `key`)
  const inlineTags = unionBy(sortedTags.filter(isProminent), sortedTags).slice(0, maxInlineTags)

  const Tag: FunctionComponent<TagProps> = ({ tag }) => (
    <AllocatorTag
      tag={tag}
      color={isActive(tag, active) ? `success` : `hollow`}
      onClick={onTagClick}
    />
  )

  return (
    <CuiExpansibleTagList
      title={<FormattedMessage id='allocator-tags.title' defaultMessage='Tags' />}
      tags={tags}
      inlineTags={inlineTags}
      getTagKey={(tag: MetadataItem) => `${tag.key}:${tag.value}`}
      renderTag={Tag}
    />
  )
}

function isActive(tag, active) {
  if (!Array.isArray(active)) {
    return false
  }

  return active.some((activeTag) => activeTag.key === tag.key && activeTag.value === tag.value)
}

function isProminent(tag) {
  return prominentAllocatorTags.includes(tag.key)
}

export default AllocatorTags
