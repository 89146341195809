/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { QueryContainer, SearchRequest } from '@modules/cloud-api/v1/types'
import type { SliderInstanceType } from '@modules/ui-types'

import { major } from '../../lib/semver'

const haveAtLeastOneEsResource = {
  nested: {
    path: `resources.elasticsearch`,
    query: {
      exists: {
        field: `resources.elasticsearch.id`,
      },
    },
  },
}

export function getDeploymentByIdQuery({ deploymentId }: { deploymentId: string }): SearchRequest {
  const getDeploymentById = {
    bool: {
      should: [
        {
          prefix: {
            id: {
              value: deploymentId,
            },
          },
        },
      ],
    },
  }

  return {
    query: {
      bool: {
        must: [haveAtLeastOneEsResource],
        filter: [getDeploymentById],
      },
    },
  }
}

export function getDeploymentsByIdQuery({
  deploymentIds,
}: {
  deploymentIds: string[]
}): SearchRequest {
  const getDeploymentsById = {
    bool: {
      should: deploymentIds.map((deploymentId) => ({
        prefix: {
          id: {
            value: deploymentId,
          },
        },
      })),
    },
  }

  return {
    query: {
      bool: {
        must: [haveAtLeastOneEsResource],
        filter: [getDeploymentsById],
      },
    },
    size: Math.min(deploymentIds.length, 100),
  }
}

export function getDeploymentsByClusterIdsQuery({
  clusterIds,
  regionId,
  sliderInstanceType = `elasticsearch`,
}: {
  clusterIds: string[]
  regionId?: string
  sliderInstanceType?: SliderInstanceType
}): SearchRequest {
  const resourceFilter: QueryContainer[] = clusterIds.map((clusterId) => ({
    prefix: {
      [`resources.${sliderInstanceType}.id`]: {
        value: clusterId,
      },
    },
  }))

  const resourceMust: QueryContainer[] | undefined = regionId
    ? [
        {
          term: {
            [`resources.${sliderInstanceType}.region`]: {
              value: regionId,
            },
          },
        },
      ]
    : undefined

  const getDeploymentsByClusterIds = {
    nested: {
      path: `resources.${sliderInstanceType}`,
      query: {
        bool: {
          must: resourceMust,
          should: resourceFilter,
          minimum_should_match: 1,
        },
      },
    },
  }

  return {
    query: {
      bool: {
        must: [haveAtLeastOneEsResource],
        filter: [getDeploymentsByClusterIds],
      },
    },
  }
}

export function searchDeploymentsQuery({
  regionId,
  version,
  searchValue,
  matchOrganizationId,
  includeTerminated,
  targetMajorVersions,
}: {
  regionId?: string
  version?: string
  searchValue?: string | null
  matchOrganizationId?: string | null
  includeTerminated?: boolean
  targetMajorVersions?: (number) => number[]
}): SearchRequest {
  const elasticsearchFilters: QueryContainer[] = []

  if (regionId) {
    const prefixRegion = {
      term: {
        ['resources.elasticsearch.region']: {
          value: regionId,
        },
      },
    }

    elasticsearchFilters.push(prefixRegion)
  }

  if (version) {
    const majorVersion = major(version)
    const majorVersionTargets = targetMajorVersions
      ? targetMajorVersions(majorVersion)
      : [majorVersion]

    const versionFilters = majorVersionTargets.map((targetVersion) => ({
      prefix: {
        ['resources.elasticsearch.info.plan_info.current.plan.elasticsearch.version']: {
          value: targetVersion.toString(),
        },
      },
    }))

    const versionFilter =
      versionFilters.length === 1
        ? versionFilters[0]
        : {
            bool: {
              should: versionFilters,
            },
          }

    elasticsearchFilters.push(versionFilter)
  }

  const elasticsearchResourceQuery = {
    nested: {
      path: `resources.elasticsearch`,
      query: {
        bool: {
          must: elasticsearchFilters,
        },
      },
    },
  }

  const checkNotTerminated = {
    nested: {
      path: `resources.elasticsearch`,
      query: {
        exists: {
          field: 'resources.elasticsearch.info.topology.instances',
        },
      },
    },
  }

  const notSystemOwned = {
    term: {
      'metadata.system_owned': {
        value: 'false',
      },
    },
  }

  const filterOptions: QueryContainer[] = [notSystemOwned]

  if (matchOrganizationId) {
    const prefixAccount: QueryContainer = {
      term: {
        'metadata.organization_id': {
          value: matchOrganizationId,
        },
      },
    }

    filterOptions.push(prefixAccount)
  }

  if (searchValue) {
    const queryText: QueryContainer = {
      bool: {
        must: [
          {
            bool: {
              should: [
                { prefix: { id: { value: searchValue } } },
                { match: { name: { query: searchValue } } },
                { prefix: { name: { value: searchValue } } },
              ],
            },
          },
        ],
      },
    }

    filterOptions.push(queryText)
  }

  const booleanMust: any[] = [elasticsearchResourceQuery]

  if (!includeTerminated) {
    booleanMust.push(checkNotTerminated)
  }

  return {
    query: {
      bool: {
        filter: [
          ...filterOptions,
          {
            bool: {
              must: booleanMust,
            },
          },
        ],
      },
    },
    size: 150,
  }
}
