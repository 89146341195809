/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import sluggish from 'sluggish'

import {
  EuiFormLabel,
  EuiSpacer,
  EuiText,
  EuiFormControlLayout,
  EuiPopover,
  EuiButtonIcon,
  EuiSuperSelect,
} from '@elastic/eui'

import type {
  GlobalDeploymentTemplateInfo,
  DeploymentTemplateInfoV2,
} from '@modules/cloud-api/v1/types'
import type { VersionNumber } from '@modules/ui-types'

import { getCorrectTemplateForGlobalTemplate } from '@/lib/stackDeployments'

import DocLink from '../../../../../components/DocLink'
import { isIncompatibleVersionForGlobalTemplate } from '../../../../../lib/globalDeploymentTemplates'
import { isTrialEligibleTemplate } from '../../../../../lib/deploymentTemplates/metadata'

import type { WrappedComponentProps } from 'react-intl'

import './selectHardwareProfile.scss'

export interface Props extends WrappedComponentProps {
  currentTemplate?: DeploymentTemplateInfoV2
  stackTemplates?: GlobalDeploymentTemplateInfo[]
  onChange: (template) => void
  version: VersionNumber
  inTrial: boolean
  isUserconsole: boolean
  disabled?: boolean
  deploymentTemplates?: DeploymentTemplateInfoV2[] | null
}

type State = {
  isPopoverOpen: boolean
}

class SelectHardwareProfile extends Component<Props, State> {
  state = {
    isPopoverOpen: false,
  }

  render() {
    const {
      currentTemplate,
      stackTemplates,
      inTrial,
      version,
      isUserconsole,
      disabled,
      intl: { formatMessage },
    } = this.props

    if (!currentTemplate || !stackTemplates) {
      return null
    }

    const stackTemplateOptions =
      stackTemplates &&
      stackTemplates.map((template) => {
        const templateMissingSelectedVersion = isIncompatibleVersionForGlobalTemplate(
          template,
          version,
        )
        const trialTemplateUnavailable = inTrial && !isTrialEligibleTemplate(template)
        const disabled = templateMissingSelectedVersion || trialTemplateUnavailable
        const templateInfo = this.getTemplateInfo(template)

        return {
          ...template,
          name: templateInfo.name,
          value: template.template_category_id || '',
          disabled: Boolean(disabled),
          'data-test-id': `hardwareProfile-${sluggish(template.template_category_id)}`,
          dropdownDisplay: this.renderHardwareProfile({ ...template, ...templateInfo }),
          inputDisplay: templateInfo.name,
        }
      })

    const messages = defineMessages({
      ess: {
        id: `select-hardware-profile-label`,
        defaultMessage: `Hardware profile`,
      },
      ece: {
        id: `select-template-label`,
        defaultMessage: `Template`,
      },
      popoverText: {
        id: `hardware-profile-template`,
        defaultMessage: `Learn more about hardware profiles.`,
      },
    })
    const label = isUserconsole ? messages.ess : messages.ece
    const fieldName = 'hardware-profile-select-box'

    return (
      <EuiFormControlLayout
        className={fieldName}
        fullWidth={true}
        prepend={
          <EuiFormLabel style={{ width: `180px` }}>
            <FormattedMessage {...label} />
            <EuiPopover
              isOpen={this.state.isPopoverOpen}
              closePopover={() => this.setState({ isPopoverOpen: false })}
              anchorPosition='upCenter'
              button={
                <EuiButtonIcon
                  aria-label={formatMessage(messages.popoverText)}
                  className='hardwareProfileIcon'
                  onClick={this.onClickProfileInfoIcon}
                  iconType='iInCircle'
                  color='text'
                />
              }
            >
              <EuiText size='s' style={{ width: '300px' }}>
                {isUserconsole ? (
                  <FormattedMessage
                    data-test-id='ess-hardware-profile-tooltip'
                    id='select-hardware-profile.tooltip'
                    defaultMessage='A hardware profile deploys the Elastic Stack on virtual hardware. Each profile has a different blend of storage, RAM, and vCPU. {learnMore}'
                    values={{
                      learnMore: (
                        <DocLink link='templatesDocLink'>
                          <FormattedMessage
                            id='select-hardware-profile.learn-more'
                            defaultMessage='Learn more'
                          />
                        </DocLink>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    data-test-id='ece-template-tooltip'
                    id='select-template.tooltip'
                    defaultMessage='Hardware templates deploy the Elastic Stack on virtual hardware. Each template has a different blend of RAM, storage, and vCPU. You can also customize them to suit your needs. {learnMore}'
                    values={{
                      learnMore: (
                        <DocLink link='templatesDocLink'>
                          <FormattedMessage
                            id='select-template-profile.learn-more'
                            defaultMessage='Learn more'
                          />
                        </DocLink>
                      ),
                    }}
                  />
                )}
              </EuiText>
            </EuiPopover>
          </EuiFormLabel>
        }
      >
        <EuiSuperSelect
          name={fieldName}
          disabled={disabled}
          fullWidth={true}
          hasDividers={true}
          options={stackTemplateOptions!}
          valueOfSelected={currentTemplate.template_category_id || ''}
          onChange={(selected) => this.onSelectHardwareProfile(selected, stackTemplateOptions)}
          data-test-id={fieldName}
          itemClassName='hardware-profile-select-item'
        />
      </EuiFormControlLayout>
    )
  }

  renderHardwareProfile(template) {
    const { currentTemplate, inTrial } = this.props
    const trialTemplateUnavailable = inTrial && template.disabled
    const trialDescription = (
      <FormattedMessage
        id='create-deployment-from-template.hardware-profile.trial'
        defaultMessage='Not available in trial'
      />
    )
    const isSelectedTemplate =
      template.template_category_id === currentTemplate?.template_category_id

    return (
      <Fragment>
        <EuiText
          data-test-id='hardware-profile-label'
          size='s'
          className={
            isSelectedTemplate ? 'hardware-profile-label-selected' : 'hardware-profile-label'
          }
        >
          <strong>{template.name}</strong>
        </EuiText>

        <EuiSpacer size='xs' />

        <EuiText size='s' color='subdued'>
          {trialTemplateUnavailable ? trialDescription : template.description}
        </EuiText>
      </Fragment>
    )
  }

  onSelectHardwareProfile = (selected, options) => {
    const { onChange } = this.props
    const selectedOption = options.find((option) => option.template_category_id === selected)
    onChange(selectedOption)
  }

  onClickProfileInfoIcon = () => {
    this.setState((prevState: State) => ({ isPopoverOpen: !prevState.isPopoverOpen }))
  }

  getTemplateInfo = (
    globalTemplate: GlobalDeploymentTemplateInfo,
  ): { name: string; description: string | undefined } => {
    const { deploymentTemplates } = this.props

    if (!deploymentTemplates) {
      return {
        name: globalTemplate.name,
        description: globalTemplate.description,
      }
    }

    const matchingTemplate = getCorrectTemplateForGlobalTemplate({
      deploymentTemplates,
      globalTemplate,
    })

    if (!matchingTemplate) {
      return {
        name: globalTemplate.name,
        description: globalTemplate.description,
      }
    }

    return {
      name: matchingTemplate.name,
      description: matchingTemplate.description,
    }
  }
}

export default injectIntl(SelectHardwareProfile)
