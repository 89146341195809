/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, isEmpty } from 'lodash'

import type { SamlSettings } from '@modules/cloud-api/v1/types'

import { undefineEmptyStrings } from '../undefineEmptyStrings'

import type { SamlProviderFormShape } from './SamlProviderForm'

export function getInitialFormValues(): SamlProviderFormShape {
  return {
    form_mode: 'create',
    name: '',
    id: '',
    enabled: true,
    assertion_consumer_service_url: '',
    logout_url: '',
    identity_provider_entity_id: '',
    service_provider_entity_id: '',
    metadata_url: '',
    hide_optional_attributes: true,
    attribute_mappings: {
      principal: '',
      groups: '',
      name: '',
      mail: '',
      dn: '',
    },
    role_mappings: {
      default_roles: [],
      rules: [],
    },
    advanced_settings_yaml: '',
    signing_saml_messages: [],
    signing_certificate_url: '',
    signing_certificate_url_password: '',
    encryption_certificate_url: '',
    encryption_certificate_url_password: '',
    use_single_logout: true,
    force_authn: false,
    ssl_certificate_url: '',
    ssl_certificate_url_truststore_type: 'jks',
    ssl_certificate_url_truststore_password: '',
  }
}

export function formToSettings(form: SamlProviderFormShape): SamlSettings {
  const request: SamlSettings = {
    id: form.id,
    name: form.name,
    order: form.order,
    enabled: form.enabled,
    attributes: { ...form.attribute_mappings },
    idp: {
      entity_id: form.identity_provider_entity_id,
      metadata_path: form.metadata_url,
      use_single_logout: form.use_single_logout,
    },
    sp: {
      acs: form.assertion_consumer_service_url,
      entity_id: form.service_provider_entity_id,
      logout: form.logout_url,
    },
    force_authn: form.force_authn,
    override_yaml: form.advanced_settings_yaml,
    role_mappings: {
      default_roles: form.role_mappings.default_roles,
      rules: form.role_mappings.rules.map((rule) => ({
        type: rule.type,
        value: rule.value,
        roles: rule.roles,
      })),
    },
    signing_certificate_url: form.signing_certificate_url,
    signing_certificate_url_password: form.signing_certificate_url_password,
    signing_saml_messages: form.signing_saml_messages,
    encryption_certificate_url: form.encryption_certificate_url,
    encryption_certificate_url_password: form.encryption_certificate_url_password,
  }

  if (!isEmpty(form.ssl_certificate_url)) {
    request.ssl_certificate_url = form.ssl_certificate_url
    request.ssl_certificate_url_truststore_type = form.ssl_certificate_url_truststore_type
    request.ssl_certificate_url_truststore_password = form.ssl_certificate_url_truststore_password
  }

  undefineEmptyStrings(request)

  if (isEmpty(request.signing_saml_messages)) {
    request.signing_saml_messages = undefined
  }

  return request
}

export function settingsToForm(samlSettings: SamlSettings): SamlProviderFormShape {
  const {
    id,
    name,
    order,
    enabled,
    idp,
    sp,
    attributes,
    override_yaml,
    role_mappings = { default_roles: [], rules: [] },
    signing_certificate_url = '',
    signing_certificate_url_password = '',
    signing_saml_messages = [],
    encryption_certificate_url = '',
    encryption_certificate_url_password = '',
    force_authn = false,
    ssl_certificate_url = '',
    ssl_certificate_url_truststore_type = 'jks',
    ssl_certificate_url_truststore_password = '',
  } = cloneDeep(samlSettings)

  // attributes.principal and attributes.groups are mandatory. If all
  // other fields are empty, we should default to hiding them.
  const hideOptionalAttributes =
    isEmpty(attributes.dn) && isEmpty(attributes.mail) && isEmpty(attributes.name)

  const form: SamlProviderFormShape = {
    form_mode: 'edit',
    id,
    name,
    order,
    enabled,
    assertion_consumer_service_url: sp.acs,
    logout_url: sp.logout,
    identity_provider_entity_id: idp.entity_id,
    service_provider_entity_id: sp.entity_id,
    metadata_url: idp.metadata_path,
    hide_optional_attributes: hideOptionalAttributes,
    attribute_mappings: {
      principal: attributes.principal || '',
      groups: attributes.groups || '',
      name: attributes.name || '',
      mail: attributes.mail || '',
      dn: attributes.dn || '',
    },
    role_mappings: {
      default_roles: role_mappings.default_roles,
      rules: role_mappings.rules.map((rule, index) => ({ ...rule, index })),
    },
    advanced_settings_yaml: override_yaml || '',
    signing_certificate_url,
    signing_certificate_url_password,
    signing_saml_messages,
    encryption_certificate_url,
    encryption_certificate_url_password,
    use_single_logout: typeof idp.use_single_logout === 'boolean' ? idp.use_single_logout : true,
    force_authn,
    ssl_certificate_url,
    ssl_certificate_url_truststore_type,
    ssl_certificate_url_truststore_password,
  }

  return form
}
