/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { range } from 'lodash'
import { FormattedMessage } from 'react-intl'

import type { EuiComboBoxOptionOption } from '@elastic/eui'

import type {
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  DiscreteSizes,
} from '@modules/cloud-api/v1/types'
import type { AnyTopologyElement, SliderInstanceType, SliderNodeType } from '@modules/ui-types'

import { getInTrial } from '@/lib/reduxShortcuts/trials'
import { getSliderTrialLimit } from '@/lib/sliders'
import {
  getDedicatedMasterThresholdFromTemplate,
  getSliderNodeTypeForTopologyElement,
  isDedicatedML,
} from '@/lib/stackDeployments/selectors'
import { canDisableConfiguration } from '@/lib/deployments/architecture'
import prettySize from '@/lib/prettySize'

import { getKeys, getNumber, getSizeOptionText } from '../helpers'

import type { ReactElement } from 'react'
import type { NormalizeSizingProps } from '../NormalizeSizing'

type MakeSizePickerOptionsProps = NormalizeSizingProps & {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  topologyElement: AnyTopologyElement
  sliderInstanceType: SliderInstanceType
  sliderNodeTypes?: SliderNodeType[]
  isBlobStorage: boolean
  canShowZero: boolean
  isAutoscalingEnabled?: boolean
}

/**
 * Common base for all size picker options -- creates an option for each
 * possible size, given by the supplied discrete sizes and the node count max.
 * If in the context of a trial account, disables values outside the trial
 * limits.
 */
const makeAllSizePickerOptions = ({
  deployment,
  topologyElement,
  sliderInstanceType,
  sliderNodeTypes,
  size,
  sizes,
  resource,
  maxNodeCount,
  isBlobStorage,
  storageMultiplier,
  cpuMultiplier,
  canShowZero,
}: MakeSizePickerOptionsProps): Array<EuiComboBoxOptionOption<number>> => {
  const sliderNodeType = getSliderNodeTypeForTopologyElement({ topologyElement })

  const { primaryKey, secondaryKey } = getKeys({
    sliderInstanceType,
    instanceResource: resource,
    storageMultiplier,
    sliderNodeType,
  })
  const maxSizeValue = Math.max(...sizes)
  const possibleNumberOfNodes = maxNodeCount > 1 ? range(2, maxNodeCount + 1) : []
  const multiNodeSizes =
    maxSizeValue && possibleNumberOfNodes.length > 0
      ? possibleNumberOfNodes.map((value) => value * maxSizeValue)
      : [] // The API only returns the size per 1 node, not the the total sizes if a user needs more than 1 node per instance

  const combinedSizes = sizes.concat(multiNodeSizes) // Combining the sizes returned by the API and the calculated sizes based on number of nodes

  const showZero =
    canShowZero &&
    canDisableConfiguration({
      topologyElement,
      sliderInstanceType,
      dedicatedMasterThreshold: getDedicatedMasterThresholdFromTemplate({
        deploymentTemplate: deployment,
      }),
    })

  const combinedSizesWithZeroIfApplicable = showZero ? [0, ...combinedSizes] : combinedSizes

  // It's possible for ECE users to create deployments via the API that don't conform to the sizing set forth in the UI
  // In this case, we need to ensure the UI doesn't break and we display the accurate sizing.
  const currentOptionExists =
    size === 0 || // either handled with an explicit zero for autoscaled tiers, or a disable control for others
    combinedSizesWithZeroIfApplicable.find((option) => option === size)

  if (!currentOptionExists) {
    combinedSizesWithZeroIfApplicable.push(size)
  }

  const inTrial = getInTrial()

  const trialLimit = getSliderTrialLimit({
    inTrial,
    sliderInstanceType,
    sliderNodeTypes,
  })

  const options = combinedSizesWithZeroIfApplicable.map((value) => {
    const memorySize = getNumber({
      resourceIn: resource,
      storageMultiplier,
      totalSize: value,
      resourceOut: `memory`,
      isBlobStorage,
    })

    return {
      label: getSizeOptionText({
        instanceResource: resource,
        storageMultiplier,
        cpuMultiplier,
        value,
        primaryKey,
        secondaryKey,
        isBlobStorage,
        isMachineLearning: isDedicatedML({ topologyElement }),
      }),
      value,
      disabled: trialLimit && memorySize > trialLimit.memorySize,
    }
  })

  return options
}

/**
 * Size options, or "current size" in the context of autoscaling. Values outside
 * the current autoscaling range, if any, are disabled.
 */
export const makeCurrentSizePickerOptions = (
  args: MakeSizePickerOptionsProps,
): Array<EuiComboBoxOptionOption<number>> => {
  const { autoscalingMinSize, autoscalingMaxSize, isAutoscalingEnabled } = args

  const options = makeAllSizePickerOptions(args)

  return options.map((option) => {
    if (typeof option.value !== 'number') {
      return option // sanity
    }

    return {
      ...option,
      disabled: option.disabled || isDisabled(option.value),
    }
  })

  function isDisabled(value: number) {
    if (!isAutoscalingEnabled) {
      return
    }

    return (
      (typeof autoscalingMinSize === 'number' && value < autoscalingMinSize) ||
      (typeof autoscalingMaxSize === 'number' && value > autoscalingMaxSize)
    )
  }
}

/**
 * Autoscaling minimum size options. Values greater than the max size are disabled.
 */
export const makeAutoscalingMinSizePickerOptions = (
  args: MakeSizePickerOptionsProps,
): Array<EuiComboBoxOptionOption<number>> => {
  const { autoscalingMaxSize } = args

  return makeAllSizePickerOptions(args).map((option) => ({
    ...option,
    disabled:
      option.disabled ||
      Boolean(
        typeof option.value === 'number' &&
          typeof autoscalingMaxSize === 'number' &&
          option.value > autoscalingMaxSize,
      ),
  }))
}

/**
 * Autoscaling maximum size options. Values less than the autoscaling min (if
 * defined) or current size (if not) are disabled.
 */
export const makeAutoscalingMaxSizePickerOptions = (
  args: MakeSizePickerOptionsProps,
): Array<EuiComboBoxOptionOption<number>> => {
  const { size, autoscalingMinSize } = args

  return makeAllSizePickerOptions(args).map((option) => ({
    ...option,
    disabled:
      option.disabled ||
      Boolean(
        typeof option.value === 'number' &&
          option.value < (typeof autoscalingMinSize === 'number' ? autoscalingMinSize : size),
      ),
  }))
}

export function getBlobStorageHelpText({
  resourceIn,
  storageMultiplier,
  size,
  isBlobStorage,
}: {
  resourceIn: DiscreteSizes['resource']
  storageMultiplier: number | undefined
  size: number
  isBlobStorage: boolean
}): ReactElement | null {
  if (!isBlobStorage) {
    return null
  }

  if (size === 0) {
    return null // avoid "Includes 0 MB of cached storage for 0 MB of storage."
  }

  const searchableSize = getNumber({
    resourceIn,
    resourceOut: 'storage',
    totalSize: size,
    storageMultiplier,
    isBlobStorage: true,
  })

  const cachedSize = getNumber({
    resourceIn,
    resourceOut: 'storage',
    totalSize: size,
    storageMultiplier,
    isBlobStorage: false,
  })

  return (
    <FormattedMessage
      id='size-picker.frozen-tier.sizing-help-text'
      defaultMessage='Includes {cachedSize} of cached storage for {searchableSize} of storage.'
      values={{
        searchableSize: prettySize(searchableSize),
        cachedSize: prettySize(cachedSize),
      }}
    />
  )
}
