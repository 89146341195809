/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState, Region, Runner as RunnerType } from '@modules/ui-types'

import { fetchRunnerRequest, getRegion, getRunner } from '../../reducers'
import { fetchRunner } from '../../actions/runners'
import { fetchCoordinatorCandidates } from '../../actions/candidates'
import withPolling from '../../lib/withPolling'

import Runner from './Runner'

import type { ReduxState, ThunkDispatch } from '../../types/redux'
import type { RouteComponentProps } from 'react-router-dom'
import type { ReactNode } from 'react'

interface StateProps {
  runnerId: string
  region: Region
  runner: RunnerType
  runnerRequest: AsyncRequestState
}

interface DispatchProps {
  fetchRunner: () => Promise<any>
  fetchCoordinatorCandidates: () => Promise<any>
}

interface QueryParams {
  regionId: string
  hostId: string
}

interface ConsumerProps extends RouteComponentProps<QueryParams> {
  children?: ReactNode
  spacerBefore?: boolean
  spacerAfter?: boolean
}

// eslint-disable-next-line no-shadow
const pollingComponent = withPolling(Runner, ({ fetchRunner, fetchCoordinatorCandidates }) => ({
  onPoll: () => Promise.all([fetchRunner(), fetchCoordinatorCandidates()]),
  pollImmediately: [['regionId'], ['hostId']],
}))

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId, hostId },
    },
  }: ConsumerProps,
): StateProps => ({
  runnerId: hostId,
  region: getRegion(state, regionId)!,
  runner: getRunner(state, regionId, hostId),
  runnerRequest: fetchRunnerRequest(state, regionId, hostId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { regionId, hostId },
    },
  }: ConsumerProps,
) => ({
  fetchRunner: () => dispatch(fetchRunner(regionId, hostId)),
  fetchCoordinatorCandidates: () => dispatch(fetchCoordinatorCandidates(regionId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(pollingComponent)
