/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState } from '@modules/ui-types'

import {
  fetchManagedApiKeys,
  resetRevokeKeysRequest,
  revokeApiKeys,
} from '../../../../../actions/apiKeys'
import {
  fetchManagedApiKeysRequest,
  getManagedApiKeys,
  revokeApiKeysRequest,
} from '../../../../../reducers'
import { canSeeApiKeys } from '../../../../../lib/apiKeys'

import ManageApiKeys from './ManageApiKeys'

import type { ApiKey } from './types'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  managedApiKeys?: any[]
  fetchManagedKeysRequest: AsyncRequestState
  revokeApiKeysRequest: AsyncRequestState
  showApiKeys: boolean
}

type DispatchProps = {
  fetchManagedApiKeys: () => void
  resetRevokeKeysRequest: () => void
  revokeApiKeys: (keys: ApiKey[]) => void
}

type ConsumerProps = RouteComponentProps

const mapStateToProps = (state): StateProps => {
  const managedApiKeys = getManagedApiKeys(state)

  return {
    managedApiKeys,
    fetchManagedKeysRequest: fetchManagedApiKeysRequest(state),
    revokeApiKeysRequest: revokeApiKeysRequest(state),
    showApiKeys: canSeeApiKeys(),
  }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchManagedApiKeys: () => dispatch(fetchManagedApiKeys()),
  resetRevokeKeysRequest: () => dispatch(resetRevokeKeysRequest()),
  revokeApiKeys: (keys) => dispatch(revokeApiKeys(keys)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ManageApiKeys)
