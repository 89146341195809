/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type {
  SecurityRealmInfoList,
  SecurityRealmsReorderRequest,
} from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import withPolling from '../../../../../../lib/withPolling'
import {
  deleteActiveDirectorySecurityRealmRequest,
  deleteLdapSecurityRealmRequest,
  deleteSamlSecurityRealmRequest,
  fetchSecurityRealmsRequest,
  getSecurityRealms,
  reorderSecurityRealmsRequest,
} from '../../../../reducers'
import {
  deleteActiveDirectorySecurityRealm,
  deleteLdapSecurityRealm,
  deleteSamlSecurityRealm,
  fetchSecurityCluster,
  fetchSecurityRealms,
  reorderSecurityRealms,
} from '../../../../actions'

import ExternalAuthenticationList from './ExternalAuthenticationList'

import type { ThunkDispatch, ReduxState } from '@/types/redux'

interface StateProps {
  securityRealms: SecurityRealmInfoList
  deleteActiveDirectorySecurityRealmRequest: AsyncRequestState
  deleteLdapSecurityRealmRequest: AsyncRequestState
  deleteSamlSecurityRealmRequest: AsyncRequestState
  getSecurityRealmsRequest: AsyncRequestState
  reorderSecurityRealmsRequest: AsyncRequestState
  regionId: string
}

interface DispatchProps {
  deleteActiveDirectorySecurityRealm: (realmId: string) => Promise<any>
  deleteLdapSecurityRealm: (realmId: string) => Promise<any>
  deleteSamlSecurityRealm: (realmId: string) => Promise<any>
  fetchSecurityRealms: () => Promise<any>
  reorderSecurityRealms: (realms: SecurityRealmsReorderRequest) => Promise<any>
}

interface RequiredProps {
  isPlanChangePending?: boolean
  isSecurityDeploymentDisabled?: boolean
  match: {
    params: { regionId: string }
  }
}

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  },
) => ({
  securityRealms: getSecurityRealms(state, regionId),
  deleteActiveDirectorySecurityRealmRequest: deleteActiveDirectorySecurityRealmRequest(
    state,
    regionId,
  ),
  deleteLdapSecurityRealmRequest: deleteLdapSecurityRealmRequest(state, regionId),
  deleteSamlSecurityRealmRequest: deleteSamlSecurityRealmRequest(state, regionId),
  getSecurityRealmsRequest: fetchSecurityRealmsRequest(state, regionId),
  reorderSecurityRealmsRequest: reorderSecurityRealmsRequest(state, regionId),
  regionId,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { regionId },
    },
  },
) => ({
  deleteActiveDirectorySecurityRealm: (realmId: string) =>
    dispatch(deleteActiveDirectorySecurityRealm(regionId, realmId)).then(() =>
      dispatch(fetchSecurityCluster(regionId)),
    ),

  deleteLdapSecurityRealm: (realmId: string) =>
    dispatch(deleteLdapSecurityRealm(regionId, realmId)).then(() =>
      dispatch(fetchSecurityCluster(regionId)),
    ),

  deleteSamlSecurityRealm: (realmId: string) =>
    dispatch(deleteSamlSecurityRealm(regionId, realmId)).then(() =>
      dispatch(fetchSecurityCluster(regionId)),
    ),

  fetchSecurityRealms: () => dispatch(fetchSecurityRealms(regionId)),
  reorderSecurityRealms: (realms: SecurityRealmsReorderRequest) =>
    dispatch(reorderSecurityRealms(regionId, realms)),
})

const pollingComponent = withPolling(
  ExternalAuthenticationList,
  ({ fetchSecurityRealms: onPoll }) => ({ onPoll }),
)

export default connect<StateProps, DispatchProps, RequiredProps>(
  mapStateToProps,
  mapDispatchToProps,
)(pollingComponent)
