/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { Runner } from '@modules/ui-types'

import { getRunner } from '../../reducers'

import Host from './Host'

import type { RouteComponentProps } from 'react-router'

type StateProps = {
  regionId: string
  hostId: string
  hostType?: HostType
  runner?: Runner
}

type DispatchProps = unknown

type HostType = 'allocator' | 'proxy' | 'control-plane'

type QueryParams = {
  regionId: string
  hostId: string
  hostType?: HostType
}

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId, hostId, hostType },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  hostId,
  hostType,
  runner: getRunner(state, regionId, hostId),
})

const mapDispatchToProps: DispatchProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Host)
