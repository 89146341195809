/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { capitalize } from 'lodash'
import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import { EuiCallOut, EuiToolTip } from '@elastic/eui'

import { isPrepaidConsumptionCustomer } from '@/lib/billingDetails'

import DangerRadioButton from '../../../../DangerRadioButton'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'
import type { Props } from './types'

const messages = defineMessages({
  downgradeThroughSupport: {
    id: `organization.organization-overview.ensure-premium.downgrade-through-support`,
    defaultMessage: `Downgrading to Standard is not supported through the UI. Please reach out to the Support team.`,
  },
})

const EnsurePremiumButton: FunctionComponent<Props & WrappedComponentProps> = ({
  intl: { formatMessage },
  ensurePremium,
  ensureLevel,
  level,
  spin = false,
  billingDetails,
}) => {
  const isCurrentLevel = ensureLevel === level
  const isStandardLevel = level === `standard`
  const isNotStandardLevel = !isStandardLevel
  const ensureStandard = ensureLevel === `standard`
  const isPrepaidConsumption =
    billingDetails.data && isPrepaidConsumptionCustomer(billingDetails.data)
  const isDisabled =
    (billingDetails.loading && ensureStandard) || // disable "standard" when loading data
    (ensureStandard && isNotStandardLevel && isPrepaidConsumption)

  const ensurePremiumButton = (
    <DangerRadioButton
      id={`ensure-premium-level--${ensureLevel}`}
      onConfirm={ensurePremium}
      disabled={isDisabled}
      checked={isCurrentLevel}
      spin={spin}
      modal={{
        title: (
          <FormattedMessage
            id='organization.organization-overview.ensure-premium.modal-title'
            defaultMessage='Change subscription from {level} to {ensureLevel}?'
            values={{
              level: capitalize(level),
              ensureLevel: capitalize(ensureLevel),
            }}
          />
        ),
        body:
          isStandardLevel && isPrepaidConsumption ? (
            <EuiCallOut
              color='warning'
              title={
                <FormattedMessage
                  id='organization.organization-overview.ensure-premium.modal-body-standard-warning-title'
                  defaultMessage='This action cannot be undone'
                />
              }
            >
              <FormattedMessage
                id='organization.organization-overview.ensure-premium.modal-body-standard-warning'
                defaultMessage="Once you confirm, you won't be able to downgrade this customer back to a Standard subscription through the UI."
              />
            </EuiCallOut>
          ) : null,
      }}
    >
      {capitalize(ensureLevel)}
    </DangerRadioButton>
  )

  if (isDisabled) {
    return (
      <EuiToolTip content={formatMessage(messages.downgradeThroughSupport)}>
        <div>{ensurePremiumButton}</div>
      </EuiToolTip>
    )
  }

  return ensurePremiumButton
}

export default injectIntl(EnsurePremiumButton)
