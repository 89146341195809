/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { injectIntl, FormattedMessage } from 'react-intl'

import { EuiText, withEuiTheme } from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import messages from './messages'

import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'
import type { Role } from '@/components/Users/RoleAssignmentsPanel/types'

type Props = {
  roleId: Role
  currentAllDeploymentsCount: number
} & WrappedComponentProps

interface PropsWithTheme extends Props, WithEuiThemeProps {}

const AllRolesPanel: FunctionComponent<PropsWithTheme> = ({
  roleId,
  theme,
  currentAllDeploymentsCount,
  intl: { formatMessage },
}) => (
  <div
    css={css({
      padding: `${theme.euiTheme.size.xl} 0 ${theme.euiTheme.size.m}`,
    })}
  >
    <EuiText>
      <FormattedMessage
        id='organization.view-permissions-flyout.all.{roleId}.description'
        defaultMessage='{role} on all {count} current and future deployments.'
        values={{
          role: formatMessage(messages.label[roleId]),
          count: `${currentAllDeploymentsCount}`,
        }}
      />
    </EuiText>
  </div>
)

export default injectIntl(withEuiTheme(AllRolesPanel))
