/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import type { LoggingSettings } from '@modules/cloud-api/v1/types'
import type { LoggerLevel, AsyncRequestState } from '@modules/ui-types'
import { withErrorBoundary } from '@modules/cui'

import ZkLoggingContent from './ZkLoggingContent'

type PatchLoggingLevels = {
  [key: string]: LoggerLevel | null
}

type EditModeOptions = 'new' | 'view'

type Props = {
  tableHeader?: boolean
  loggingSettings: LoggingSettings | null
  fetchLoggingSettings: () => void
  fetchLoggingSettingsRequest: AsyncRequestState
  patchLoggingSettings: (settings: { loggingLevels: PatchLoggingLevels }) => Promise<void>
  patchLoggingSettingsRequest: AsyncRequestState
  editMode?: EditModeOptions
  isFlyoutOpen?: boolean
  closeFlyout?: () => void
  toggleToView?: () => void
}

class ZkLoggingSettings extends Component<Props> {
  render() {
    const { closeFlyout } = this.props

    const handleClose = () => {
      if (closeFlyout) {
        closeFlyout()
      }

      return
    }

    const handleEditClose = () => {
      const { toggleToView } = this.props

      handleClose()

      if (toggleToView) {
        toggleToView()
      }
    }

    return <ZkLoggingContent {...this.props} handleEditClose={() => handleEditClose()} />
  }
}

export default withErrorBoundary(ZkLoggingSettings)
