/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { TrustRelationshipGetResponse } from '@modules/cloud-api/v1/types'
import { CuiPermissibleControl } from '@modules/cui'

import type { FunctionComponent } from 'react'

export type Props = {
  localTrustRelationship: TrustRelationshipGetResponse
}

const DownloadRootCertificate: FunctionComponent<Props> = ({
  localTrustRelationship,
}): JSX.Element => {
  const { id, name, public_ca_cert } = localTrustRelationship

  // public_ca_cert isn't returned on the object if a user doesn't have the permission to
  // view the cert, even if the endpoint permission is accepted and the other fields exist.
  const disableButton = !public_ca_cert

  const blob = new window.Blob([public_ca_cert || ``], {
    type: `application/x-x509-ca-cert;charset=utf-8`,
  })
  const fileName = `${name.replace(/ /g, '-').toLowerCase()}-${id}.crt`
  const contents = window.URL.createObjectURL(blob)

  return (
    <CuiPermissibleControl permissions={Permission.getTrustRelationship} force={disableButton}>
      <EuiButton
        isDisabled={disableButton}
        download={fileName}
        href={contents}
        iconType='download'
        iconSide='right'
      >
        <FormattedMessage
          id='trust-management.download-root-certificate-button'
          defaultMessage='Download'
        />
      </EuiButton>
    </CuiPermissibleControl>
  )
}

export default DownloadRootCertificate
