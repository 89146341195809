/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import { CuiFilterContext, nullQueryExecutor } from '@modules/cui'
import type { ControlledFilterQuery, OnFilterChange } from '@modules/cui'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  query: ControlledFilterQuery
  onChange: OnFilterChange<DeploymentSearchResponse>
  deployments: DeploymentSearchResponse[]
  isLoading: boolean
}

const messages = defineMessages({
  searchBarPlaceholder: {
    id: 'role-assignments.deployment-access.deployments-search.placeholder',
    defaultMessage: 'Search deployment',
  },
  emptyMessage: {
    id: 'role-assignments.deployment-access.specific-deployments.empty-search-message',
    defaultMessage: 'No deployments match your query',
  },
})

const SpecificDeploymentsFilterContext: FunctionComponent<Props> = ({
  intl: { formatMessage },
  query,
  onChange,
  deployments,
  isLoading,
}) => (
  <Fragment>
    <EuiSpacer size='l' />

    <CuiFilterContext<DeploymentSearchResponse>
      query={query}
      onChange={onChange}
      records={deployments}
      executeQuery={nullQueryExecutor}
      placeholder={formatMessage(messages.searchBarPlaceholder)}
      emptyMessage={formatMessage(messages.emptyMessage)}
      incremental={true}
      isLoading={isLoading}
    />
  </Fragment>
)

export default injectIntl(SpecificDeploymentsFilterContext)
