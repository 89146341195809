/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { find, last, split } from 'lodash'

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiCode,
  EuiDescribedFormGroup,
  EuiFormRow,
  EuiSkeletonText,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import type { PlatformInfo } from '@modules/cloud-api/v1/types'
import { CuiTable, CuiLink } from '@modules/cui'

import { hostUrl } from '../../../lib/urlBuilder'

import './platform.scss'

interface Props {
  regionId: string
  platform: PlatformInfo | null
}

type State = {
  expanded: boolean
}

class PlatformAdminServersStatus extends Component<Props, State> {
  state: State = {
    expanded: false,
  }

  render() {
    return (
      <Fragment>
        <EuiDescribedFormGroup
          title={
            <EuiTitle size='xs'>
              <h3>
                <FormattedMessage
                  id='region-admin-platform.admin-api-server-status'
                  defaultMessage='Admin API Server'
                />
              </h3>
            </EuiTitle>
          }
          description={
            <FormattedMessage
              id='region-admin-platform.admin-api-server-description'
              defaultMessage='Check the status of the API server hosts.'
            />
          }
          fullWidth={true}
        >
          <EuiFormRow fullWidth={true}>{this.renderStatusSwitch()}</EuiFormRow>
        </EuiDescribedFormGroup>

        {this.renderStatusTable()}
      </Fragment>
    )
  }

  renderStatusSwitch() {
    const { expanded } = this.state

    if (expanded) {
      return (
        <EuiButtonEmpty
          data-test-id='admin-api-server-hide-details'
          iconSide='right'
          iconType='arrowUp'
          onClick={() => this.setState({ expanded: false })}
        >
          <FormattedMessage
            id='region-admin-platform.hide-system-details'
            defaultMessage='Hide system details'
          />
        </EuiButtonEmpty>
      )
    }

    return (
      <EuiButtonEmpty
        data-test-id='admin-api-server-show-details'
        iconSide='right'
        iconType='arrowDown'
        onClick={() => this.setState({ expanded: true })}
      >
        <FormattedMessage
          id='region-admin-platform.view-system-details'
          defaultMessage='View system details'
        />
      </EuiButtonEmpty>
    )
  }

  renderStatusTable() {
    const { regionId, platform } = this.props
    const { expanded } = this.state

    if (!expanded) {
      return null
    }

    if (!platform) {
      return (
        <Fragment>
          <EuiSpacer size='l' />

          <EuiSkeletonText />
        </Fragment>
      )
    }

    const adminConsole = find(platform.services, { type: `admin-console` })

    const columns = [
      {
        label: <FormattedMessage id='region-admin-platform.host' defaultMessage='Host' />,
        render: (image) => <CuiLink to={hostUrl(regionId, image.id)}>{image.id}</CuiLink>,
        sortKey: `id`,
        width: `200px`,
      },
      {
        label: <FormattedMessage id='region-admin-platform.version' defaultMessage='Version' />,
        render: (image) => <EuiBadge color='hollow'>{image.version}</EuiBadge>,
        sortKey: `version`,
      },
      {
        className: 'regionAdmin-buildSha',
        label: <FormattedMessage id='region-admin-platform.build-sha' defaultMessage='Build SHA' />,
        render: (image) => <EuiCode data-test-id='build-sha'>{getHash(image)}</EuiCode>,
        sortKey: getHash,
      },
    ]

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <CuiTable
          rows={adminConsole ? adminConsole.image : []}
          columns={columns}
          getRowId={(image) => image.id}
        />
      </Fragment>
    )
  }
}

export default PlatformAdminServersStatus

function getHash(image) {
  return last(split(image.hash, `:`))
}
