/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { WorkingSnapshotRepository } from '@modules/ui-types'

import { getConfigForKey } from '@/selectors'

import RepositoryEditor from '../RepositoryEditor'

import type { RouteComponentProps } from 'react-router'

interface StateProps {
  regionId: string
  repository: WorkingSnapshotRepository
}

interface DispatchProps {}

type ConsumerProps = RouteComponentProps<{
  regionId: string | undefined
}>

const mapStateToProps = (state, { match: { params } }: ConsumerProps) => {
  const regionIdParam = params.regionId
  const defaultRegionId = getConfigForKey(state, `DEFAULT_REGION`)
  const regionId = regionIdParam || defaultRegionId

  return {
    regionId,
    repository: {
      repository_name: ``,
      config: {
        type: `s3`,
        settings: {},
      },
    },
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(mapStateToProps)(RepositoryEditor)
