/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'

import type { AnyProject } from '@modules/project-api/types'
import { getUpdateProjectMutationHook } from '@modules/project-lib/hooks/update'

import genericMessages from '../helpers/genericMessages'

export type Props = {
  project: AnyProject
}

const EditableProjectName: React.FunctionComponent<Props> = ({ project }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [name, setName] = useState(project.name)

  const updateProjectMutation = getUpdateProjectMutationHook(project.type)()

  // autofocus textbox on edit
  const inputRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
    }
  }, [isEditing])

  if (!isEditing && !updateProjectMutation.isLoading) {
    return (
      <div style={{ marginTop: -8, marginBottom: -8 }}>
        {/* ^ offset input/button overhead in the interest of consistent line-spacing */}
        <EuiFlexGroup data-test-subj='projectName' responsive={false} alignItems='center'>
          <EuiFlexItem grow={false}>{project.name}</EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty size='s' onClick={() => setIsEditing(true)}>
              <FormattedMessage {...genericMessages.edit} />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    )
  }

  return (
    <div style={{ marginTop: -8, marginBottom: -8 }}>
      {/* ^ offset input/button overhead in the interest of consistent line-spacing */}
      <EuiFlexGroup
        data-test-subj='projectName'
        responsive={false}
        gutterSize='m'
        alignItems='center'
      >
        <EuiFlexItem grow={false}>
          <EuiFieldText
            inputRef={inputRef}
            compressed={true}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            data-test-subj='submitButton'
            iconType='check'
            iconSize='l'
            size='s'
            color='primary'
            display='fill'
            disabled={name.trim().length === 0}
            isLoading={updateProjectMutation.isLoading}
            onClick={() => {
              setIsEditing(false)
              updateProjectMutation.mutate({ id: project.id, body: { name } })
            }}
          />
        </EuiFlexItem>
        {!updateProjectMutation.isLoading && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              data-test-subj='cancelButton'
              iconType='cross'
              iconSize='l'
              size='s'
              color='text'
              display='base'
              onClick={() => {
                setIsEditing(false)
                setName(project.name)
              }}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </div>
  )
}

export default EditableProjectName
