/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  actions: {
    id: 'projects.messages.actions',
    defaultMessage: 'Actions',
  },
  cancel: {
    id: 'projects.messages.cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'projects.messages.delete',
    defaultMessage: 'Delete',
  },
  deleting: {
    id: 'projects.messages.deleting',
    defaultMessage: 'Deleting...',
  },
  edit: {
    id: 'projects.messages.edit',
    defaultMessage: 'Edit',
  },
  elasticsearch: {
    id: 'projects.messages.elasticsearch',
    defaultMessage: 'Elasticsearch',
  },
  fleet: {
    id: 'projects.messages.fleet',
    defaultMessage: 'Fleet',
  },
  kibana: {
    id: 'projects.messages.kibana',
    defaultMessage: 'Kibana',
  },
  manage: {
    id: 'projects.messages.manage',
    defaultMessage: 'Manage',
  },
  open: {
    id: 'projects.messages.open',
    defaultMessage: 'Open',
  },
  save: {
    id: 'projects.messages.save',
    defaultMessage: 'Save',
  },
  saving: {
    id: 'projects.messages.saving',
    defaultMessage: 'Saving...',
  },
  unknown: {
    id: 'projects.messages.unknown',
    defaultMessage: 'Unknown',
  },
})
