/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getInTrial } from '@/lib/reduxShortcuts/trials'

import { getConfigForKey } from '../../../../../../../store'

import ZoneCount from './ZoneCount'

import type { Props } from './ZoneCount'

type ConnectedPropKeys = 'dedicatedMastersFixedZoneCount' | 'inTrial'
type StateProps = Pick<Props, ConnectedPropKeys>
type ConsumerProps = Omit<Props, ConnectedPropKeys>

const mapStateToProps = (): StateProps => ({
  dedicatedMastersFixedZoneCount: getConfigForKey(`DEDICATED_MASTERS_FIXED_ZONE_COUNT`),
  inTrial: getInTrial(),
})

export default connect<StateProps, unknown, ConsumerProps>(mapStateToProps)(ZoneCount)
