/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { SEARCH_USERS } from '@/apps/adminconsole/constants/actions'

import * as actions from '../../constants/actions'
import { getConfigForKey } from '../../store'

import { getAsyncRequestState } from './'

export function fetchRegionListRequest(state, ...rest) {
  const defaultRegionId = Boolean(getConfigForKey(`DEFAULT_REGION`))

  if (defaultRegionId) {
    return fetchPlatformRequest(state, ...rest)
  }

  return fetchProvidersRequest(state, ...rest)
}

export const fetchRootRequest = getAsyncRequestState(actions.FETCH_ROOT)
export const fetchLicenseRequest = getAsyncRequestState(actions.FETCH_LICENSE)
export const addLicenseRequest = getAsyncRequestState(actions.SET_LICENSE)
export const removeLicenseRequest = getAsyncRequestState(actions.REMOVE_LICENSE)
export const acceptEulaRequest = getAsyncRequestState(actions.ACCEPT_EULA)
export const fetchResetPasswordStatus = getAsyncRequestState(actions.RESET_CLUSTER_PASSWORD)

export const setAllocatorMaintenanceModeRequest = getAsyncRequestState(
  actions.SET_ALLOCATOR_MAINTENANCE_MODE,
)
export const getAllocatorRequest = getAsyncRequestState(actions.FETCH_ALLOCATOR)
export const getPhoneHomeConfigRequest = getAsyncRequestState(actions.UPDATE_PHONE_HOME_ENABLED)
export const saveClusterPlanRequest = getAsyncRequestState(actions.SAVE_CLUSTER_PLAN)
export const fetchRunnerRequest = getAsyncRequestState(actions.FETCH_RUNNER)
export const deleteRunnerRequest = getAsyncRequestState(actions.DELETE_RUNNER)
export const fetchVersionsRequest = getAsyncRequestState(actions.FETCH_VERSIONS)
export const fetchVersionRequest = getAsyncRequestState(actions.FETCH_VERSION)
export const updateKibanaPlanRequest = getAsyncRequestState(actions.UPDATE_KIBANA_PLAN)
export const fetchPlatformRequest = getAsyncRequestState(actions.FETCH_PLATFORM)
export const fetchTlsCertificateRequest = getAsyncRequestState(actions.FETCH_TLS_CERTIFICATE)
export const uploadTlsCertificateRequest = getAsyncRequestState(actions.UPLOAD_TLS_CERTIFICATE)
export const downloadClusterDiagnosticBundleRequest = getAsyncRequestState(
  actions.DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE,
)
export const fetchSnapshotRepositoryRequest = getAsyncRequestState(
  actions.FETCH_SNAPSHOT_REPOSITORY,
)
export const fetchSnapshotRepositoriesRequest = getAsyncRequestState(
  actions.FETCH_SNAPSHOT_REPOSITORIES,
)
export const deleteSnapshotRepositoryRequest = getAsyncRequestState(
  actions.DELETE_SNAPSHOT_REPOSITORY,
)
export const upsertSnapshotRepositoryRequest = getAsyncRequestState(
  actions.UPSERT_SNAPSHOT_REPOSITORY,
)
export const fetchClusterHealthRequest = getAsyncRequestState(actions.FETCH_CLUSTER_HEALTH)
export const downloadClusterLogsRequest = getAsyncRequestState(actions.DOWNLOAD_CLUSTER_LOGS)
export const uploadStackPackRequest = getAsyncRequestState(actions.UPLOAD_STACK_PACK)
export const setDiskQuotaRequest = getAsyncRequestState(actions.SET_DISK_QUOTA)
export const setInstanceCapacityRequest = getAsyncRequestState(actions.SET_INSTANCE_CAPACITY)
export const retryFailedShardAllocationsRequest = getAsyncRequestState(
  actions.RETRY_FAILED_SHARD_ALLOCATIONS,
)
export const fetchSnapshotsRequest = getAsyncRequestState(actions.FETCH_SNAPSHOTS)
export const restoreSnapshotRequest = getAsyncRequestState(actions.RESTORE_SNAPSHOT)
export const deleteStackVersionRequest = getAsyncRequestState(actions.DELETE_STACK_VERSION)
export const fetchUpgradeAssistantStatusRequest = getAsyncRequestState(
  actions.FETCH_UPGRADE_ASSISTANT_STATUS,
)
export const addAllocatorTagRequest = getAsyncRequestState(actions.ADD_ALLOCATOR_TAG)
export const removeAllocatorTagRequest = getAsyncRequestState(actions.REMOVE_ALLOCATOR_TAG)
export const searchAllocatorsRequest = getAsyncRequestState(actions.SEARCH_ALLOCATORS)
export const searchRunnersRequest = getAsyncRequestState(actions.SEARCH_RUNNERS)
export const callStoredProcedureRequest = getAsyncRequestState(actions.CALL_STORED_PROCEDURE)
export const fetchInstanceConfigurationsRequest = getAsyncRequestState(
  actions.FETCH_INSTANCE_CONFIGURATIONS,
)
export const deleteInstanceConfigurationRequest = getAsyncRequestState(
  actions.DELETE_INSTANCE_CONFIGURATION,
)
export const fetchInstanceConfigurationRequest = getAsyncRequestState(
  actions.FETCH_INSTANCE_CONFIGURATION,
)
export const createInstanceConfigurationRequest = getAsyncRequestState(
  actions.CREATE_INSTANCE_CONFIGURATION,
)
export const updateInstanceConfigurationRequest = getAsyncRequestState(
  actions.UPDATE_INSTANCE_CONFIGURATION,
)
export const fetchDeploymentTemplatesRequest = getAsyncRequestState(
  actions.FETCH_DEPLOYMENT_TEMPLATES,
)
export const fetchDeploymentTemplateRequest = getAsyncRequestState(
  actions.FETCH_DEPLOYMENT_TEMPLATE,
)
export const createDeploymentTemplateRequest = getAsyncRequestState(
  actions.CREATE_DEPLOYMENT_TEMPLATE,
)
export const deleteDeploymentTemplateRequest = getAsyncRequestState(
  actions.DELETE_DEPLOYMENT_TEMPLATE,
)
export const updateDeploymentTemplateRequest = getAsyncRequestState(
  actions.UPDATE_DEPLOYMENT_TEMPLATE,
)
export const vacateEsClusterRequest = getAsyncRequestState(actions.VACATE_ES_CLUSTER)
export const vacateEsClusterValidateRequest = getAsyncRequestState(
  actions.VACATE_ES_CLUSTER_VALIDATE,
)
export const vacateAllocatorRequest = getAsyncRequestState(actions.VACATE_ALLOCATOR)
export const vacateAllocatorValidateRequest = getAsyncRequestState(
  actions.VACATE_ALLOCATOR_VALIDATE,
)
export const getLoginRequest = getAsyncRequestState(actions.LOG_IN)
export const getSubmitMfaResponseRequest = getAsyncRequestState(actions.SUBMIT_MFA_RESPONSE)
export const getVerifySaasCurrentUserMfaFactorRequest = getAsyncRequestState(
  actions.VERIFY_CURRENT_USER_MFA_FACTOR,
)
export const queryDeploymentProxyRequest = getAsyncRequestState(actions.QUERY_DEPLOYMENT_PROXY)
export const fetchSaasUserRequest = getAsyncRequestState(actions.FETCH_SAAS_USER)
export const fetchSnapshotStatusRequest = getAsyncRequestState(actions.FETCH_SNAPSHOT_STATUS)
export const fetchAllUsersRequest = getAsyncRequestState(actions.FETCH_ALL_USERS)
export const createUserRequest = getAsyncRequestState(actions.CREATE_USER)
export const updateUserRequest = getAsyncRequestState(actions.UPDATE_USER)
export const deleteUserRequest = getAsyncRequestState(actions.DELETE_USER)
export const fetchMfaDevicesRequest = getAsyncRequestState(actions.FETCH_MFA_DEVICES)
export const deleteEmailSuppressionsRequest = getAsyncRequestState(
  actions.DELETE_EMAIL_SUPPRESSIONS,
)
export const resetMfaRequest = getAsyncRequestState(actions.RESET_MFA)
export const fetchWhitelistedVersionRequest = getAsyncRequestState(
  actions.FETCH_WHITELISTED_VERSIONS,
)
export const removeWhitelistedVersionRequest = getAsyncRequestState(
  actions.DELETE_WHITELISTED_VERSION,
)
export const addWhitelistedVersionRequest = getAsyncRequestState(actions.PUT_WHITELISTED_VERSION)
export const fetchAuthMethodsRequest = getAsyncRequestState(actions.FETCH_AUTH_METHODS)
export const takeSnapshotRequest = getAsyncRequestState(actions.TAKE_SNAPSHOT)
export const fetchCcsSettingsRequest = getAsyncRequestState(actions.FETCH_CCS_SETTINGS)
export const updateCcsSettingsRequest = getAsyncRequestState(actions.UPDATE_CCS_SETTINGS)
export const createSecretRequest = getAsyncRequestState(actions.CREATE_SECRET)
export const deleteSecretRequest = getAsyncRequestState(actions.DELETE_SECRET)
export const fetchCurrentUserRequest = getAsyncRequestState(actions.FETCH_CURRENT_USER)
export const updateCurrentUserRequest = getAsyncRequestState(actions.UPDATE_CURRENT_USER)
export const startConstructorMaintenanceModeRequest = getAsyncRequestState(
  actions.START_CONSTRUCTOR_MAINTENANCE_MODE,
)
export const stopConstructorMaintenanceModeRequest = getAsyncRequestState(
  actions.STOP_CONSTRUCTOR_MAINTENANCE_MODE,
)
export const fetchCcsEligibleRemotesRequest = getAsyncRequestState(
  actions.FETCH_CCS_ELIGIBLE_REMOTES,
)
export const fetchResourceCommentsRequest = getAsyncRequestState(actions.FETCH_RESOURCE_COMMENTS)
export const createResourceCommentRequest = getAsyncRequestState(actions.CREATE_RESOURCE_COMMENT)
export const deleteResourceCommentRequest = getAsyncRequestState(actions.DELETE_RESOURCE_COMMENT)
export const updateResourceCommentRequest = getAsyncRequestState(actions.UPDATE_RESOURCE_COMMENT)
export const herokuAuthHandshakeRequest = getAsyncRequestState(actions.HEROKU_AUTH_HANDSHAKE)
export const fetchHeapDumpsRequest = getAsyncRequestState(actions.FETCH_HEAP_DUMPS)
export const startHeapDumpCaptureRequest = getAsyncRequestState(actions.CAPTURE_HEAP_DUMP)
export const captureThreadDumpRequest = getAsyncRequestState(actions.CAPTURE_THREAD_DUMP)
export const fetchApiKeysRequest = getAsyncRequestState(actions.FETCH_API_KEYS)
export const fetchManagedApiKeysRequest = getAsyncRequestState(actions.FETCH_MANAGED_API_KEYS)
export const revokeApiKeyRequest = getAsyncRequestState(actions.REVOKE_API_KEY)
export const generateApiKeyRequest = getAsyncRequestState(actions.GENERATE_API_KEY)
export const revokeApiKeysRequest = getAsyncRequestState(actions.REVOKE_API_KEYS)
export const fetchStackDeploymentRequest = getAsyncRequestState(actions.FETCH_STACK_DEPLOYMENT)
export const createStackDeploymentRequest = getAsyncRequestState(actions.CREATE_STACK_DEPLOYMENT)
export const claimInstantStackDeploymentRequest = getAsyncRequestState(
  actions.CLAIM_INSTANT_STACK_DEPLOYMENT,
)
export const deleteStackDeploymentRequest = getAsyncRequestState(actions.DELETE_STACK_DEPLOYMENT)
export const fetchProvidersRequest = getAsyncRequestState(actions.FETCH_PROVIDERS)
export const searchStackDeploymentsRequest = getAsyncRequestState(actions.SEARCH_STACK_DEPLOYMENTS)
export const updateStackDeploymentRequest = getAsyncRequestState(actions.UPDATE_STACK_DEPLOYMENT)
export const updateStackDeploymentDryRunRequest = getAsyncRequestState(
  actions.UPDATE_STACK_DEPLOYMENT_DRY_RUN,
)
export const restartStackDeploymentResourceRequest = getAsyncRequestState(
  actions.RESTART_DEPLOYMENT_RESOURCE,
)
export const restartStackDeploymentEsResourceRequest = getAsyncRequestState(
  actions.RESTART_DEPLOYMENT_ES_RESOURCE,
)
export const shutdownStackDeploymentRequest = getAsyncRequestState(actions.SHUTDOWN_DEPLOYMENT)

export const deleteStackDeploymentResourceRequest = getAsyncRequestState(
  actions.DELETE_DEPLOYMENT_RESOURCE,
)
export const setDeploymentResourceMetadataRequest = getAsyncRequestState(
  actions.SET_DEPLOYMENT_RESOURCE_METADATA,
)
export const cancelDeploymentResourcePlanRequest = getAsyncRequestState(
  actions.CANCEL_DEPLOYMENT_RESOURCE_PLAN,
)
export const restoreStackDeploymentRequest = getAsyncRequestState(actions.RESTORE_DEPLOYMENT)
export const extendTrialRequest = getAsyncRequestState(actions.EXTEND_TRIAL)
export const fetchCloudStatusRequest = getAsyncRequestState(actions.FETCH_CLOUD_STATUS)
export const fetchBlogsRequest = getAsyncRequestState(actions.FETCH_BLOGS)
export const fetchUpcomingEventsRequest = getAsyncRequestState(actions.FETCH_UPCOMING_EVENTS)
export const fetchBlueprintRolesRequest = getAsyncRequestState(actions.FETCH_BLUEPRINT_ROLES)
export const createSaasUserRequest = getAsyncRequestState(actions.CREATE_SAAS_USER)
export const updateRolesRequest = getAsyncRequestState(actions.UPDATE_RUNNER_ROLES)
export const demoteCoordinatorRequest = getAsyncRequestState(actions.DEMOTE_COORDINATOR)
export const fetchFeedRequest = getAsyncRequestState(actions.FETCH_FEED)
export const updateSaasUserProfileRequest = getAsyncRequestState(actions.UPDATE_SAAS_USER_PROFILE)
export const scrubSaasUserRequest = getAsyncRequestState(actions.SCRUB_SAAS_USER)
export const fetchAdminconsolesRequest = getAsyncRequestState(actions.FETCH_ADMINCONSOLES)
export const fetchAdminconsoleLoggingSettingsRequest = getAsyncRequestState(
  actions.FETCH_ADMINCONSOLE_LOGGING_SETTINGS,
)
export const patchAdminconsoleLoggingSettingsRequest = getAsyncRequestState(
  actions.PATCH_ADMINCONSOLE_LOGGING_SETTINGS,
)
export const enableSlmRequest = getAsyncRequestState(actions.ENABLE_SLM)
export const executeSlmPolicyRequest = getAsyncRequestState(actions.EXECUTE_SLM_POLICY)
export const fetchContainerSetsRequest = getAsyncRequestState(actions.FETCH_CONTAINER_SETS)
export const fetchContainerSetRequest = getAsyncRequestState(actions.FETCH_CONTAINER_SET)
export const fetchContainerRequest = getAsyncRequestState(actions.FETCH_CONTAINER)
export const fetchRunnerLoggingSettingsRequest = getAsyncRequestState(
  actions.FETCH_RUNNER_LOGGING_SETTINGS,
)
export const patchRunnerLoggingSettingsRequest = getAsyncRequestState(
  actions.PATCH_RUNNER_LOGGING_SETTINGS,
)
export const fetchAllocatorLoggingSettingsRequest = getAsyncRequestState(
  actions.FETCH_ALLOCATOR_LOGGING_SETTINGS,
)
export const patchAllocatorLoggingSettingsRequest = getAsyncRequestState(
  actions.PATCH_ALLOCATOR_LOGGING_SETTINGS,
)
export const fetchConstructorLoggingSettingsRequest = getAsyncRequestState(
  actions.FETCH_CONSTRUCTOR_LOGGING_SETTINGS,
)
export const patchConstructorLoggingSettingsRequest = getAsyncRequestState(
  actions.PATCH_CONSTRUCTOR_LOGGING_SETTINGS,
)
export const createTrafficFilterRulesetRequest = getAsyncRequestState(
  actions.CREATE_TRAFFIC_FILTER_RULESET,
)
export const createTrafficFilterRulesetAssociationRequest = getAsyncRequestState(
  actions.CREATE_TRAFFIC_FILTER_RULESET_ASSOCIATION,
)
export const deleteTrafficFilterRulesetAssociationRequest = getAsyncRequestState(
  actions.DELETE_TRAFFIC_FILTER_RULESET_ASSOCIATION,
)
export const deleteTrafficFilterRulesetRequest = getAsyncRequestState(
  actions.DELETE_TRAFFIC_FILTER_RULESET,
)

export const fetchTrafficFilterRulesetsRequest = getAsyncRequestState(
  actions.FETCH_TRAFFIC_FILTER_RULESETS,
)
export const updateTrafficFilterRulesetRequest = getAsyncRequestState(
  actions.UPDATE_TRAFFIC_FILTER_RULESET,
)
export const setAppSearchReadOnlyModeRequest = getAsyncRequestState(
  actions.SET_APP_SEARCH_READ_ONLY_MODE,
)

export const createDeploymentExtensionRequest = getAsyncRequestState(
  actions.CREATE_DEPLOYMENT_EXTENSION,
)
export const deleteDeploymentExtensionRequest = getAsyncRequestState(
  actions.DELETE_DEPLOYMENT_EXTENSION,
)
export const fetchDeploymentExtensionRequest = getAsyncRequestState(
  actions.FETCH_DEPLOYMENT_EXTENSION,
)
export const fetchDeploymentExtensionsRequest = getAsyncRequestState(
  actions.FETCH_DEPLOYMENT_EXTENSIONS,
)
export const updateDeploymentExtensionRequest = getAsyncRequestState(
  actions.UPDATE_DEPLOYMENT_EXTENSION,
)
export const uploadDeploymentExtensionRequest = getAsyncRequestState(
  actions.UPLOAD_DEPLOYMENT_EXTENSION,
)
export const setDeploymentMonitoringRequest = getAsyncRequestState(
  actions.SET_MONITORING_DEPLOYMENT,
)
export const stopDeploymentMonitoringRequest = getAsyncRequestState(
  actions.STOP_MONITORING_DEPLOYMENT,
)

export const fetchTrustRelationshipsRequest = getAsyncRequestState(
  actions.FETCH_TRUST_RELATIONSHIPS,
)
export const createTrustRelationshipRequest = getAsyncRequestState(
  actions.CREATE_TRUST_RELATIONSHIP,
)
export const updateTrustRelationshipRequest = getAsyncRequestState(
  actions.UPDATE_TRUST_RELATIONSHIP,
)
export const deleteTrustRelationshipRequest = getAsyncRequestState(
  actions.DELETE_TRUST_RELATIONSHIP,
)
export const fetchTrustedEnvsRequest = getAsyncRequestState(actions.FETCH_TRUSTED_ENVS)

export const fetchApiBaseUrlRequest = getAsyncRequestState(actions.FETCH_API_BASE_URL)
export const updateApiBaseUrlRequest = getAsyncRequestState(actions.UPDATE_API_BASE_URL)

export const fetchDeploymentDomainNameRequest = getAsyncRequestState(
  actions.FETCH_DEPLOYMENT_DOMAIN_NAME,
)
export const updateDeploymentDomainNameRequest = getAsyncRequestState(
  actions.UPDATE_DEPLOYMENT_DOMAIN_NAME,
)

export const enableCrossClusterReplicationRequest = getAsyncRequestState(
  actions.ENABLE_CROSS_CLUSTER_REPLICATION,
)

export const fetchCurrentAccountRequest = getAsyncRequestState(actions.FETCH_CURRENT_ACCOUNT)
export const updateCurrentAccountRequest = getAsyncRequestState(actions.UPDATE_CURRENT_ACCOUNT)

export const updateDeploymentDomainAliasRequest = getAsyncRequestState(
  actions.UPDATE_DEPLOYMENT_ALIAS,
)

export const deploymentDomainAliasEditAccessRequest = getAsyncRequestState(
  actions.DEPLOYMENT_ALIAS_EDIT_ACCESS,
)

export const fetchDeploymentDomainAliasEditAccessRequest = getAsyncRequestState(
  actions.GET_DEPLOYMENT_ALIAS_EDIT_ACCESS,
)

export const updateDeploymentDomainAliasEditAccessRequest = getAsyncRequestState(
  actions.UPDATE_DEPLOYMENT_ALIAS_EDIT_ACCESS,
)

export const fetchElasticsearchServicePricesRequest = getAsyncRequestState(actions.FETCH_ESS_PRICES)
export const fetchProxiesRequest = getAsyncRequestState(actions.FETCH_PROXIES)
export const fetchPlanActivityDetailRequest = getAsyncRequestState(actions.FETCH_ACTIVITY_DETAIL)

export const createOrganizationRequest = getAsyncRequestState(actions.CREATE_ORGANIZATION)
export const updateOrganizationRequest = getAsyncRequestState(actions.UPDATE_ORGANIZATION)
export const fetchOrganizationRequest = getAsyncRequestState(actions.FETCH_ORGANIZATION)
export const fetchOrganizationsRequest = getAsyncRequestState(actions.FETCH_ORGANIZATIONS)
export const addOrganizationMemberRequest = getAsyncRequestState(actions.ADD_ORGANIZATION_MEMBER)

export const acceptOrganizationInvitationRequest = getAsyncRequestState(
  actions.ACCEPT_ORGANIZATION_INVITATION,
)

export const upsertOrganizationInvitationsRequest = getAsyncRequestState(
  actions.UPSERT_ORGANIZATION_INVITATION,
)

export const deleteOrganizationInvitationsRequest = getAsyncRequestState(
  actions.DELETE_ORGANIZATION_INVITATIONS,
)

export const fetchOrganizationInvitationRequest = getAsyncRequestState(
  actions.FETCH_ORGANIZATION_INVITATION,
)

export const fetchOrganizationInvitationsRequest = getAsyncRequestState(
  actions.FETCH_ORGANIZATION_INVITATIONS,
)

export const deleteOrganizationMembershipsRequest = getAsyncRequestState(
  actions.DELETE_ORGANIZATION_MEMBERSHIPS,
)

export const fetchOrganizationMembershipsRequest = getAsyncRequestState(
  actions.FETCH_ORGANIZATION_MEMBERSHIPS,
)
export const updateOrganizationMemberRoleAssignmentsRequest = getAsyncRequestState(
  actions.UPDATE_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS,
)
export const fetchDeploymentsCostsRequest = getAsyncRequestState(actions.FETCH_DEPLOYMENTS_COSTS)
export const fetchAccountItemsCostsRequest = getAsyncRequestState(actions.FETCH_ACCOUNT_ITEMS_COSTS)
export const fetchDeploymentCostItemsRequest = getAsyncRequestState(
  actions.FETCH_DEPLOYMENT_COST_ITEMS,
)
export const fetchAccountCostsOverviewRequest = getAsyncRequestState(
  actions.FETCH_ACCOUNT_COSTS_OVERVIEW,
)
export const subscribeMarketplaceUserRequest = getAsyncRequestState(
  actions.SUBSCRIBE_MARKETPLACE_USER,
)
export const activateMarketplaceUserRequest = getAsyncRequestState(
  actions.ACTIVATE_MARKETPLACE_USER,
)
export const createMarketplaceUserRequest = getAsyncRequestState(actions.CREATE_MARKETPLACE_USER)

export const createPendingSaasUserRequest = getAsyncRequestState(actions.CREATE_PENDING_SAAS_USER)

export const fetchPrepaidBalanceLineItemsRequest = getAsyncRequestState(
  actions.FETCH_PREPAID_BALANCE_LINE_ITEMS,
)

export const fetchOrganizationPrepaidsRequest = getAsyncRequestState(
  actions.FETCH_ORGANIZATION_PREPAIDS,
)
export const fetchSaasOrganizationRequest = getAsyncRequestState(actions.FETCH_SAAS_ORGANIZATION)

export const fetchEolStatusRequest = getAsyncRequestState(actions.FETCH_EOL_STATUS)
export const generateResetPasswordLinkRequest = getAsyncRequestState(
  actions.GENERATE_RESET_PASSWORD_LINK,
)

export const fetchOrganizationBillingDetailsRequest = getAsyncRequestState(
  actions.FETCH_ORGANIZATION_BILLING_DETAILS,
)

export const fetchMarketplaceBillingDetailsRequest = getAsyncRequestState(
  actions.FETCH_MARKETPLACE_BILLING_DETAILS,
)

export const fetchCostsChartsRequest = getAsyncRequestState(actions.FETCH_COSTS_CHARTS)

export const fetchCostsChartsByDeploymentRequest = getAsyncRequestState(
  actions.FETCH_COSTS_CHARTS_BY_DEPLOYMENT,
)

export const migrateDeploymentTemplateRequest = getAsyncRequestState(
  actions.MIGRATE_DEPLOYMENT_TEMPLATE,
)

export const lockDeploymentRequest = getAsyncRequestState(actions.LOCK_DEPLOYMENT)

export const updateDeploymentTagsRequest = getAsyncRequestState(actions.UPDATE_DEPLOYMENT_TAGS)

export const activateLineItemsRequest = getAsyncRequestState(actions.ACTIVATE_LINE_ITEMS)

export const fetchCertificateAuthorityRequest = getAsyncRequestState(
  actions.FETCH_CERTIFICATE_AUTHORITY,
)

export const fetchAllowExtraVersionsStatusRequest = getAsyncRequestState(
  actions.FETCH_ALLOW_EXTRA_VERSIONS_STATUS,
)

export const updateAllowExtraVersionsStatusRequest = getAsyncRequestState(
  actions.UPDATE_ALLOW_EXTRA_VERSIONS_STATUS,
)
export const fetchDeploymentHealthRequest = getAsyncRequestState(actions.FETCH_DEPLOYMENT_HEALTH)
export const saveDiscoveryQuestionsRequest = getAsyncRequestState(actions.SAVE_DISCOVERY_QUESTIONS)

export const convertOrganizationToInvoicingRequest = getAsyncRequestState(
  actions.CONVERT_ORGANIZATION_TO_INVOICING,
)

export const convertResellerToDirectRequest = getAsyncRequestState(
  actions.CONVERT_RESELLER_TO_DIRECT,
)

export const creditOrganizationRequest = getAsyncRequestState(actions.CREDIT_ORGANIZATION)

export const extendSaasOrganizationTrialRequest = getAsyncRequestState(
  actions.EXTEND_SAAS_ORGANIZATION_TRIAL,
)
export const regenerateInvoicesRequest = getAsyncRequestState(actions.REGENERATE_INVOICES)
export const searchUsersRequest = getAsyncRequestState(SEARCH_USERS)

export const refreshMarketplaceTokenRequest = getAsyncRequestState(
  actions.REFRESH_MARKETPLACE_TOKEN,
)
