/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sortBy, identity } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { Runner } from '@modules/ui-types'
import { CuiLink } from '@modules/cui'
import type { CuiExpansibleTag } from '@modules/cui'

import { getNamedRole } from '../../../lib/hostRoles'

import RunnerTags from './RunnerTags'

import type { FunctionComponent } from 'react'

interface Props {
  getActiveTags?: (field: string) => any[]
  onTagClick?: (field: string, tag: CuiExpansibleTag) => void
  runner: Runner
  maxInlineTags?: number
}

const HostRoles: FunctionComponent<Props> = ({
  getActiveTags = () => [],
  onTagClick,
  runner,
  maxInlineTags,
}) => (
  <RunnerTags
    title={<FormattedMessage id='runners-table.roles' defaultMessage='Roles' />}
    field='role'
    tags={sortBy(
      runner.roles.map((role) => role.role_name),
      [getNamedRole, identity],
    )}
    isActiveTag={(field: string, tagText: string) =>
      !getNamedRole(tagText) && getActiveTags(field).includes(tagText)
    }
    onTagClick={onTagClick}
    maxInlineTags={maxInlineTags}
    renderTag={({ tagText }: { tag: CuiExpansibleTag; tagText: string }) => {
      const namedRole = getNamedRole(tagText)

      if (namedRole) {
        return (
          <CuiLink to={namedRole.getUrl(runner.region!, runner.runner_id)}>
            {namedRole.label}
          </CuiLink>
        )
      }

      return tagText
    }}
  />
)

export default HostRoles
