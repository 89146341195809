/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import { isLocked } from '@/lib/stackDeployments/selectors'
import { lockDeployment, resetLockDeploymentRequest } from '@/actions/deployments/lockDeployment'
import { lockDeploymentRequest } from '@/reducers/asyncRequests/registry'

import { isFeatureActivated } from '../../../selectors'

import DeploymentLock from './DeploymentLock'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { StateProps, ConsumerProps, DispatchProps } from './DeploymentLock'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => ({
  isLocked: isLocked({ deployment }),
  lockDeploymentRequest: lockDeploymentRequest(state, deployment.id),
  canToggleDeploymentLock: isFeatureActivated(state, Feature.toggleDeploymentLock),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deployment }: ConsumerProps,
): DispatchProps => ({
  lockDeployment: (locked) => dispatch(lockDeployment(deployment, locked)),
  resetLockDeploymentRequest: () => dispatch(resetLockDeploymentRequest(deployment.id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentLock)
