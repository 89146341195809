/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useState } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import moment from 'moment'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiDatePicker,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { CreditRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import { isPrepaidConsumptionCustomer } from '@/lib/billingDetails'
import withBillingDetails from '@/lib/withBillingDetails'

import type { Moment } from 'moment'
import type { FunctionComponent } from 'react'
import type { BillingDetailsContextProps } from '@/lib/withBillingDetails/types'

const MAXIMUM_CREDIT = 500

const messages = defineMessages({
  creditAccountModalTitle: {
    id: `organization.organization-overview.credit-account-button.modal-title`,
    defaultMessage: `Credit account`,
  },
  creditAccountModalBody: {
    id: `organization.organization-overview.credit-account-button.modal-message`,
    defaultMessage:
      'You are about to credit an account. The amount you add here will be deducted from this ' +
      'account’s next invoice. The credit will be manually reviewed before being applied to the next invoice.',
  },
  creditAccountModalConfirm: {
    id: `organization.organization-overview.credit-account-button.modal-confirm`,
    defaultMessage: `Credit account`,
  },
  creditAccountDollarAmount: {
    id: `organization.organization-overview.credit-account-button.dollar-amount-label`,
    defaultMessage: `USD amount`,
  },
  creditAccountDollarAmountHelp: {
    id: `organization.organization-overview.credit-account-button.dollar-amount-label-help`,
    defaultMessage: 'Max ${max}',
  },
  creditAccountEcuAmount: {
    id: `organization.organization-overview.credit-account-button.ecu-amount-label`,
    defaultMessage: `ECU quantity`,
  },
  creditAccountEcuAmountHelp: {
    id: `organization.organization-overview.credit-account-button.ecu-amount-label-help`,
    defaultMessage: `Max {max}`,
  },
  creditAccountReason: {
    id: `organization.organization-overview.credit-account-button.reason-label`,
    defaultMessage: `Reason for credit`,
  },
  creditAccountReasonHelp: {
    id: `organization.organization-overview.credit-account-button.reason-label-help`,
    defaultMessage: `Appears on customer invoice`,
  },
  creditAccountDescription: {
    id: `organization.organization-overview.credit-account-button.description-label`,
    defaultMessage: `Description`,
  },
  creditAccountDescriptionHelp: {
    id: `organization.organization-overview.credit-account-button.description-label-help`,
    defaultMessage: `Optional. A internal description of the credit`,
  },
  cancel: {
    id: `organization.organization-overview.credit-account-button.cancel`,
    defaultMessage: `Cancel`,
  },
  notApplicableToPrepaid: {
    id: `organization.organization-overview.credit-account.not-applicable-to-prepaid`,
    defaultMessage: `Not applicable to prepaid customers`,
  },
  onlyApplicableToPrepaid: {
    id: `organization.organization-overview.credit-account.only-applicable-to-prepaid`,
    defaultMessage: `Only applicable to prepaid customers`,
  },
  expirationDate: {
    id: `organization.organization-overview.credit-account.expiration-date`,
    defaultMessage: `Expiration date`,
  },
  expirationDateHelp: {
    id: `organization.organization-overview.credit-account.expiration-date-help`,
    defaultMessage: `Optional. Expiration date of the credit. Defaul to 1 year.`,
  },
})

interface Props {
  billingDetails: BillingDetailsContextProps
  creditAccount: (creditRequest: CreditRequest) => Promise<void>
  creditAccountRequest: AsyncRequestState
  requestedBy: string
  onClose: () => void
}

const CreditAccountFlyout: FunctionComponent<Props> = ({
  billingDetails,
  creditAccount,
  creditAccountRequest,
  onClose,
  requestedBy,
}) => {
  const [touchedAmount, setTouchedAmount] = useState(false)
  const [touchedReason, setTouchedReason] = useState(false)
  const [amount, setAmount] = useState('')
  const [reason, setReason] = useState('')
  const [description, setDescription] = useState('')
  const [expiry, setExpiry] = useState<Moment | null>(null)

  const numericAmount = Number.parseFloat(amount)
  const validAmount = !isNaN(numericAmount) && numericAmount > 0
  const validReason = reason.trim().length > 0

  const validDate =
    expiry == null ||
    (expiry.isSameOrAfter(moment()) && expiry.isSameOrBefore(moment().add(1, 'year')))

  const isFormValid = validAmount && validReason && validDate

  const onSubmit = () => {
    const creditRequest: CreditRequest = {
      quantity: numericAmount,
      description: description.trim(),
      reason: reason.trim(),
      requested_by: requestedBy,
      end: expiry?.toISOString(),
    }
    /* We only close if the REST request succeeds, which is why we render the error above and not in the */
    /* component that renders the modal */
    creditAccount(creditRequest).then(onClose)
  }

  const isPrepaidCustomer = isPrepaidConsumptionCustomer(billingDetails.data!)

  const flyoutTitleId = useGeneratedHtmlId({
    prefix: 'creditOrganizationTitle',
  })

  return (
    <EuiFlyout
      size='m'
      maxWidth={0}
      ownFocus={true}
      onClose={onClose}
      aria-labelledby={flyoutTitleId}
    >
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size='m'>
          <h2 id={flyoutTitleId}>
            <FormattedMessage {...messages.creditAccountModalTitle} />
          </h2>
        </EuiTitle>
      </EuiFlyoutHeader>

      <EuiFlyoutBody>
        <EuiText>
          <FormattedMessage {...messages.creditAccountModalBody} />
        </EuiText>

        <EuiSpacer />

        <EuiForm>
          <EuiFormRow
            label={
              <FormattedMessage
                {...(isPrepaidCustomer
                  ? messages.creditAccountEcuAmount
                  : messages.creditAccountDollarAmount)}
              />
            }
            helpText={
              <FormattedMessage
                {...(isPrepaidCustomer
                  ? messages.creditAccountEcuAmountHelp
                  : messages.creditAccountDollarAmountHelp)}
                values={{ max: MAXIMUM_CREDIT }}
              />
            }
          >
            <EuiFieldNumber
              data-test-id='credit-account-amount'
              max={500}
              isInvalid={touchedAmount && !validAmount}
              value={amount}
              onChange={(e) => {
                setTouchedAmount(true)
                setAmount(e.target.value)
              }}
              readOnly={creditAccountRequest.inProgress}
            />
          </EuiFormRow>

          <EuiFormRow
            label={<FormattedMessage {...messages.creditAccountReason} />}
            helpText={<FormattedMessage {...messages.creditAccountReasonHelp} />}
          >
            <EuiFieldText
              data-test-id='credit-account-reason'
              value={reason}
              isInvalid={touchedReason && !validReason}
              onChange={(e) => {
                setTouchedReason(true)
                setReason(e.target.value)
              }}
              readOnly={creditAccountRequest.inProgress}
            />
          </EuiFormRow>

          <EuiFormRow
            isDisabled={isPrepaidCustomer}
            label={<FormattedMessage {...messages.creditAccountDescription} />}
            helpText={
              <FormattedMessage
                {...(isPrepaidCustomer
                  ? messages.notApplicableToPrepaid
                  : messages.creditAccountDescriptionHelp)}
              />
            }
          >
            <EuiTextArea
              data-test-id='credit-account-description'
              disabled={isPrepaidCustomer}
              value={description}
              rows={3}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              readOnly={creditAccountRequest.inProgress}
            />
          </EuiFormRow>

          <EuiFormRow
            label={<FormattedMessage {...messages.expirationDate} />}
            helpText={
              <FormattedMessage
                {...(isPrepaidCustomer
                  ? messages.expirationDateHelp
                  : messages.onlyApplicableToPrepaid)}
              />
            }
            isDisabled={!isPrepaidCustomer}
          >
            <EuiDatePicker
              disabled={!isPrepaidCustomer}
              dateFormat='YYYY-MM-DD'
              data-test-id='credit-account-expiry'
              isInvalid={!validDate}
              selected={expiry}
              minDate={moment()}
              maxDate={moment().add(1, 'year')}
              onChange={setExpiry}
              onClear={() => setExpiry(null)}
            />
          </EuiFormRow>
        </EuiForm>

        {creditAccountRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert iconType='alert' type='error'>
              {creditAccountRequest.error}
            </CuiAlert>
          </Fragment>
        )}
      </EuiFlyoutBody>

      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty iconType='cross' onClick={onClose} flush='left'>
              <FormattedMessage {...messages.cancel} />
            </EuiButtonEmpty>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              data-test-id='confirm-credit-account-btn'
              disabled={!isFormValid}
              onClick={onSubmit}
              fill={true}
              isLoading={creditAccountRequest.inProgress}
            >
              <FormattedMessage {...messages.creditAccountModalConfirm} />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}

export default withBillingDetails(CreditAccountFlyout)
