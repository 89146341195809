/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import BillingDetailsProvider from '.'

import type { ComponentType } from 'react'
import type { ConsumerProps } from './types'

function withBillingDetailsProvider<WrappedComponentProps extends ConsumerProps>(
  WrappedComponent: ComponentType<WrappedComponentProps>,
) {
  return (props: WrappedComponentProps) => {
    const { organizationId } = props

    // TODO: we should check for the billing-organization:get permission before making the request.
    // We disabled this check temporarily to avoid a bug in which the page would show a endless spinner,
    // it looks like the permissions changes in the redux store weren't propagated correctly.
    return (
      <BillingDetailsProvider organizationId={organizationId}>
        <WrappedComponent {...props} />
      </BillingDetailsProvider>
    )
  }
}

export default withBillingDetailsProvider
