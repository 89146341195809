/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { sumBy } from 'lodash'

import type { AccessorFn } from '@elastic/charts'

import type { ChartItem } from '@modules/cloud-api/v1/types'

import { ColorKeyForOtherDeployments, VisibleDeployments } from '../../constants'

type Map = Record<string, number>

export const getListOfDeploymentsByUsage = (chartData: ChartItem[]): string[] => {
  const usageByDeployment: Map = {}

  chartData.forEach(({ values }) => {
    values.forEach(({ id, value, name }) => {
      const key = `${id}_${name}`

      usageByDeployment[key] = (usageByDeployment[key] || 0) + value
    })
  })

  // Sort prepared map based on usage and return it as a list of deploymentIds
  const deploymentsListByUsage = Object.entries(usageByDeployment)
    .sort((a, b) => b[1] - a[1])
    .map((item) => item[0])

  return deploymentsListByUsage
}

export const prepareDataAccessors = (deploymentsRanking: string[]) => {
  const accessors: AccessorFn[] = []
  const topDeployments = deploymentsRanking.slice(0, VisibleDeployments)

  topDeployments.map((deployment) => {
    const accessor: AccessorFn = ({ values }) => {
      const item = values.find(({ id, name }) => `${id}_${name}` === deployment)
      accessor.fieldName = deployment // fieldName must be unique thats we we cannot rely only on name

      return item?.value || 0
    }

    accessors.push(accessor)
  })

  // we need to add one more accessor `Other` which is a sum of the rest
  if (deploymentsRanking.length > VisibleDeployments) {
    const otherDeployments = deploymentsRanking.slice(VisibleDeployments)

    const accessor: AccessorFn = ({ values }) => {
      const items = values.filter(({ id, name }) => otherDeployments.includes(`${id}_${name}`))
      accessor.fieldName = ColorKeyForOtherDeployments

      return sumBy(items, 'value') || 0
    }

    accessors.push(accessor)
  }

  return accessors
}

export const getNameFromYAccessor = (yAccessor) => yAccessor.split('_')[1]
