/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type {
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
} from '@modules/cloud-api/v1/types'
import type { AnyTopologyElement } from '@modules/ui-types'

import Summary from '@/components/Topology/DeploymentTemplates/components/DeploymentInfrastructure/TopologyElement/Summary'
import { isDedicatedML, isFrozen } from '@/lib/stackDeployments/selectors'

import type { FunctionComponent } from 'react'

import '../deploymentArchitecture.scss'

type Props = {
  instanceConfiguration: InstanceConfiguration
  resource: `memory` | `storage`
  nodeConfiguration: AnyTopologyElement
  size: number
  zoneCount: number
  tiebreaker?: boolean
}

const DeploymentArchitectureSummaryText: FunctionComponent<Props> = ({
  instanceConfiguration,
  resource,
  nodeConfiguration,
  size,
  zoneCount,
  tiebreaker,
}) => {
  const { cpu_multiplier, storage_multiplier } = instanceConfiguration
  const {
    discrete_sizes: { sizes },
  } = instanceConfiguration

  return (
    <Summary
      tiebreaker={tiebreaker}
      topologyElement={nodeConfiguration}
      sliderInstanceType={instanceConfiguration.instance_type}
      instanceConfiguration={instanceConfiguration}
      isMachineLearning={isDedicatedML({ topologyElement: nodeConfiguration })}
      isFrozen={isFrozen({ topologyElement: nodeConfiguration })}
      autoscalingMin={
        // `nodeConfiguration` is actually an `AnyTopologyElement`, but only the ES flavour
        // has the `autoscaling_min` field.
        (nodeConfiguration as ElasticsearchClusterTopologyElement)?.autoscaling_min?.value
      }
      zoneCount={zoneCount}
      size={size}
      resource={resource}
      storageMultiplier={storage_multiplier}
      cpuMultiplier={cpu_multiplier}
      maxSize={Math.max(...sizes)}
      textSize='xs'
    />
  )
}

export default DeploymentArchitectureSummaryText
