/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormLabel, EuiRadioGroup, EuiSpacer } from '@elastic/eui'

import Header from '@modules/cui/Header'

import SearchByUserEmail from '@/components/Users/SearchByUserEmail'
import SearchByDeploymentId from '@/components/Users/SearchByDeploymentId'
import { saasUsersCrumbs } from '@/lib/crumbBuilder'

import SearchByUserId from './SearchByUserId'

import type { UserSearchType } from '@/actions/saasUsers'
import type { FunctionComponent } from 'react'

export interface Props {
  lookupSaasUsers: boolean
  setUserSearchType: (userSearchType: UserSearchType) => void
  userSearchType: UserSearchType
  userSearchTerm: string
  setUserSearchTerm: (userSearchTerm: string) => void
}

const Users: FunctionComponent<Props> = ({
  lookupSaasUsers,
  setUserSearchType,
  userSearchType,
}) => {
  if (!lookupSaasUsers) {
    return null
  }

  const searchTypeOptions = [
    {
      id: `id`,
      label: <FormattedMessage id='users.search-by-id-label' defaultMessage='ID' />,
    },
    {
      id: `email`,
      label: <FormattedMessage id='users.search-by-email-label' defaultMessage='Email' />,
    },
    {
      id: `deployment_id`,
      label: (
        <FormattedMessage id='users.search-by-deployment-id-label' defaultMessage='Deployment ID' />
      ),
    },
  ]

  return (
    <Fragment>
      <Header
        name={<FormattedMessage id='users.title' defaultMessage='Users' />}
        breadcrumbs={saasUsersCrumbs()}
      />

      <EuiSpacer size='m' />

      <EuiFormLabel>
        <FormattedMessage id='users.find-label' defaultMessage='Find a user by:' />
      </EuiFormLabel>

      <EuiRadioGroup
        options={searchTypeOptions}
        idSelected={userSearchType}
        onChange={(selectedType: UserSearchType) => setUserSearchType(selectedType)}
      />

      <EuiSpacer size='m' />

      {userSearchType === `id` && <SearchByUserId />}

      {userSearchType === `email` && <SearchByUserEmail />}

      {userSearchType === `deployment_id` && <SearchByDeploymentId />}
    </Fragment>
  )
}

export default Users
