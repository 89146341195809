/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import ProjectPage from './ProjectPage'
import ProjectsPage from './ProjectsPage'
import ProjectPerformancePage from './ProjectPerformancePage'
import ProjectSecurityPage from './ProjectSecurityPage'

import type { RouteConfig } from 'react-router-config'

export default function getProjectRoutes(): RouteConfig[] {
  const routes: RouteConfig[] = [
    {
      path: '/projects',
      component: ProjectsPage,
      title: 'Projects',
    },
    {
      path: '/projects/:projectType/:projectId',
      component: ProjectPage,
      title: 'Project',
    },
    {
      path: '/projects/:projectType/:projectId/performance',
      component: ProjectPerformancePage,
      title: 'Performance',
    },
    {
      path: '/projects/:projectType/:projectId/security',
      component: ProjectSecurityPage,
      title: 'Security',
    },
  ]

  return routes
}
