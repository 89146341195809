/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { cloneDeep, merge, size } from 'lodash'

import {
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import type {
  DeploymentTemplateRequestBody,
  ElasticsearchClusterTopologyElement,
} from '@modules/cloud-api/v1/types'
import type { DeepPartial } from '@modules/ts-essentials'

import { getFirstEsCluster } from '@/lib/stackDeployments/selectors'

import type { WrappedComponentProps } from 'react-intl'

import './indexLifecycleManagementSettings.scss'

interface Props extends WrappedComponentProps {
  instance: ElasticsearchClusterTopologyElement
  template: DeploymentTemplateRequestBody
  updateDeploymentTemplate: (deploymentTemplate: DeploymentTemplateRequestBody) => void
  addPillColor: (option: Option) => void
  pillColors: any
}

type Option = {
  label: string
  color?: string
}

type State = {
  isInvalid: boolean
}

export const pillColorDefinitions = [
  '#3185FC', //euiColorVis1
  '#00B3A4', // euiColorVis0
  '#E6C220', // euiColorVis5
  'danger',
  '#F98510', //euiColorVis7
  '#BFA180', //euiColorVis6
  '#490092', //euiColorVis3
  '#DD0A73', //euiColorAccent
  '#FEB6DB', //euiColorVis4
  '#017D73', //euiColorSecondary
  '#920000', //euiColorVis9
  'default',
  'hollow',
]

class NodeAttributeForm extends Component<Props, State> {
  state = {
    isInvalid: false,
  }

  render() {
    const { instance } = this.props
    const { isInvalid } = this.state

    const selectedOptions = this.getSelectedOptions()

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='xs'>
          <EuiFlexItem grow={2} />
          <EuiFlexItem grow={6}>
            <EuiFormLabel className='ilmSettings-desktopOnlyLabel'>
              <FormattedMessage id='node-attr.title' defaultMessage='Node attributes' />
            </EuiFormLabel>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup gutterSize='xs' className='ilmSettings-nodeAttributes'>
          <EuiFlexItem grow={2}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiTitle size='xxs' className='ilmSettings-instanceName'>
                  <h5>
                    <EuiIcon type={`logoElasticsearch`} size='m' className='ilmSettings-esIcon' />
                    {instance.id}
                  </h5>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false} className='ilmSettings-arrowContainer'>
                <EuiIcon type={`sortRight`} size='s' className='ilmSettings-arrowIcon' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem grow={6}>
            <EuiFormRow
              helpText={
                <FormattedMessage
                  id='node-attr.help-text'
                  defaultMessage='E.g.: data:hot, data:warm'
                />
              }
              isInvalid={isInvalid}
              error={isInvalid ? 'Attribute should follow the format aaaa:bbbb' : undefined}
            >
              <Fragment>
                <EuiFormLabel
                  htmlFor='node-attibute-form-combo'
                  className='ilmSettings-mobileOnlyLabel'
                >
                  <FormattedMessage id='node-attr.title' defaultMessage='Node attributes' />
                </EuiFormLabel>
                <EuiComboBox
                  id='node-attibute-form-combo'
                  noSuggestions={true}
                  selectedOptions={selectedOptions}
                  onChange={this.onChange}
                  onSearchChange={this.onSearchChange}
                  onCreateOption={this.onCreateOption}
                  isInvalid={isInvalid}
                  data-test-id='node-attribute-form'
                />
              </Fragment>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
      </Fragment>
    )
  }

  getSelectedOptions = (): Option[] => {
    const { instance, pillColors } = this.props

    const nodeAttributes = instance.elasticsearch?.node_attributes

    if (!nodeAttributes || size(nodeAttributes) < 1) {
      return []
    }

    const selectedItems = Object.keys(nodeAttributes).map((key) => {
      const option = {
        label: `${key}:${nodeAttributes[key]}`,
        color: pillColors[`${key}:${nodeAttributes[key]}`],
      }

      return option
    })

    return selectedItems
  }

  setPillColor = (option) => {
    const { pillColors } = this.props

    if (pillColors[option.label]) {
      return pillColors[option.label]
    }

    const color = pillColorDefinitions[size(pillColors) % pillColorDefinitions.length]

    return color
  }

  changeAttributes = (selectedOptions: Option[]) => {
    const index = getFirstEsCluster({
      deployment: this.props.template.deployment_template,
    })?.plan.cluster_topology.indexOf(this.props.instance)

    if (index === undefined) {
      return // sanity
    }

    const template = cloneDeep(this.props.template)
    const deploymentTemplate = template.deployment_template

    const esResource = getFirstEsCluster({ deployment: deploymentTemplate })

    if (!esResource) {
      return // sanity
    }

    const nodeAttributes: Record<string, string> = selectedOptions.reduce(
      (result, option: Option) => {
        const [nodeAttrKey, nodeAttrValue] = option.label.split(':')

        result[nodeAttrKey] = nodeAttrValue

        return result
      },
      {},
    )

    merge<ElasticsearchClusterTopologyElement, DeepPartial<ElasticsearchClusterTopologyElement>>(
      esResource.plan.cluster_topology[index],
      {
        elasticsearch: {
          node_attributes: nodeAttributes,
        },
      },
    )

    this.props.updateDeploymentTemplate(template)
  }

  onChange = (selectedOptions) => {
    this.changeAttributes(selectedOptions)
    this.setState({
      isInvalid: false,
    })
  }

  onSearchChange = (searchValue) => {
    if (!searchValue) {
      this.setState({
        isInvalid: false,
      })

      return
    }

    if (isValid(searchValue)) {
      this.setState({
        isInvalid: false,
      })
    }
  }

  onCreateOption = (searchValue) => {
    const { addPillColor } = this.props
    const selectedOptions = this.getSelectedOptions()

    if (!isValid(searchValue)) {
      this.setState({ isInvalid: true })
      return false
    }

    const color = this.setPillColor({ label: searchValue })

    const newOption: Option = {
      label: searchValue,
      color,
    }

    addPillColor(newOption)

    const newSelectedOptions = selectedOptions.concat(newOption)

    this.changeAttributes(newSelectedOptions)

    return
  }
}

function isValid(value) {
  const regExp = /^[a-zA-Z0-9-_]+:[a-zA-Z0-9-_]+$/
  const validCharacters = regExp.test(value)

  return validCharacters
}

export default injectIntl(NodeAttributeForm)
