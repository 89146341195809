/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiFormLabel,
  EuiFormRow,
  EuiSpacer,
  EuiTextColor,
  EuiTitle,
} from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, CuiButton, withErrorBoundary } from '@modules/cui'

type Props = {
  deploymentDomainName: string | null
  fetchDeploymentDomainName: () => void
  fetchDeploymentDomainNameRequest: AsyncRequestState
  regionId: string
  updateDeploymentDomainName: (params: { deploymentDomainName: string }) => Promise<any>
  updateDeploymentDomainNameRequest: AsyncRequestState
}

type State = {
  editing: boolean
  loading: boolean
  deploymentDomainName: string
}

class EditDeploymentDomainName extends Component<Props, State> {
  state: State = {
    editing: false,
    loading: true,
    deploymentDomainName: ``,
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    const { fetchDeploymentDomainNameRequest, deploymentDomainName } = nextProps

    if (!prevState.loading) {
      return null
    }

    if (fetchDeploymentDomainNameRequest.inProgress) {
      return null
    }

    if (fetchDeploymentDomainNameRequest.error) {
      return null
    }

    if (!fetchDeploymentDomainNameRequest.isDone) {
      return null
    }

    if (typeof deploymentDomainName !== `string`) {
      throw new Error(`Expected "deploymentDomainName" to be a string.`)
    }

    return { loading: false, deploymentDomainName }
  }

  componentDidMount() {
    const { fetchDeploymentDomainName } = this.props
    fetchDeploymentDomainName()
  }

  render() {
    const { updateDeploymentDomainNameRequest } = this.props
    const { editing, loading, deploymentDomainName } = this.state
    const validationError = this.getDomainNameValidationError()

    return (
      <EuiDescribedFormGroup
        title={
          <EuiTitle size='xs'>
            <h3>
              <FormattedMessage
                id='edit-deployment-domain-name.deployment-domain-name'
                defaultMessage='Deployment endpoints'
              />
            </h3>
          </EuiTitle>
        }
        description={
          <FormattedMessage
            id='edit-deployment-domain-name.deployment-domain-name-help-text'
            defaultMessage='Specify a domain name that replaces the default hostname. The domain name becomes part of the endpoint URL for the deployment.'
          />
        }
        fullWidth={true}
      >
        <EuiFormRow fullWidth={true}>
          <div>
            <EuiFormLabel htmlFor='deploymentDomainName'>
              <FormattedMessage
                id='edit-deployment-domain-name.deployment-domain-cname'
                defaultMessage='CNAME'
              />
            </EuiFormLabel>

            <EuiSpacer size='xs' />

            <EuiFlexGroup gutterSize='m' alignItems='flexStart'>
              <EuiFlexItem>
                <EuiFieldText
                  id='deploymentDomainName'
                  disabled={loading}
                  readOnly={!editing}
                  value={deploymentDomainName}
                  onChange={(e) => this.setState({ deploymentDomainName: e.target.value })}
                  fullWidth={true}
                />
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <div>{this.renderButtons()}</div>
              </EuiFlexItem>
            </EuiFlexGroup>

            {validationError && (
              <Fragment>
                <EuiSpacer size='xs' />

                <EuiFormHelpText>
                  <EuiTextColor color='danger'>{validationError}</EuiTextColor>
                </EuiFormHelpText>
              </Fragment>
            )}

            {updateDeploymentDomainNameRequest.error && (
              <Fragment>
                <EuiSpacer size='m' />

                <CuiAlert type='error'>{updateDeploymentDomainNameRequest.error}</CuiAlert>
              </Fragment>
            )}
          </div>
        </EuiFormRow>
      </EuiDescribedFormGroup>
    )
  }

  renderButtons() {
    const { loading, editing } = this.state

    if (!editing) {
      return (
        <EuiFlexGroup gutterSize='s'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty disabled={loading} onClick={() => this.setState({ editing: true })}>
              <FormattedMessage id='edit-deployment-domain-name.edit' defaultMessage='Edit' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    return (
      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem grow={false}>
          <div>{this.renderSaveButton()}</div>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={() => this.resetState()}>
            <FormattedMessage id='edit-deployment-domain-name.cancel' defaultMessage='Cancel' />
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderSaveButton() {
    const { updateDeploymentDomainName, updateDeploymentDomainNameRequest } = this.props
    const { deploymentDomainName, loading } = this.state
    const validationError = this.getDomainNameValidationError()

    return (
      <CuiButton
        color='primary'
        disabled={loading || Boolean(validationError)}
        spin={updateDeploymentDomainNameRequest.inProgress}
        onClick={() =>
          updateDeploymentDomainName({ deploymentDomainName })
            .then(() => this.setState({ editing: false }))
            .catch(() => this.resetState())
        }
        confirm={true}
        confirmModalProps={{
          title: (
            <FormattedMessage
              id='edit-deployment-domain-name.confirm-title'
              defaultMessage='Change deployments domain name?'
            />
          ),
          confirm: (
            <FormattedMessage
              id='edit-deployment-domain-name.confirm-button'
              defaultMessage='Save'
            />
          ),
        }}
      >
        <FormattedMessage id='edit-deployment-domain-name.change-api-url' defaultMessage='Update' />
      </CuiButton>
    )
  }

  resetState = () => {
    const { deploymentDomainName } = this.props

    this.setState({
      editing: false,
      deploymentDomainName: deploymentDomainName!,
    })
  }

  getDomainNameValidationError = () => {
    const { editing, deploymentDomainName } = this.state

    if (!editing) {
      return null
    }

    if (isEmpty(deploymentDomainName)) {
      return (
        <FormattedMessage
          id='edit-deployment-domain-name.invalid-empty'
          defaultMessage='The domain name cannot be empty.'
        />
      )
    }

    return null
  }
}

export default withErrorBoundary(EditDeploymentDomainName)
