/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { SearchRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, Runner } from '@modules/ui-types'

import { searchRunners } from '../../apps/adminconsole/actions/runnerSearch'
import { searchRunnersRequest } from '../../reducers'
import { getRunnerSearchResults } from '../../apps/adminconsole/reducers'
import withPolling from '../../lib/withPolling'

import Runners from './Runners'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { ComponentType } from 'react'

interface StateProps {
  runnersSearchResults: Runner[] | null
  runnersSearchRequest: AsyncRequestState
}

interface DispatchProps {
  fetchRunners: () => Promise<any>
}

interface ConsumerProps {
  regionId: string
}

const query: SearchRequest = {
  size: 5000,
}

const mapStateToProps = (state: ReduxState, { regionId }: ConsumerProps): StateProps => ({
  runnersSearchResults: getRunnerSearchResults(state, regionId, 'runners'),
  runnersSearchRequest: searchRunnersRequest(state, regionId, 'runners'),
})

const mapDispatchToProps = (dispatch: ThunkDispatch, { regionId }: ConsumerProps) => ({
  fetchRunners: () => dispatch(searchRunners('runners', regionId, query)),
})

export default wrapWithRunnersContainer(Runners)

export function wrapWithRunnersContainer(WrappedComponent: ComponentType<ConsumerProps>) {
  const pollingComponent = withPolling(WrappedComponent, ({ fetchRunners }) => ({
    onPoll: () => fetchRunners(),
    pollImmediately: [[`regionId`]],
  }))

  const connectedComponent = connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(pollingComponent)

  return connectedComponent
}
