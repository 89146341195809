/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AvailableAuthenticationMethods } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { redirectAfterLogin, fetchAuthMethods } from '../../../../actions/auth'
import { fetchAuthMethodsRequest, getAuthMethods } from '../../../../reducers'
import { isFeatureActivated } from '../../../../selectors'
import { getBearerToken } from '../../../../lib/auth'

import LoginIndex from './LoginIndex'

import type { ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  location: RouteComponentProps['location']
  authMethods: AvailableAuthenticationMethods | null
  fetchAuthMethodsRequest: AsyncRequestState
  newBearerToken: string | null
}

type DispatchProps = {
  redirectAfterLogin: (redirectTo: string) => () => void
  fetchAuthMethods: () => void
}

type QueryStringParameters = {
  redirectTo?: string
  fromURI?: string
}

type ConsumerProps = RouteComponentProps<QueryStringParameters>

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const { hash } = location
  const basicAuthProxyPassEnabled = isFeatureActivated(state, Feature.basicAuthProxyPass)
  const newBearerToken = basicAuthProxyPassEnabled ? getBearerToken(hash) : null

  return {
    location,
    authMethods: getAuthMethods(state),
    fetchAuthMethodsRequest: fetchAuthMethodsRequest(state),
    newBearerToken,
  }
}

const mapDispatchToProps: DispatchProps = {
  redirectAfterLogin,
  fetchAuthMethods,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(LoginIndex)
