/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'
import { Form, Formik } from 'formik'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiPortal,
  EuiSpacer,
  EuiText,
  EuiTitle,
  htmlIdGenerator,
} from '@elastic/eui'

import { CuiFieldRadio, CuiFormField } from '@modules/cui/forms'

import { roleMappingMessages } from '../../authProviderMessages'
import UserRoleComboBox from '../../../Users/UserRoleCombobox'
import { validateRoleMappingRule } from '../../validateRoleMappingRule'

import type { RoleMappingRule } from '../ActiveDirectoryProviderForm'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  isNew: boolean
  rule: RoleMappingRule
  onSave: (rule: RoleMappingRule) => void
  onClose: () => void
}

const idPrefix = htmlIdGenerator('editRoleMappingFlyout')()

const EditRoleMappingFlyout: FunctionComponent<Props> = (props) => {
  const {
    intl: { formatMessage },
    isNew,
    rule,
    onClose,
    onSave,
  } = props

  // The form is rendered in a portal so that we don't have nested forms.

  return (
    <EuiPortal>
      <Formik<RoleMappingRule>
        initialValues={{ ...rule }}
        validate={validateRoleMappingRule(formatMessage)}
        onSubmit={onSave}
      >
        {({ submitForm }) => (
          <Form>
            <EuiFlyout
              ownFocus={true}
              onClose={onClose}
              size='s'
              aria-labelledby='editRoleMappingFlyoutTitle'
            >
              <EuiFlyoutHeader hasBorder={true}>
                <EuiTitle size='s'>
                  <h2 id='editRoleMappingFlyoutTitle'>
                    {formatMessage(
                      isNew
                        ? roleMappingMessages.addRoleMapping
                        : roleMappingMessages.editRoleMapping,
                    )}
                  </h2>
                </EuiTitle>
              </EuiFlyoutHeader>

              <EuiFlyoutBody>
                <EuiText>
                  {formatMessage(roleMappingMessages.editActiveDirectoryRoleMappingDescription)}
                </EuiText>

                <EuiSpacer />

                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <CuiFieldRadio
                      id={`${idPrefix}_search_mode_user`}
                      name='type'
                      value='user_dn'
                      data-test-id='search_mode_user'
                      className='euiRadio-asLabel'
                      label={formatMessage(roleMappingMessages.userDnField)}
                    />
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <CuiFieldRadio
                      id={`${idPrefix}_search_mode_group`}
                      name='type'
                      value='group_dn'
                      data-test-id='search_mode_group'
                      className='euiRadio-asLabel'
                      label={formatMessage(roleMappingMessages.groupDnField)}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>

                <EuiSpacer size='xs' />

                <CuiFormField
                  aria-label={formatMessage(roleMappingMessages.dnString)}
                  name='value'
                  data-test-id='role_mapping_rule_value'
                />

                <UserRoleComboBox
                  useFormik={true}
                  name='roles'
                  label={formatMessage(roleMappingMessages.roleLabel)}
                  data-test-id='role_mapping_rule_roles'
                />
              </EuiFlyoutBody>

              <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent='spaceBetween'>
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty iconType='cross' onClick={onClose} flush='left'>
                      {formatMessage(roleMappingMessages.cancelEditRule)}
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton
                      data-test-id={`${isNew ? 'add' : 'edit'}-role-mapping-button`}
                      type='button'
                      onClick={submitForm}
                      fill={true}
                    >
                      {formatMessage(
                        isNew
                          ? roleMappingMessages.addRoleMapping
                          : roleMappingMessages.saveRoleMapping,
                      )}
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlyoutFooter>
            </EuiFlyout>
          </Form>
        )}
      </Formik>
    </EuiPortal>
  )
}

export default injectIntl(EditRoleMappingFlyout)
