/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState } from '@modules/ui-types'

import { fetchApiBaseUrl, updateApiBaseUrl } from '../../../actions/apiBaseUrl'
import { fetchApiBaseUrlRequest, updateApiBaseUrlRequest, getApiBaseUrl } from '../../../reducers'

import EditApiBaseUrl from './EditApiBaseUrl'

interface StateProps {
  apiBaseUrl?: string | null
  fetchApiBaseUrlRequest: AsyncRequestState
  updateApiBaseUrlRequest: AsyncRequestState
}

interface DispatchProps {
  fetchApiBaseUrl: () => void
  updateApiBaseUrl: (payload: { apiBaseUrl: string }) => Promise<any>
}

type ConsumerProps = unknown

const mapStateToProps = (state): StateProps => ({
  apiBaseUrl: getApiBaseUrl(state),
  fetchApiBaseUrlRequest: fetchApiBaseUrlRequest(state),
  updateApiBaseUrlRequest: updateApiBaseUrlRequest(state),
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchApiBaseUrl: () => dispatch(fetchApiBaseUrl()),
  updateApiBaseUrl: (payload: { apiBaseUrl: string }) => dispatch(updateApiBaseUrl(payload)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EditApiBaseUrl)
