/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { DeploymentsSearchResponse, SearchRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { searchDeployments } from '../../actions/stackDeployments'
import {
  getDeletedStackDeploymentIds,
  getStackDeploymentsFromSearch,
  searchStackDeploymentsRequest,
} from '../../reducers'

import StackActivityFeed from './StackActivityFeed'

import type { ReduxState } from '@/types/redux'

type StateProps = {
  deletedDeploymentIds: string[]
  searchResults: DeploymentsSearchResponse | null
  searchResultsRequest: AsyncRequestState
}

type DispatchProps = {
  searchDeployments: (query: SearchRequest) => void
}

interface ConsumerProps {}

const queryId = `activity-feed-filter-context`

const mapStateToProps = (state: ReduxState): StateProps => ({
  deletedDeploymentIds: getDeletedStackDeploymentIds(state),
  searchResults: getStackDeploymentsFromSearch(state, queryId),
  searchResultsRequest: searchStackDeploymentsRequest(state, queryId),
})

const mapDispatchToProps: DispatchProps = {
  searchDeployments: (query) => searchDeployments({ queryId, query }),
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(StackActivityFeed)
