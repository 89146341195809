/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiTitle, EuiText, EuiSpacer, EuiHealth } from '@elastic/eui'

import type { Runner } from '@modules/ui-types'
import { CuiAlert, CuiTable, CuiTableLoadingCellContent, withErrorBoundary } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import { hasControlPlaneRole, getControlPlaneRoles, getNamedRole } from '../../../lib/hostRoles'
import ConstructorTableRowActions from '../../ControlPlanes/ConstructorTableRowActions'

import type { Constructor, Constructors } from '@/types/redux'
import type { NamedRole } from '../../../lib/hostRoles'
import type { FunctionComponent } from 'react'

type Props = {
  regionId: string
  hostId: string
  runner?: Runner
  constructors?: Constructors
  startManagingRoles: () => void
}

type RoleHealthProps = {
  loading: boolean
  runner: Runner
  ctor?: Constructor
  role: NamedRole
}

class ControlPlane extends Component<Props> {
  render() {
    return (
      <Fragment>
        <EuiTitle>
          <h3>
            <FormattedMessage id='control-plane.title' defaultMessage='Control plane' />
          </h3>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText color='subdued'>
          <FormattedMessage
            id='control-plane.description'
            defaultMessage='Manages your installation and enables access to the Cloud UI and the Cloud REST API.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        {this.renderAlerts()}

        {this.renderControlPlaneDetails()}
      </Fragment>
    )
  }

  renderControlPlaneDetails() {
    const { runner, constructors } = this.props
    const { runner_id, roles, regionId } = runner!

    const constructorList = constructors?.constructors

    const ctor = constructorList?.find((coo) => coo.regionId === regionId && coo.id === runner_id)

    const mappedRoles = roles.map((role) => ({ id: role.role_name }))
    const controlPlaneRoles = getControlPlaneRoles({ roles: mappedRoles }).map(
      (role) => getNamedRole(role.id)!,
    )

    const columns: Array<CuiTableColumn<NamedRole>> = [
      {
        label: <FormattedMessage id='control-plane.role-column' defaultMessage='Role' />,
        render: (role) => role.label,
      },
      {
        label: (
          <FormattedMessage id='control-plane.role-status-column' defaultMessage='Role status' />
        ),
        render: (role) => (
          <RoleHealth runner={runner!} ctor={ctor} role={role} loading={!constructorList} />
        ),
        textOnly: false,
      },
      {
        mobile: {
          label: <FormattedMessage id='control-plane.actions-column' defaultMessage='Actions' />,
        },
        render: (role) => (
          <Fragment>
            {ctor && isConstructorRole({ ctor, role }) && (
              <ConstructorTableRowActions ctor={ctor} />
            )}
          </Fragment>
        ),
      },
    ]

    if (!controlPlaneRoles.length) {
      return null
    }

    return (
      <Fragment>
        <EuiTitle size='s'>
          <h3>
            <FormattedMessage
              id='control-plane.assigned-roles-title'
              defaultMessage='Assigned roles'
            />
          </h3>
        </EuiTitle>

        <EuiSpacer size='l' />

        <CuiTable<NamedRole>
          columns={columns}
          rows={controlPlaneRoles}
          getRowId={(row) => row.id}
        />
      </Fragment>
    )
  }

  renderAlerts() {
    return <Fragment>{this.renderMissingRoleWarning()}</Fragment>
  }

  renderMissingRoleWarning() {
    const { runner, startManagingRoles } = this.props

    if (hasControlPlaneRole({ runner })) {
      return null
    }

    return (
      <Fragment>
        <CuiAlert type='warning' iconType='cloudStormy'>
          <FormattedMessage
            id='control-plane.host-not-a-proxy'
            defaultMessage="This host doesn't have any Control plane roles. You can add them in {manageRoles}."
            values={{
              manageRoles: (
                <EuiLink onClick={startManagingRoles}>
                  <FormattedMessage id='control-plane.manage-roles' defaultMessage='Manage roles' />
                </EuiLink>
              ),
            }}
          />
        </CuiAlert>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }
}

const RoleHealth: FunctionComponent<RoleHealthProps> = ({ runner, ctor, role, loading }) => {
  if (loading) {
    return <CuiTableLoadingCellContent />
  }

  if (ctor && isConstructorRole({ ctor, role })) {
    if (!ctor.connected) {
      return (
        <EuiHealth color='warning'>
          <FormattedMessage id='control-plane.ctor-disconnected' defaultMessage='Disconnected' />
        </EuiHealth>
      )
    }

    if (ctor.maintenance) {
      return (
        <EuiHealth color='warning'>
          <FormattedMessage id='control-plane.ctor-maintenance' defaultMessage='Maintenance' />
        </EuiHealth>
      )
    }
  }

  const { healthy, connected } = runner

  if (!connected) {
    return (
      <EuiHealth color='warning'>
        <FormattedMessage id='control-plane.role-disconnected' defaultMessage='Disconnected' />
      </EuiHealth>
    )
  }

  if (!healthy) {
    return (
      <EuiHealth color='danger'>
        <FormattedMessage id='control-plane.role-unhealthy' defaultMessage='Unhealthy' />
      </EuiHealth>
    )
  }

  return (
    <EuiHealth color='success'>
      <FormattedMessage id='control-plane.role-healthy' defaultMessage='Healthy' />
    </EuiHealth>
  )
}

export default withErrorBoundary(ControlPlane)

function isConstructorRole({ ctor, role }: { ctor: Constructor; role: NamedRole }): boolean {
  return ctor && (role.id === 'constructor' || role.id === 'coordinator')
}
