/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'

import {
  fetchTrustRelationships,
  resetFetchTrustRelationships,
} from '../../../../actions/trustManagement'
import {
  fetchTrustRelationshipsRequest,
  getCurrentAccount,
  getTrustRelationships,
} from '../../../../reducers'
import { fetchCurrentAccount, resetFetchCurrentAccount } from '../../../../actions/account'

import DeploymentTrustManagement from './DeploymentTrustManagement'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => {
  const regionId = getRegionId({ deployment })

  return {
    currentAccount: getCurrentAccount(state),
    trustRelationships: getTrustRelationships(state, regionId),
    fetchTrustRelationshipsRequest: fetchTrustRelationshipsRequest(state, regionId),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deployment }: ConsumerProps,
): DispatchProps => {
  const regionId = getRegionId({ deployment })

  return {
    fetchCurrentAccount: () => dispatch(fetchCurrentAccount()),
    resetFetchCurrentAccount: () => dispatch(resetFetchCurrentAccount()),
    fetchTrustRelationships: () =>
      dispatch(
        fetchTrustRelationships({
          regionId,
        }),
      ),
    resetFetchTrustRelationships: () => dispatch(resetFetchTrustRelationships(regionId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentTrustManagement)
