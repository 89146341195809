/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState } from '@modules/ui-types'

import { requestUserDeletion, resetRequestUserDeletionRequest } from '@/actions/saasUsers'
import { requestUserDeletionRequest } from '@/apps/adminconsole/reducers'

import RequestDeletionButton from './RequestDeletionButton'

import type { ReduxState } from '@/types/redux'

interface StateProps {
  requestDeletionRequest: AsyncRequestState
}

interface DispatchProps {
  requestDeletion: () => void
  resetRequestDeletion: () => void
}

interface ConsumerProps {
  userId: string
  email: string
}

const mapStateToProps = (state: ReduxState, { userId }: ConsumerProps): StateProps => ({
  requestDeletionRequest: requestUserDeletionRequest(state, userId),
})

const mapDispatchToProps = (dispatch, { userId }: ConsumerProps): DispatchProps => ({
  requestDeletion: () => dispatch(requestUserDeletion(userId)),
  resetRequestDeletion: () => dispatch(resetRequestUserDeletionRequest(userId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RequestDeletionButton)
