/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { jsx, css } from '@emotion/react'
import moment from 'moment'

import { EuiDescriptionList, EuiIconTip, EuiSkeletonText } from '@elastic/eui'
import type { EuiDescriptionListProps } from '@elastic/eui'

import type {
  AzureMarketplaceSubscription,
  SaasOrganizationResponse,
} from '@modules/cloud-api/v1/types'
import type { UserProfile } from '@modules/ui-types'
import { CuiDate } from '@modules/cui'

import PageSection from '../../PageSection'

import type { FunctionComponent } from 'react'

type EuiDescriptionListItems = Required<EuiDescriptionListProps>['listItems']

interface CommonMarketplaceInfo<Domain extends UserProfile['domain'] | undefined = undefined> {
  domain?: Domain
}

interface AzureMarketplaceInfo extends CommonMarketplaceInfo<'azure'> {
  azure_subscribed: boolean
  azure_plan_id?: string
  azure_subscription_id?: string
  azure_unsubscribed_at?: string
  status: AzureMarketplaceSubscription['status']
  name: AzureMarketplaceSubscription['name']
  offer_id: AzureMarketplaceSubscription['offer_id']
  beneficiary_email: AzureMarketplaceSubscription['beneficiary']['email_id']
  beneficiary_object_id: AzureMarketplaceSubscription['beneficiary']['object_id']
  beneficiary_tenant_id: AzureMarketplaceSubscription['beneficiary']['tenant_id']
  purchaser_email: AzureMarketplaceSubscription['purchaser']['email_id']
}

interface GCPMarketplaceInfo extends CommonMarketplaceInfo<'gcp'> {
  gcp_customer_id?: string
  gcp_entitlement_id?: string
  gcp_subscribed: boolean
  gcp_subscribed_annual?: boolean
  gcp_unsubscribed_at?: string
  gcp_procurement_account_id?: string
  gcp_offer?: string
  gcp_offer_end_time?: string
  gcp_quote_id?: string
}

interface AWSMarketplaceInfo extends CommonMarketplaceInfo<'aws'> {
  aws_customer_id?: string
  aws_subscribed: boolean
}

type MarketplaceInfoType = AzureMarketplaceInfo | GCPMarketplaceInfo | AWSMarketplaceInfo

type GetListItems<Info> = (info: Info) => EuiDescriptionListItems

const notAvailableMsg = (
  <FormattedMessage id='marketplace-information.not-available.text' defaultMessage='N/A' />
)

const getAzureMarketplaceInfoList: GetListItems<AzureMarketplaceInfo> = ({
  azure_plan_id,
  azure_subscribed,
  azure_subscription_id,
  azure_unsubscribed_at,
  name,
  status,
  beneficiary_email,
  beneficiary_object_id,
  beneficiary_tenant_id,
  purchaser_email,
  offer_id,
}) => [
  {
    title: <FormattedMessage id='azure-status.title' defaultMessage='Status' />,
    description: (
      <Fragment>
        <span css={css({ marginRight: 4 })}>{status || notAvailableMsg}</span>
        {azure_unsubscribed_at && (
          <EuiIconTip
            type='iInCircle'
            color='primary'
            content={
              <FormattedMessage
                id='azure-unsubscribed-at.tooltip'
                defaultMessage='Marketplace unsubscribe date: {date}'
                values={{
                  date: <CuiDate date={azure_unsubscribed_at} />,
                }}
              />
            }
          />
        )}
        {shouldShowMismatchStatusWarning({ azure_subscribed, status }) && (
          <EuiIconTip
            type='alert'
            color='warning'
            data-test-id='azure-status-warning'
            content={
              <FormattedMessage
                id='azure-status.warning'
                defaultMessage='Internal status ({internalStatus}) and marketplace status are different. Possibly causing billing issues.'
                values={{
                  internalStatus: azure_subscribed ? (
                    <FormattedMessage id='subscription-active' defaultMessage='Active' />
                  ) : (
                    <FormattedMessage id='subscription-inactive' defaultMessage='Inactive' />
                  ),
                }}
              />
            }
          />
        )}
      </Fragment>
    ),
  },
  {
    title: <FormattedMessage id='azure-name.title' defaultMessage='SaaS resource name' />,
    description: name || notAvailableMsg,
  },
  {
    title: <FormattedMessage id='azure-subscription-id.title' defaultMessage='SaaS resource ID' />,
    description: azure_subscription_id || notAvailableMsg,
  },
  {
    title: <FormattedMessage id='azure-plan-id.title' defaultMessage='Plan ID' />,
    description: azure_plan_id || notAvailableMsg,
  },
  {
    title: <FormattedMessage id='azure-tenant-id.title' defaultMessage='Tenant ID' />,
    description: beneficiary_tenant_id || notAvailableMsg,
  },
  {
    title: <FormattedMessage id='azure-integration.title' defaultMessage='Offer ID' />,
    description: offer_id ? getAzureOfferName(offer_id) : notAvailableMsg,
  },
  {
    title: (
      <Fragment>
        <span css={css({ marginRight: 4 })}>
          <FormattedMessage id='azure-beneficiary.title' defaultMessage='Beneficiary' />
        </span>

        <EuiIconTip
          type='iInCircle'
          color='primary'
          content={
            <FormattedMessage
              id='azure-beneficiary.description'
              defaultMessage='Email address for which SaaS subscription was purchased.'
            />
          }
        />
      </Fragment>
    ),
    description: beneficiary_email ? (
      <Fragment>
        {beneficiary_email} ({beneficiary_object_id})
      </Fragment>
    ) : (
      notAvailableMsg
    ),
  },
  {
    title: (
      <Fragment>
        <span css={css({ marginRight: 4 })}>
          <FormattedMessage id='azure-purchaser.title' defaultMessage='Purchaser' />
        </span>
        <EuiIconTip
          type='iInCircle'
          color='primary'
          content={
            <FormattedMessage
              id='azure-purchaser.description'
              defaultMessage='Email address that purchased the SaaS subscription. These could be different from beneficiary information for reseller (CSP) purchase.'
            />
          }
        />
      </Fragment>
    ),
    description: purchaser_email || notAvailableMsg,
  },
]

const getAzureOfferName = (offer_id: AzureMarketplaceInfo['offer_id']): string =>
  ({
    'ec-azure': 'Legacy',
    'ec-azure-pp': 'Native',
  }[offer_id] || offer_id)

const shouldShowMismatchStatusWarning = ({
  azure_subscribed,
  status,
}: {
  azure_subscribed: AzureMarketplaceInfo['azure_subscribed']
  status: AzureMarketplaceInfo['status']
}): boolean => {
  if (azure_subscribed && status === 'Subscribed') {
    return false
  }

  if (!azure_subscribed && status === 'Unsubscribed') {
    return false
  }

  return true
}

enum GcpSubscriptionType {
  ANNUAL = 'annual',
  MONTHLY = 'monthly',
}

const getGcpSubscriptionType = (gcp_offer_end_time?: string): GcpSubscriptionType => {
  if (!gcp_offer_end_time) {
    return GcpSubscriptionType.MONTHLY
  }

  const offerEndTimeMoment = moment.utc(gcp_offer_end_time)
  const currentTimeMoment = moment.utc()

  if (currentTimeMoment.isBefore(offerEndTimeMoment)) {
    return GcpSubscriptionType.ANNUAL
  }

  return GcpSubscriptionType.MONTHLY
}

const getGCPMarketplaceInfoList: GetListItems<GCPMarketplaceInfo> = ({
  gcp_customer_id,
  gcp_subscribed,
  gcp_entitlement_id,
  gcp_unsubscribed_at,
  gcp_procurement_account_id,
  gcp_offer_end_time,
  gcp_quote_id,
}) => {
  const subscriptionType = getGcpSubscriptionType(gcp_offer_end_time)
  const isAnnualCustomer = subscriptionType === GcpSubscriptionType.ANNUAL

  const commonAttributes = [
    {
      title: <FormattedMessage id='gcp-status.title' defaultMessage='Status' />,
      description: (
        <Fragment>
          <span css={css({ marginRight: 4 })}>
            {gcp_subscribed ? (
              <FormattedMessage id='gcp-status-subscribed' defaultMessage='Subscribed' />
            ) : (
              <FormattedMessage id='gcp-status-unsubscribed' defaultMessage='Unsubscribed' />
            )}
          </span>
          {gcp_unsubscribed_at && (
            <EuiIconTip
              type='iInCircle'
              color='primary'
              content={
                <FormattedMessage
                  id='gcp-unsubscribed-at.tooltip'
                  defaultMessage='Unsubscribe date: {date}'
                  values={{
                    date: <CuiDate date={gcp_unsubscribed_at} />,
                  }}
                />
              }
            />
          )}
        </Fragment>
      ),
    },
    {
      title: (
        <FormattedMessage id='gcp-subscription-type.title' defaultMessage='Subscription type' />
      ),
      description: {
        [GcpSubscriptionType.ANNUAL]: (
          <FormattedMessage id='gcp-annual-subscription' defaultMessage='Annual' />
        ),
        [GcpSubscriptionType.MONTHLY]: (
          <FormattedMessage id='gcp-monthly-subscription' defaultMessage='Monthly' />
        ),
      }[subscriptionType],
    },
    {
      title: <FormattedMessage id='gcp-entitlement-id.title' defaultMessage='Entitlement ID' />,
      description: gcp_entitlement_id || notAvailableMsg,
    },
    {
      title: (
        <FormattedMessage
          id='gcp-procurement-account-id.title'
          defaultMessage='Procurement account ID'
        />
      ),
      description: gcp_procurement_account_id || notAvailableMsg,
    },
    {
      title: <FormattedMessage id='gcp-customer-id.title' defaultMessage='Customer ID' />,
      description: gcp_customer_id || notAvailableMsg,
    },
  ]

  const annualCustomerAttributes = [
    {
      title: <FormattedMessage id='gcp-offer-end-time.title' defaultMessage='Offer end time' />,
      description: gcp_offer_end_time ? <CuiDate date={gcp_offer_end_time} /> : notAvailableMsg,
    },
    {
      title: <FormattedMessage id='gcp-quote-id.title' defaultMessage='Quote ID' />,
      description: gcp_quote_id || notAvailableMsg,
    },
  ]

  return [...commonAttributes, ...(isAnnualCustomer ? annualCustomerAttributes : [])]
}

const getAWSMarketplaceInfoList: GetListItems<AWSMarketplaceInfo> = ({
  aws_customer_id,
  aws_subscribed,
}) => [
  {
    title: <FormattedMessage id='aws-customer-id.title' defaultMessage='AWS customer ID' />,
    description: aws_customer_id || notAvailableMsg,
  },
  {
    title: (
      <FormattedMessage id='aws-subscribed.title' defaultMessage='AWS marketplace subscription' />
    ),
    description: aws_subscribed ? (
      <FormattedMessage id='subscription-active' defaultMessage='Active' />
    ) : (
      <FormattedMessage id='subscription-inactive' defaultMessage='Inactive' />
    ),
  },
]

const getDescriptionListItemsByMarketplace = (
  info: MarketplaceInfoType,
): EuiDescriptionListItems => {
  switch (info.domain) {
    case 'aws': {
      return getAWSMarketplaceInfoList(info)
    }

    case 'azure': {
      return getAzureMarketplaceInfoList(info)
    }

    case 'gcp': {
      return getGCPMarketplaceInfoList(info)
    }

    default:
      return []
  }
}

const getMarketplaceInfo = ({
  saasOrganization,
  marketplaceBillingDetails,
}: {
  saasOrganization: SaasOrganizationResponse
  marketplaceBillingDetails?: AzureMarketplaceSubscription
}): MarketplaceInfoType | null => {
  const {
    organization: { domain },
    subscription,
  } = saasOrganization

  switch (domain) {
    case 'aws': {
      const { aws_customer_id, aws_subscribed } = subscription
      return {
        domain: 'aws',
        aws_customer_id,
        aws_subscribed,
      } as AWSMarketplaceInfo
    }

    case 'gcp': {
      const {
        marketplace_attributes: {
          gcp_customer_id,
          gcp_entitlement_id,
          gcp_subscribed_annual,
          gcp_unsubscribed_at,
          gcp_offer,
          gcp_procurement_account_id,
          gcp_offer_end_time,
          gcp_quote_id,
        } = {},
        gcp_subscribed,
      } = subscription
      return {
        domain: 'gcp',
        gcp_customer_id,
        gcp_entitlement_id,
        gcp_subscribed,
        gcp_subscribed_annual,
        gcp_unsubscribed_at,
        gcp_offer,
        gcp_procurement_account_id,
        gcp_offer_end_time,
        gcp_quote_id,
      } as GCPMarketplaceInfo
    }

    case 'azure': {
      const {
        marketplace_attributes: {
          azure_plan_id,
          azure_subscription_id,
          azure_unsubscribed_at,
        } = {},
        azure_subscribed,
      } = subscription
      return {
        domain: 'azure',
        azure_subscribed,
        azure_plan_id,
        azure_subscription_id,
        azure_unsubscribed_at,
        name: marketplaceBillingDetails?.name,
        status: marketplaceBillingDetails?.status,
        beneficiary_email: marketplaceBillingDetails?.beneficiary.email_id,
        beneficiary_object_id: marketplaceBillingDetails?.beneficiary.object_id,
        beneficiary_tenant_id: marketplaceBillingDetails?.beneficiary.tenant_id,
        purchaser_email: marketplaceBillingDetails?.purchaser.email_id,
        offer_id: marketplaceBillingDetails?.offer_id,
      } as AzureMarketplaceInfo
    }

    default:
      return null
  }
}

interface MarketplaceInfoProps {
  saasOrganization: SaasOrganizationResponse
  marketplaceBillingDetails?: AzureMarketplaceSubscription
  isLoading?: boolean
}

const MarketplaceInfo: FunctionComponent<MarketplaceInfoProps> = ({
  saasOrganization,
  marketplaceBillingDetails,
  isLoading,
}) => {
  const marketplaceInfoTitle = (
    <FormattedMessage id='marketplace-info.title' defaultMessage='Marketplace Information' />
  )

  if (isLoading) {
    return (
      <PageSection iconType='tag' title={marketplaceInfoTitle}>
        <EuiSkeletonText lines={8} />
      </PageSection>
    )
  }

  const marketplaceInfo = getMarketplaceInfo({ saasOrganization, marketplaceBillingDetails })

  if (!marketplaceInfo) {
    return null
  }

  return (
    <PageSection iconType='tag' title={marketplaceInfoTitle}>
      <EuiDescriptionList
        compressed={true}
        type='responsiveColumn'
        listItems={getDescriptionListItemsByMarketplace(marketplaceInfo)}
      />
    </PageSection>
  )
}

export default MarketplaceInfo
