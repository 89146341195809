/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from '@elastic/eui'

import { CuiAlert } from '@modules/cui'
import history from '@modules/utils/history'

import { userOverviewUrl } from '@/lib/urlBuilder'
import { notNull } from '@/lib/arrays'

import SpinButton from '../../SpinButton'

import type { FunctionComponent, FormEvent } from 'react'
import type { Props } from './types'

const SearchByDeploymentId: FunctionComponent<Props> = (props) => {
  const { deploymentsRequest, searchDeployment, fetchSaasUser } = props

  const [searchId, setSearchId] = useState('')
  const [noResults, setNoResults] = useState(false)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    searchDeployment(searchId)
      .then(({ payload }) => {
        const deployments = payload?.deployments

        if (!deployments || deployments.length === 0) {
          setNoResults(true)
        }

        const userId = deployments?.map((each) => each.metadata?.owner_id).find(notNull)

        if (!userId) {
          return
        }

        return fetchSaasUser(userId).then(() => history.push(userOverviewUrl(userId)))
      })
      .catch(() => setNoResults(true))
  }

  return (
    <EuiForm component='form' onSubmit={onSubmit}>
      <EuiFormRow label={<FormattedMessage id='users.email-label' defaultMessage='Email' />}>
        <EuiFieldText value={searchId} onChange={(e) => setSearchId(e.target.value)} />
      </EuiFormRow>

      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <SpinButton
              fill={true}
              disabled={searchId.trim().length === 0}
              spin={deploymentsRequest.inProgress}
              type='submit'
            >
              <FormattedMessage
                id='users.search-by-deployment-id.button-text'
                defaultMessage='Search'
              />
            </SpinButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButtonEmpty type='button' onClick={() => setSearchId('')}>
              <FormattedMessage id='users.search-button-reset' defaultMessage='Reset' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      {deploymentsRequest.error && (
        <Fragment>
          <EuiSpacer />
          <CuiAlert type='error'>{deploymentsRequest.error}</CuiAlert>
        </Fragment>
      )}

      {!deploymentsRequest.error && deploymentsRequest.isDone && noResults && (
        <Fragment>
          <EuiSpacer />
          <CuiAlert type='warning' iconType='alert'>
            <FormattedMessage
              id='users.search-by-deployment-id.no-results'
              defaultMessage='No matching deployments found'
            />
          </CuiAlert>
        </Fragment>
      )}
    </EuiForm>
  )
}

export default SearchByDeploymentId
