/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import { generateResetPasswordLink } from '@/actions/generateResetPasswordLink'
import { generateResetPasswordLinkRequest } from '@/reducers'
import { getConfigForKey, isFeatureActivated } from '@/selectors'

import GenerateResetPasswordLinkButton from './GenerateResetPasswordLinkButton'

import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (state): StateProps => ({
  hostname: getConfigForKey(state, `CLOUD_USERCONSOLE_URL`),
  generateResetPasswordLinkRequest: generateResetPasswordLinkRequest(state),
  isFeatureHidden: !isFeatureActivated(state, Feature.generateResetPasswordLink),
})

const mapDispatchToProps = (dispatch, { userId }: ConsumerProps): DispatchProps => ({
  generateResetPasswordLink: () => dispatch(generateResetPasswordLink(userId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(GenerateResetPasswordLinkButton)
