/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { trimStart } from 'lodash'

import { getDeploymentResourceProxyRequestsUrl } from '@modules/cloud-api/v1/urls'

import asyncRequest from '../asyncRequests'
import { QUERY_DEPLOYMENT_PROXY, CLEAR_DEPLOYMENT_PROXY_RESPONSE } from '../../constants/actions'

import type { Method } from '../asyncRequests'

export function queryDeploymentProxy({
  deploymentId,
  refId,
  resourceKind,
  method,
  path,
  body,
  meta = {},
}: {
  deploymentId: string
  refId: string
  resourceKind: string
  method: Method
  path: string
  body: any
  meta?: any
}) {
  const proxyPath = trimStart(path, `/`)
  const url = getDeploymentResourceProxyRequestsUrl({
    deploymentId,
    resourceKind,
    refId,
    proxyPath,
  })

  /*
   * The Fetch standard doesn't allow GET requests that include a body.
   * As a workaround it needs to:
   *
   * [1]: Make a POST request instead.
   * [2]: Set the X-HTTP-Method-Override header to 'GET'. Adminconsole will
   *      revert it back to GET before forwarding the request to the ES API.
   */
  const isGetMethod = method === `GET`
  const requestMethod = isGetMethod ? `POST` : method // [1]
  const httpMethodOverrideHeader = isGetMethod
    ? {
        'X-HTTP-Method-Override': 'GET', // [2]
      }
    : null

  const headers = {
    'X-Management-Request': true,
    Accept: `*/*`,
    ...httpMethodOverrideHeader,
  }

  // the payload might not be JSON at all, as is the case with `POST /_msearch`
  const parsedBody = parseBody(body)
  const payload = parsedBody === `` ? undefined : parsedBody
  const json = typeof payload !== `string`

  const now = Date.now()

  return asyncRequest({
    type: QUERY_DEPLOYMENT_PROXY,
    method: requestMethod, // [1]
    payload,
    url,
    meta: { deploymentId, ...meta },
    crumbs: [deploymentId],
    handleUnauthorized: true,
    requestSettings: {
      json,
      request: {
        headers,
      },
      response: {
        fullStatus: true,
      },
    },
    responseMapper: (response) => {
      const requestDuration = Date.now() - now

      return {
        requestDuration,
        ...response,
      }
    },
  })
}

export function clearDeploymentProxyResponse(deploymentId: string) {
  return {
    type: CLEAR_DEPLOYMENT_PROXY_RESPONSE,
    meta: { deploymentId },
  }
}

function parseBody(body) {
  if (typeof body !== `string`) {
    return body
  }

  try {
    return JSON.parse(body)
  } catch (e) {
    return body
  }
}
