/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState, SliderInstanceType, StackDeployment } from '@modules/ui-types'

import {
  downloadClusterDiagnosticBundle,
  resetDownloadClusterDiagnosticBundleRequest,
} from '../../../actions/clusters'
import {
  getClusterDiagnosticBundle,
  downloadClusterDiagnosticBundleRequest,
  getStackDeployment,
} from '../../../reducers'

import ClusterDiagnosticBundle from './ClusterDiagnosticBundle'

import type { ThunkDispatch } from '@/types/redux'

type StateProps = {
  deployment: StackDeployment
  getClusterDiagnosticBundle: (resourceKind: SliderInstanceType) => any
  downloadClusterDiagnosticBundleRequest: (resourceKind: SliderInstanceType) => AsyncRequestState
}

type DispatchProps = {
  downloadClusterDiagnosticBundle: (resourceKind: SliderInstanceType, refId: string) => Promise<any>
  resetElasticsearchDiagnosticBundleRequest: () => void
  resetKibanaDiagnosticBundleRequest: () => void
}

type ConsumerProps = {
  deploymentId: string
}

const mapStateToProps = (state, { deploymentId }): StateProps => {
  const deployment = getStackDeployment(state, deploymentId)!

  return {
    deployment,
    getClusterDiagnosticBundle: (resourceKind: SliderInstanceType) =>
      getClusterDiagnosticBundle(state, deploymentId, resourceKind),
    downloadClusterDiagnosticBundleRequest: (resourceKind: SliderInstanceType) =>
      downloadClusterDiagnosticBundleRequest(state, deploymentId, resourceKind),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deploymentId }: ConsumerProps,
): DispatchProps => ({
  downloadClusterDiagnosticBundle: (resourceKind: SliderInstanceType, refId: string) =>
    dispatch(downloadClusterDiagnosticBundle({ deploymentId, resourceKind, refId })),
  resetElasticsearchDiagnosticBundleRequest: () =>
    dispatch(resetDownloadClusterDiagnosticBundleRequest(deploymentId, `elasticsearch`)),
  resetKibanaDiagnosticBundleRequest: () =>
    dispatch(resetDownloadClusterDiagnosticBundleRequest(deploymentId, `kibana`)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ClusterDiagnosticBundle)
