/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DeploymentHealth } from '@modules/cloud-api/v1/types'

import { FETCH_DEPLOYMENT_HEALTH } from '@/constants/actions'
import { replaceIn } from '@/lib/immutability-helpers'

type FetchAction = {
  type: 'FETCH_DEPLOYMENT_HEALTH'
  meta: { deploymentId: string }
  error: Error | null
  payload: DeploymentHealth
}

export interface State {
  [deploymentIdentifier: string]: DeploymentHealth
}

const initialState: State = {}

export default function deploymentHealthReducer(
  state: State = initialState,
  action: FetchAction,
): State {
  if (action.type === FETCH_DEPLOYMENT_HEALTH) {
    if (!action.error && action.payload) {
      const { deploymentId } = action.meta
      return replaceIn(state, deploymentId, action.payload)
    }
  }

  return state
}

export const getDeploymentHealth = (state: State, deploymentId: string): DeploymentHealth | null =>
  state[deploymentId] || null
