/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '@modules/cui'

import BillingOverviewPanel from '@/components/User/BillingOverview/BillingOverviewPanel'

import OrganizationPrepaidSubscriptionsTable from './OrganizationPrepaidSubscriptionsTable'
import OrganizationPrepaidPremiumProductsTable from './OrganizationPrepaidPremiumProductsTable'

import type { ReactNode } from 'react'
import type { AllProps as Props } from './types'

class OrganizationPrepaids extends Component<Props> {
  componentDidMount(): void {
    const { organizationId, fetchOrganizationPrepaids } = this.props

    if (organizationId) {
      fetchOrganizationPrepaids(organizationId)
    }
  }

  render(): ReactNode {
    const { getOrganizationPrepaids, organizationId, fetchOrganizationPrepaidsRequest } = this.props

    if (!organizationId) {
      return null
    }

    if (fetchOrganizationPrepaidsRequest.error) {
      return (
        <CuiAlert
          data-test-id='organization-prepaids-error'
          details={fetchOrganizationPrepaidsRequest.error}
          type='error'
        />
      )
    }

    const organizationPrepaids = getOrganizationPrepaids(organizationId)
    const isLoading = fetchOrganizationPrepaidsRequest.inProgress

    return (
      <Fragment>
        <BillingOverviewPanel
          adminOnly={true}
          title={
            <FormattedMessage
              id='organization.organization-prepaids.premium-products-title'
              defaultMessage='PREMIUM PRODUCTS'
            />
          }
        >
          <EuiSpacer size='m' />
          <OrganizationPrepaidPremiumProductsTable
            premiumProducts={organizationPrepaids?.premium_products}
            initialLoading={isLoading}
          />
        </BillingOverviewPanel>

        <EuiSpacer size='xl' />

        <BillingOverviewPanel
          adminOnly={true}
          title={
            <FormattedMessage
              id='organization.organization-prepaids.subscriptions-title'
              defaultMessage='SUBSCRIPTIONS'
            />
          }
        >
          <EuiSpacer size='m' />
          <OrganizationPrepaidSubscriptionsTable
            prepaidSubscriptions={organizationPrepaids?.subscriptions}
            initialLoading={isLoading}
          />
        </BillingOverviewPanel>
      </Fragment>
    )
  }
}

export default OrganizationPrepaids
