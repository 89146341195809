/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  dataLossRiskTitle: {
    id: `data-loss-risk-title`,
    defaultMessage: `Your deployment and data could become unavailable`,
  },
  dataLossRiskMessage: {
    id: `data-loss-risk-message`,
    defaultMessage: `With only 1 availability zone for your {hotTier} and {warmTier}, any host failures put you at risk of losing data.
     To maintain high availability of your data, use 2 or more availability zones. {learnMore}`,
  },
  dataLossRiskMessageWithColdTier: {
    id: `data-loss-risk-message-with-cold-tier`,
    defaultMessage: `With only 1 availability zone for your {hotTier}, {warmTier}, and {coldTier}, any host failures put you at risk of losing data.
     To maintain high availability of your data, use 2 or more availability zones. For the cold and frozen tiers, you can set up searchable snapshots instead. {learnMore}`,
  },
})
