/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sortBy } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiIcon, EuiLink, EuiHealth } from '@elastic/eui'

import type { RegionProxy } from '@modules/ui-types'
import { CuiLink, CuiTable } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import { hostProxyUrl } from '../../lib/urlBuilder'

import type { FunctionComponent } from 'react'

type Props = {
  regionId: string
  proxies: RegionProxy[]
  totalCount: number
  onZoneClick: (zoneId) => void
  initialLoading: boolean
}

const ProxiesTable: FunctionComponent<Props> = ({
  regionId,
  proxies,
  totalCount,
  onZoneClick,
  initialLoading,
}) => {
  const columns: Array<CuiTableColumn<RegionProxy>> = [
    {
      label: <FormattedMessage id='proxies-table.host' defaultMessage='Host' />,
      render: ({ id, runnerId }) =>
        runnerId === null ? id : <CuiLink to={hostProxyUrl(regionId, runnerId)}>{id}</CuiLink>,
      sortKey: `id`,
      width: `230px`,
    },

    {
      id: 'health',
      render: ({ healthy }) => (
        <EuiHealth color={healthy ? 'success' : 'danger'}>
          {healthy ? (
            <FormattedMessage id='proxies-table.healthy' defaultMessage='Healthy' />
          ) : (
            <FormattedMessage id='proxies-table.unhealthy' defaultMessage='Unhealthy' />
          )}
        </EuiHealth>
      ),
      label: <FormattedMessage id='proxies-table.status' defaultMessage='Proxy status' />,
      width: `130px`,
    },

    {
      label: <FormattedMessage id='proxies-table.zone' defaultMessage='Zone' />,
      render: ({ availabilityZone }) => (
        <EuiLink onClick={() => onZoneClick(availabilityZone)}>{availabilityZone}</EuiLink>
      ),
      sortKey: `availabilityZone`,
      width: `130px`,
    },

    {
      label: (
        <FormattedMessage
          id='proxies-table.es-connections'
          defaultMessage='Elasticsearch connections'
        />
      ),
      render: (proxy) => (
        <Fragment>
          <EuiIcon type='logoElasticsearch' /> {proxy.allocationCounts.elasticsearch}
        </Fragment>
      ),
      sortKey: `allocationCounts.elasticsearch`,
    },

    {
      label: (
        <FormattedMessage
          id='proxies-table.kibana-connections'
          defaultMessage='Kibana connections'
        />
      ),
      render: (proxy) => (
        <Fragment>
          <EuiIcon type='logoKibana' /> {proxy.allocationCounts.kibana}
        </Fragment>
      ),
      sortKey: `allocationCounts.kibana`,
    },

    {
      label: (
        <FormattedMessage id='proxies-table.apm-connections' defaultMessage='APM connections' />
      ),
      render: (proxy) => (
        <Fragment>
          <EuiIcon type='logoObservability' /> {proxy.allocationCounts.apm}
        </Fragment>
      ),
      sortKey: `allocationCounts.apm`,
    },

    {
      label: (
        <FormattedMessage
          id='proxies-table.enterprise-search-connections'
          defaultMessage='Enterprise Search connections'
        />
      ),
      render: (proxy) => (
        <Fragment>
          <EuiIcon type='logoEnterpriseSearch' /> {proxy.allocationCounts.enterpriseSearch}
        </Fragment>
      ),
      sortKey: `allocationCounts.enterpriseSearch`,
    },

    {
      label: (
        <FormattedMessage id='proxies-table.total-connections' defaultMessage='Total connections' />
      ),
      render: (proxy) => proxy.allocationCounts.total,
      sortKey: (proxy) => proxy.allocationCounts.total,
    },
  ]

  return (
    <CuiTable<RegionProxy>
      rows={sortBy(proxies, `availabilityZone`)}
      getRowId={(proxy) => proxy.id}
      pageSize={25}
      showMatchCount={true}
      totalCount={totalCount}
      matchType={<FormattedMessage id='proxies-table.match-type' defaultMessage='proxy' />}
      matchTypePlural={
        <FormattedMessage id='proxies-table.match-type-plural' defaultMessage='proxies' />
      }
      columns={columns}
      initialLoading={initialLoading}
    />
  )
}

export default ProxiesTable
