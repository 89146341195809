/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState } from '@modules/ui-types'

import { fetchRegion } from '../../../actions/regions'
import {
  startConstructorMaintenanceMode,
  stopConstructorMaintenanceMode,
} from '../../../actions/constructors'
import {
  startConstructorMaintenanceModeRequest,
  stopConstructorMaintenanceModeRequest,
} from '../../../reducers'
import { getConfigForKey } from '../../../selectors'

import ConstructorTableRowActions from './ConstructorTableRowActions'

import type { Constructor } from '@/types/redux'

type StateProps = {
  startMaintenanceModeRequest: AsyncRequestState
  stopMaintenanceModeRequest: AsyncRequestState
  isElasticCloudEnterprise: boolean
}

type DispatchProps = {
  startMaintenanceMode: () => void
  stopMaintenanceMode: () => void
}

type ConsumerProps = {
  ctor: Constructor
}

const mapStateToProps = (state, { ctor: { regionId, id } }: ConsumerProps): StateProps => ({
  startMaintenanceModeRequest: startConstructorMaintenanceModeRequest(state, regionId, id),
  stopMaintenanceModeRequest: stopConstructorMaintenanceModeRequest(state, regionId, id),
  isElasticCloudEnterprise:
    getConfigForKey(state, `CLOUD_UI_APP`) === `cloud-enterprise-adminconsole`,
})

const mapDispatchToProps = (
  dispatch,
  { ctor: { regionId, id } }: ConsumerProps,
): DispatchProps => ({
  startMaintenanceMode: () =>
    dispatch(startConstructorMaintenanceMode(regionId, id)).then(() => dispatch(fetchRegion())),
  stopMaintenanceMode: () =>
    dispatch(stopConstructorMaintenanceMode(regionId, id)).then(() => dispatch(fetchRegion())),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ConstructorTableRowActions)
