/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation } from 'react-query'

import {
  updateElasticsearchProject,
  updateObservabilityProject,
  updateSecurityProject,
} from '@modules/project-api/v1/callers'
import type {
  ProjectID,
  UpdateElasticsearchProjectRequest,
  UpdateObservabilityProjectRequest,
  UpdateSecurityProjectRequest,
} from '@modules/project-api/v1/types'
import type { ProjectType } from '@modules/ui-types/projects'
import type { QueryFunctionReturnType } from '@modules/query/types'
import type { AnyProject } from '@modules/project-api/types'
import { queryClient } from '@modules/query'

import { makeQueryKey } from './helpers'

/**
 * Returns the appropriate hook for the provided project type.
 */
export function getUpdateProjectMutationHook(
  projectType: ProjectType,
):
  | typeof useUpdateElasticsearchProjectMutation
  | typeof useUpdateObservabilityProjectMutation
  | typeof useUpdateSecurityProjectMutation {
  switch (projectType) {
    case 'observability':
      return useUpdateObservabilityProjectMutation
    case 'security':
      return useUpdateSecurityProjectMutation
    default:
      return useUpdateElasticsearchProjectMutation
  }
}

const useUpdateElasticsearchProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof updateElasticsearchProject>,
    unknown,
    { id: ProjectID; body: UpdateElasticsearchProjectRequest }
  >({
    mutationFn: ({ id, body }) =>
      updateElasticsearchProject({
        pathParameters: { id },
        body,
      }),
    onSuccess: onProjectUpdated,
  })

const useUpdateObservabilityProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof updateObservabilityProject>,
    unknown,
    { id: ProjectID; body: UpdateObservabilityProjectRequest }
  >({
    mutationFn: ({ id, body }) =>
      updateObservabilityProject({
        pathParameters: { id },
        body,
      }),
    onSuccess: onProjectUpdated,
  })

const useUpdateSecurityProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof updateSecurityProject>,
    unknown,
    { id: ProjectID; body: UpdateSecurityProjectRequest }
  >({
    mutationFn: ({ id, body }) =>
      updateSecurityProject({
        pathParameters: { id },
        body,
      }),
    onSuccess: onProjectUpdated,
  })

const onProjectUpdated = (project: AnyProject) => {
  const listQueryKey = makeQueryKey(
    (
      {
        elasticsearch: 'listElasticsearchProjects',
        observability: 'listObservabilityProjects',
        security: 'listSecurityProjects',
      } as const
    )[project.type],
  )
  queryClient.invalidateQueries(listQueryKey)

  const detailQueryKey = makeQueryKey(
    (
      {
        elasticsearch: 'getElasticsearchProject',
        observability: 'getObservabilityProject',
        security: 'getSecurityProject',
      } as const
    )[project.type],
    project.id,
  )
  queryClient.setQueryData(detailQueryKey, project)
}
