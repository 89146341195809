/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  getDefaultDeploymentDomainNameUrl,
  setDefaultDeploymentDomainNameUrl,
} from '@modules/cloud-api/v1/urls'
import type { DeploymentDomainName } from '@modules/cloud-api/v1/types'

import asyncRequest from '../asyncRequests'
import {
  FETCH_DEPLOYMENT_DOMAIN_NAME,
  UPDATE_DEPLOYMENT_DOMAIN_NAME,
} from '../../constants/actions'

export function fetchDeploymentDomainName({ regionId }: { regionId: string }) {
  return asyncRequest({
    type: FETCH_DEPLOYMENT_DOMAIN_NAME,
    url: getDefaultDeploymentDomainNameUrl({ regionId }),
    crumbs: [regionId],
    meta: { regionId },
  })
}

export function updateDeploymentDomainName({
  regionId,
  deploymentDomainName,
}: {
  regionId: string
  deploymentDomainName: string
}) {
  const payload: DeploymentDomainName = {
    value: deploymentDomainName,
  }

  return asyncRequest({
    type: UPDATE_DEPLOYMENT_DOMAIN_NAME,
    method: `PUT`,
    url: setDefaultDeploymentDomainNameUrl({ regionId }),
    payload,
    crumbs: [regionId],
    meta: { regionId },
  })
}
