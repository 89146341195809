/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiButtonEmpty, EuiCallOut, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '@modules/cui'

import DangerButton from '../../DangerButton'

import type { AllProps as Props } from './types'

const messages = defineMessages({
  scrubFormLabel: {
    id: `clear-privately-identifiable-information-button.scrub-form-label`,
    defaultMessage: `Scrub sensitive information`,
  },
  scrubButtonLabel: {
    id: `clear-privately-identifiable-information-button.scrub-button-label`,
    defaultMessage: `Scrub`,
  },
  scrubDescription: {
    id: `clear-privately-identifiable-information-button.scrub-description`,
    defaultMessage: `Obfuscates privately identifiable information`,
  },
  scrubModalTitle: {
    id: `clear-privately-identifiable-information-button.are-you-sure`,
    defaultMessage: `Scrub sensitive information?`,
  },
  scrubModalBody: {
    id: `clear-privately-identifiable-information-button.scrub-modal-body`,
    defaultMessage: `You're about to obfuscate privately identifiable information for this account. This action cannot be undone.`,
  },
  confirmScrub: {
    id: `clear-privately-identifiable-information-button.scrub-modal-confirm`,
    defaultMessage: `Yes, scrub sensitive information`,
  },
  scrubSuccess: {
    id: `clear-privately-identifiable-information-button.scrub-success`,
    defaultMessage: `Privately identifiable information obfuscated`,
  },
})

class ClearPrivatelyIdentifiableInformationButton extends Component<Props> {
  componentWillUnmount() {
    this.props.resetScrubRequest()
  }

  render() {
    const {
      intl: { formatMessage },
      scrub,
      scrubRequest,
    } = this.props

    return (
      <Fragment>
        <DangerButton
          size='s'
          buttonType={EuiButtonEmpty}
          buttonProps={{ flush: 'left' }}
          onConfirm={scrub}
          spin={scrubRequest.inProgress}
          modal={{
            title: formatMessage(messages.scrubModalTitle),
            body: formatMessage(messages.scrubModalBody),
            confirmButtonText: formatMessage(messages.confirmScrub),
          }}
        >
          <FormattedMessage {...messages.scrubFormLabel} />
        </DangerButton>

        {scrubRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{scrubRequest.error}</CuiAlert>
          </Fragment>
        )}

        {!scrubRequest.error && scrubRequest.isDone && (
          <Fragment>
            <EuiSpacer size='m' />

            <EuiCallOut color='warning' title={formatMessage(messages.scrubSuccess)} />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default injectIntl(ClearPrivatelyIdentifiableInformationButton)
