/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCode, EuiFlexGroup, EuiFlexItem, EuiSkeletonText, EuiText } from '@elastic/eui'

import { DeploymentName } from '@/components/DeploymentName'

import {
  getTrustLevelFromRelationship,
  isOwnAccountRelationship,
  trustLevelShortLabels,
} from '../../../../lib/stackDeployments/trustRelationships'
import { getFirstEsClusterFromGet } from '../../../../lib/stackDeployments/selectors'

import type { AllProps } from './types'

class Allowlist extends Component<AllProps> {
  componentDidMount(): void {
    this.searchForTrustedDeployments()
  }

  componentDidUpdate(): void {
    // may not have userOrgId on mount, so catch it on update
    this.searchForTrustedDeployments()
  }

  render(): JSX.Element | null {
    const { userOrgId, trustRelationship, allowlistDeployments } = this.props

    const trustLevel = getTrustLevelFromRelationship(trustRelationship)

    if (trustLevel !== `specific` || !trustRelationship?.trust_allowlist?.length) {
      return (
        <EuiText data-test-id='deployment-trust-level' data-test-trustlevel={trustLevel} size='s'>
          <FormattedMessage {...trustLevelShortLabels[trustLevel]} />
        </EuiText>
      )
    }

    // within the user's account we can display more info like deployment name and health
    if (isOwnAccountRelationship(trustRelationship, userOrgId)) {
      if (!allowlistDeployments) {
        return <EuiSkeletonText lines={1} />
      }

      return (
        <EuiFlexGroup gutterSize='s' direction='column'>
          {trustRelationship.trust_allowlist.map((clusterId) => {
            const trustedDeployment = allowlistDeployments.find((_deployment) => {
              const esCluster = getFirstEsClusterFromGet({ deployment: _deployment })
              return esCluster?.id === clusterId
            })

            if (allowlistDeployments && !trustedDeployment) {
              return // skip over deleted deployments
            }

            return (
              <EuiFlexItem
                key={clusterId}
                data-test-id={`trusted-deployment-${clusterId}`}
                grow={false}
              >
                <DeploymentName deployment={trustedDeployment} linkify={true} />
              </EuiFlexItem>
            )
          })}
        </EuiFlexGroup>
      )
    }

    return (
      <EuiFlexGroup gutterSize='s' direction='column'>
        {trustRelationship.trust_allowlist.map((clusterId) => (
          <EuiFlexItem key={clusterId}>
            <EuiCode>{clusterId.slice(0, 6)}</EuiCode>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    )
  }

  searchForTrustedDeployments(): void {
    const {
      userOrgId,
      trustRelationship,
      allowlistDeploymentsRequest,
      searchForAllowlistDeployments,
    } = this.props

    // only run once
    if (allowlistDeploymentsRequest.inProgress || allowlistDeploymentsRequest.isDone) {
      return
    }

    // only run for the "local" (same org if ESS, same env if ECE) trust relationship
    if (!isOwnAccountRelationship(trustRelationship, userOrgId)) {
      return
    }

    // only run if there are discrete deployments to query
    if (!trustRelationship.trust_allowlist || trustRelationship.trust_allowlist.length === 0) {
      return
    }

    searchForAllowlistDeployments(trustRelationship.trust_allowlist)
  }
}

export default Allowlist
