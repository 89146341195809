/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import type { EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { EuiIcon, EuiPopover, EuiContextMenu } from '@elastic/eui'

import type { CoordinatorCandidateInfo } from '@modules/cloud-api/v1/types'
import { CuiButton } from '@modules/cui'

import DemoteHost from '../DemoteHost'
import DeleteHost from '../DeleteHost'

import type { IntlShape } from 'react-intl'

type Props = {
  intl: IntlShape
  regionId: string
  hostId: string
  coordinator?: CoordinatorCandidateInfo
  startManagingRoles: () => void
}

type State = {
  managingHost: boolean
  demotingHost: boolean
  deletingHost: boolean
}

const messages = defineMessages({
  manageRoles: { id: 'host-overview.manage-roles', defaultMessage: 'Manage roles' },
  demoteHost: { id: 'host-overview.demote-host', defaultMessage: 'Demote host' },
  deleteHost: { id: 'host-overview.delete-host', defaultMessage: 'Delete host' },
})

class HostMenu extends Component<Props, State> {
  state: State = {
    managingHost: false,
    demotingHost: false,
    deletingHost: false,
  }

  render() {
    const {
      intl: { formatMessage },
      regionId,
      hostId,
      coordinator,
      startManagingRoles,
    } = this.props

    const { managingHost, demotingHost, deletingHost } = this.state

    const manageHostPanels: EuiContextMenuPanelDescriptor[] = [
      {
        id: 0,
        items: [
          {
            name: formatMessage(messages.manageRoles),
            icon: <EuiIcon type='gear' size='m' />,
            onClick: () => {
              this.setState({ managingHost: false })
              startManagingRoles()
            },
          },

          ...(coordinator
            ? [
                {
                  name: formatMessage(messages.demoteHost),
                  icon: <EuiIcon type='sortDown' size='m' />,
                  onClick: () => this.setState({ managingHost: false, demotingHost: true }),
                },
              ]
            : [
                {
                  name: formatMessage(messages.deleteHost),
                  icon: <EuiIcon type='trash' size='m' />,
                  onClick: () => this.setState({ managingHost: false, deletingHost: true }),
                },
              ]),
        ],
      },
    ]

    return (
      <Fragment>
        <EuiPopover
          id='popover'
          button={
            <CuiButton
              iconType='arrowDown'
              iconSide='right'
              onClick={() => this.setState({ managingHost: true })}
            >
              <FormattedMessage id='host.manage-host' defaultMessage='Manage host' />
            </CuiButton>
          }
          isOpen={managingHost}
          closePopover={() => this.setState({ managingHost: false })}
          anchorPosition='downRight'
          panelPaddingSize='none'
        >
          <EuiContextMenu initialPanelId={0} panels={manageHostPanels} />
        </EuiPopover>

        {demotingHost && (
          <DemoteHost
            regionId={regionId}
            hostId={hostId}
            onClose={() => this.setState({ demotingHost: false })}
          />
        )}

        {deletingHost && (
          <DeleteHost
            regionId={regionId}
            hostId={hostId}
            onClose={() => this.setState({ deletingHost: false })}
          />
        )}
      </Fragment>
    )
  }
}

export default injectIntl(HostMenu)
