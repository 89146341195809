/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, withErrorBoundary } from '@modules/cui'

import DangerButton from '../../../DangerButton'

import type { FunctionComponent } from 'react'

interface Props {
  regenerateInvoices: () => Promise<unknown>
  regenerateInvoicesRequest: AsyncRequestState
}

const RegenerateInvoicesButton: FunctionComponent<Props> = ({
  regenerateInvoices,
  regenerateInvoicesRequest,
}) => (
  <Fragment>
    <DangerButton
      buttonType={EuiButtonEmpty}
      size='s'
      color='primary'
      fill={false}
      onConfirm={regenerateInvoices}
      spin={regenerateInvoicesRequest.inProgress}
      modal={{
        title: (
          <h1>
            <FormattedMessage
              id='organization.organization-overview.regenerate-invoices.confirm-title'
              defaultMessage='Are you sure?'
            />
          </h1>
        ),
        body: (
          <FormattedMessage
            id='organization.organization-overview.regenerate-invoices.confirm-body'
            defaultMessage='You are about to regenerate invoices for this organization.'
          />
        ),
      }}
    >
      <FormattedMessage
        id='organization.organization-overview.regenerate-invoices.button-label'
        defaultMessage='Regenerate invoices'
      />
    </DangerButton>

    {regenerateInvoicesRequest.error && (
      <Fragment>
        <EuiSpacer size='m' />

        <CuiAlert type='error'>{regenerateInvoicesRequest.error}</CuiAlert>
      </Fragment>
    )}
  </Fragment>
)

export default withErrorBoundary(RegenerateInvoicesButton)
