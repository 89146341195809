/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiAccordion, EuiCallOut, EuiSpacer, EuiHorizontalRule } from '@elastic/eui'

import type { AsyncRequestState, Region } from '@modules/ui-types'
import Feature from '@modules/utils/feature'
import Header from '@modules/cui/Header'

import PhoneHomeConfig from '../PhoneHome/Config'
import { EditApiBaseUrl } from '../ApiBaseUrl'
import EditDeploymentDomainName from '../EditDeploymentDomainName'
import { regionSettingsCrumbs } from '../../lib/crumbBuilder'
import { isFeatureActivated } from '../../store'
import ScrollIntoView from '../ScrollIntoView'

import DeploymentAliasConfig from './DeploymentAliasConfig'
import Platform from './Platform'
import License from './License'
import TlsCertificates from './TlsCertificates'
import RbacStatus from './RbacStatus'

import type { WrappedComponentProps } from 'react-intl'

interface Props extends WrappedComponentProps {
  experimentalFeatures: boolean
  isEceAdminconsole: boolean
  region?: Region
  regionId: string
  fetchDeploymentDomainNameRequest: AsyncRequestState
}

const messages = defineMessages({
  title: {
    id: `region-settings.title`,
    defaultMessage: `Settings`,
  },
  experimentalLabel: {
    id: `region-settings-experimental.button-label`,
    defaultMessage: `Experimental settings`,
  },
  experimentalDisclaimerTitle: {
    id: `region-settings-experimental.disclaimer-title`,
    defaultMessage: `Proceed with caution!`,
  },
  experimentalDisclaimer: {
    id: `region-settings-experimental.disclaimer`,
    defaultMessage: `WARNING: This functionality is experimental and is subject to change. The design and code is less mature than official GA features. This functionality is being provided "as-is" with no warranties. Experimental features are not subject to the production support SLA of official GA features.`,
  },
  fetchRegionSettingsFailed: {
    id: `region-settings-index.fetch-region-settings-failed`,
    defaultMessage: `Fetching region settings failed`,
  },
  noExperimentalFeatures: {
    id: `region-settings-index.no-experimental-features`,
    defaultMessage: `There are no experimental features at present.`,
  },
  platformEndpoints: {
    id: `region-settings-index.platform-endpoints`,
    defaultMessage: `Platform endpoints`,
  },
})

class RegionSettings extends Component<Props> {
  render() {
    const { regionId } = this.props

    return (
      <Fragment>
        <Header
          name={<FormattedMessage {...messages.title} />}
          breadcrumbs={regionSettingsCrumbs({ regionId })}
        />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const { isEceAdminconsole, regionId, fetchDeploymentDomainNameRequest } = this.props

    /**
     * The TLS settings section is rendered after the edit deployment domain name one.
     * The former is wrapped around a `ScrollIntoView` component that will guide the users
     * to the TLS settings section when referenced by a link via hash in the URL, for example,
     * when clicking on a TLS certificates chain expiration alert.
     *
     * Since previous sections can be rendered after this `ScrollIntoView` component, it usually tries
     * to scroll before they are hydrated by they data they depend on thus the scroll action not
     * happening at all.
     *
     * That's why we have made the rendering of `tlsSection` depend on having a response with the
     * data for a section rendered before it: `EditDeploymentDomainName`. When we know that previous
     * section has been hydrated, only then, we wrap the TLS section around the scrolling component.
     * This makes it highly improbable for it to be rendered before most of the previous components
     * have been hydrated without having to resort to fixed times delays.
     */
    const tlsSection = <TlsCertificates regionId={regionId} />
    const readyToScrollToTls = fetchDeploymentDomainNameRequest.meta?.state === 'success'

    return (
      <div>
        <Platform regionId={regionId} />

        <EuiSpacer size='l' />

        {isFeatureActivated(Feature.manageRbac) && (
          <Fragment>
            <EuiSpacer size='l' />

            <RbacStatus regionId={regionId} />
          </Fragment>
        )}

        <EuiHorizontalRule margin='xl' />

        <EditApiBaseUrl />

        <EuiSpacer size='l' />

        <EditDeploymentDomainName regionId={regionId} />

        <EuiHorizontalRule margin='xl' />

        {isEceAdminconsole && (
          <Fragment>
            <DeploymentAliasConfig regionId={regionId} />

            <EuiSpacer size='l' />
          </Fragment>
        )}

        {readyToScrollToTls ? (
          <ScrollIntoView whenLocationMatches={(hash, _) => hash === 'tls'}>
            {tlsSection}
          </ScrollIntoView>
        ) : (
          tlsSection
        )}

        <EuiSpacer size='l' />

        <License regionId={regionId} />

        {isEceAdminconsole && (
          <Fragment>
            <EuiSpacer size='l' />
            <PhoneHomeConfig />
          </Fragment>
        )}

        {this.renderExperimental()}
      </div>
    )
  }

  renderExperimental() {
    const {
      intl: { formatMessage },
      experimentalFeatures,
    } = this.props

    if (!experimentalFeatures) {
      return null
    }

    const buttonContent = formatMessage(messages.experimentalLabel)
    const experimentalDisclaimerTitle = formatMessage(messages.experimentalDisclaimerTitle)

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <EuiAccordion
          id='region-settings-experimental'
          data-test-id='region-settings-experimental'
          buttonContent={buttonContent}
        >
          <EuiSpacer size='m' />

          <EuiCallOut title={experimentalDisclaimerTitle} color='warning' iconType='help'>
            <FormattedMessage tagName='p' {...messages.experimentalDisclaimer} />
          </EuiCallOut>

          <FormattedMessage tagName='p' {...messages.noExperimentalFeatures} />
        </EuiAccordion>
      </Fragment>
    )
  }
}

export default injectIntl(RegionSettings)
