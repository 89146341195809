/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, withErrorBoundary } from '@modules/cui'

import DangerButton from '../../DangerButton'

import type { FunctionComponent } from 'react'

interface Props {
  resendEmailVerificationLink: () => void
  resendEmailVerificationLinkRequest: AsyncRequestState
}

const messages = defineMessages({
  clearTransactionalEmailSuppressions: {
    id: `resent-verification-email-button.button-text`,
    defaultMessage: `Resend verification email`,
  },
  confirmModalTitle: {
    id: `resent-verification-email-button.confirm-title-text`,
    defaultMessage: `Resend verification?`,
  },
  confirmModalOkButton: {
    id: `resent-verification-email-button.confirm-ok-button-text`,
    defaultMessage: `Resend verification email`,
  },
})

const ResendVerificationEmail: FunctionComponent<Props> = ({
  resendEmailVerificationLink,
  resendEmailVerificationLinkRequest,
}) => (
  <Fragment>
    <DangerButton
      buttonType={EuiButtonEmpty}
      buttonProps={{ flush: 'left' }}
      size='s'
      color='primary'
      fill={false}
      onConfirm={resendEmailVerificationLink}
      spin={resendEmailVerificationLinkRequest.inProgress}
      modal={{
        title: <FormattedMessage {...messages.confirmModalTitle} />,
        confirmButtonText: <FormattedMessage {...messages.confirmModalOkButton} />,
      }}
    >
      <FormattedMessage {...messages.clearTransactionalEmailSuppressions} />
    </DangerButton>

    {resendEmailVerificationLinkRequest.error && (
      <Fragment>
        <EuiSpacer size='m' />

        <CuiAlert type='error'>{resendEmailVerificationLinkRequest.error}</CuiAlert>
      </Fragment>
    )}
  </Fragment>
)

export default withErrorBoundary(ResendVerificationEmail)
