/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import { v4 as uuid } from 'uuid'

import { getPhoneHomeDataUrl, getTelemetryConfigUrl } from '@modules/cloud-api/v1/urls'
import type { TelemetryConfigRequest } from '@modules/cloud-api/v1/types'
import { get, post } from '@modules/utils/ajax'

import {
  DISABLE_PHONE_HOME,
  FETCH_PHONE_HOME_ENABLED,
  UPDATE_PHONE_HOME_ENABLED,
} from '@/constants/actions'
import { getConfigForKey } from '@/selectors'

import asyncRequest from '../asyncRequests'

import type { ThunkDispatch, ThunkAction, Action } from '@/types/redux'

export const PHONE_HOME_STORAGE_KEY = `LAST_PHONE_HOME`

let isPhoningHome = false

export const updatePhoneHomeIsEnabled = (enabled: boolean) => {
  const payload: TelemetryConfigRequest = { enabled }
  return asyncRequest({
    type: UPDATE_PHONE_HOME_ENABLED,
    method: `PUT`,
    payload,
    url: getTelemetryConfigUrl(),
  })
}

export const fetchPhoneHomeIsEnabled = () =>
  asyncRequest({
    type: FETCH_PHONE_HOME_ENABLED,
    method: `GET`,
    url: getTelemetryConfigUrl(),
  })

export function runPhoneHomeIfNeeded(): ThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    if (isPhoningHome || !shouldPhoneHome()) {
      return Promise.resolve()
    }

    const baseTelemetryUrl = getConfigForKey(getState(), `TELEMETRY_URL`)

    isPhoningHome = true
    return runPhoneHomeData(baseTelemetryUrl, dispatch)
      .then(() => {
        isPhoningHome = false
        return
      })
      .catch((e) => {
        isPhoningHome = false
        throw e
      })
  }
}

function runPhoneHomeData(baseTelemetryUrl: string, dispatch: ThunkDispatch): Promise<void> {
  return (
    get(getPhoneHomeDataUrl())
      .then((response) =>
        post(`${baseTelemetryUrl}/ece/v2/phone-home`, {
          ...response.body,
          uuid: uuid(),
        }),
      )
      .then(() => localStorage.setItem(PHONE_HOME_STORAGE_KEY, new Date().toISOString()))

      // We just swallow this silently. It might error because of a 404, which means they don't have it enabled
      // or it could error because of a 500. In both cases we are unable to take action.
      .catch((e) => {
        dispatch(disablePhoneHome())
        throw e
      })
  )
}

function shouldPhoneHome(): boolean {
  const lastPhoneHome = localStorage.getItem(PHONE_HOME_STORAGE_KEY)

  if (lastPhoneHome == null) {
    return true
  }

  const yesterday = moment().subtract(1, `day`)
  return moment(lastPhoneHome).isBefore(yesterday)
}

const disablePhoneHome = (): Action<typeof DISABLE_PHONE_HOME> => ({
  type: DISABLE_PHONE_HOME,
  meta: {},
})
