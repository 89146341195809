/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  DeploymentCreateRequest,
  DeploymentObservabilitySettings,
} from '@modules/cloud-api/v1/types'
import type { StackDeployment } from '@modules/ui-types'

export function hasEnabledObservabilityTemplateOption({
  deploymentTemplate,
  option,
}: {
  deploymentTemplate: DeploymentCreateRequest
  option: 'logging' | 'metrics'
}): boolean {
  return Boolean(deploymentTemplate.settings?.observability?.[option]?.destination?.deployment_id)
}

export function hasEnabledMonitoring({ deployment }: { deployment: StackDeployment }): boolean {
  const { settings } = deployment

  const isMonitoring = settings?.observability?.metrics || settings?.observability?.logging

  if (!isMonitoring) {
    return false
  }

  return true
}

export function getObservabilityDeploymentId({
  observability,
}: {
  observability: DeploymentObservabilitySettings
}): string | undefined {
  if (!observability) {
    return
  }

  const monitoringDeploymentId = [
    observability.logging?.destination.deployment_id,
    observability.metrics?.destination.deployment_id,
  ].find(Boolean)

  return monitoringDeploymentId
}
