/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText, EuiIconTip } from '@elastic/eui'

import type {
  DeploymentSearchResponse,
  ElasticsearchClusterSettings,
  SearchRequest,
} from '@modules/cloud-api/v1/types'
import type {
  RestoreSnapshot,
  RegionId,
  StackDeploymentCreateRequest,
  ClusterSnapshot,
  VersionNumber,
} from '@modules/ui-types'
import { withErrorBoundary } from '@modules/cui'
import type { DeepPartial } from '@modules/ts-essentials'

import { searchDeploymentsQuery } from '@/lib/deploymentQuery'

import SelectVersion from '../SelectVersion'
import { getEsPlan, getRegionId } from '../../../../../../lib/stackDeployments/selectors'
import { getPlatform, getPlatformInfoById } from '../../../../../../lib/platform'

import SelectSnapshotSource from './SelectSnapshotSource'

type Props = {
  editorState: StackDeploymentCreateRequest
  onChangeSnapshot: (value?: ClusterSnapshot | null) => void
  setEsSettings: (settings: DeepPartial<ElasticsearchClusterSettings> | null) => void
  regionId: RegionId
  showRegion: boolean
  version: VersionNumber
  hasDefaultSnapshotRepository: boolean
  disabled: boolean
  cancelRestoreFromSnapshot: () => void
  onUpdateSnapshotSource: () => void
  availableVersions: VersionNumber[]
  whitelistedVersions: VersionNumber[]
  setVersion: (version: VersionNumber) => void
  onSearch: (request: SearchRequest) => void
  restoreFromSnapshot: boolean
  getRegionName: (regionId: RegionId) => string
  selectedSnapshotDeployment: DeploymentSearchResponse | null
  isUserconsole: boolean
}

type State = {
  pickingSnapshot: boolean
}

class SetupSnapshots extends Component<Props, State> {
  state: State = {
    pickingSnapshot: false,
  }

  static getDerivedStateFromProps(nextProps: Props): Partial<State> | null {
    const { editorState } = nextProps
    const { deployment } = editorState
    const esPlan = getEsPlan({ deployment })
    const snapshotRestoreSettings = esPlan?.transient?.restore_snapshot

    if (snapshotRestoreSettings) {
      return { pickingSnapshot: true }
    }

    return null
  }

  render() {
    const {
      version,
      showRegion,
      onUpdateSnapshotSource,
      onChangeSnapshot,
      regionId,
      editorState,
      availableVersions,
      whitelistedVersions,
      setVersion,
      disabled,
      restoreFromSnapshot,
      isUserconsole,
    } = this.props
    const { deployment } = editorState
    const esPlan = getEsPlan({ deployment })
    const snapshotRestoreSettingsSource = esPlan?.transient?.restore_snapshot

    const snapshotRestoreSettings: RestoreSnapshot | undefined =
      snapshotRestoreSettingsSource?.snapshot_name &&
      snapshotRestoreSettingsSource?.source_cluster_id
        ? {
            snapshot_name: snapshotRestoreSettingsSource?.snapshot_name,
            source_cluster_id: snapshotRestoreSettingsSource?.source_cluster_id,
          }
        : undefined

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <SelectSnapshotSource
          version={version}
          regionId={regionId}
          showRegion={showRegion}
          forceLastSnapshot={false}
          asRestoreForm={false}
          snapshotRestoreSettings={snapshotRestoreSettings}
          onUpdateSnapshotSource={onUpdateSnapshotSource}
          onSelectSnapshot={onChangeSnapshot}
          searchDeployments={(userInput: string) => this.searchDeployments(userInput)}
        />

        <SelectVersion
          version={version}
          availableVersions={availableVersions}
          whitelistedVersions={whitelistedVersions}
          setVersion={setVersion}
          disabled={disabled || !restoreFromSnapshot}
          regionId={regionId}
          editorState={editorState}
        />
        {isUserconsole && this.renderProviderAndRegion()}
      </Fragment>
    )
  }

  renderProviderAndRegion = () => {
    const { selectedSnapshotDeployment, getRegionName } = this.props
    const regionId = selectedSnapshotDeployment
      ? getRegionId({ deployment: selectedSnapshotDeployment })
      : null
    const regionName = regionId ? getRegionName(regionId) : null
    const selectedPlatform = getPlatform(regionId)
    const platform = selectedPlatform ? getPlatformInfoById(selectedPlatform) : null

    if (!selectedSnapshotDeployment || !platform) {
      return <EuiSpacer size='s' />
    }

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <EuiFlexGroup
          gutterSize='s'
          alignItems='center'
          responsive={false}
          data-test-subj='restoreFromSnapshot_providerRegionLabel'
          justifyContent='flexEnd'
        >
          <EuiFlexItem className='platform-icon' grow={false}>
            <EuiIcon size='m' type={platform.iconType} />
          </EuiFlexItem>

          <EuiFlexItem grow={false} className='platform-name'>
            <EuiText style={{ paddingLeft: 0 }} size='s'>
              {`${platform.shortTitle} ${regionName}`}
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiIconTip
              position='top'
              color='subdued'
              content={
                <FormattedMessage
                  id='restore-from-snapshot.tooltip'
                  defaultMessage='Your new deployment will be based on the same cloud provider and region as the deployment you restore your data from.'
                />
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  searchDeployments = (userInput: string) => {
    const { onSearch, version } = this.props

    const query = searchDeploymentsQuery({
      targetMajorVersions: (major) => [major, major - 1],
      searchValue: userInput,
      version,
    })

    onSearch(query)
  }
}

export default withErrorBoundary(SetupSnapshots)
