/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  createActiveDirectoryConfigurationUrl,
  deleteActiveDirectoryConfigurationUrl,
  getActiveDirectoryConfigurationUrl,
  updateActiveDirectoryConfigurationUrl,
} from '@modules/cloud-api/v1/urls'
import type { ActiveDirectorySettings } from '@modules/cloud-api/v1/types'
import type { RegionId } from '@modules/ui-types'

import {
  CREATE_ACTIVE_DIRECTORY_SECURITY_REALM,
  DELETE_ACTIVE_DIRECTORY_SECURITY_REALM,
  FETCH_ACTIVE_DIRECTORY_SETTINGS,
  UPDATE_ACTIVE_DIRECTORY_SECURITY_REALM,
} from '../constants/actions'
import asyncRequest from '../../../actions/asyncRequests'

export function fetchActiveDirectorySecurityRealm(regionId: RegionId, realmId: string) {
  const url = getActiveDirectoryConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: FETCH_ACTIVE_DIRECTORY_SETTINGS,
    url,
    meta: { regionId, realmId },
    crumbs: [regionId, realmId],
  })
}

export function createActiveDirectorySecurityRealm(
  regionId: RegionId,
  payload: ActiveDirectorySettings,
) {
  const url = createActiveDirectoryConfigurationUrl({ regionId })

  return asyncRequest({
    type: CREATE_ACTIVE_DIRECTORY_SECURITY_REALM,
    method: 'POST',
    url,
    payload,
    meta: { regionId },
    crumbs: [regionId],
  })
}

export function updateActiveDirectorySecurityRealm(
  regionId: RegionId,
  realmId: string,
  payload: ActiveDirectorySettings,
) {
  const url = updateActiveDirectoryConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: UPDATE_ACTIVE_DIRECTORY_SECURITY_REALM,
    method: 'PUT',
    url,
    payload,
    meta: { regionId, realmId },
    crumbs: [regionId, realmId],
  })
}

export function deleteActiveDirectorySecurityRealm(regionId: RegionId, realmId: string) {
  const url = deleteActiveDirectoryConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: DELETE_ACTIVE_DIRECTORY_SECURITY_REALM,
    method: 'DELETE',
    url,
    meta: { regionId, realmId },
    crumbs: [regionId],
  })
}
