/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { SaasUsersSearchResponseData } from '@modules/cloud-api/v1/types'
import type { BillingSubscriptionLevel } from '@modules/ui-types'
import { CuiAlert, CuiLink, CuiTable } from '@modules/cui'

import { organizationOverviewUrl, userOverviewUrl } from '@/lib/urlBuilder'
import OrganizationDomain from '@/components/Organization/OrganizationOverview/OrganizationDomain'
import { CustomerLevel } from '@/components/CustomerProfile'

import SpinButton from '../../SpinButton'

import type { FunctionComponent, FormEvent } from 'react'
import type { Props } from './types'

const SearchByUserEmail: FunctionComponent<Props> = (props) => {
  const {
    isPrivate,
    resetSearchUsers,
    resetSearchUsersRequest,
    searchUsers,
    searchUsersResults,
    searchUsersRequest,
    searchEmail,
    setSearchEmail,
  } = props

  // Call the reset function when this component unmounts.
  useEffect(() => resetSearchUsersRequest, [resetSearchUsersRequest])

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    return searchUsers(searchEmail)
  }

  const onReset = () => {
    resetSearchUsers()
    setSearchEmail('')
  }

  return (
    <EuiForm component='form' onSubmit={onSubmit}>
      <EuiFormRow label={<FormattedMessage id='users.email-label' defaultMessage='Email' />}>
        <EuiFieldText value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
      </EuiFormRow>

      <EuiSpacer size='m' />

      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <SpinButton type='submit' fill={true} spin={searchUsersRequest.inProgress}>
              <FormattedMessage id='users.search-button-text' defaultMessage='Search' />
            </SpinButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButtonEmpty type='button' onClick={onReset}>
              <FormattedMessage id='users.search-button-reset' defaultMessage='Reset' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiSpacer />

      {searchUsersRequest.error && <CuiAlert type='error'>{searchUsersRequest.error}</CuiAlert>}

      {searchUsersResults && (
        <CuiTable<SaasUsersSearchResponseData>
          rows={searchUsersResults}
          columns={[
            {
              label: <FormattedMessage id='users.table-email' defaultMessage='Email' />,
              render: (user) => (
                <CuiLink to={userOverviewUrl(String(user.user_id))}>{user.email}</CuiLink>
              ),
              sortKey: `email`,
            },
            {
              label: <FormattedMessage id='users.table-source-domain' defaultMessage='Domain' />,
              render: (user) => <OrganizationDomain domain={user.domain} />,
              sortKey: `domain`,
            },
            {
              label: (
                <FormattedMessage
                  id='users.table-subscription-level'
                  defaultMessage='Subscription'
                />
              ),
              render: (user) => (
                <CustomerLevel
                  isPrivate={isPrivate}
                  level={
                    // FIXME can I address this in the API?
                    user.level as BillingSubscriptionLevel
                  }
                />
              ),
              sortKey: `level`,
            },
            {
              label: (
                <FormattedMessage
                  id='users.table-subscription-attribbutes'
                  defaultMessage='Attributes'
                />
              ),
              render: (saasUser) =>
                saasUser.is_trial ? (
                  <EuiText size='s' color='success'>
                    <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
                      <EuiFlexItem grow={false}>
                        <EuiIcon type='clock' size='m' />
                      </EuiFlexItem>

                      <EuiFlexItem grow={false}>
                        <FormattedMessage id='users.in-trial' defaultMessage='Trial started' />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiText>
                ) : (
                  <EuiText color='subdued'>
                    <FormattedMessage id='users.no-attributes' defaultMessage='None' />
                  </EuiText>
                ),
              sortKey: `is_trial`,
            },
            {
              label: (
                <FormattedMessage
                  id='users.table-organization-id'
                  defaultMessage='Organization ID'
                />
              ),
              render: ({ organization_id }) =>
                organization_id ? (
                  <CuiLink to={organizationOverviewUrl(organization_id)}>{organization_id}</CuiLink>
                ) : (
                  '-'
                ),
              sortKey: `organization_id`,
            },
          ]}
        />
      )}
    </EuiForm>
  )
}

export default SearchByUserEmail
