/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { EuiCheckableCard, EuiTextColor, EuiText } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { DeploymentRole } from './types'

type Props = {
  isChecked: boolean
  isDisabled: boolean
  title: string
  description: string
  role: DeploymentRole
  onChangeAllDeploymentsRoleAssignment: (role: DeploymentRole) => void
}

const DeploymentRolePanel: FunctionComponent<Props> = ({
  isChecked,
  isDisabled,
  title,
  description,
  role,
  onChangeAllDeploymentsRoleAssignment,
}) => {
  const label = (
    <EuiText size='m'>
      <h5>{title}</h5>
      <EuiTextColor color='subdued'>
        <EuiText size='s'>{description}</EuiText>
      </EuiTextColor>
    </EuiText>
  )

  return (
    <EuiCheckableCard
      css={css({
        '.euiRadio.euiRadio--noLabel': {
          top: '35%',
        },
      })}
      disabled={isDisabled}
      id={role}
      label={label}
      name={title}
      value={role}
      checked={isChecked}
      onChange={() => {
        if (!isChecked) {
          onChangeAllDeploymentsRoleAssignment(role)
        }
      }}
    />
  )
}

export default DeploymentRolePanel
