/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment, Suspense, lazy } from 'react'

import { EuiOverlayMask } from '@elastic/eui'

import type { BillingSubscriptionLevel } from '@modules/ui-types/profile'

import CustomerOnlyAction from '@/components/User/CustomerOnlyAction'
import SubscriptionDetailsButton from '@/components/User/BillingSubscription/components/SubscriptionDetailsButton'

import type { ReactElement } from 'react'

const SelectSubscriptionModalLazy = lazy(
  () => import('@/apps/userconsole/components/Billing/SelectSubscription'),
)

const ReviewSubscriptionChangesModalLazy = lazy(
  () => import('@/apps/userconsole/components/Billing/ReviewSubscriptionChangesModal'),
)

interface State {
  isSelectSubscriptionModalOpen: boolean
  isUsageModalOpen: boolean
  upgradeLevel: BillingSubscriptionLevel | undefined
}

export interface Props {
  buttonText: ReactElement
  isButtonDisabled?: boolean
}

class SubscriptionManager extends Component<Props, State> {
  state = {
    isSelectSubscriptionModalOpen: false,
    isUsageModalOpen: false,
    upgradeLevel: undefined,
  }

  render() {
    const { isSelectSubscriptionModalOpen, isUsageModalOpen, upgradeLevel } = this.state
    const { buttonText, isButtonDisabled = false } = this.props

    return (
      <Fragment>
        <CustomerOnlyAction>
          <SubscriptionDetailsButton
            disabled={isButtonDisabled}
            onClick={() => this.setState({ isSelectSubscriptionModalOpen: true })}
          >
            {buttonText}
          </SubscriptionDetailsButton>
        </CustomerOnlyAction>

        {isSelectSubscriptionModalOpen && (
          <Suspense fallback={<EuiOverlayMask />} data-test-id='selectSubscriptionModal'>
            <SelectSubscriptionModalLazy
              upgradeLevel={upgradeLevel}
              closeModal={() =>
                this.setState({ isSelectSubscriptionModalOpen: false, upgradeLevel: undefined })
              }
              onSeeReviewChanges={() => {
                this.setState({ isSelectSubscriptionModalOpen: false, isUsageModalOpen: true })
              }}
            />
          </Suspense>
        )}

        {isUsageModalOpen && (
          <Suspense fallback={<EuiOverlayMask />} data-test-id='confirmSubscriptionModal'>
            <ReviewSubscriptionChangesModalLazy
              closeModal={() => this.setState({ isUsageModalOpen: false })}
              onUpgrade={(level) =>
                this.setState({
                  isUsageModalOpen: false,
                  isSelectSubscriptionModalOpen: true,
                  upgradeLevel: level,
                })
              }
            />
          </Suspense>
        )}
      </Fragment>
    )
  }
}

export default SubscriptionManager
