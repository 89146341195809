/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { StackVersionConfig } from '@modules/cloud-api/v1/types'
import type { Region, AsyncRequestState, VersionNumber, RegionId } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'
import { asAjaxRequestError } from '@modules/utils/ajax'
import Header from '@modules/cui/Header'

import DocLink from '../../DocLink'
import { elasticStackVersionsUrl } from '../../../lib/urlBuilder'
import { elasticStackVersionsCrumbs, elasticStackVersionCrumbs } from '../../../lib/crumbBuilder'

import VersionDetails from './VersionDetails'
import VersionsTable from './VersionsTable'
import UploadStackPackButton from './UploadStackPackButton'

import type { RouteComponentProps } from 'react-router'

import './elasticStackVersions.scss'

type StateProps = {
  addWhitelistedVersionRequest: AsyncRequestState
  currentVersion?: VersionNumber
  fetchWhitelistedVersionRequest: AsyncRequestState
  getDeleteVersionRequest: (regionId: RegionId, version: VersionNumber) => AsyncRequestState
  isWhitelistingEnabled: boolean
  region?: Region
  regionId: string
  removeWhitelistedVersionRequest: AsyncRequestState
  versions: StackVersionConfig[] | null
  versionsRequest: AsyncRequestState
}

type DispatchProps = {
  deleteVersion: () => Promise<any>
  fetchVersions: (regionId: string) => Promise<any>
  fetchWhitelistedVersions: (args: { regionId: string }) => Promise<any>
}

type QueryParams = {
  regionId: RegionId
  versionId?: VersionNumber
}

type ConsumerProps = RouteComponentProps<QueryParams>

export type Props = StateProps & DispatchProps & ConsumerProps

class ElasticStackVersions extends Component<Props> {
  render() {
    const { regionId, currentVersion } = this.props

    return (
      <Fragment>
        {currentVersion ? (
          <Header
            name={
              <FormattedMessage
                id='region-admin-elastic-stack-version.title'
                defaultMessage='Elastic Stack version'
              />
            }
            breadcrumbs={elasticStackVersionCrumbs({ regionId, versionId: currentVersion })}
          />
        ) : (
          <Header
            name={
              <FormattedMessage
                id='region-admin-elastic-stack-versions.title'
                defaultMessage='Elastic Stack versions'
              />
            }
            breadcrumbs={elasticStackVersionsCrumbs({ regionId })}
          />
        )}

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const {
      region,
      regionId,
      versions,
      versionsRequest,
      currentVersion,
      fetchVersions,
      deleteVersion,
      getDeleteVersionRequest,
      isWhitelistingEnabled,
    } = this.props

    if (versionsRequest.error) {
      return (
        <CuiAlert details={versionsRequest.error} type='error'>
          <FormattedMessage
            id='region-admin-elastic-stack-versions.failed-fetching-versions'
            defaultMessage='Failed fetching versions'
          />
        </CuiAlert>
      )
    }

    if (currentVersion) {
      return this.renderSingleVersionView()
    }

    return (
      <div>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiText>
              <FormattedMessage
                id='region-admin-elastic-stack-versions.description'
                defaultMessage='New versions of the Elastic Stack must be prepared to work with ECE and are provided as packs that you upload. {learnMore}'
                values={{
                  learnMore: (
                    <DocLink link='manageElasticStackDocLink'>
                      <FormattedMessage
                        id='region-admin-elastic-stack-versions.learn-more'
                        defaultMessage='Learn more …'
                      />
                    </DocLink>
                  ),
                }}
              />
            </EuiText>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <UploadStackPackButton regionId={regionId} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='l' />

        <VersionsTable
          versions={versions || undefined}
          region={region}
          isWhitelistingEnabled={isWhitelistingEnabled}
          fetchVersions={fetchVersions}
          deleteVersion={deleteVersion}
          getDeleteVersionRequest={getDeleteVersionRequest}
        />

        {this.renderErrorMessage()}
      </div>
    )
  }

  renderSingleVersionView() {
    const { regionId } = this.props

    return (
      <Fragment>
        {this.renderSingleVersionDetails()}

        <EuiSpacer size='l' />

        <div className='p'>
          <Link to={elasticStackVersionsUrl(regionId)}>
            <FormattedMessage
              id='region-admin-elastic-stack-versions.go-back'
              defaultMessage='Back to version list'
            />
          </Link>
        </div>
      </Fragment>
    )
  }

  renderSingleVersionDetails() {
    const { versions, currentVersion } = this.props
    const currentVersionDetails = versions?.find((v) => v.version === currentVersion)

    if (!versions) {
      return <EuiSkeletonText />
    }

    if (!currentVersionDetails) {
      return (
        <CuiAlert type='error'>
          <FormattedMessage
            id='region-admin-elastic-stack-versions.version-not-found'
            defaultMessage='Version not found'
          />
        </CuiAlert>
      )
    }

    return <VersionDetails version={currentVersionDetails} />
  }

  renderErrorMessage() {
    const {
      fetchWhitelistedVersionRequest,
      addWhitelistedVersionRequest,
      removeWhitelistedVersionRequest,
    } = this.props
    let requestError

    for (const errorRequest of [
      fetchWhitelistedVersionRequest,
      addWhitelistedVersionRequest,
      removeWhitelistedVersionRequest,
    ]) {
      if (errorRequest.error) {
        requestError = asAjaxRequestError(errorRequest.error)?.body?.errors?.map(
          ({ message }) => message,
        )
        break
      }
    }

    if (!requestError) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <EuiCallOut
          title={
            <FormattedMessage
              id='region-admin-elastic-stack-versions.connection-error'
              defaultMessage='Request Error'
            />
          }
          color='danger'
          iconType='cross'
        >
          {requestError}
        </EuiCallOut>
      </Fragment>
    )
  }
}

export default ElasticStackVersions
