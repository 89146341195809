/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { StackVersionConfig } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, VersionNumber, Region, RegionId } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import {
  fetchVersions,
  deleteVersion,
  fetchWhitelistedVersions,
} from '../../../actions/elasticStack'
import {
  addWhitelistedVersionRequest,
  deleteStackVersionRequest,
  fetchVersionsRequest,
  fetchWhitelistedVersionRequest,
  getRegion,
  getActiveVersionStacks,
  removeWhitelistedVersionRequest,
} from '../../../reducers'
import { isFeatureActivated } from '../../../selectors'
import withPolling from '../../../lib/withPolling'

import ElasticStackVersions from './ElasticStackVersions'

import type { ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  addWhitelistedVersionRequest: AsyncRequestState
  currentVersion?: VersionNumber
  fetchWhitelistedVersionRequest: AsyncRequestState
  getDeleteVersionRequest: (regionId: RegionId, version: VersionNumber) => AsyncRequestState
  isWhitelistingEnabled: boolean
  region?: Region
  regionId: string
  removeWhitelistedVersionRequest: AsyncRequestState
  versions: StackVersionConfig[] | null
  versionsRequest: AsyncRequestState
}

type DispatchProps = {
  deleteVersion: (version: string, region: Region) => void
  fetchVersions: (regionId: string) => void
  fetchWhitelistedVersions: (args: { regionId: string }) => void
}

type QueryParams = {
  regionId: RegionId
  versionId?: VersionNumber
}

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (state: ReduxState, { match: { params } }: ConsumerProps): StateProps => ({
  addWhitelistedVersionRequest: addWhitelistedVersionRequest(state, params.regionId),
  currentVersion: params.versionId,
  fetchWhitelistedVersionRequest: fetchWhitelistedVersionRequest(state, params.regionId),
  getDeleteVersionRequest: (regionId, versionId) =>
    deleteStackVersionRequest(state, regionId, versionId),
  isWhitelistingEnabled: isFeatureActivated(state, Feature.whitelistingStack),
  region: getRegion(state, params.regionId),
  regionId: params.regionId,
  removeWhitelistedVersionRequest: removeWhitelistedVersionRequest(state, params.regionId),
  versions: getActiveVersionStacks(state, params.regionId),
  versionsRequest: fetchVersionsRequest(state, params.regionId),
})

const pollingComponent = withPolling<ConsumerProps>(
  ElasticStackVersions,
  // eslint-disable-next-line no-shadow
  ({ fetchVersions, fetchWhitelistedVersions, isWhitelistingEnabled, match: { params } }) => ({
    onPoll: [
      () => fetchVersions(params.regionId),
      () =>
        isWhitelistingEnabled
          ? fetchWhitelistedVersions({ regionId: params.regionId })
          : Promise.resolve(),
    ],
    pollImmediately: [[`match`, `params`, `regionId`]],
  }),
)

const mapDispatchToProps: DispatchProps = {
  deleteVersion,
  fetchWhitelistedVersions,
  fetchVersions,
}

export default connect<StateProps, DispatchProps, ConsumerProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps,
)(pollingComponent)
