/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import { CuiFilterContext, getFilterEsQuery, nullQueryExecutor } from '@modules/cui'
import type { ControlledFilterQuery, OnFilterChangeParams } from '@modules/cui'

import { getSchema } from './schema'
import { getFilters } from './filters'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent, ReactNode, ReactElement } from 'react'

import './activityFeedFilterContext.scss'

const messages = defineMessages({
  searchBarPlaceholder: {
    id: `activity-feed-filter-context.placeholder`,
    defaultMessage: `e.g.: pending:y apm`,
  },
})

type Props = WrappedComponentProps & {
  query: ControlledFilterQuery
  onChange: (params: OnFilterChangeParams<DeploymentSearchResponse>) => void
  deployments: DeploymentSearchResponse[]
  isLoading: boolean
  fetchRegionList: () => void
  actions?: ReactElement | null
  tools?: ReactNode[] | null
  toolsLeft?: ReactNode[] | null
}

const ActivityFeedFilterContextImpl: FunctionComponent<Props> = ({
  intl,
  query,
  onChange,
  deployments,
  isLoading,
  fetchRegionList,
  actions,
  tools,
  toolsLeft,
}) => {
  const { formatMessage } = intl

  const { schema } = getSchema()

  const filters = getFilters({
    intl,
    fetchRegionList,
  })

  const placeholder = formatMessage(messages.searchBarPlaceholder)

  return (
    <CuiFilterContext<DeploymentSearchResponse>
      query={query}
      onChange={onChange}
      records={deployments}
      schema={schema}
      filters={filters}
      executeQuery={nullQueryExecutor}
      placeholder={placeholder}
      isLoading={isLoading}
      incremental={false}
      actions={actions}
      tools={tools}
      toolsLeft={toolsLeft}
    />
  )
}

const ActivityFeedFilterContext = injectIntl(ActivityFeedFilterContextImpl)

export default ActivityFeedFilterContext

export function getEsQuery(query) {
  const { schema } = getSchema()
  return getFilterEsQuery({ query, schema })
}
