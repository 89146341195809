/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from '@elastic/eui'

import { CuiAlert } from '@modules/cui'
import history from '@modules/utils/history'

import { userOverviewUrl } from '@/lib/urlBuilder'

import SpinButton from '../../SpinButton'

import type { FunctionComponent, FormEvent } from 'react'
import type { Props } from './types'

const messages = defineMessages({
  userId: {
    id: 'users.user-id-label',
    defaultMessage: 'ID',
  },
  userNotFound: {
    id: `users.user-not-found`,
    defaultMessage: `No users match your query.`,
  },
  search: {
    id: 'users.search-button-text',
    defaultMessage: 'Search',
  },
})

const SearchByUserId: FunctionComponent<Props> = (props) => {
  const { fetchSaasUser, fetchSaasUserRequest, searchId, setSearchId } = props

  const [noResults, setNoResults] = useState(false)

  const requestState = fetchSaasUserRequest(searchId)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    // Try to fetch the user. If we get a successful response, navigate to the associate page
    fetchSaasUser(searchId)
      .then(() => history.push(userOverviewUrl(searchId)))
      .catch(() => setNoResults(true))
  }

  return (
    <EuiForm component='form' onSubmit={onSubmit}>
      <EuiFormRow label={<FormattedMessage {...messages.userId} />}>
        <EuiFieldText value={searchId} onChange={(e) => setSearchId(e.target.value)} />
      </EuiFormRow>

      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <SpinButton
              fill={true}
              disabled={searchId.trim().length === 0}
              spin={requestState.inProgress}
              type='submit'
            >
              <FormattedMessage {...messages.search} />
            </SpinButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButtonEmpty type='button' onClick={() => setSearchId('')}>
              <FormattedMessage id='users.search-button-reset' defaultMessage='Reset' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      {requestState.error && (
        <Fragment>
          <EuiSpacer />
          <CuiAlert type='error'>{requestState.error}</CuiAlert>
        </Fragment>
      )}

      {!requestState.error && requestState.isDone && noResults && (
        <Fragment>
          <EuiSpacer />
          <CuiAlert type='warning' iconType='alert'>
            <FormattedMessage {...messages.userNotFound} />
          </CuiAlert>
        </Fragment>
      )}
    </EuiForm>
  )
}

export default SearchByUserId
