/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import { filter } from 'lodash'

import type { EuiComboBoxOptionOption } from '@elastic/eui'
import { EuiFormControlLayout, EuiComboBox, EuiHealth, EuiFormLabel } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import type { ClusterSnapshot, AsyncRequestState } from '@modules/ui-types'

import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors'

import { getSnapshotHealthColor } from '../../../../../../Cluster/Snapshots/SnapshotHealth'

import type { IntlShape } from 'react-intl'

type Props = {
  fetchSnapshotsRequest: AsyncRequestState | null
  intl: IntlShape
  selectedDeployment: DeploymentSearchResponse | null
  onSelectSnapshot: (snapshot: ClusterSnapshot | null, id: string) => void
  searchResults?: ClusterSnapshot[] | null
  onRestoreSnapshot?: (payload: unknown) => void
  restoreSnapshotRequest?: AsyncRequestState
  onUpdateIndexRestore?: (payload: unknown) => void
  selectedSnapshot?: string
}

const messages = defineMessages({
  placeholder: {
    id: `snapshot-restore-from-remote.placeholder-latest-success`,
    defaultMessage: `Select snapshot (optional)`,
  },
  buttonMessage: {
    id: `snapshot-restore-from-remote.button`,
    defaultMessage: `restore snapshot`,
  },
})

class SelectSnapshot extends Component<Props> {
  render() {
    const {
      fetchSnapshotsRequest,
      intl: { formatMessage },
      selectedDeployment,
    } = this.props

    const { options, selectedOptions } = this.buildOptions()

    return (
      <EuiFormControlLayout
        fullWidth={true}
        prepend={
          <EuiFormLabel style={{ width: `180px` }}>
            <FormattedMessage defaultMessage='Snapshot' id='select-snapshot-label' />
          </EuiFormLabel>
        }
      >
        <EuiComboBox
          fullWidth={true}
          isDisabled={selectedDeployment == null}
          async={true}
          isLoading={fetchSnapshotsRequest !== null && fetchSnapshotsRequest.inProgress}
          onChange={([selectedOption]) => this.onSelectSnapshot(selectedOption)}
          options={options}
          selectedOptions={selectedOptions}
          placeholder={formatMessage(messages.placeholder)}
          singleSelection={{ asPlainText: true }}
          renderOption={this.renderSnapshot}
          data-test-id='select-snapshot-combo'
        />
      </EuiFormControlLayout>
    )
  }

  renderSnapshot = (snapshot) => (
    <EuiHealth color={getSnapshotHealthColor({ state: snapshot.state })}>
      {snapshot.label}
    </EuiHealth>
  )

  onSelectSnapshot(selectedOption) {
    const { selectedDeployment, onSelectSnapshot } = this.props

    if (!selectedDeployment) {
      return
    }

    const firstEs = getFirstEsClusterFromGet({ deployment: selectedDeployment })

    if (!firstEs) {
      return
    }

    onSelectSnapshot(selectedOption as ClusterSnapshot, firstEs.id)
  }

  buildOptions() {
    const { searchResults, selectedSnapshot } = this.props

    if (searchResults == null) {
      return {
        options: [],
        selectedOptions: [],
      }
    }

    const successfulSnapshots = filter(searchResults, (el) => el.state === `SUCCESS`)

    const options: Array<EuiComboBoxOptionOption<ClusterSnapshot>> = successfulSnapshots.map(
      (el) => ({
        ...el,
        include_global_state: el.include_global_state
          ? el.include_global_state.toString()
          : undefined,
        label: el.snapshot,
      }),
    )

    const selectedOptions = getSelectedOptions()

    return {
      options,
      selectedOptions,
    }

    function getSelectedOptions() {
      if (!selectedSnapshot) {
        return []
      }

      return options.filter((option) => option.label === selectedSnapshot)
    }
  }
}

export default injectIntl(SelectSnapshot)
