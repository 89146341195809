/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'
import { fetchUserOktaGroups, postUserOktaGroups } from '@/apps/adminconsole/actions'
import { fetchUserOktaGroupsRequest, postUserOktaGroupsRequest } from '@/apps/adminconsole/reducers'

import OktaGroups from './OktaGroups'

import type { DispatchProps, StateProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { userId }): StateProps => ({
  fetchUserOktaGroupsRequest: fetchUserOktaGroupsRequest(state, userId),
  postUserOktaGroupsRequest: postUserOktaGroupsRequest(state, userId),
  groups: state.oktaGroups?.[userId],
})

const mapDispatchToProps = (dispatch, { userId }: ConsumerProps): DispatchProps => ({
  fetchUserOktaGroups: () => dispatch(fetchUserOktaGroups({ userId })),
  postUserOktaGroups: () => dispatch(postUserOktaGroups({ userId, groupName: 'Support' })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OktaGroups)
