/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { isEqual } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Component } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, withEuiTheme } from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import { CuiAlert } from '@modules/cui'
import type { OnFilterChangeParams } from '@modules/cui'

import {
  convertRoleAssignmentsToDeploymentRows,
  filterDeploymentRoleAssignmentByAll,
} from '@/components/Users/RoleAssignmentsPanel/lib'
import { DeploymentRole } from '@/components/Users/RoleAssignmentsPanel/types'

import searchDeploymentsQuery from '../../../../StackDeploymentSearch/searchDeploymentsQuery'
import SpecificDeploymentsFilterContext from '../../../../Users/RoleAssignmentsPanel/SpecificDeploymentsRoles/SpecificDeploymentsFilterContext'
import { getEsQuery } from '../../../../StackDeploymentSearch/DeploymentFilterContext'

import PermissionsDeploymentsTable from './PermissionsDeploymentsTable'

import type { DeploymentRow } from '@/components/Users/RoleAssignmentsPanel/SpecificDeploymentsRoles/types'
import type { ReactElement } from 'react'
import type { AllProps as Props, State } from './types'

interface PropsWithTheme extends Props, WithEuiThemeProps {}

class SpecificDeploymentsPanel extends Component<PropsWithTheme> {
  state: State = {
    esQuery: null,
    query: '',
  }

  componentDidUpdate(_oldProps: Props, oldState: State) {
    const { esQuery } = this.state

    if (!isEqual(esQuery, oldState.esQuery)) {
      this.props.searchDeployments(searchDeploymentsQuery({ esQuery }))
    }
  }

  componentWillUnmount(): void {
    this.props.resetDeploymentsSearchRequest()
  }

  render(): ReactElement {
    const { organizationId, deployments, searchDeploymentsRequest, roleAssignments, theme } =
      this.props

    const { query } = this.state

    const specificDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
      roleAssignments.deployment,
      false,
    )

    const count = specificDeploymentsRoleAssignments?.reduce(
      (total, { deployment_ids: deploymentIds = [] }) => total + deploymentIds.length,
      0,
    )

    const deploymentRows: DeploymentRow[] = convertRoleAssignmentsToDeploymentRows(
      this.props.deployments,
      this.props.roleAssignments,
    ).filter(({ roleId }) => roleId !== DeploymentRole.NO_ACCESS)

    return (
      <EuiFlexGroup
        direction='column'
        gutterSize='s'
        css={css({
          margin: `${theme.euiTheme.size.l} 0 0 -${theme.euiTheme.size.m}`,
          borderRadius: theme.euiTheme.border.radius.medium,
          padding: theme.euiTheme.size.base,
        })}
      >
        <EuiFlexItem>
          <FormattedMessage
            id='organization.view-members-flyout.specific-deployments-table.description'
            defaultMessage='This member has access to {count} {count, plural, one {deployment} other {deployments}}.'
            values={{ count }}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <SpecificDeploymentsFilterContext
            query={query}
            onChange={this.onChangeSearchDeployments}
            deployments={deployments}
            isLoading={searchDeploymentsRequest.inProgress}
          />
        </EuiFlexItem>

        {searchDeploymentsRequest.error && (
          <EuiFlexItem>
            <EuiSpacer size='l' />

            <CuiAlert type='error'>{searchDeploymentsRequest.error}</CuiAlert>
          </EuiFlexItem>
        )}

        <EuiFlexItem>
          <PermissionsDeploymentsTable
            organizationId={organizationId}
            isLoading={searchDeploymentsRequest.inProgress}
            deploymentRows={deploymentRows}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  onChangeSearchDeployments = ({
    query,
    queryText,
  }: OnFilterChangeParams<DeploymentSearchResponse>) => {
    this.setState({
      esQuery: getEsQuery(query),
      query: queryText,
    })
  }
}

export default withEuiTheme(SpecificDeploymentsPanel)
