/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch } from '@/types/redux'
import { getCommonProfile } from '@/reducers/profile'
import { getPrepaidBalanceLineItems } from '@/reducers'
import withBillingDetails from '@/lib/withBillingDetails'

import { fetchUpdateBillingLevelRequest, getAccountActivity } from '../../../../reducers'
import { resetUpdateBillingLevel } from '../../../../actions/account'

import SelectSubscriptionBody from './SelectSubscriptionBody'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state): StateProps => {
  const profile = getCommonProfile(state)

  return {
    profile,
    activity: getAccountActivity(state)!,
    prepaidBalanceLineItems:
      (profile && getPrepaidBalanceLineItems(state, profile.organization_id!)) || [],
    updateBillingLevelRequest: fetchUpdateBillingLevelRequest(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  resetUpdateBillingLevel: () => dispatch(resetUpdateBillingLevel()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withBillingDetails(SelectSubscriptionBody))
