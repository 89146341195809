/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText, EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiLink, EuiIcon } from '@elastic/eui'

import { CuiAlert } from '@modules/cui'

import { colorForDeployment } from '@/lib/theme'

import ErrorCallout from '../../ErrorCallout'
import FiltersPanel from '../components/Filters'
import BillingUsageOverview from '../BillingUsageOverview'
import BillingUsageByDeployment from '../BillingUsageByDeployment'

import { defaultFilters, stringifyFilters } from './utils'

import type { Filters } from '../types'
import type { AllProps } from './types'

import './billingUsageMainPage.scss'

class BillingUsageMainPage extends Component<AllProps> {
  componentDidMount() {
    const { fetchDeployments } = this.props

    fetchDeployments()
    this.setFilters()
  }

  render() {
    const {
      deployments,
      deploymentsRequest,
      organizationId,
      fetchDeployments,
      filters: { selectedDeploymentId },
    } = this.props

    const selectedDeployment = deployments.find(({ id }) => id === selectedDeploymentId)
    const shouldShowMissingDeploymentError =
      !deploymentsRequest.inProgress && selectedDeploymentId && !selectedDeployment

    if (deploymentsRequest.error) {
      // Because Chart and Table relay on global deployments list we cannot display any information
      return <ErrorCallout onReload={fetchDeployments} />
    }

    return (
      <Fragment>
        <FormattedMessage id='billing-usage.usage-report' defaultMessage='Usage report for' />

        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiText size='m' data-test-id='billing-usage.page-title'>
              <h3>{this.getPageTitle()}</h3>
            </EuiText>
          </EuiFlexItem>

          {selectedDeploymentId && (
            <EuiFlexItem grow={false}>
              <EuiLink
                onClick={this.showAllDeployments}
                data-test-id='back-to-all-deployments-link'
              >
                <EuiIcon type='returnKey' />{' '}
                <FormattedMessage
                  id='billing-usage.back-to-all-deployments-link'
                  defaultMessage='Back to deployments'
                />
              </EuiLink>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        <EuiSpacer size='l' />

        <FiltersPanel
          {...this.props.filters}
          deployments={deployments}
          onFilterChange={this.setFilters}
          isLoading={deploymentsRequest.inProgress}
          resetFilters={this.resetFilters}
          organizationId={organizationId}
          data-test-id='billing-usage.filters'
        />

        <EuiSpacer size='l' />

        {!selectedDeploymentId && (
          <BillingUsageOverview
            onDeploymentSelected={this.setFilters}
            deployments={deployments}
            organizationId={organizationId}
            isLoading={deploymentsRequest.inProgress}
            data-test-id='billing-usage.overview'
            {...this.props.filters}
          />
        )}

        {selectedDeployment && (
          <BillingUsageByDeployment
            deploymentFromSearch={selectedDeployment}
            organizationId={organizationId}
            data-test-id='billing-usage.view-by-deployment'
            {...this.props.filters}
          />
        )}

        {shouldShowMissingDeploymentError && (
          <CuiAlert type='error' data-test-id='missing-deployment-error'>
            <FormattedMessage
              id='billing-usage.missing-deployment-error'
              defaultMessage="Couldn't find the requested deployment. {link}"
              values={{
                link: (
                  <EuiLink onClick={this.showAllDeployments}>
                    <FormattedMessage
                      id='billing-usage.back-to-deployments-link-error'
                      defaultMessage='Go back to all deployments'
                    />
                  </EuiLink>
                ),
              }}
            />
          </CuiAlert>
        )}
      </Fragment>
    )
  }

  getPageTitle = () => {
    const { selectedDeploymentId } = this.props.filters
    const { deployments } = this.props

    if (selectedDeploymentId) {
      return deployments.find(({ id }) => id === selectedDeploymentId)?.name || selectedDeploymentId
    }

    return (
      <FormattedMessage
        id='billing-usage.all-deployments'
        defaultMessage='All deployments'
        data-test-id='all-deployments-title'
      />
    )
  }

  setFilters = (filters: Partial<Filters> = {}): void => {
    colorForDeployment.reset()
    const { history } = this.props
    const queryString = stringifyFilters({ ...this.props.filters, ...filters })
    history.replace(`${history.location.pathname}?${queryString}`)
  }

  resetFilters = (): void => this.setFilters(defaultFilters)

  showAllDeployments = (): void => this.setFilters({ selectedDeploymentId: '' })
}

export default BillingUsageMainPage
