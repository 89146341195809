/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import { EuiDescribedFormGroup, EuiSpacer } from '@elastic/eui'

import { CuiFormCheckbox } from '@modules/cui/forms'
import { CuiCodeEditor, CuiTitleWithBadge } from '@modules/cui'

import DocLink from '../../../../../../../components/DocLink'
import { labels, messages, sections } from '../../authProviderMessages'

import type { FunctionComponent } from 'react'

interface Props {
  onChange: (value: string) => void
}

const AdvancedConfiguration: FunctionComponent<Props> = ({ onChange }) => (
  <EuiDescribedFormGroup
    className='euiDescribedFormGroup__fieldPadding--none'
    title={
      <CuiTitleWithBadge>
        <FormattedMessage {...sections.advancedConfiguration} />
      </CuiTitleWithBadge>
    }
    description={
      <FormattedMessage
        {...sections.advancedConfigurationHelpText}
        values={{
          learnMore: (
            <DocLink link='samlProviderAdvancedDocLink'>
              <FormattedMessage {...messages.learnMore} />
            </DocLink>
          ),
        }}
      />
    }
  >
    <Field name='advanced_settings_yaml'>
      {({ field: { value } }) => (
        <CuiCodeEditor
          language='yaml'
          height='160px' /* 10 lines worth @ 16px */
          value={value}
          onChange={onChange}
        />
      )}
    </Field>

    <EuiSpacer size='m' />

    <CuiFormCheckbox
      id='saml-auth-provider-use-single-logout-checkbox'
      label={<FormattedMessage {...labels.useSingleLogout} />}
      name='use_single_logout'
    />

    <EuiSpacer size='s' />

    <CuiFormCheckbox
      id='saml-auth-provider-enable-force-authentication-checkbox'
      label={<FormattedMessage {...labels.enableForceAuthentication} />}
      name='force_authn'
    />
  </EuiDescribedFormGroup>
)

export default AdvancedConfiguration
