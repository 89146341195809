/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { LdapSettings } from '@modules/cloud-api/v1/types'

import { createLdapSecurityRealm, fetchSecurityCluster } from '../../../../../actions'
import { createLdapSecurityRealmRequest } from '../../../../../reducers'

import AddLdapProvider from './AddLdapProvider'

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId },
    },
  },
) => ({
  regionId,
  submitRequest: createLdapSecurityRealmRequest(state, regionId),
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId },
    },
  },
) => ({
  onSubmit: (payload: LdapSettings) =>
    dispatch(createLdapSecurityRealm(regionId, payload)).then(() =>
      dispatch(fetchSecurityCluster(regionId)),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddLdapProvider)
