/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import type { IconType } from '@elastic/eui'
import {
  EuiSkeletonText,
  EuiButtonEmpty,
  EuiIcon,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui'

import { CuiAlert } from '@modules/cui'
import history from '@modules/utils/history'

import { createDeploymentTrustRelationshipUrl, securityUrl } from '@/lib/urlBuilder'
import { getConfigForKey } from '@/store'

import { getTargetEnvironmentType } from '../helpers'

import ReusableTrustRelationships from './ReusableTrustRelationships'

import type { RelationshipType } from '../types'
import type { MessageDescriptor, WrappedComponentProps } from 'react-intl'
import type { AllProps } from './types'

type CardData = {
  value: RelationshipType
  icon: IconType
  messages: { title: MessageDescriptor; description: MessageDescriptor }
}

type State = {
  selected: RelationshipType | null
  previouslyUsedEnvName: string | null
  createNewTrust: boolean
}

const essCard: CardData = {
  value: 'ess',
  icon: 'logoCloud',
  messages: defineMessages({
    title: { id: 'deploymentTrustManagement.create.ess.title', defaultMessage: 'Elastic Cloud' },
    description: {
      id: 'deploymentTrustManagement.create.ess.description',
      defaultMessage: 'Trust deployments from an Elastic Cloud organization.',
    },
  }),
}

const eceCard: CardData = {
  value: 'ece',
  icon: 'logoCloudEnterprise',
  messages: defineMessages({
    title: {
      id: 'deploymentTrustManagement.create.ece.title',
      defaultMessage: 'Elastic Cloud Enterprise',
    },
    description: {
      id: 'deploymentTrustManagement.create.ece.description',
      defaultMessage: 'Trust deployments from an Elastic Cloud Enterprise environment.',
    },
  }),
}

const onPremCard: CardData = {
  value: 'self-managed',
  icon: 'logoElasticsearch',
  messages: defineMessages({
    title: { id: 'deploymentTrustManagement.create.onPrem.title', defaultMessage: 'Self-managed' },
    description: {
      id: 'deploymentTrustManagement.create.onPrem.description',
      defaultMessage:
        'Trust Elasticsearch clusters or Elasticsearch nodes from a self-managed Elastic environment.',
    },
  }),
}

class CreateDeploymentTrustRelationship extends React.Component<
  AllProps & WrappedComponentProps,
  State
> {
  state: State = {
    selected: null,
    previouslyUsedEnvName: null,
    createNewTrust: false,
  }

  componentDidMount() {
    const { fetchTrustedEnvs } = this.props
    fetchTrustedEnvs()
  }

  render() {
    const { deployment, trustedEnvs, fetchTrustedEnvsRequest } = this.props

    const cards: CardData[] = [essCard, eceCard, onPremCard]

    const showCreateNewComponent = isEmpty(trustedEnvs) || this.state.createNewTrust

    if (fetchTrustedEnvsRequest.inProgress || !trustedEnvs) {
      return (
        <Fragment>
          <EuiSkeletonText />
          <EuiSpacer />
        </Fragment>
      )
    }

    return (
      <div>
        {this.renderReusableTrustComponent()}

        {showCreateNewComponent && (
          <div data-test-id='create-deployment-trust-relationship-create-new-options'>
            <EuiFlexGroup gutterSize='l'>
              {cards.map((card, i) => (
                <EuiFlexItem key={i} style={{ maxWidth: `22rem` }}>
                  <EuiCard
                    selectable={{
                      onClick: () => this.setState({ selected: card.value }),
                      isSelected: this.state.selected === card.value,
                    }}
                    icon={<EuiIcon type={card.icon} size='xl' />}
                    title={<FormattedMessage {...card.messages.title} />}
                    description={<FormattedMessage {...card.messages.description} />}
                  />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>

            <EuiSpacer size='xxl' />

            <EuiFlexGroup justifyContent='flexStart'>
              <EuiFlexItem grow={false}>
                <EuiButton
                  type='button'
                  data-test-id='save-trust-relationship-button'
                  disabled={this.state.selected === null}
                  onClick={this.onNext}
                  fill={true}
                >
                  <FormattedMessage
                    id='deploymentTrustManagement.create.next'
                    defaultMessage='Next'
                  />
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={() => history.push(securityUrl(deployment.id))}>
                  <FormattedMessage id='deploymentTrustManagement.cancel' defaultMessage='Cancel' />
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        )}
      </div>
    )
  }

  renderReusableTrustComponent() {
    const { trustedEnvs, fetchTrustedEnvsRequest } = this.props

    if (fetchTrustedEnvsRequest.error) {
      return (
        <CuiAlert type='danger' data-test-id='reusable-trust-relationship.error'>
          {fetchTrustedEnvsRequest.error}
        </CuiAlert>
      )
    }

    if (isEmpty(trustedEnvs)) {
      return null
    }

    return (
      <Fragment>
        <ReusableTrustRelationships
          onSelected={(value) => this.onSelectedPreviouslyCreatedEnv(value)}
          trustedEnvs={trustedEnvs}
        />

        <EuiSpacer size='l' />

        <EuiButton
          onClick={() => this.setState({ createNewTrust: true })}
          data-test-id='create-deployment-trust-relationship-show-new-button'
        >
          <FormattedMessage
            id='deploymentTrustManagement.create.intro'
            defaultMessage='Trust a new environment'
          />
        </EuiButton>

        <EuiSpacer size='l' />
      </Fragment>
    )
  }

  onSelectedPreviouslyCreatedEnv(value) {
    if (!value) {
      return
    }

    this.setState(
      {
        selected: getTargetEnvironmentType(value),
        previouslyUsedEnvName: value.name,
      },
      () => {
        this.onNext()
      },
    )
  }

  onNext = () => {
    const destination = this.getDestination()

    if (!destination) {
      return
    }

    if (this.state.previouslyUsedEnvName) {
      return history.push(
        `${destination}?type=${this.state.selected}&previouslyUsedEnv=${this.state.previouslyUsedEnvName}`,
      )
    }

    history.push(`${destination}?type=${this.state.selected}`)
  }

  getDestination = (): string | null => {
    const { deployment } = this.props
    const isEce = getConfigForKey(`APP_PLATFORM`) === 'ece'

    switch (this.state.selected) {
      case 'ess':
        return isEce
          ? createDeploymentTrustRelationshipUrl(deployment.id, 'direct')
          : createDeploymentTrustRelationshipUrl(deployment.id, 'accounts')
      case 'ece':
        return isEce
          ? createDeploymentTrustRelationshipUrl(deployment.id, 'external')
          : createDeploymentTrustRelationshipUrl(deployment.id, 'direct')
      case 'self-managed':
        return createDeploymentTrustRelationshipUrl(deployment.id, 'direct')
      default:
        return null
    }
  }
}

export default CreateDeploymentTrustRelationship
