/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  admin: {
    id: 'roles-assignments.admin',
    defaultMessage: 'Admin',
  },
  adminDescription: {
    id: 'roles-assignments.admin.description',
    defaultMessage:
      'Can create, edit, view, delete data and manage the configuration of a deployment.',
  },
  editor: {
    id: 'roles-assignments.editor',
    defaultMessage: 'Editor',
  },
  editorDescription: {
    id: 'roles-assignments.editor.description',
    defaultMessage: 'Can create, edit, view and delete data.',
  },
  viewer: {
    id: 'roles-assignments.viewer',
    defaultMessage: 'Viewer',
  },
  viewerDescription: {
    id: 'roles-assignments.viewer.description',
    defaultMessage: 'Can view and operate on existing data.',
  },
  noAccess: {
    id: 'roles-assignments.no-access',
    defaultMessage: 'No access',
  },
  noAccessDescription: {
    id: 'roles-assignments.no-access.description',
    defaultMessage: "Can't view and access the deployment.",
  },
  resetSelection: {
    id: 'roles-assignments.reset-selection',
    defaultMessage: 'Reset selection',
  },
})

export default messages
