/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiButtonIcon } from '@elastic/eui'

import type { InnerClause, OuterClause } from '@modules/ui-types/instanceConfigurationTypes'

import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = {
  intl: IntlShape
  outerClause: OuterClause
  innerClause?: InnerClause
  deleteClause: (outerClause: OuterClause, innerClause?: InnerClause) => void
}

const messages = defineMessages({
  removeClause: {
    id: `instance-configuration-filter-allocators.remove-clause`,
    defaultMessage: `Remove`,
  },
})

const ClauseRemover: FunctionComponent<Props> = ({
  intl,
  outerClause,
  innerClause,
  deleteClause,
}) => {
  const label = intl.formatMessage(messages.removeClause)

  return (
    <div className='topologyFilterAllocators-removeClause'>
      <EuiButtonIcon
        onClick={() => deleteClause(outerClause, innerClause)}
        iconType='cross'
        color='danger'
        aria-label={label}
      />
    </div>
  )
}

export default injectIntl(ClauseRemover)
