/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiDescribedFormGroup, EuiFieldPassword } from '@elastic/eui'

import { CuiFormField } from '@modules/cui/forms'
import { CuiTitleWithBadge } from '@modules/cui'

import DocLink from '../../../../../../../components/DocLink'
import { labels, messages, sections } from '../../authProviderMessages'

import type { FunctionComponent } from 'react'

const EncryptionConfiguration: FunctionComponent = () => (
  <EuiDescribedFormGroup
    title={
      <CuiTitleWithBadge>
        <FormattedMessage {...sections.encryptionConfiguration} />
      </CuiTitleWithBadge>
    }
    description={
      <FormattedMessage
        {...sections.encryptionConfigurationDescription}
        values={{
          learnMore: (
            <DocLink link='samlProviderEncryptionConfigurationDocLink'>
              <FormattedMessage {...messages.learnMore} />
            </DocLink>
          ),
        }}
      />
    }
  >
    <CuiFormField
      label={<FormattedMessage {...labels.encryptionCertificateUrl} />}
      name='encryption_certificate_url'
      helpText={<FormattedMessage {...labels.encryptionCertificateUrlHelpText} />}
    />

    <CuiFormField
      label={<FormattedMessage {...labels.encryptionCertificateUrlPassword} />}
      name='encryption_certificate_url_password'
      component={EuiFieldPassword}
      helpText={<FormattedMessage {...labels.encryptionCertificateUrlHelpTextPassword} />}
    />
  </EuiDescribedFormGroup>
)

export default EncryptionConfiguration
