/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { getConfigForKey } from '@/selectors'
import { fetchDriftJwt as fetchDriftJwtAction } from '@/apps/userconsole/actions/profile'
import {
  fetchDrfitJwtRequest,
  getProfile,
  checkIsProfileLoggedOutPublicPricingPage,
} from '@/apps/userconsole/reducers'

import { DriftChat } from './DriftChat'

import type { DispatchProps, StateProps } from './types'

const mapStateToProps = (state: ReduxState): StateProps => {
  const mode = getConfigForKey(state, `DRIFT_CHAT_MODE`)
  const driftFile = mode === `popup` ? `index.html` : `drift-iframe.html`
  const profile = getProfile(state)

  return {
    mode,
    chatUrl: `${getConfigForKey(state, 'DRIFT_CHAT_URL_BASE')}/${driftFile}`,
    trialPlaybookId: getConfigForKey(state, 'DRIFT_PLAYBOOK_TRIAL'),
    marketplacePlaybookId: getConfigForKey(state, 'DRIFT_PLAYBOOK_MARKETPLACE'),
    directPayingPlaybookId: getConfigForKey(state, 'DRIFT_PLAYBOOK_DIRECT_PAYING'),
    oldMarketplacePlaybookId: getConfigForKey(state, 'DRIFT_PLAYBOOK_OLD_MARKETPLACE'),
    fetchDriftJwtRequestState: fetchDrfitJwtRequest(state),
    profile: checkIsProfileLoggedOutPublicPricingPage(profile) ? null : profile,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchDriftJwt: () => dispatch(fetchDriftJwtAction()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DriftChat))
