/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { SearchRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, RegionId, AllocatorSearchResult } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import schedule from '../../lib/schedule'

import AllocatorsOverview from './AllocatorsOverview'

import type { FunctionComponent } from 'react'

type Props = {
  regionId: string
  searchResults: AllocatorSearchResult[]
  searchAllocators: ({
    queryId,
    regionId,
    payload,
  }: {
    queryId: string
    regionId?: RegionId
    payload?: SearchRequest
  }) => void
  searchAllocatorsRequest: AsyncRequestState
}

const Allocators: FunctionComponent<Props> = ({
  regionId,
  searchResults,
  searchAllocatorsRequest,
}) => {
  if (searchAllocatorsRequest.error) {
    return <CuiAlert type='error'>{searchAllocatorsRequest.error}</CuiAlert>
  }

  return <AllocatorsOverview regionId={regionId} allocators={searchResults} />
}

export default schedule(Allocators, ({ searchAllocators, regionId }) =>
  searchAllocators({ regionId, queryId: `allocators` }),
)
