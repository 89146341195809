/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiCode, EuiSpacer, EuiTitle, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { StackVersionConfig } from '@modules/cloud-api/v1/types'
import type { SliderInstanceType } from '@modules/ui-types'
import { CuiCodeBlock } from '@modules/cui'

import { CuiSliderLogo } from '@/components/SliderLogo'

import { getSupportedSliderInstanceTypes, getSliderPrettyName } from '../../../lib/sliders'
import stringify from '../../../lib/stringify'
import FormGroup from '../../FormGroup'

import type { FunctionComponent } from 'react'

function versionRenderer(sliderInstanceType) {
  return function (stackPack) {
    const slider = stackPack[sliderInstanceType]

    if (slider == null) {
      return null
    }

    const esVersion = stackPack.version
    return <EuiBadge>v{slider.version || esVersion}</EuiBadge>
  }
}

function SliderVersionDetails({
  sliderInstanceType,
  stackPack,
  titleSize = `s`,
  logoSize = `l`,
}: {
  sliderInstanceType: SliderInstanceType
  stackPack: StackVersionConfig
  titleSize?: 's' | 'm'
  logoSize?: 'l' | 'xl'
}) {
  const version = versionRenderer(sliderInstanceType)(stackPack)
  const dockerImage = stackPack[sliderInstanceType].docker_image

  return (
    <Fragment>
      <EuiTitle size={titleSize}>
        <EuiFlexGroup gutterSize='s'>
          <EuiFlexItem grow={false}>
            <span>
              <CuiSliderLogo
                size={logoSize}
                sliderInstanceType={sliderInstanceType}
                version={stackPack.version}
              />
            </span>
          </EuiFlexItem>
          <EuiFlexItem>
            <h5>
              <FormattedMessage
                id='region-admin-create-elastic-stack-version.generic-heading'
                defaultMessage='{stackPrettyName} settings'
                values={{
                  stackPrettyName: (
                    <FormattedMessage
                      {...getSliderPrettyName({ sliderInstanceType, version: stackPack.version })}
                    />
                  ),
                }}
              />
            </h5>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiTitle>

      <EuiSpacer size='m' />

      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <FormGroup
            label={
              <FormattedMessage
                id='region-admin-create-elastic-stack-version.generic-version-identifier'
                defaultMessage='Version identifier'
              />
            }
          >
            <EuiSpacer size='s' />
            <EuiBadge>{version}</EuiBadge>
          </FormGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <FormGroup
            label={
              <FormattedMessage
                id='region-admin-create-elastic-stack-version.generic-docker-image'
                defaultMessage='Docker image'
              />
            }
          >
            <EuiSpacer size='s' />
            <EuiCode>{dockerImage}</EuiCode>
          </FormGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  )
}

interface Props {
  version: StackVersionConfig
}

const VersionDetails: FunctionComponent<Props> = ({ version }) => {
  const statelessSliderTypes = getSupportedSliderInstanceTypes()
    .filter((sliderInstanceType) => sliderInstanceType !== `elasticsearch`)
    .filter((sliderInstanceType) => version[sliderInstanceType] != null)

  return (
    <div>
      <SliderVersionDetails
        sliderInstanceType='elasticsearch'
        stackPack={version}
        titleSize='m'
        logoSize='xl'
      />

      <EuiSpacer size='m' />

      <FormGroup
        label={
          <FormattedMessage
            id='region-admin-create-elastic-stack-version.elasticsearch-default-plugins'
            defaultMessage='Default plugins'
          />
        }
      >
        <EuiSpacer size='s' />
        <CuiCodeBlock language='json'>
          {stringify(version.elasticsearch!.default_plugins)}
        </CuiCodeBlock>
      </FormGroup>

      <EuiSpacer size='m' />

      <FormGroup
        label={
          <FormattedMessage
            id='region-admin-create-elastic-stack-version.elasticsearch-available-plugins'
            defaultMessage='Available plugins'
          />
        }
      >
        <EuiSpacer size='s' />
        <CuiCodeBlock language='json'>{stringify(version.elasticsearch!.plugins)}</CuiCodeBlock>
      </FormGroup>

      {statelessSliderTypes.map((sliderInstanceType) => (
        <div key={sliderInstanceType}>
          <EuiSpacer size='xl' />
          <SliderVersionDetails
            key={sliderInstanceType}
            sliderInstanceType={sliderInstanceType}
            stackPack={version}
          />
        </div>
      ))}
    </div>
  )
}

export default VersionDetails
