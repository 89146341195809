/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { DISMISS_NOTIFICATION_MESSAGE } from '@/constants/actions'

import type { NotificationMessageType } from '@/constants/notificationMessageTypes'
import type { ThunkAction, Action } from '@/types/redux'

interface DismissNotificationMessageAction extends Action<typeof DISMISS_NOTIFICATION_MESSAGE> {
  payload: {
    dismissed: boolean
  }
  notificationType: NotificationMessageType
}

function setNotificationState(
  notificationState: boolean,
  notificationType: NotificationMessageType,
): DismissNotificationMessageAction {
  return {
    type: DISMISS_NOTIFICATION_MESSAGE,
    meta: {},
    payload: {
      dismissed: notificationState,
    },
    notificationType,
  }
}

export function dismissNotification(
  notificationType: NotificationMessageType,
): ThunkAction<DismissNotificationMessageAction> {
  return (dispatch) => {
    localStorage.setItem(notificationType, `true`)
    return dispatch(setNotificationState(true, notificationType))
  }
}
