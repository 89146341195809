/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiText } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'

import DeploymentAutoscalingStatus from '@/components/StackDeploymentSearch/DeploymentsTable/DeploymentInfo/DeploymentAutoscalingStatus'
import { getVersion } from '@/lib/stackDeployments/selectors'
import prettySize from '@/lib/prettySize'
import { getDeploymentMemoryCapacity } from '@/lib/stackDeployments/selectors/instances'
import PortalDeploymentStatus from '@/components/Portal/components/PortalDeploymentStatus'

import type { AllProps } from './types'

export type DeploymentInfoItem = 'autoscaling' | 'storage' | 'cpu' | 'ram' | 'status'

interface Props extends AllProps {
  deploymentInfoItem: DeploymentInfoItem
  hasPendingUpdate?: boolean
}

class DeploymentInfo extends Component<Props> {
  componentDidMount() {
    const { deploymentInfoItem } = this.props

    if (deploymentInfoItem === 'autoscaling') {
      this.fetchDeploymentTemplatesWithStackVersion()
    }
  }

  render() {
    const { deployment, deploymentInfoItem } = this.props

    switch (deploymentInfoItem) {
      case 'status':
        return this.renderDeploymentStatus(deployment)
      case 'autoscaling':
        return <DeploymentAutoscalingStatus {...this.props} />
      case 'storage':
      case 'cpu':
        return null
      case 'ram':
        return (
          <EuiText size='s' data-test-id='deployment-total-ram'>
            {prettySize(getDeploymentMemoryCapacity({ deployment }))}
          </EuiText>
        )
      default:
        return null
    }
  }

  renderDeploymentStatus(deployment: DeploymentSearchResponse) {
    const { hasPendingUpdate } = this.props

    if (hasPendingUpdate) {
      return (
        <EuiBadge color='warning' data-test-id='deployment-status-badge'>
          <FormattedMessage id='portal-deployment-status.processing' defaultMessage='Pending' />
        </EuiBadge>
      )
    }

    return <PortalDeploymentStatus deployment={deployment} className='deployments-table-status' />
  }

  fetchDeploymentTemplatesWithStackVersion() {
    const { deployment, esCluster, fetchDeploymentTemplatesIfNeeded, regionId } = this.props
    const stackVersion = deployment ? getVersion({ deployment }) : esCluster.plan.version

    if (regionId == null) {
      return // avoid loading templates without region
    }

    if (stackVersion == null) {
      return // avoid loading templates without version qualification
    }

    fetchDeploymentTemplatesIfNeeded({ regionId, stackVersion })
  }
}

export default DeploymentInfo
