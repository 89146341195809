/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { clamp } from 'lodash'

import type { InstanceSummary } from '@modules/ui-types'
import type { ClusterInstanceInfo } from '@modules/cloud-api/v1/types'

import toPercentage from '@/lib/toPercentage'

import { hasStoppedRoutingRequests, isNodePausedByUser } from '../../../lib/stackDeployments'

import type { InstanceHealthStatus } from './NodeInstanceStatus'

export const getNodeRoles = (instance: InstanceSummary): string | undefined => {
  const isESNode = instance.kind === 'elasticsearch'

  if (!isESNode) {
    return instance.kind
  }

  const serviceRoles = instance.instance.service_roles?.join(`, `)

  if (
    instance.instance.instance_set_id &&
    !instance.instance.service_roles?.includes(instance.instance.instance_set_id)
  ) {
    return serviceRoles?.concat(`, ${instance.instance.instance_set_id}` || '')
  }

  return serviceRoles
}

export const getJVMMemoryPressure = (instance: ClusterInstanceInfo): number => {
  const memoryPressure = instance.memory?.memory_pressure ?? 0

  return clamp(memoryPressure, 0, 100)
}

export const getUsagePercent = (used, available): number => {
  const usage = used / available

  if (!Number.isNaN(usage) && Number.isFinite(usage)) {
    return toPercentage(usage)
  }

  return 0
}

export const getDiskAllocationRaw = (
  instance: ClusterInstanceInfo,
): { diskSpaceAvailable: number; diskSpaceUsed: number; usagePercent: number } => {
  const { disk } = instance
  const diskSpaceAvailable = disk?.disk_space_available ?? 0
  const diskSpaceUsed = disk?.disk_space_used ?? 0
  const usagePercent = getUsagePercent(diskSpaceUsed, diskSpaceAvailable)

  return { diskSpaceAvailable, diskSpaceUsed, usagePercent }
}

export const getInstanceHealth = (instance: ClusterInstanceInfo): InstanceHealthStatus => {
  if (isNodePausedByUser(instance)) {
    return `paused-by-user`
  }

  if (hasStoppedRoutingRequests(instance)) {
    return `stopped-routing-requests`
  }

  return instance.healthy ? 'healthy' : 'unhealthy'
}

export const getSize = (
  instance: ClusterInstanceInfo,
): { type: 'storage' | 'memory'; size: number } | null => {
  const { disk, memory, instance_configuration } = instance
  const instanceStorage = disk?.disk_space_available ?? 0
  const instanceMemory = memory?.instance_capacity ?? 0

  if (instanceStorage && instance_configuration?.resource === `storage`) {
    return {
      type: `storage`,
      size: instanceStorage,
    }
  }

  if (instanceMemory) {
    return {
      type: `memory`,
      size: instanceMemory,
    }
  }

  return null
}
