/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SaasAuthResponse, SaasUserOktaGroupsResponse } from '@modules/cloud-api/v1/types'

import {
  FETCH_USER_OKTA_GROUPS,
  UPDATE_USER_OKTA_GROUPS,
} from '@/apps/adminconsole/constants/actions'

import { replaceIn } from '../../lib/immutability-helpers'

export interface State {
  [userId: string]: OktaUserGroups
}
export type OktaUserGroups = Array<{
  name: string
}>

type FetchAction = {
  type: 'FETCH_USER_OKTA_GROUPS'
  error: Error | null
  payload: SaasUserOktaGroupsResponse | null
  meta: {
    userId: string
  }
}

type UpdateAction = {
  type: 'UPDATE_USER_OKTA_GROUPS'
  error: Error | null
  payload: SaasAuthResponse | null
  meta: {
    userId: string
  }
}

export default function oktaGroupsReducer(
  state: State = {},
  action: FetchAction | UpdateAction,
): State {
  if (action.type === FETCH_USER_OKTA_GROUPS) {
    if (action.payload && !action.error) {
      const { userId } = action.meta

      return replaceIn(state, [userId], action.payload.groups ?? [])
    }
  }

  if (action.type === UPDATE_USER_OKTA_GROUPS) {
    if (action.payload && !action.error) {
      const { userId } = action.meta
      const newState = state[userId].push({ name: 'Support' })
      return replaceIn(state, [userId, `groups`], newState)
    }
  }

  return state
}
