/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { AsyncRequestState, RegionId } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import { CuiPermissibleControl } from '@modules/cui/PermissibleControl'
import history from '@modules/utils/history'

import SpinButton from '../../../../../components/SpinButton'
import { authenticationProvidersUrl } from '../../../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'

interface Props {
  createSecurityCluster: () => Promise<any>
  createSecurityClusterRequest: AsyncRequestState
  regionId: RegionId
}

const messages = defineMessages({
  noSecurityCluster: {
    id: 'security-cluster-wrapper.no-security-cluster',
    defaultMessage: 'No security cluster is configured',
  },
  noSecurityClusterDescription: {
    id: 'security-cluster-wrapper.no-security-cluster-description',
    defaultMessage: 'A security cluster must be configured for user management features.',
  },
  createSecurityCluster: {
    id: 'security-cluster-wrapper.create-security-cluster',
    defaultMessage: 'Create security cluster',
  },
})

const NoSecurityCluster: FunctionComponent<Props> = ({
  createSecurityCluster,
  createSecurityClusterRequest,
  regionId,
}) => (
  <Fragment>
    <EuiCallOut color='primary' title={<FormattedMessage {...messages.noSecurityCluster} />}>
      <FormattedMessage {...messages.noSecurityClusterDescription} />

      <EuiSpacer size='m' />

      <CuiPermissibleControl permissions={Permission.createSecurityDeployment}>
        <SpinButton
          spin={createSecurityClusterRequest.inProgress}
          onClick={() =>
            createSecurityCluster().then(() => {
              history.push(authenticationProvidersUrl(regionId))
              return
            })
          }
        >
          <FormattedMessage {...messages.createSecurityCluster} />
        </SpinButton>
      </CuiPermissibleControl>
    </EuiCallOut>

    {createSecurityClusterRequest.error && (
      <Fragment>
        <EuiSpacer />
        <CuiAlert type='error'>{createSecurityClusterRequest.error}</CuiAlert>
      </Fragment>
    )}
  </Fragment>
)

export default NoSecurityCluster
