/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { ThunkDispatch } from '@/types/redux'

import { loginWithAzure } from '../../../actions/auth'

import MicrosoftSignUp from './MicrosoftSignUp'

import type { SignUpProps, MicrosoftSignUpDispatchProps } from './../types'

interface StateProps {}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  loginWithAzure: (args) => dispatch(loginWithAzure(args)),
})

export default withRouter(
  connect<StateProps, MicrosoftSignUpDispatchProps, SignUpProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(MicrosoftSignUp),
)
