/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiBadge,
  EuiHorizontalRule,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiPopover,
  EuiSpacer,
  EuiButtonIcon,
} from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import EOLVersionWarningBadge from '@/components/EndOfLifeWarnings/EndOfLifeVersionWarningBadge'
import {
  getDisplayId,
  getDisplayName,
  getFirstEsClusterFromGet,
  getVersion,
  isSystemOwned,
  getDeploymentTags,
} from '@/lib/stackDeployments/selectors'

import DeploymentTag from '../../../components/Deployment/DeploymentTags/DeploymentTag'
import StackDeploymentStatus from '../../StackDeploymentStatus'
import { isCrossClusterSearch } from '../../../lib/deployments/ccs'
import { getThemeColors } from '../../../lib/theme'
import { getEsDeploymentTemplateId } from '../../../lib/stackDeployments'

import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'

const MAX_INLINE_TAGS = 4

type Props = {
  deployment: DeploymentSearchResponse
}

const messages = defineMessages({
  showAllTags: {
    id: `deployment-tags.card.show-all-tags`,
    defaultMessage: `Show all tags`,
  },
  popOverTitle: {
    id: `deployment-tags.card.tags.pop-over`,
    defaultMessage: `All tags`,
  },
})

const Title: FunctionComponent<Props & WrappedComponentProps> = ({
  intl: { formatMessage },
  deployment,
}) => {
  const { euiColorDarkShade } = getThemeColors()
  const esResource = getFirstEsClusterFromGet({ deployment })
  const deploymentTemplateId = esResource && getEsDeploymentTemplateId({ resource: esResource })
  const displayName = getDisplayName({ deployment })
  const displayId = getDisplayId({ deployment })
  const systemOwned = isSystemOwned({ deployment })
  const version = esResource && getVersion({ deployment })
  const hasCcs = isCrossClusterSearch({ deploymentTemplateId, systemOwned })
  const tags = getDeploymentTags({ deployment })
  const inlineTags = tags.slice(0, MAX_INLINE_TAGS)
  const isExpansible = tags.length > 4
  const [isPopoverOpen, setPopoverOpen] = useState(false)

  return (
    <Fragment>
      <EuiFlexGroup justifyContent='spaceBetween' responsive={false}>
        <EuiFlexItem className='eui-textTruncate' grow={false}>
          <EuiTitle className='eui-textTruncate' size='s'>
            <h2 className='deploymentCard-displayName' data-test-id='deploymentTitle'>
              <PrivacySensitiveContainer nativeElementType='span'>
                {displayName}
              </PrivacySensitiveContainer>
            </h2>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <StackDeploymentStatus deployment={deployment} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <div>
        {displayId !== displayName && (
          <EuiBadge color='hollow' data-test-id='title-id-badge'>
            {displayId}
          </EuiBadge>
        )}

        {version && <EuiBadge data-test-id='title-version-badge'>v{version}</EuiBadge>}
        {version && (
          <EOLVersionWarningBadge
            data-test-id='title-eol-version-warning'
            version={version}
            sliderInstanceType='elasticsearch'
            style={{ marginLeft: '4px' }}
          />
        )}

        {inlineTags.map((tag, index) => (
          <DeploymentTag key={index} tag={tag} color='hollow' />
        ))}

        {isExpansible && (
          <EuiPopover
            data-test-id='title-tags-popover'
            id='title-tags-popover'
            button={
              <EuiButtonIcon
                aria-label={formatMessage(messages.showAllTags)}
                iconType='boxesHorizontal'
                color='text'
                onMouseEnter={() => {
                  setPopoverOpen(true)
                }}
                onMouseLeave={() => {
                  setPopoverOpen(false)
                }}
              />
            }
            closePopover={() => setPopoverOpen(false)}
            isOpen={isPopoverOpen}
          >
            <div style={{ maxWidth: `400px` }}>
              <Fragment>
                <EuiTitle size='xs'>
                  <h5> {formatMessage(messages.popOverTitle)}</h5>
                </EuiTitle>

                <EuiSpacer size='s' />
              </Fragment>
              <EuiFlexGroup gutterSize='s' wrap={true} responsive={false}>
                {tags.map((tag, index) => (
                  <EuiFlexItem key={index} grow={false}>
                    <DeploymentTag tag={tag} color='hollow' />
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </div>
          </EuiPopover>
        )}

        {hasCcs && (
          <EuiBadge color={euiColorDarkShade}>
            <FormattedMessage
              id='deployment-card-title.ccs'
              defaultMessage='Cross cluster search'
            />
          </EuiBadge>
        )}
      </div>
      <EuiHorizontalRule margin='s' />
    </Fragment>
  )
}

export default injectIntl(Title)
