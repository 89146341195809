/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

// eslint-disable-next-line no-restricted-imports
import { get } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFieldPassword, EuiFieldText, EuiFormRow, EuiSpacer } from '@elastic/eui'

import type { SnapshotRepositoryConfig } from '@modules/ui-types'

import { replaceIn } from '../../../lib/immutability-helpers'
import AutofillBuster from '../../AutofillBuster'

import type { Errors, OnChangeEventPropType } from './RepositoryEditor'

interface Props {
  config: SnapshotRepositoryConfig
  errors: Errors
  onChange: (onChangeEventProp: OnChangeEventPropType) => void
}

const SimpleStorageEditor: React.FunctionComponent<Props> = ({ config, errors, onChange }) => {
  const fields = [
    {
      name: `region`,
      path: [`settings`, `region`],
      label: (
        <FormattedMessage
          id='snapshot-repository-management.simple-storage.region'
          defaultMessage='Region'
        />
      ),
    },
    {
      name: `bucket`,
      path: [`settings`, `bucket`],
      label: (
        <FormattedMessage
          id='snapshot-repository-management.simple-storage.bucket'
          defaultMessage='Bucket'
        />
      ),
    },
    {
      Component: EuiFieldPassword,
      name: `access_key`,
      path: [`settings`, `access_key`],
      label: (
        <FormattedMessage
          id='snapshot-repository-management.simple-storage.access_key'
          defaultMessage='Access Key'
        />
      ),
    },
    {
      Component: EuiFieldPassword,
      name: `secret_key`,
      path: [`settings`, `secret_key`],
      label: (
        <FormattedMessage
          id='snapshot-repository-management.simple-storage.secret_key'
          defaultMessage='Secret Key'
        />
      ),
    },
  ]
  return (
    <AutofillBuster>
      {fields.map((field, index) => {
        const id = `snapshot-repository-management.simple-storage.${field.path.join(`.`)}`
        const { name, Component = EuiFieldText, path, label } = field
        const error = get(errors, field.path[1])

        return (
          <Fragment key={index}>
            <EuiSpacer size='m' />

            <EuiFormRow label={label} error={error} isInvalid={!!error}>
              <Component
                id={id}
                name={name}
                value={get(config, path, ``)}
                onChange={(e) =>
                  onChange({
                    config: replaceIn(config, path, e.target.value),
                  })
                }
              />
            </EuiFormRow>
          </Fragment>
        )
      })}
    </AutofillBuster>
  )
}

export default SimpleStorageEditor
