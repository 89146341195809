/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, difference, without } from 'lodash'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiFilterSelectItem,
  EuiPopover,
  EuiFilterButton,
} from '@elastic/eui'

import type { SliderInstanceType } from '@modules/ui-types'

import {
  getSupportedSliderInstanceTypes,
  getSliderIconType,
  getSliderPrettyName,
} from '../../lib/sliders'

type Props = {
  scope?: Scope
  onChange: (scope?: Scope) => void
}

type Scope = SliderInstanceType[]

type State = {
  showScope: boolean
}

class FilterContextSliderScope extends Component<Props, State> {
  state: State = {
    showScope: false,
  }

  render() {
    const { scope } = this.props
    const { showScope } = this.state

    return (
      <EuiPopover
        id='scope-filters'
        button={
          <EuiFilterButton
            key='scope-filters'
            iconType='arrowDown'
            iconSide='right'
            onClick={this.toggleScopeDropdown}
          >
            {scope === undefined ? (
              <FormattedMessage id='activity-feed.scope' defaultMessage='Scope' />
            ) : (
              <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
                {scope.map((sliderInstanceType) => (
                  <EuiFlexItem key={sliderInstanceType} grow={false}>
                    <EuiIcon type={getSliderIconType({ sliderInstanceType })} />
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            )}
          </EuiFilterButton>
        }
        isOpen={showScope}
        closePopover={this.closeScopeDropdown}
        panelPaddingSize='none'
      >
        <div className='euiFilterSelect__items' style={{ width: 200 }}>
          {getSupportedSliderInstanceTypes().map((sliderInstanceType) => (
            <EuiFilterSelectItem
              key={sliderInstanceType}
              checked={this.isInScope(sliderInstanceType) ? `on` : undefined}
              onClick={() => this.toggleScope(sliderInstanceType)}
            >
              <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
                <EuiFlexItem grow={false}>
                  <EuiIcon type={getSliderIconType({ sliderInstanceType })} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <FormattedMessage {...getSliderPrettyName({ sliderInstanceType })} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFilterSelectItem>
          ))}
        </div>
      </EuiPopover>
    )
  }

  toggleScopeDropdown = () => {
    const { showScope } = this.state
    this.setState({ showScope: !showScope })
  }

  closeScopeDropdown = () => {
    this.setState({ showScope: false })
  }

  isInScope = (sliderInstanceType: SliderInstanceType): boolean => {
    const { scope } = this.props

    if (scope === undefined) {
      return true
    }

    return scope.includes(sliderInstanceType)
  }

  toggleScope = (sliderInstanceType: SliderInstanceType): void => {
    const { scope, onChange } = this.props

    if (scope === undefined) {
      onChange([sliderInstanceType])
      return
    }

    const scoped = this.isInScope(sliderInstanceType)

    const nextTypes = scoped ? without(scope, sliderInstanceType) : [...scope, sliderInstanceType]

    // isEmpty(difference(A, B)) behaves like isEqual(A, B) but doesn't depend on sort order
    const empty = isEmpty(nextTypes)
    const full = isEmpty(difference(getSupportedSliderInstanceTypes(), nextTypes))

    if (empty || full) {
      onChange(undefined)
      return
    }

    onChange(nextTypes)
  }
}

export default FilterContextSliderScope
