/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import {
  EuiButton,
  EuiButtonIcon,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFieldText,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFlyoutFooter,
  EuiOverlayMask,
  EuiSpacer,
  EuiSteps,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type {
  TrustRelationshipCreateRequest,
  TrustRelationshipGetResponse,
  TrustRelationshipUpdateRequest,
} from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, CuiLink, CuiPermissibleControl } from '@modules/cui'

import { readFile } from '../../../lib/fileHelpers'
import { platformTrustManagementUrl } from '../../../lib/urlBuilder'

import type { WrappedComponentProps } from 'react-intl'

const messages = defineMessages({
  trustedCertificate: {
    id: 'trust-management.trusted-relationship-trusted-certificate-title',
    defaultMessage: `Upload CA certificate`,
  },
  environmentId: {
    id: 'trust-management.trusted-relationship-environment-id-title',
    defaultMessage: `Enter environment ID`,
  },
  environmentName: {
    id: 'trust-management.trusted-relationship-environment-name-title',
    defaultMessage: `Name your environment`,
  },
})

type OnSavePayload = TrustRelationshipCreateRequest | TrustRelationshipUpdateRequest

export type Props = {
  onSave: (payload: OnSavePayload) => Promise<unknown>
  onSaveRequest: AsyncRequestState
  resetOnClose: () => void
  trustRelationship?: TrustRelationshipGetResponse
}

type State = {
  isOpen: boolean
  name: string
  account_ids: string[]
  public_ca_cert: string
}

class TrustRelationshipFlyout extends Component<Props & WrappedComponentProps, State> {
  state: State = this.createInitialState()

  componentWillUnmount(): void {
    this.props.resetOnClose()
  }

  render(): JSX.Element {
    return (
      <Fragment>
        {this.isEditing() ? (
          <CuiPermissibleControl permissions={Permission.updateTrustRelationship}>
            <EuiButtonIcon
              iconType='pencil'
              data-test-id='edit-trust-relationship-button'
              aria-label='edit-trust-relationship'
              onClick={() => this.onFlyoutOpen()}
            />
          </CuiPermissibleControl>
        ) : (
          <CuiPermissibleControl permissions={Permission.createTrustRelationship}>
            <EuiButton onClick={() => this.onFlyoutOpen()} fullWidth={false}>
              <FormattedMessage
                id='trust-management.trusted-relationship-button'
                defaultMessage='{verb} trust relationship'
                values={{
                  verb: this.getVerb(),
                }}
              />
            </EuiButton>
          </CuiPermissibleControl>
        )}

        {this.renderFlyout()}
      </Fragment>
    )
  }

  renderFlyout(): JSX.Element | null {
    const {
      onSaveRequest: { error, inProgress },
    } = this.props
    const { isOpen, name, public_ca_cert, account_ids } = this.state

    if (!isOpen) {
      return null
    }

    const isDisabled = !name || !public_ca_cert || !account_ids.length || inProgress

    return (
      <EuiOverlayMask headerZindexLocation='below'>
        <EuiFlyout maxWidth='32rem' onClose={() => this.onFlyoutClose()}>
          <EuiFlyoutHeader hasBorder={true}>
            <EuiTitle size='m'>
              <h2>
                <FormattedMessage
                  id='trust-management.trusted-relationship-flyout-title'
                  defaultMessage='{verb} trust relationship'
                  values={{
                    verb: this.getVerb(),
                  }}
                />
              </h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiFlexGroup direction='column' gutterSize='s'>
              <EuiFlexItem grow={true}>
                <EuiText>
                  <FormattedMessage
                    id='trust-management.trusted-relationship-flyout-description'
                    defaultMessage='Navigate to the {section} page of the ECE environment you want to establish trust with, download the {cert}, copy the {id} and complete the following steps.'
                    values={{
                      section: (
                        <CuiLink
                          to={platformTrustManagementUrl(`ece-region`)}
                          onClick={() => this.onFlyoutClose()}
                        >
                          <FormattedMessage
                            id='trust-management.trusted-relationship-link'
                            defaultMessage='Trust Management'
                          />
                        </CuiLink>
                      ),
                      cert: (
                        <strong>
                          <FormattedMessage
                            id='trust-management.trusted-relationship-cert'
                            defaultMessage='CA certificate'
                          />
                        </strong>
                      ),
                      id: (
                        <strong>
                          <FormattedMessage
                            id='trust-management.trusted-relationship-id'
                            defaultMessage='environment ID'
                          />
                        </strong>
                      ),
                    }}
                  />
                </EuiText>
                <EuiSpacer size='m' />
              </EuiFlexItem>
              <EuiFlexItem grow={true}>{this.renderSteps()}</EuiFlexItem>
              {error && (
                <EuiFlexItem grow={false}>
                  <CuiAlert type='danger' data-test-id='save-relationship-request-error'>
                    {error}
                  </CuiAlert>
                </EuiFlexItem>
              )}
              <EuiFlexItem grow={false}>
                <EuiCallOut
                  title={
                    <FormattedMessage
                      id='trust-management.trusted-relationship-flyout-callout-title'
                      defaultMessage='Trust must be configured in both environments'
                    />
                  }
                  iconType='iInCircle'
                >
                  <p>
                    <FormattedMessage
                      id='trust-management.trusted-relationship-flyout-callout-content'
                      defaultMessage='To establish trust, both environments must be configured to trust each other. Use the trust parameters to configure the remote ECE environment to trust this environment.'
                    />
                  </p>
                </EuiCallOut>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={() => this.onFlyoutClose()} flush='left'>
                  <FormattedMessage
                    id='trust-management.trusted-relationship-flyout-cancel'
                    defaultMessage='Cancel'
                  />
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  type='button'
                  data-test-id='save-relationship-button'
                  disabled={isDisabled}
                  onClick={() => this.onSave()}
                  isLoading={inProgress}
                  fill={true}
                >
                  {this.isEditing() ? (
                    <FormattedMessage
                      id='trust-management.trusted-relationship-flyout-save-changes'
                      defaultMessage='Save changes'
                    />
                  ) : (
                    <FormattedMessage
                      id='trust-management.trusted-relationship-flyout-submit'
                      defaultMessage='{verb} trust'
                      values={{
                        verb: this.getVerb(),
                      }}
                    />
                  )}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </EuiOverlayMask>
    )
  }

  renderSteps(): JSX.Element {
    const {
      intl: { formatMessage },
    } = this.props
    const { name, account_ids } = this.state

    const steps = [
      {
        title: formatMessage(messages.trustedCertificate),
        children: (
          <Fragment>
            <EuiFilePicker
              accept='application/x-x509-ca-cert'
              initialPromptText='Select or drag and drop CA certificate'
              onChange={(files) => this.onCertChange(files)}
            />
          </Fragment>
        ),
      },
      {
        title: formatMessage(messages.environmentId),
        children: (
          <Fragment>
            <EuiText>
              <p>
                <FormattedMessage
                  id='trust-management.trusted-relationship-environment-id-description'
                  defaultMessage='Provide the environment ID of the ECE environment you want to establish trust with'
                />
              </p>
            </EuiText>

            <EuiSpacer />

            <EuiFieldText
              value={account_ids.length ? account_ids[0] : ``}
              data-test-id='trust-relationship-environment-id'
              onChange={(e) => this.onAccountIdChange(e.target.value)}
            />
          </Fragment>
        ),
      },
      {
        title: formatMessage(messages.environmentName),
        children: (
          <Fragment>
            <EuiText>
              <p>
                <FormattedMessage
                  id='trust-management.trusted-relationship-environment-name-description'
                  defaultMessage='Provide a name for your trusted environment.'
                />
              </p>
            </EuiText>

            <EuiSpacer />

            <EuiFieldText
              value={name}
              data-test-id='trust-relationship-name'
              onChange={(e) => this.onNameChange(e.target.value)}
            />
          </Fragment>
        ),
      },
    ]

    return <EuiSteps steps={steps} />
  }

  createInitialState(): State {
    const { trustRelationship } = this.props

    return trustRelationship
      ? {
          isOpen: false,
          name: trustRelationship.name,
          account_ids: trustRelationship.account_ids || [],
          public_ca_cert: trustRelationship.public_ca_cert || ``,
        }
      : {
          isOpen: false,
          name: ``,
          account_ids: [],
          public_ca_cert: ``,
        }
  }

  isEditing(): boolean {
    return Boolean(this.props.trustRelationship)
  }

  getVerb(): JSX.Element {
    return this.isEditing() ? (
      <FormattedMessage
        id='trust-management.trusted-relationship-button-edit'
        defaultMessage='Edit'
      />
    ) : (
      <FormattedMessage
        id='trust-management.trusted-relationship-button-create'
        defaultMessage='Create'
      />
    )
  }

  onFlyoutOpen(): void {
    this.setState({ ...this.createInitialState(), isOpen: true })
  }

  onFlyoutClose(): void {
    this.setState(this.createInitialState())
  }

  onCertChange(files: FileList | null): void {
    if (files?.length) {
      readFile(files[0]).then((public_ca_cert) => this.setState({ public_ca_cert }))
    } else {
      this.setState({ public_ca_cert: `` })
    }
  }

  onAccountIdChange(accountId: string): void {
    // We currently just use the single account id, but this may expand in the future
    // to handle multiple IDs when we introduce support for "Organizations".
    this.setState({ account_ids: [accountId] })
  }

  onNameChange(name: string): void {
    this.setState({ name })
  }

  onSave(): void {
    const { onSave } = this.props
    const { name, account_ids, public_ca_cert } = this.state

    onSave({ name, account_ids, public_ca_cert }).then(() => this.onFlyoutClose())
  }
}

export default injectIntl(TrustRelationshipFlyout)
