/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import cx from 'classnames'

import { EuiBadge } from '@elastic/eui'

import type { MetadataItem } from '@modules/cloud-api/v1/types'

import type { FunctionComponent, ReactNode } from 'react'

interface Props {
  children?: ReactNode
  tag: MetadataItem
  onClick?: (tag: MetadataItem) => any
  className?: string
  color?: string
}

const AllocatorTag: FunctionComponent<Props> = ({ tag, onClick, className, children, ...rest }) => {
  const classes = cx(`allocatorTag`, className)

  const tagText = tag.value ? `${tag.key}: ${tag.value}` : tag.key

  return (
    // @ts-ignore whatever
    <EuiBadge
      onClick={onClick ? () => onClick(tag) : undefined}
      onClickAriaLabel={tagText}
      className={classes}
      aria-label={tagText}
      {...rest}
    >
      {tagText}

      {children}
    </EuiBadge>
  )
}

export default AllocatorTag
