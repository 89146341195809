/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'

import { searchDeployments } from '../../../../../actions/stackDeployments'
import {
  searchStackDeploymentsRequest,
  getStackDeploymentsFromSearch,
} from '../../../../../reducers'
import { getDeploymentsByIdQuery } from '../../../../../lib/deploymentQuery'

import AlsoTrustedByDeploymentsList from './AlsoTrustedByDeploymentsList'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const nonce = `alsoTrustedByDeployments`

const mapStateToProps = (state: ReduxState): StateProps => ({
  alsoTrustedByDeployments: getStackDeploymentsFromSearch(state, nonce)?.deployments,
  alsoTrustedByDeploymentsRequest: searchStackDeploymentsRequest(state, nonce),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchForAlsoTrustedByDeployments: (deploymentIds) => {
    const query = getDeploymentsByIdQuery({ deploymentIds })
    dispatch(searchDeployments({ queryId: nonce, query }))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AlsoTrustedByDeploymentsList)
