/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  BillingHistory,
  ItemsCosts,
  LineItem,
  Prepaids,
  OrganizationBillingDetails,
  ChartItems,
  AzureMarketplaceSubscription,
} from '@modules/cloud-api/v1/types'
import type { GetCostsOverviewUrlParams } from '@modules/cloud-api/v1/urls'
import type { AccountCosts, AccountCostsSummary } from '@modules/ui-types'

import {
  FETCH_DEPLOYMENTS_COSTS,
  FETCH_ACCOUNT_ITEMS_COSTS,
  FETCH_DEPLOYMENT_COST_ITEMS,
  FETCH_ACCOUNT_COSTS_OVERVIEW,
  FETCH_PREPAID_BALANCE_LINE_ITEMS,
  FETCH_BILLING_HISTORY,
  FETCH_ORGANIZATION_PREPAIDS,
  FETCH_ORGANIZATION_BILLING_DETAILS,
  FETCH_COSTS_CHARTS,
  FETCH_COSTS_CHARTS_BY_DEPLOYMENT,
  FETCH_MARKETPLACE_BILLING_DETAILS,
} from '@/constants/actions'
import { getAsyncRequestState } from '@/reducers/asyncRequests'
import { replaceIn } from '@/lib/immutability-helpers'

export type UserState = {
  costs?: AccountCosts
  deploymentItemsCosts?: ItemsCosts
  deploymentItemsCostsByDeployment?: ItemsCosts
  costsOverview?: Record<string, AccountCostsSummary>
  prepaidBalanceLineItems?: LineItem[]
  billingHistory?: BillingHistory
  prepaids?: Prepaids
  billingDetails?: OrganizationBillingDetails
  costsCharts?: ChartItems
  costsChartsByDeployment?: Record<string, ChartItems>
  marketplaceBillingDetails?: AzureMarketplaceSubscription // for now we have only details for Azure
}

export type State = Record<string, UserState>

const initialState: State = {}

export default function billingReducer(state = initialState, action): State {
  if (!action.error && action.payload) {
    if (action.type === FETCH_DEPLOYMENTS_COSTS) {
      const {
        payload,
        meta: { timePeriod, organizationId },
      } = action

      return replaceIn(state, [organizationId, 'costs'], { ...payload, timePeriod })
    }

    if (action.type === FETCH_ACCOUNT_ITEMS_COSTS) {
      const {
        payload,
        meta: { timePeriod, organizationId },
      } = action

      return replaceIn(state, [organizationId, 'deploymentItemsCosts'], {
        ...payload,
        timePeriod,
      })
    }

    if (action.type === FETCH_DEPLOYMENT_COST_ITEMS) {
      const {
        payload,
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'deploymentItemsCostsByDeployment'], payload)
    }

    if (action.type === FETCH_ACCOUNT_COSTS_OVERVIEW) {
      const {
        payload,
        meta: { organizationId, from, to },
      } = action
      const {
        costs: { total },
        trials,
      } = payload

      const dataKey = from && to ? `${from}_${to}` : 'current'

      return replaceIn(state, [organizationId, 'costsOverview', dataKey], {
        ...payload,
        isTrialConversionUser: trials > 0 && trials !== total,
        paidUsage: total - trials,
      })
    }

    if (action.type === FETCH_PREPAID_BALANCE_LINE_ITEMS) {
      const {
        payload: { line_items },
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'prepaidBalanceLineItems'], line_items)
    }

    if (action.type === FETCH_BILLING_HISTORY) {
      const {
        payload,
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'billingHistory'], payload)
    }

    if (action.type === FETCH_ORGANIZATION_BILLING_DETAILS) {
      const {
        payload,
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'billingDetails'], payload)
    }

    if (action.type === FETCH_MARKETPLACE_BILLING_DETAILS) {
      const {
        payload,
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'marketplaceBillingDetails'], payload)
    }

    if (action.type === FETCH_COSTS_CHARTS) {
      const {
        payload,
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'costsCharts'], payload)
    }

    if (action.type === FETCH_COSTS_CHARTS_BY_DEPLOYMENT) {
      const {
        payload,
        meta: { organizationId, deploymentId },
      } = action

      return replaceIn(state, [organizationId, 'costsChartsByDeployment', deploymentId], payload)
    }
  }

  if (action.type === FETCH_ORGANIZATION_PREPAIDS) {
    const {
      payload,
      meta: { organizationId },
    } = action

    return replaceIn(state, [organizationId, 'prepaids'], payload)
  }

  return state
}

export function getBilling(state: State): State {
  return state
}

export function getAccountDeploymentsCosts(
  state: State,
  organizationId: string,
): AccountCosts | undefined {
  return state?.[organizationId]?.costs
}

export function getDeploymentItemsCosts(
  state: State,
  organizationId: string,
): ItemsCosts | undefined {
  return state?.[organizationId]?.deploymentItemsCosts
}

export function getDeploymentItemsCostsByDeployment(
  state: State,
  organizationId: string,
): ItemsCosts | undefined {
  return state?.[organizationId]?.deploymentItemsCostsByDeployment
}

interface GetAccountCostsOverviewProps extends GetCostsOverviewUrlParams {
  state: State
}

export function getAccountCostsOverview({
  state,
  organizationId,
  from,
  to,
}: GetAccountCostsOverviewProps): AccountCostsSummary | undefined {
  const dataKey = from && to ? `${from}_${to}` : 'current'

  return state?.[organizationId]?.costsOverview?.[dataKey]
}

export function getPrepaidBalanceLineItems(
  state: State,
  organizationId: string,
): LineItem[] | undefined {
  return state?.[organizationId]?.prepaidBalanceLineItems
}

export function getBillingHistory(
  state: State,
  organizationId: string,
): BillingHistory | undefined {
  return state?.[organizationId]?.billingHistory
}

export function getOrganizationPrepaids(
  state: State,
  organizationId: string,
): Prepaids | undefined {
  return state[organizationId]?.prepaids
}

export function getOrganizationBillingDetails(
  state: State,
  organizationId: string,
): OrganizationBillingDetails | undefined {
  return state[organizationId]?.billingDetails
}

export function getMarketplaceBillingDetails(
  state: State,
  organizationId: string,
): AzureMarketplaceSubscription | undefined {
  return state[organizationId]?.marketplaceBillingDetails
}

export function getCostsCharts(state: State, organizationId: string): ChartItems | undefined {
  return state[organizationId]?.costsCharts
}

export function getCostsChartsByDeployment(
  state: State,
  organizationId: string,
  deploymentId: string,
): ChartItems | undefined {
  return state[organizationId]?.costsChartsByDeployment?.[deploymentId]
}

export const fetchDeploymentsCostsRequest = getAsyncRequestState(FETCH_DEPLOYMENTS_COSTS)

export const fetchDeploymentCostsByDeploymentRequest = getAsyncRequestState(
  FETCH_DEPLOYMENT_COST_ITEMS,
)

export const fetchAccountItemsCostsRequest = getAsyncRequestState(FETCH_ACCOUNT_ITEMS_COSTS)

export const fetchBillingHistoryRequest = getAsyncRequestState(FETCH_BILLING_HISTORY)
