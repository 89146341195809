/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import { fetchOrganizationInvitations, fetchOrganizationMemberships } from '@/actions/organizations'
import { isFeatureActivated } from '@/selectors'
import { fetchSaasOrganization } from '@/actions/saasOrganizations'
import withBillingDetailsProvider from '@/lib/withBillingDetails/BillingDetailsProvider/withBillingDetailsProvider'
import withBillingDetails from '@/lib/withBillingDetails'
import { getOrganizationMemberRows } from '@/components/Organization/OrganizationMembers/lib'

import { fetchSaasOrganizationRequest } from '../../reducers/asyncRequests/registry'
import {
  getSaasOrganization,
  fetchOrganizationInvitationsRequest,
  fetchOrganizationMembershipsRequest,
} from '../../reducers'

import Organization from './Organization'
import { getOrganizationDeploymentsSearchResults } from './OrganizationDeployments/lib'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { organizationId, selectedTabId },
    },
  }: ConsumerProps,
): StateProps => {
  const organizationMemberRows = getOrganizationMemberRows(state, organizationId)

  // explicitly passing organizationId so the withBillingDetailsProvider HOC gets the necessary props

  return {
    organizationId,
    saasOrganization: getSaasOrganization(state, organizationId),
    fetchSaasOrganizationRequest: fetchSaasOrganizationRequest(state, organizationId),
    searchResults: getOrganizationDeploymentsSearchResults(state, organizationId),
    selectedTabId,
    lookupSaasUsers: isFeatureActivated(state, Feature.lookupSaasUsers),
    showPrepaidConsumptionUI: isFeatureActivated(state, Feature.billingPrepaidConsumptionUI),
    organizationMemberRows,
    fetchOrganizationInvitationsRequest: fetchOrganizationInvitationsRequest(state, organizationId),
    fetchOrganizationMembersRequest: fetchOrganizationMembershipsRequest(state, organizationId),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { organizationId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  fetchSaasOrganization: () => dispatch(fetchSaasOrganization({ organizationId })),
  fetchOrganizationMembers: () => dispatch(fetchOrganizationMemberships({ organizationId })),
  fetchOrganizationInvites: () => dispatch(fetchOrganizationInvitations({ organizationId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withBillingDetailsProvider(withBillingDetails(Organization)))
