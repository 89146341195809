/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  creditOrganizationUrl,
  extendSaasOrganizationTrialUrl,
  getSaasOrganizationUrl,
  regenerateInvoicesUrl,
  setPaymentMethodUrl,
  setToDirectUrl,
} from '@modules/cloud-api/v1/urls'
import type { GetSaasOrganizationUrlParams } from '@modules/cloud-api/v1/urls'
import type {
  CreditRequest,
  ExtendTrialRequest,
  SetPaymentRequest,
} from '@modules/cloud-api/v1/types'

import {
  CONVERT_ORGANIZATION_TO_INVOICING,
  CONVERT_RESELLER_TO_DIRECT,
  CREDIT_ORGANIZATION,
  EXTEND_SAAS_ORGANIZATION_TRIAL,
  FETCH_SAAS_ORGANIZATION,
  REGENERATE_INVOICES,
} from '@/constants/actions'
import { fetchOrganizationBillingDetails } from '@/actions/billing'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'

export function fetchSaasOrganization(params: GetSaasOrganizationUrlParams) {
  const url = getSaasOrganizationUrl(params)
  const { organizationId } = params

  return asyncRequest({
    type: FETCH_SAAS_ORGANIZATION,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}

export function convertOrganizationToInvoicing(email: string, organizationId: string) {
  const url = setPaymentMethodUrl({ organizationId })

  const payload: SetPaymentRequest = {
    payment_type: `po`,
    email,
    reason: 'Convert organization to invoicing via UI',
  }

  return asyncRequest({
    method: `PUT`,
    type: CONVERT_ORGANIZATION_TO_INVOICING,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
    payload,
  })
}

export const resetConvertOrganizationToInvoicingRequest = (...crumbs: string[]) =>
  resetAsyncRequest(CONVERT_ORGANIZATION_TO_INVOICING, crumbs)

export function convertResellerToDirect(organizationId: string) {
  const url = setToDirectUrl({ organizationId })

  return (dispatch) =>
    dispatch(
      asyncRequest({
        method: `POST`,
        type: CONVERT_RESELLER_TO_DIRECT,
        url,
        meta: { organizationId },
        crumbs: [organizationId],
      }),
    ).then(() => dispatch(fetchOrganizationBillingDetails({ organizationId })))
}

export const resetConvertResellerToDirectRequest = (...crumbs: string[]) =>
  resetAsyncRequest(CONVERT_RESELLER_TO_DIRECT, crumbs)

export function creditOrganization(organizationId: string, creditRequest: CreditRequest) {
  const url = creditOrganizationUrl({ organizationId })

  return asyncRequest({
    type: CREDIT_ORGANIZATION,
    method: 'POST',
    url,
    payload: creditRequest,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}

export const resetCreditOrganizationRequest = (...crumbs: string[]) =>
  resetAsyncRequest(CREDIT_ORGANIZATION, crumbs)

export function extendSaasOrganizationTrial(organizationId: string, new_expiration_date: string) {
  const url = extendSaasOrganizationTrialUrl({ organizationId })

  const payload: ExtendTrialRequest = {
    new_expiration_date,
  }

  return (dispatch) =>
    dispatch(
      asyncRequest({
        method: `POST`,
        type: EXTEND_SAAS_ORGANIZATION_TRIAL,
        url,
        meta: { organizationId },
        crumbs: [organizationId],
        payload,
      }),
    ).then(() => dispatch(fetchSaasOrganization({ organizationId })))
}

export const resetExtendSaasOrganizationTrialRequest = (...crumbs: string[]) =>
  resetAsyncRequest(EXTEND_SAAS_ORGANIZATION_TRIAL, crumbs)

export function regenerateInvoices(organizationId: string) {
  const url = regenerateInvoicesUrl({ organizationId })

  return asyncRequest({
    method: `POST`,
    type: REGENERATE_INVOICES,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}

export const resetRegenerateInvoiceRequest = (...crumbs: string[]) =>
  resetAsyncRequest(REGENERATE_INVOICES, crumbs)
