/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'

import StackDeploymentActivityTabs, {
  getAllPlanAttempts,
} from '../StackDeploymentActivity/StackDeploymentActivityTabs'

import type { ActivityTabId } from '../StackDeploymentActivity/StackDeploymentActivityTabs'

type Props = {
  deployment: DeploymentSearchResponse
  defaultTab?: ActivityTabId
}

type DefaultProps = {
  defaultTab: ActivityTabId
}

type State = {
  selectedTab: ActivityTabId
}

class StatefulStackDeploymentActivityTabs extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps = {
    defaultTab: `_all`,
  }

  state: State = {
    selectedTab: this.props.defaultTab,
  }

  render() {
    const { deployment } = this.props
    const { selectedTab } = this.state

    return (
      <StackDeploymentActivityTabs
        deployment={deployment}
        planAttempts={getAllPlanAttempts({ deployment, includeCurrent: true })}
        selectedTab={selectedTab}
        onTabSelect={(tab) => this.setState({ selectedTab: tab })}
      />
    )
  }
}

export default StatefulStackDeploymentActivityTabs
