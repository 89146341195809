/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import { ThunkDispatch } from '@/types/redux'
import {
  resetSearchUsers,
  resetSearchUsersRequest,
  searchUsers,
  setUserSearchTerm,
} from '@/actions/saasUsers'
import { getSearchUsersResults } from '@/apps/adminconsole/reducers'
import { searchUsersRequest } from '@/reducers/asyncRequests/registry'
import { getConfigForKey } from '@/selectors'
import { getUserSearchTerm } from '@/apps/adminconsole/reducers/searchUsers'

import SearchByUserEmail from './SearchByUserEmail'

import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (state): StateProps => ({
  isPrivate: getConfigForKey(state, `APP_FAMILY`) === `essp`,
  searchUsersResults: getSearchUsersResults(state),
  searchUsersRequest: searchUsersRequest(state),
  searchEmail: getUserSearchTerm(state.searchUsers),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchUsers: (email: string) => dispatch(searchUsers(email)),
  resetSearchUsers: () => dispatch(resetSearchUsers()),
  resetSearchUsersRequest: () => dispatch(resetSearchUsersRequest()),
  setSearchEmail: (searchEmail: string) => dispatch(setUserSearchTerm(searchEmail)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withTransaction(`Users`, `search-by-user-email`)(SearchByUserEmail))
