/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiSkeletonText } from '@elastic/eui'

import type { Container } from '@modules/cloud-api/v1/types'
import { CuiCodeEditor } from '@modules/cui'

import type { FunctionComponent } from 'react'

type Props = {
  container: Container | null
}

const ContainerJson: FunctionComponent<Props> = ({ container }) => {
  if (!container) {
    return <EuiSkeletonText />
  }

  const containerJson = JSON.stringify(container, null, 2)

  return <CuiCodeEditor language='json' height='550px' value={containerJson} isReadOnly={true} />
}

export default ContainerJson
