/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'
import type { EuiBasicTableColumn } from '@elastic/eui'

import { CuiAlert } from '@modules/cui'
import { projectUrl } from '@modules/project-lib/urls'
import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-lib/hooks/list'
import history from '@modules/utils/history'
import type { AnyProject } from '@modules/project-api/types'

import genericMessages from './helpers/genericMessages'
import { getOnlyRegionId, getPrettyCloudProvider, getPrettyProjectType } from './helpers/pretty'

type Props = {
  limit?: number
}

const ProjectList: React.FunctionComponent<Props> = ({ limit = Infinity }) => {
  const elasticsearchQuery = useListElasticsearchProjectsQuery()
  const observaibilityQuery = useListObservabilityProjectsQuery()
  const securityQuery = useListSecurityProjectsQuery()

  const queries = [elasticsearchQuery, observaibilityQuery, securityQuery]
  const successfulQueries = queries.filter((query) => query.isSuccess)
  const failedQueries = queries.filter((query) => query.isError)

  const isLoading = queries.some((query) => query.isLoading)

  if (isLoading) {
    return <EuiSkeletonText />
  }

  // A combination of .map and .filter doesn't correctly discriminate out
  // `undefined` so a slightly obtuse reduce, sorry. And we're waiting until all
  // calls have returned to avoid rapid FOUC-style table redraws.
  const items = successfulQueries.reduce<AnyProject[]>(
    (acc, query) => [...acc, ...(query.data?.items || [])],
    [],
  )

  const visibleItems = items.slice(0, limit)

  const columns: Array<EuiBasicTableColumn<AnyProject>> = [
    {
      name: <FormattedMessage id='projects.list.header.name' defaultMessage='Project' />,
      render: ({ name, endpoints }: AnyProject) => (
        <EuiText size='s'>
          <strong>
            {endpoints?.kibana ? (
              <EuiLink color='text' href={endpoints.kibana}>
                {name}
              </EuiLink>
            ) : (
              name
            )}
          </strong>
        </EuiText>
      ),
    },
    {
      name: <FormattedMessage id='projects.list.header.type' defaultMessage='Solution' />,
      width: '25%',
      render: ({ type }: AnyProject) => <FormattedMessage {...getPrettyProjectType(type)} />,
    },
    {
      name: (
        <FormattedMessage
          id='projects.list.header.region'
          defaultMessage='Cloud provider & region'
        />
      ),
      width: '25%',
      render: ({ region_id }: AnyProject) => (
        <span>
          <FormattedMessage {...getPrettyCloudProvider(region_id)} /> – {getOnlyRegionId(region_id)}
        </span>
      ),
    },
    {
      name: <FormattedMessage {...genericMessages.actions} />,
      width: '12em',
      align: 'right',
      render: ({ type, id, endpoints }: AnyProject) => (
        <EuiFlexGroup
          gutterSize='l'
          justifyContent='flexEnd'
          alignItems='baseline'
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            {endpoints?.kibana && (
              <EuiLink href={endpoints.kibana}>
                <FormattedMessage {...genericMessages.open} />
              </EuiLink>
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiLink onClick={() => history.push(projectUrl(type, id))}>
              <FormattedMessage {...genericMessages.manage} />
            </EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
  ]

  return (
    <div>
      {failedQueries.map(({ error }) => (
        <Fragment>
          <CuiAlert type='danger'>{error}</CuiAlert>
          <EuiSpacer size='m' />
        </Fragment>
      ))}

      <EuiBasicTable tableLayout='auto' items={visibleItems} columns={columns} />
    </div>
  )
}

export default ProjectList
