/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { intersection, isEqual, reject, without } from 'lodash'

import type {
  DeploymentTemplateInfoV2,
  ElasticsearchClusterTopologyElement,
  GlobalDeploymentTemplateInfo,
} from '@modules/cloud-api/v1/types'
import type {
  AnyTopologyElement,
  SliderType,
  SliderInstanceType,
  SliderNodeType,
  VersionNumber,
} from '@modules/ui-types'

import { satisfies } from '@/lib/semver'

import { getConfigForKey } from '../../store'
import { getDedicatedTemplateType } from '../deploymentTemplates/metadata'
import { getDedicatedGlobalTemplateType } from '../globalDeploymentTemplates/metadata'

import {
  alwaysSupportedSliderInstanceTypes,
  getExtraSupportedSliderInstanceTypes,
  getAllKnownSliderInstanceTypes,
  getAllKnownSliderTypes,
} from './sliders'

export function getSupportedSliderInstanceTypes(): SliderInstanceType[] {
  const explicitInstanceTypes = getConfigForKey(`OVERRIDING_INSTANCE_TYPES`)

  const supportedSliderTypes = Array.isArray(explicitInstanceTypes)
    ? explicitInstanceTypes
    : [...alwaysSupportedSliderInstanceTypes, ...getExtraSupportedSliderInstanceTypes()]

  return intersection(getAllKnownSliderInstanceTypes(), supportedSliderTypes)
}

export function getSupportedSliderInstanceTypesForVersion(
  version: VersionNumber | null,
): SliderInstanceType[] {
  const supportedSliderInstanceTypes = getSupportedSliderInstanceTypes()

  if (!version) {
    return supportedSliderInstanceTypes
  }

  const integrationsServerSupportedRange = getConfigForKey(
    `INTEGRATIONS_SERVER_SUPPORTED_VERSION_RANGE`,
  )
  const apmSupportedRange = getConfigForKey(`APM_SUPPORTED_VERSION_RANGE`)

  return supportedSliderInstanceTypes.filter((type) => {
    if (type === 'apm' && apmSupportedRange && !satisfies(version, apmSupportedRange)) {
      return false
    }

    if (
      type === 'integrations_server' &&
      integrationsServerSupportedRange &&
      !satisfies(version, integrationsServerSupportedRange)
    ) {
      return false
    }

    return true
  })
}

// There is no underlying `integrations_server` instance type - it is actually APM under the hood.
// As such, any query to actual Elasticsearch will error if we add integrations_server to the query
export function getSupportedQueryableSliderInstanceTypes(): SliderInstanceType[] {
  const supportedSliderInstanceTypes = getSupportedSliderInstanceTypes()

  return supportedSliderInstanceTypes.filter(
    (instanceType) => instanceType !== 'integrations_server',
  )
}

export function getSupportedSliderInstanceTypesWithoutEs(): Array<
  Exclude<SliderInstanceType, 'elasticsearch'>
> {
  return without(getSupportedSliderInstanceTypes(), `elasticsearch`)
}

export function getProductSliderTypes(): SliderType[] {
  return [
    ...getAllKnownSliderInstanceTypes().map((sliderInstanceType) => ({
      sliderInstanceType,
    })),
    {
      sliderInstanceType: `elasticsearch`,
      sliderNodeType: `ml`,
    },
  ]
}

export function getSupportedProductSliderTypes(): SliderType[] {
  return [
    ...getSupportedSliderInstanceTypes().map((sliderInstanceType) => ({
      sliderInstanceType,
    })),
    {
      sliderInstanceType: `elasticsearch`,
      sliderNodeType: `ml`,
    },
  ]
}

export function getNonDeprecatedProductSliderTypes(): SliderType[] {
  return reject(getSupportedProductSliderTypes(), (sliderType) =>
    isEqual(sliderType, {
      sliderInstanceType: `appsearch`,
    }),
  )
}

export function isSliderInstanceType(type: string): boolean {
  return getAllKnownSliderInstanceTypes().includes(type)
}

export function isSliderInstanceTypeSupportedInPlatform(
  sliderInstanceType: SliderInstanceType,
): boolean {
  const inPlatform = getSupportedSliderInstanceTypes().includes(sliderInstanceType)
  return inPlatform
}

export function isSliderInstanceTypeSupportedInTemplate(
  sliderInstanceType: SliderInstanceType,
  deploymentTemplate: DeploymentTemplateInfoV2 | undefined,
): boolean {
  if (!isSliderInstanceTypeSupportedInPlatform(sliderInstanceType)) {
    return false
  }

  return Boolean(deploymentTemplate?.deployment_template.resources?.[sliderInstanceType])
}

export function isTemplateSupportedInPlatform(
  deploymentTemplate: DeploymentTemplateInfoV2,
): boolean {
  const dedicatedMetadataItem = getDedicatedTemplateType(deploymentTemplate)

  if (dedicatedMetadataItem == null) {
    return true // no dedicated purpose for this template, so it's fine
  }

  return isSliderInstanceTypeSupportedInPlatform(dedicatedMetadataItem)
}

export function isGlobalTemplateSupportedInPlatform(
  globalTemplate: GlobalDeploymentTemplateInfo,
): boolean {
  const dedicatedMetadataItem = getDedicatedGlobalTemplateType(globalTemplate)

  if (dedicatedMetadataItem == null) {
    return true // no dedicated purpose for this template, so it's fine
  }

  return isSliderInstanceTypeSupportedInPlatform(dedicatedMetadataItem)
}

export function doesSliderInstanceTypeHaveNodeTypes(
  sliderInstanceType: SliderInstanceType,
): boolean {
  const instanceTypesWithoutNodeTypes = [`kibana`, `apm`, `integrations_server`]
  return !instanceTypesWithoutNodeTypes.includes(sliderInstanceType)
}

export function doesTopologyIncludeMl(
  nodeConfigurations: ElasticsearchClusterTopologyElement[],
): boolean {
  return doesTopologyIncludeNodeType({
    nodeConfigurations,
    sliderInstanceType: `elasticsearch`,
    sliderNodeType: `ml`,
  })
}

export function doesTopologyIncludeNodeType({
  nodeConfigurations,
  sliderInstanceType,
  sliderNodeType,
}: {
  nodeConfigurations: AnyTopologyElement[]
  sliderInstanceType: SliderInstanceType
  sliderNodeType: SliderNodeType
}): boolean {
  // manual checking of both node_type and node_roles here to avoid circular dependency
  return nodeConfigurations.some(
    (topologyElement) =>
      topologyElement[sliderInstanceType] &&
      ((topologyElement as ElasticsearchClusterTopologyElement).node_roles?.includes(
        sliderNodeType as any,
      ) ||
        (topologyElement as ElasticsearchClusterTopologyElement).node_type?.[sliderNodeType]),
  )
}

export function isValidSliderNodeType({
  sliderInstanceType,
  sliderNodeType,
}: {
  sliderInstanceType: SliderInstanceType
  sliderNodeType: string
}): boolean {
  const validSliderNodeTypes = getAllKnownSliderTypes()
    .filter((sliderType) => sliderType.sliderInstanceType === sliderInstanceType)
    .map((sliderType) => sliderType.sliderNodeType)
    .filter(Boolean)

  return validSliderNodeTypes.includes(sliderNodeType)
}
