/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { migrateDeploymentTemplateUrl } from '@modules/cloud-api/v1/urls'

import { Action } from '@/types/redux'
import asyncRequest, { resetAsyncRequest } from '@/actions/asyncRequests'
import { MIGRATE_DEPLOYMENT_TEMPLATE } from '@/constants/actions'

import type { RESET_ASYNC_REQUEST } from '@/constants/actions'

export function migrateDeploymentTemplate({
  deploymentId,
  templateId,
}: {
  deploymentId: string
  templateId: string
}) {
  const url = migrateDeploymentTemplateUrl({ deploymentId, templateId })

  return asyncRequest({
    type: MIGRATE_DEPLOYMENT_TEMPLATE,
    url,
    method: `GET`,
    meta: { deploymentId, templateId },
    crumbs: [deploymentId, templateId],
  })
}

export const resetMigrateDeploymentTemplate = (
  ...crumbs: string[]
): Action<typeof RESET_ASYNC_REQUEST> => resetAsyncRequest(MIGRATE_DEPLOYMENT_TEMPLATE, crumbs)
