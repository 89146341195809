/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { ReduxState } from '@/types/redux'
import { searchDeployments } from '@/actions/stackDeployments'
import { transformResourceQuery } from '@/components/StackDeploymentSearch/DeploymentFilterContext/queryParser'
import { searchStackDeploymentsRequest, getStackDeploymentsFromSearch } from '@/reducers'

import BillingUsageMainPage from './BillingUsageMainPage'
import { parseFiltersQuery } from './utils'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const queryId = 'billing-usage-deployments'

const getDeploymentsQuery = (organizationId: string) => ({
  size: 10000,
  query: {
    bool: {
      filter: [
        transformResourceQuery({
          path: `info.settings.metadata.organization_id`,
          queryType: `match`,
          query: { query: String(organizationId) },
        }),
      ],
    },
  },
})

const mapStateToProps = (
  state: ReduxState,
  { location: { search } }: ConsumerProps,
): StateProps => ({
  filters: parseFiltersQuery(search.slice(1)),
  deploymentsRequest: searchStackDeploymentsRequest(state, queryId),
  deployments: getStackDeploymentsFromSearch(state, queryId)?.deployments || [],
})

const mapDispatchToProps = (dispatch, { organizationId }: ConsumerProps): DispatchProps => ({
  fetchDeployments: () =>
    dispatch(searchDeployments({ queryId, query: getDeploymentsQuery(organizationId) })),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(BillingUsageMainPage),
)
