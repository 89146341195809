/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiBadge,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import { CuiAlert, CuiPermissibleControl } from '@modules/cui'
import history from '@modules/utils/history'
import Header from '@modules/cui/Header'

import { topologyInstanceConfigurationCrumbs } from '@/lib/crumbBuilder'
import { topologyEditInstanceConfigurationUrl } from '@/lib/urlBuilder'

import DangerButton from '../../../DangerButton'
import DeprecationWarning from '../DeprecationWarning'

import ConfigurationSummary from './components/ConfigurationSummary'
import Allocators from './components/Allocators'
import InstanceTypes from './components/InstanceTypes'
import Multipliers from './components/Multipliers'

import type { AllProps as Props } from './types'

import './instanceConfiguration.scss'

class InstanceConfigurationView extends Component<Props> {
  componentDidMount() {
    const { fetchInstanceConfiguration } = this.props
    fetchInstanceConfiguration()
  }

  render() {
    return (
      <Fragment>
        {this.renderTitle()}

        {this.renderContent()}
      </Fragment>
    )
  }

  renderTitle() {
    const { regionId, instanceId, instanceConfiguration } = this.props

    if (!instanceConfiguration) {
      return (
        <Header
          breadcrumbs={topologyInstanceConfigurationCrumbs({ regionId, instanceId })}
          name={
            <FormattedMessage
              id='instance-configuration-view.title-placeholder'
              defaultMessage='Loading instance configuration …'
            />
          }
        />
      )
    }

    const { name, system_owned } = instanceConfiguration

    return (
      <Header
        breadcrumbs={topologyInstanceConfigurationCrumbs({ regionId, instanceId })}
        name={
          <EuiFlexGroup gutterSize='m' alignItems='center'>
            <EuiFlexItem grow={false}>
              <div>{name}</div>
            </EuiFlexItem>

            {system_owned && (
              <EuiFlexItem grow={false}>
                <EuiBadge color='primary'>
                  <FormattedMessage
                    id='instance-configuration-summary.system-owned-label'
                    defaultMessage='System owned'
                  />
                </EuiBadge>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        }
      >
        {this.renderHeaderActions()}
        <EuiSpacer size='s' />
      </Header>
    )
  }

  renderHeaderActions() {
    const {
      regionId,
      instanceId,
      instanceConfiguration,
      deleteInstanceConfiguration,
      deleteInstanceConfigurationRequest,
    } = this.props

    const systemOwned = instanceConfiguration.system_owned

    const { name } = instanceConfiguration

    return (
      <EuiFlexGroup justifyContent='flexEnd' alignItems='center' gutterSize='s'>
        {!systemOwned && (
          <EuiFlexItem grow={false}>
            <DangerButton
              onConfirm={deleteInstanceConfiguration}
              isEmpty={true}
              modal={{
                confirmButtonText: (
                  <FormattedMessage
                    id='instance-configuration-view.delete-modal.confirm'
                    defaultMessage='Delete instance configuration'
                  />
                ),
                title: (
                  <FormattedMessage
                    id='instance-configuration-view.delete-modal.title'
                    defaultMessage='Delete "{name}"?'
                    values={{
                      name,
                    }}
                  />
                ),
              }}
              isBusy={deleteInstanceConfigurationRequest.inProgress}
            >
              <FormattedMessage
                id='instance-configuration-view.delete-button'
                defaultMessage='Delete'
              />
            </DangerButton>
          </EuiFlexItem>
        )}

        <EuiFlexItem grow={false}>
          <CuiPermissibleControl permissions={Permission.setInstanceConfiguration}>
            <EuiButton
              onClick={() =>
                history.push(topologyEditInstanceConfigurationUrl(regionId, instanceId))
              }
            >
              <FormattedMessage
                id='instance-configuration-view.edit-button'
                defaultMessage='Edit instance configuration'
              />
            </EuiButton>
          </CuiPermissibleControl>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderContent() {
    const { instanceConfiguration, instanceConfigurationRequest } = this.props

    if (instanceConfigurationRequest.error) {
      return <CuiAlert type='error'>{instanceConfigurationRequest.error}</CuiAlert>
    }

    if (instanceConfigurationRequest.inProgress || instanceConfiguration == null) {
      return (
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size='l' />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <FormattedMessage
                id='instance-configuration-view.loading'
                defaultMessage='Loading …'
              />
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    return this.renderOverview()
  }

  renderOverview() {
    const {
      instanceConfiguration,
      instanceConfiguration: {
        description,
        allocator_filter: clauses,
        storage_multiplier: storageMultiplier,
        discrete_sizes: { resource, sizes, default_size: defaultSize },
        max_zones,
      },
      regionId,
      searchAllocatorsSimpleQuery,
      searchAllocatorsRequest,
      searchResults,
    } = this.props

    return (
      <Fragment>
        <DeprecationWarning instanceConfiguration={instanceConfiguration} />

        <ConfigurationSummary description={description} />

        <EuiSpacer />

        <Multipliers
          storageMultiplier={storageMultiplier}
          resource={resource}
          sizes={sizes}
          defaultSize={defaultSize}
          maxZones={max_zones}
        />

        <EuiSpacer />

        <InstanceTypes instanceConfiguration={instanceConfiguration} />

        <EuiSpacer />

        <Allocators
          clauses={clauses}
          regionId={regionId}
          searchAllocatorsQuery={searchAllocatorsSimpleQuery}
          searchAllocatorsRequest={searchAllocatorsRequest}
          searchResults={searchResults}
        />
      </Fragment>
    )
  }
}

export default injectIntl(InstanceConfigurationView)
