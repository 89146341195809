/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiPageHeader, EuiSpacer } from '@elastic/eui'

import ProjectList from '@modules/project-components/ProjectList'
import { projectsCrumbs } from '@modules/project-lib/breadcrumbs'
import history from '@modules/utils/history'
import { BreadcrumbsContext } from '@modules/cui/Breadcrumbs'
import Breadcrumbs from '@modules/cui/Breadcrumbs/Breadcrumbs'

type Props = unknown

const ProjectsPage: React.FunctionComponent<Props> = () => (
  <div>
    <BreadcrumbsContext.Consumer>
      {({ breadcrumbsRef }) => (
        <Breadcrumbs breadcrumbsRef={breadcrumbsRef} breadcrumbs={projectsCrumbs()} />
      )}
    </BreadcrumbsContext.Consumer>

    <EuiPageHeader
      bottomBorder={true}
      pageTitle={<FormattedMessage id='projects.page.list.title' defaultMessage='Projects' />}
      rightSideGroupProps={{ gutterSize: 'm' }}
      rightSideItems={[
        <EuiButton fill={true} color='primary' onClick={() => history.push(`/create/serverless`)}>
          <FormattedMessage id='projects.page.list.createProject' defaultMessage='Create project' />
        </EuiButton>,
      ]}
    />

    <EuiSpacer />

    <ProjectList />
  </div>
)

export default ProjectsPage
