/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, size } from 'lodash'

import { EuiSpacer } from '@elastic/eui'

import type { ContainerSetDetails, Container } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, getFilterQueryString, setFilterQueryString } from '@modules/cui'
import type { ControlledFilterQuery, OnFilterChangeParams } from '@modules/cui'
import Header from '@modules/cui/Header'

import { containerSetCrumbs } from '../../lib/crumbBuilder'

import ContainersTable from './ContainersTable'
import ContainerSetSchedule from './ContainerSetSchedule'
import ContainersFilterContext from './ContainersFilterContext'

type Props = {
  regionId: string
  containerSetId: string
  containerSet: ContainerSetDetails | null
  fetchContainerSetRequest: AsyncRequestState
  fetchContainerSet: () => void
}

type State = {
  query: ControlledFilterQuery
  queryResults: Container[]
}

class ContainerSetOverview extends Component<Props, State> {
  state: State = {
    query: getFilterQueryString({ storageKey: `ContainerSet/Containers` }),
    queryResults: [],
  }

  render() {
    const { regionId, containerSetId, fetchContainerSetRequest } = this.props
    const isLoading = fetchContainerSetRequest.inProgress

    return (
      <Fragment>
        <Header
          name={
            <FormattedMessage id='container-set-overview.title' defaultMessage='Container set' />
          }
          breadcrumbs={containerSetCrumbs({ regionId, containerSetId })}
        />

        <ContainerSetSchedule search={this.search} busy={isLoading} />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const { containerSet, fetchContainerSetRequest } = this.props
    const { query } = this.state
    const isLoading = fetchContainerSetRequest.inProgress

    if (fetchContainerSetRequest.error) {
      return <CuiAlert type='error'>{fetchContainerSetRequest.error}</CuiAlert>
    }

    return (
      <Fragment>
        <ContainersFilterContext
          query={query}
          onChange={this.onChange}
          containers={containerSet ? this.getContainers() : undefined}
          isLoading={isLoading}
        />

        {this.renderContainersTable()}
      </Fragment>
    )
  }

  renderContainersTable() {
    const { regionId, containerSet, containerSetId } = this.props
    const { queryResults } = this.state

    const loadedButNoMatches = containerSet && isEmpty(queryResults)

    if (loadedButNoMatches) {
      return null
    }

    const totalCount = size(this.getContainers())

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <ContainersTable
          regionId={regionId}
          containerSetId={containerSetId}
          containers={queryResults}
          totalCount={totalCount}
          initialLoading={!containerSet}
        />
      </Fragment>
    )
  }

  search = () => {
    const { fetchContainerSet } = this.props

    fetchContainerSet()
  }

  onChange = ({ queryText, queryResults }: OnFilterChangeParams<Container>) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `ContainerSet/Containers`, queryText })
  }

  getContainers = () => {
    const { containerSet } = this.props

    if (!containerSet) {
      return []
    }

    if (!Array.isArray(containerSet.containers)) {
      return []
    }

    return containerSet.containers
  }
}

export default ContainerSetOverview
