/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, intersection } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText } from '@elastic/eui'

import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type {
  AnyResourceInfo,
  AsyncRequestState,
  SliderInstanceType,
  VersionNumber,
} from '@modules/ui-types'
import { CuiAlert, CuiLink } from '@modules/cui'

import PureJsonEditor from '../../PureJsonEditor'
import { getSliderPrettyName, getSupportedSliderInstanceTypes } from '../../../lib/sliders'
import { hasOngoingResourceTypeConfigurationChange } from '../../../lib/stackDeployments/selectors'
import { deploymentActivityUrl } from '../../../lib/urlBuilder'

import type { WithStackDeploymentRouteParamsProps } from '../../StackDeploymentEditor'

type StateProps = {
  deploymentId: string
  deployment: DeploymentGetResponse | null
  setDeploymentResourceMetadataRequest: (
    deploymentId: string,
    resourceType: SliderInstanceType,
    resourceRefId: string,
  ) => AsyncRequestState
}

type DispatchProps = {
  setDeploymentResourceMetadata: (params: {
    deploymentId: string
    resourceType: SliderInstanceType
    resourceRefId: string
    version: string | null
    metadata?: unknown
  }) => void
}

type ConsumerProps = WithStackDeploymentRouteParamsProps

type Props = StateProps & DispatchProps & ConsumerProps

type State = {
  editorStates: ResourceMetadataEditorStates
}

type ResourceMetadataEditorState = {
  resource: AnyResourceInfo
  data?: unknown
  version: string | null
}

type ResourceMetadataEditorStates = {
  [kind: string]: ResourceMetadataEditorState[]
}

class EditStackDeploymentAdvancedDataEditor extends Component<Props, State> {
  state: State = {
    editorStates: createUpdateMetadataEditorStatesFromGetResponse({
      deployment: this.props.deployment,
    }),
  }

  render() {
    const { editorStates } = this.state
    const sliderInstanceTypes = getSupportedSliderInstanceTypes()
    const resourceTypes = Object.keys(editorStates)
    const supportedResourceTypes = intersection(resourceTypes, sliderInstanceTypes)

    return (
      <Fragment>
        {supportedResourceTypes.map((sliderInstanceType) => {
          const version = editorStates[sliderInstanceType][0]?.version

          return (
            <Fragment key={sliderInstanceType}>
              <EuiSpacer size='m' />

              <EuiText>
                <h3>
                  <FormattedMessage
                    id='edit-stack-deployment-advanced-data-editor.resource-data'
                    defaultMessage='{sliderPrettyName} cluster data'
                    values={{
                      sliderPrettyName: (
                        <FormattedMessage
                          {...getSliderPrettyName({ sliderInstanceType, version })}
                        />
                      ),
                    }}
                  />
                </h3>
              </EuiText>

              <EuiSpacer size='s' />

              {this.renderResourceMetadataEditors(sliderInstanceType)}
            </Fragment>
          )
        })}
      </Fragment>
    )
  }

  renderResourceMetadataEditors(sliderInstanceType) {
    const { deployment, setDeploymentResourceMetadata, setDeploymentResourceMetadataRequest } =
      this.props

    const { editorStates } = this.state

    const { id } = deployment!

    return (
      <Fragment>
        {editorStates[sliderInstanceType].map(({ resource, version, data }) => {
          const { ref_id } = resource
          const request = setDeploymentResourceMetadataRequest(id, sliderInstanceType, ref_id)

          const changingPlan = hasOngoingResourceTypeConfigurationChange({
            deployment: deployment!,
            resourceType: sliderInstanceType,
          })

          return (
            <PureJsonEditor
              key={resource.ref_id}
              initialValue={data || null}
              message={this.getSaveMessage(request, sliderInstanceType, version)}
              isSaving={request.inProgress}
              save={(metadata) =>
                setDeploymentResourceMetadata({
                  deploymentId: id,
                  resourceType: sliderInstanceType,
                  resourceRefId: ref_id,
                  version,
                  metadata: metadata || undefined,
                })
              }
              readonly={changingPlan}
              readonlyHelpText={
                <FormattedMessage
                  id='edit-stack-deployment-advanced-plan-editor.metadata-update-disabled-while-ongoing-changes'
                  defaultMessage='{sliderPrettyName} metadata cannot be updated while a configuration change is in progress. {seeActivity}.'
                  values={{
                    sliderPrettyName: (
                      <FormattedMessage {...getSliderPrettyName({ sliderInstanceType, version })} />
                    ),
                    seeActivity: (
                      <CuiLink to={deploymentActivityUrl(id)}>
                        <FormattedMessage
                          id='edit-stack-deployment-advanced-plan-editor.see-activity'
                          defaultMessage='Go to Activity'
                        />
                      </CuiLink>
                    ),
                  }}
                />
              }
            />
          )
        })}
      </Fragment>
    )
  }

  getSaveMessage(
    request: AsyncRequestState,
    sliderInstanceType: SliderInstanceType,
    version: VersionNumber | null,
  ) {
    if (request.isDone) {
      return (
        <CuiAlert type='info'>
          <FormattedMessage
            id='edit-stack-deployment-advanced-data-editor.cluster-data-updated'
            defaultMessage='{sliderPrettyName} data updated'
            values={{
              sliderPrettyName: (
                <FormattedMessage {...getSliderPrettyName({ sliderInstanceType, version })} />
              ),
            }}
          />
        </CuiAlert>
      )
    }

    if (request.error) {
      return <CuiAlert type='error'>{request.error}</CuiAlert>
    }

    return null
  }
}

export default EditStackDeploymentAdvancedDataEditor

function createUpdateMetadataEditorStatesFromGetResponse({
  deployment,
}): ResourceMetadataEditorStates {
  const { resources } = deployment
  const sliderInstanceTypes = getSupportedSliderInstanceTypes()
  const resourceTypes = Object.keys(resources)
  const supportedResourceTypes = intersection(resourceTypes, sliderInstanceTypes)

  const states: ResourceMetadataEditorStates = {}

  for (const sliderInstanceType of supportedResourceTypes) {
    const clusters: AnyResourceInfo[] = resources[sliderInstanceType]

    for (const resource of clusters) {
      const { metadata } = resource.info

      const dataObject: ResourceMetadataEditorState = {
        resource,
        data: (metadata && cloneDeep(metadata.raw)) || {},
        version: (metadata && String(metadata.version)) || null,
      }

      if (!Array.isArray(states[sliderInstanceType])) {
        states[sliderInstanceType] = []
      }

      states[sliderInstanceType].push(dataObject)
    }
  }

  return states
}
