/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages } from 'react-intl'
import { uniq } from 'lodash'

import type { SearchFilterConfig } from '@elastic/eui'
import { EuiBadge } from '@elastic/eui'

import type { RegionProxy } from '@modules/ui-types'

import type { IntlShape } from 'react-intl'

const messages = defineMessages({
  zonesLabel: {
    id: `proxy-filter-context.zones-label`,
    defaultMessage: `Zones`,
  },
  healthyLabel: {
    id: `proxy-filter-context.healthy-label`,
    defaultMessage: `Healthy`,
  },
  unhealthyLabel: {
    id: `proxy-filter-context.unhealthy-label`,
    defaultMessage: `Unhealthy`,
  },
})

export function getFilters({
  intl: { formatMessage },
  proxies = [],
}: {
  intl: IntlShape
  proxies?: RegionProxy[]
}): SearchFilterConfig[] {
  const { zones } = getOptionLists(proxies)

  const filters: SearchFilterConfig[] = [
    {
      name: formatMessage(messages.zonesLabel),
      type: `field_value_selection`,
      field: `zone`,
      multiSelect: `or`,
      options: zones,
    },
    {
      type: `field_value_toggle_group`,
      field: `healthy`,
      items: [
        {
          name: formatMessage(messages.healthyLabel),
          value: `y`,
        },
        {
          name: formatMessage(messages.unhealthyLabel),
          value: `n`,
        },
      ],
    },
  ]

  return filters
}

function getOptionLists(proxies: RegionProxy[]) {
  const zones = uniq(proxies.map((proxy) => proxy.availabilityZone))
    .sort()
    .map((zone) => ({
      view: renderBadge(zone),
      value: zone,
    }))

  return {
    zones,
  }
}

function renderBadge(value) {
  return (
    <div>
      <EuiBadge>{value}</EuiBadge>
    </div>
  )
}
