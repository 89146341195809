/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { definitions, DeploymentHealth } from '@modules/cloud-api/v1/types'
import type {
  AllocatorSearchResult,
  AsyncRequestState,
  StackDeployment,
  SliderInstanceType,
} from '@modules/ui-types'

import { getMasterProblems } from '@/lib/healthProblems/stackDeploymentHealth/healthReporters/masterHealthReporter'
import { getIlmMigrationProblems } from '@/lib/healthProblems/stackDeploymentHealth/healthReporters/ilmMigrationHealthReporter'
import { getShardProblems } from '@/lib/healthProblems/stackDeploymentHealth/healthReporters/shardHealthReporter'
import { getInstanceProblems } from '@/lib/healthProblems/stackDeploymentHealth/healthReporters/instanceHealthReporter'
import { getAllocatorHealthProblems } from '@/lib/healthProblems/stackDeploymentHealth/healthReporters/allocatorHealthReporter'

import type { Problem } from '../../problems'

/**
 * Legacy reporter for deployment heath issues by resource. This should report on issues that are currently being reported by the
 * Deployment Health API, but only in contexts where the deployment health API is not yet available
 * (deployment list is only place I am aware of). This should ideally be removed and replaced by the
 * DeploymentHealthApiProblemReporter for all cases, but in the meantime this is
 * here. If we redesign DeploymentList to use deployment health API, this can go away.
 */
export function getLegacyDeploymentProblemsFromResource(
  deployment: StackDeployment,
  resource: definitions['ElasticsearchResourceInfo'],
  sliderInstanceType: SliderInstanceType,
  health?: DeploymentHealth | null,
  deploymentHealthRequest?: AsyncRequestState,
  deploymentAllocators?: AllocatorSearchResult[],
): Problem[] {
  if (!shouldRunLegacyHealthReporter(health, deploymentHealthRequest)) {
    return []
  }

  const problems: Problem[] = []

  if (sliderInstanceType === 'elasticsearch') {
    problems.push(...getMasterProblems(resource))
    problems.push(...getShardProblems(resource))
    problems.push(...getIlmMigrationProblems(deployment))
  }

  problems.push(...getInstanceProblems(deployment, resource, sliderInstanceType))
  problems.push(...getAllocatorHealthProblems(deploymentAllocators))

  return problems
}

/**
 * Legacy reporter for deployment heath issues that are not deployment resource specific (for example, not tied to
 * Kibana or Elasticsearch).
 */
export function getLegacyDeploymentProblems(
  health?: DeploymentHealth | null,
  deploymentHealthRequest?: AsyncRequestState,
  deploymentAllocators?: AllocatorSearchResult[],
): Problem[] {
  if (!shouldRunLegacyHealthReporter(health, deploymentHealthRequest)) {
    return []
  }

  const problems: Problem[] = []

  problems.push(...getAllocatorHealthProblems(deploymentAllocators))
  return problems
}

/**
 * The problems collected by this reporter are already handled by the deploymentHealth reporter. If that reporter is active
 * in this context, then this reporter should not run. This reporter should be considered active
 * if there is no current deploymentHealthRequest or that request is complete and no results were
 * reported.
 */
export function shouldRunLegacyHealthReporter(
  health?: DeploymentHealth | null,
  deploymentHealthRequest?: AsyncRequestState,
) {
  return !(health || deploymentHealthRequest?.inProgress)
}
