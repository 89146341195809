/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormHelpText, EuiSpacer } from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { AsyncRequestState, StackDeployment } from '@modules/ui-types'
import { CuiAlert, CuiPermissibleControl } from '@modules/cui'

import { hasProxyLogging } from '@/lib/stackDeployments/selectors/metadata'
import { replaceIn } from '@/lib/immutability-helpers'

import SpinButton from '../../SpinButton'
import FormGroup from '../../FormGroup'

import type { FunctionComponent } from 'react'

import './proxyLogging.scss'

export type StateProps = {
  setDeploymentResourceMetadataRequest: AsyncRequestState
}

export type ConsumerProps = {
  deployment: StackDeployment
}

export type DispatchProps = {
  updateMetadata: (params: {
    deployment: StackDeployment
    updater: (metadata: { [key: string]: any }) => { [key: string]: any }
    requestMeta?: { [key: string]: any }
  }) => void
}

export type Props = StateProps & ConsumerProps & DispatchProps

const setProxyLoggingType = 'set-proxy-logging'

const ProxyLogging: FunctionComponent<Props> = ({
  setDeploymentResourceMetadataRequest,
  deployment,
  updateMetadata,
}) => {
  const { inProgress, error } = setDeploymentResourceMetadataRequest
  const updateRequestIsRelevant =
    setDeploymentResourceMetadataRequest.meta.type === setProxyLoggingType
  const proxyLogging = hasProxyLogging({ deployment })

  const update = () =>
    updateMetadata({
      deployment,
      updater: (metadata) => replaceIn(metadata, ['proxy', 'logging'], !proxyLogging),
      requestMeta: {
        type: setProxyLoggingType,
      },
    })

  return (
    <Fragment>
      <FormGroup
        label={
          <FormattedMessage
            id='cluster-manage-proxy-logging.proxy-logging-title'
            defaultMessage='Proxy Logging'
          />
        }
      >
        <EuiSpacer size='s' />

        <CuiPermissibleControl permissions={Permission.setDeploymentResourceRawMetadata}>
          <SpinButton
            data-test-id='deployment-ops.proxy-logging.button'
            color='primary'
            onClick={update}
            spin={updateRequestIsRelevant && inProgress}
          >
            {proxyLogging && (
              <FormattedMessage
                id='cluster-manage-proxy-logging.turn-off'
                defaultMessage='Turn off'
              />
            )}
            {!proxyLogging && (
              <FormattedMessage
                id='cluster-manage-proxy-logging.turn-on'
                defaultMessage='Turn on'
              />
            )}
          </SpinButton>
        </CuiPermissibleControl>

        <EuiFormHelpText>
          <FormattedMessage
            id='cluster-manage-proxy-logging.description'
            defaultMessage='Log requests routed to this cluster by the proxy. Turning off logging can help when the volume of requests routed by the proxies is high and logs grow in size quickly.'
          />
        </EuiFormHelpText>

        {updateRequestIsRelevant && error && (
          <CuiAlert
            data-test-id='deployment-ops.proxy-logging.error'
            className='proxyLogging-error'
            type='error'
          >
            {error}
          </CuiAlert>
        )}
      </FormGroup>

      <EuiSpacer />
    </Fragment>
  )
}

export default ProxyLogging
