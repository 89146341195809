/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { capitalize } from 'lodash'

import { EuiText, EuiEmptyPrompt, EuiHealth } from '@elastic/eui'

import type { LineItem } from '@modules/cloud-api/v1/types'
import { CuiTable } from '@modules/cui'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { CuiTableColumn } from '@modules/cui'

import { getThemeColors } from '@/lib/theme'
import { numericDateTime } from '@/config/dates'
import { isActiveItem, isExpiredItem, isFutureItem } from '@/lib/billing'

import DetailsGridPopover from '../DetailsGridPopover'

import type { FilterState } from '../types'
import type { ReactElement } from 'react'

const colors = getThemeColors()

interface Props {
  prepaidBalanceLineItems: LineItem[]
  initialLoading?: boolean
  filter: FilterState
  rows: LineItem[]
  rowClass: (item: LineItem) => string
}

class PrepaidsTable extends PureComponent<Props> {
  render(): ReactElement {
    const { initialLoading, rows, rowClass, filter } = this.props
    const gridColumns = this.getGridColumns()

    if (!initialLoading && rows.length === 0) {
      return (
        <EuiEmptyPrompt
          title={
            <EuiText size='xs'>
              <h2>
                <FormattedMessage
                  data-test-id={`empty-state-filter-${filter}`}
                  id='billing-details-summary.empty-grid.no-active-balances'
                  defaultMessage='You have no {filter} balances'
                  values={{ filter }}
                />
              </h2>
            </EuiText>
          }
          body={
            <EuiText size='s'>
              <FormattedMessage
                id='billing-details-summary.empty-grid-body.no-active-balances'
                defaultMessage='{filter} balances will show up here.'
                values={{ filter: capitalize(filter) }}
              />
            </EuiText>
          }
        />
      )
    }

    return (
      <CuiTable<LineItem>
        getRowId={(item) => item.id}
        getRowTestSubj={(item) => `table-line-item-${item.id}`}
        initialLoading={initialLoading}
        rows={rows}
        columns={gridColumns.items}
        rowClass={rowClass}
        initialSort={gridColumns.initialSort}
        initialSortDirection='asc'
      />
    )
  }

  renderBalanceColumn(value: number): ReactElement {
    return (
      <EuiText size='s' className='prepaid-account-details-ecu'>
        <CuiElasticConsumptionUnits unit='none' value={value} />
      </EuiText>
    )
  }

  getStatusCell = (props: Pick<LineItem, 'start' | 'end'>) => {
    const date = new Date()

    if (isActiveItem(props, date)) {
      return (
        <EuiHealth color={colors.euiColorSuccess}>
          <FormattedMessage id='billing-details-summary.active-label' defaultMessage='Active' />
        </EuiHealth>
      )
    }

    if (isExpiredItem(props, date)) {
      return (
        <EuiHealth color={colors.euiColorMediumShade}>
          <FormattedMessage id='billing-details-summary.expired-label' defaultMessage='Expired' />
        </EuiHealth>
      )
    }

    if (isFutureItem(props, date)) {
      return (
        <EuiHealth color={colors.euiColorWarning}>
          <FormattedMessage id='billing-details-summary.future-label' defaultMessage='Future' />
        </EuiHealth>
      )
    }

    return
  }

  getGridColumns(): {
    initialSort: CuiTableColumn<LineItem>
    items: Array<CuiTableColumn<LineItem>>
  } {
    const expirationDateColumn: CuiTableColumn<LineItem> = {
      label: (
        <FormattedMessage
          id='prepaid-account-details.expiration-date'
          defaultMessage='Expiration date'
        />
      ),
      render: ({ end }) => (
        <FormattedDate
          data-test-id='expiration-date-column-value'
          value={end}
          {...numericDateTime}
        />
      ),
      sortKey: ({ end }) => new Date(end),
      width: '250px',
    }

    return {
      initialSort: expirationDateColumn,
      items: [
        {
          label: <FormattedMessage id='prepaid-account-details.status' defaultMessage='Status' />,
          render: ({ start, end }) => this.getStatusCell({ start, end }),
          width: '90px',
        },
        {
          label: (
            <FormattedMessage
              id='prepaid-account-details.initial-balance'
              defaultMessage='Credits'
            />
          ),
          render: ({ ecu_quantity }) => this.renderBalanceColumn(ecu_quantity),
          align: 'right',
        },
        {
          label: (
            <FormattedMessage id='prepaid-account-details.amount-spent' defaultMessage='Used' />
          ),
          render: ({ ecu_balance, ecu_quantity }) =>
            this.renderBalanceColumn(ecu_quantity - ecu_balance),
          align: 'right',
        },
        {
          label: (
            <FormattedMessage
              id='prepaid-account-details.remaining-balance'
              defaultMessage='Remaining balance'
            />
          ),
          render: ({ ecu_balance }) => this.renderBalanceColumn(ecu_balance),
          align: 'right',
        },
        expirationDateColumn,
        {
          label: (
            <FormattedMessage
              id='prepaid-account-details.contract-info'
              defaultMessage='Contract info'
            />
          ),
          render: (lineItem) => <DetailsGridPopover details={lineItem} />,
        },
      ],
    }
  }
}

export default PrepaidsTable
