/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import type { DeploymentCostsAggregation } from '@modules/ui-types/billing'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import { CuiTable, CuiHelpTipIcon } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import { aggregateCosts, aggregateCostsForDeployment } from '@/lib/billing'

import { VisibleDeployments } from '../../constants'
import AggregationTableRow from '../AggregationTableRow'
import DeploymentListNameCell from '../DeploymentListNameCell'

import type { AllProps } from './types'

import './deploymentListUsageTable.scss'

class DeploymentListUsageTable extends Component<AllProps> {
  static defaultProps = {
    visibleDeployments: VisibleDeployments,
  }

  state = {
    showDetails: false,
  }

  render() {
    const { isLoading, visibleDeployments, onDeploymentSelected, deploymentsCosts } = this.props
    const { showDetails } = this.state
    const costs = deploymentsCosts?.deployments || []
    const items = costs.map(aggregateCostsForDeployment).sort((a, b) => b.total - a.total)
    const visibleItems = items.slice(0, visibleDeployments)
    const collapsedItems = items.slice(visibleDeployments)
    const collapsedTotals = aggregateCosts(collapsedItems)
    const columns = this.getColumns(aggregateCosts(items))

    return (
      <CuiTable
        className='billing-deployments-list-usage-table'
        rows={visibleItems}
        columns={columns}
        initialLoading={isLoading}
        hasFooterRow={true}
        responsive={false}
        renderCustomRowsLast={() => (
          <AggregationTableRow
            onDeploymentSelected={onDeploymentSelected}
            totals={collapsedTotals}
            aggregations={collapsedItems}
            showDetails={showDetails}
            onToggle={() => this.setState({ showDetails: !showDetails })}
          />
        )}
      />
    )
  }

  renderEcuValue(value: number) {
    return <CuiElasticConsumptionUnits value={value} unit='none' />
  }

  getColumns({
    capacity: totalCapacity,
    dataTransfer: totalDataTransfer,
    storage: totalStorage,
    total: grandTotal,
  }: DeploymentCostsAggregation): Array<CuiTableColumn<DeploymentCostsAggregation>> {
    const { onDeploymentSelected, deployments } = this.props
    const deploymentsIds = deployments.map(({ id }) => id)

    return [
      {
        label: (
          <FormattedMessage
            id='billing-usage-list.deployment-name-label'
            defaultMessage='Deployment name'
          />
        ),
        header: {
          className: 'name-header',
        },
        width: '230px',
        render: ({ name, id }) => (
          <DeploymentListNameCell
            name={name}
            id={id}
            onClick={() => onDeploymentSelected({ selectedDeploymentId: id })}
            isDeleted={!deploymentsIds.includes(id)}
          />
        ),
        footer: {
          render: () => (
            <Fragment>
              <FormattedMessage id='billing-deployment-usage.total' defaultMessage='Total usage' />
              <CuiHelpTipIcon iconType='iInCircle'>
                <FormattedMessage
                  id='billing-deployment-usage.tip-icon'
                  defaultMessage='Usage only. Does not include credits, prepaids, or any other discounts.'
                />
              </CuiHelpTipIcon>
            </Fragment>
          ),
        },
      },
      {
        label: (
          <FormattedMessage id='billing-usage-list.capacity-label' defaultMessage='Capacity' />
        ),
        render: ({ capacity }) => this.renderEcuValue(capacity),
        footer: {
          render: () => this.renderEcuValue(totalCapacity),
        },
      },
      {
        label: (
          <FormattedMessage
            id='billing-usage-list.data-transfer-label'
            defaultMessage='Data transfer'
          />
        ),
        render: ({ dataTransfer }) => this.renderEcuValue(dataTransfer),
        footer: {
          render: () => this.renderEcuValue(totalDataTransfer),
        },
      },
      {
        label: (
          <FormattedMessage id='billing-usage-list.snapshots-label' defaultMessage='Snapshots' />
        ),
        render: ({ storage }) => this.renderEcuValue(storage),
        footer: {
          render: () => this.renderEcuValue(totalStorage),
        },
      },
      {
        label: <FormattedMessage id='billing-usage-list.total-label' defaultMessage='Total' />,
        render: ({ total }) => this.renderEcuValue(total),
        footer: {
          render: () => this.renderEcuValue(grandTotal),
        },
      },
    ]
  }
}

export default DeploymentListUsageTable
