/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiToolTip } from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui'

import type { FunctionComponent } from 'react'
import type { AllProps } from './types'

const CustomerOnlyAction: FunctionComponent<AllProps> = ({
  children,
  appName,
  appPlatform,
  message,
}) => {
  if (appPlatform !== 'saas') {
    throw new Error(`${CustomerOnlyAction.name} can only be used when APP_PLATFORM === saas`)
  }

  if (appName === 'adminconsole') {
    const disabledAction = React.cloneElement(children, { disabled: true })
    const content = message || (
      <FormattedMessage
        id='customer-only-action.disabled-help-text'
        defaultMessage='This action can only be performed by customers.'
      />
    )

    return <EuiToolTip content={content}>{disabledAction}</EuiToolTip>
  }

  return <Fragment>{children}</Fragment>
}

export default withErrorBoundary(CustomerOnlyAction)
