/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { DeploymentTemplateInfoV2 } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import {
  fetchDeploymentTemplates,
  updatePendingTemplateName,
  updatePendingTemplateDescription,
} from '../../../../actions/topology/deploymentTemplates'
import { fetchDeploymentTemplatesRequest, getDeploymentTemplates } from '../../../../reducers'
import { isFeatureActivated } from '../../../../selectors'
import withPolling from '../../../../lib/withPolling'

import DeploymentTemplateList from './DeploymentTemplateList'

import type { ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  regionId: string
  deploymentTemplates?: DeploymentTemplateInfoV2[]
  deploymentTemplatesRequest: AsyncRequestState
  showIlmMigrationCallout: boolean
}

type DispatchProps = {
  fetchDeploymentTemplates: () => Promise<any>
  updatePendingTemplateName: (name: string) => void
  updatePendingTemplateDescription: (description: string) => void
}

type RouteParams = {
  regionId: string
}

type ConsumerProps = RouteComponentProps<RouteParams> & unknown

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  deploymentTemplates: getDeploymentTemplates(state, regionId, null),
  deploymentTemplatesRequest: fetchDeploymentTemplatesRequest(state, regionId),
  showIlmMigrationCallout: isFeatureActivated(state, Feature.ilmTemplateMigrationFeature),
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  fetchDeploymentTemplates: () =>
    dispatch(
      fetchDeploymentTemplates({
        regionId,
        stackVersion: null,
        showMaxZones: true,
      }),
    ),
  updatePendingTemplateName: (name: string) => dispatch(updatePendingTemplateName(name)),
  updatePendingTemplateDescription: (description: string) =>
    dispatch(updatePendingTemplateDescription(description)),
})

const PollingDeploymentTemplateList = withPolling(
  DeploymentTemplateList,
  ({ fetchDeploymentTemplates: onPoll }) => ({
    onPoll,
  }),
)

const ConnectedDeploymentTemplateList = connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PollingDeploymentTemplateList)

export default ConnectedDeploymentTemplateList
