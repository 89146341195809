/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { captureDeploymentInstanceThreadDumpUrl } from '@modules/cloud-api/v1/urls'
import type { SliderInstanceType } from '@modules/ui-types'
import type { AjaxBlobResult } from '@modules/utils/ajax'

import asyncRequest from '../asyncRequests'
import { CAPTURE_THREAD_DUMP } from '../../constants/actions'

export function captureThreadDump({
  deploymentId,
  resourceKind,
  refId,
  instanceId,
}: {
  deploymentId: string
  resourceKind: SliderInstanceType
  refId: string
  instanceId: string
}) {
  const url = captureDeploymentInstanceThreadDumpUrl({
    deploymentId,
    resourceKind,
    refId,
    instanceId,
  })

  return asyncRequest<typeof CAPTURE_THREAD_DUMP, AjaxBlobResult>({
    type: CAPTURE_THREAD_DUMP,
    method: 'post',
    url,
    requestSettings: {
      binary: 'true',
    },
    meta: {
      deploymentId,
      resourceKind,
      refId,
      instanceId,
    },
    crumbs: [deploymentId, refId, instanceId],
  })
}
