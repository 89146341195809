/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import Cookies from 'js-cookie'
import moment from 'moment'

import type { UserProfile } from '@modules/ui-types'

import type { CookieAttributes } from 'js-cookie'

const defaultSettings: CookieAttributes = {
  // transmission over secure HTTPS protocol only, not to be confused with `http-only`
  secure: true,

  // don't share our cookies with third parties by default
  sameSite: `Strict`,

  // cookie is accessible site-wide
  path: '/',
}

export function setCookie(
  name: string,
  value: string,
  {
    settings: userSettings = {},
    topLevel = false,
  }: {
    settings?: Partial<CookieAttributes>
    topLevel?: boolean
  } = {},
): string | undefined {
  const settings: CookieAttributes = {
    ...defaultSettings,
    ...userSettings,
  }

  if (topLevel && areWeOnElasticDotCo()) {
    settings.domain = `.elastic.co`
  }

  // we can't make cookies "secure" over HTTP
  if (location.protocol === `http:`) {
    delete settings.secure
  }

  return Cookies.set(name, value, settings)
}

export function getCookie(name: string): string | undefined {
  return Cookies.get(name)
}

export function setCloudTrialCookie(profile: UserProfile) {
  const { created, trials } = profile
  const firstTrial = trials[0]
  const latestTrial = trials[trials.length - 1]
  const cloudTrialCookieKey = `ec_trial_start`
  const cloudTrialStartWithin30DaysCookieKey = `ec_trial_start_within_30_days`
  const settings = { expires: 29, sameSite: `Lax` as const }

  if (latestTrial && !getCookie(cloudTrialCookieKey)) {
    setCookie(cloudTrialCookieKey, latestTrial.start, { settings, topLevel: true })
  }

  if (firstTrial && !getCookie(cloudTrialStartWithin30DaysCookieKey)) {
    setCookie(
      cloudTrialStartWithin30DaysCookieKey,
      cloudTrialStartedWithin30Days(created, firstTrial.start),
      { settings, topLevel: true },
    )
  }
}

function cloudTrialStartedWithin30Days(profileCreationDate, trialStartDate): string {
  const createdDate = moment(profileCreationDate)
  const startDate = moment(trialStartDate)
  const isWithin30Days = moment.duration(startDate.diff(createdDate)).asDays() <= 30

  return isWithin30Days.toString()
}

function areWeOnElasticDotCo() {
  const isOnElasticCo = /(?:\.|(^https?:\/\/))elastic\.co$/

  // are we hosted on *.elastic.co or elastic.co?
  return isOnElasticCo.test(location.hostname)
}
