/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { BillingSubscriptionLevel, ProfileState, UserProfile } from '@modules/ui-types/profile'

export const DEFAULT_SUBSCRIPTION_LEVEL: BillingSubscriptionLevel = 'enterprise'

const LEVELS: BillingSubscriptionLevel[] = ['standard', 'gold', 'platinum', 'enterprise']

export const defaultSubscriptionLevelByUser = ({
  profile,
  trialDefaultTierFlag,
}: {
  profile: ProfileState | UserProfile
  trialDefaultTierFlag?: BillingSubscriptionLevel
}): BillingSubscriptionLevel => {
  if (!profile || !profile.inTrial) {
    return DEFAULT_SUBSCRIPTION_LEVEL
  }

  if (trialDefaultTierFlag && LEVELS.includes(trialDefaultTierFlag)) {
    return trialDefaultTierFlag
  }

  return DEFAULT_SUBSCRIPTION_LEVEL
}
