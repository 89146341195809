/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { find } from 'lodash'

import {
  EuiCallOut,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiFormLabel,
  EuiFormRow,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type {
  SearchRequest,
  DeploymentGetResponse,
  DeploymentsSearchResponse,
  DeploymentTemplateRequestBody,
} from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, RegionId, StackDeployment, VersionNumber } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import { MonitoringDeploymentPicker } from '@/components/MonitoringDeploymentPicker'

import SectionHeader from '../../SectionHeader'
import { searchDeploymentsQuery } from '../../../../../../lib/deploymentQuery'
import {
  getFirstRefId,
  getVersion,
  hasEnabledObservabilityTemplateOption,
} from '../../../../../../lib/stackDeployments/selectors'
import { gte } from '../../../../../../lib/semver'

import type { WrappedComponentProps } from 'react-intl'

export type OwnProps = {
  regionId: RegionId
  template: DeploymentTemplateRequestBody
  updateDeploymentTemplate: (template: DeploymentTemplateRequestBody) => void
}

export type StateProps = {
  searchResults: DeploymentsSearchResponse | null
  searchResultsRequest: AsyncRequestState
  version?: VersionNumber
}

export type DispatchProps = {
  searchDeployments: (searchRequest: SearchRequest) => void
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps

type State = {
  checkSelectionError: boolean
  logsIncompatibleVersion: boolean
  pristine: boolean
  searchValue: string | null
  selectedDeployment: DeploymentGetResponse | null
  monitorLogs: boolean
  monitorMetrics: boolean
  searchDeploymentsComplete: boolean
}

class Monitoring extends Component<Props, State> {
  state: State = {
    checkSelectionError: false,
    logsIncompatibleVersion: false,
    pristine: true,
    searchValue: ``,
    selectedDeployment: null,
    monitorLogs: hasEnabledObservabilityTemplateOption({
      deploymentTemplate: this.props.template.deployment_template,
      option: 'logging',
    }),
    monitorMetrics: hasEnabledObservabilityTemplateOption({
      deploymentTemplate: this.props.template.deployment_template,
      option: 'metrics',
    }),
    searchDeploymentsComplete: false,
  }

  componentDidMount() {
    this.searchDeploymentsList({ searchValue: null })
    this.setSelectedDeploymentFromTemplate()
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.searchResultsRequest.inProgress === false &&
      nextProps.searchResultsRequest.isDone === true &&
      !this.state.searchDeploymentsComplete
    ) {
      this.setSelectedDeploymentFromTemplate()
    }
  }

  render() {
    return (
      <Fragment>
        <SectionHeader
          title={
            <FormattedMessage
              id='deployment-template-monitoring-config.section-title'
              defaultMessage='Monitoring'
            />
          }
        />

        <EuiSpacer size='m' />

        <EuiText color='subdued'>
          <FormattedMessage
            id='deployment-template-monitoring-config.section-description'
            defaultMessage='Ship logs and monitoring metrics to a deployment where they are stored separately. Then, you can enable additional log types, search the logs, configure retention periods, and use Kibana to view monitoring visualizations.'
          />
        </EuiText>

        <EuiSpacer size='m' />

        {this.renderDeployments()}
      </Fragment>
    )
  }

  renderDeployments() {
    const { searchResultsRequest, searchResults } = this.props

    const { selectedDeployment } = this.state

    if (!searchResults) {
      return (
        <div>
          <EuiSpacer size='m' />
          <EuiSkeletonText lines={3} />
        </div>
      )
    }

    if (
      searchResults &&
      searchResults.deployments.length === 0 &&
      !searchResultsRequest.inProgress
    ) {
      return (
        <CuiAlert data-test-id='deploymentTemplateMonitoringConfig-noDeployments' type='warning'>
          <FormattedMessage
            id='deployment-configure.no-deployments'
            defaultMessage='You have not created any deployments. You can add a monitoring deployment later.'
          />
        </CuiAlert>
      )
    }

    return (
      <EuiFlexGroup direction='column' gutterSize='m' alignItems='flexStart'>
        <EuiFlexItem>
          <div>
            <EuiSpacer size='m' />
            <EuiText size='s' color='subdued'>
              <FormattedMessage
                id='deployment-monitoring-enable.select-deployment'
                defaultMessage='Select a deployment'
              />
            </EuiText>
            <div data-test-id='log-monitoring' style={{ maxWidth: `300px`, width: `100%` }}>
              <MonitoringDeploymentPicker
                data-test-id='deployment-picker-dropdown'
                searchDeploymentsRequest={searchResultsRequest}
                searchDeploymentList={this.searchDeploymentsList}
                searchResults={searchResults}
                onChange={this.onSelectDeloyment}
                value={selectedDeployment}
              />
            </div>

            <EuiFormHelpText>
              <FormattedMessage
                id='select-monitoring-source.description'
                defaultMessage='Must be in the same region and have a compatible major version of the Elastic Stack.'
              />
            </EuiFormHelpText>
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow isInvalid={this.state.checkSelectionError}>
            {this.renderMonitoringOptions()}
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderMonitoringOptions() {
    const { template } = this.props
    const { checkSelectionError } = this.state

    const monitoringLogs = hasEnabledObservabilityTemplateOption({
      deploymentTemplate: template.deployment_template,
      option: 'logging',
    })

    const monitoringMetrics = hasEnabledObservabilityTemplateOption({
      deploymentTemplate: template.deployment_template,
      option: 'metrics',
    })

    return (
      <Fragment>
        <EuiSpacer size='s' />

        <EuiFormLabel>
          <FormattedMessage
            id='deployment-monitoring-enable.data-being-shipped'
            defaultMessage='Data being shipped'
          />
        </EuiFormLabel>
        <EuiSpacer size='s' />
        <EuiCheckbox
          id={'logs'}
          label='Logs'
          onChange={() => this.toggleLogsMonitoring()}
          checked={monitoringLogs}
          data-test-id='choose-logs-monitoring'
        />

        {this.renderLogsVersionMessage()}

        <EuiCheckbox
          id={'metrics'}
          label='Metrics'
          onChange={() => this.toggleMetricsMonitoring()}
          checked={monitoringMetrics}
          data-test-id='choose-metrics-monitoring'
        />

        {checkSelectionError && (
          <Fragment>
            <EuiSpacer size='m' />
            <EuiCallOut
              size='s'
              title={
                <FormattedMessage
                  id='deployment-monitoring-enable.monitoring-selection-warning'
                  defaultMessage='Please choose at least one option'
                />
              }
              color='danger'
              iconType='alert'
            />
          </Fragment>
        )}

        <EuiSpacer size='l' />
      </Fragment>
    )
  }

  renderLogsVersionMessage() {
    if (!this.state.logsIncompatibleVersion) {
      return null
    }

    return (
      <Fragment>
        <EuiFormHelpText>
          <FormattedMessage
            id='deployment-monitoring-enable.deployment-version-warning'
            defaultMessage='Deployment must be at version 6.5.0 or later to send logs'
          />
        </EuiFormHelpText>
        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  searchDeploymentsList = ({ searchValue }: { searchValue: string | null }) => {
    const { regionId, searchDeployments, version } = this.props

    this.setState({ searchValue })

    if (regionId) {
      const query = searchDeploymentsQuery({ regionId, searchValue, version })
      searchDeployments(query)
    }
  }

  onSelectDeloyment = (selectedDeployment: StackDeployment | null) => {
    if (!selectedDeployment) {
      this.setState(
        {
          monitorLogs: false,
          monitorMetrics: false,
          pristine: false,
          selectedDeployment: null,
        },
        this.updateObservabilitySettings,
      )

      return
    }

    const checkLogsDisabled = this.isMonitoringCompatibleVersion(selectedDeployment)
    const monitorLogs = !checkLogsDisabled

    this.setState(
      {
        monitorLogs,
        monitorMetrics: true,
        logsIncompatibleVersion: checkLogsDisabled,
        selectedDeployment,
      },
      this.updateObservabilitySettings,
    )
  }

  isMonitoringCompatibleVersion = (selectedDeployment: StackDeployment | null): boolean => {
    if (selectedDeployment === null) {
      return false
    }

    const searchVersion = getVersion({ deployment: selectedDeployment })!

    if (searchVersion === null) {
      return false
    }

    if (gte(searchVersion, `6.5.0`)) {
      return false
    }

    return true
  }

  updateObservabilitySettings = (): void => {
    const { selectedDeployment, monitorLogs, monitorMetrics } = this.state

    if (selectedDeployment) {
      const refId = getFirstRefId({
        deployment: selectedDeployment,
        sliderInstanceType: `elasticsearch`,
      })

      if (!refId) {
        return // sanity
      }

      const metricsMonitoringPayload = monitorMetrics
        ? {
            metrics: {
              destination: {
                deployment_id: selectedDeployment.id,
                ref_id: refId,
              },
            },
          }
        : {
            metrics: undefined,
          }

      const logsMonitoringPayload = monitorLogs
        ? {
            logging: {
              destination: {
                deployment_id: selectedDeployment.id,
                ref_id: refId,
              },
            },
          }
        : {
            logging: undefined,
          }

      const template: DeploymentTemplateRequestBody = {
        ...this.props.template,
        deployment_template: {
          ...this.props.template.deployment_template,
          settings: {
            ...this.props.template.deployment_template.settings,
            observability: {
              ...metricsMonitoringPayload,
              ...logsMonitoringPayload,
            },
          },
        },
      }

      this.props.updateDeploymentTemplate(template)
    } else {
      const template: DeploymentTemplateRequestBody = {
        ...this.props.template,
        deployment_template: {
          ...this.props.template.deployment_template,
          settings: {
            ...this.props.template.deployment_template.settings,
            observability: undefined,
          },
        },
      }

      this.props.updateDeploymentTemplate(template)
    }
  }

  setSelectedDeploymentFromTemplate = () => {
    const { template, searchResults } = this.props
    const deploymentTemplate = template.deployment_template

    if (!deploymentTemplate.settings?.observability) {
      return
    }

    const deploymentIds = [
      deploymentTemplate.settings.observability.metrics?.destination?.deployment_id,
      deploymentTemplate.settings.observability.logging?.destination?.deployment_id,
    ].filter(Boolean)

    if (!deploymentIds || !searchResults?.deployments) {
      return
    }

    const chosenMonitoringDeployment: DeploymentGetResponse | undefined = find(
      searchResults.deployments,
      (deployment) => deploymentIds.includes(deployment.id),
    )

    if (!chosenMonitoringDeployment) {
      return
    }

    this.setState({
      selectedDeployment: chosenMonitoringDeployment,
      searchDeploymentsComplete: true,
      monitorLogs: hasEnabledObservabilityTemplateOption({
        deploymentTemplate,
        option: 'logging',
      }),
      monitorMetrics: hasEnabledObservabilityTemplateOption({
        deploymentTemplate,
        option: 'metrics',
      }),
    })
  }

  toggleLogsMonitoring = () => {
    this.setState(
      {
        monitorLogs: !this.state.monitorLogs,
      },
      () => {
        this.checkMonitoringOptions()
        this.updateObservabilitySettings()
      },
    )
  }

  toggleMetricsMonitoring = () => {
    this.setState(
      {
        monitorMetrics: !this.state.monitorMetrics,
      },
      () => {
        this.checkMonitoringOptions()
        this.updateObservabilitySettings()
      },
    )
  }

  checkMonitoringOptions = () => {
    this.setState({
      checkSelectionError: !this.state.monitorLogs && !this.state.monitorMetrics,
    })
  }
}

export default injectIntl(Monitoring)
