/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { RoleAssignments } from '@modules/cloud-api/v1/types'

import type { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer'

export enum DeploymentRole {
  DEPLOYMENT_ADMIN = 'deployment-admin',
  DEPLOYMENT_EDITOR = 'deployment-editor',
  DEPLOYMENT_VIEWER = 'deployment-viewer',
  NO_ACCESS = 'no-access',
}

export enum OrganizationRole {
  ORGANIZATION_OWNER = 'organization-admin',
  ORGANIZATION_BILLING = 'billing-admin',
}

export type Role = OrganizationRole | DeploymentRole

export type State = {
  isDeploymentAccessSelected: boolean
}

export type StateProps = {
  rolesScopes: Set<string> | null
  hasGetAllDeploymentsPermission: boolean
}

export type ConsumerProps = {
  organizationId: string
  roleAssignments: RoleAssignments
  onChangeRoleAssignments: (roleAssignments: RoleAssignments) => void
} & LDProps

export type AllProps = StateProps & ConsumerProps
