/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiCallOut, EuiCode, EuiSpacer } from '@elastic/eui'

import type { InstanceSummary } from '@modules/ui-types'

import { getConfigForKey } from '@/store'
import ExternalLink from '@/components/ExternalLink'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  instancesWithOverride: InstanceSummary[]
}

const InstanceOverrideCallout: FunctionComponent<Props> = ({ instancesWithOverride }) => {
  if (instancesWithOverride.length === 0) {
    return null
  }

  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

  const instanceNames = instancesWithOverride
    .map<React.ReactNode>((instance) => (
      <EuiCode transparentBackground={true}>{instance.instance.instance_name}</EuiCode>
    ))
    .reduce((prev, curr) => [prev, ', ', curr])

  const description = isUserConsole ? (
    <FormattedMessage
      id='instances-override-callout-userconsole.description'
      defaultMessage='Size or disk quota overrides are configured for the following instances and can prevent automated actions (such as autoscaling if enabled) from happening for the entire deployment: [{instances}]. <link>Contact support</link> to remove overrides as soon as possible.'
      values={{
        instances: instanceNames,
        link: (content) => (
          <ExternalLink showExternalLinkIcon={false} href='/support'>
            {content}
          </ExternalLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage
      id='instances-override-callout-adminconsole.description'
      defaultMessage='Size or disk quota overrides are configured for the following instances and can prevent automated actions (such as autoscaling if enabled) from happening for the entire deployment: [{instances}]. In addition, overrides can impact the overall allocator stability. Remove the overrides as soon as possible.'
      values={{
        instances: instanceNames,
      }}
    />
  )

  return (
    <Fragment>
      <EuiCallOut
        data-test-id='instanceOverride-callout'
        title={
          <FormattedMessage
            id='instance-override-callout.title'
            defaultMessage='Overrides may prevent your deployment from functioning properly'
          />
        }
        color='warning'
      >
        <p>{description}</p>
      </EuiCallOut>
      <EuiSpacer />
    </Fragment>
  )
}

export default injectIntl(InstanceOverrideCallout)
