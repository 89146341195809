/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { TrustRelationshipGetResponse } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { deleteTrustRelationship } from '../../../actions/trustManagement'
import { deleteTrustRelationshipRequest } from '../../../reducers'

import TrustRelationshipTable from './TrustRelationshipTable'

import type { ThunkDispatch, ReduxState } from '@/types/redux'

type StateProps = {
  deleteTrustRelationshipRequest: (trustRelationshipId: string) => AsyncRequestState
}

type DispatchProps = {
  deleteTrustRelationship: (trustRelationshipId: string) => void
}

type ConsumerProps = {
  regionId: string
  trustRelationships: TrustRelationshipGetResponse[]
}

const mapStateToProps = (state: ReduxState, { regionId }: ConsumerProps): StateProps => ({
  deleteTrustRelationshipRequest: (trustRelationshipId: string) =>
    deleteTrustRelationshipRequest(state, regionId, trustRelationshipId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { regionId }: ConsumerProps,
): DispatchProps => ({
  deleteTrustRelationship: (trustRelationshipId) =>
    dispatch(
      deleteTrustRelationship({
        regionId,
        trustRelationshipId,
      }),
    ),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TrustRelationshipTable)
