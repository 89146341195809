/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { CommonClusterPlanInfo } from '@modules/cloud-api/v1/types'
import type { ResourceChangeAttempt, AsyncRequestState } from '@modules/ui-types'

import { fetchPlanActivityDetailRequest, getPlanActivityDetail } from '@/reducers'
import DetailRow from '@/components/StackDeploymentActivity/DeploymentActivityTable/DetailRow/DetailRow'
import withPolling from '@/lib/withPolling'
import { fetchPlanActivityDetail } from '@/actions/planActivityDetail'

import type { ReduxState, ThunkDispatch } from '@/types/redux'

type StateProps = {
  planActivityDetail: () => CommonClusterPlanInfo | null
  fetchPlanActivityDetailRequest: () => AsyncRequestState
}

type ConsumerProps = {
  change: ResourceChangeAttempt
}

const mapStateToProps = (state: ReduxState, { change }: ConsumerProps): StateProps => ({
  planActivityDetail: () =>
    getPlanActivityDetail(
      state,
      change.deployment.id,
      change.resourceType,
      change.resource.ref_id,
      change.planAttempt.plan_attempt_id,
    ),
  fetchPlanActivityDetailRequest: () =>
    fetchPlanActivityDetailRequest(
      state,
      change.deployment.id,
      change.resourceType,
      change.resource.ref_id,
      change.planAttempt.plan_attempt_id ?? null,
    ),
})

interface DispatchProps {
  fetchPlanActivityDetail: (
    deploymentId: string,
    resourceKind: string,
    refId: string,
    attemptId?: string,
  ) => Promise<any>
}

const mapDispatchToProps = (dispatch: ThunkDispatch, { change }: ConsumerProps): DispatchProps => ({
  fetchPlanActivityDetail: () =>
    dispatch(
      fetchPlanActivityDetail(
        change.deployment.id,
        change.resourceType,
        change.resource.ref_id,
        change.planAttempt.plan_attempt_id!,
      ),
    ),
})

const PollingDetailRow = withPolling(DetailRow, ({ fetchPlanActivityDetail: onPoll, change }) => ({
  onPoll,
  // if the plan is pending, reload every 10 seconds;
  // otherwise, every 2 minutes (since we dont expect a completed plan to change)
  interval: 10,
  stopPolling: !!change.planAttempt.attempt_end_time,
}))

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PollingDetailRow)
