/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages } from 'react-intl'
import { flatMap, uniq, uniqBy, sortBy } from 'lodash'

import type { SearchFilterConfig } from '@elastic/eui'
import { EuiBadge } from '@elastic/eui'

import type { AllocatorSearchResult } from '@modules/ui-types'

import AllocatorTag from '../../Allocator/AllocatorTags/AllocatorTag'
import { prominentAllocatorTags } from '../../../lib/allocator'

import { serializeTag } from './helpers'

import type { IntlShape } from 'react-intl'

type Params = {
  intl: IntlShape
  allocators?: AllocatorSearchResult[]
  includeFeatureFilter: boolean
}

const messages = defineMessages({
  zonesLabel: {
    id: `allocators-filter-bar.zones-label`,
    defaultMessage: `Zones`,
  },
  healthyLabel: {
    id: `allocators-filter-bar.healthy-label`,
    defaultMessage: `Healthy`,
  },
  unhealthyLabel: {
    id: `allocators-filter-bar.unhealthy-label`,
    defaultMessage: `Unhealthy`,
  },
  emptyLabel: {
    id: `allocators-filter-bar.empty-label`,
    defaultMessage: `Empty`,
  },
  fullLabel: {
    id: `allocators-filter-bar.full-label`,
    defaultMessage: `Full`,
  },
  maintenanceLabel: {
    id: `allocators-filter-bar.maintenance-label`,
    defaultMessage: `Maintenance`,
  },
  connectedLabel: {
    id: `allocators-filter-bar.connected-label`,
    defaultMessage: `Connected`,
  },
  disconnectedLabel: {
    id: `allocators-filter-bar.disconnected-label`,
    defaultMessage: `Disconnected`,
  },
  tagsLabel: {
    id: `allocators-filter-bar.tags-label`,
    defaultMessage: `Tags`,
  },
  featuresLabel: {
    id: `allocators-filter-bar.features-label`,
    defaultMessage: `Features`,
  },
})

export function getFilters({
  intl: { formatMessage },
  allocators = [],
  includeFeatureFilter,
}: Params): SearchFilterConfig[] {
  const { zones, tags, features } = getOptionLists(allocators)

  const filters: SearchFilterConfig[] = [
    {
      name: formatMessage(messages.zonesLabel),
      type: `field_value_selection`,
      field: `zone`,
      multiSelect: `or`,
      options: zones,
    },
    {
      type: `field_value_toggle_group`,
      field: `healthy`,
      items: [
        {
          name: formatMessage(messages.healthyLabel),
          value: `y`,
        },
        {
          name: formatMessage(messages.unhealthyLabel),
          value: `n`,
        },
      ],
    },
    {
      type: `field_value_toggle_group`,
      field: `maintenance`,
      items: [
        {
          name: formatMessage(messages.maintenanceLabel),
          value: `y`,
        },
      ],
    },
    {
      type: `field_value_toggle_group`,
      field: `connected`,
      items: [
        {
          name: formatMessage(messages.connectedLabel),
          value: `y`,
        },
        {
          name: formatMessage(messages.disconnectedLabel),
          value: `n`,
        },
      ],
    },
    {
      type: `field_value_toggle_group`,
      field: `fill`,
      items: [
        {
          name: formatMessage(messages.emptyLabel),
          value: String(0),
        },
        {
          name: formatMessage(messages.fullLabel),
          value: String(100),
        },
      ],
    },
    {
      name: formatMessage(messages.tagsLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `tag`,
      multiSelect: `and`,
      options: tags.filter(byTagProminence),
    },
  ]

  if (includeFeatureFilter) {
    filters.push({
      name: formatMessage(messages.featuresLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `feature`,
      multiSelect: `and`,
      options: features,
    })
  }

  return filters
}

function getOptionLists(allocators: AllocatorSearchResult[]) {
  const zones = uniq(allocators.map((allocator) => allocator.zoneId))
    .sort()
    .map((zoneId) => ({
      view: renderBadge(zoneId),
      value: zoneId,
    }))

  const tags = sortBy(uniqBy(flatMap(allocators, `tags`), serializeTag), serializeTag).map(
    (tag) => ({
      _tag: tag,
      view: renderTag(tag),
      value: serializeTag(tag),
    }),
  )

  const features = uniq(flatMap(allocators, `enabledFeatures`))
    .sort()
    .map((feature) => ({
      view: renderBadge(feature),
      value: feature,
    }))

  return {
    zones,
    tags,
    features,
  }
}

function renderBadge(value) {
  return (
    <div>
      <EuiBadge>{value}</EuiBadge>
    </div>
  )
}

function renderTag(tag) {
  return (
    <div className='allocatorFilterContext-tag'>
      <AllocatorTag tag={tag} />
    </div>
  )
}

function byTagProminence(tag) {
  return prominentAllocatorTags.includes(tag._tag.key)
}
