/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { ElasticsearchResourceInfo } from '@modules/cloud-api/v1/types'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { getFirstEsClusterFromGet, getRegionId } from '@/lib/stackDeployments/selectors'
import { fetchDeploymentTemplatesRequest, getDeploymentTemplate } from '@/reducers'
import createCluster from '@/reducers/clusters/createCluster'
import { getSelfUrl, getVersion } from '@/reducers/clusters'
import { fetchDeploymentTemplatesIfNeeded } from '@/actions/topology/deploymentTemplates'

import DeploymentInfo from './DeploymentInfo'

import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  { deployment: stackDeployment }: ConsumerProps,
): StateProps => {
  const regionId = getRegionId({ deployment: stackDeployment })

  const esResource: ElasticsearchResourceInfo = getFirstEsClusterFromGet({
    deployment: stackDeployment,
  })!

  const { id: esClusterId, info } = esResource
  const selfUrl = getSelfUrl(regionId!, esClusterId)

  const esCluster = createCluster({
    regionId: regionId!,
    clusterId: esClusterId,
    selfUrl,
    source: info,
    stackDeployment,
  })

  const { deploymentTemplateId, _raw } = esCluster
  const version = getVersion(_raw)

  return {
    esCluster,
    deploymentTemplate: getDeploymentTemplate(state, regionId!, deploymentTemplateId!, version),
    fetchDeploymentTemplatesRequest: fetchDeploymentTemplatesRequest(state, regionId),
    esVersion: version!,
    regionId: regionId!,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchDeploymentTemplatesIfNeeded: (params) => dispatch(fetchDeploymentTemplatesIfNeeded(params)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentInfo)
