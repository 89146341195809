/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import cx from 'classnames'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import { withTransaction } from '@elastic/apm-rum-react'

import type { AvailableAuthenticationMethods } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import PasswordBasedLogin from '../../../../../components/Login/PasswordBasedLogin'
import LandingPage from '../../../../../components/LandingPage'
import { redirectOnMount, hasRedirectOnMount, hasAnySsoMethod } from '../../../../../lib/auth'

import type { WrappedComponentProps } from 'react-intl'
import type { RouteComponentProps } from 'react-router'

import '../../../../../components/Login/login.scss'

export type UserLogin = {
  email: string
  password: string
}

export type AdminLogin = {
  username: string
  password: string
}

export type Props = {
  fetchAuthMethods: () => void
  fetchAuthMethodsRequest?: AsyncRequestState
  location: RouteComponentProps['location']
  loginAndRedirect: (args: {
    redirectTo?: string
    oktaRedirectUrl?: string
    credentials: UserLogin | AdminLogin
  }) => Promise<any>
  loginRequest: AsyncRequestState
  authMethods: AvailableAuthenticationMethods | null
  redirectAfterLogin: (redirectTo?: string) => void
  redirectTo?: string
  newBearerToken: string | null
  fromURI?: string
  source?: string
}

class BasicLogin extends Component<Props & WrappedComponentProps> {
  componentDidMount() {
    const { fetchAuthMethods, authMethods, redirectAfterLogin, redirectTo, newBearerToken } =
      this.props

    if (hasRedirectOnMount(newBearerToken)) {
      redirectOnMount({ redirectAfterLogin, redirectTo, newBearerToken })
      return
    }

    if (!authMethods) {
      fetchAuthMethods()
    }
  }

  render() {
    const { newBearerToken } = this.props

    if (hasRedirectOnMount(newBearerToken)) {
      return null // the flow almost instantly redirects away from this page
    }

    const { loginRequest } = this.props
    const classes = cx({ 'login-form-sso': false })

    return (
      <LandingPage scrollPage={true} loading={loginRequest.inProgress} className={classes}>
        {this.renderContent()}
      </LandingPage>
    )
  }

  renderContent() {
    const { authMethods, fetchAuthMethodsRequest, location, loginAndRedirect } = this.props
    const canSso = hasAnySsoMethod(authMethods)

    const isCheckingAuthMethods =
      authMethods == null || Boolean(fetchAuthMethodsRequest && fetchAuthMethodsRequest.inProgress)

    // This is also displayed if all auth methods are disabled - because what else are we supposed to do?
    return (
      <PasswordBasedLogin
        location={location}
        loginAndRedirect={loginAndRedirect}
        isCheckingAuthMethods={isCheckingAuthMethods}
        canSwitchMethod={canSso}
      />
    )
  }
}

export default withTransaction(`BasicLogin`, `component`)(injectIntl(BasicLogin))
