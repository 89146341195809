/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getProfile } from '@/apps/userconsole/reducers'
import {
  getTheme,
  searchStackDeploymentsRequest,
  getStackDeploymentsFromSearch,
  fetchDeploymentTemplatesRequest,
  getStackDeployment,
  fetchStackDeploymentRequest,
} from '@/reducers'
import { searchDeployments, fetchDeployment } from '@/actions/stackDeployments'
import { getDeploymentTemplateInfoForStackDeployment } from '@/lib/reduxShortcuts/deploymentTemplates'
import { fetchDeploymentTemplatesIfNeeded } from '@/actions/topology/deploymentTemplates'
import { getRegionId, getVersion } from '@/lib/stackDeployments/selectors'
import { TRIAL_DEPLOYMENT_LIMIT } from '@/constants/trial'

import TrialSummaryModal from './TrialSummaryModal'

import type { ReduxState } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const queryId = 'trial-summary-deployments'

const mapStateToProps = (state: ReduxState): StateProps => {
  const deploymentFromSearch = getStackDeploymentsFromSearch(state, queryId)?.deployments?.[0]
  const deployment = deploymentFromSearch
    ? getStackDeployment(state, deploymentFromSearch.id)
    : null
  const deploymentTemplate = deployment
    ? getDeploymentTemplateInfoForStackDeployment({
        deployment,
      })
    : null
  const regionId = deployment ? getRegionId({ deployment }) : ''
  const version = deployment ? getVersion({ deployment }) : null

  return {
    profile: getProfile(state),
    theme: getTheme(state),
    deploymentFromSearch,
    deploymentsRequest: searchStackDeploymentsRequest(state, queryId),
    deployment,
    deploymentRequest: fetchStackDeploymentRequest(state, deploymentFromSearch?.id || ''),
    deploymentTemplate,
    deploymentTemplatesRequest: fetchDeploymentTemplatesRequest(state, regionId, version, 'false'),
  }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchDeployments: () =>
    dispatch(searchDeployments({ queryId, query: { size: TRIAL_DEPLOYMENT_LIMIT } })), // customer can have only 1 deployment in trial period
  fetchDeploymentTemplates: ({ regionId, stackVersion }) =>
    dispatch(
      fetchDeploymentTemplatesIfNeeded({
        regionId,
        stackVersion,
      }),
    ),
  fetchDeploymentDetails: (deploymentId: string) => dispatch(fetchDeployment({ deploymentId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TrialSummaryModal)
