/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { QueryClient } from 'react-query'

/**
 * Establish *default* settings for react-query usage. They can be overridden on
 * a query key or individual hook usage basis.
 */
export function setGlobalDefaults(queryClient: QueryClient) {
  queryClient.setDefaultOptions({
    queries: {
      // memoize a query for 1 minute (unless explicitly invalidated)
      staleTime: 1000 * 60 * 1,

      // delay the "garbage collection" of the result of a query for 5 mins
      cacheTime: 1000 * 60 * 5,

      // turn off automatic retries, on a component's initial render and at
      // all other times
      retryOnMount: false,
      retry: false,
    },
  })
}
