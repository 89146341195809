/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { sumBy } from 'lodash'
import moment from 'moment'

import type { ItemsCosts } from '@modules/cloud-api/v1/types'

import type { CurrentRates } from './types'

const defaultEmptyCost = { value: null, formatted_value: 'N/A' }

const defaultRates: CurrentRates = {
  capacity: defaultEmptyCost,
  data_in: defaultEmptyCost,
  data_out: defaultEmptyCost,
  data_internode: defaultEmptyCost,
  storage_api: defaultEmptyCost,
  storage_bytes: defaultEmptyCost,
}

export const getCurrentRates = (itemsCosts?: ItemsCosts): CurrentRates => {
  if (!itemsCosts) {
    return defaultRates
  }

  const currentRates = { ...defaultRates }

  // map data_transfer_and_storage dimensions
  itemsCosts.data_transfer_and_storage.map(({ type, rate }) => {
    currentRates[type] = rate
  })

  // sum all values from resources array
  if (itemsCosts.resources.length > 0) {
    const currentUTC = moment.utc()
    const activeResources = itemsCosts.resources.filter(({ period }) =>
      currentUTC.isBetween(period.start, period.end),
    )
    const capacityValue = Number(sumBy(activeResources, 'price_per_hour').toFixed(4))

    currentRates.capacity = { value: capacityValue, formatted_value: `${capacityValue} per hour` }
  }

  return currentRates
}
