/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { captureDeploymentResourceDiagnosticsUrl } from '@modules/cloud-api/v1/urls'
import type { SliderInstanceType } from '@modules/ui-types/sliders'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE } from '../../constants/actions'

export function downloadClusterDiagnosticBundle({
  deploymentId,
  resourceKind,
  refId,
}: {
  deploymentId: string
  resourceKind: SliderInstanceType
  refId: string
}) {
  const url = captureDeploymentResourceDiagnosticsUrl({ deploymentId, resourceKind, refId })

  const headers = {
    Accept: `application/zip`,
  }

  return asyncRequest<string>({
    type: DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE,
    method: 'POST',
    url,
    requestSettings: {
      binary: true,
      request: {
        headers,
      },
    },
    meta: { deploymentId, resourceKind },
    crumbs: [deploymentId, resourceKind],
  })
}

export const resetDownloadClusterDiagnosticBundleRequest = (...crumbs: string[]) =>
  resetAsyncRequest(DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE, crumbs)
