/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { StackDeployment, SliderInstanceType, AnyResourceInfo } from '@modules/ui-types'

interface GetFirstOptions {
  deployment: StackDeployment
  sliderInstanceType: SliderInstanceType
}

function getFirstProp<TKey extends keyof AnyResourceInfo>(
  prop: TKey,
  { deployment, sliderInstanceType }: GetFirstOptions,
): AnyResourceInfo[TKey] | null {
  const [resource]: AnyResourceInfo[] = deployment.resources[sliderInstanceType]

  if (!resource) {
    return null
  }

  return resource?.[prop]
}

export const getFirstRefId = (a: GetFirstOptions): string | null => getFirstProp('ref_id', a)
export const getFirstId = (a: GetFirstOptions): string | null => getFirstProp('id', a)

export const getFirstEsRefId = ({ deployment }: { deployment: StackDeployment }): string => {
  const refId = getFirstRefId({ deployment, sliderInstanceType: `elasticsearch` })

  if (refId == null) {
    // All deployments need an ES instance, so we expect to always find one
    throw new Error(
      `Failed to find [ref_id] in deployment [${deployment.id}] for slider type [elasticsearch]`,
    )
  }

  return refId
}

export const getFirstEsId = ({ deployment }: { deployment: StackDeployment }): string => {
  const id = getFirstId({ deployment, sliderInstanceType: `elasticsearch` })

  if (id == null) {
    // All deployments need an ES instance, so we expect to always find one
    throw new Error(
      `Failed to find [id] in deployment [${deployment.id}] for slider type [elasticsearch]`,
    )
  }

  return id
}
