/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'

import { EuiFlexGroup, EuiFlexItem, EuiNotificationBadge } from '@elastic/eui'

import RoleBadge from './RoleBadge'

import type { DeploymentRole } from '@/components/Users/RoleAssignmentsPanel/types'
import type { FunctionComponent } from 'react'

type Props = {
  role: DeploymentRole
  count: string
}

const RoleWithDeploymentsCount: FunctionComponent<Props> = ({ role, count }) => (
  <EuiFlexGroup gutterSize='xs' responsive={false}>
    <EuiFlexItem grow={false}>
      <RoleBadge role={role} />
    </EuiFlexItem>

    <EuiFlexItem grow={false} style={{ marginLeft: `-12px`, marginTop: '-7px' }}>
      <EuiNotificationBadge color='subdued'>{count}</EuiNotificationBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default RoleWithDeploymentsCount
