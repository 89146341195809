/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import { isFeatureActivated } from '@/selectors'
import { setUserSearchType } from '@/actions/saasUsers'

import Users from './Users'

import type { ReduxState } from '@/types/redux'
import type { UserSearchType } from '@/actions/saasUsers'
import type { Props } from './Users'

interface StateProps {
  lookupSaasUsers: boolean
  userSearchType: UserSearchType
}

interface DispatchProps {
  setUserSearchType: (userSearchType: UserSearchType) => void
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  lookupSaasUsers: isFeatureActivated(state, Feature.lookupSaasUsers),
  userSearchType: state.searchUsers.searchType,
})

const mapDispatchToProps = {
  setUserSearchType,
}

export default connect<StateProps, DispatchProps, Props>(mapStateToProps, mapDispatchToProps)(Users)
