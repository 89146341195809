/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import {
  getRegionsByProvider,
  getRegionIdsByProvider,
  getProvidersNames,
} from '../../../../reducers'
import { getProfile } from '../../../../apps/userconsole/reducers'
import { getConfigForKey, isFeatureActivated } from '../../../../selectors'
import { fetchBasePricesIfNeeded } from '../../../../apps/userconsole/actions/pricing'

import SelectTemplate from './SelectTemplate'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state): StateProps => ({
  getRegionsByProvider: (provider) => getRegionsByProvider(state, provider),
  getRegionIdsByProvider: (provider) => getRegionIdsByProvider(state, provider),
  providerIds: getProvidersNames(state),
  profile: getProfile(state),
  isUserconsole: getConfigForKey(state, `APP_NAME`) === `userconsole`,
  hasDefaultSnapshotRepository: isFeatureActivated(state, Feature.defaultSnapshotRepository),
})

const mapDispatchToProps: DispatchProps = {
  fetchBasePrices: fetchBasePricesIfNeeded,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectTemplate)
