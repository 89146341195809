/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, filter } from 'lodash'
import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiTitle,
  htmlIdGenerator,
} from '@elastic/eui'

import type { AsyncRequestState, Keystore } from '@modules/ui-types'
import { addToast } from '@modules/cui'
import untab from '@modules/utils/untab'

import SpinButton from '../../../SpinButton'
import SecretType from '../SecretType'

import type { IntlShape } from 'react-intl'

import './ManageKeystore.scss'

const makeId = htmlIdGenerator()

const messages = defineMessages({
  type: {
    id: `keystore.flyout.form.type-label`,
    defaultMessage: `Type`,
  },
  key: {
    id: `keystore.flyout.form.key-label`,
    defaultMessage: `Key`,
  },
  settingName: {
    id: `keystore.flyout.form.settingName-label`,
    defaultMessage: `Setting name`,
  },
  secret: {
    id: `keystore.flyout.form.secret-label`,
    defaultMessage: `Secret`,
  },
  json: {
    id: `keystore.flyout.form.edit-as-json`,
    defaultMessage: `Edit as JSON`,
  },
  parentKeyPlaceholder: {
    id: `keystore.flyout.form.parent-key-placeholder`,
    defaultMessage: `e.g. s3.client.example.access_key`,
  },
  required: {
    id: `keystore.flyout.required`,
    defaultMessage: `Required`,
  },
  matchRegex: {
    id: `keystore.flyout.match-regex`,
    defaultMessage: `Setting name may only contain lower case letters, numbers, and the following characters: _-.`,
  },
  removeLine: {
    id: `keystore.flyout.remove-line`,
    defaultMessage: `Remove Line`,
  },
})

const filePlaceholder = untab(`// Include the contents of the value field
// Below is an example of how to format a GCS Client
// {
//    "type": "<TYPE>"
//    "project_id": "<PROJECT_ID>"
//    "private_key_id": "<PRIVATE_KEY_ID>"
//    "private_key": "<PRIVATE_KEY>"
//    "client_email": "<CLIENT_EMAIL>"
//    "auth_uri": "<AUTH_URI>"
//    "token_uri": "<TOKEN_URI>"
//    "auth_provider_x509_cert_url": "<AUTH_PROVIDER_X509_CERT_URL>"
//    "client_x509_cert_url": "<CLIENT_X509_CERT_URL>"
// }
`)

const toastText = {
  secretCreationSuccess: {
    family: 'keystore',
    title: (
      <FormattedMessage
        id='keystore.add-setting.success'
        defaultMessage='Setting successfully saved'
      />
    ),
    color: 'success',
  },
}

const validKeys = [
  // source: https://www.elastic.co/guide/en/elasticsearch/reference/current/monitoring-settings.html
  '^xpack.monitoring.exporters.[^.]+.auth.secure_password$',

  // source: https://www.elastic.co/guide/en/cloud/current/ec-secure-clusters-oidc.html
  '^xpack.security.authc.realms.oidc.[^.]+.rp.client_secret$',

  // source: https://www.elastic.co/guide/en/elasticsearch/reference/current/notification-settings.html
  '^xpack.watcher.encryption_key$',
  '^xpack.notification.slack.account.[^.]+.secure_url$',
  '^xpack.notification.pagerduty.account.[^.]+.secure_service_api_key$',
  '^xpack.notification.jira.account.monitoring.secure_user$',
  '^xpack.notification.jira.account.monitoring.secure_url$',
  '^xpack.notification.jira.account.monitoring.secure_password$',
  '^xpack.notification.email.account.gmail_account.smtp.secure_password$',
  '^xpack.notification.email.account.outlook_account.smtp.secure_password$',
  '^xpack.notification.email.account.ses_account.smtp.secure_password$',
  '^xpack.notification.email.account.exchange_account.smtp.secure_pass$',
  '^xpack.http.ssl.secure_key_passphrase$',
  '^xpack.http.ssl.keystore.secure_password$',
  '^xpack.http.ssl.keystore.secure_key_password$',
  '^xpack.http.ssl.truststore.secure_password$',
  '^xpack.notification.email.ssl.secure_key_passphrase$',
  '^xpack.notification.email.ssl.keystore.secure_password$',
  '^xpack.notification.email.ssl.keystore.secure_key_password$',
  '^xpack.notification.email.ssl.truststore.secure_password$',

  // source: https://www.elastic.co/guide/en/elasticsearch/reference/current/security-settings.html
  '^xpack.security.authc.realms.jwt.[^.]+.client_authentication.shared_secret$',
  '^xpack.security.authc.realms.jwt.[^.]+.hmac_jwkset$',
  '^xpack.security.authc.realms.jwt.[^.]+.hmac_key$',
  '^xpack.security.authc.realms.saml.[^.]+.encryption.keystore.secure_key_password$',
  '^xpack.security.authc.realms.saml.[^.]+.encryption.keystore.secure_password$',
  '^xpack.security.authc.realms.saml.[^.]+.encryption.secure_key_passphrase$',
  '^xpack.security.authc.realms.saml.[^.]+.signing.keystore.secure_key_password$',
  '^xpack.security.authc.realms.saml.[^.]+.signing.keystore.secure_password$',
  '^xpack.security.authc.realms.saml.[^.]+.signing.secure_key_passphrase$',

  // 7.0+
  '^xpack.security.authc.realms.(active_directory|ldap|saml|oicd|jwt).[^.]+.ssl.keystore.secure_key_password$',
  '^xpack.security.authc.realms.(active_directory|ldap|saml|oicd|jwt).[^.]+.ssl.keystore.secure_password$',
  '^xpack.security.authc.realms.(active_directory|ldap|saml|oicd|jwt).[^.]+.ssl.secure_key_passphrase$',
  '^xpack.security.authc.realms.(active_directory|ldap|saml|oicd|jwt).[^.]+.ssl.secure_keystore.password$',
  '^xpack.security.authc.realms.(active_directory|ldap|saml|oicd|jwt).[^.]+.ssl.truststore.secure_password$',

  // 6.8 and older
  '^xpack.security.authc.realms.[^.]+.ssl.keystore.secure_key_password$',
  '^xpack.security.authc.realms.[^.]+.ssl.keystore.secure_password$',
  '^xpack.security.authc.realms.[^.]+.ssl.secure_key_passphrase$',
  '^xpack.security.authc.realms.[^.]+.ssl.secure_keystore.password$',
  '^xpack.security.authc.realms.[^.]+.ssl.truststore.secure_password$',

  '^truststore.secure_password$',
  '^xpack.security.http.ssl.secure_key_passphrase$',
  '^xpack.security.http.ssl.keystore.secure_password$',
  '^xpack.security.http.ssl.keystore.secure_key_password$',
  '^xpack.security.http.ssl.truststore.secure_password$',
  '^xpack.security.transport.ssl.secure_key_passphrase$',
  '^xpack.security.transport.ssl.keystore.secure_password$',
  '^xpack.security.transport.ssl.keystore.secure_key_password$',
  '^xpack.security.transport.ssl.truststore.secure_password$',

  // source: https://www.elastic.co/guide/en/elasticsearch/reference/current/repository-s3.html
  '^s3.client.[^.]+.access_key$',
  '^s3.client.[^.]+.secret_key$',
  '^s3.client.[^.]+.session_token$',
  '^s3.client.[^.]+.proxy.username$',
  '^s3.client.[^.]+.proxy.password$',

  // source: https://www.elastic.co/guide/en/elasticsearch/reference/current/repository-azure.html
  '^azure.client.[^.]+.account$',
  '^azure.client.[^.]+.key$',
  '^azure.client.[^.]+.sas_token$',

  // source: https://www.elastic.co/guide/en/elasticsearch/reference/current/repository-gcs.html
  '^gcs.client.[^.]+.credentials_file$',

  // source: https://www.elastic.co/guide/en/elasticsearch/reference/current/docs-reindex.html
  '^reindex.ssl.truststore.secure_password$',
  '^reindex.ssl.secure_key_passphrase$',
  '^reindex.ssl.keystore.secure_password$',
  '^reindex.ssl.keystore.secure_key_password$',
]

const validKeysRegex = new RegExp(validKeys.join('|')) // nosemgrep

const expectedConfirmation = 'I UNDERSTAND THE RISKS'

type KeySecretPair = {
  key: string
  secret: string
  id: string
}

export type Props = {
  intl: IntlShape
  closeFlyout: () => void
  addSecretToKeystore: (secrets: Keystore) => Promise<any>
  createSecretRequest: AsyncRequestState
  onSaveError: () => void
}

type State = {
  type: 'single' | 'multi' | 'file'
  keySecretPairs: KeySecretPair[]
  parentKey: string
  secret: string
  isInvalid: boolean
  invalidReason: string | null
  warningVisible: boolean
  confirmText: string
}

class ManageKeystore extends Component<Props, State> {
  state: State = {
    type: 'single',
    keySecretPairs: [{ key: '', secret: '', id: makeId() }],
    parentKey: '',
    secret: '',
    isInvalid: false,
    invalidReason: null,
    warningVisible: false,
    confirmText: '',
  }

  render() {
    const { warningVisible } = this.state

    if (warningVisible) {
      return this.renderWarningModal()
    }

    return this.renderFlyout()
  }

  renderFlyout() {
    const { closeFlyout } = this.props

    return (
      <EuiFlyout
        onClose={closeFlyout}
        maxWidth={600}
        aria-labelledby='manageKeystore-flyoutTitle'
        ownFocus={true}
      >
        {this.renderHeader()}

        {this.renderBody()}
        {this.renderFooter()}
      </EuiFlyout>
    )
  }

  renderHeader() {
    return (
      <EuiFlyoutHeader>
        <EuiTitle size='m'>
          <h2 id='manageKeystore-flyoutTitle'>
            <FormattedMessage
              id='keystore.flyout.title.create-setting'
              defaultMessage='Create setting'
            />
          </h2>
        </EuiTitle>
      </EuiFlyoutHeader>
    )
  }

  renderBody() {
    const { type, parentKey, isInvalid, invalidReason } = this.state
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <EuiFlyoutBody>
        <EuiFlexGroup
          direction='column'
          className='manageKeystore-flyoutBody'
          justifyContent='spaceBetween'
          gutterSize='none'
        >
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction='column' gutterSize='none'>
              <EuiFormRow
                fullWidth={true}
                label={formatMessage(messages.settingName)}
                isInvalid={isInvalid}
                error={isInvalid && invalidReason ? formatMessage(messages[invalidReason]) : null}
              >
                <EuiFieldText
                  value={parentKey}
                  onChange={this.onChangeParentKey}
                  fullWidth={true}
                  placeholder={formatMessage(messages.parentKeyPlaceholder)}
                  isInvalid={isInvalid}
                  data-test-id='manageKeystore-keyName'
                />
              </EuiFormRow>
              <EuiText color='subdued' size='xs' className='manageKeystore-helpText'>
                <FormattedMessage
                  id='keystore.flyout.form.settingName-help'
                  defaultMessage='Must be unique. For certain values, strict formatting is required.'
                />
              </EuiText>

              <EuiSpacer />

              <SecretType
                selected={type}
                label={formatMessage(messages.type)}
                onChange={this.onChangeButtonGroup}
              />

              {this.renderFields()}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutBody>
    )
  }

  renderWarningModal() {
    const { confirmText } = this.state
    const { closeFlyout, createSecretRequest } = this.props

    return (
      <EuiOverlayMask>
        <EuiModal onClose={closeFlyout}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='keystore.flyout.confirmation.title'
                defaultMessage='Unsupported setting detected'
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiSpacer size='m' />
            <EuiText>
              <FormattedMessage
                id='keystore.flyout.confirmation.warning-text-top'
                defaultMessage='The key you are trying to add is not supported and can prevent Elasticsearch from restarting or running correctly.
                                Learn more about secure settings from <a>the Elasticsearch documentation</a>.'
                values={{
                  a: (contents) => (
                    <EuiLink
                      href='https://www.elastic.co/guide/en/elasticsearch/reference/current/secure-settings.html'
                      target='_blank'
                    >
                      {contents}
                    </EuiLink>
                  ),
                }}
              />
              <EuiSpacer size='xl' />
              <FormattedMessage
                id='keystore.flyout.confirmation.input-box'
                defaultMessage='To finish adding the key, type "{confirmation}" and select {save}.'
                values={{
                  confirmation: <strong>{expectedConfirmation}</strong>,
                  save: (
                    <strong>
                      <FormattedMessage id='keystore.flyout.save' defaultMessage='Save' />
                    </strong>
                  ),
                }}
              />
            </EuiText>
            <EuiSpacer size='m' />
            <EuiFieldText
              value={confirmText}
              fullWidth={true}
              onChange={this.onChangeConfirmText}
              data-test-id='manageKeystore-confirmModal-confirmText'
            />
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeFlyout}>
              <FormattedMessage id='keystore.flyout.close' defaultMessage='Close' />
            </EuiButtonEmpty>

            <SpinButton
              disabled={this.shouldDisableConfirmButton()}
              onClick={this.createSecret}
              spin={createSecretRequest.inProgress}
              fill={true}
              data-test-id='manageKeystore-confirmModal-saveButton'
            >
              <FormattedMessage id='keystore.flyout.save' defaultMessage='Save' />
            </SpinButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  renderFooter() {
    const { closeFlyout, createSecretRequest } = this.props
    const { isInvalid } = this.state

    return (
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty iconType='cross' onClick={closeFlyout} flush='left'>
              <FormattedMessage id='keystore.flyout.close' defaultMessage='Close' />
            </EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <SpinButton
              disabled={isInvalid}
              onClick={this.createSecret}
              spin={createSecretRequest.inProgress}
              fill={true}
              className='manageKeystore-saveButton'
              data-test-id='manageKeystore-saveButton'
            >
              <FormattedMessage id='keystore.flyout.save' defaultMessage='Save' />
            </SpinButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    )
  }

  renderFields() {
    const { type } = this.state

    if (type === 'single') {
      return this.renderSingleField()
    }

    if (type === 'multi') {
      return this.renderMultiFields()
    }

    if (type === 'file') {
      return this.renderFileField()
    }

    return null
  }

  renderSingleField() {
    const {
      intl: { formatMessage },
    } = this.props
    const { secret } = this.state

    return (
      <EuiFormRow label={formatMessage(messages.secret)} fullWidth={true}>
        <EuiFieldText
          name='secret'
          value={secret}
          onChange={this.onChangeSecret}
          autoComplete='off'
          fullWidth={true}
        />
      </EuiFormRow>
    )
  }

  renderMultiFields() {
    const { keySecretPairs } = this.state
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <Fragment>
        {keySecretPairs.map((pair, i) => (
          <EuiFlexGroup key={pair.id} className='manageKeystore-keySecretPair'>
            <EuiFlexItem>
              <EuiFormRow label={formatMessage(messages.key)}>
                <EuiFieldText
                  name={`key-${i}`}
                  value={pair.key}
                  onChange={this.onChangeKeySecret}
                  data-name='key'
                  data-id={i}
                  data-test-id='manageKeystore-keyField'
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={formatMessage(messages.secret)}>
                <EuiFieldText
                  name={`secret-${i}`}
                  value={pair.secret}
                  onChange={this.onChangeKeySecret}
                  data-name='secret'
                  autoComplete='off'
                  data-id={i}
                  data-test-id='manageKeystore-secretField'
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup alignItems='center'>
                <EuiButtonIcon
                  onClick={() => this.deleteRow(pair.id)}
                  aria-label={formatMessage(messages.removeLine)}
                  iconType='cross'
                  color='danger'
                  disabled={i === 0}
                  className='manageKeystore-deleteRow'
                />
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        ))}

        <div>
          <EuiButtonEmpty onClick={this.addValue} data-test-id='manageKeystore-addField'>
            <FormattedMessage id='keystore.flyout.form.add-field' defaultMessage='Add Field' />
          </EuiButtonEmpty>
        </div>
      </Fragment>
    )
  }

  renderFileField() {
    const {
      intl: { formatMessage },
    } = this.props
    const { secret } = this.state
    return (
      <EuiFormRow label={formatMessage(messages.secret)} fullWidth={true}>
        <EuiTextArea
          value={secret}
          onChange={this.onChangeSecret}
          fullWidth={true}
          className='manageKeystore-fileInput'
          placeholder={filePlaceholder}
          rows={15}
        />
      </EuiFormRow>
    )
  }

  addValue = (): void => {
    const { keySecretPairs } = this.state
    const newKeySecretPairs = cloneDeep(keySecretPairs)
    newKeySecretPairs.push({ key: '', secret: '', id: makeId() })
    this.setState({ keySecretPairs: newKeySecretPairs })
  }

  isValidKey(key) {
    return validKeysRegex.test(key)
  }

  shouldDisableConfirmButton() {
    return this.state.confirmText !== expectedConfirmation
  }

  createSecret = (): void => {
    const { addSecretToKeystore, closeFlyout, onSaveError } = this.props
    const { parentKey, confirmText } = this.state

    if (parentKey.length === 0) {
      this.setState({ isInvalid: true, invalidReason: 'required' })
      return
    }

    if (!(this.isValidKey(parentKey) || confirmText === expectedConfirmation)) {
      this.setState({ warningVisible: true, confirmText: '' })
      return
    }

    const secrets = this.formatData()

    addSecretToKeystore(secrets)
      .then(() => {
        closeFlyout()
        addToast({
          ...toastText.secretCreationSuccess,
        })
        return
      })
      .catch(() => {
        closeFlyout()
        onSaveError()
      })
  }

  formatData = (): Keystore => {
    const { keySecretPairs, type, parentKey, secret } = this.state

    if (type === 'single') {
      return {
        [parentKey]: {
          value: secret,
        },
      }
    }

    if (type === 'file') {
      return {
        [parentKey]: {
          value: secret,
          as_file: true,
        },
      }
    }

    if (type === 'multi') {
      return {
        [parentKey]: {
          value: keySecretPairs.reduce((obj, item) => {
            obj[item.key] = item.secret
            return obj
          }, {}),
        },
      }
    }

    return {}
  }

  onChangeButtonGroup = (optionId) => {
    this.setState({
      type: optionId,
      keySecretPairs: [{ key: '', secret: '', id: makeId() }],
      secret: '',
    })
  }

  onChangeParentKey = (e) => {
    const {
      target: { value },
    } = e
    const parentKeyRegExp = /^[a-z0-9_\-.]+$/

    if (!parentKeyRegExp.test(value)) {
      this.setState({ isInvalid: true, invalidReason: 'matchRegex' })
    } else {
      this.setState({ isInvalid: false, invalidReason: null })
    }

    this.setState({ parentKey: value, warningVisible: false, confirmText: '' })
  }

  onChangeKeySecret = (e) => {
    const { keySecretPairs } = this.state
    const newKeySecretPairs = cloneDeep(keySecretPairs)
    newKeySecretPairs[e.target.dataset.id][e.target.dataset.name] = e.target.value

    this.setState({ keySecretPairs: newKeySecretPairs })
  }

  onChangeSecret = (e) => {
    this.setState({ secret: e.target.value })
  }

  onChangeConfirmText = (e) => {
    const {
      target: { value },
    } = e

    this.setState({ confirmText: value })
  }

  deleteRow(id) {
    const { keySecretPairs } = this.state

    const remainingPairs = filter(keySecretPairs, (pair) => pair.id !== id)

    this.setState({ keySecretPairs: remainingPairs })
  }
}

export default injectIntl(ManageKeystore)
