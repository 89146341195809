/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import {
  resetUpdateOrganizationMemberRoleAssignmentRequest,
  updateOrganizationMemberRoleAssignments,
} from '@/actions/organizations'
import { updateOrganizationMemberRoleAssignmentsRequest } from '@/reducers/asyncRequests/registry'

import OrganizationMemberRoleAssignments from './OrganizationMemberRoleAssignments'

import type { ConsumerProps, DispatchProps, StateProps } from './types'
import type { RoleAssignmentsDiff } from '@/components/Users/RoleAssignmentsPanel/lib'

const mapStateToProps = (
  state: ReduxState,
  { organizationId, member }: ConsumerProps,
): StateProps => ({
  updateOrganizationMemberRoleAssignmentsRequest: updateOrganizationMemberRoleAssignmentsRequest(
    state,
    organizationId,
    member.id,
  ),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organizationId, member }: ConsumerProps,
): DispatchProps => ({
  updateOrganizationMemberRoleAssignments: (
    roleAssignments: RoleAssignments,
    roleAssignmentsDiff: RoleAssignmentsDiff,
  ) =>
    dispatch(
      updateOrganizationMemberRoleAssignments({
        organizationId,
        userId: member.id,
        roleAssignments,
        roleAssignmentsDiff,
      }),
    ),
  resetUpdateOrganizationMemberRoleAssignmentsRequest: () =>
    dispatch(resetUpdateOrganizationMemberRoleAssignmentRequest(organizationId, member.id)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationMemberRoleAssignments)
