/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, sortBy } from 'lodash'
import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import { EuiBadge, EuiIcon, EuiTextColor } from '@elastic/eui'

import type { Container } from '@modules/cloud-api/v1/types'
import { CuiLink, CuiTable } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import { hostUrl, containerUrl } from '../../lib/urlBuilder'

import type { FunctionComponent } from 'react'
import type { IntlShape } from 'react-intl'

type Props = {
  intl: IntlShape
  regionId: string
  containerSetId: string
  containers: Container[]
  totalCount: number
  initialLoading: boolean
}

const messages = defineMessages({
  health: {
    id: `containers-table.health`,
    defaultMessage: `Health`,
  },
  containerSetId: {
    id: `containers-table.container-id`,
    defaultMessage: `Container`,
  },
  hostIds: {
    id: `containers-table.host-ids`,
    defaultMessage: `Hosts`,
  },
})

const ContainersTable: FunctionComponent<Props> = ({
  intl,
  regionId,
  containerSetId,
  containers,
  totalCount,
  initialLoading,
}) => {
  const { formatMessage } = intl

  const columns: Array<CuiTableColumn<Container>> = [
    {
      mobile: {
        label: formatMessage(messages.health),
      },
      render: (container) => (
        <EuiIcon type='dot' color={isEmpty(container.instances) ? `warning` : `success`} />
      ),
      width: `40px`,
    },

    {
      label: formatMessage(messages.containerSetId),
      render: (container) => (
        <CuiLink to={containerUrl(regionId, containerSetId, container.container_id)}>
          {container.container_id}
        </CuiLink>
      ),
      sortKey: `container_id`,
      width: `300px`,
    },

    {
      label: formatMessage(messages.hostIds),
      render: (container) =>
        isEmpty(container.instances) ? (
          <EuiTextColor color='subdued'>
            <FormattedMessage
              id='container-set-containers-table.runners-none'
              defaultMessage='None'
            />
          </EuiTextColor>
        ) : (
          container.instances!.map((instance) => (
            <EuiBadge key={instance.runner_id}>
              <CuiLink to={hostUrl(regionId, instance.runner_id)}>{instance.runner_id}</CuiLink>
            </EuiBadge>
          ))
        ),
    },
  ]

  return (
    <CuiTable<Container>
      rows={sortBy(containers, `container_id`)}
      columns={columns}
      getRowId={(container) => container.container_id}
      totalCount={totalCount}
      pageSize={50}
      initialLoading={initialLoading}
    />
  )
}

export default injectIntl(ContainersTable)
