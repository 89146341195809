/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormHelpText, EuiSpacer } from '@elastic/eui'

import type { AsyncRequestState, StackDeployment } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import DangerButton from '../../DangerButton'
import FormGroup from '../../FormGroup'

export type StateProps = {
  canToggleDeploymentLock: boolean
  isLocked: boolean
  lockDeploymentRequest: AsyncRequestState
}

export type DispatchProps = {
  lockDeployment: (locked: boolean) => Promise<unknown>
  resetLockDeploymentRequest: () => void
}

export type ConsumerProps = {
  deployment: StackDeployment
}

type Props = StateProps & DispatchProps & ConsumerProps

class DeploymentLock extends Component<Props> {
  componentWillUnmount() {
    this.props.resetLockDeploymentRequest()
  }

  render() {
    const { canToggleDeploymentLock, isLocked, lockDeployment, lockDeploymentRequest } = this.props

    if (!canToggleDeploymentLock) {
      return null
    }

    return (
      <Fragment>
        <FormGroup
          label={
            <FormattedMessage id='deployment-lock.title' defaultMessage='Deployment locking' />
          }
        >
          <EuiSpacer size='s' />

          <DangerButton
            color='warning'
            iconType={isLocked ? `lockOpen` : `lock`}
            spin={lockDeploymentRequest.inProgress}
            onConfirm={() => lockDeployment(!isLocked)}
            modal={
              isLocked
                ? {
                    title: (
                      <FormattedMessage
                        id='deployment-lock.unlock-title'
                        defaultMessage='Unlock this deployment?'
                      />
                    ),
                    body: (
                      <FormattedMessage
                        id='deployment-lock.unlock-body'
                        defaultMessage='Unlocking the deployment allows users to initiate configuration changes.'
                      />
                    ),
                    confirmButtonText: (
                      <FormattedMessage
                        id='deployment-lock.unlock-confirm'
                        defaultMessage='Unlock'
                      />
                    ),
                  }
                : {
                    title: (
                      <FormattedMessage
                        id='deployment-lock.lock-title'
                        defaultMessage='Lock this deployment?'
                      />
                    ),
                    body: (
                      <FormattedMessage
                        id='deployment-lock.lock-body'
                        defaultMessage='Locking the deployment prevents users from initiating configuration changes.'
                      />
                    ),
                    confirmButtonText: (
                      <FormattedMessage id='deployment-lock.lock-confirm' defaultMessage='Lock' />
                    ),
                  }
            }
          >
            {isLocked ? (
              <FormattedMessage id='deployment-lock.turn-off' defaultMessage='Unlock' />
            ) : (
              <FormattedMessage id='deployment-lock.turn-on' defaultMessage='Lock' />
            )}
          </DangerButton>

          <EuiFormHelpText>
            <FormattedMessage
              id='deployment-lock.description'
              defaultMessage="When a deployment is locked, users can't initiate configuration changes."
            />
          </EuiFormHelpText>

          {lockDeploymentRequest.error && (
            <Fragment>
              <EuiSpacer size='m' />
              <CuiAlert type='error'>{lockDeploymentRequest.error}</CuiAlert>
            </Fragment>
          )}
        </FormGroup>
      </Fragment>
    )
  }
}

export default DeploymentLock
