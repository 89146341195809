/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSkeletonText, EuiSpacer, EuiTitle, EuiDescribedFormGroup } from '@elastic/eui'

import type { AsyncRequestState, License as LicenseType } from '@modules/ui-types'

import DocLink from '../../DocLink'

import AddLicense from './AddLicense'
import LicenseDetails from './LicenseDetails'

export interface Props {
  fetchLicense: (regionId: string) => void
  regionId: string
  license?: LicenseType | null
  fetchLicenseRequest: AsyncRequestState
  addLicenseRequest: AsyncRequestState
  removeLicenseRequest: AsyncRequestState
}

export default class License extends Component<Props> {
  componentDidMount() {
    const { fetchLicense, regionId } = this.props
    fetchLicense(regionId)
  }

  render() {
    return (
      <EuiDescribedFormGroup
        title={
          <EuiTitle size='xs'>
            <h3>
              <FormattedMessage id='region-admin-license.license-header' defaultMessage='License' />
            </h3>
          </EuiTitle>
        }
        description={
          <Fragment>
            <FormattedMessage
              id='region-admin-license.license-description'
              defaultMessage='The use of Elastic Cloud Enterprise requires a valid license, which you can obtain from Elastic and add to your installation.'
            />

            <EuiSpacer size='m' />

            <FormattedMessage
              id='region-admin-license.license-description-2'
              defaultMessage='Full ECE licenses that you obtain from Elastic enable the same products, features, and support that are available to Platinum subscriptions on Elastic Cloud. {learnMore}.'
              values={{
                learnMore: (
                  <DocLink link='addEceLicenseDocLink'>
                    <FormattedMessage
                      id='region-admin-license.license-learn-more'
                      defaultMessage='Learn more'
                    />
                  </DocLink>
                ),
              }}
            />
          </Fragment>
        }
        fullWidth={true}
      >
        {this.renderContent()}
      </EuiDescribedFormGroup>
    )
  }

  renderContent() {
    const { regionId, license, fetchLicenseRequest, addLicenseRequest, removeLicenseRequest } =
      this.props

    if (fetchLicenseRequest.inProgress) {
      return (
        <div data-test-id='fetching-license'>
          <EuiSkeletonText />
        </div>
      )
    }

    if (!license) {
      return <AddLicense regionId={regionId} />
    }

    return (
      <LicenseDetails
        regionId={regionId}
        license={license}
        addLicenseRequest={addLicenseRequest}
        removeLicenseRequest={removeLicenseRequest}
      />
    )
  }
}
