/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import type { Container } from '@modules/cloud-api/v1/types'
import { CuiFilterContext, createQueryExecutor } from '@modules/cui'
import type { ControlledFilterQuery } from '@modules/cui'

import { getQueryModel, schema, defaultFields } from './schema'
import { getFilters } from './filters'

import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = {
  intl: IntlShape
  query: ControlledFilterQuery
  onChange: (q: any) => void
  containers?: Container[]
  isLoading: boolean
}

const messages = defineMessages({
  searchBarPlaceholder: {
    id: `containers-filter-context.placeholder`,
    defaultMessage: `e.g.: ui`,
  },
  emptyMessage: {
    id: `containers-filter-context.no-matches`,
    defaultMessage: `No containers match your query`,
  },
})

const executeQuery = createQueryExecutor({
  defaultFields,
  getQueryModel,
})

const ContainersFilterContext: FunctionComponent<Props> = ({
  intl,
  query,
  onChange,
  containers,
  isLoading,
}) => {
  const { formatMessage } = intl
  const filters = getFilters()
  const placeholder = formatMessage(messages.searchBarPlaceholder)
  const emptyMessage = formatMessage(messages.emptyMessage)

  return (
    <CuiFilterContext<Container>
      query={query}
      onChange={onChange}
      records={containers}
      schema={schema}
      filters={filters}
      executeQuery={executeQuery}
      placeholder={placeholder}
      emptyMessage={emptyMessage}
      isLoading={isLoading}
    />
  )
}

export default injectIntl(ContainersFilterContext)
