/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, difference, differenceBy, isEmpty } from 'lodash'

import type {
  ElasticsearchClusterPlan,
  ElasticsearchUserBundle,
  ElasticsearchUserPlugin,
  Extension,
  StackVersionConfig,
} from '@modules/cloud-api/v1/types'

import { satisfies } from './semver'

type PlanUserExtension = ElasticsearchUserPlugin | ElasticsearchUserBundle
type ExtensionType = 'plugin' | 'bundle'

export function getAllowedPluginsForVersions({
  plan,
  versions,
}: {
  plan: ElasticsearchClusterPlan
  versions: StackVersionConfig[]
}): string[] {
  const planVersion = plan.elasticsearch.version
  const version = versions.find((stackVersion) => stackVersion.version === planVersion)
  return getAllowedPluginsForVersion({ version })
}

function getAllowedPluginsForVersion({ version }: { version?: StackVersionConfig }): string[] {
  if (version == null || version.elasticsearch == null) {
    return []
  }

  /* 1. `default_plugins`: the system demands that clusters always have these enabled,
   *    or else the system itself will not be able to work with the cluster correctly.
   *    We _never_ want to offer users a choice about plugins in the `default_plugins` list.
   *
   * 2. `extraDefaultIngestPlugins` are unrelated. These are *user-controlled* plugins,
   *    and we want to have the UI select them by default for certain usability reasons.
   *    Users might indeed choose to remove or add these plugins.
   */
  const allowedPlugins = difference(
    version.elasticsearch.plugins,
    version.elasticsearch.default_plugins,
  )

  return allowedPlugins
}

export function getCustomPluginsFromPlan(
  plan: ElasticsearchClusterPlan,
  kind?: ExtensionType,
): PlanUserExtension[] {
  const extensions: PlanUserExtension[] = []

  if (!kind || kind === 'bundle') {
    extensions.push(...(plan.elasticsearch.user_bundles || []))
  }

  if (!kind || kind === 'plugin') {
    extensions.push(...(plan.elasticsearch.user_plugins || []))
  }

  return extensions
}

export function getIncompatibleCustomPlugins(
  oldVersion: string | null | undefined,
  newVersion: string | null | undefined,
  plan: ElasticsearchClusterPlan,
): PlanUserExtension[] {
  if (!newVersion || newVersion === oldVersion) {
    return []
  }

  const userExtensions = getCustomPluginsFromPlan(plan)

  if (isEmpty(userExtensions)) {
    return []
  }

  const unsafeUserExtensions = userExtensions.filter(isUnsafeExtension)
  return unsafeUserExtensions

  function isUnsafeExtension(extension: PlanUserExtension): boolean {
    return !satisfies(newVersion!, extension.elasticsearch_version)
  }
}

export function getPlanWithUpdatedCustomPlugins(
  selectedPluginUrls: string[],
  removedPlugins: PlanUserExtension[],
  extensions: Extension[],
  plan: ElasticsearchClusterPlan,
): ElasticsearchClusterPlan {
  const updatedPlan = cloneDeep(plan)

  const selectedUserBundles: PlanUserExtension[] = []
  const selectedUserPlugins: PlanUserExtension[] = []

  for (const pluginUrl of selectedPluginUrls) {
    const extension = extensions.find((each) => each.url === pluginUrl)

    if (!extension) {
      continue
    }

    const planExtension = convertExtensionToPlanFormat(plan, extension)

    if (extension.extension_type === `bundle`) {
      selectedUserBundles.push(planExtension)
    } else {
      selectedUserPlugins.push(planExtension)
    }
  }

  const newUserBundles = differenceBy(
    getCustomPluginsFromPlan(updatedPlan, `bundle`).concat(selectedUserBundles),
    removedPlugins,
    `url`,
  )

  const newUserPlugins = differenceBy(
    getCustomPluginsFromPlan(updatedPlan, `plugin`).concat(selectedUserPlugins),
    removedPlugins,
    `url`,
  )

  updatedPlan.elasticsearch.user_bundles = newUserBundles
  updatedPlan.elasticsearch.user_plugins = newUserPlugins

  return updatedPlan
}

export function convertExtensionToPlanFormat(
  plan: ElasticsearchClusterPlan,
  extension: Extension,
): PlanUserExtension {
  const { name, url, version } = extension
  const planVersion = plan.elasticsearch.version
  const elasticsearch_version = version || planVersion!

  return {
    name,
    url,
    elasticsearch_version,
  }
}
