/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { UpdatedTlsChain } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, Region as RegionType, License, RegionId } from '@modules/ui-types'

import { fetchRegion } from '../../actions/regions'
import { fetchLicense } from '../../actions/licenses'
import { getRegion, getLicense, fetchLicenseRequest, fetchProvidersRequest } from '../../reducers'
import { fetchTlsCertificate } from '../../actions/tls'

import Region from './Region'

import type { ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  fetchLicenseRequest: AsyncRequestState
  license?: License | null
  region?: RegionType
  regionId: RegionId
  providersRequest: AsyncRequestState
}

type DispatchProps = {
  fetchRegion: () => void
  fetchLicense: (regionId: RegionId) => void
  fetchTlsCertificate: (regionId: RegionId, target: UpdatedTlsChain) => void
}

type QueryParams = {
  regionId: RegionId
}

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => ({
  fetchLicenseRequest: fetchLicenseRequest(state, regionId),
  license: getLicense(state, regionId),
  region: getRegion(state, regionId),
  regionId,
  providersRequest: fetchProvidersRequest(state, regionId),
})

const mapDispatchToProps: DispatchProps = {
  fetchRegion,
  fetchLicense,
  fetchTlsCertificate: (regionId: RegionId, target: UpdatedTlsChain) =>
    fetchTlsCertificate(regionId, target.service),
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Region)
