/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiDescribedFormGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiSwitch,
  EuiText,
} from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import DocLink from '../../DocLink'

interface Props {
  fetchPhoneHomeIsEnabled: () => void
  isPhoneHomeEnabled: boolean
  phoneHomeConfigRequest: AsyncRequestState
  updatePhoneHomeIsEnabled: (enabled: boolean) => void
}

class PhoneHomeConfig extends Component<Props> {
  componentDidMount() {
    this.props.fetchPhoneHomeIsEnabled()
  }

  render() {
    const { isPhoneHomeEnabled, phoneHomeConfigRequest, updatePhoneHomeIsEnabled } = this.props

    return (
      <EuiDescribedFormGroup
        title={
          <EuiText size='xs'>
            <h3>
              <FormattedMessage
                id='eula.phone-home.usage-statistics'
                defaultMessage='Share usage statistics with Elastic'
              />
            </h3>
          </EuiText>
        }
        description={
          <FormattedMessage
            id='eula.phone-home.opt-out-message'
            defaultMessage='Enabling data usage collection helps us manage and improve our products and services. We do not share these statistics with anyone. {learnMore}'
            values={{
              learnMore: (
                <DocLink link='phoneHome'>
                  <FormattedMessage
                    id='region-settings.statistics.learn-more'
                    defaultMessage='Learn more'
                  />
                </DocLink>
              ),
            }}
          />
        }
        fullWidth={true}
      >
        <EuiFormRow fullWidth={true}>
          <div>
            <EuiSwitch
              id='phone-home-enabled'
              checked={isPhoneHomeEnabled}
              onChange={() => updatePhoneHomeIsEnabled(!isPhoneHomeEnabled)}
              label={
                <EuiFlexGroup gutterSize='l' alignItems='center' responsive={false}>
                  <EuiFlexItem grow={false}>
                    {isPhoneHomeEnabled ? (
                      <FormattedMessage
                        id='eula.phone-home.phone-home-enabled'
                        defaultMessage='On'
                      />
                    ) : (
                      <FormattedMessage
                        id='eula.phone-home.phone-home-disabled'
                        defaultMessage='Off'
                      />
                    )}
                  </EuiFlexItem>

                  {phoneHomeConfigRequest.inProgress && (
                    <EuiFlexItem grow={false}>
                      <EuiLoadingSpinner size='m' />
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              }
            />

            {phoneHomeConfigRequest.error && (
              <Fragment>
                <EuiSpacer size='m' />

                <CuiAlert type='error'>{phoneHomeConfigRequest.error}</CuiAlert>
              </Fragment>
            )}
          </div>
        </EuiFormRow>
      </EuiDescribedFormGroup>
    )
  }
}

export default PhoneHomeConfig
