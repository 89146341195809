/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type {
  ClauseConnector,
  InnerClause,
  OuterClause as OuterClauseType,
} from '@modules/ui-types/instanceConfigurationTypes'

import ClauseActions from './ClauseActions'
import OuterClause from './OuterClause'

import type { FunctionComponent } from 'react'

type Props = {
  appendInnerClause: (outerClause: OuterClauseType, connector: ClauseConnector) => void
  appendOuterClause: (connector: ClauseConnector) => void
  clauses: OuterClauseType[]
  deleteInnerClause: (outerClause: OuterClauseType, innerClause: InnerClause) => void
  deleteOuterClause: (outerClause: OuterClauseType) => void
  errorsInClauses: unknown
  setInnerClauseConnector: (outerClause: OuterClauseType, connector: ClauseConnector) => void
  setOuterClauseConnector: (connector: ClauseConnector) => void
  setTagKey: (outerClause: OuterClauseType, index: number, value: string) => void
  setTagValue: (outerClause: OuterClauseType, index: number, value: string) => void
}

const QueryBuilder: FunctionComponent<Props> = ({
  appendInnerClause,
  appendOuterClause,
  clauses,
  deleteInnerClause,
  deleteOuterClause,
  errorsInClauses,
  setInnerClauseConnector,
  setOuterClauseConnector,
  setTagKey,
  setTagValue,
}) => (
  <ul className='topologyFilterAllocators-filterClauses'>
    {clauses.map((outerClause, outerIndex) => (
      <li
        key={outerIndex}
        className='topologyFilterAllocators-filterClause topologyFilterAllocators-filterClause-outer'
      >
        <OuterClause
          errorsInClauses={errorsInClauses}
          isRoot={outerIndex === 0}
          outerClause={outerClause}
          appendInnerClause={appendInnerClause}
          deleteOuterClause={deleteOuterClause}
          deleteInnerClause={deleteInnerClause}
          setOuterClauseConnector={setOuterClauseConnector}
          setInnerClauseConnector={setInnerClauseConnector}
          setTagKey={setTagKey}
          setTagValue={setTagValue}
        />
      </li>
    ))}
    <li>
      <ClauseActions level='outer' appendClause={appendOuterClause} />
    </li>
  </ul>
)

export default QueryBuilder
