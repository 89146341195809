/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiIcon, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import type { RootConfig, AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import SpinButton from '../SpinButton'
import { logoutUrl } from '../../lib/urlBuilder'
import LanguageSwitcher from '../LanguageSwitcher'

import EulaAgreement from './EulaAgreement'

import type { WrappedComponentProps } from 'react-intl'

import './eula.scss'

interface Props extends WrappedComponentProps {
  acceptEula: (root: RootConfig) => void
  acceptEulaRequest: AsyncRequestState
  root: RootConfig
}

type State = {
  lang: string
}

class Eula extends Component<Props, State> {
  state: State = {
    lang: this.props.intl.locale,
  }

  render() {
    const { acceptEula, acceptEulaRequest, root } = this.props
    const { lang } = this.state

    return (
      <div className='eula'>
        <EuiFlexGroup gutterSize='m' alignItems='center' justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <div>
              <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                <EuiFlexItem grow={false}>
                  <EuiIcon type='logoElastic' size='xl' />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <EuiTitle data-test-id='eula-title-elastic'>
                    <h2>
                      <FormattedMessage id='eula.ece-title' defaultMessage='Elastic' />
                    </h2>
                  </EuiTitle>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <div>
              <LanguageSwitcher lang={lang} onChange={this.setLanguage} />
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='l' />

        <EuiText>
          <FormattedMessage
            id='eula.accept-before-use'
            defaultMessage='You need to accept our End User License Agreement before you can use Elastic Cloud Enterprise.'
          />
        </EuiText>

        <EuiSpacer size='m' />

        <EulaAgreement lang={lang} />

        <EuiSpacer size='m' />

        <EuiFlexGroup gutterSize='m' alignItems='center' justifyContent='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <SpinButton
              data-test-id='eula-agree'
              color='primary'
              fill={true}
              onClick={() => acceptEula(root)}
              spin={acceptEulaRequest.inProgress}
            >
              <FormattedMessage id='eula.agree' defaultMessage='Agree' />
            </SpinButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <Link className='eula-logout' to={logoutUrl()}>
              <FormattedMessage id='eula.decline' defaultMessage='Decline' />
            </Link>
          </EuiFlexItem>
        </EuiFlexGroup>

        {acceptEulaRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{acceptEulaRequest.error}</CuiAlert>
          </Fragment>
        )}
      </div>
    )
  }

  setLanguage = (lang) => {
    this.setState({ lang })
  }
}

export default injectIntl(Eula)
