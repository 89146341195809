/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { fetchInstanceConfigurations } from '@/actions/topology/instanceConfigurations'
import { getInstanceConfigurations } from '@/reducers'
import withPolling from '@/lib/withPolling'

import InstanceConfigurationList from './InstanceConfigurationList'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  instanceConfigurations: getInstanceConfigurations(state, regionId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  fetchInstanceConfigurations: () =>
    dispatch(fetchInstanceConfigurations(regionId, { showMaxZones: true })),
})

const PollingComponent = withPolling(
  InstanceConfigurationList,
  ({ fetchInstanceConfigurations: onPoll }) => ({
    onPoll,
  }),
)

export default connect(mapStateToProps, mapDispatchToProps)(PollingComponent)
