/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { updateStackPacksUrl } from '@modules/cloud-api/v1/urls'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { UPLOAD_STACK_PACK } from '../../constants/actions'

export function uploadStackPack(regionId: string, stackPack) {
  const url = updateStackPacksUrl({ regionId })

  return asyncRequest({
    type: UPLOAD_STACK_PACK,
    method: `POST`,
    url,
    requestSettings: {
      request: {
        headers: {
          'Content-Type': `application/zip`,
        },
      },
      json: false,
    },
    payload: stackPack,
    meta: { regionId },
    crumbs: [regionId],
  })
}

export const resetUploadStackPackRequest = (...crumbs) =>
  resetAsyncRequest(UPLOAD_STACK_PACK, crumbs)
