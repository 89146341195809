/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import MediaQuery from 'react-responsive'
import Sticky from 'react-sticky-el'

import { EuiDescriptionList, EuiSpacer, EuiText, EuiTextColor, EuiTitle } from '@elastic/eui'

import type {
  DeploymentTemplateInfoV2,
  DeploymentTemplateRequestBody,
  InstanceConfiguration,
} from '@modules/cloud-api/v1/types'
import { CuiTimeAgo } from '@modules/cui'

import { getTopologiesFromTemplate } from '../../../lib/deploymentTemplates/getTopologiesFromTemplate'

import ArchitectureVizFromTopology from './components/ArchitectureViz/ArchitectureVizFromTopology'

import type { FunctionComponent, ReactElement } from 'react'

import './components/ArchitectureViz/architectureVizSticky.scss'

export type Props = {
  template: DeploymentTemplateRequestBody | DeploymentTemplateInfoV2
  instanceConfigurations: InstanceConfiguration[]
  render: (name: string, content: ReactElement) => ReactElement<any>
}

const DeploymentTemplateArchitectureViz: FunctionComponent<Props> = ({
  template,
  instanceConfigurations,
  render,
}) => {
  const {
    name,
    description,
    min_version,
    source: { admin_id: adminId, date } = { admin_id: undefined, date: undefined },
  } = template as DeploymentTemplateInfoV2

  const summaryItems = [
    {
      title: <FormattedMessage id='architecture-panel.summary-name' defaultMessage='Name' />,
      description: name || (
        <em>
          <FormattedMessage
            id='architecture-panel.summary-name-placeholder'
            defaultMessage='No name yet'
          />
        </em>
      ),
    },
  ]

  if (min_version) {
    summaryItems.push({
      title: (
        <FormattedMessage
          id='architecture-panel.summary-min-version'
          defaultMessage='Min. version'
        />
      ),
      description: min_version,
    })
  }

  if (description) {
    summaryItems.push({
      title: <FormattedMessage id='architecture-panel.description' defaultMessage='Description' />,
      description,
    })
  }

  let auditMessage: React.ReactNode = null

  if (date) {
    const formattedDateCreated = <CuiTimeAgo shouldCapitalize={false} date={new Date(date)} />

    auditMessage = (
      <EuiText size='xs'>
        <EuiSpacer size='s' />
        <EuiTextColor color='subdued'>
          <FormattedMessage
            id='view-deployment-template.summary-meta-data-custom'
            defaultMessage='Created by {author} {dateCreated}'
            values={{
              dateCreated: formattedDateCreated,
              author: adminId || `root`,
            }}
          />
        </EuiTextColor>
      </EuiText>
    )
  }

  const content = (
    <MediaQuery minDeviceWidth={768}>
      {(matches) => (
        <Sticky
          stickyStyle={{ marginTop: `20px` }}
          disabled={!matches}
          boundaryElement='[data-app="appContentBody"]'
        >
          <EuiTitle>
            <h3>
              <FormattedMessage id='architecture-panel.summary-title' defaultMessage='Summary' />
            </h3>
          </EuiTitle>

          <EuiSpacer size='m' />

          <EuiDescriptionList listItems={summaryItems} />

          <EuiSpacer size='l' />

          <EuiTitle>
            <h3>
              <FormattedMessage
                id='architecture-panel.architecture-title'
                defaultMessage='Architecture'
              />
            </h3>
          </EuiTitle>

          <ArchitectureVizFromTopology
            nodeConfigurations={getTopologiesFromTemplate({
              deploymentTemplate: template.deployment_template,
            })}
            instanceConfigurations={instanceConfigurations}
            version={undefined}
          />
          {auditMessage}
        </Sticky>
      )}
    </MediaQuery>
  )

  return render(`architectureViz stickyViz`, content)
}

export default DeploymentTemplateArchitectureViz
