/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, isEqual } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { get, set } from 'local-storage'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import type {
  DeploymentsSearchResponse,
  DeploymentSearchResponse,
  SearchRequest,
} from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, SliderInstanceType } from '@modules/ui-types'
import {
  CuiAlert,
  CuiLink,
  getFilterQueryString,
  isEmptyQuery,
  setFilterQueryString,
} from '@modules/cui'
import type { OnFilterChangeParams } from '@modules/cui'
import Header from '@modules/cui/Header'

import { deploymentsUrl } from '../../lib/urlBuilder'
import { activityFeedCrumbs } from '../../lib/crumbBuilder'
import LocalStorageKey from '../../constants/localStorageKeys'

import ActivityFeedFilterContext, { getEsQuery } from './ActivityFeedFilterContext'
import ActivityFeedTable from './ActivityFeedTable'
import ActivityFeedSchedule from './ActivityFeedSchedule'
import FilterContextSliderScope from './FilterContextSliderScope'
import { parseEsQuery } from './activityFeedQuery'

type Props = {
  deletedDeploymentIds: string[]
  searchResults: DeploymentsSearchResponse | null
  searchResultsRequest: AsyncRequestState
  searchDeployments: (query: SearchRequest) => void
}

type Scope = SliderInstanceType[]

type State = {
  esQuery: any
  scope?: Scope
  query: string | null
  queryResults: DeploymentSearchResponse[]
}

class StackActivityFeed extends Component<Props, State> {
  state: State = this.getInitialState()

  getInitialState(): State {
    const queryString = getFilterQueryString({ storageKey: `ActivityFeed` })
    const esQuery = getEsQuery(queryString)
    const scope = get<Scope | null>(LocalStorageKey.activityFeedScope) || undefined

    return {
      esQuery,
      scope,
      query: queryString,
      queryResults: [],
    }
  }

  componentDidMount() {
    this.search()
  }

  componentDidUpdate(_oldProps: Props, oldState: State) {
    const { esQuery } = this.state

    if (!isEqual(esQuery, oldState.esQuery)) {
      this.search()
    }
  }

  render() {
    const { searchResultsRequest } = this.props
    const { query, scope } = this.state
    const deployments = this.getDeploymentRecords()

    return (
      <Fragment>
        <Header
          name={
            <FormattedMessage id='activity-feed.activity-feed' defaultMessage='Activity Feed' />
          }
          breadcrumbs={activityFeedCrumbs()}
        />

        {searchResultsRequest.error && (
          <Fragment>
            <CuiAlert type='error'>{searchResultsRequest.error}</CuiAlert>
            <EuiSpacer size='m' />
          </Fragment>
        )}

        <ActivityFeedSchedule search={this.search} busy={searchResultsRequest.inProgress} />

        <ActivityFeedFilterContext
          query={query}
          onChange={this.onChange}
          deployments={deployments}
          isLoading={searchResultsRequest.inProgress}
          tools={[
            <FilterContextSliderScope key='scope-filters' scope={scope} onChange={this.setScope} />,
          ]}
        />

        <EuiSpacer size='m' />

        {this.renderActivityTable()}
      </Fragment>
    )
  }

  renderActivityTable() {
    const { searchResults, searchResultsRequest, deletedDeploymentIds } = this.props
    const { query, queryResults, scope } = this.state

    const noErrors = !searchResultsRequest.error
    const isLoading = !searchResults && noErrors

    const emptyQuery = isEmptyQuery(query)

    const deployments =
      isLoading && isEmpty(queryResults)
        ? null
        : queryResults.filter((deployment) => !deletedDeploymentIds.includes(deployment.id))

    return (
      <ActivityFeedTable
        deployments={deployments}
        scope={scope}
        emptyText={
          <EuiCallOut
            color='primary'
            title={
              emptyQuery ? (
                <FormattedMessage
                  id='activity-feed.empty'
                  defaultMessage='Nothing to see here! Go, live a little — {editSomeCluster}, just for fun.'
                  values={{
                    editSomeCluster: (
                      <CuiLink to={deploymentsUrl()}>
                        <FormattedMessage
                          id='activity-feed.edit-a-deployment'
                          defaultMessage='edit a deployment'
                        />
                      </CuiLink>
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id='activity-feed.no-matches'
                  defaultMessage='No configuration changes match your query'
                />
              )
            }
          />
        }
      />
    )
  }

  getDeploymentRecords() {
    const { searchResults } = this.props
    return searchResults ? searchResults.deployments : []
  }

  onChange = ({
    query,
    queryText,
    queryResults,
  }: OnFilterChangeParams<DeploymentSearchResponse>) => {
    this.setState({
      esQuery: getEsQuery(query),
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `ActivityFeed`, queryText })
  }

  search = () => {
    const { searchDeployments, deletedDeploymentIds } = this.props
    const { esQuery, scope } = this.state

    const query = parseEsQuery({
      esQuery,
      deletedDeploymentIds,
      scope,
    })

    searchDeployments(query)
  }

  setScope = (scope: Scope | undefined) => {
    this.setState({ scope })
    set<Scope | null>(LocalStorageKey.activityFeedScope, scope || null)
  }
}

export default StackActivityFeed
