/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'

import type { QueryContainer } from '@modules/cloud-api/v1/types'
import type { OuterClause } from '@modules/ui-types/instanceConfigurationTypes'

import { allocatorFilterToClauses, removeMalformedClauses } from './allocatorFilterClauses'

export function clausesToExpression(clauses: OuterClause[]) {
  return removeMalformedClauses(clauses).map(toOuterClauseString).join(` `)
}

function toOuterClauseString(outerClause, outerIndex) {
  const { connector, innerClauses } = outerClause

  const prefix = outerIndex === 0 ? `` : `${connector.toUpperCase()} `

  const innerString = innerClauses.map(toInnerClauseString).join(` `)

  return `${prefix}(${innerString})`
}

function toInnerClauseString(innerClause, innerIndex) {
  const { connector, key, value } = innerClause

  const query = isEmpty(value) ? key : `${key}:${value}`

  if (innerIndex === 0) {
    return query
  }

  return `${connector.toUpperCase()} ${query}`
}

export function allocatorFilterToExpression(payload: QueryContainer | undefined) {
  return clausesToExpression(allocatorFilterToClauses(payload))
}
