/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { PlatformInfo } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { fetchPlatformOverviewIfNeeded } from '../../../../actions/platform'
import { fetchPlatformRequest, getPlatformOverview } from '../../../../reducers'

import AllowExtraVersions from './AllowExtraVersions'

type ConsumerProps = {
  organizationId?: string
}

type DispatchProps = {
  fetchPlatformOverviewIfNeeded: () => void
}

type StateProps = {
  platform: PlatformInfo | null
  fetchPlatformRequest: AsyncRequestState
}

export type AllProps = StateProps & DispatchProps & ConsumerProps

const mapStateToProps = (state, { organizationId }: ConsumerProps) => ({
  organizationId,
  platform: getPlatformOverview(state),
  fetchPlatformRequest: fetchPlatformRequest(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchPlatformOverviewIfNeeded: () => dispatch(fetchPlatformOverviewIfNeeded()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AllowExtraVersions)
