/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getUsersUrl } from '@modules/cloud-api/v1/urls'

import { ReduxState } from '@/types/redux'
import { FETCH_DRIFT_JWT, FETCH_PROFILE } from '@/apps/userconsole/constants/actions'
import asyncRequest from '@/actions/asyncRequests'
import { fetchProfileRequest, getProfile } from '@/apps/userconsole/reducers'
import { setApmUserContext } from '@/lib/apm'

export function fetchDriftJwt() {
  const url = 'api/v1/saas/inappchat/token'
  return asyncRequest({
    type: FETCH_DRIFT_JWT,
    method: `GET`,
    url,
  })
}

export function fetchProfile() {
  const url = getUsersUrl()

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: FETCH_PROFILE,
        url,
      }),
    ).then(
      // In the userconsole, the response payload IS NOT a `UserList`, like you would be led
      // to believe from the JSDoc for `getUsersUrl()`. It seems to be some kind of custom payload.
      ({ payload }) => setApmUserContext(String(payload?.user_id), payload?.email),
    )
}

function shouldFetch(state: ReduxState) {
  const profileRequest = fetchProfileRequest(state)

  if (profileRequest.inProgress) {
    return false
  }

  const profile = getProfile(state)
  return profile === null || profile.pending_level
}

export function fetchProfileIfNeeded(params?: { force: boolean }) {
  return (dispatch, getState) => {
    if ((!params || !params.force) && !shouldFetch(getState())) {
      return Promise.resolve()
    }

    return dispatch(fetchProfile())
  }
}
