/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { InstanceConfiguration, MetadataItem } from '@modules/cloud-api/v1/types'
import type { Allocator, AsyncRequestState, RegionId } from '@modules/ui-types'

import {
  addAllocatorTag,
  removeAllocatorTag,
  resetAddAllocatorTagRequest,
  resetRemoveAllocatorTagRequest,
} from '../../../actions/allocators'
import { fetchInstanceConfigurations } from '../../../actions/topology/instanceConfigurations'
import {
  addAllocatorTagRequest,
  getInstanceConfigurations,
  removeAllocatorTagRequest,
} from '../../../reducers'

import AllocatorTags from './AllocatorTags'

interface StateProps {
  addAllocatorTagRequest: AsyncRequestState
  getRemoveAllocatorTagRequest: (key: string) => AsyncRequestState
  allocatorTags: MetadataItem[]
  instanceConfigurations: InstanceConfiguration[]
}

interface DispatchProps {
  addAllocatorTag: (allocator: any, tag: MetadataItem) => Promise<any>
  resetAddAllocatorTagRequest: (regionId: RegionId, id: string) => void
  removeAllocatorTag: (allocator: unknown, key: string) => void
  resetRemoveAllocatorTagRequest: (regionId: RegionId, id: string, key: string) => void
  fetchInstanceConfigurations: (regionId: RegionId) => void
}

type ConsumerProps = {
  allocator: Allocator
}

const mapStateToProps = (state: any, { allocator }: ConsumerProps): StateProps => {
  const instanceConfigurations = getInstanceConfigurations(state, allocator.regionId)

  return {
    addAllocatorTagRequest: addAllocatorTagRequest(state, allocator.regionId, allocator.id),
    getRemoveAllocatorTagRequest: (key) =>
      removeAllocatorTagRequest(state, allocator.regionId, allocator.id, key),
    allocatorTags: Object.keys(allocator.metadata).map((key) => ({
      key,
      value: String(allocator.metadata[key]),
    })),
    instanceConfigurations,
  }
}

const mapDispatchToProps: DispatchProps = {
  // @ts-ignore
  addAllocatorTag,
  resetAddAllocatorTagRequest,
  removeAllocatorTag,
  resetRemoveAllocatorTagRequest,
  fetchInstanceConfigurations,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AllocatorTags)
