/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  EuiFormHelpText,
  EuiCode,
} from '@elastic/eui'

import { getDirectTrustRelationshipTrustRestrictionSettings } from '@/lib/stackDeployments/trustRelationships'
import { securityUrl } from '@/lib/urlBuilder'
import CustomTextPopover from '@/components/CustomTextPopover'
import DocLink from '@/components/DocLink'
import { getConfigForKey } from '@/store'
import { getTargetEnvironmentType } from '@/components/DeploymentTrustRelationships/helpers'

import type { AllProps } from './types'

type State = {
  caCertificatePopoverOpen: boolean
  restrictionSettingsPopoverOpen: boolean
}

class DirectTrustRelationshipInstructions extends React.Component<AllProps, State> {
  state: State = { caCertificatePopoverOpen: false, restrictionSettingsPopoverOpen: false }

  componentDidMount(): void {
    const { deployment, fetchCertificateAuthority } = this.props

    fetchCertificateAuthority(deployment.id)
  }

  render(): JSX.Element | null {
    const { deployment, trustRelationshipFromState, trustRelationshipUnderEdit } = this.props

    const isEss = getConfigForKey(`APP_PLATFORM`) === 'saas'

    return (
      <EuiFlexGroup justifyContent='center'>
        <EuiPanel
          paddingSize='l'
          style={{ maxWidth: `50rem`, paddingLeft: `3rem`, paddingRight: `3rem` }}
        >
          <EuiFlexGroup direction='column' alignItems='center' gutterSize='s'>
            <EuiFlexItem>
              <EuiIcon color='success' type='checkInCircleFilled' size='xxl' />
              <EuiSpacer size='m' />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiTitle size='m'>
                <h1>
                  <FormattedMessage
                    id='directTrustRelationshipInstructions.title'
                    defaultMessage='Environment trusted successfully'
                  />
                </h1>
              </EuiTitle>
              <EuiSpacer size='s' />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiTitle size='xs'>
                <EuiTextColor color='subdued'>
                  <h2 style={{ textTransform: 'uppercase' }}>
                    <FormattedMessage
                      id='directTrustRelationshipInstructions.subtitle'
                      defaultMessage='Next Steps'
                    />
                  </h2>
                </EuiTextColor>
              </EuiTitle>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size='xxl' />

          <EuiText>
            <p>
              <FormattedMessage
                id='directTrustRelationshipInstructions.intro'
                defaultMessage='The trusted environment also needs to trust this deployment. If trust has not been configured on the trusted environment yet, {goToGuidance}follow these steps:'
                values={{
                  goToGuidance:
                    getTargetEnvironmentType(trustRelationshipUnderEdit) === 'ece' &&
                    'go to the Trust management page, under Platform, and ',
                }}
              />
            </p>
          </EuiText>

          <EuiSpacer size='xl' />

          <EuiTitle size='s'>
            <h3>
              <FormattedMessage
                id='directTrustRelationshipInstructions.step1.title'
                defaultMessage='Step 1: Download CA certificate'
              />
            </h3>
          </EuiTitle>
          <EuiSpacer size='m' />
          <EuiText>
            <FormattedMessage
              id='directTrustRelationshipInstructions.step1.body'
              defaultMessage='Download the CA certificate of this deployment. You need it to configure trust with this deployment from your {environmentCluster}.'
              values={{
                environmentCluster: this.envCluster(),
              }}
            />
          </EuiText>
          <EuiSpacer size='m' />
          {this.renderCertButton()}

          <EuiSpacer size='xxl' />

          <EuiTitle size='s'>
            <h3>
              <FormattedMessage
                id='directTrustRelationshipInstructions.step2.title'
                defaultMessage='Step 2: Save trust restriction settings'
              />
            </h3>
          </EuiTitle>
          <EuiSpacer size='m' />
          <EuiText>
            <FormattedMessage
              id='directTrustRelationshipInstructions.step2.body'
              defaultMessage='Copy or download the following trust restriction settings. You need to add these settings to your {environmentName} configuration, in addition to the CA certificate you downloaded in step 1, to make sure that trust is limited to all or specific cluster nodes of this deployment.'
              values={{
                environmentName: this.envName(),
              }}
            />
          </EuiText>
          <EuiSpacer size='m' />
          {this.renderSettingsBox()}

          <EuiSpacer size='xxl' />

          <EuiTitle size='s'>
            <h3>
              <FormattedMessage
                id='directTrustRelationshipInstructions.step3.title'
                defaultMessage='Step 3: Configure {environmentName} to trust this deployment'
                values={{
                  environmentName: trustRelationshipFromState.name,
                }}
              />
            </h3>
          </EuiTitle>
          <EuiSpacer size='m' />
          <EuiText>
            <FormattedMessage
              id='directTrustRelationshipInstructions.step3.body'
              defaultMessage='For the trust relationship to be fully functional, it must be bi-lateral. Use the {caCertificate} and {restrictionSettings} you just downloaded to configure your {environmentCluster} to trust this deployment.'
              values={{
                caCertificate: this.renderCaCertificateHelpText(),
                restrictionSettings: this.renderRestrictionSettingsHelpText(),
                environmentCluster: this.envCluster(),
              }}
            />
          </EuiText>
          {isEss && getTargetEnvironmentType(trustRelationshipUnderEdit) === 'ece' && (
            <Fragment>
              <EuiSpacer size='m' />
              <EuiText color='subdued' size='xs'>
                <FormattedMessage
                  id='directTrustRelationshipInstructions.note.body'
                  defaultMessage='When configuring trust from an ECE environment to an Elasticsearch Service environment,
                  provide the Organization ID as environment ID. You can find the ID on the Organization page
                  of the environment you want to trust, next to the name of the organization.'
                />
              </EuiText>
            </Fragment>
          )}

          <EuiSpacer size='xl' />

          <EuiFlexGroup direction='column' alignItems='center'>
            <EuiFlexItem>
              <EuiButton fill={true} href={securityUrl(deployment.id)}>
                <FormattedMessage
                  id='directTrustRelationshipInstructions.done'
                  defaultMessage='Done'
                />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexGroup>
    )
  }

  renderCertButton = (): JSX.Element | null => {
    const [cert] = this.props.certificateAuthority?.public_certificates || []

    const text = (
      <FormattedMessage
        id='directTrustRelationshipInstructions.downloadCertButton'
        defaultMessage='Download CA certificate'
      />
    )

    if (!cert) {
      return <EuiButton isLoading={true}>{text}</EuiButton>
    }

    const blob = new window.Blob([cert.pem || ``], {
      type: `application/x-x509-ca-cert;charset=utf-8`,
    })
    const fileName = `${cert.metadata.fingerprint}.crt`
    const contents = window.URL.createObjectURL(blob)

    return (
      <EuiButton download={fileName} href={contents} iconType='download'>
        {text}
      </EuiButton>
    )
  }

  renderSettingsBox = (): JSX.Element => {
    const { trustRelationshipFromState, certificateAuthority } = this.props

    const yaml = getDirectTrustRelationshipTrustRestrictionSettings(
      trustRelationshipFromState,
      certificateAuthority,
    )

    const blob = new window.Blob([yaml], {
      type: `application/yaml;charset=utf-8`,
    })
    const fileName = `trust.yml`
    const contents = window.URL.createObjectURL(blob)

    return (
      <Fragment>
        <EuiCodeBlock isCopyable={true} paddingSize='m' language='yaml' whiteSpace='pre'>
          {yaml}
        </EuiCodeBlock>
        <EuiSpacer size='m' />
        <EuiButton download={fileName} href={contents} iconType='download'>
          <FormattedMessage
            id='directTrustRelationshipInstructions.downloadYaml'
            defaultMessage='Download trust.yml'
          />
        </EuiButton>
      </Fragment>
    )
  }

  renderCaCertificateHelpText(): JSX.Element {
    const { caCertificatePopoverOpen } = this.state

    return (
      <CustomTextPopover
        buttonText={
          <FormattedMessage id='use-ca-certificate.text' defaultMessage='CA certificate' />
        }
        onClick={() => this.onClickCaCertificate()}
        isOpen={caCertificatePopoverOpen}
      >
        <EuiFormHelpText>
          <FormattedMessage
            id='trust-new-ca.description'
            defaultMessage='Update the security certificates of your {environmentCluster} with this CA. {learnMore}'
            values={{
              learnMore: (
                <DocLink link='trustNewCaDocLink'>
                  <FormattedMessage id='trust-new-ca.learn-more' defaultMessage='Learn more' />
                </DocLink>
              ),
              environmentCluster: this.envCluster(),
            }}
          />
        </EuiFormHelpText>
      </CustomTextPopover>
    )
  }

  renderRestrictionSettingsHelpText(): JSX.Element {
    const { restrictionSettingsPopoverOpen } = this.state

    return (
      <CustomTextPopover
        buttonText={
          <FormattedMessage
            id='restriction-settings.text'
            defaultMessage='trust restriction settings'
          />
        }
        onClick={() => this.onClickRestrictionSettings()}
        isOpen={restrictionSettingsPopoverOpen}
      >
        <EuiFormHelpText>
          <FormattedMessage
            id='restriction-settings.description'
            defaultMessage='Update the value of {setting} in {configFile} to reflect the downloaded trust restriction settings.'
            values={{
              setting: <EuiCode>xpack.security.transport.ssl.trust_restrictions.path</EuiCode>,
              configFile: <EuiCode>elasticsearch.yml</EuiCode>,
            }}
          />
        </EuiFormHelpText>
      </CustomTextPopover>
    )
  }

  onClickCaCertificate() {
    this.setState({ caCertificatePopoverOpen: !this.state.caCertificatePopoverOpen })
  }

  onClickRestrictionSettings() {
    this.setState({ restrictionSettingsPopoverOpen: !this.state.restrictionSettingsPopoverOpen })
  }

  envCluster(): string {
    const { trustRelationshipUnderEdit } = this.props

    switch (getTargetEnvironmentType(trustRelationshipUnderEdit)) {
      case 'ess':
        return 'Elasticsearch Service deployment'
      case 'ece':
        return 'ECE environment'
      default:
        return 'self-managed Elasticsearch cluster'
    }
  }

  envName(): string {
    const { trustRelationshipUnderEdit } = this.props

    switch (getTargetEnvironmentType(trustRelationshipUnderEdit)) {
      case 'ess':
        return 'Elasticsearch Service organization'
      case 'ece':
        return 'ECE environment'
      default:
        return 'self-managed environment'
    }
  }
}

export default DirectTrustRelationshipInstructions
