/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

// The component _must_ be imported before the
// 'brace/*' imports otherwise the `ace` global
// is not available and the app errors
// eslint-disable-next-line import/order
import LegacyEuiCodeEditor from './LegacyEuiCodeEditor'

import 'brace/theme/chrome'
import 'brace/theme/tomorrow_night'
import 'brace/mode/json'
import 'brace/mode/yaml'
import 'brace/ext/searchbox'

import type { WithEuiThemeProps } from '@elastic/eui'
import { withEuiTheme } from '@elastic/eui'

import type { FunctionComponent } from 'react'

const themes = {
  light: `chrome`,
  dark: `tomorrow_night`,
}

type Language = 'json' | 'text' | 'yaml'

interface Props extends WithEuiThemeProps {
  disabled?: boolean
  isReadOnly?: boolean
  height?: string
  language?: Language
  value?: string
  onChange?: (value: string) => void
  onEditor?: (editor: any) => void
}

const CodeEditor: FunctionComponent<Props> = ({
  theme: euiTheme,
  language,
  value,
  onChange,
  onEditor,
  ...rest
}) => {
  const theme = themes[euiTheme.colorMode.toLowerCase() || 'light']

  return (
    <LegacyEuiCodeEditor
      ref={handleRef}
      theme={theme}
      width='100%'
      mode={language}
      value={value}
      onChange={onChange}
      {...rest}
    />
  )

  function handleRef(el) {
    if (!el) {
      return
    }

    const { editor } = el.aceEditor

    onEditorDefaults(editor)

    if (onEditor) {
      onEditor(editor)
    }
  }
}

function onEditorDefaults(editor) {
  editor.setShowPrintMargin(false)
  editor.commands.removeCommands([`gotoline`])
}

export const CuiCodeEditor = withEuiTheme(CodeEditor)
