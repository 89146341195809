/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type { CloudAppName, ProfileState, Theme, RoutableBreadcrumb } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { getUsername } from '@/lib/profile'

import connectAndForwardRef from '../../lib/connectAndForwardRef'
import { setTheme } from '../../actions/theme'
import { getProfile } from '../../apps/userconsole/reducers'
import { getTheme, getStackDeployment, getCurrentUserUsername } from '../../reducers'
import { isFeatureActivated, getConfigForKey } from '../../selectors'
import { hasUserSettings } from '../UserSettings/helpers'
import { SAD_hasUnexpiredSession } from '../../lib/auth'
import { shouldShowOrganization } from '../Organization/lib'

import ChromeHeader from './ChromeHeader'

import type { ReduxState } from '@/types/redux'
import type { RefObject } from 'react'

type StateProps = {
  loggedIn: boolean
  hasUserSettings: boolean
  theme: Theme
  appName: CloudAppName
  profile: ProfileState
  getStackDeploymentById: (id: string) => DeploymentGetResponse | null
  cloudPortalEnabled: boolean
  isHeroku: boolean
  username: string | null
  showOrganization: boolean
}

type DispatchProps = {
  setTheme: (theme: Theme) => void
}

type ConsumerProps = {
  breadcrumbs?: RoutableBreadcrumb[]
  hideTrialIndicator?: boolean
  showHelp?: boolean
  ref: RefObject<HTMLDivElement> | null
}

const mapStateToProps = (state: ReduxState): StateProps => {
  const appName = getConfigForKey(state, `APP_NAME`)!
  const isAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`
  const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`
  const profile = getProfile(state)

  return {
    loggedIn: SAD_hasUnexpiredSession(),
    cloudPortalEnabled: isFeatureActivated(state, Feature.cloudPortalEnabled),
    hasUserSettings: hasUserSettings(state),
    theme: getTheme(state),
    appName,
    profile,
    getStackDeploymentById: (id) => getStackDeployment(state, id),
    isHeroku: getConfigForKey(state, `APP_FAMILY`) === `heroku`,
    username: isAdminconsole ? getCurrentUserUsername(state) : getUsername({ isHeroku, profile }),
    showOrganization: shouldShowOrganization(profile),
  }
}

const mapDispatchToProps: DispatchProps = {
  setTheme,
}

export default connectAndForwardRef<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    // Because we rely on `context` within ChromeHeader (via <Search />), we can't treat the
    // component as pure.
    pure: false,
  },
)(ChromeHeader)
