/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { capitalize } from 'lodash'

import {
  EuiRadio,
  EuiButton,
  EuiToolTip,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiFormHelpText,
} from '@elastic/eui'

import type { AsyncRequestState, SliderInstanceType, StackDeployment } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import { getFirstSliderClusterFromGet } from '@/lib/stackDeployments/selectors'
import SpinButton from '@/components/SpinButton'
import { isSliderEnabledInStackDeployment } from '@/lib/sliders'

import ZipInspectorModal from '../../ZipInspectorModal'

import type { ReactElement } from 'react'

type State = {
  selectedResourceKind: SliderInstanceType
  inspecting: boolean
}

type Props = {
  deployment: StackDeployment
  getClusterDiagnosticBundle: (resourceKind: SliderInstanceType) => any
  downloadClusterDiagnosticBundle: (resourceKind: SliderInstanceType, refId: string) => Promise<any>
  downloadClusterDiagnosticBundleRequest: (resourceKind: SliderInstanceType) => AsyncRequestState
  resetElasticsearchDiagnosticBundleRequest: () => void
  resetKibanaDiagnosticBundleRequest: () => void
}

class ClusterDiagnosticBundle extends Component<Props, State> {
  state = {
    selectedResourceKind: 'elasticsearch',
    inspecting: false,
  }

  componentWillUnmount(): void {
    this.resetDownloadRequest()
  }

  render(): ReactElement {
    const { deployment, downloadClusterDiagnosticBundleRequest, getClusterDiagnosticBundle } =
      this.props

    const { selectedResourceKind, inspecting } = this.state

    const { error } = downloadClusterDiagnosticBundleRequest(selectedResourceKind)

    const isKibanaEnabled = isSliderEnabledInStackDeployment(deployment, `kibana`)

    const kibanaMessage = (
      <FormattedMessage id='cluster-diagnostic-bundle.kibana' defaultMessage='Kibana' />
    )

    const kibanaLabel = isKibanaEnabled ? (
      kibanaMessage
    ) : (
      <EuiToolTip
        content={
          <FormattedMessage
            id='cluster-diagnostic-bundle.kibana-is-not-available'
            defaultMessage='Kibana is not available on this deployment'
          />
        }
      >
        {kibanaMessage}
      </EuiToolTip>
    )

    return (
      <div>
        <EuiFormRow
          label={
            <FormattedMessage
              id='cluster-diagnostic-bundle.title'
              defaultMessage='Diagnostic bundle'
            />
          }
        >
          <EuiFlexGroup gutterSize='m'>
            <EuiFlexItem grow={false}>
              <EuiRadio
                id='elasticsearch'
                name='elasticsearch'
                onChange={() => {
                  this.resetDownloadRequest()
                  this.setState({ selectedResourceKind: `elasticsearch` })
                }}
                checked={selectedResourceKind === `elasticsearch`}
                label={
                  <FormattedMessage
                    id='cluster-diagnostic-bundle.elasticsearch'
                    defaultMessage='Elasticsearch'
                  />
                }
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiRadio
                id='kibana'
                name='kibana'
                onChange={() => {
                  this.resetDownloadRequest()
                  this.setState({ selectedResourceKind: `kibana` })
                }}
                disabled={!isKibanaEnabled}
                checked={selectedResourceKind === `kibana`}
                label={kibanaLabel}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiSpacer size='m' />

        <EuiFlexGroup gutterSize='s'>{this.renderActions()}</EuiFlexGroup>

        {error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{error}</CuiAlert>
          </Fragment>
        )}

        {inspecting && (
          <ZipInspectorModal
            title={
              <FormattedMessage
                id='cluster-diagnostic-bundle.inspector-title'
                defaultMessage='{kind} diagnostic bundle'
                values={{ kind: capitalize(selectedResourceKind) }}
              />
            }
            close={this.stopInspecting}
            zip={getClusterDiagnosticBundle(selectedResourceKind)}
            filename={this.getFilename()}
          />
        )}
      </div>
    )
  }

  renderActions(): ReactElement {
    const { downloadClusterDiagnosticBundleRequest, getClusterDiagnosticBundle } = this.props
    const { selectedResourceKind } = this.state

    const { isDone } = downloadClusterDiagnosticBundleRequest(selectedResourceKind)
    const clusterDiagnosticBundle = getClusterDiagnosticBundle(selectedResourceKind)

    if (isDone && clusterDiagnosticBundle) {
      return this.renderDownloadButton(clusterDiagnosticBundle)
    }

    return this.renderGenerateButton()
  }

  renderDownloadButton(clusterDiagnosticBundle): ReactElement {
    const { selectedResourceKind } = this.state
    const filename = this.getFilename()

    const downloadButton = (
      <EuiButton size='s' href={clusterDiagnosticBundle.blobUrl} download={filename}>
        <FormattedMessage
          id='cluster-diagnostic-bundle.download-x-bundle'
          defaultMessage='Download {kind} bundle'
          values={{ kind: capitalize(selectedResourceKind) }}
        />
      </EuiButton>
    )

    return (
      <Fragment>
        <EuiFlexItem grow={false}>{downloadButton}</EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButtonEmpty size='s' iconType='eye' onClick={this.inspect}>
            <FormattedMessage id='cluster-diagnostic-bundle.inspect' defaultMessage='Inspect' />
          </EuiButtonEmpty>
        </EuiFlexItem>
      </Fragment>
    )
  }

  renderGenerateButton(): ReactElement {
    const { deployment, downloadClusterDiagnosticBundle, downloadClusterDiagnosticBundleRequest } =
      this.props
    const { selectedResourceKind } = this.state
    const { isDone, inProgress } = downloadClusterDiagnosticBundleRequest(selectedResourceKind)

    const firstCluster = getFirstSliderClusterFromGet({
      deployment,
      sliderInstanceType: selectedResourceKind,
    })

    if (!firstCluster) {
      return (
        <EuiFormHelpText>
          <FormattedMessage
            id='cluster-diagnostic-bundle.no-cluster-available'
            defaultMessage="The diagnostic bundle can't be generated because there are no available {kind} clusters."
            values={{ kind: capitalize(selectedResourceKind) }}
          />
        </EuiFormHelpText>
      )
    }

    const { ref_id: refId } = firstCluster

    return (
      <EuiFlexItem grow={false}>
        <SpinButton
          color='primary'
          size='s'
          onClick={() => downloadClusterDiagnosticBundle(selectedResourceKind, refId)}
          disabled={isDone}
          spin={inProgress}
        >
          {inProgress ? (
            <FormattedMessage
              id='cluster-diagnostic-bundle.generating-x-bundle'
              defaultMessage='Generating {kind} bundle'
              values={{ kind: capitalize(selectedResourceKind) }}
            />
          ) : (
            <FormattedMessage
              id='cluster-diagnostic-bundle.generate-x-bundle'
              defaultMessage='Generate {kind} bundle'
              values={{ kind: capitalize(selectedResourceKind) }}
            />
          )}
        </SpinButton>
      </EuiFlexItem>
    )
  }

  getFilename(): string {
    const { deployment } = this.props

    return `diagnostic-${deployment.id.slice(0, 6)}-${moment().format(`YYYY-MMM-DD--HH_mm_ss`)}.zip`
  }

  resetDownloadRequest = (): void => {
    const { resetElasticsearchDiagnosticBundleRequest, resetKibanaDiagnosticBundleRequest } =
      this.props

    resetElasticsearchDiagnosticBundleRequest()
    resetKibanaDiagnosticBundleRequest()
  }

  inspect = (): void => {
    this.setState({ inspecting: true })
  }

  stopInspecting = (): void => {
    this.setState({ inspecting: false })
  }
}

export default ClusterDiagnosticBundle
