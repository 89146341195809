/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { isEmpty, size, sortBy, uniqBy } from 'lodash'

import {
  EuiBadge,
  EuiCode,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiIcon,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type {
  InstanceConfiguration,
  DeploymentTemplateInfoV2,
  ElasticsearchClusterTopologyElement,
} from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, RegionId } from '@modules/ui-types'
import { CuiAlert, CuiRouterLinkButton } from '@modules/cui'
import Header from '@modules/cui/Header'

import { getFirstEsCluster } from '@/lib/stackDeployments/selectors'
import { getDataTopologies } from '@/lib/deploymentTemplates/getTopologiesFromTemplate'

import NodeAttributeTags from '../../IndexLifecycleManagement/NodeAttributeTags'
import DangerButton from '../../../DangerButton'
import { getIndexHalflifeFromSeconds } from '../../../../lib/curation'
import { topologyEditDeploymentTemplateUrl } from '../../../../lib/urlBuilder'
import { topologyDeploymentTemplateCrumbs } from '../../../../lib/crumbBuilder'
import { hasMaxZones } from '../../../../lib/instanceConfigurations/instanceConfiguration'
import DeploymentTemplateInfrastructure from '../components/DeploymentInfrastructure/DeploymentTemplateInfrastructure'
import DeploymentTemplateArchitectureViz from '../DeploymentTemplateArchitectureViz'

import MonitoringDeployment from './MonitoringDeployment'

import type { IntlShape } from 'react-intl'

import './deploymentTemplateView.scss'

export type Props = {
  deleteDeploymentTemplate: () => void
  deleteDeploymentTemplateRequest: AsyncRequestState
  fetchDeploymentTemplate: () => void
  fetchDeploymentTemplateRequest: AsyncRequestState
  fetchInstanceConfigurations: () => void
  instanceConfigurations?: InstanceConfiguration[] | null
  intl: IntlShape
  regionId: RegionId
  resetDeleteDeploymentTemplateRequest: () => void
  template?: DeploymentTemplateInfoV2 | null
  templateId: string
}

const messages = defineMessages({
  durationIn_hours: {
    id: `deployment-template-view.index-curation-settings.duration-in-hours`,
    defaultMessage: `{amount} {amount, plural, one {hour} other {hours}}`,
  },
  durationIn_days: {
    id: `deployment-template-view.index-curation-settings.duration-in-days`,
    defaultMessage: `{amount} {amount, plural, one {day} other {days}}`,
  },
  durationIn_weeks: {
    id: `deployment-template-view.index-curation-settings.duration-in-weeks`,
    defaultMessage: `{amount} {amount, plural, one {week} other {weeks}}`,
  },
  durationIn_months: {
    id: `deployment-template-view.index-curation-settings.duration-in-months`,
    defaultMessage: `{amount} {amount, plural, one {month} other {months}}`,
  },
})

function getTierSortKey(topologyElement: ElasticsearchClusterTopologyElement): number {
  const tiersInOrder = ['hot_content', 'hot', 'content', 'warm', 'cold', 'frozen']
  const index = tiersInOrder.findIndex((tier) => tier === topologyElement.id)
  return (index === -1 ? tiersInOrder.length : index) + 1 // prevent zero falsiness and defensively put any unknown tier at the end
}

class ViewDeploymentTemplate extends Component<Props> {
  componentDidMount() {
    const {
      fetchDeploymentTemplate,
      fetchInstanceConfigurations,
      instanceConfigurations,
      template,
    } = this.props

    if (template == null) {
      fetchDeploymentTemplate()
    }

    // We may have already fetched instance configs, but we require them to be
    // requested with the show_max_zones to include an the max_zones field.
    if (instanceConfigurations == null || !hasMaxZones(instanceConfigurations)) {
      fetchInstanceConfigurations()
    }
  }

  componentWillUnmount() {
    const { resetDeleteDeploymentTemplateRequest } = this.props

    resetDeleteDeploymentTemplateRequest()
  }

  render() {
    return (
      <Fragment>
        {this.renderTitle()}

        {this.renderContent()}
      </Fragment>
    )
  }

  renderTitle() {
    const { regionId, templateId, template } = this.props

    if (!template) {
      return (
        <Header
          breadcrumbs={topologyDeploymentTemplateCrumbs({ regionId, templateId })}
          name={
            <FormattedMessage
              id='deployment-template-view.title-placeholder'
              defaultMessage='Deployment template'
            />
          }
        />
      )
    }

    const { name } = template

    return (
      <Header breadcrumbs={topologyDeploymentTemplateCrumbs({ regionId, templateId })} name={name}>
        {this.renderHeaderActions()}
      </Header>
    )
  }

  renderHeaderActions() {
    const {
      regionId,
      templateId,
      template,
      deleteDeploymentTemplate,
      deleteDeploymentTemplateRequest,
    } = this.props

    if (!template) {
      return null
    }

    const { name, system_owned } = template

    if (system_owned) {
      // You can't edit or delete a system default template.
      return null
    }

    return (
      <Fragment>
        <EuiFlexGroup justifyContent='flexEnd' alignItems='center' gutterSize='s'>
          <EuiFlexItem grow={false}>
            <DangerButton
              data-test-id='delete-deployment-template-btn'
              onConfirm={deleteDeploymentTemplate}
              isEmpty={true}
              modal={{
                body: (
                  <FormattedMessage
                    id='deployment-template-view.delete-modal.body'
                    defaultMessage='This will not affect any clusters created using this template, or any instance configurations used by the template.'
                  />
                ),
                confirmButtonText: (
                  <FormattedMessage
                    id='deployment-template-view.delete-modal.confirm'
                    defaultMessage='Delete deployment template'
                  />
                ),
                title: (
                  <FormattedMessage
                    id='deployment-template-view.delete-modal.title'
                    defaultMessage='Delete "{name}"?'
                    values={{
                      name,
                    }}
                  />
                ),
              }}
              isBusy={deleteDeploymentTemplateRequest.inProgress}
            >
              <FormattedMessage id='deployment-template-view.delete' defaultMessage='Delete' />
            </DangerButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <CuiRouterLinkButton
              data-test-id='edit-deployment-template-btn'
              to={topologyEditDeploymentTemplateUrl(regionId, templateId)}
            >
              <FormattedMessage
                id='deployment-template-view.edit-template'
                defaultMessage='Edit template'
              />
            </CuiRouterLinkButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        {deleteDeploymentTemplateRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{deleteDeploymentTemplateRequest.error}</CuiAlert>
          </Fragment>
        )}

        <EuiSpacer size='s' />
      </Fragment>
    )
  }

  renderContent() {
    const { template, fetchDeploymentTemplateRequest, instanceConfigurations } = this.props

    if (fetchDeploymentTemplateRequest.error) {
      return (
        <div data-test-id='fetch-template-error'>
          <CuiAlert type='error'>{fetchDeploymentTemplateRequest.error}</CuiAlert>
        </div>
      )
    }

    if (template == null || instanceConfigurations == null) {
      return (
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <div data-test-id='spinner'>
              <EuiLoadingSpinner size='l' />
            </div>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <FormattedMessage id='deployment-template-view.loading' defaultMessage='Loading …' />
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    return this.renderOverview()
  }

  renderOverview() {
    const { template, instanceConfigurations } = this.props

    if (!template?.deployment_template) {
      return null
    }

    if (!instanceConfigurations) {
      return null
    }

    return (
      <Fragment>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle>
              <h3>
                <FormattedMessage
                  id='deployment-template-view.instances-title'
                  defaultMessage='Instances'
                />
              </h3>
            </EuiTitle>

            <EuiSpacer size='m' />

            <DeploymentTemplateInfrastructure
              deployment={template.deployment_template}
              instanceConfigurations={instanceConfigurations}
              updateDeploymentTemplate={undefined}
            />

            {this.renderIndexCuration()}
            {this.renderStackFeatures()}
          </EuiFlexItem>

          <DeploymentTemplateArchitectureViz
            template={template}
            instanceConfigurations={instanceConfigurations}
            render={(className, content) => (
              <EuiFlexItem grow={false} className={className}>
                {content}
              </EuiFlexItem>
            )}
          />
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderStackFeatures() {
    const deploymentTemplate = this.props.template?.deployment_template

    if (!deploymentTemplate) {
      return // sanity
    }

    const esResource = getFirstEsCluster({ deployment: deploymentTemplate })

    const observability = deploymentTemplate.settings?.observability
    const snapshotRepositoryId =
      esResource?.settings?.snapshot?.repository?.reference?.repository_name

    if (!snapshotRepositoryId && !observability) {
      return
    }

    const { regionId } = this.props

    return (
      <Fragment>
        <div className='deploymentTemplate-stackFeatures'>
          <EuiSpacer size='xl' />
          <EuiTitle>
            <h3>
              <FormattedMessage
                id='deployment-template-view.stack-features-title'
                defaultMessage='Stack features'
              />
            </h3>
          </EuiTitle>
          {snapshotRepositoryId && (
            <Fragment>
              <EuiSpacer />
              <EuiTitle size='s'>
                <h4>
                  <FormattedMessage
                    id='deployment-template-view.stack-features.snapshots-title'
                    defaultMessage='Snapshots'
                  />
                </h4>
              </EuiTitle>
              <EuiSpacer size='s' />
              <EuiFormLabel>
                <FormattedMessage
                  id='deployment-template-view.stack-features.snapshots-repository'
                  defaultMessage='Snapshot repository'
                />
              </EuiFormLabel>
              <EuiText>{snapshotRepositoryId}</EuiText>
            </Fragment>
          )}
          {observability && (
            <MonitoringDeployment regionId={regionId} observability={observability} />
          )}
        </div>
      </Fragment>
    )
  }

  renderIndexCuration() {
    const {
      intl: { formatMessage },
    } = this.props
    const deploymentTemplate = this.props.template?.deployment_template

    if (!deploymentTemplate) {
      return // sanity
    }

    const esResource = getFirstEsCluster({ deployment: deploymentTemplate })

    if (!esResource) {
      return // sanity
    }

    const indexPatterns = esResource.settings?.curation?.specs || []

    const dataTopologyElements = getDataTopologies({ deployment: deploymentTemplate })

    if (uniqBy(dataTopologyElements, `id`).length < 2) {
      return null
    }

    const nodeAttributesExist =
      dataTopologyElements &&
      dataTopologyElements.some((node) => size(node.elasticsearch?.node_attributes) > 0)

    return (
      <Fragment>
        <div className='deploymentTemplate-indexManagement'>
          <EuiTitle>
            <h3>
              <FormattedMessage
                id='deployment-template-view.index-management-title'
                defaultMessage='Index Management'
              />
            </h3>
          </EuiTitle>
          <EuiText size='s' color='subdued'>
            <FormattedMessage
              id='deployment-template-view.index-management-text'
              defaultMessage='Manage when your indices will move from hot configurations to warm ones.'
            />
          </EuiText>
        </div>
        {nodeAttributesExist && (
          <Fragment>
            <EuiTitle className='deploymentTemplate-subTitle' size='xxs'>
              <h5>
                <FormattedMessage
                  id='deployment-template-view.ilm-title'
                  defaultMessage='Index Lifecycle Management'
                />
                <EuiBadge color='hollow' className='deploymentTemplate-versionBadge'>
                  <FormattedMessage
                    id='deployment-template-view.ilm-version'
                    defaultMessage='v6.7 and later'
                  />
                </EuiBadge>
              </h5>
            </EuiTitle>
            <EuiSpacer />
            {dataTopologyElements && (
              <EuiFlexGroup>
                {sortBy(dataTopologyElements, getTierSortKey).map(
                  (instance, index) =>
                    !isEmpty(instance.elasticsearch?.node_attributes) && (
                      <EuiFlexItem key={index} grow={false}>
                        <EuiFlexGroup gutterSize='xs'>
                          <EuiFlexItem grow={5}>
                            <EuiTitle size='xxs'>
                              <h6 className='deploymentTemplate-attributeTitle'>
                                <EuiIcon
                                  type={`logoElasticsearch`}
                                  size='m'
                                  className='deploymentTemplate-esLogo'
                                />
                                {instance.instance_configuration_id}
                              </h6>
                            </EuiTitle>
                            <EuiSpacer size='xs' />
                            <NodeAttributeTags index={index} disabled={true} instance={instance} />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    ),
                )}
              </EuiFlexGroup>
            )}
          </Fragment>
        )}
        {indexPatterns.length > 0 && (
          <Fragment>
            <EuiTitle className='deploymentTemplate-subTitle' size='xxs'>
              <h5>
                <FormattedMessage
                  id='deployment-template-view.index-curation-title'
                  defaultMessage='Index Curation'
                />
              </h5>
            </EuiTitle>

            <ul className='deploymentTemplate-indexPatterns'>
              {indexPatterns.map((pattern, index) => {
                const { index_pattern, trigger_interval_seconds } = pattern

                const { amount, type } = getIndexHalflifeFromSeconds(trigger_interval_seconds)

                const durationMessageId = `durationIn_${type}`

                return (
                  <li key={index}>
                    <FormattedMessage
                      id='deployment-template-view.index-curation-pattern'
                      defaultMessage='Indices matching { pattern } will be moved from { source } to { destination } after { duration }.'
                      values={{
                        pattern: <EuiCode>{index_pattern}</EuiCode>,
                        duration: formatMessage(messages[durationMessageId], { amount }),
                        source: (
                          <EuiCode>
                            {esResource.plan.elasticsearch.curation?.from_instance_configuration_id}
                          </EuiCode>
                        ),
                        destination: (
                          <EuiCode>
                            {esResource.plan.elasticsearch.curation?.to_instance_configuration_id}
                          </EuiCode>
                        ),
                      }}
                    />
                  </li>
                )
              })}
            </ul>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default injectIntl(ViewDeploymentTemplate)
