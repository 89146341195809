/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { Runner } from '@modules/ui-types'
import { CuiLink } from '@modules/cui'

import { hostUrl } from '../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'

interface Props {
  runner: Runner
}

const RunnerName: FunctionComponent<Props> = ({ runner: { regionId, runner_id } }) => (
  <CuiLink to={hostUrl(regionId, runner_id)} data-test-id={`runner-link-${runner_id}`}>
    {runner_id}
  </CuiLink>
)

export default RunnerName
