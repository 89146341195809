/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiButtonIcon, EuiCallOut, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { DeploymentTemplateInfoV2, DeploymentUpdateRequest } from '@modules/cloud-api/v1/types'
import type { StackDeployment } from '@modules/ui-types'

import EnableAutoscalingModal from '@/components/Autoscaling/EnableAutoscalingModal'
import { getEnableAutoscalingLsKey } from '@/constants/localStorageKeys'

import DocLink from '../../DocLink'

import type { WrappedComponentProps } from 'react-intl'

import '../autoscalingCallout.scss'

interface Props extends WrappedComponentProps {
  stackDeployment: StackDeployment
  deploymentTemplate?: DeploymentTemplateInfoV2
  updateDeployment: (settings: {
    regionId: string
    deploymentId: string
    deployment: DeploymentUpdateRequest
  }) => Promise<unknown>
}

type State = {
  isDismissed: boolean
}

const messages = defineMessages({
  dismiss: {
    id: 'autoscaling-available-callout.dismiss',
    defaultMessage: 'Dismiss',
  },
})

class AutoscalingAvailableCallout extends Component<Props, State> {
  state: State = {
    isDismissed: this.isCalloutDismissed(),
  }

  render() {
    const {
      intl: { formatMessage },
      stackDeployment,
      deploymentTemplate,
    } = this.props
    const { isDismissed } = this.state

    if (isDismissed) {
      return null
    }

    return (
      <Fragment>
        <EuiCallOut
          data-test-id='autoscalingAvailable-callout'
          className='autoscaling-callout'
          title={
            <EuiFlexGroup responsive={false} component='span'>
              <EuiFlexItem component='span'>
                <FormattedMessage
                  id='autoscaling-available-callout.title'
                  defaultMessage='Autoscaling is available'
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false} component='span'>
                <EuiButtonIcon
                  onClick={this.dismissCookie}
                  iconType='cross'
                  aria-label={formatMessage(messages.dismiss)}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        >
          <Fragment>
            <p>
              <FormattedMessage
                id='autoscaling-available-callout.description'
                defaultMessage='You can now enable autoscaling on your deployment. When the deployment approaches the disk allocation threshold, storage capacity will scale up automatically. Additionally, for machine learning nodes RAM can scale both up and down. {learnMore}'
                values={{
                  learnMore: (
                    <DocLink link='autoscalingEnableDocLink'>
                      <FormattedMessage
                        id='autoscaling-available-callout.learn-more'
                        defaultMessage='Learn more'
                      />
                    </DocLink>
                  ),
                }}
              />
            </p>
            <EnableAutoscalingModal
              deployment={stackDeployment}
              deploymentTemplate={deploymentTemplate}
              onEnableAutoscaling={this.dismissCookie}
            />
          </Fragment>
        </EuiCallOut>
      </Fragment>
    )
  }

  dismissCookie = () => {
    const { stackDeployment } = this.props
    this.setState({ isDismissed: true })
    localStorage.setItem(getEnableAutoscalingLsKey({ deploymentId: stackDeployment!.id }), `true`)
  }

  isCalloutDismissed(): boolean {
    const { stackDeployment } = this.props

    if (
      localStorage.getItem(getEnableAutoscalingLsKey({ deploymentId: stackDeployment!.id })) ===
      `true`
    ) {
      return true
    }

    return false
  }
}

export default injectIntl(AutoscalingAvailableCallout)
