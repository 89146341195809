/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'

import { fetchCurrentAccount, resetFetchCurrentAccount } from '../../../../actions/account'
import { getCurrentAccount } from '../../../../reducers'

import DeploymentTrustManagement from './DeploymentTrustManagement'

import type { StateProps, DispatchProps } from './types'

const mapStateToProps = (state: ReduxState): StateProps => ({
  currentAccount: getCurrentAccount(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchCurrentAccount: () => dispatch(fetchCurrentAccount()),
  resetFetchCurrentAccount: () => dispatch(resetFetchCurrentAccount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentTrustManagement)
