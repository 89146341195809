/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import {
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFormRow,
  EuiSpacer,
  htmlIdGenerator,
} from '@elastic/eui'

import { CuiHelpTipIcon } from '@modules/cui'
import { CuiFormArray, CuiFormField, CuiFormRadioGroup } from '@modules/cui/forms'

import DocLink from '../../../../../../../components/DocLink'
import {
  helpText,
  labels,
  loadBalancing,
  messages,
  sections,
  validation,
} from '../../authProviderMessages'
import { convertNameToRealmId } from '../../convertNameToRealmId'

import type { FieldProps } from 'formik'
import type { FunctionComponent } from 'react'

const idPrefix = htmlIdGenerator()()

const loadBalancingOptions = [
  {
    id: `${idPrefix}_failover`,
    value: 'failover',
    label: <FormattedMessage {...loadBalancing.failover} />,
  },
  {
    id: `${idPrefix}_dns_failover`,
    value: 'dns_failover',
    label: <FormattedMessage {...loadBalancing.dnsFailover} />,
  },
  {
    id: `${idPrefix}_round_robin`,
    value: 'round_robin',
    label: <FormattedMessage {...loadBalancing.roundRobin} />,
  },
  {
    id: `${idPrefix}_dns_round_robin`,
    value: 'dns_round_robin',
    label: <FormattedMessage {...loadBalancing.dnsRoundRobin} />,
  },
]

interface Props {
  // Rather than putting Active DirectoryProviderFormShape here, specify just the properties we
  // care about, and an Active DirectoryProviderFormShape will satisfy this shape.
  // This makes writing unit tests easier.
  values: {
    urls: string[]
    load_balancing: 'failover' | 'dns_failover' | 'round_robin' | 'dns_round_robin'
  }
}

const GeneralSettings: FunctionComponent<Props> = ({ values }) => (
  <EuiDescribedFormGroup
    title={
      <h3>
        <FormattedMessage {...sections.generalActiveDirectorySettings} />
      </h3>
    }
    description={
      <FormattedMessage
        {...sections.generalActiveDirectorySettingsDescription}
        values={{
          learnMore: (
            <DocLink link='activeDirectoryProviderGeneralSettingsDocLink'>
              <FormattedMessage {...messages.learnMore} />
            </DocLink>
          ),
        }}
      />
    }
  >
    <Field name='name'>
      {({
        field,
        form: {
          values: { form_mode },
          errors,
          touched,
          setFieldValue,
        },
      }: FieldProps) => {
        const error = touched[field.name] && errors[field.name]

        return (
          <EuiFormRow
            label={<FormattedMessage {...labels.profileName} />}
            helpText={<FormattedMessage {...labels.profileNameHelpText} />}
            isInvalid={error != null}
            error={error}
          >
            <EuiFieldText
              {...field}
              onChange={(e) => {
                field.onChange(e)

                if (form_mode === 'create') {
                  setFieldValue('id', convertNameToRealmId(e.target.value))
                }
              }}
            />
          </EuiFormRow>
        )
      }}
    </Field>

    <CuiFormField label={<FormattedMessage {...labels.realmId} />} name='id' isDisabled={true} />

    <CuiFormArray
      name='urls'
      initialValue=''
      data-test-id='active-directory-urls-array'
      placeholder='ldap://server:port'
      label={
        <Fragment>
          <FormattedMessage {...labels.activeDirectoryServers} />

          <CuiHelpTipIcon>
            <FormattedMessage
              {...helpText.activeDirectoryUrls}
              values={{
                example: <code>ldap[s]://&lt;server&gt;:&lt;port&gt;</code>,
                ldap: <code>ldap</code>,
                ldaps: <code>ldaps</code>,
              }}
            />
          </CuiHelpTipIcon>
        </Fragment>
      }
      addMessage={<FormattedMessage {...labels.addActiveDirectoryServer} />}
      addDisabled={
        values.load_balancing === 'dns_failover' || values.load_balancing === 'dns_round_robin'
      }
      addDisabledMessage={<FormattedMessage {...validation.singleUrlWithDnsLoadBalancing} />}
    />

    <EuiSpacer />

    <CuiFormRadioGroup
      label={<FormattedMessage {...labels.loadBalancing} />}
      name='load_balancing'
      helpText={
        values.urls.length === 1 ? null : (
          <FormattedMessage {...validation.singleUrlWithDnsLoadBalancing} />
        )
      }
      options={
        values.urls.length === 1
          ? loadBalancingOptions
          : loadBalancingOptions.map((each) => ({
              ...each,
              disabled: each.value === 'dns_failover' || each.value === 'dns_round_robin',
            }))
      }
    />

    <CuiFormField label={<FormattedMessage {...labels.domainName} />} name='domain_name' />
  </EuiDescribedFormGroup>
)

export default GeneralSettings
