/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSwitch, EuiText, EuiTitle } from '@elastic/eui'

import type { DeploymentTemplateRequestBody } from '@modules/cloud-api/v1/types'

import { isAutoscalingEnabled } from '@/lib/stackDeployments/selectors'
import { setAutoscalingDisabled, setAutoscalingEnabled } from '@/lib/stackDeployments/autoscaling'
import { isAutoscalingSupportedInTemplate } from '@/lib/stackDeployments/selectors/autoscaling'

export interface Props {
  deploymentTemplate: DeploymentTemplateRequestBody
  updateDeploymentTemplate: (deploymentTemplate: DeploymentTemplateRequestBody) => void
}

class TemplateAutoscalingToggle extends Component<Props> {
  render() {
    const { deploymentTemplate, updateDeploymentTemplate } = this.props

    const autoscalingAvailable = isAutoscalingSupportedInTemplate({
      deploymentTemplate: deploymentTemplate.deployment_template,
    })

    if (!autoscalingAvailable) {
      return null
    }

    const autoscalingEnabled = isAutoscalingEnabled({
      deployment: deploymentTemplate.deployment_template,
    })

    return (
      <EuiCallOut>
        <EuiSwitch
          id='template-autoscaling-enable'
          data-test-id='template-autoscaling-enable'
          label={
            <Fragment>
              <EuiTitle size='xxs'>
                <h4>
                  <FormattedMessage
                    id='template-autoscaling-toggle.label'
                    defaultMessage='Enable autoscaling by default'
                  />
                </h4>
              </EuiTitle>
              <EuiText color='subdued' size='s'>
                <FormattedMessage
                  id='template-autoscaling-toggle.description'
                  defaultMessage='Autoscaling allows you to automatically monitor and adjust capacity, and maintain steady, predictable performance.'
                />
              </EuiText>
            </Fragment>
          }
          checked={autoscalingEnabled}
          onChange={this.onChange}
          disabled={!updateDeploymentTemplate}
        />
      </EuiCallOut>
    )
  }

  onChange = () => {
    const { deploymentTemplate, updateDeploymentTemplate } = this.props

    const { deployment_template: deployment } = deploymentTemplate

    const newDeploymentCreateRequest = isAutoscalingEnabled({ deployment })
      ? setAutoscalingDisabled({ deployment })
      : setAutoscalingEnabled({ deployment })

    updateDeploymentTemplate({
      ...deploymentTemplate,
      deployment_template: newDeploymentCreateRequest,
    })
  }
}

export default TemplateAutoscalingToggle
