/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { difference, flatMap, isEqual, map, noop, uniqWith } from 'lodash'

import type { WithEuiThemeProps } from '@elastic/eui'
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPopover,
  EuiSpacer,
  EuiTitle,
  withEuiTheme,
} from '@elastic/eui'

import type { RegionId, Theme } from '@modules/ui-types'
import { CuiAlert, CuiLink } from '@modules/cui'
import history from '@modules/utils/history'

import { roleColors, getRunnerRoles } from '../runnerRoles'
import { calculateHexGrid } from '../../../../lib/hexGrid'
import { getNamedRole } from '../../../../lib/hostRoles'
import { hostAllocatorUrl, hostUrl } from '../../../../lib/urlBuilder'

import type { CSSProperties } from 'react'
import type { CombinedPlatformData, NodeType } from '../infrastructureVisualizationTypes'

interface Props extends WithEuiThemeProps {
  regionId: RegionId
  zone: CombinedPlatformData[]
  cloudTheme: Theme
}

interface State {
  popover: null | {
    runner: CombinedPlatformData
    x: number
    y: number
  }
}

class PlatformVisualization extends Component<Props, State> {
  state: State = {
    popover: null,
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  render() {
    const { zone } = this.props

    const margin = {
      top: 30,
      right: 20,
      bottom: 20,
      left: 30,
    }

    const { width, height, draw } = calculateHexGrid(zone)

    const svgWidth = width + margin.left + margin.right
    const svgHeight = height + margin.top + margin.bottom

    return (
      <div onMouseLeave={() => this.setState({ popover: null })}>
        <svg className='platformVisualization-viz' width={svgWidth} height={svgHeight}>
          {this.renderGradients()}

          <g transform={`translate(${margin.left},${margin.top})`}>
            {zone.map((runner) => {
              const { id } = runner
              const roles = getRunnerRoles(runner)

              const pen = draw(runner)

              const gradientId = `platformVisualization-gradient-${roles.join(`-`)}`
              const gradientHref = `url(#${gradientId})`

              // we only overlay a health gradient when a runner has problems
              const healthHref = this.getHealthGradientHref(runner)

              const firstPath = this.renderHostPath(runner, {
                pen,
                href: gradientHref,
                isInteractive: !healthHref,
              })

              const secondPath = this.renderHostPath(runner, {
                pen,
                href: healthHref,
                isInteractive: true,
              })

              return (
                <Fragment key={id}>
                  {firstPath}
                  {secondPath}
                </Fragment>
              )
            })}
          </g>
        </svg>

        {this.renderHostPopover()}
      </div>
    )
  }

  renderHostPath(
    runner: CombinedPlatformData,
    { pen, href, isInteractive }: { pen: string; href: string | null; isInteractive: boolean },
  ) {
    const { theme } = this.props

    if (!href) {
      return null
    }

    const hexagonStyle = css({
      cursor: 'pointer',
      stroke: theme.euiTheme.colors.emptyShade,
      strokeWidth: '1.5px',
      strokeOpacity: '0.8',
      '&:hover': css({
        opacity: '0.5',
      }),
    })

    const baseProps = {
      d: pen,
      className: `platformVisualization-hexagon`,
      fill: href,
      css: hexagonStyle,
    }

    if (!isInteractive) {
      return <path {...baseProps} />
    }

    return (
      <path
        {...baseProps}
        onClick={() => this.onRunnerClick(runner)}
        onMouseEnter={(e) =>
          this.setState({
            popover: {
              runner,
              x: e.clientX,
              y: e.clientY,
            },
          })
        }
      />
    )
  }

  renderHostPopover() {
    const { regionId } = this.props
    const { popover } = this.state

    if (popover === null) {
      return null
    }

    const { runner, x, y } = popover
    const { healthy, isInMaintenanceMode } = runner
    const roles = getRunnerRoles(runner)
    const hostLabel = this.getHostLabel(roles)

    const styles: CSSProperties = {
      position: `fixed`,
      zIndex: 100,
      top: y,
      left: x,
    }

    return (
      <EuiPopover
        id='platform-visualization-popover'
        anchorPosition='rightUp'
        button={<div />}
        isOpen={true}
        style={styles}
        closePopover={noop}
      >
        <div>
          <EuiTitle size='s'>
            <h3>
              <CuiLink to={hostUrl(regionId, runner.id)}>{runner.id}</CuiLink>
            </h3>
          </EuiTitle>

          <EuiSpacer size='s' />

          <div>
            {roles.map((role) => {
              const color = roleColors.named[role]
              const namedRole = getNamedRole(role)

              const roleText =
                role === 'runner' ? (
                  <FormattedMessage id='platform-visualization.role-label-' defaultMessage='Host' />
                ) : (
                  role
                )

              const linkUrl = namedRole ? namedRole.getUrl : hostUrl
              const label = namedRole ? namedRole.label : roleText

              return (
                <EuiBadge key={role} color={color}>
                  <CuiLink to={linkUrl(regionId, runner.id)} color='inherit'>
                    {label}
                  </CuiLink>
                </EuiBadge>
              )
            })}
          </div>

          {!healthy && (
            <Fragment>
              <EuiSpacer size='m' />

              <CuiAlert type='error' size='s'>
                <EuiFlexGroup gutterSize='s' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='alert' />
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <FormattedMessage
                      id='platform-visualization.unhealthy-runner'
                      defaultMessage='This {hostLabel} is unhealthy'
                      values={{ hostLabel }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </CuiAlert>
            </Fragment>
          )}

          {isInMaintenanceMode && (
            <Fragment>
              <EuiSpacer size='m' />

              <CuiAlert type='warning' size='s'>
                <EuiFlexGroup gutterSize='s' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='wrench' />
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <FormattedMessage
                      id='platform-visualization.maintenance-runner'
                      defaultMessage='This {hostLabel} is under maintenance mode'
                      values={{ hostLabel }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </CuiAlert>
            </Fragment>
          )}
        </div>
      </EuiPopover>
    )
  }

  renderGradients() {
    const { cloudTheme, zone } = this.props
    const roleCombinations: NodeType[][] = uniqWith(map(zone, getRunnerRoles), isEqual)

    const runnerGradients = roleCombinations.map((roles) => {
      const id = roles.join(`-`)

      const coloredRoles = isEqual(roles, [`runner`])
        ? roles
        : difference<NodeType>(roles, [`runner`])

      const colors: string[] = coloredRoles.map((role) => roleColors[cloudTheme][role])

      return { id, colors }
    })

    return (
      <defs>
        {runnerGradients.map(this.renderRunnerGradient)}

        {this.renderHealthGradient(`maintenance`)}
        {this.renderHealthGradient(`unhealthy`)}
      </defs>
    )
  }

  renderRunnerGradient({ id, colors }: { id: string; colors: string[] }) {
    if (colors.length === 0) {
      return null
    }

    const portion = 100 / colors.length

    const colorStops = flatMap(colors, (color, index: number) => [
      { color, offset: `${portion * index}%` },
      { color, offset: `${portion * (index + 1)}%` },
    ])

    return (
      <linearGradient
        key={id}
        id={`platformVisualization-gradient-${id}`}
        x1='0%'
        x2='100%'
        y1='0%'
        y2='0%'
      >
        {colorStops.map((stop, index) => (
          <stop key={index} stopColor={stop.color} offset={stop.offset} />
        ))}
      </linearGradient>
    )
  }

  renderHealthGradient(id: string) {
    return (
      <linearGradient
        id={`platformVisualization-gradient--${id}`}
        className={`platformVisualization-gradient--${id}`}
        spreadMethod='repeat'
        x1='0%'
        x2='10%'
        y1='0%'
        y2='15%'
      >
        <stop stopColor='currentColor' offset='0%' />
        <stop stopColor='currentColor' offset='50%' />
        <stop stopColor='transparent' offset='50%' />
      </linearGradient>
    )
  }

  getHealthGradientHref(runner: CombinedPlatformData) {
    const { healthy, isInMaintenanceMode } = runner

    if (!healthy) {
      return `url(#platformVisualization-gradient--unhealthy)`
    }

    if (isInMaintenanceMode) {
      return `url(#platformVisualization-gradient--maintenance)`
    }

    return null
  }

  getHostLabel(roles: string[]) {
    const genericHost = (
      <FormattedMessage id='platform-visualization.host-name' defaultMessage='host' />
    )

    if (roles.length > 1) {
      return genericHost
    }

    const [firstRole] = roles
    const namedRole = getNamedRole(firstRole)

    if (namedRole) {
      return namedRole.label
    }

    if (firstRole === 'runner') {
      return genericHost
    }

    return firstRole
  }

  onRunnerClick(runner: CombinedPlatformData) {
    const { regionId } = this.props
    const { types, id } = runner

    if (types.allocator) {
      history.push(hostAllocatorUrl(regionId, id))
    } else {
      history.push(hostUrl(regionId, id))
    }
  }
}

export default withEuiTheme(PlatformVisualization)
