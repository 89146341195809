/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { IconType } from '@elastic/eui'

import type { SearchRequest, DeploymentsSearchResponse } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { getDeletedStackDeploymentIds } from '../../../reducers'

import OrganizationDeployments from './OrganizationDeployments'
import {
  getOrganizationDeploymentsSearchResults,
  searchOrganizationDeployments,
  searchOrganizationDeploymentsRequest,
} from './lib'

type StateProps = {
  deletedDeploymentIds: string[]
  searchResults: DeploymentsSearchResponse | null
  searchResultsRequest: AsyncRequestState
  hideCreateClusterButton: boolean
  exportDeployments: boolean
  showIlmMigrationCallout: boolean
  storageKey: string
  profile: null
}

type DispatchProps = {
  searchDeployments: (query: SearchRequest) => void
}

type ConsumerProps = {
  organizationId: string
  iconType?: IconType
}

const mapStateToProps = (state, { organizationId }: ConsumerProps): StateProps => ({
  deletedDeploymentIds: getDeletedStackDeploymentIds(state),
  searchResults: getOrganizationDeploymentsSearchResults(state, organizationId),
  searchResultsRequest: searchOrganizationDeploymentsRequest(state, organizationId),
  hideCreateClusterButton: true,
  exportDeployments: true,
  showIlmMigrationCallout: false,
  storageKey: `OrganizationDeployments/${organizationId}`,
  profile: null,
})

const mapDispatchToProps = (dispatch, { organizationId }: ConsumerProps): DispatchProps => ({
  searchDeployments: (query) => dispatch(searchOrganizationDeployments({ query, organizationId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationDeployments)
