/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCheckbox, EuiFormRow, EuiSpacer } from '@elastic/eui'

import { AdvancedForm, RestoreButton, initialState } from '../../../../../../RestoreSnapshot'

import type { Props as RestoreSnapshotProps } from '../../../../../../RestoreSnapshot'

type RestoreSnapshotState = typeof initialState

class RestoreFromSnapshot extends Component<RestoreSnapshotProps, RestoreSnapshotState> {
  state: RestoreSnapshotState = cloneDeep(initialState)

  render() {
    const {
      onBeforeRestore,
      restoreSnapshot,
      restoreSnapshotRequest,
      snapshot,
      onChangeMaintMode,
      maintModeValue,
    } = this.props

    const { matchPattern, renamePattern, specifyIndices } = this.state

    return (
      <Fragment>
        <EuiSpacer size='s' />

        <AdvancedForm
          specifyIndices={specifyIndices}
          matchPattern={matchPattern}
          renamePattern={renamePattern}
          snapshot={snapshot}
          onChange={(changes: RestoreSnapshotState) => this.setState(changes)}
        />

        <EuiSpacer size='m' />

        {onChangeMaintMode && (
          <Fragment>
            <EuiFormRow
              label={
                <FormattedMessage id='restore-from-snapshot.advanced' defaultMessage='Advanced' />
              }
              helpText={
                <FormattedMessage
                  id='restore-from-snapshot.help-text'
                  defaultMessage='During a normal snapshot restore, the deployment has all instances placed into maintenance mode and the deployment becomes unavailable. If you are absolutely certain that incoming requests will not conflict with the snapshot restore, you may uncheck this option.'
                />
              }
            >
              <EuiCheckbox
                id='user-disabled'
                data-test-id='manageUser-disable'
                label={
                  <FormattedMessage
                    id='restore-from-snapshot.checkbox-label'
                    defaultMessage='Extended Maintenance'
                  />
                }
                checked={maintModeValue}
                onChange={(e) => onChangeMaintMode(e.target.checked)}
              />
            </EuiFormRow>
            <EuiSpacer size='m' />{' '}
          </Fragment>
        )}

        <RestoreButton
          restoreSnapshot={restoreSnapshot}
          restoreSnapshotRequest={restoreSnapshotRequest}
          onBeforeRestore={onBeforeRestore}
          specifyIndices={specifyIndices}
          matchPattern={matchPattern}
          renamePattern={renamePattern}
          snapshot={snapshot}
        />
      </Fragment>
    )
  }
}

export default RestoreFromSnapshot
