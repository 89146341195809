/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, size } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import type { Container, ContainerInstance } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, getFilterQueryString, setFilterQueryString } from '@modules/cui'
import type { ControlledFilterQuery, OnFilterChangeParams } from '@modules/cui'
import Header from '@modules/cui/Header'

import { containerCrumbs } from '../../lib/crumbBuilder'

import ContainerInstancesFilterContext from './ContainerInstancesFilterContext'
import ContainerInstancesTable from './ContainerInstancesTable'
import ContainerJson from './ContainerJson'

type Props = {
  regionId: string
  containerSetId: string
  containerId: string
  container: Container | null
  fetchContainerRequest: AsyncRequestState
  fetchContainer: () => void
}

type State = {
  query: ControlledFilterQuery
  queryResults: ContainerInstance[]
}

class ContainerOverview extends Component<Props, State> {
  state: State = {
    query: getFilterQueryString({ storageKey: `Container/Instances` }),
    queryResults: [],
  }

  componentDidMount() {
    const { fetchContainer } = this.props
    fetchContainer()
  }

  render() {
    const { regionId, containerSetId, containerId } = this.props

    return (
      <Fragment>
        <Header
          name={<FormattedMessage id='container-overview.title' defaultMessage='Container' />}
          breadcrumbs={containerCrumbs({ regionId, containerSetId, containerId })}
        />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const { container, fetchContainerRequest } = this.props
    const { query } = this.state

    if (fetchContainerRequest.error) {
      return <CuiAlert type='error'>{fetchContainerRequest.error}</CuiAlert>
    }

    const containerInstances = this.getContainerInstances()

    return (
      <Fragment>
        <ContainerInstancesFilterContext
          query={query}
          onChange={this.onChange}
          containerInstances={containerInstances}
          isLoading={fetchContainerRequest.inProgress}
        />

        {this.renderContainerInstancesTable()}

        <EuiSpacer size='l' />

        <ContainerJson container={container} />
      </Fragment>
    )
  }

  renderContainerInstancesTable() {
    const { regionId, container } = this.props
    const { queryResults } = this.state

    const loadedButNoMatches = container && isEmpty(queryResults)

    if (loadedButNoMatches) {
      return null
    }

    const containerInstances = this.getContainerInstances()
    const totalCount = size(containerInstances)

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <ContainerInstancesTable
          regionId={regionId}
          containerInstances={queryResults}
          totalCount={totalCount}
          initialLoading={!container}
        />
      </Fragment>
    )
  }

  getContainerInstances() {
    const { container } = this.props

    if (!container) {
      return
    }

    return container.instances || []
  }

  onChange = ({ queryText, queryResults }: OnFilterChangeParams<ContainerInstance>) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `Container/Instances`, queryText })
  }
}

export default ContainerOverview
