/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { clone, size } from 'lodash'

import { EuiBadge, EuiCallOut, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import type { DeploymentTemplateRequestBody } from '@modules/cloud-api/v1/types'

import { getDataTopologies } from '@/lib/deploymentTemplates/getTopologiesFromTemplate'

import { hasIlmNodeAttributes } from '../../../lib/stackDeployments'

import NodeAttributeForm, { pillColorDefinitions } from './NodeAttributeForm'

type Props = {
  template: DeploymentTemplateRequestBody
  updateDeploymentTemplate: (deploymentTemplate: DeploymentTemplateRequestBody) => void
}

type State = {
  pillColors: any
}

class IndexLifecycleManagementSettings extends Component<Props, State> {
  state: State = this.getInitialState()

  getInitialState() {
    const { template } = this.props

    const dataInstances = getDataTopologies({ deployment: template.deployment_template })

    const pillColors = {}

    dataInstances.forEach((topologyElement) => {
      const nodeAttributes = topologyElement.elasticsearch?.node_attributes

      if (!nodeAttributes) {
        return
      }

      Object.keys(nodeAttributes).forEach((key) => {
        const label = `${key}:${nodeAttributes[key]}`

        if (!pillColors[label]) {
          pillColors[label] = pillColorDefinitions[size(pillColors) % pillColorDefinitions.length]
        }
      })
    })

    return { pillColors }
  }

  render() {
    const { template, updateDeploymentTemplate } = this.props
    const { pillColors } = this.state

    const dataInstances = getDataTopologies({ deployment: template.deployment_template })
    const nodeAttributesExistForAllInstances = hasIlmNodeAttributes(dataInstances)

    return (
      <div className='ilmSettings'>
        <EuiTitle size='s'>
          <h4>
            <FormattedMessage
              id='index-lifecycle-management-settings.title'
              defaultMessage='Index lifecycle management (ILM)'
            />
            <EuiBadge className='ilmSettings-badge-recommended'>
              <FormattedMessage
                id='index-lifecycle-management-settings.recommended'
                defaultMessage='Recommended'
              />
            </EuiBadge>
          </h4>
        </EuiTitle>
        <EuiSpacer size='s' />
        <EuiTitle size='xxxs' textTransform='uppercase' className='ilmSettings-label'>
          <h5>
            <FormattedMessage
              id='index-lifecycle-management-settings.stack-version'
              defaultMessage='Stack version 6.7 and later'
            />
          </h5>
        </EuiTitle>
        <EuiText>
          <p>
            <FormattedMessage
              id='index-lifecycle-management-settings.further-description'
              defaultMessage='Move indices between different stages of the lifecycle (hot, warm, cold, delete) in response to index details and other factors, such as shard size and performance requirements.'
            />
          </p>
        </EuiText>

        {!nodeAttributesExistForAllInstances && (
          <Fragment>
            <EuiSpacer />
            <EuiCallOut
              color='warning'
              title={
                <FormattedMessage
                  id='index-lifecycle-management-settings.missing-config.title'
                  defaultMessage='Missing configuration'
                />
              }
            >
              <FormattedMessage
                id='index-lifecycle-management-settings.missing-config.description'
                defaultMessage='Add one or more node attributes for each data node to enable ILM.'
              />
            </EuiCallOut>
          </Fragment>
        )}

        <EuiSpacer />

        <Fragment>
          <EuiTitle size='xs'>
            <h5>
              <FormattedMessage
                id='index-lifecycle-management-settings.node-attributes.title'
                defaultMessage='Node attributes'
              />
            </h5>
          </EuiTitle>
          <EuiSpacer size='xs' />
          <EuiText>
            <p>
              <FormattedMessage
                id='index-lifecycle-management-settings.node-attributes.description'
                defaultMessage='Assign attributes to each node - hot, warm or cold - to configure index lifecycle policies in Kibana.'
              />
            </p>
          </EuiText>
          <EuiSpacer size='m' />
          {dataInstances.map((instance, index) => (
            <NodeAttributeForm
              template={template}
              key={index}
              instance={instance}
              updateDeploymentTemplate={updateDeploymentTemplate}
              addPillColor={this.addPillColor}
              pillColors={pillColors}
            />
          ))}
        </Fragment>
      </div>
    )
  }

  addPillColor = (option) => {
    const { pillColors } = this.state
    const newPillColors = clone(pillColors)
    newPillColors[option.label] = option.color

    this.setState({ pillColors: newPillColors })
  }
}

export default IndexLifecycleManagementSettings
