/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { StackDeployment } from '@modules/ui-types'

import {
  updateEsMetadata,
  resetSetDeploymentResourceMetadata,
  resetTempShieldUser,
} from '@/actions/stackDeployments/metadata'
import { getFirstEsRefId } from '@/lib/stackDeployments/selectors'

import {
  getCurrentUser,
  getNewTempShieldUser,
  getRoot,
  setDeploymentResourceMetadataRequest,
} from '../../../reducers'

import TempShieldUsers from './TempShieldUsers'

import type { ReduxState } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './TempShieldUsers'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => {
  const { id: deploymentId } = deployment
  const esRefId = getFirstEsRefId({ deployment })!
  return {
    newTempShieldUser: getNewTempShieldUser(state),
    root: getRoot(state),
    user: getCurrentUser(state),
    setDeploymentResourceMetadataRequest: setDeploymentResourceMetadataRequest(
      state,
      deploymentId,
      `elasticsearch`,
      esRefId,
    ),
  }
}

const mapDispatchToProps: DispatchProps = {
  resetSetDeploymentResourceMetadata: (deployment: StackDeployment) => {
    const { id: deploymentId } = deployment
    const esRefId = getFirstEsRefId({ deployment })!
    return resetSetDeploymentResourceMetadata(deploymentId, `elasticsearch`, esRefId)
  },
  resetTempShieldUser,
  updateMetadata: updateEsMetadata,
}

export default connect(mapStateToProps, mapDispatchToProps)(TempShieldUsers)
