/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState, ThunkDispatch } from '@/types/redux'
import { resetSearchStackDeploymentsRequest } from '@/actions/stackDeployments/crud'

import { searchDeployments } from '../../../../../actions/stackDeployments'
import {
  getStackDeploymentsFromSearch,
  searchStackDeploymentsRequest,
} from '../../../../../reducers'

import SpecificDeploymentsPanel from './SpecificDeploymentsPanel'

import type { StateProps, ConsumerProps, DispatchProps } from './types'

const queryId = `permissions-specific-deployments-roles`

const mapStateToProps = (state: ReduxState): StateProps => ({
  deployments: getStackDeploymentsFromSearch(state, queryId)?.deployments || [],
  searchDeploymentsRequest: searchStackDeploymentsRequest(state, queryId),
  resetDeploymentsSearchRequest: () => resetSearchStackDeploymentsRequest(queryId),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchDeployments: (query) => dispatch(searchDeployments({ queryId, query })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SpecificDeploymentsPanel)
