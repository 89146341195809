/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { setRunnerRolesUrl } from '@modules/cloud-api/v1/urls'
import type { RunnerRoleInfo } from '@modules/cloud-api/v1/types'
import type { RegionId } from '@modules/ui-types'

import { UPDATE_RUNNER_ROLES } from '../../constants/actions'
import asyncRequest, { resetAsyncRequest } from '../asyncRequests'

import { fetchRunner } from './fetchRunner'

import type { ThunkAction } from '@/types/redux'

export function updateRoles(
  regionId: RegionId,
  runnerId: string,
  roles: RunnerRoleInfo[],
): ThunkAction {
  return (dispatch) => {
    const url = setRunnerRolesUrl({ regionId, runnerId })
    const body = { roles }

    return dispatch(
      asyncRequest({
        type: UPDATE_RUNNER_ROLES,
        url,
        payload: body,
        method: 'PUT',
        meta: {
          regionId,
          runnerId,
        },
        crumbs: [regionId, runnerId],
      }),
    ).then(() => dispatch(fetchRunner(regionId, runnerId)))
  }
}

export function resetUpdateRoles(regionId: RegionId, runnerId: string) {
  return resetAsyncRequest(UPDATE_RUNNER_ROLES, [regionId, runnerId])
}
