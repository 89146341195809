/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSkeletonText } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'
import Header from '@modules/cui/Header'

import { platformCrumbs } from '../../../../../lib/crumbBuilder'

import ManageRegionsTable from './ManageRegionsTable'

import type { ReactNode } from 'react'

export interface Props {
  fetchRegionListIfNeeded: () => void
  regionIds: string[] | null
  regionsRequest: AsyncRequestState
  children?: ReactNode
}

export default class ManageRegions extends Component<Props> {
  componentDidMount() {
    const { fetchRegionListIfNeeded } = this.props
    fetchRegionListIfNeeded()
  }

  render() {
    return (
      <div>
        <Header
          name={<FormattedMessage id='manage-regions.regions' defaultMessage='Regions' />}
          breadcrumbs={platformCrumbs()}
        />

        <div>{this.renderRegions()}</div>
      </div>
    )
  }

  renderRegions() {
    const { regionIds, regionsRequest } = this.props

    if (regionsRequest.error) {
      return (
        <CuiAlert data-test-id='loading-regions-failed' details={regionsRequest.error} type='error'>
          <FormattedMessage
            id='manage-regions.fetching-regions-failed'
            defaultMessage='Fetching regions failed'
          />
        </CuiAlert>
      )
    }

    if (regionIds == null) {
      return (
        <div data-test-id='loading-regions'>
          <EuiSkeletonText />
        </div>
      )
    }

    return <ManageRegionsTable regionIds={regionIds} />
  }
}
