/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { SamlSettings } from '@modules/cloud-api/v1/types'

import {
  fetchSamlSecurityRealmRequest,
  getSamlAuthProvider,
  updateSamlSecurityRealmRequest,
} from '../../../../../reducers'
import {
  fetchSamlSecurityRealm,
  fetchSecurityCluster,
  updateSamlSecurityRealm,
} from '../../../../../actions'

import EditSamlProvider from './EditSamlProvider'

const mapStateToProps = (
  state,
  {
    match: {
      params: { regionId, realmId },
    },
  },
) => ({
  samlSettings: getSamlAuthProvider(state, regionId, realmId),
  fetchSamlSecurityRealmRequest: fetchSamlSecurityRealmRequest(state, regionId, realmId),
  updateSamlSecurityRealmRequest: updateSamlSecurityRealmRequest(state, regionId, realmId),
  regionId,
  realmId,
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { regionId, realmId },
    },
  },
) => ({
  fetchSamlSecurityRealm: () => dispatch(fetchSamlSecurityRealm(regionId, realmId)),
  onSubmit: (payload: SamlSettings) =>
    dispatch(updateSamlSecurityRealm(regionId, realmId, payload)).then(() =>
      dispatch(fetchSecurityCluster(regionId)),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditSamlProvider)
