/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { NewDeploymentCredentials } from '@modules/ui-types'

import { CLEAR_CLUSTER_CREDENTIALS, RESET_CLUSTER_PASSWORD } from '../../constants/actions'

import type { ClusterCredentialsAction, ResetPasswordAction } from './clusterTypes'

export interface State {
  [descriptor: string]: NewDeploymentCredentials | null
}

function reduceCredentials(
  state: NewDeploymentCredentials | undefined | null = null,
  action: ResetPasswordAction,
): NewDeploymentCredentials | null {
  const username = action.payload?.username ?? 'elastic'
  const password = action.payload?.password

  if (username != null && password != null) {
    return {
      username,
      password,
      resetPassword: true,
    }
  }

  return state
}

function createDescriptor(id, refId) {
  return refId == null || id == null ? null : `${id}/${refId}`
}

export default function clustersCredentialsReducer(
  state: State = {},
  action: ClusterCredentialsAction,
): State {
  if (action.error) {
    return state
  }

  if (action.type === RESET_CLUSTER_PASSWORD) {
    const { id, refId } = action.meta
    const descriptor = createDescriptor(id, refId)

    if (!descriptor) {
      return state
    }

    return {
      ...state,
      [descriptor]: reduceCredentials(state[descriptor], action),
    }
  }

  if (action.type === CLEAR_CLUSTER_CREDENTIALS) {
    const { id, refId } = action.meta
    const descriptor = createDescriptor(id, refId)

    if (!descriptor) {
      return state
    }

    return {
      ...state,
      [descriptor]: null,
    }
  }

  return state
}

export function getClusterCredentials(state: State, id: string, refId: string) {
  const descriptor = createDescriptor(id, refId)
  return descriptor == null ? null : state[descriptor]
}
