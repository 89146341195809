/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import {
  EuiCode,
  EuiDescribedFormGroup,
  EuiHorizontalRule,
  EuiSpacer,
  EuiTitle,
  EuiText,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { ClusterCredentials } from '@modules/cloud-api/v1/types'
import type { StackDeployment, ElasticsearchCluster, AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, CuiButton, CuiPermissibleControl } from '@modules/cui'

import Keystore from '../Keystore'
import DeploymentTrafficFilterSection from '../DeploymentTrafficFilterSection'
import DeploymentTrustManagementECE from '../../../apps/adminconsole/components/DeploymentTrustManagement'
import DeploymentTrustManagementESS from '../../../apps/userconsole/components/DeploymentTrustManagement'
import DocLink from '../../DocLink'
import SaveDeploymentCredentialsModal from '../../Deployment/DeploymentGettingStarted/SaveDeploymentCredentialsModal'
import { kibanaUrl } from '../../../lib/urlBuilder'
import { gte } from '../../../lib/semver'
import { getClusterMetadata, isSystemOwned } from '../../../lib/stackDeployments/selectors'

import CertificateAuthority from './CertificateAuthority'
import RemoteClusterLink from './RemoteClusterLink'
import KibanaSecurityLink from './KibanaSecurityLink'

import type { FunctionComponent } from 'react'

export type StateProps = {
  resetPasswordStatus: AsyncRequestState
  trafficFilteringEnabled: boolean
  crossEnvCcsCcrEnabled: boolean
  isEce: boolean
  credentials: ClusterCredentials | null
}

export type DispatchProps = {
  resetPassword: (deployment: StackDeployment) => void
}

export type ConsumerProps = {
  deployment: StackDeployment
  cluster: ElasticsearchCluster
}

export type Props = StateProps & DispatchProps & ConsumerProps

const DeploymentSecurityEditor: FunctionComponent<Props> = ({
  deployment,
  cluster,
  resetPassword,
  resetPasswordStatus,
  trafficFilteringEnabled,
  crossEnvCcsCcrEnabled,
  isEce,
  credentials,
}) => {
  const clusterMetadata = getClusterMetadata({ deployment })
  const systemOwned = isSystemOwned({ deployment })

  const showTrustManagement = crossEnvCcsCcrEnabled && !systemOwned

  return (
    <Fragment>
      <EuiDescribedFormGroup
        ratio='third'
        fullWidth={true}
        title={
          <h3>
            <FormattedMessage
              id='deployment-security-editor.settings-heading'
              defaultMessage='Settings'
            />
          </h3>
        }
        description={
          <FormattedMessage
            id='deployment-security-editor.settings-description'
            defaultMessage='Security settings can be tweaked in Kibana Manage.'
          />
        }
      >
        <div>
          {cluster.kibana.enabled ? (
            <KibanaSecurityLink deployment={deployment} cluster={cluster} />
          ) : (
            <FormattedMessage
              id='deployment-security-editor.enable-kibana'
              data-test-id='enable-kibana'
              defaultMessage='{enableKibana} to make security changes to this deployment.'
              values={{
                enableKibana: (
                  <Link to={kibanaUrl(cluster.stackDeploymentId!)}>
                    <FormattedMessage
                      id='deployment-security-editor.enable-kibana-link'
                      defaultMessage='Enable Kibana'
                    />
                  </Link>
                ),
              }}
            />
          )}
        </div>
      </EuiDescribedFormGroup>

      <EuiHorizontalRule />

      <EuiDescribedFormGroup
        ratio='third'
        fullWidth={true}
        title={
          <h3>
            <FormattedMessage
              id='deployment-security-editor.reset-password-heading'
              defaultMessage='Reset password'
            />
          </h3>
        }
        description={
          <FormattedMessage
            id='deployment-security-editor.reset-password-explanation'
            defaultMessage='Generates a new password for the {elasticUser} user.'
            values={{
              elasticUser: <EuiCode>elastic</EuiCode>,
            }}
          />
        }
      >
        <div>
          <CuiPermissibleControl permissions={Permission.resetElasticsearchUserPassword}>
            <CuiButton
              color='primary'
              size='s'
              data-test-id='deploymentSecurity-resetPasswordBtn'
              onClick={() => {
                resetPassword(deployment)
              }}
              spin={resetPasswordStatus.inProgress}
              fill={false}
              confirm={true}
              confirmModalProps={{
                title: (
                  <FormattedMessage
                    id='deployment-security-editor.confirm-to-reset-password'
                    defaultMessage='Reset your password?'
                  />
                ),
                confirm: (
                  <FormattedMessage
                    id='deployment-security-editor.confirm-to-reset-password.confirm'
                    defaultMessage='Reset'
                  />
                ),
              }}
            >
              <FormattedMessage
                id='cluster-delete-cluster.reset-password'
                defaultMessage='Reset password'
              />
            </CuiButton>
          </CuiPermissibleControl>

          {resetPasswordStatus.error && (
            <Fragment>
              <EuiSpacer size='m' />
              <CuiAlert type='error' data-test-id='reset-password-error'>
                {resetPasswordStatus.error}
              </CuiAlert>
            </Fragment>
          )}
        </div>
      </EuiDescribedFormGroup>

      <DeploymentTrafficFilterSection
        regionId={cluster.regionId}
        deploymentId={cluster.stackDeploymentId!}
        trafficFilteringEnabled={trafficFilteringEnabled}
        spacerBefore={true}
      />

      {cluster.plan.version && gte(cluster.plan.version, `6.4.0`) && (
        <Fragment>
          <EuiHorizontalRule />

          <Keystore deployment={deployment} systemOwned={systemOwned} />
        </Fragment>
      )}

      {showTrustManagement && (
        <Fragment>
          <EuiHorizontalRule />
          <Fragment>
            <EuiTitle size='s'>
              <h3>
                <FormattedMessage
                  id='deployment-security-editor.remote-connections-title'
                  defaultMessage='Remote Connections'
                />
              </h3>
            </EuiTitle>
            <EuiText size='s'>
              <FormattedMessage
                id='deployment-security-editor.remote-connections-description'
                defaultMessage='This section is related to the configuration of remote clusters. <link>Learn more</link>'
                values={{
                  link: (content) => <DocLink link='remoteClusters'>{content}</DocLink>,
                }}
              />
            </EuiText>
            <EuiSpacer size='xl' />
          </Fragment>

          <Fragment>
            {isEce ? (
              <DeploymentTrustManagementECE deployment={deployment} />
            ) : (
              <DeploymentTrustManagementESS deployment={deployment} />
            )}

            <EuiHorizontalRule />
          </Fragment>

          <CertificateAuthority deployment={deployment} />

          {clusterMetadata && (
            <Fragment>
              <EuiHorizontalRule />

              <EuiDescribedFormGroup
                ratio='third'
                data-test-id='remote-cluster-params'
                fullWidth={true}
                title={
                  <h3>
                    <FormattedMessage
                      id='deployment-security-editor.remote-cluster-heading'
                      defaultMessage='Remote cluster parameters'
                    />
                  </h3>
                }
                description={
                  <FormattedMessage
                    id='deployment-security-editor.remote-cluster-explanation'
                    defaultMessage='Connect clusters from a trusted environment to clusters of this deployment using these parameters. {docLink}'
                    values={{
                      docLink: (
                        <DocLink link='enableCCS'>
                          <FormattedMessage
                            id='deployment-security-editor.remote-cluster-explanation.learnmore'
                            defaultMessage='Learn more'
                          />
                        </DocLink>
                      ),
                    }}
                  />
                }
              >
                <Fragment>
                  <RemoteClusterLink
                    title={
                      <FormattedMessage
                        id='deployment-security-editor.remote-cluster-proxy-address-heading'
                        defaultMessage='Proxy address'
                      />
                    }
                    description={
                      <FormattedMessage
                        id='deployment-security-editor.remote-cluster-proxy-address-description'
                        defaultMessage='The endpoint of the remote connection used by Kibana.'
                      />
                    }
                    endpoint={`${clusterMetadata.endpoint}:${clusterMetadata.ports?.transport_passthrough}`}
                  />

                  <EuiSpacer />

                  <RemoteClusterLink
                    title={
                      <FormattedMessage
                        id='deployment-security-editor.remote-cluster-server-name-heading'
                        defaultMessage='Server name'
                      />
                    }
                    description={
                      <FormattedMessage
                        id='deployment-security-editor.remote-cluster-server-name-description'
                        defaultMessage='The host name string to use for remote connections.'
                      />
                    }
                    endpoint={clusterMetadata.endpoint!}
                  />
                </Fragment>
              </EuiDescribedFormGroup>
            </Fragment>
          )}
        </Fragment>
      )}
      {credentials && !resetPasswordStatus.inProgress && (
        <SaveDeploymentCredentialsModal deployment={deployment} />
      )}
    </Fragment>
  )
}

export default DeploymentSecurityEditor
