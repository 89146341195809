/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { injectIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'

import { filterDeploymentRoleAssignmentByAll } from '@/components/Users/RoleAssignmentsPanel/lib'

import RoleBadge from './RoleBadge'
import RoleWithDeploymentsCount from './RoleWithDeploymentsCount'

import type {
  DeploymentRole,
  OrganizationRole,
} from '@/components/Users/RoleAssignmentsPanel/types'
import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'

export type Props = {
  roleAssignments: RoleAssignments | undefined
  allOrganizationDeploymentsCount: number | string
} & WrappedComponentProps

const OrganizationMemberRole: FunctionComponent<Props> = ({
  roleAssignments = {
    organization: [],
    deployment: [],
  },
  allOrganizationDeploymentsCount,
}) => {
  const organizationRoleAssignments = roleAssignments.organization
  const allDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
    roleAssignments.deployment,
    true,
  )
  const specificDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
    roleAssignments.deployment,
    false,
  )

  return (
    <EuiFlexGroup wrap={true} gutterSize='xs' responsive={false}>
      {organizationRoleAssignments?.map(({ role_id: roleId }) => (
        <EuiFlexItem grow={false} key={roleId}>
          <RoleBadge role={roleId as OrganizationRole} />
        </EuiFlexItem>
      ))}

      {allDeploymentsRoleAssignments.map(({ role_id: roleId }, index) => (
        <EuiFlexItem grow={false} key={`all-badge-${index}`}>
          <RoleWithDeploymentsCount
            role={roleId as DeploymentRole}
            count={`${allOrganizationDeploymentsCount}`}
          />
        </EuiFlexItem>
      ))}

      {specificDeploymentsRoleAssignments.map((deploymentRole, index) => {
        const count = deploymentRole.deployment_ids?.length

        if (count === undefined || count === 0) {
          return null
        }

        return (
          <EuiFlexItem grow={false} key={`specific-badge-${index}`}>
            <RoleWithDeploymentsCount
              role={deploymentRole.role_id as DeploymentRole}
              count={`${count}`}
            />
          </EuiFlexItem>
        )
      })}
    </EuiFlexGroup>
  )
}

export default injectIntl(OrganizationMemberRole)
