/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'url'

import { isEmpty } from 'lodash'

import { validation as messages } from '../authProviderMessages'
import { isValidYaml } from '../../../../../../lib/yaml'

import type { SamlProviderFormShape } from './SamlProviderForm'
import type { FormikErrors } from 'formik'
import type { IntlShape } from 'react-intl'

const validateSamlForm =
  ({ formatMessage }: IntlShape) =>
  (form: SamlProviderFormShape) => {
    const errors: FormikErrors<SamlProviderFormShape> = {}

    const required = formatMessage(messages.required)

    const fields: Array<keyof SamlProviderFormShape> = [
      'assertion_consumer_service_url',
      'identity_provider_entity_id',
      'logout_url',
      'metadata_url',
      'name',
      'service_provider_entity_id',
    ]

    for (const field of fields) {
      if (isEmpty(form[field])) {
        errors[field] = required
      }
    }

    const acsFields: Array<keyof SamlProviderFormShape['attribute_mappings']> = [
      'principal',
      'groups',
    ]

    for (const acsField of acsFields) {
      if (isEmpty(form.attribute_mappings[acsField])) {
        errors[`attribute_mappings.${acsField}`] = required
      }
    }

    if (isEmpty(form.role_mappings.default_roles) && isEmpty(form.role_mappings.rules)) {
      const rolesMessage = formatMessage(messages.mustSpecifyDefaultRolesOrRules)
      errors[`role_mappings.default_roles`] = rolesMessage
      errors[`role_mappings.rules`] = rolesMessage
    }

    const urlFields: Array<keyof SamlProviderFormShape> = [
      'assertion_consumer_service_url',
      'logout_url',
      'metadata_url',
      'signing_certificate_url',
      'encryption_certificate_url',
      'ssl_certificate_url',
    ]

    for (const urlKey of urlFields) {
      if (form[urlKey]) {
        // The urlFields type actually allows for any form field, whereas we know that we'll only get strings back
        const { hostname, protocol } = parse(form[urlKey] as string)

        if (isEmpty(hostname) || isEmpty(protocol)) {
          errors[urlKey] = formatMessage(messages.validUrl)
        }
      }
    }

    if (!isValidYaml(form.advanced_settings_yaml)) {
      errors.advanced_settings_yaml = formatMessage(messages.validYaml)
    }

    return errors
  }

export default validateSamlForm
