/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiCallOut, EuiSpacer } from '@elastic/eui'

import type { CreditRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { withErrorBoundary } from '@modules/cui'

import CreditAccountFlyout from './CreditAccountFlyout'

import type { FunctionComponent, ReactNode } from 'react'

export interface Props {
  creditAccount: (creditRequest: CreditRequest) => Promise<void>
  creditAccountRequest: AsyncRequestState
  requestedBy: string
}

const CreditAccountButton: FunctionComponent<Props> = ({
  creditAccount,
  creditAccountRequest,
  requestedBy,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  let modal: ReactNode = null

  if (modalOpen) {
    modal = (
      <CreditAccountFlyout
        creditAccount={creditAccount}
        creditAccountRequest={creditAccountRequest}
        requestedBy={requestedBy}
        onClose={() => setModalOpen(false)}
      />
    )
  }

  return (
    <Fragment>
      <EuiButtonEmpty size='s' onClick={() => setModalOpen(true)}>
        <FormattedMessage
          id='organization.organization-overview.credit-account-button.button-text'
          defaultMessage='Credit account'
        />
      </EuiButtonEmpty>

      {modal}

      {!creditAccountRequest.error && creditAccountRequest.isDone && (
        <Fragment>
          <EuiSpacer size='m' />
          <EuiCallOut
            size='s'
            iconType='check'
            title={
              <FormattedMessage
                id='organization.organization-overview.credit-account-button.text-success'
                defaultMessage='Credit added to account'
              />
            }
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default withErrorBoundary(CreditAccountButton)
