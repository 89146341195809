/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  startAllocatorMaintenanceModeUrl,
  stopAllocatorMaintenanceModeUrl,
} from '@modules/cloud-api/v1/urls'
import type { Allocator } from '@modules/ui-types'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { SET_ALLOCATOR_MAINTENANCE_MODE } from '../../constants/actions'

export function setAllocatorMaintenanceMode(allocator: Allocator, maintenance: boolean) {
  const { regionId, id } = allocator
  const url = maintenance
    ? startAllocatorMaintenanceModeUrl({ regionId, allocatorId: id })
    : stopAllocatorMaintenanceModeUrl({ regionId, allocatorId: id })

  return asyncRequest({
    type: SET_ALLOCATOR_MAINTENANCE_MODE,
    method: `POST`,
    url,
    payload: { maintenance },
    meta: { regionId, id, selfUrl: url },
    crumbs: [regionId, id],
  })
}

export const resetSetAllocatorMaintenanceModeRequest = (...crumbs) =>
  resetAsyncRequest(SET_ALLOCATOR_MAINTENANCE_MODE, crumbs)
