/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiBadge } from '@elastic/eui'

import { CuiExpansibleTagList } from '@modules/cui'
import type { CuiExpansibleTag } from '@modules/cui'

import type { ReactElement, ReactNode, FunctionComponent } from 'react'
import type { FormattedMessage } from 'react-intl'

interface Props {
  title: ReactElement<typeof FormattedMessage>
  field: string
  onTagClick?: (field: string, tag: CuiExpansibleTag) => void
  tags: string[]
  isActiveTag: (field: string, tagText: string) => boolean
  renderTag?: (params: { tag: CuiExpansibleTag; tagText: string }) => NonNullable<ReactNode>
  maxInlineTags?: number
}

interface RunnerTagProps {
  tag: CuiExpansibleTag
  tagText: string
  contents: NonNullable<ReactNode>
}

const RunnerTags: FunctionComponent<Props> = ({
  title,
  field,
  tags,
  isActiveTag,
  onTagClick,
  maxInlineTags,
  renderTag = ({ tagText }) => tagText,
}) => {
  const RunnerTag: FunctionComponent<RunnerTagProps> = ({ tag, tagText, contents }) => {
    const clickProps = onTagClick
      ? {
          onClick: () => onTagClick(field, tag),
          onClickAriaLabel: tagText,
        }
      : {}

    return (
      <EuiBadge color={isActiveTag(field, tagText) ? `success` : `hollow`} {...clickProps}>
        {contents}
      </EuiBadge>
    )
  }

  return (
    <CuiExpansibleTagList
      title={title}
      tags={tags}
      maxInlineTags={maxInlineTags}
      renderTag={({ tag }) => {
        const tagText = typeof tag === 'string' ? tag : `${tag.key}:${tag.value}`

        return (
          <RunnerTag
            tag={tag}
            tagText={tagText}
            contents={renderTag({
              tag,
              tagText,
            })}
          />
        )
      }}
    />
  )
}

export default RunnerTags
