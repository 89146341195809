/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import type { SaasUsersSearchResponseData } from '@modules/cloud-api/v1/types'

import { AsyncAction, Action } from '@/types/redux'
import {
  RESET_SEARCH_USERS,
  SEARCH_USERS,
  SET_USER_SEARCH_TYPE,
  SET_USER_SEARCH_TERM,
} from '@/apps/adminconsole/constants/actions'

import type { UserSearchType } from '@/actions/saasUsers'

export interface State {
  searchType: UserSearchType
  searchTerm: Record<UserSearchType, string>
  results: undefined | SaasUsersSearchResponseData[]
}

interface SearchUsersAction
  extends AsyncAction<typeof SEARCH_USERS, SaasUsersSearchResponseData[]> {}

interface SetUserSearchTypeAction extends Action<typeof SET_USER_SEARCH_TYPE> {
  payload: UserSearchType
}

interface SetUserSearchTermAction extends Action<typeof SET_USER_SEARCH_TERM> {
  payload: string
}

type ResetSearchUsersAction = Action<typeof RESET_SEARCH_USERS>

const defaultState: State = {
  searchType: 'id',
  searchTerm: {
    id: '',
    deployment_id: '',
    email: '',
  },
  results: undefined,
}

export default function searchUsersReducer(
  state: State = defaultState,
  action:
    | SearchUsersAction
    | SetUserSearchTypeAction
    | SetUserSearchTermAction
    | ResetSearchUsersAction,
): State {
  if (action.type === SEARCH_USERS && !action.error && action.payload) {
    return { ...state, results: action.payload }
  }

  if (action.type === SET_USER_SEARCH_TYPE) {
    return { ...state, searchType: action.payload }
  }

  if (action.type === SET_USER_SEARCH_TERM) {
    return {
      ...state,
      searchTerm: {
        ...state.searchTerm,
        [state.searchType]: action.payload,
      },
    }
  }

  if (action.type === RESET_SEARCH_USERS) {
    return { ...state, results: undefined }
  }

  return state
}

export function getResults(state: State) {
  return state.results
}

export function getUserSearchTerm(state: State) {
  return state.searchTerm[state.searchType]
}
