/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import hasResourcePermissions from '@/lib/rbacPermissions'
import { getAuthzRoles } from '@/reducers'
import { getConfigForKey } from '@/selectors'

import RoleAssignmentsPanel from './RoleAssignmentsPanel'

import type { ReduxState } from '@/types/redux'
import type { ConsumerProps, StateProps } from './types'

const getRolesScopes = (state: ReduxState): Set<string> | null => {
  const isAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`

  // AC users should have permission to see all role scopes
  if (isAdminconsole) {
    return new Set(['organization', 'deployment'])
  }

  const authzRoles = getAuthzRoles(state)

  if (authzRoles === null) {
    return null
  }

  return new Set(authzRoles.map(({ scope_type }) => scope_type))
}

const mapStateToProps = (state: ReduxState): StateProps => {
  const { hasPermissions } = hasResourcePermissions(state, [{ type: 'deployment', action: 'get' }])

  return {
    rolesScopes: getRolesScopes(state),
    hasGetAllDeploymentsPermission: hasPermissions,
  }
}

export default connect<StateProps, null, ConsumerProps>(mapStateToProps)(RoleAssignmentsPanel)
