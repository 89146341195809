/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { CreditRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { creditOrganization, resetCreditOrganizationRequest } from '@/actions/saasOrganizations'
import { creditOrganizationRequest } from '@/reducers/asyncRequests/registry'
import { getCurrentUserUsername } from '@/reducers'

import CreditAccountButton from './CreditAccountButton'

import type { ThunkDispatch, ReduxState } from '@/types/redux'

interface StateProps {
  creditAccountRequest: AsyncRequestState
  requestedBy: string
}

interface DispatchProps {
  // This component doesn't care about the promise's value
  creditAccount: (creditRequest: CreditRequest) => Promise<any>
  resetCreditAccount: () => void
}

interface ConsumerProps {
  organizationId: string
}

const mapStateToProps = (state: ReduxState, { organizationId }: ConsumerProps): StateProps => {
  // In a real SaaS deployment that's using Okta SSO, we'll always have an email as the username,
  const username = getCurrentUserUsername(state) || 'admin'
  const requestedBy = username.includes('@') ? username : `${username}@elastic.co`

  return {
    creditAccountRequest: creditOrganizationRequest(state, organizationId),
    requestedBy,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organizationId }: ConsumerProps,
): DispatchProps => ({
  creditAccount: (creditRequest) => dispatch(creditOrganization(organizationId, creditRequest)),
  resetCreditAccount: () => dispatch(resetCreditOrganizationRequest(organizationId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreditAccountButton)
