/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import { EuiErrorBoundary } from '@elastic/eui'

import type { CommonClusterPlanInfo } from '@modules/cloud-api/v1/types'
import type { ResourceChangeAttempt, AsyncRequestState } from '@modules/ui-types'
import { CuiTableLoadingCellContent, withErrorBoundary } from '@modules/cui'

import {
  StackConfigurationChangeError,
  StackConfigurationChangeRawPlanJson,
  StackConfigurationChangeStepDataMigration,
  StackConfigurationChangeSteps,
  StackConfigurationChangeStepSnapshot,
  StackConfigurationChangeWarnings,
} from '../../../StackDeploymentConfigurationChange'

type Props = {
  change: ResourceChangeAttempt
  planActivityDetail: () => CommonClusterPlanInfo | null
  fetchPlanActivityDetailRequest: () => AsyncRequestState
}

class DetailRow extends Component<Props> {
  componentDidMount() {}

  render() {
    const { change, fetchPlanActivityDetailRequest, planActivityDetail } = this.props
    const { deployment, resource, resourceType, planAttempt } = change

    const isFetching = fetchPlanActivityDetailRequest().inProgress

    const planAttemptDetails = planActivityDetail()

    if (isFetching && !planAttemptDetails) {
      // if we are still fetching and we have nothing cached, show loading component
      return <CuiTableLoadingCellContent />
    } else if (!planAttemptDetails) {
      // if the request is done, and we still have no data, nothing we can do
      return null // sanity - we don't expect this
    }

    planAttempt.plan_attempt_log = planAttemptDetails.plan_attempt_log

    return (
      <EuiErrorBoundary>
        <StackConfigurationChangeError
          resource={resource}
          resourceType={resourceType}
          planAttempt={planAttempt}
          spacerAfter={true}
        />
        <StackConfigurationChangeWarnings
          resource={resource}
          resourceType={resourceType}
          planAttempt={planAttempt}
          spacerAfter={true}
        />
        <StackConfigurationChangeStepSnapshot
          deployment={deployment}
          planAttempt={planAttempt}
          spacerAfter={true}
        />
        <StackConfigurationChangeStepDataMigration
          deployment={deployment}
          planAttempt={planAttempt}
          spacerAfter={true}
        />
        <StackConfigurationChangeRawPlanJson
          resource={resource}
          planAttempt={planAttempt}
          spacerAfter={true}
        />
        <StackConfigurationChangeSteps
          deployment={deployment}
          resourceType={resourceType}
          resource={resource}
          planAttempt={planAttempt}
        />
      </EuiErrorBoundary>
    )
  }
}

export default withErrorBoundary(DetailRow)
