/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import DocLink from '@/components/DocLink'

import type { SliderNodeDefinition } from '../types'

const definition: SliderNodeDefinition = {
  messages: defineMessages({
    prettyName: {
      id: `sliders.elasticsearch.data_cold.prettyName`,
      defaultMessage: `Cold data`,
    },
    instanceConfigurationDescription: {
      id: `sliders.elasticsearch.data_cold.instanceConfigurationDescription`,
      defaultMessage: `Store rarely queried data on a cold tier for increased cost savings.`,
    },
  }),
  iconType: `logoElasticsearch`,
  instanceConfigurationExtraDescription: (
    <FormattedMessage
      id='sliders.elasticsearch.data_cold.instanceConfigurationExtraDescription'
      defaultMessage='For better storage savings, you can keep {fullyMountedIndices} of {searchableSnapshots} on the cold tier in a snapshot repository. Unlike regular indices, these read-only indices don’t require replicas for reliability. If there’s a failure, recovery comes from the underlying snapshot instead. This greatly reduces the local storage required.'
      values={{
        fullyMountedIndices: (
          <DocLink link='searchableSnapshotsFullyMountedDocLink'>
            <FormattedMessage
              id='sliders.elasticsearch.data_cold.instanceConfigurationExtraDescription.fully-mounted'
              defaultMessage='fully mounted indices'
            />
          </DocLink>
        ),
        searchableSnapshots: (
          <DocLink link='searchableSnapshotsILMDocLink'>
            <FormattedMessage
              id='sliders.elasticsearch.data_cold.instanceConfigurationExtraDescription.searchable-snapshots'
              defaultMessage='searchable snapshots'
            />
          </DocLink>
        ),
      }}
    />
  ),
  trialLimit: {
    memorySize: 4096,
    zoneCount: 2,
  },
}

export default definition
