/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { MetadataItem } from '@modules/cloud-api/v1/types'
import { updateDeploymentUrl } from '@modules/cloud-api/v1/urls'

import asyncRequest from '../asyncRequests'
import { UPDATE_DEPLOYMENT_TAGS } from '../../constants/actions'

export function updateDeploymentTags({
  deploymentId,
  tags,
}: {
  deploymentId: string
  tags: MetadataItem[]
}) {
  const payload = {
    prune_orphans: false,
    metadata: {
      tags,
    },
  }

  return asyncRequest({
    crumbs: [deploymentId],
    type: UPDATE_DEPLOYMENT_TAGS,
    method: `PUT`,
    url: updateDeploymentUrl({ deploymentId }),
    payload,
  })
}
