/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import Permission from '@modules/cloud-api/v1/permissions'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiButtonEmpty } from '@modules/cui'

import type { FunctionComponent } from 'react'

interface Props {
  regionId: string
  removeLicense: (regionId: string) => void
  removeLicenseRequest: AsyncRequestState
}

const DeleteLicense: FunctionComponent<Props> = ({
  regionId,
  removeLicense,
  removeLicenseRequest,
}) => (
  <CuiButtonEmpty
    size='s'
    color='danger'
    iconType='trash'
    onClick={() => removeLicense(regionId)}
    spin={removeLicenseRequest.inProgress}
    permissions={Permission.deleteLicense}
    confirm={true}
    confirmModalProps={{
      title: (
        <FormattedMessage
          id='license-delete.confirm-to-delete'
          defaultMessage='Confirm to delete'
        />
      ),
    }}
  >
    <FormattedMessage id='license-delete.delete-license' defaultMessage='Delete license' />
  </CuiButtonEmpty>
)

export default DeleteLicense
