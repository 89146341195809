/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { StackDeployment } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { isFeatureActivated } from '../../../selectors'

import IlmDeploymentMigrationCallout from './IlmDeploymentMigrationCallout'

type StateProps = {
  ilmMigrationFeature: boolean
}

type ConsumerProps = {
  stackDeployment?: StackDeployment | null
}

const mapStateToProps = (state): StateProps => ({
  ilmMigrationFeature: isFeatureActivated(state, Feature.ilmMigrationFeature),
})

export default connect<StateProps, unknown, ConsumerProps>(
  mapStateToProps,
  {},
)(IlmDeploymentMigrationCallout)
