/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { v4 as uuid } from 'uuid'

import type { QueryContainer } from '@modules/cloud-api/v1/types'
import type {
  ClauseConnector,
  ComplexClause,
  InnerClause,
  OuterClause,
} from '@modules/ui-types/instanceConfigurationTypes'

const CONNECTOR_AND = `and`

const CONNECTOR_OR = `or`

export function removeMalformedClauses(inputOuterClauses: OuterClause[]): OuterClause[] {
  return inputOuterClauses
    .map(sanitizeInnerClauses)
    .filter((clause) => clause.innerClauses.length > 0)
    .map(sanitizeOuterClause)

  function sanitizeInnerClauses(outerClause: OuterClause): OuterClause {
    const { connector } = outerClause

    const innerClauses = outerClause.innerClauses
      .filter((innerClause) => innerClause.key.length > 0 || innerClause.value.length > 0)
      .map(sanitizeInnerClause)

    return { ...outerClause, connector, innerClauses }
  }

  function sanitizeInnerClause(
    innerClause: InnerClause,
    innerClauseIndex: number,
    innerClauses: InnerClause[],
  ): InnerClause {
    let connector

    if (innerClauseIndex === 0) {
      connector = innerClauses.length < 2 ? `and` : innerClauses[1].connector
    } else {
      connector = innerClause.connector
    }

    return { ...innerClause, connector }
  }

  function sanitizeOuterClause(outerClause, outerClauseIndex, outerClauses) {
    let connector

    if (outerClauseIndex === 0) {
      connector = outerClauses.length < 2 ? CONNECTOR_AND : outerClauses[1].connector
    } else {
      connector = outerClause.connector
    }

    return { id: outerClause.id, connector, innerClauses: outerClause.innerClauses }
  }
}

export function validateClauses(clauses: Array<ComplexClause | OuterClause | InnerClause>): any {
  // Lots of ts-ignore markers here due to smooshing together the clause types
  return (clauses || []).reduce((errors, clause) => {
    // @ts-ignore
    const value = typeof clause.value === `string` ? clause.value : ``

    // @ts-ignore
    const key = typeof clause.key === `string` ? clause.key : ``
    const hasMeaningfulKey = key.trim() !== ``
    const hasMeaningfulValue = value.trim() !== ``

    // @ts-ignore
    const isValidClause = clause.innerClauses != null || (hasMeaningfulKey && hasMeaningfulValue)

    const keyError = hasMeaningfulKey
      ? {}
      : {
          key: `Key is required for clause with value "${value}".`,
        }

    const valueError = hasMeaningfulValue
      ? {}
      : {
          value: `Value is required for clause with key "${key}".`,
        }

    const clauseErrors = isValidClause
      ? {}
      : {
          [clause.id]: {
            fields: {
              ...keyError,
              ...valueError,
            },
          },
        }

    // @ts-ignore
    const isInnerClause = clause.innerClauses != null && Array.isArray(clause.innerClauses)

    // @ts-ignore
    const innerClausesErrors = isInnerClause ? validateClauses(clause.innerClauses) : {}

    return {
      ...errors,
      ...innerClausesErrors,
      ...clauseErrors,
    }
  }, {})
}

export function allocatorFilterToClauses(payload: QueryContainer | undefined): OuterClause[] {
  const must: QueryContainer[] | undefined = payload?.bool?.must
  const should: QueryContainer[] | undefined = payload?.bool?.should

  const payloadOuterClauses: QueryContainer[] | undefined = must || should

  if (!payloadOuterClauses) {
    return []
  }

  const outerConnector = must ? CONNECTOR_AND : CONNECTOR_OR

  const query = payloadOuterClauses.map((payloadOuterClause) => {
    const innerMust = payloadOuterClause.bool?.must
    const innerShould = payloadOuterClause.bool?.should

    const payloadInnerClauses = innerMust || innerShould

    const innerConnector = innerMust ? CONNECTOR_AND : CONNECTOR_OR
    const outerClause = makeOuterClause(outerConnector, true)

    outerClause.innerClauses =
      payloadInnerClauses?.map((payloadInnerClause) => {
        let metadataKeyValue: string = ''
        let metadataValueKeywordValue: string = ''

        for (const mustClause of payloadInnerClause.nested?.query.bool?.must ?? []) {
          let value = mustClause.term?.['metadata.key']?.value

          if (value) {
            metadataKeyValue = value
          } else {
            value = mustClause.term?.['metadata.value.keyword']?.value

            if (value) {
              metadataValueKeywordValue = value
            }
          }
        }

        return makeInnerClause(innerConnector, metadataKeyValue, metadataValueKeywordValue)
      }) ?? []
    return outerClause
  })

  return query
}

export function makeOuterClause(
  connector: ClauseConnector = CONNECTOR_OR,
  isEmpty: boolean = false,
): OuterClause {
  const innerClauses = isEmpty ? [] : [makeInnerClause()]
  return {
    id: uuid(),
    connector,
    innerClauses,
  }
}

export function makeInnerClause(
  connector: ClauseConnector = CONNECTOR_AND,
  key: string = ``,
  value: string = ``,
): InnerClause {
  return {
    id: uuid(),
    connector,
    key,
    value,
  }
}
