/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  ElasticsearchBlockingIssueElement,
  ElasticsearchResourceInfo,
} from '@modules/cloud-api/v1/types'

export function hasBlockingIssues({ resource }: { resource: ElasticsearchResourceInfo }): boolean {
  const { cluster_blocking_issues: blockingIssues } = resource.info.elasticsearch

  if (!blockingIssues) {
    return false
  }

  return blockingIssues.healthy === false
}

export function getBlockingIssues({
  resource,
  type,
}: {
  resource: ElasticsearchResourceInfo
  type: 'index' | 'global'
}): ElasticsearchBlockingIssueElement[] {
  if (!resource.info.elasticsearch.cluster_blocking_issues) {
    return [] as ElasticsearchBlockingIssueElement[]
  }

  const { blocks } = resource.info.elasticsearch.cluster_blocking_issues
  return blocks.filter((block) => block.level === type)
}
