/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useContext } from 'react'

import type { CloudAppConfig } from '@modules/ui-types'

// Manual cast here because the provider will always initialise, but TS doesn't know that.
export const ConfigContext = React.createContext<CloudAppConfig>({} as CloudAppConfig)

/**
 * Hook for exposing config entries through context.
 */
export const useConfig = <K extends keyof CloudAppConfig>(key: K) =>
  // Excluding undefined as CloudAppConfig includes a Partial which inserts
  // `undefined` as a valid value (at time of writing; this may change or we may
  // flip the flag that distinguishes between undefined and optional at some
  // point, so don't *assume* this is still true). This is also predicated on
  // nullish values in config being actual `null` and not `undefined`.
  useContext(ConfigContext)[key] as Exclude<CloudAppConfig[K], undefined>
