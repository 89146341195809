/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { retryPastDueInvoicesUrl } from '@modules/cloud-api/v1/urls'

import { ThunkAction } from '@/types/redux'
import asyncRequest, { resetAsyncRequest } from '@/actions/asyncRequests'

import { RETRY_PAST_DUE_INVOICES } from '../constants/actions'

export function retryPastDueInvoices(organizationId: string): ThunkAction<void> {
  const url = retryPastDueInvoicesUrl({ organizationId })

  return asyncRequest({
    method: `POST`,
    type: RETRY_PAST_DUE_INVOICES,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}

export const resetRetryPastDueInvoicesRequest = (...crumbs: string[]) =>
  resetAsyncRequest(RETRY_PAST_DUE_INVOICES, crumbs)
