/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiSkeletonText,
  EuiBadge,
  EuiText,
} from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import { CuiDate } from '@modules/cui'

import { isPrepaidConsumptionDirectResellerCustomer } from '@/lib/billingDetails'
import prettySize from '@/lib/prettySize'
import PageSection from '@/components/PageSection'
import { getDeploymentMemoryCapacity, isEsStopped } from '@/lib/stackDeployments/selectors'
import OrganizationDomain from '@/components/Organization/OrganizationOverview/OrganizationDomain'
import OrganizationSubscriptionLevelBadge from '@/components/Organization/OrganizationOverview/OrganizationSubscriptionLevelBadge'
import OrganizationCapacityUsageToolTipIcon from '@/components/Organization/OrganizationOverview/OrganizationCapacityUsageToolTipIcon'
import OrganizationKpiDashboardLink from '@/components/Organization/OrganizationOverview/OrganizationKpiDashboardLink'
import OrganizationTrafficLogLink from '@/components/Organization/OrganizationOverview/OrganizationTrafficLogLink'
import OrganizationFeatures from '@/components/Organization/OrganizationOverview/OrganizationFeatures'
import EnableDiskNotificationsButton from '@/components/Organization/OrganizationOverview/EnableDiskNotificationsButton'
import EnsurePremium from '@/components/Organization/OrganizationOverview/EnsurePremium'
import OrganizationTrials from '@/components/Organization/OrganizationOverview/OrganizationTrials'
import ConvertOrganizationToInvoicingButton from '@/components/Organization/OrganizationOverview/ConvertOrganizationToInvoicingButton'
import RetryInvoicesButton from '@/components/Organization/OrganizationOverview/RetryInvoicesButton'
import RegenerateInvoicesButton from '@/components/Organization/OrganizationOverview/RegenerateInvoicesButton'
import CreditAccountButton from '@/components/Organization/OrganizationOverview/CreditAccountButton'
import ConvertResellerToDirectButton from '@/components/Organization/OrganizationOverview/ConvertResellerToDirectButton'
import ContactList from '@/components/Organization/OrganizationOverview/ContactList'
import MarketplaceInfo from '@/components/Organization/OrganizationOverview/MarketplaceInformation'
import BillingDetails from '@/components/Organization/OrganizationOverview/BillingDetails'
import AllowedDomains from '@/components/Organization/AllowedDomains'
import DocLink from '@/components/DocLink'

import AllowExtraVersionsStatus from './AllowExtraVersions'

import type { ReactChild, ReactElement } from 'react'
import type { AllProps as Props } from './types'

class OrganizationOverview extends Component<Props> {
  componentDidMount(): void {
    const isAzureOrganization = this.props.saasOrganization.organization.domain === 'azure'

    this.props.searchOrganizationDeployments()

    if (isAzureOrganization) {
      this.props.fetchMarketplaceBillingDetails()
    }
  }

  render(): ReactElement {
    return (
      <EuiFlexGroup gutterSize='xl' justifyContent='spaceBetween'>
        {this.renderLeftHandSide()}
        {this.renderRightHandSide()}
      </EuiFlexGroup>
    )
  }

  renderLeftHandSide(): ReactElement {
    return (
      <EuiFlexItem grow={false}>
        <div style={{ maxWidth: `500px` }}>
          {this.renderOrganizationSection()}
          {this.renderHardwareSection()}
          {this.renderContactsSection()}
          {this.renderDashboardsSection()}
          {this.renderBypassVersionChecks()}
          {this.renderAllowedDomains()}
        </div>
      </EuiFlexItem>
    )
  }

  renderRightHandSide(): ReactElement {
    const { marketplaceBillingDetails, fetchMarketplaceBillingDetailsRequest } = this.props

    return (
      <EuiFlexItem grow={false}>
        <div style={{ maxWidth: `500px` }}>
          {this.renderSubscriptionSection()}
          {this.renderTrialsSection()}
          {this.renderFeaturesSection()}
          <MarketplaceInfo
            saasOrganization={this.props.saasOrganization}
            marketplaceBillingDetails={marketplaceBillingDetails}
            isLoading={fetchMarketplaceBillingDetailsRequest.inProgress}
          />
          <BillingDetails />
        </div>
      </EuiFlexItem>
    )
  }

  renderOrganizationSection(): ReactElement {
    const {
      saasOrganization: {
        organization: { created, domain, organization_id: organizationId },
      },
    } = this.props

    return (
      <PageSection
        iconType='users'
        title={
          <FormattedMessage
            id='organization.organization-overview.organization-section'
            defaultMessage='Organization'
          />
        }
        flexItems={this.renderOrganizationSectionHeaderBadges()}
      >
        <EuiDescriptionList
          compressed={true}
          type='responsiveColumn'
          listItems={[
            {
              title: (
                <FormattedMessage
                  id='organization.organization-overview.organization-id-label'
                  defaultMessage='Organization ID'
                />
              ),
              description: organizationId,
            },
            {
              title: (
                <FormattedMessage
                  id='organization.organization-overview.created-on'
                  defaultMessage='Created On'
                />
              ),
              description: <CuiDate date={created} />,
            },
            {
              title: (
                <FormattedMessage
                  id='organization.organization-overview.domain-label'
                  defaultMessage='Domain'
                />
              ),
              description: <OrganizationDomain domain={domain} />,
            },
          ]}
        />
      </PageSection>
    )
  }

  renderOrganizationSectionHeaderBadges(): ReactElement {
    const {
      saasOrganization: {
        subscription: { is_paying: isPaying },
      },
    } = this.props

    return (
      <EuiFlexItem grow={false}>
        {isPaying ? (
          <EuiBadge color='success' iconType='check'>
            <FormattedMessage
              id='organization.organization-overview.paying'
              defaultMessage='Paying'
              data-test-id='paying'
            />
          </EuiBadge>
        ) : (
          <EuiBadge color='danger' iconType='cross'>
            <FormattedMessage
              id='organization.organization-overview.not-paying'
              defaultMessage='Not paying'
            />
          </EuiBadge>
        )}
      </EuiFlexItem>
    )
  }

  renderHardwareSection(): ReactElement {
    return (
      <PageSection
        iconType='storage'
        title={
          <FormattedMessage
            id='organization.organization-overview.hardware-section'
            defaultMessage='Hardware allocation'
          />
        }
      >
        <EuiDescriptionList
          compressed={true}
          type='responsiveColumn'
          listItems={[
            {
              title: (
                <FormattedMessage
                  id='organization.organization-overviewcapacity-limit-label'
                  defaultMessage='Account capacity limit'
                />
              ),
              description: (
                <FormattedMessage
                  id='organization.organization-overview.capacity-limit-description'
                  defaultMessage='Unlimited'
                />
              ),
            },
            {
              title: (
                <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
                  <EuiFlexItem grow={false}>
                    <FormattedMessage
                      id='organization.organization-overview.estimated-allocation-label'
                      defaultMessage='Estimated allocation'
                    />
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <OrganizationCapacityUsageToolTipIcon />
                  </EuiFlexItem>
                </EuiFlexGroup>
              ),
              description: this.renderCapacityUsage(),
            },
          ]}
        />
      </PageSection>
    )
  }

  renderCapacityUsage(): ReactChild {
    const { deploymentsSearchResults } = this.props

    if (!deploymentsSearchResults) {
      return <EuiSkeletonText lines={1} />
    }

    const { deployments, match_count, return_count } = deploymentsSearchResults

    /* [1] We aggregate the currently used instance capacity.
     *
     *     Note that this is already incorrect:
     *     we should rely on allocated capacity, not healthy capacity.
     *
     *     At the same time, we only get back 150 search results, so we
     *     might not even be aggregating all healthy capacity either!
     *
     * [2] To accommodate for that second case, we calculate the percentage
     *      of matches we're not taking into account.
     *
     * [3] And we add that much usage to our admirably rough estimate.
     *
     * For instance:
     *   A customer like IBM might have 1500 deployments.
     *   Their first 150 clusters might have around 1.5 TB of healthy capacity.
     *   Given their first 150 clusters use 1.5 TB of healthy capacity, we estimate
     *   that the remaining 1350 clusters use another 13.5 TB of healthy capacity.
     *   That gives us a total of 15 TB of healthy capacity being predicted as used by IBM.
     */

    const usedCapacity = deployments.reduce(aggregateCapacity, 0) // [1]

    const matchPercentage = match_count // [2]
      ? return_count / match_count
      : 0

    const estimatedUsage = matchPercentage // [3]
      ? usedCapacity / matchPercentage
      : usedCapacity

    return prettySize(estimatedUsage)

    function aggregateCapacity(total: number, deployment: DeploymentSearchResponse) {
      return total + getDeploymentMemoryCapacity({ deployment })
    }
  }

  renderContactsSection(): ReactElement {
    const {
      saasOrganization: {
        organization: { organization_id: organizationId, data },
      },
    } = this.props

    // disk notifications are enabled by default, and this field only gets set once the user explicitly makes a change
    // @ts-ignore the API types are useless here
    const diskNotificationsEnabled = data?.user_warning?.disk?.enabled ?? true

    return (
      <PageSection
        iconType='email'
        title={
          <FormattedMessage
            id='organization.organization-overview.contacts-section'
            defaultMessage='Contacts and notifications'
          />
        }
      >
        <EuiFlexGroup gutterSize='m' alignItems='flexStart'>
          <EuiFlexItem>
            <ContactList
              data-test-id='billing-contacts'
              label={
                <FormattedMessage
                  id='organization.organization-overview.billing-contacts'
                  defaultMessage='Billing contacts'
                />
              }
              contacts={
                // @ts-ignore the API types are useless here
                data?.notifications?.billing
              }
            />
          </EuiFlexItem>

          <EuiFlexItem>
            <ContactList
              data-test-id='operational-contacts'
              label={
                <FormattedMessage
                  id='organization.organization-overview.operational-contacts'
                  defaultMessage='Operational contacts'
                />
              }
              contacts={
                // @ts-ignore the API types are useless here
                data?.notifications?.operational
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <EnableDiskNotificationsButton
          organizationId={organizationId}
          enabled={diskNotificationsEnabled}
        />
      </PageSection>
    )
  }

  renderDashboardsSection(): ReactElement {
    const {
      saasOrganization: {
        organization: { organization_id: organizationId },
      },
    } = this.props

    return (
      <PageSection
        iconType='logoKibana'
        title={
          <FormattedMessage
            id='organization.organization-overview.kibana-section'
            defaultMessage='Kibana dashboards'
          />
        }
      >
        <OrganizationKpiDashboardLink organizationId={organizationId} isActive={this.isActive()} />

        <EuiSpacer size='l' />

        <OrganizationTrafficLogLink organizationId={organizationId} />
      </PageSection>
    )
  }

  renderSubscriptionSection(): ReactElement {
    return (
      <PageSection
        iconType='package'
        title={
          <FormattedMessage
            id='organization.organization-overview.subscription-label'
            defaultMessage='Subscription'
          />
        }
        flexItems={this.renderSubscriptionSectionHeaderBadges()}
      >
        {this.renderSubscriptionLevelRadios()}

        {this.renderSubscriptionActions()}
      </PageSection>
    )
  }

  renderSubscriptionSectionHeaderBadges(): ReactElement {
    const {
      saasOrganization: {
        subscription: { level },
      },
    } = this.props

    return (
      <EuiFlexItem grow={false}>
        <OrganizationSubscriptionLevelBadge level={level} />
      </EuiFlexItem>
    )
  }

  renderSubscriptionLevelRadios(): ReactElement {
    const {
      saasOrganization: {
        organization: { organization_id: organizationId },
        subscription: { level },
      },
    } = this.props

    return <EnsurePremium organizationId={organizationId} level={level} />
  }

  renderSubscriptionActions(): ReactElement | null {
    const {
      enableInvoiceAdminActions,
      saasOrganization: {
        organization: { organization_id: organizationId },
        subscription: { invoicable },
      },
      billingDetails,
    } = this.props
    const shouldShowConvertToDirectButton =
      billingDetails.data && isPrepaidConsumptionDirectResellerCustomer(billingDetails.data)

    if (!enableInvoiceAdminActions) {
      return null
    }

    const actions: ReactElement[] = []

    if (shouldShowConvertToDirectButton) {
      actions.push(<ConvertResellerToDirectButton organizationId={organizationId} />)
    }

    if (!invoicable) {
      actions.push(<ConvertOrganizationToInvoicingButton organizationId={organizationId} />)
      actions.push(<RetryInvoicesButton organizationId={organizationId} />)
    }

    actions.push(<RegenerateInvoicesButton organizationId={organizationId} />)
    actions.push(<CreditAccountButton organizationId={organizationId} />)

    return (
      <Fragment>
        <EuiSpacer size='xl' />

        {actions.map((action, index) => (
          <Fragment key={index}>
            {action}
            <EuiSpacer size='s' />
          </Fragment>
        ))}
      </Fragment>
    )
  }

  renderTrialsSection(): ReactElement {
    return <OrganizationTrials saasOrganization={this.props.saasOrganization} />
  }

  renderFeaturesSection(): ReactElement {
    const { saasOrganization } = this.props

    return (
      <PageSection
        iconType='heart'
        title={
          <FormattedMessage
            id='organization.organization-overview.features-label'
            defaultMessage='Features'
          />
        }
      >
        <OrganizationFeatures saasOrganization={saasOrganization} />
      </PageSection>
    )
  }

  renderBypassVersionChecks(): ReactElement {
    const {
      saasOrganization: {
        organization: { organization_id: organizationId },
      },
    } = this.props

    return (
      <PageSection
        iconType='storage'
        title={
          <FormattedMessage
            id='organization.organization-overview.versino-bypass-section'
            defaultMessage='Version Bypass'
          />
        }
      >
        <AllowExtraVersionsStatus organizationId={organizationId} />
      </PageSection>
    )
  }

  renderAllowedDomains(): ReactElement {
    const {
      saasOrganization: {
        organization: { organization_id: organizationId },
      },
    } = this.props

    return (
      <PageSection
        iconType='storage'
        title={
          <FormattedMessage
            id='organization.organization-overview.allowed-domains-section'
            defaultMessage='Allowed Domains'
          />
        }
      >
        <EuiText size='s'>
          <FormattedMessage
            id='organization.organization-overview.allowed-domains-section.description'
            defaultMessage='Provide the list of domains that are allowed to receive email notifications from Elastic Cloud deployments. Allowlists
            are supported for deployments on versions 7.17.7 and higher, and deployments on versions 8.5.1 and higher. After saving your changes,
            run a configuration change on each deployment to apply the new allowlist. <link>Learn More</link>'
            values={{
              link: (content) => <DocLink link='organizationsAllowedDomains'>{content}</DocLink>,
            }}
          />
        </EuiText>

        <EuiSpacer />

        {organizationId && <AllowedDomains organizationId={organizationId} />}
      </PageSection>
    )
  }

  isActive(): boolean {
    const { deploymentsSearchResults } = this.props

    if (!deploymentsSearchResults) {
      return false
    }

    return deploymentsSearchResults.deployments.some((deployment) => !isEsStopped({ deployment }))
  }
}

export default OrganizationOverview
