/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonIcon, EuiPopover, EuiText } from '@elastic/eui'

import DocLink from '../../../../../../DocLink'

import type { FunctionComponent } from 'react'

const MLAutoscalingInfo: FunctionComponent = () => {
  const [isPopoverOpen, setPopoverOpen] = useState(false)

  const button = (
    <EuiButtonIcon
      onClick={() => setPopoverOpen(true)}
      iconType='iInCircle'
      color='text'
      aria-label='Info'
    />
  )

  return (
    <EuiPopover
      anchorPosition='upCenter'
      button={button}
      isOpen={isPopoverOpen}
      closePopover={() => setPopoverOpen(false)}
    >
      <EuiText>
        <FormattedMessage
          defaultMessage='Autoscaling uses base vCPU values when determining size for meeting usage needs. {cpuAllocation}'
          id='ml-autoscaling-popover-message'
          values={{
            cpuAllocation: (
              <DocLink link='vcpuBoost'>
                <FormattedMessage
                  id='vcpuInfo.learnMoreAbout'
                  defaultMessage='Learn more about base and boosted vCPU values.'
                />
              </DocLink>
            ),
          }}
        />
      </EuiText>
    </EuiPopover>
  )
}

export default MLAutoscalingInfo
