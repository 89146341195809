/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { memoize } from 'lodash'

import type { ContainerInstance } from '@modules/cloud-api/v1/types'
import type { FilterSchema } from '@modules/cui'

export const schema: FilterSchema = {
  strict: true,
  fields: {
    runner: {
      type: `string`,
    },
    status: {
      type: `string`,
    },
    image: {
      type: `string`,
    },
    env: {
      type: `string`,
    },
  },
}

export const defaultFields = [`runner`, `image`, `env`]

export const getQueryModel = memoize(getQueryModelImpl)

function getQueryModelImpl(containerInstance: ContainerInstance) {
  return {
    runner: containerInstance.runner_id,
    status: (containerInstance.inspect as any)?.State?.Status,
    image: (containerInstance.inspect as any)?.Config?.Image,
    env: (containerInstance.inspect as any)?.Config?.Env?.join(`\n`),
  }
}
