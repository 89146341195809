/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { MessageDescriptor, IntlShape } from 'react-intl'

type ModalMessages = {
  title: MessageDescriptor
  body: MessageDescriptor
  confirm: MessageDescriptor
  cancel: MessageDescriptor
}

type ToastMessages = {
  success: MessageDescriptor
  failure: MessageDescriptor
}

type Messages = {
  modal: {
    currentMember: ModalMessages
    lastMember: { body: MessageDescriptor }
    invitation: ModalMessages & { cancel: MessageDescriptor }
    member: ModalMessages
  }
  toast: {
    currentMember: ToastMessages
    invitation: ToastMessages
    member: ToastMessages
  }
}

export type MessageValues = {
  organizationName: string
  email: string
}

const messages: Messages = {
  modal: {
    currentMember: defineMessages({
      title: {
        id: `organization.organization-members.remove-member-button.leave-organization-x`,
        defaultMessage: `Leave organization {organizationName}?`,
      },
      body: {
        id: `organization.organization-members.remove-member-button.leave-organization-body`,
        defaultMessage: `When you leave the organization, you will no longer be able to access it. Other members can reinvite you.`,
      },
      confirm: {
        id: `organization.organization-members.remove-member-button.leave-organization`,
        defaultMessage: `Leave organization`,
      },
      cancel: {
        id: `organization.organization-members.remove-member-button.cancel-leave-organization`,
        defaultMessage: `Cancel`,
      },
    }),
    lastMember: defineMessages({
      body: {
        id: `organization.organization-members.remove-member-button.last-member-body`,
        defaultMessage: `You are the last member in the organization. If you leave, you will lose access to snapshots, pre-paid credits, billing history, and won't be able to get reinvited. Are you sure you want to leave this organization?`,
      },
    }),
    invitation: defineMessages({
      title: {
        id: `organization.organization-members.remove-member-button.revoke-invite-title`,
        defaultMessage: `Revoke invite?`,
      },
      body: {
        id: `organization.organization-members.remove-member-button.revoke-invite-body`,
        defaultMessage: `If you revoke this invite, {email} will no longer be able to join this organization unless they receive a new invite.`,
      },
      confirm: {
        id: `organization.organization-members.remove-member-button.revoke-invite`,
        defaultMessage: `Revoke invite`,
      },
      cancel: {
        id: `organization.organization-members.remove-member-button.keep-invite`,
        defaultMessage: `Keep invite`,
      },
    }),
    member: defineMessages({
      title: {
        id: `organization.organization-members.remove-member-button.remove-x`,
        defaultMessage: `Remove {email}?`,
      },
      body: {
        id: `organization.organization-members.remove-member-button.remove-member-body`,
        defaultMessage: `Once removed, {email} will no longer have access to this organization and its resources.`,
      },
      confirm: {
        id: `organization.organization-members.remove-member-button.remove-member`,
        defaultMessage: `Remove member`,
      },
      cancel: {
        id: `organization.organization-members.remove-member-button.cancel-remove-member`,
        defaultMessage: `Cancel`,
      },
    }),
  },
  toast: {
    currentMember: defineMessages({
      success: {
        id: `organization.organization-members.remove-member-button.current-member-success`,
        defaultMessage: `You left organization {organizationName}`,
      },
      failure: {
        id: `organization.organization-members.remove-member-button.current-member-failure`,
        defaultMessage: `You cannot leave the organization`,
      },
    }),
    invitation: defineMessages({
      success: {
        id: `organization.organization-members.remove-member-button.invite-success`,
        defaultMessage: `{email}'s invite has been revoked`,
      },
      failure: {
        id: `organization.organization-members.remove-member-button.invite-failure`,
        defaultMessage: `Could not revoke {email}'s invite`,
      },
    }),
    member: defineMessages({
      success: {
        id: `organization.organization-members.remove-member-button.member-success`,
        defaultMessage: `{email} has been removed`,
      },
      failure: {
        id: `organization.organization-members.remove-member-button.member-failure`,
        defaultMessage: `Could not remove {email}`,
      },
    }),
  },
}

export const errors = defineMessages({
  hasDeployments: {
    id: 'organization.organization-members.errors.has-deployments',
    defaultMessage:
      'To leave organization {organizationName}, you have to delete all your deployments and pay all pending invoices.',
  },
})

export const formatMessageWithValues = ({
  intl: { formatMessage },
  message,
  values,
}: {
  intl: IntlShape
  message: MessageDescriptor
  values: MessageValues
}): string => formatMessage(message, { ...values })

export default messages
