/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  acceptOrganizationInvitationUrl,
  addRoleAssignmentsUrl,
  createOrganizationInvitationsUrl,
  createOrganizationMembershipUrl,
  createOrganizationUrl,
  deleteOrganizationInvitationsUrl,
  deleteOrganizationMembershipsUrl,
  getOrganizationInvitationUrl,
  getOrganizationUrl,
  listOrganizationInvitationsUrl,
  listOrganizationMembersUrl,
  listOrganizationsUrl,
  patchOrganizationUrl,
  refreshSaasCurrentUserUrl,
  removeRoleAssignmentsUrl,
} from '@modules/cloud-api/v1/urls'
import type {
  Organization,
  OrganizationInvitationRequest,
  OrganizationMembershipRequest,
  OrganizationRequest,
  RoleAssignments,
} from '@modules/cloud-api/v1/types'

import { ThunkDispatch, ThunkAction } from '@/types/redux'
import { fetchProfile } from '@/apps/userconsole/actions/profile'
import {
  ACCEPT_ORGANIZATION_INVITATION,
  ADD_ORGANIZATION_MEMBER,
  ADD_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION_INVITATIONS,
  DELETE_ORGANIZATION_MEMBERSHIPS,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_INVITATION,
  FETCH_ORGANIZATION_INVITATIONS,
  FETCH_ORGANIZATION_MEMBERSHIPS,
  FETCH_ORGANIZATIONS,
  REFRESH_SAAS_CURRENT_USER,
  UPDATE_ORGANIZATION,
  UPSERT_ORGANIZATION_INVITATION,
  UPDATE_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS,
} from '@/constants/actions'
import { fetchOrganizationRequest, getOrganization } from '@/reducers'
import asyncRequest, { resetAsyncRequest } from '@/actions/asyncRequests'

import { fetchAuthzRoles } from '../authzRoles'

import type { RoleAssignmentsDiff } from '@/components/Users/RoleAssignmentsPanel/lib'

export function fetchOrganizations({ organizationName }: { organizationName: string }) {
  const url = listOrganizationsUrl({ q: `name:${organizationName}`, limit: 20 })

  return asyncRequest({
    type: FETCH_ORGANIZATIONS,
    method: `GET`,
    url,
  })
}

export function fetchOrganization({ organizationId }: { organizationId: string }) {
  const url = getOrganizationUrl({ organizationId })

  return asyncRequest({
    type: FETCH_ORGANIZATION,
    method: `GET`,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}

export function refreshSaasCurrentUser() {
  const url = refreshSaasCurrentUserUrl()

  return asyncRequest({
    type: REFRESH_SAAS_CURRENT_USER,
    method: `POST`,
    url,
  })
}

function shouldFetchOrganization(state, organizationId: string) {
  const organizationRequest = fetchOrganizationRequest(state, organizationId)

  if (organizationRequest.inProgress) {
    return false
  }

  return !getOrganization(state, organizationId)
}

export function fetchOrganizationIfNeeded({ organizationId }: { organizationId: string }) {
  return (dispatch, getState) => {
    if (shouldFetchOrganization(getState(), organizationId)) {
      return dispatch(fetchOrganization({ organizationId }))
    }

    return Promise.resolve()
  }
}

export function updateOrganization({
  organizationId,
  organization,
}: {
  organizationId: string
  organization: OrganizationRequest
}) {
  const url = patchOrganizationUrl({ organizationId })

  return asyncRequest({
    type: UPDATE_ORGANIZATION,
    method: `PATCH`,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
    payload: organization,
  })
}

export function _createOrganization({
  name,
  requestSettings,
}: {
  name?: string
  requestSettings?: any
}) {
  const url = createOrganizationUrl()

  const payload: OrganizationRequest = {
    name,
  }

  return (dispatch: ThunkDispatch) =>
    dispatch(
      asyncRequest<typeof CREATE_ORGANIZATION, Organization>({
        type: CREATE_ORGANIZATION,
        method: `POST`,
        url,
        payload,
        requestSettings,
      }),
    )
}

export function createOrganization({ name }: { name?: string }): ThunkAction {
  const url = createOrganizationUrl()

  const payload: OrganizationRequest = {
    name,
  }

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: CREATE_ORGANIZATION,
        method: `POST`,
        url,
        payload,
      }),
    )
      .then(() => dispatch(refreshSaasCurrentUser()))
      .then(() => dispatch(fetchAuthzRoles()))
      .then(() => dispatch(fetchProfile()))
}

export function acceptOrganizationInvitation({
  invitationToken,
}: {
  invitationToken: string
}): ThunkAction {
  const url = acceptOrganizationInvitationUrl({ invitationToken })

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: ACCEPT_ORGANIZATION_INVITATION,
        method: `POST`,
        url,
        meta: { invitationToken },
        crumbs: [invitationToken],
      }),
    )
      .then(() => dispatch(refreshSaasCurrentUser()))
      .then(() => dispatch(fetchAuthzRoles()))
      .then(() => dispatch(fetchProfile()))
}

export function fetchOrganizationInvitation({ invitationToken }: { invitationToken: string }) {
  const url = getOrganizationInvitationUrl({ invitationToken })

  return asyncRequest({
    type: FETCH_ORGANIZATION_INVITATION,
    method: `GET`,
    url,
    meta: { invitationToken },
    crumbs: [invitationToken],
  })
}

export function fetchOrganizationInvitations({ organizationId }: { organizationId: string }) {
  const url = listOrganizationInvitationsUrl({ organizationId })

  return asyncRequest({
    type: FETCH_ORGANIZATION_INVITATIONS,
    method: `GET`,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}

export function upsertOrganizationInvitations({
  organizationId,
  emails,
  roleAssignments,
}: {
  organizationId: string
  emails: string[]
  roleAssignments?: RoleAssignments
}) {
  const url = createOrganizationInvitationsUrl({ organizationId })

  const payload: OrganizationInvitationRequest = {
    emails,
    role_assignments: roleAssignments,
  }

  return asyncRequest({
    type: UPSERT_ORGANIZATION_INVITATION,
    method: `POST`,
    url,
    meta: { organizationId, emails, roleAssignments },
    crumbs: [organizationId, emails.join(',')],
    payload,
  })
}

export function deleteOrganizationInvitations({
  organizationId,
  tokens,
}: {
  organizationId: string
  tokens: string[]
}) {
  const concatenatedTokens = tokens.join(',')

  const url = deleteOrganizationInvitationsUrl({
    organizationId,
    invitationTokens: concatenatedTokens,
  })

  return asyncRequest({
    type: DELETE_ORGANIZATION_INVITATIONS,
    method: `DELETE`,
    url,
    meta: { organizationId, tokens },
    crumbs: [organizationId, concatenatedTokens],
  })
}

export function fetchOrganizationMemberships({ organizationId }: { organizationId: string }) {
  const url = listOrganizationMembersUrl({ organizationId })

  return asyncRequest({
    type: FETCH_ORGANIZATION_MEMBERSHIPS,
    method: `GET`,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}

export function deleteOrganizationMemberships({
  organizationId,
  userIds,
}: {
  organizationId: string
  userIds: string[]
}) {
  const concatenatedUserIds = userIds.join(',')
  const url = deleteOrganizationMembershipsUrl({ organizationId, userIds: concatenatedUserIds })

  return asyncRequest({
    type: DELETE_ORGANIZATION_MEMBERSHIPS,
    method: `DELETE`,
    url,
    meta: { organizationId, userIds },
    crumbs: [organizationId, concatenatedUserIds],
  })
}

export function addOrganizationMember({
  userId,
  organizationId,
}: {
  userId: string
  organizationId: string
}): ThunkAction {
  const url = createOrganizationMembershipUrl({ organizationId })

  const payload: OrganizationMembershipRequest = {
    user_id: userId,
  }

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: ADD_ORGANIZATION_MEMBER,
        method: `POST`,
        url,
        payload,
      }),
    )
}

export function updateOrganizationMemberRoleAssignments({
  organizationId,
  userId,
  roleAssignments,
  roleAssignmentsDiff,
}: {
  organizationId: string
  userId: string
  roleAssignments: RoleAssignments
  roleAssignmentsDiff: RoleAssignmentsDiff
}): ThunkAction {
  const addUrl = addRoleAssignmentsUrl({ userId })
  const removeUrl = removeRoleAssignmentsUrl({ userId })

  const { added, removed } = roleAssignmentsDiff

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: ADD_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS,
        method: `POST`,
        url: addUrl,
        payload: added,
      }),
    ).then(() =>
      dispatch(
        asyncRequest({
          type: UPDATE_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS,
          method: `DELETE`,
          url: removeUrl,
          meta: { organizationId, userId, roleAssignments },
          crumbs: [organizationId, userId],
          payload: removed,
        }),
      ),
    )
}

export const resetFetchOrganizationsRequest = (...crumbs: string[]) =>
  resetAsyncRequest(FETCH_ORGANIZATIONS, crumbs)

export const resetFetchOrganizationRequest = (...crumbs: string[]) =>
  resetAsyncRequest(FETCH_ORGANIZATION, crumbs)

export const resetAcceptOrganizationInvitationRequest = (...crumbs: string[]) =>
  resetAsyncRequest(ACCEPT_ORGANIZATION_INVITATION, crumbs)

export const resetUpsertOrganizationInvitationsRequest = (...crumbs: string[]) =>
  resetAsyncRequest(UPSERT_ORGANIZATION_INVITATION, crumbs)

export const resetUpdateOrganizationRequest = (...crumbs: string[]) =>
  resetAsyncRequest(UPDATE_ORGANIZATION, crumbs)

export const resetUpdateOrganizationMemberRoleAssignmentRequest = (...crumbs: string[]) =>
  resetAsyncRequest(UPDATE_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS, crumbs)
