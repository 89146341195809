/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Fragment, PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { capitalize } from 'lodash'
import { css, jsx } from '@emotion/react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import type { WithEuiThemeProps } from '@elastic/eui'
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButtonEmpty,
  EuiCallOut,
  EuiSpacer,
  EuiStat,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  withEuiTheme,
  EuiPopoverTitle,
  EuiLink,
  EuiImage,
} from '@elastic/eui'

import { CuiHelpTipIcon, CuiLink } from '@modules/cui'
import CuiPrice from '@modules/cui/formatters/CuiPrice'

import applyCreditIcon from '@/files/apply-credit.svg'
import { pricingUrl, externalPricing } from '@/apps/userconsole/urls'
import { isPrepaidConsumptionDirectResellerCustomer } from '@/lib/billingDetails'
import SelectSubscriptionBody from '@/apps/userconsole/components/Billing/SelectSubscription/SelectSubscriptionBody'
import { defaultSubscriptionLevelByUser } from '@/constants/billing'
import ExternalLink from '@/components/ExternalLink'
import { getHourlyRateForLevel } from '@/lib/billing'
import { supportUrl } from '@/lib/urlBuilder'

import { ModalCss, ModalTitleCss } from './styles'

import type { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer'
import type { ReactElement } from 'react'
import type { AllProps, AvailableBillingSubscriptionLevel } from './types'
import type { Subscription } from '@/apps/userconsole/components/Billing/types'
import type { WrappedComponentProps } from 'react-intl'

const formId = 'prepaid-credit-activation-form'

interface State {
  selectedSubscription: AvailableBillingSubscriptionLevel
  showChangeSubscriptionModal: boolean
}

type Props = AllProps & WithEuiThemeProps & WrappedComponentProps & LDProps

class PrepaidCreditActivationModal extends PureComponent<Props, State> {
  state: State = {
    selectedSubscription: this.getDefaultLevel(),
    showChangeSubscriptionModal: false,
  }

  componentDidMount() {
    const {
      fetchAccountActivity,
      profile: { organization_id },
    } = this.props

    if (organization_id) {
      fetchAccountActivity(organization_id)
    }
  }

  render(): ReactElement {
    const {
      onClose,
      onSubmit,
      onCodeChange,
      isLoading,
      isSubmitted,
      inputError,
      generalError,
      theme,
      intl: { formatMessage },
    } = this.props
    const { showChangeSubscriptionModal, selectedSubscription } = this.state

    if (showChangeSubscriptionModal) {
      return (
        <EuiModal
          onClose={onClose}
          css={ModalCss({
            theme,
            changeSubscriptionModal: showChangeSubscriptionModal,
            isModalAnimating: false,
          })}
          maxWidth={false}
        >
          <SelectSubscriptionBody
            selectedSubscription={selectedSubscription}
            onSelectSubscription={this.onSelectSubscription}
            disabledSubscriptions={['standard']}
          />
          <EuiModalFooter>
            <div>
              <EuiFlexGroup justifyContent='flexEnd'>
                <EuiFlexItem>
                  <EuiButton
                    fullWidth={false}
                    data-test-id='save-subscription'
                    onClick={this.closeChangeSubscriptionModal}
                    fill={true}
                  >
                    <FormattedMessage id='update-subscription.save' defaultMessage='Save' />
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiModalFooter>
        </EuiModal>
      )
    }

    return (
      <EuiModal
        onClose={onClose}
        css={ModalCss({
          theme,
          changeSubscriptionModal: showChangeSubscriptionModal,
          isModalAnimating: isSubmitted,
        })}
      >
        <EuiImage
          size={200}
          css={css({ marginTop: 20 })}
          src={applyCreditIcon}
          alt={formatMessage({
            id: 'prepaid-credit-modal.add-ecu-credits-illustration',
            defaultMessage: 'Add ECU credits illustration',
          })}
        />
        <EuiModalHeader>
          <EuiModalHeaderTitle css={css(ModalTitleCss)} data-test-id='modalTitle'>
            {this.getTitle()}
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody data-test-id='modalBody'>
          <EuiForm
            id={formId}
            component='form'
            onSubmit={(event) => {
              event.preventDefault()
              const data = new FormData(event.target as HTMLFormElement)
              const activation_code = data.get('activationCode') as string

              onSubmit({ activation_code, subscription_level: selectedSubscription })
            }}
          >
            <EuiFormRow
              isInvalid={Boolean(inputError)}
              error={inputError}
              fullWidth={true}
              label={
                <FormattedMessage
                  id='prepaid-credit-modal.label'
                  defaultMessage='Activation code'
                />
              }
            >
              <EuiFieldText
                name='activationCode'
                placeholder='XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'
                fullWidth={true}
                isInvalid={Boolean(inputError)}
                isLoading={isLoading}
                disabled={isLoading || isSubmitted}
                onChange={onCodeChange}
                data-test-id='activationCodeTextField'
              />
            </EuiFormRow>
          </EuiForm>

          {generalError && this.renderGeneralError()}

          <EuiSpacer size='l' />
          {this.renderNotificationMessage()}
          <EuiSpacer size='m' />

          {this.renderSelectSubscription()}
        </EuiModalBody>

        <EuiModalFooter>{this.getFooter()}</EuiModalFooter>
      </EuiModal>
    )
  }

  renderNotificationMessage = (): JSX.Element => {
    const { profile } = this.props

    if (profile?.level === 'standard') {
      return (
        <EuiText size='xs' color='warning'>
          <FormattedMessage
            id='prepaid-credit-modal.incompatible-message'
            defaultMessage='By adding prepaid Elastic Consumption Unit (ECU) credits, your subscription plan will be automatically upgraded to the Enterprise level at the Activation date agreed in your contract. Select Change subscription to check other compatible plans.'
          />
        </EuiText>
      )
    }

    return (
      <EuiText size='xs' color='warning'>
        <FormattedMessage
          id='prepaid-credit-modal.compatible-message'
          defaultMessage='While your current subscription plan is compatible with prepaid Elastic Consumption Unit (ECU) credits, we recommend that you upgrade to Enterprise to get more cost saving options and optimize how you spend your ECUs.'
        />
      </EuiText>
    )
  }

  renderHourlyRate = (): JSX.Element => {
    const { accountActivity } = this.props
    const { selectedSubscription } = this.state
    const hourlyRateForLevel = getHourlyRateForLevel({
      subscription: selectedSubscription,
      activity: accountActivity,
    })
    const shouldShowUnknownRate = this.isHourlyRateUnknown()

    if (shouldShowUnknownRate) {
      return (
        <EuiLink href={pricingUrl()} target='_blank'>
          <FormattedMessage
            id='prepaid-credit-modal.check-pricing-link'
            defaultMessage='Check pricing'
          />
        </EuiLink>
      )
    }

    return <CuiPrice value={hourlyRateForLevel} unit='none' dp={4} />
  }

  renderSelectSubscription = (): JSX.Element => {
    const { accountActivity, isSubmitted } = this.props
    const { selectedSubscription } = this.state

    return (
      <div data-test-id='prepaid-credit-modal.selected-subscription'>
        <EuiStat
          titleSize='s'
          isLoading={accountActivity == null}
          title={this.renderHourlyRate()}
          descriptionElement='div'
          description={
            <FormattedMessage
              id='prepaid-credit-modal.subscription-hourly-rate'
              defaultMessage='{level} hourly rate {tooltip}'
              values={{
                level: (
                  <ExternalLink showExternalLinkIcon={false} href={externalPricing}>
                    {capitalize(selectedSubscription)}
                  </ExternalLink>
                ),
                tooltip: this.renderHelperTooltip(),
              }}
            />
          }
        />
        <EuiButtonEmpty
          onClick={this.openChangeSubscriptionModal}
          iconType='pagesSelect'
          flush='left'
          disabled={isSubmitted}
          data-test-id='change-subscription'
        >
          <FormattedMessage
            id='prepaid-credit-modal.change-subscription'
            defaultMessage='Change subscription'
          />
        </EuiButtonEmpty>
      </div>
    )
  }

  renderHelperTooltip = (): JSX.Element => {
    if (this.isHourlyRateUnknown()) {
      return (
        <CuiHelpTipIcon color='text' anchorPosition='upCenter'>
          <EuiPopoverTitle>
            <FormattedMessage
              id='prepaid-credit-modal.tooltip.unknown-rate-title'
              defaultMessage='Unknown hourly rate'
            />
          </EuiPopoverTitle>

          <EuiText size='s'>
            <FormattedMessage
              id='prepaid-credit-modal.tooltip.unknown-rate-description'
              defaultMessage={`We couldn't find enough recent activity or data on your deployments to provide a reliable new hourly rate with the selected subscription plan. To get more information on pricing, visit {ourWebsite} or {contactSupport}.`}
              values={{
                ourWebsite: (
                  <EuiLink href={pricingUrl()} target='_blank'>
                    <FormattedMessage
                      id='upgradable-deployment-version.our-website-link'
                      defaultMessage='our website'
                    />
                  </EuiLink>
                ),
                contactSupport: (
                  <EuiLink href={supportUrl()} target='_blank'>
                    <FormattedMessage
                      id='upgradable-deployment-version.contact-support-link'
                      defaultMessage='contact support'
                    />
                  </EuiLink>
                ),
              }}
            />
          </EuiText>
        </CuiHelpTipIcon>
      )
    }

    return (
      <CuiHelpTipIcon color='text' anchorPosition='upCenter'>
        <EuiText size='s'>
          <FormattedMessage
            id='prepaid-credit-modal.tooltip.rate-description'
            defaultMessage='This hourly rate is a forecast based on the recent activity of your deployments and the subscription plan selected. As your usage and settings evolve, the rate can evolve as well.'
          />
        </EuiText>
      </CuiHelpTipIcon>
    )
  }

  renderGeneralError = (): JSX.Element => (
    <Fragment>
      <EuiSpacer size='m' />
      <EuiCallOut
        title={
          <FormattedMessage
            id='prepaid-credit-modal.general-error-title'
            defaultMessage='Something went wrong'
          />
        }
        color='danger'
        iconType='alert'
        data-test-id='generalError'
      >
        <FormattedMessage
          id='prepaid-credit-modal.general-error-message'
          defaultMessage="Try again. {support} if it still isn't working."
          values={{
            support: (
              <CuiLink to={supportUrl()} target='_blank'>
                <FormattedMessage
                  id='prepaid-credit-modal.contact-support'
                  defaultMessage='Contact support'
                />
              </CuiLink>
            ),
          }}
        />
      </EuiCallOut>
    </Fragment>
  )

  getTitle = (): JSX.Element => {
    const { isSubmitted } = this.props

    return isSubmitted ? (
      <FormattedMessage id='prepaid-credit-modal.title-submitted' defaultMessage='Credits added' />
    ) : (
      <FormattedMessage id='prepaid-credit-modal.title' defaultMessage='Add ECU credits' />
    )
  }

  getFooter = (): JSX.Element => {
    const { onClose, isLoading, isSubmitted } = this.props

    return isSubmitted ? (
      <EuiButton fill={true} onClick={onClose} data-test-id='closeButton'>
        <FormattedMessage id='prepaid-credit-modal.close' defaultMessage='Close' />
      </EuiButton>
    ) : (
      <Fragment>
        <EuiButtonEmpty onClick={onClose} disabled={isLoading} data-test-id='closeButton'>
          <FormattedMessage id='prepaid-credit-modal.cancel' defaultMessage='Cancel' />
        </EuiButtonEmpty>

        <EuiButton
          type='submit'
          form={formId}
          fill={true}
          disabled={isLoading}
          data-test-id='submitButton'
        >
          <FormattedMessage id='prepaid-credit-modal.add' defaultMessage='Add' />
        </EuiButton>
      </Fragment>
    )
  }

  openChangeSubscriptionModal = (): void => {
    this.setState({ showChangeSubscriptionModal: true })
  }

  closeChangeSubscriptionModal = (): void => {
    this.setState({ showChangeSubscriptionModal: false })
  }

  onSelectSubscription = (subscription: Subscription): void => {
    this.setState({ selectedSubscription: subscription.value as AvailableBillingSubscriptionLevel })
  }

  getDefaultLevel(): AvailableBillingSubscriptionLevel {
    const { profile, flags } = this.props

    // standard level is not available
    if (profile?.level === 'standard') {
      return defaultSubscriptionLevelByUser({
        profile,
        trialDefaultTierFlag: flags?.trialDefaultTierFlag,
      }) as AvailableBillingSubscriptionLevel
    }

    return (
      profile?.level ||
      defaultSubscriptionLevelByUser({
        profile,
        trialDefaultTierFlag: flags?.trialDefaultTierFlag,
      })
    )
  }

  isHourlyRateUnknown = (): boolean => {
    const { accountActivity, billingDetails } = this.props
    const { selectedSubscription } = this.state
    const hourlyRateForLevel = getHourlyRateForLevel({
      subscription: selectedSubscription,
      activity: accountActivity,
    })
    const shouldShowUnknownRate =
      !hourlyRateForLevel || isPrepaidConsumptionDirectResellerCustomer(billingDetails.data!)

    return shouldShowUnknownRate
  }
}

export default withEuiTheme(injectIntl(withLDConsumer()(PrepaidCreditActivationModal)))
