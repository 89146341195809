/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiDescribedFormGroup } from '@elastic/eui'

import { CuiFormField } from '@modules/cui/forms'

import { labels, sections } from '../../authProviderMessages'

import type { FunctionComponent } from 'react'

const IdentityProviderMetadataFile: FunctionComponent = () => (
  <EuiDescribedFormGroup
    className='euiDescribedFormGroup__fieldPadding--none'
    title={
      <h3>
        <FormattedMessage {...sections.identityProviderMetadataFile} />
      </h3>
    }
    description={<FormattedMessage {...sections.identityProviderMetadataFileDescription} />}
  >
    <CuiFormField
      name='metadata_url'
      helpText={<FormattedMessage {...labels.identityProviderMetadataFileHelpText} />}
    />
  </EuiDescribedFormGroup>
)

export default IdentityProviderMetadataFile
