/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { RegionInfo } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, Runner } from '@modules/ui-types'

import { getRegionConstructors, fetchPlatformRequest, getRegionInfo } from '../../reducers'

import ControlPlanes from './ControlPlanes'

import type { Constructors, ReduxState } from '@/types/redux'

type StateProps = {
  constructors?: Constructors
  fetchPlatformRequest: AsyncRequestState
  regionInfo: RegionInfo | null
}

interface DispatchProps {}

type ConsumerProps = {
  regionId: string
  runnersSearchResults: Runner[] | null
  runnersSearchRequest: AsyncRequestState
}

const mapStateToProps = (state: ReduxState, { regionId }: ConsumerProps): StateProps => ({
  constructors: getRegionConstructors(state, regionId),
  fetchPlatformRequest: fetchPlatformRequest(state, regionId),
  regionInfo: getRegionInfo(state, regionId),
})

const mapDispatchToProps: DispatchProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ControlPlanes)
