/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { withErrorBoundary } from '@modules/cui'

import { getHighestSeverity } from '../../../lib/healthProblems'

import type { Problem } from '../../../lib/healthProblems'
import type { FunctionComponent } from 'react'

interface Props {
  problems: Problem[]
}

const PlatformHealthProblemsTitle: FunctionComponent<Props> = ({ problems }) => {
  const customTitleProblem = problems.find((problem) => problem.title !== undefined)

  if (customTitleProblem) {
    return customTitleProblem.title!
  }

  const highestSeverity = getHighestSeverity(problems)
  const problemCount = problems.length

  if (highestSeverity === `danger`) {
    return (
      <FormattedMessage
        id='platform-health-problems-title.platform-unhealthy'
        defaultMessage='Unhealthy platform'
      />
    )
  }

  if (highestSeverity === `warning`) {
    return (
      <FormattedMessage
        id='platform-health-problems-title.platform-warnings'
        defaultMessage='Platform health {problemCount, plural, one {warning} other {warnings}}'
        values={{ problemCount }}
      />
    )
  }

  if (highestSeverity === `info`) {
    return (
      <FormattedMessage
        id='platform-health-problems-title.platform-notices'
        defaultMessage='Platform health {problemCount, plural, one {notice} other {notices}}'
        values={{ problemCount }}
      />
    )
  }

  return (
    <FormattedMessage
      id='platform-health-problems-title.healthy-platform'
      defaultMessage='Healthy'
      values={{ problemCount }}
    />
  )
}

export default withErrorBoundary(PlatformHealthProblemsTitle)
