/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiBadge } from '@elastic/eui'

import type { Role } from '@/components/Users/RoleAssignmentsPanel/types'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

const messages = defineMessages<Role>({
  'organization-admin': {
    id: 'organization.organization-member-role.organization-owner',
    defaultMessage: 'Organization owner',
  },
  'billing-admin': {
    id: 'organization.badge.organization-member-role.billing-admin',
    defaultMessage: 'Billing admin',
  },
  'deployment-admin': {
    id: 'organization.badge.organization-member-role.deployment-admin',
    defaultMessage: 'Deployment admin',
  },
  'deployment-editor': {
    id: 'organization.badge.organization-member-role.deployment-editor',
    defaultMessage: 'Deployment editor',
  },
  'deployment-viewer': {
    id: 'organization.badge.organization-member-role.deployment-viewer',
    defaultMessage: 'Deployment viewer',
  },
  'no-access': {
    id: 'organization.badge.organization-member-role.no-access',
    defaultMessage: 'No access',
  },
})

type Props = {
  role: Role
}

const RoleBadge: FunctionComponent<Props & WrappedComponentProps> = ({
  role,
  intl: { formatMessage },
}) => <EuiBadge color='hollow'>{formatMessage(messages[role])}</EuiBadge>

export default injectIntl(RoleBadge)
