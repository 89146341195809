/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { EuiIconProps, WithEuiThemeProps } from '@elastic/eui'
import { withEuiTheme, EuiIcon } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { StrictOmit } from '../../ts-essentials'

type Props = WithEuiThemeProps & {
  darkType: EuiIconProps['type']
  lightType: EuiIconProps['type']
} & StrictOmit<EuiIconProps, 'type'>

const ThemedIcon: FunctionComponent<Props> = ({ theme, darkType, lightType, ...rest }) => (
  <EuiIcon type={theme.colorMode.toLowerCase() === 'dark' ? darkType : lightType} {...rest} />
)

export const CuiThemedIcon = withEuiTheme(ThemedIcon)
