/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ContainerSetDetails } from '@modules/cloud-api/v1/types'
import type { FilterSchema } from '@modules/cui'

export const schema: FilterSchema = {
  strict: true,
  fields: {
    id: {
      type: `string`,
    },
    hidden: {
      type: `string`,
      validate: validateHumanBool,
    },
  },
}

export const defaultFields = [`id`]

export function getQueryModel(containerSet: ContainerSetDetails) {
  return {
    id: containerSet.container_set_id,
    hidden: toHumanBool(containerSet.hidden),
  }
}

function toHumanBool(value) {
  return value ? `y` : `n`
}

function validateHumanBool(value) {
  if (value !== `y` && value !== `n`) {
    throw new Error(`Expected "y" or "n"`)
  }
}
