/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ElasticsearchResourceInfo } from '@modules/cloud-api/v1/types'

export function hasHealthyShards({
  resource,
}: {
  resource: ElasticsearchResourceInfo
}): boolean | null {
  if (!resource.info.elasticsearch.shards_status) {
    return null
  }

  return resource.info.elasticsearch.shards_status.status !== 'red'
}

export function shardsStatus({ resource }: { resource: ElasticsearchResourceInfo }): string {
  if (!resource.info.elasticsearch.shards_status) {
    return ''
  }

  return resource.info.elasticsearch.shards_status.status
}
