/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { map, size } from 'lodash'

import { EuiCode, EuiSpacer, EuiCallOut } from '@elastic/eui'

import type { VacateResult as VacateResultType } from '@modules/ui-types'
import { CuiLink } from '@modules/cui'

import { resolveDeploymentUrlForAnyCluster } from '../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'

type Props = {
  regionId: string
  vacateResult?: VacateResultType
}

const VacateResult: FunctionComponent<Props> = ({ regionId, vacateResult }) => {
  if (!vacateResult) {
    return null
  }

  const amountFailed = size(vacateResult.failed)
  const wasSuccessful = amountFailed === 0

  if (wasSuccessful) {
    return null
  }

  const amountVacating = size(vacateResult.vacating)
  const isVacating = amountVacating > 0

  if (!isVacating) {
    return (
      <Fragment>
        <EuiSpacer size='m' />

        <EuiCallOut
          color='danger'
          title={
            <FormattedMessage
              id='allocator-vacate-vacate-status.no-nodes-vacated'
              defaultMessage='{amountFailed, plural, one {Instance could not} other {No instances could}} be moved'
              values={{ amountFailed }}
            />
          }
        >
          <div>
            <ul>
              {map(vacateResult.failed, ({ sliderInstanceType, errors }, clusterId) =>
                errors.map((errorMessage) => (
                  <li>
                    <FormattedMessage
                      id='allocator-vacate-vacate-status.vacate-failure-reason'
                      defaultMessage='{clusterId}: {errorMessage}'
                      values={{
                        clusterId: (
                          <EuiCode>
                            <CuiLink
                              to={resolveDeploymentUrlForAnyCluster({
                                regionId,
                                clusterId,
                                sliderInstanceType,
                              })}
                            >
                              {clusterId.slice(0, 6)}
                            </CuiLink>
                          </EuiCode>
                        ),
                        errorMessage,
                      }}
                    />
                  </li>
                )),
              )}
            </ul>

            <p>
              <FormattedMessage
                id='allocator-vacate-vacate-status.no-pending-plan-then-retry'
                defaultMessage='Make sure that the instances you are trying to move have no pending configuration changes, then retry.'
              />
            </p>
          </div>
        </EuiCallOut>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <EuiSpacer size='m' />

      <EuiCallOut
        color='warning'
        title={
          <FormattedMessage
            id='allocator-vacate-vacate-status.some-nodes-not-vacated'
            defaultMessage='Some instances could not be vacated'
          />
        }
      >
        <div>
          <ul>
            {map(vacateResult.failed, (failure, clusterId) =>
              failure.errors.map((errorMessage) => (
                <li>
                  {clusterId}: {errorMessage}
                </li>
              )),
            )}
          </ul>

          <p>
            <FormattedMessage
              id='allocator-vacate-vacate-status.other-nodes-vacating'
              defaultMessage='The other instances are being vacated. We recommend waiting for this vacate to finish before vacating more nodes.'
            />
          </p>
        </div>
      </EuiCallOut>
    </Fragment>
  )
}

export default VacateResult
