/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { updateUserUrl } from '@modules/cloud-api/v1/urls'
import type { User } from '@modules/cloud-api/v1/types'

import { ThunkAction } from '@/types/redux'

import asyncRequest from '../asyncRequests'
import { UPDATE_USER } from '../../constants/actions'

export function updateNativeUser(payload: User): ThunkAction {
  return (dispatch) => {
    const userName = payload.user_name
    const url = updateUserUrl({ userName })

    return dispatch(
      asyncRequest({
        type: UPDATE_USER,
        url,
        method: `patch`,
        meta: { userName },
        payload,
        crumbs: [userName],
      }),
    )
  }
}
