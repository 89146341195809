/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiDescribedFormGroup,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'

import { CuiFormField } from '@modules/cui/forms'
import { CuiTitleWithBadge } from '@modules/cui'

import { labels, sections } from '../../authProviderMessages'

import type { FunctionComponent } from 'react'

const TestConfiguration: FunctionComponent = () => (
  <EuiDescribedFormGroup
    title={
      <CuiTitleWithBadge>
        <FormattedMessage {...sections.testActiveDirectoryAuth} />
      </CuiTitleWithBadge>
    }
    description={<FormattedMessage {...sections.testActiveDirectoryAuthHelpText} />}
  >
    <CuiFormField
      label={<FormattedMessage {...labels.username} />}
      icon='user'
      name='test_credentials.username'
    />

    <CuiFormField
      label={<FormattedMessage {...labels.password} />}
      component={EuiFieldPassword}
      name='test_credentials.password'
    />

    <EuiFlexGroup>
      <EuiFlexItem grow={false}>
        {/* eslint-disable-next-line no-alert */}
        <EuiButton onClick={() => window.alert('Not implemented')}>
          <FormattedMessage {...labels.testProfile} />
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiDescribedFormGroup>
)

export default TestConfiguration
