/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState } from '@modules/ui-types'

import { regenerateInvoicesRequest } from '@/reducers/asyncRequests/registry'
import { regenerateInvoices, resetRegenerateInvoiceRequest } from '@/actions/saasOrganizations'

import RegenerateInvoicesButton from './RegenerateInvoicesButton'

import type { ReduxState } from '@/types/redux'

interface StateProps {
  regenerateInvoicesRequest: AsyncRequestState
}

interface DispatchProps {
  regenerateInvoices: () => Promise<unknown>
  resetRegenerateInvoices: () => void
}

interface ConsumerProps {
  organizationId: string
}

const mapStateToProps = (state: ReduxState, { organizationId }: ConsumerProps): StateProps => ({
  regenerateInvoicesRequest: regenerateInvoicesRequest(state, organizationId),
})

const mapDispatchToProps = (dispatch, { organizationId }: ConsumerProps): DispatchProps => ({
  regenerateInvoices: () => dispatch(regenerateInvoices(organizationId)),
  resetRegenerateInvoices: () => dispatch(resetRegenerateInvoiceRequest(organizationId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RegenerateInvoicesButton)
