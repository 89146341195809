/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiFormField, CuiFormSwitch } from '@modules/cui/forms'

import DocLink from '../../../../../../../components/DocLink'
import { labels, messages, sections } from '../../authProviderMessages'

import type { FunctionComponent } from 'react'

interface Props {
  hideOptionalAttributes: boolean
  setFieldValue: (key: string, value: any) => void
}

const MappingRow = ({ name, header = false }) => (
  <Fragment>
    <EuiFlexGroup data-test-subj='mapping-row'>
      <EuiFlexItem grow={false} style={{ width: 90 }}>
        {header ? (
          <EuiFormRow label='User property'>
            <EuiFieldText readOnly={true} value={name} />
          </EuiFormRow>
        ) : (
          <EuiFieldText readOnly={true} value={name} />
        )}
      </EuiFlexItem>
      <EuiFlexItem>
        {header ? (
          <CuiFormField label='SAML attribute name' name={`attribute_mappings.${name}`} />
        ) : (
          <CuiFormField name={`attribute_mappings.${name}`} />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer size='m' />
  </Fragment>
)

const AttributeMappings: FunctionComponent<Props> = ({ setFieldValue, hideOptionalAttributes }) => (
  <EuiDescribedFormGroup
    title={
      <h3>
        <FormattedMessage {...sections.attributeMappings} />
      </h3>
    }
    description={
      <Fragment>
        <EuiText size='s'>
          <FormattedMessage
            {...sections.attributeMappingsDescription}
            values={{
              learnMore: (
                <DocLink link='samlProviderAttributeMappingDocLink'>
                  <FormattedMessage {...messages.learnMore} />
                </DocLink>
              ),
            }}
          />
        </EuiText>

        <EuiSpacer size='m' />

        <CuiFormSwitch
          name='hide_optional_attributes'
          label={<FormattedMessage {...labels.hideOptionalAttributes} />}
          setFieldValue={setFieldValue}
        />
      </Fragment>
    }
  >
    <Fragment>
      <MappingRow header={true} name='principal' />
      <MappingRow name='groups' />

      {hideOptionalAttributes || (
        <Fragment>
          <MappingRow name='name' />
          <MappingRow name='mail' />
          <MappingRow name='dn' />
        </Fragment>
      )}
    </Fragment>
  </EuiDescribedFormGroup>
)

export default AttributeMappings
