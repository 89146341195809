/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

import {
  EuiButtonIcon,
  EuiFormControlLayout,
  EuiFormLabel,
  EuiPopover,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type {
  RegionId,
  VersionNumber,
  StackDeploymentCreateRequest,
  AsyncRequestState,
} from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import { isPrerelease } from '../../../../../DeploymentConfigure/EsVersion/groupVersions'
import { isIncompatibleVersionForGlobalTemplate } from '../../../../../../lib/globalDeploymentTemplates'
import { elasticStackVersionsUrl } from '../../../../../../lib/urlBuilder'
import DocLink from '../../../../../../components/DocLink'

import SelectVersionComboBox from './SelectVersionComboBox'

import type { WrappedComponentProps } from 'react-intl'

export interface Props extends WrappedComponentProps {
  availableVersions: VersionNumber[]
  whitelistedVersions: VersionNumber[]
  setVersion: (version: VersionNumber) => void
  version: VersionNumber
  disabled?: boolean
  regionId: RegionId
  isUserconsole: boolean
  editorState: StackDeploymentCreateRequest
  fetchVersionsRequest: AsyncRequestState
}

type State = {
  isPopoverOpen: boolean
}

const messages = defineMessages({
  popoverText: {
    id: `select-version-text`,
    defaultMessage: `Learn more about Elastic Stack versions.`,
  },
})

class SelectVersion extends Component<Props, State> {
  state = {
    isPopoverOpen: false,
  }

  render() {
    const {
      version,
      availableVersions,
      whitelistedVersions,
      disabled,
      regionId,
      isUserconsole,
      editorState: { globalDeploymentTemplate },
      fetchVersionsRequest,
      intl: { formatMessage },
    } = this.props

    return (
      <Fragment>
        <EuiFormControlLayout
          fullWidth={true}
          prepend={
            <EuiFormLabel style={{ width: `180px` }}>
              <FormattedMessage defaultMessage='Version' id='select-version-label' />
              <EuiPopover
                isOpen={this.state.isPopoverOpen}
                closePopover={() => this.setState({ isPopoverOpen: false })}
                anchorPosition='upCenter'
                button={
                  <EuiButtonIcon
                    aria-label={formatMessage(messages.popoverText)}
                    className='hardwareProfileIcon'
                    onClick={this.onClickVersionIcon}
                    iconType='iInCircle'
                    color='text'
                  />
                }
              >
                <EuiText size='s' style={{ width: '300px' }}>
                  {isUserconsole ? (
                    <FormattedMessage
                      data-test-id='ess-select-version-tooltip'
                      id='select-version.tooltip'
                      defaultMessage='We make the two latest minor versions of the latest major version available for provisioning. In addition, the latest minor version of the previous major version is also available. {learnMore}'
                      values={{
                        learnMore: (
                          <DocLink link='versionPolicyLink'>
                            <FormattedMessage
                              id='select-version.learn-more'
                              defaultMessage='Learn more'
                            />
                          </DocLink>
                        ),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      data-test-id='ece-select-version-tooltip'
                      id='select-stackpack.tooltip'
                      defaultMessage='Your Elastic Cloud Enterprise administrator can download new versions of the Elastic Stack to be made available for provisioning. {learnMore}'
                      values={{
                        learnMore: (
                          <DocLink link='versionPolicyLink'>
                            <FormattedMessage
                              id='select-stackpack.learn-more'
                              defaultMessage='Learn more'
                            />
                          </DocLink>
                        ),
                      }}
                    />
                  )}
                </EuiText>
              </EuiPopover>
            </EuiFormLabel>
          }
        >
          <SelectVersionComboBox
            key='select-version'
            version={version}
            availableVersions={availableVersions}
            whitelistedVersions={whitelistedVersions}
            id='select-version-for-template'
            onUpdate={this.onChangeVersion}
            checkVersionDisabled={(v) =>
              isIncompatibleVersionForGlobalTemplate(globalDeploymentTemplate!, v)
            }
            disabled={disabled}
          />
        </EuiFormControlLayout>

        {!fetchVersionsRequest.inProgress && isEmpty(availableVersions) && (
          <CuiAlert type='warning'>
            <FormattedMessage
              id='stack-deployment-editor-setup-deployment.no-stack-versions'
              defaultMessage='Could not find any Elasticsearch versions. You need to {addVersion} before you can create a deployment.'
              values={{
                addVersion: (
                  <Link to={elasticStackVersionsUrl(regionId)}>
                    <FormattedMessage
                      id='stack-deployment-editor-setup-deployment.add-an-stack-version'
                      defaultMessage='add an Elasticsearch version'
                    />
                  </Link>
                ),
              }}
            />
          </CuiAlert>
        )}

        {isPrerelease(version) && (
          <Fragment>
            <EuiSpacer size='s' />

            <CuiAlert size='s' type='warning'>
              <FormattedMessage
                id='VersionRadio.prerelease-versions-warning'
                defaultMessage='This is a pre-release version of the Elastic Stack that we make available for testing. Do not use for production workloads. You will not be able to upgrade from this pre-release version.'
              />
            </CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  onChangeVersion = (version: VersionNumber) => {
    const { setVersion } = this.props

    setVersion(version)
  }

  onClickVersionIcon = () => {
    this.setState((prevState: State) => ({ isPopoverOpen: !prevState.isPopoverOpen }))
  }
}

export default injectIntl(SelectVersion)
