/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiImage,
  EuiPage,
  EuiPageBody,
  EuiPageSection,
  EuiText,
} from '@elastic/eui'

import type { Theme } from '@modules/ui-types'
import { CuiRouterLinkButton } from '@modules/cui'
import history from '@modules/utils/history'

import HttpErrorPage from '../HttpErrorPage'
import { loginUrl } from '../../../lib/urlBuilder'
import errorImg from '../../../files/404_astronaut_light.png'
import errorImgDark from '../../../files/404_astronaut_dark.png'

import type { FunctionComponent } from 'react'

export interface Props {
  theme: Theme
  showLoginButton: boolean
  withHttpErrorPage?: boolean
}

const themes = {
  light: errorImg,
  dark: errorImgDark,
}

const NotFound: FunctionComponent<Props> = ({
  theme,
  showLoginButton,
  withHttpErrorPage = true,
}) => {
  const content = (
    <EuiEmptyPrompt
      titleSize='m'
      icon={<EuiImage size='fullWidth' src={themes[theme]} alt='' />}
      layout='vertical'
      color='plain'
      paddingSize='l'
      title={
        <h2>
          <FormattedMessage id='http-errors.not-found.title' defaultMessage='Page not found' />
        </h2>
      }
      body={
        <EuiText size='s'>
          <FormattedMessage
            id='http-errors.not-found.content'
            defaultMessage="Sorry, the page you're looking for can't be found. It might have been removed or renamed, or maybe it never existed at all."
          />
        </EuiText>
      }
      actions={
        showLoginButton ? (
          <div data-test-id='not-found-login-button'>
            <CuiRouterLinkButton fill={true} to={loginUrl()} className='http-error-pages-button'>
              <FormattedMessage id='http-errors-pages.login-button' defaultMessage='Log in' />
            </CuiRouterLinkButton>
          </div>
        ) : (
          [
            <Fragment>
              <div data-test-id='not-found-home-button'>
                <EuiButton fill={true} onClick={() => history.replace('/')} fullWidth={true}>
                  <FormattedMessage
                    id='http-errors-pages.home-button'
                    defaultMessage='Go to home'
                  />
                </EuiButton>
              </div>
            </Fragment>,
            <Fragment>
              <div data-test-id='not-found-go-back-button'>
                <EuiButtonEmpty
                  onClick={() => history.goBack()}
                  className='http-error-pages-button'
                >
                  <FormattedMessage
                    id='http-errors-pages.go-back-button'
                    defaultMessage='Go back'
                  />
                </EuiButtonEmpty>
              </div>
            </Fragment>,
          ]
        )
      }
    />
  )

  if (withHttpErrorPage) {
    return <HttpErrorPage>{content}</HttpErrorPage>
  }

  return (
    <EuiPage className='http-error-pages' style={{ height: '90vh' }}>
      <EuiPageBody>
        <EuiPageSection paddingSize='m' alignment='horizontalCenter'>
          {content}
        </EuiPageSection>
      </EuiPageBody>
    </EuiPage>
  )
}

export default NotFound
