/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { resendSaasVerificationEmailAdminUrl } from '@modules/cloud-api/v1/urls'
import type { SaasResendVerificationRequest } from '@modules/cloud-api/v1/types'

import { RESEND_EMAIL_VERIFICATION_LINK } from '@/apps/userconsole/constants/actions'

import asyncRequest from '../asyncRequests'

export function resendEmailVerificationLink(payload: SaasResendVerificationRequest) {
  const url = resendSaasVerificationEmailAdminUrl()
  const { email } = payload

  return asyncRequest({
    type: RESEND_EMAIL_VERIFICATION_LINK,
    method: `POST`,
    payload,
    url,
    meta: { email },
    crumbs: [email],
  })
}
