/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { isEqual, sortBy } from 'lodash'

import type { AllocatorVizInstance, RegionId, SliderInstanceType } from '@modules/ui-types'

import toPercentage from '../../lib/toPercentage'
import { resolveDeploymentUrlForAnyCluster } from '../../lib/urlBuilder'
import prettySize from '../../lib/prettySize'
import { getSliderColor } from '../../lib/sliders'
import { getInstanceId, getMemoryCapacity } from '../../lib/allocatorInstanceCapacitiesViz'

import { getCapacityColor } from './capacityVizColors'

import './allocatorsTable-capacityViz.scss'

// Aliased to be more descriptive. eg: 'rgb(255, 255, 255)'
type RgbColor = string

type Props = {
  regionId: RegionId
  instances: AllocatorVizInstance[]
  usedCapacity: number
  totalCapacity: number
  largestCapacity: number
  hideInstanceKindsHairline?: boolean
  usedCapacityColor?: string
}

type State = {
  shaders: Map<RgbColor, IterableIterator<RgbColor>>
}

export default class InstanceCapacitiesViz extends Component<Props, State> {
  state: State = {
    shaders: new Map(),
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps)
  }

  render() {
    const { totalCapacity, largestCapacity, instances } = this.props

    const sortedInstances = sortBy(instances, (instance) => -getMemoryCapacity(instance))
    const widthPercent = toPercentage(totalCapacity / largestCapacity)
    const width = `${widthPercent}%`
    const styles = { width }

    return (
      <div style={styles}>
        <div className='allocatorCapacity'>
          {sortedInstances.map((instance, index) =>
            this.renderUsedCapacity(instance, index % 2 !== 0),
          )}
          {this.renderUnusedCapacity()}
        </div>

        {this.renderInstanceKinds(sortedInstances)}
      </div>
    )
  }

  renderUsedCapacity(instance: AllocatorVizInstance, alternate: boolean) {
    const { totalCapacity, regionId, usedCapacityColor } = this.props
    const { clusterId, kind, stackDeploymentId } = instance
    const instanceId = getInstanceId(instance)
    const capacity = getMemoryCapacity(instance)
    const usage = toPercentage(capacity / totalCapacity)
    const displayId = clusterId.slice(0, 6)
    const backgroundColor = usedCapacityColor || getCapacityColor(capacity, alternate)

    const tooltip = `${prettySize(capacity)} ${kind} (${displayId})`

    // The reason we're not using `<Link>` from `react-router` is that
    // it considerably slowed down unmounting the visualizations.
    return (
      <a
        key={`${clusterId}-${kind}-${instanceId}`}
        href={resolveDeploymentUrlForAnyCluster({
          regionId,
          clusterId,
          sliderInstanceType: kind,
          stackDeploymentId,
        })}
        className='allocatorCapacity-used'
        data-tooltip={tooltip}
        style={{
          width: `${usage}%`,
          backgroundColor,
        }}
      />
    )
  }

  renderUnusedCapacity() {
    const { usedCapacity, totalCapacity } = this.props

    if (usedCapacity === totalCapacity) {
      return null
    }

    const unused = 100 - toPercentage(usedCapacity / totalCapacity)
    const unusedSpace = totalCapacity - usedCapacity
    const tooltip = `${prettySize(unusedSpace)} of available capacity`

    return (
      <div
        className='allocatorCapacity-unused allocatorCapacity-unused-color'
        data-tooltip={tooltip}
        style={{
          width: `${unused}%`,
        }}
      />
    )
  }

  renderInstanceKinds(instances: AllocatorVizInstance[]) {
    const { totalCapacity, hideInstanceKindsHairline } = this.props

    if (hideInstanceKindsHairline) {
      return null
    }

    const kinds = [] as Array<{
      kind: SliderInstanceType
      width: number
      start: number
      color?: string
    }>

    instances.forEach((instance) => {
      const { kind } = instance
      const width = toPercentage(getMemoryCapacity(instance) / totalCapacity)
      const lastKind = kinds[kinds.length - 1]
      const isSameKind = lastKind && lastKind.kind === kind

      if (isSameKind) {
        lastKind.width += width
        return
      }

      const start = lastKind ? lastKind.start + lastKind.width : 0
      const color = getSliderColor(kind)

      kinds.push({ kind, width, start, color })
    })

    return (
      <svg className='allocatorCapacityKinds'>
        {kinds.map(({ kind, start, width, color }) => (
          <rect
            key={`nodes-${kind}:${start}:${width}`}
            x={`${start}%`}
            width={`${width}%`}
            fill={color}
            className='allocatorCapacityKind'
            rx='1'
            ry='1'
          />
        ))}
      </svg>
    )
  }
}
