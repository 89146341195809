/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import type { AnyResourceInfo, AnyClusterPlanInfo, SliderInstanceType } from '@modules/ui-types'

import { parseConfigurationChangeError } from '../../../lib/healthProblems/stackDeploymentHealth'
import { getFormattedWarningMessage } from '../../../lib/healthProblems/stackDeploymentHealth/configurationChangeWarnings'

import type { FunctionComponent } from 'react'

type Props = {
  resource: AnyResourceInfo
  resourceType: SliderInstanceType
  planAttempt: AnyClusterPlanInfo
  spacerBefore?: boolean
  spacerAfter?: boolean
}

const StackConfigurationChangeWarnings: FunctionComponent<Props> = ({
  resource,
  resourceType,
  planAttempt,
  spacerBefore,
  spacerAfter,
}) => {
  const parsedError = parseConfigurationChangeError({
    resource,
    resourceType,
    planAttempt,
    linkify: true,
  })

  if (parsedError !== null) {
    // Don't show warnings for plans that failed with an error -- too confusing.
    // We'll show the error details instead.
    return null
  }

  const { warnings } = planAttempt

  if (warnings === null || warnings.length === 0) {
    return null
  }

  const formattedMessages = warnings.map(getFormattedWarningMessage)

  return (
    <Fragment>
      {spacerBefore && <EuiSpacer size='m' />}

      <EuiCallOut
        color='warning'
        title={
          <FormattedMessage
            id='configuration-change-warnings.generic-title'
            defaultMessage='Configuration change completed with warnings'
          />
        }
      >
        <ul data-test-id='warnings-list'>
          {formattedMessages.map((message, i) => (
            <li key={i}>{message}</li>
          ))}
        </ul>
      </EuiCallOut>

      {spacerAfter && <EuiSpacer size='m' />}
    </Fragment>
  )
}

export default StackConfigurationChangeWarnings
