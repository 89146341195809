/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { flatMap, uniq } from 'lodash'

import type {
  DeploymentUpdateRequest,
  ElasticsearchClusterPlanInfo,
  ElasticsearchClusterTopologyElement,
  ElasticsearchConfiguration,
  ElasticsearchPayload,
} from '@modules/cloud-api/v1/types'
import type {
  AnyClusterPlanInfo,
  AnyPayload,
  AnyPlanConfiguration,
  AnyTopologyElement,
} from '@modules/ui-types'

import { getResources } from '../selectors/fundamentals'
import { getAllKnownSliderInstanceTypes } from '../../sliders/sliders'

export function getPlanConfiguration(
  resource: ElasticsearchPayload | ElasticsearchClusterPlanInfo,
): ElasticsearchConfiguration | null
export function getPlanConfiguration(
  resource: AnyPayload | AnyClusterPlanInfo,
): AnyPlanConfiguration | null
export function getPlanConfiguration(
  resource: AnyPayload | AnyClusterPlanInfo,
): AnyPlanConfiguration | null {
  for (const sliderInstanceType of getAllKnownSliderInstanceTypes()) {
    const planConfiguration: AnyPlanConfiguration | undefined = resource.plan?.[sliderInstanceType]

    if (planConfiguration) {
      return planConfiguration
    }
  }

  return null
}

export function getTopologyElementConfiguration(
  resource: ElasticsearchClusterTopologyElement,
): ElasticsearchConfiguration | null
export function getTopologyElementConfiguration(
  topologyElement: AnyTopologyElement,
): AnyPlanConfiguration | null
export function getTopologyElementConfiguration(
  topologyElement: AnyTopologyElement,
): AnyPlanConfiguration | null {
  for (const sliderInstanceType of getAllKnownSliderInstanceTypes()) {
    const topologyElementConfiguration = topologyElement[sliderInstanceType]

    if (topologyElementConfiguration) {
      return topologyElementConfiguration
    }
  }

  return null
}

export function getUserSettingsFromConfiguration(
  configuration: AnyPlanConfiguration | null,
): string | undefined {
  // There are various user_settings_* fields, but we're only concerned with
  // `user_settings_yaml` here:
  //
  // - user_settings_override_yaml is not user-facing
  // - user_settings_json and user_settings_override_json are not UI-exposed,
  //   and are mutually exclusive with their yaml counterparts
  return configuration?.user_settings_yaml
}

function getTopologyLevelUserSettings(resource: AnyPayload | AnyClusterPlanInfo): string[] {
  const topologyElements = resource.plan?.cluster_topology || []

  return topologyElements
    .map((topologyElement) => {
      const configuration = getTopologyElementConfiguration(topologyElement)
      return getUserSettingsFromConfiguration(configuration) || ''
    })
    .filter((settings) => settings && settings.trim().length > 0)
}

export function getPlanLevelSettings(resource: AnyPayload | AnyClusterPlanInfo): string {
  const configuration = getPlanConfiguration(resource)
  return getUserSettingsFromConfiguration(configuration) || ''
}

export function getAllSettingsFromDeployment(deployment: DeploymentUpdateRequest): string[] {
  const resources = getResources({ deployment })

  return flatMap(resources, (resource) => {
    const planLevelUserSettings = getPlanLevelSettings(resource)
    const topologyLevelUserSettings = getTopologyLevelUserSettings(resource)
    const allSettings = [planLevelUserSettings, ...topologyLevelUserSettings].filter(Boolean)

    return allSettings
  })
}

export function hasPlanLevelSettings(resource: AnyPayload | AnyClusterPlanInfo): boolean {
  const settings = getPlanLevelSettings(resource)
  return Boolean(settings) && settings.trim().length > 0
}

export function hasTopologyLevelSettings(resource: AnyPayload | AnyClusterPlanInfo): boolean {
  return getTopologyLevelUserSettings(resource).length > 0
}

export function hasOnlyPlanLevelSettings(resource: AnyPayload | AnyClusterPlanInfo): boolean {
  // Okay, this does not do *exactly* what the function name makes it sound like
  // -- but a deployment with no user settings still needs to regard this as
  // true. The plan level configuration block is the default receptacle for user
  // settings going forward regardless of whether a given deployment *actually
  // has any* user settings yet.
  return !hasTopologyLevelSettings(resource)
}

export function shouldMoveSettingsFromTopologyToPlan(
  resource: AnyPayload | AnyClusterPlanInfo,
): boolean {
  if (hasPlanLevelSettings(resource)) {
    return false // we're not going to overwrite plan-level settings if they exist
  }

  if (!hasTopologyLevelSettings(resource)) {
    return false // if there are no topology elements with settings, there's nothing to do
  }

  const userSettings = getTopologyLevelUserSettings(resource)

  return isYamlEqual(userSettings)
}

export function isYamlEqual(yamls: Array<string | undefined>): boolean {
  const normalizedYamls = yamls
    .filter((yaml): yaml is string => typeof yaml === `string` && yaml.trim().length > 0)
    .map((yaml) =>
      yaml
        .split(`\n`)
        .map((line) => line.trimEnd()) // discard any trailing whitespace
        .filter((line) => line.length > 0) // and empty lines
        .join(`\n`),
    )

  const allEquivalent = uniq(normalizedYamls).length === 1

  return allEquivalent
}
