/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { v4 as uuid } from 'uuid'

import type { ReactElement } from 'react'

export type SideNavItem = {
  id?: string
  name: string | ReactElement
  renderItem?: () => ReactElement
  href?: string
  isSelected?: boolean
  icon?: ReactElement
  items?: EuiSideNavItem[]
  isActive?: () => boolean
  ['data-test-id']?: string
}

export type EuiSideNavItem = SideNavItem & {
  id: string
  forceOpen: boolean
}

export function createItem({ name, renderItem, items, ...rest }: SideNavItem): EuiSideNavItem {
  const props: EuiSideNavItem = {
    name,
    id: typeof name === 'string' ? name : uuid(),
    items,
    forceOpen: true,
    ...rest,
  }

  if (typeof renderItem === 'function') {
    props.renderItem = renderItem
  }

  return props
}
