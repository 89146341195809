/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { MoveClustersCommandResponse } from '@modules/cloud-api/v1/types'
import type { Allocator, AsyncRequestState, RegionId } from '@modules/ui-types'

import {
  getAllocatorVacateValidate,
  vacateAllocatorRequest,
  vacateAllocatorValidateRequest,
} from '../../../../reducers'
import {
  resetVacateAllocatorValidate,
  vacateAllocator,
  vacateAllocatorValidate,
} from '../../../../actions/allocators'
import { getConfigForKey } from '../../../../selectors'

import VacateNodesModal from './VacateNodesModal'

import type { ThunkDispatch } from '@/types/redux'
import type { Node } from './VacateNodesModal'
import type {
  VacateAllocatorOptions,
  VacateAllocatorValidateOptions,
} from '../../../../actions/allocators'

type StateProps = {
  vacateAllocatorRequest: AsyncRequestState
  vacateAllocatorValidateResult: MoveClustersCommandResponse | undefined
  vacateAllocatorValidateRequest: AsyncRequestState
  isSaaSAdminconsole: boolean
}

type DispatchProps = {
  vacateAllocator: (params: VacateAllocatorOptions) => Promise<unknown>
  vacateAllocatorValidate: (params: VacateAllocatorValidateOptions) => void
  resetVacateAllocatorValidate: (regionId: RegionId, id: string) => void
}

type ConsumerProps = {
  allocator: Allocator
  nodes: Node[]
}

export type AllProps = StateProps & DispatchProps & ConsumerProps

const mapStateToProps = (state: any, { allocator }: ConsumerProps): StateProps => {
  const { regionId, id } = allocator

  return {
    vacateAllocatorRequest: vacateAllocatorRequest(state, regionId, id),
    vacateAllocatorValidateResult: getAllocatorVacateValidate(state, regionId, id),
    vacateAllocatorValidateRequest: vacateAllocatorValidateRequest(state, regionId, id),
    isSaaSAdminconsole: getConfigForKey(state, `CLOUD_UI_APP`) === `saas-adminconsole`,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  vacateAllocator: (params: VacateAllocatorOptions) => dispatch(vacateAllocator(params)),
  vacateAllocatorValidate: (params: VacateAllocatorValidateOptions) =>
    dispatch(vacateAllocatorValidate(params)),
  resetVacateAllocatorValidate: (regionId: RegionId, id: string) =>
    dispatch(resetVacateAllocatorValidate(regionId, id)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(VacateNodesModal)
