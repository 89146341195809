/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState } from '@modules/ui-types'

import { extendSaasOrganizationTrialRequest } from '@/reducers'
import {
  extendSaasOrganizationTrial,
  resetExtendSaasOrganizationTrialRequest,
} from '@/actions/saasOrganizations'

import ExtendTrial from './ExtendTrial'

interface StateProps {
  extendTrialRequest: AsyncRequestState
}

interface DispatchProps {
  extendTrial: (date: string) => Promise<any>
  resetExtendTrial: () => void
}

interface ConsumerProps {
  organizationId: string
  expirationDate: Date
}

const mapStateToProps = (state, { organizationId }): StateProps => ({
  extendTrialRequest: extendSaasOrganizationTrialRequest(state, organizationId),
})

const mapDispatchToProps = (dispatch, { organizationId }: ConsumerProps): DispatchProps => ({
  extendTrial: (expirationDate) =>
    dispatch(extendSaasOrganizationTrial(organizationId, expirationDate)),
  resetExtendTrial: () => dispatch(resetExtendSaasOrganizationTrialRequest(organizationId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ExtendTrial)
