/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiText } from '@elastic/eui'

import type { AvailableAuthenticationMethods } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiRouterLinkButtonEmpty } from '@modules/cui'

import AppLoadingRoot from '../../../../../components/AppLoadingRoot'
import LandingPage from '../../../../../components/LandingPage'
import {
  getLoginUrl,
  hasAnySsoMethod,
  hasRedirectOnMount,
  redirectOnMount,
} from '../../../../../lib/auth'

import type { RouteComponentProps } from 'react-router'

export type Props = {
  loginRequest: AsyncRequestState
  authMethods: AvailableAuthenticationMethods
  location: RouteComponentProps['location']
  fetchAuthMethods: () => void
  fetchAuthMethodsRequest: AsyncRequestState
  redirectAfterLogin: (redirectTo: string) => () => void
  redirectTo?: string
  newBearerToken: string | null
}

class SsoBasedLogin extends Component<Props> {
  componentDidMount() {
    const {
      authMethods,
      fetchAuthMethods,
      fetchAuthMethodsRequest,
      redirectAfterLogin,
      redirectTo,
      newBearerToken,
    } = this.props

    if (hasRedirectOnMount(newBearerToken)) {
      redirectOnMount({ redirectAfterLogin, redirectTo, newBearerToken })
      return
    }

    if (!authMethods && !fetchAuthMethodsRequest.inProgress) {
      fetchAuthMethods()
    }
  }

  render() {
    const { loginRequest, authMethods, fetchAuthMethodsRequest, location } = this.props
    const canSso = hasAnySsoMethod(authMethods)

    if (authMethods === null || fetchAuthMethodsRequest.inProgress) {
      return <AppLoadingRoot />
    }

    if (isEmpty(authMethods) || !canSso) {
      return <Redirect to={{ pathname: '/login/basic', search: location.search }} />
    }

    return (
      <LandingPage scrollPage={true} loading={loginRequest.inProgress} className='login-form-sso'>
        {this.renderContent()}
      </LandingPage>
    )
  }

  renderContent() {
    const { authMethods, location } = this.props
    const ssoMethods = authMethods.sso_methods

    return (
      <EuiFlexGroup alignItems='center' direction='column'>
        <EuiFlexItem>
          <EuiFlexGroup>
            {ssoMethods.map((ssoMethod) => {
              const { sso_type, name, url } = ssoMethod
              const loginUrl = getLoginUrl({ ssoUrl: url, location })

              return (
                <EuiFlexItem key={`${sso_type}:${url}`}>
                  <EuiButton
                    fill={true}
                    className='login-oauth'
                    href={loginUrl}
                    data-test-id='start-sso-button'
                  >
                    <FormattedMessage
                      id='log-in-with-sso-provider'
                      defaultMessage='Log in with {provider}'
                      values={{ provider: name }}
                    />
                  </EuiButton>
                </EuiFlexItem>
              )
            })}
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          <CuiRouterLinkButtonEmpty
            data-test-id='switch-to-password'
            to={{ pathname: '/login/basic', search: location.search }}
          >
            <EuiText size='s'>
              <FormattedMessage
                id='log-in.switch-to-password'
                defaultMessage='Switch to password login'
              />
            </EuiText>
          </CuiRouterLinkButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }
}

export default SsoBasedLogin
