/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiFormLabel,
  EuiRadio,
  EuiSpacer,
  EuiText,
  EuiTextColor,
} from '@elastic/eui'

import type { Scripting, ScriptingValue } from '@modules/ui-types'

import { getTooltip } from './utility'

import type { WrappedComponentProps } from 'react-intl'
import type { ReactNode } from 'react'

const messages = defineMessages({
  disableScripts: {
    id: `deployment-configure-scripts.disable-inline-scripts`,
    defaultMessage: `Disable inline scripts`,
  },
  enableSandboxedScripts: {
    id: `deployment-configure-scripts.enable-sandboxed-inline-scripts-2x`,
    defaultMessage: `Enable sandboxed inline scripts`,
  },
  enableScripts: {
    id: `deployment-configure-scripts.enable-all-inline-scripts-not-recommended`,
    defaultMessage: `Enable all inline scripts (Not recommended)`,
  },
})

type Props = WrappedComponentProps & {
  onUpdate: undefined | ((type: 'inline' | 'stored' | 'file', value: ScriptingValue) => void)
  value: Scripting['inline']
  lastValue: Scripting['inline']
  hasWatcher: boolean
  description: ReactNode
}

const InlineScripts: React.FunctionComponent<Props> = ({
  intl: { formatMessage },
  onUpdate,
  value,
  lastValue,
  hasWatcher,
  description,
}) => {
  const setValue = (_value: ScriptingValue) => onUpdate && onUpdate(`inline`, _value)

  return (
    <Fragment>
      <EuiFormLabel>
        <FormattedMessage
          id='deployment-configure-scripts.inline-scripts'
          defaultMessage='Inline Scripts'
        />
      </EuiFormLabel>
      <EuiSpacer size='s' />
      <EuiText size='s'>{description}</EuiText>
      <EuiSpacer size='s' />

      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem grow={false}>
          <EuiRadio
            id='inline-scripts-off'
            name='inline-scripts'
            label={formatMessage(messages.disableScripts)}
            onChange={() => setValue(`off`)}
            checked={value === `off`}
            disabled={hasWatcher || !onUpdate}
          />
        </EuiFlexItem>

        {getTooltip(`off`, value, lastValue)}
      </EuiFlexGroup>

      {hasWatcher && (
        <EuiFormHelpText>
          <EuiTextColor color='warning'>
            <FormattedMessage
              id='deployment-configure-scripts.disable-inline-scripts-watcher-active'
              defaultMessage='Inline scripts cannot be disabled when Watcher is active.'
            />
          </EuiTextColor>
        </EuiFormHelpText>
      )}

      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem grow={false}>
          <EuiRadio
            id='inline-scripts-sandbox'
            name='inline-scripts'
            label={formatMessage(messages.enableSandboxedScripts)}
            onChange={() => setValue(`sandbox`)}
            checked={value === `sandbox`}
            disabled={!onUpdate}
          />
        </EuiFlexItem>

        {getTooltip(`sandbox`, value, lastValue)}
      </EuiFlexGroup>

      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem grow={false}>
          <EuiRadio
            id='inline-scripts-all'
            name='inline-scripts'
            label={formatMessage(messages.enableScripts)}
            onChange={() => setValue(`on`)}
            checked={value === `on`}
            disabled={!onUpdate}
          />
        </EuiFlexItem>

        {getTooltip(`on`, value, lastValue)}
      </EuiFlexGroup>
    </Fragment>
  )
}

export default injectIntl(InlineScripts)
