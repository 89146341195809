/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, size } from 'lodash'
import React, { Component, Fragment } from 'react'

import { EuiSpacer } from '@elastic/eui'
import type { Query } from '@elastic/eui'

import type { AsyncRequestState, Runner } from '@modules/ui-types'
import { CuiAlert, getFilterQueryString, setFilterQueryString } from '@modules/cui'
import type { OnFilterChangeParams } from '@modules/cui'

import RunnerFilterContext, {
  executeQuery,
  getFilterTags,
  toggleField,
} from './RunnerFilterContext'
import RunnersTable from './RunnersTable'

export interface Props {
  regionId: string
  fetchRunners: () => Promise<any>
  runnersSearchResults: Runner[] | null | undefined
  runnersSearchRequest: AsyncRequestState
}

interface State {
  query: string | null
  queryResults: Runner[]
}

function isDisplayable(runner: Runner) {
  return runner.connected || runner.containers.length > 0
}

export default class Runners extends Component<Props, State> {
  state: State = {
    query: getFilterQueryString({ storageKey: `Runners` }),
    queryResults: [],
  }

  render() {
    const { runnersSearchResults, runnersSearchRequest } = this.props

    if (!runnersSearchResults && runnersSearchRequest.error) {
      return <CuiAlert type='error'>{runnersSearchRequest.error}</CuiAlert>
    }

    const { query } = this.state
    const runners =
      (runnersSearchResults && runnersSearchResults.filter((runner) => isDisplayable(runner))) ||
      undefined

    return (
      <Fragment>
        <RunnerFilterContext query={query} onChange={this.onChange} runners={runners} />

        {this.renderRunners()}
      </Fragment>
    )
  }

  renderRunners() {
    const { regionId, runnersSearchResults } = this.props
    const { query, queryResults } = this.state

    const loadedButNoMatches = runnersSearchResults && isEmpty(queryResults)

    if (loadedButNoMatches) {
      return null
    }

    const filteredRunners = runnersSearchResults
      ? runnersSearchResults.filter((runner) => isDisplayable(runner))
      : []

    const totalCount = size(filteredRunners)

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <RunnersTable
          runners={queryResults}
          totalCount={totalCount}
          regionId={regionId}
          getActiveTags={(field) => getFilterTags({ query, field })}
          onTagClick={this.onTagClick}
          onZoneClick={this.onZoneClick}
          initialLoading={!runnersSearchResults}
        />
      </Fragment>
    )
  }

  onTagClick = (field: string, value: string) => {
    const { query } = this.state
    const updatedQuery = toggleField({ query, field, value })

    this.setQuery(updatedQuery!)
  }

  onZoneClick = (zoneId: string) => {
    const { query } = this.state
    const updatedQuery = toggleField({ query, field: 'zone', value: zoneId })

    this.setQuery(updatedQuery!)
  }

  setQuery = (query: Query) => {
    const { runnersSearchResults } = this.props

    const queryText = query.text
    const queryResults = executeQuery({ query, records: runnersSearchResults! })

    this.onChange({ queryText, queryResults })
  }

  onChange = ({ queryText, queryResults }: OnFilterChangeParams<Runner>) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `Runners`, queryText })
  }
}
