/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'

import schedule from '../../lib/schedule'
import { fetchSnapshotRepositoriesRequest, getSnapshotRepositories } from '../../reducers'
import { fetchSnapshotRepositories } from '../../actions/snapshotRepositories'
import { getConfigForKey } from '../../selectors'

import SnapshotRepositoryManagement from './SnapshotRepositoryManagement'

import type { ComponentType } from 'react'
import type { RouteComponentProps } from 'react-router'

type Props = {
  fetchSnapshotRepositories: (regionId: string) => void
  regionId: string
}

const mapStateToProps = (
  state: ReduxState,
  { match: { params } }: RouteComponentProps<{ regionId: string | undefined }>,
) => {
  const regionIdParam = params.regionId
  const defaultRegionId = getConfigForKey(state, `DEFAULT_REGION`)
  const regionId = regionIdParam || defaultRegionId

  return {
    regionId,
    snapshotRepositories: getSnapshotRepositories(state, regionId),
    fetchSnapshotRepositoriesRequest: fetchSnapshotRepositoriesRequest(state, regionId),
  }
}

const scheduledComponent: ComponentType<Props> = schedule(
  SnapshotRepositoryManagement,
  ({ fetchSnapshotRepositories: refreshRepositories, regionId }) => refreshRepositories(regionId),
  [`regionId`],
)

export default connect(mapStateToProps, {
  fetchSnapshotRepositories,
})(scheduledComponent)
