/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { isEmpty, sortBy } from 'lodash'

import {
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { InstanceConfiguration, MetadataItem } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, RegionId } from '@modules/ui-types'
import { CuiAlert, CuiPermissibleControl } from '@modules/cui'
import CopyButton from '@modules/cui/CopyButton'

import SpinButton from '../../SpinButton'
import { getInstanceConfigurationById } from '../../../lib/instanceConfigurations/instanceConfiguration'

import RemovableAllocatorTag from './RemovableAllocatorTag'

import type { WrappedComponentProps, IntlShape } from 'react-intl'

import './allocatorTags.scss'

type Props = {
  addAllocatorTag: (allocator: any, tag: MetadataItem) => Promise<any>
  addAllocatorTagRequest: AsyncRequestState
  allocator: any
  intl: IntlShape
  fetchInstanceConfigurations: (regionId: RegionId) => void
  getRemoveAllocatorTagRequest: (key: string) => AsyncRequestState
  instanceConfigurations: InstanceConfiguration[]
  removeAllocatorTag: (allocator: unknown, key: string) => void
  resetAddAllocatorTagRequest: (regionId: RegionId, id: string) => void
  resetRemoveAllocatorTagRequest: (regionId: RegionId, id: string, key: string) => void
}

const messages = defineMessages({
  keyPlaceholder: {
    id: `allocatorTags.key.placeholder`,
    defaultMessage: `Key`,
  },
  valuePlaceholder: {
    id: `allocatorTags.value.placeholder`,
    defaultMessage: `Value`,
  },
})

class AllocatorTags extends Component<Props & WrappedComponentProps, unknown> {
  state = getInitialState()

  componentDidMount() {
    const { fetchInstanceConfigurations, allocator } = this.props
    fetchInstanceConfigurations(allocator.regionId)
  }

  componentWillUnmount() {
    const { allocator, resetRemoveAllocatorTagRequest } = this.props

    allocator.tags.forEach((tag) =>
      resetRemoveAllocatorTagRequest(allocator.regionId, allocator.id, tag.key),
    )
  }

  render() {
    return (
      <Fragment>
        <EuiTitle size='s'>
          <h5>
            <FormattedMessage id='allocator-overview.tags-label' defaultMessage='Allocator tags' />
          </h5>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText color='subdued' size='s'>
          <FormattedMessage
            id='allocator-overview.tags-description'
            defaultMessage='Use tags to describe and group similar allocators together.'
          />
        </EuiText>

        <EuiSpacer size='m' />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const { allocator, getRemoveAllocatorTagRequest, removeAllocatorTag, instanceConfigurations } =
      this.props

    if (instanceConfigurations == null) {
      return <EuiSkeletonText />
    }

    const instancesWithConfigurations = allocator.instances.map((instance) =>
      Object.assign(
        {
          instanceConfiguration: getInstanceConfigurationById(
            instanceConfigurations,
            instance.instanceConfigurationId,
          ),
        },
        instance,
      ),
    )

    const allocatorTags = sortBy(allocator.tags, `key`)

    return (
      <Fragment>
        {isEmpty(allocatorTags) ? (
          <FormattedMessage
            id='allocator-overview.allocator-has-no-tags'
            defaultMessage='This allocator has no tags.'
          />
        ) : (
          <EuiFlexGrid gutterSize='s'>
            {allocatorTags.map((tag) => {
              const removeAllocatorTagRequest = getRemoveAllocatorTagRequest(tag.key)

              return (
                <EuiFlexItem key={tag.key} grow={false}>
                  <EuiFlexGroup responsive={false} gutterSize='s' alignItems='center'>
                    <EuiFlexItem grow={false}>
                      <RemovableAllocatorTag
                        instances={instancesWithConfigurations}
                        onRemove={() => removeAllocatorTag(allocator, tag.key)}
                        regionId={allocator.regionId}
                        spin={removeAllocatorTagRequest.inProgress}
                        tag={tag}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                      <CopyButton size='s' value={tag.value} asIconLink={true} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              )
            })}
          </EuiFlexGrid>
        )}

        {allocatorTags
          .map((tag) => getRemoveAllocatorTagRequest(tag.key))
          .filter((request) => request.error)
          .map((request, index) => (
            <Fragment key={index}>
              <EuiSpacer size='m' />

              <CuiAlert type='error'>{request.error}</CuiAlert>
            </Fragment>
          ))}

        <EuiSpacer size='m' />

        {this.renderCreateForm()}
      </Fragment>
    )
  }

  renderCreateForm() {
    const {
      intl: { formatMessage },
      addAllocatorTagRequest,
    } = this.props

    const { newTagKey, newTagValue, showKeyError, showValueError } = this.state

    return (
      <Fragment>
        <div className='allocatorTags-addForm'>
          <EuiFlexGroup gutterSize='m'>
            <EuiFlexItem grow={false}>
              <EuiFieldText
                className='allocatorTags-addInput'
                onChange={(e) => this.setState({ newTagKey: e.target.value, showKeyError: false })}
                placeholder={formatMessage(messages.keyPlaceholder)}
                isInvalid={showKeyError}
                value={newTagKey}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFieldText
                className='allocatorTags-addInput'
                onChange={(e) =>
                  this.setState({ newTagValue: e.target.value, showValueError: false })
                }
                placeholder={formatMessage(messages.valuePlaceholder)}
                isInvalid={showValueError}
                value={newTagValue}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <CuiPermissibleControl permissions={Permission.setAllocatorMetadata}>
                <SpinButton
                  color='primary'
                  className='allocatorTags-addBtn'
                  onClick={() => this.addTag()}
                  spin={addAllocatorTagRequest.inProgress}
                >
                  <FormattedMessage id='allocator-tags.add-tag' defaultMessage='Add tag' />
                </SpinButton>
              </CuiPermissibleControl>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>

        {addAllocatorTagRequest.error && (
          <div>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{addAllocatorTagRequest.error}</CuiAlert>
          </div>
        )}
      </Fragment>
    )
  }

  addTag() {
    const { allocator, addAllocatorTag, resetAddAllocatorTagRequest } = this.props
    const { newTagKey, newTagValue } = this.state
    const tag = { key: newTagKey, value: newTagValue }

    const hasKeyError = isValueInvalid(newTagKey)
    const hasValueError = isValueInvalid(newTagValue)

    if (hasKeyError || hasValueError) {
      this.setState({
        showValueError: hasValueError,
        showKeyError: hasKeyError,
      })
      return
    }

    this.setState(getInitialState())

    return addAllocatorTag(allocator, tag).then(() =>
      resetAddAllocatorTagRequest(allocator.regionId, allocator.id),
    )
  }
}

function getInitialState() {
  return {
    newTagKey: ``,
    newTagValue: ``,
    showKeyError: false,
    showValueError: false,
  }
}

function isValueInvalid(value) {
  return value === null || value.trim() === ``
}

export default injectIntl(AllocatorTags)
