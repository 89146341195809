/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
  InstanceTypeResource,
  DeploymentCreateRequest,
  KibanaClusterTopologyElement,
  ApmTopologyElement,
  EnterpriseSearchTopologyElement,
  IntegrationsServerTopologyElement,
} from '@modules/cloud-api/v1/types'
import type {
  RegionId,
  NodeAttributes,
  NodeRole,
  SliderNodeType,
  WellKnownSliderInstanceType,
  AllNodeRoles,
} from '@modules/ui-types'

import { getAutoscalingMaxForInstanceTemplate } from '../stackDeployments/selectors'
import { isRoleAnyDataRole } from '../stackDeployments/selectors/nodeRoles'

type CreateDefaultDeploymentTemplateParams = {
  region: RegionId
  instanceConfigurations: InstanceConfiguration[]
  instanceTypes: InstanceTypeResource[]
}

export const DEFAULT_DEDICATED_MASTERS_THRESHOLD = 3

export function createDefaultDeploymentCreateRequest({
  region,
  instanceConfigurations,
  instanceTypes,
}: CreateDefaultDeploymentTemplateParams): DeploymentCreateRequest {
  const hot = createDefaultTopologyElement({
    id: 'hot_content',
    sliderInstanceType: `elasticsearch`,
    sliderNodeType: `data`,
    instanceTypes,
    instanceConfigurations,
    nodeAttributes: { data: 'hot' },
    nodeRoles: [
      'master',
      'ingest',
      'data_hot',
      'data_content',
      'remote_cluster_client',
      'transform',
    ],
  })

  const warm = createDefaultTopologyElement({
    id: 'warm',
    sliderInstanceType: `elasticsearch`,
    sliderNodeType: `data`,
    instanceTypes,
    instanceConfigurations,
    overrideInstanceConfigurationId: 'data.highstorage',
    nodeAttributes: { data: 'warm' },
    nodeRoles: ['data_warm', 'remote_cluster_client'],
  })

  const cold = createDefaultTopologyElement({
    id: 'cold',
    sliderInstanceType: `elasticsearch`,
    sliderNodeType: `data`,
    instanceTypes,
    instanceConfigurations,
    overrideInstanceConfigurationId: 'data.highstorage',
    nodeAttributes: { data: 'cold' },
    nodeRoles: ['data_cold', 'remote_cluster_client'],
  })

  const frozen = createDefaultTopologyElement({
    id: 'frozen',
    sliderInstanceType: `elasticsearch`,
    sliderNodeType: `data`,
    instanceTypes,
    instanceConfigurations,
    overrideInstanceConfigurationId: 'data.frozen',
    nodeAttributes: { data: 'frozen' },
    nodeRoles: ['data_frozen'],
  })

  const master = createDefaultTopologyElement({
    id: 'master',
    sliderInstanceType: `elasticsearch`,
    sliderNodeType: `master`,
    instanceTypes,
    instanceConfigurations,
    nodeRoles: ['master', 'remote_cluster_client'],
  })

  const ingest = createDefaultTopologyElement({
    id: 'coordinating',
    sliderInstanceType: `elasticsearch`,
    sliderNodeType: `ingest`,
    instanceTypes,
    instanceConfigurations,
    nodeRoles: ['ingest', 'remote_cluster_client'],
  })

  const ml = createDefaultTopologyElement({
    id: 'ml',
    sliderInstanceType: `elasticsearch`,
    sliderNodeType: `ml`,
    instanceTypes,
    instanceConfigurations,
    nodeRoles: ['ml', 'remote_cluster_client'],
  })

  return {
    settings: {
      autoscaling_enabled: false,
    },
    resources: {
      elasticsearch: [
        {
          region,
          ref_id: 'main-elasticsearch',
          plan: {
            cluster_topology: [hot, warm, cold, frozen, master, ingest, ml],
            elasticsearch: {},
          },
          settings: {
            dedicated_masters_threshold: DEFAULT_DEDICATED_MASTERS_THRESHOLD,
          },
        },
      ],
      kibana: [
        {
          region,
          ref_id: 'main-kibana',
          elasticsearch_cluster_ref_id: 'main-elasticsearch',
          plan: {
            cluster_topology: [
              createDefaultTopologyElement({
                sliderInstanceType: `kibana`,
                instanceTypes,
                instanceConfigurations,
              }),
            ],
            kibana: {},
          },
        },
      ],
      apm: [
        {
          region,
          ref_id: 'main-apm',
          elasticsearch_cluster_ref_id: 'main-elasticsearch',
          plan: {
            cluster_topology: [
              createDefaultTopologyElement({
                sliderInstanceType: `apm`,
                instanceTypes,
                instanceConfigurations,
              }),
            ],
            apm: {},
          },
        },
      ],
      integrations_server: [
        {
          region,
          ref_id: 'main-integrations_server',
          elasticsearch_cluster_ref_id: 'main-elasticsearch',
          plan: {
            cluster_topology: [
              createDefaultTopologyElement({
                sliderInstanceType: `integrations_server`,
                instanceTypes,
                instanceConfigurations,
              }),
            ],
            integrations_server: {},
          },
        },
      ],
      enterprise_search: [
        {
          region,
          ref_id: 'main-enterprise_search',
          elasticsearch_cluster_ref_id: 'main-elasticsearch',
          plan: {
            cluster_topology: [
              createDefaultTopologyElement({
                sliderInstanceType: `enterprise_search`,
                instanceTypes,
                instanceConfigurations,
              }),
            ],
            enterprise_search: {},
          },
        },
      ],
    },
  }
}

type CreateDefaultTopologyElementParams = {
  id?: string
  sliderInstanceType: WellKnownSliderInstanceType
  sliderNodeType?: SliderNodeType
  overrideInstanceConfigurationId?: string
  instanceTypes: InstanceTypeResource[]
  instanceConfigurations: InstanceConfiguration[]
  nodeAttributes?: NodeAttributes
  nodeRoles?: NodeRole[]
}

function createDefaultTopologyElement(
  args: Omit<CreateDefaultTopologyElementParams, 'sliderInstanceType'> & {
    sliderInstanceType: 'elasticsearch'
  },
): ElasticsearchClusterTopologyElement
function createDefaultTopologyElement(
  args: Omit<CreateDefaultTopologyElementParams, 'sliderInstanceType'> & {
    sliderInstanceType: 'kibana'
  },
): KibanaClusterTopologyElement
function createDefaultTopologyElement(
  args: Omit<CreateDefaultTopologyElementParams, 'sliderInstanceType'> & {
    sliderInstanceType: 'apm'
  },
): ApmTopologyElement
function createDefaultTopologyElement(
  args: Omit<CreateDefaultTopologyElementParams, 'sliderInstanceType'> & {
    sliderInstanceType: 'integrations_server'
  },
): IntegrationsServerTopologyElement
function createDefaultTopologyElement(
  args: Omit<CreateDefaultTopologyElementParams, 'sliderInstanceType'> & {
    sliderInstanceType: 'enterprise_search'
  },
): EnterpriseSearchTopologyElement

function createDefaultTopologyElement({
  id,
  overrideInstanceConfigurationId,
  sliderInstanceType,
  sliderNodeType,
  instanceTypes,
  instanceConfigurations,
  nodeAttributes,
  nodeRoles,
}: CreateDefaultTopologyElementParams):
  | ElasticsearchClusterTopologyElement
  | KibanaClusterTopologyElement
  | ApmTopologyElement
  | IntegrationsServerTopologyElement
  | EnterpriseSearchTopologyElement {
  //
  // Determine the default instance config
  //

  const defaultInstanceConfiguration: InstanceConfiguration | undefined =
    getInstanceConfigForId(getInstanceConfigIdByExplicitOverride()) ||
    getInstanceConfigForId(getInstanceConfigIdByExplicitDefault()) ||
    getInstanceConfigForId(getInstanceConfigIdByFirstCompatible())

  function getInstanceConfigIdByExplicitOverride() {
    return overrideInstanceConfigurationId
  }

  function getInstanceConfigIdByExplicitDefault() {
    const instanceType = instanceTypes.find(
      ({ instance_type }) => instance_type === sliderInstanceType,
    )

    if (instanceType == null) {
      throw new Error(`Couldn't find an instance type for '${sliderInstanceType}'`)
    }

    if (sliderNodeType) {
      return (
        // try for an exact node type match
        instanceType.node_types.find(({ node_type }) => node_type === sliderNodeType)
          ?.default_instance_configuration_id ||
        // otherwise fall back to `data`
        instanceType.node_types.find(({ node_type }) => node_type === `data`)
          ?.default_instance_configuration_id
      )
    }

    return instanceType?.default_instance_configuration_id
  }

  function getInstanceConfigIdByFirstCompatible() {
    return instanceConfigurations.find(
      (each) => each.instance_type === sliderInstanceType && each.deleted_on == null,
    )?.id
  }

  function getInstanceConfigForId(idToFind: string | undefined) {
    return instanceConfigurations.find((instanceConfig) => instanceConfig.id === idToFind)
  }

  if (!defaultInstanceConfiguration) {
    throw new Error(
      `Can't create a default instance template configuration for type: ${sliderInstanceType}`,
    )
  }

  //
  // Build the topology element
  //

  const { default_size, sizes, resource } = defaultInstanceConfiguration.discrete_sizes

  const topologyElement = {
    id,
    instance_configuration_id: defaultInstanceConfiguration.id,
    size: { value: default_size, resource },
    zone_count: 1,
    [sliderInstanceType]: {},
  }

  if (sliderInstanceType === `elasticsearch`) {
    const el = topologyElement as ElasticsearchClusterTopologyElement

    el.elasticsearch!.node_attributes = nodeAttributes || {}

    if (sliderNodeType === 'data' || sliderNodeType === 'ml') {
      el.autoscaling_max = getAutoscalingMaxForInstanceTemplate({
        instanceConfiguration: defaultInstanceConfiguration,
        resource,
      })
    }

    if (sliderNodeType === 'ml') {
      el.autoscaling_min = { value: default_size, resource }
    }

    if (
      sliderNodeType === 'data' ||
      sliderNodeType === 'ml' ||
      sliderNodeType === 'master' ||
      sliderNodeType === 'ingest'
    ) {
      el.topology_element_control = {
        min: {
          value: nodeRoles?.includes('data_hot') ? Math.min(...sizes) : 0,
          resource,
        },
      }
    }
  }

  if (nodeRoles) {
    const el = topologyElement as ElasticsearchClusterTopologyElement

    el.node_roles = nodeRoles
    el.node_type = getNodeTypeForNodeRoles({
      nodeRoles,
      instanceConfiguration: defaultInstanceConfiguration,
    })
  }

  if (id) {
    topologyElement.id = id
  }

  return topologyElement
}

export function getNodeTypeForNodeRoles({
  nodeRoles,
  instanceConfiguration,
}: {
  nodeRoles: AllNodeRoles | undefined
  instanceConfiguration: InstanceConfiguration
}): Record<SliderNodeType, boolean> | undefined {
  const nodeTypes = instanceConfiguration.node_types

  if (!nodeTypes) {
    return
  }

  const nodeType: Record<SliderNodeType, boolean> = {}

  nodeTypes.forEach((type) => {
    if (type === `data`) {
      // data is a special case, as there are many node roles that map to that node type
      if (nodeRoles?.some(isRoleAnyDataRole)) {
        nodeType.data = true
      }
    } else {
      // everything else is a pass-through from the instance config's node_types array to the topology element's node_type object
      nodeType[type] = !nodeRoles || (nodeRoles as string[]).includes(type)
    }
  })

  return nodeType
}
