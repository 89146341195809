/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { RegionID } from '@modules/project-api/v1/types'
import type { ProjectType } from '@modules/ui-types/projects'

import genericMessages from './genericMessages'

import type { MessageDescriptor } from 'react-intl'

const CLOUD_PROVIDER_IDS = ['aws', 'azure', 'gcp', 'local'] as const
type CloudProviderId = typeof CLOUD_PROVIDER_IDS[number]

const projectTypes: Record<ProjectType, MessageDescriptor> = defineMessages({
  elasticsearch: {
    id: 'projects.pretty.type.elasticsearch',
    defaultMessage: 'Elasticsearch',
  },
  observability: {
    id: 'projects.pretty.type.observability',
    defaultMessage: 'Observability',
  },
  security: {
    id: 'projects.pretty.type.security',
    defaultMessage: 'Security',
  },
})

const cloudProviders: Record<CloudProviderId, MessageDescriptor> = defineMessages({
  aws: {
    id: 'projects.pretty.cloudProvider.aws',
    defaultMessage: 'AWS',
  },
  azure: {
    id: 'projects.pretty.cloudProvider.azure',
    defaultMessage: 'Azure',
  },
  gcp: {
    id: 'projects.pretty.cloudProvider.gcp',
    defaultMessage: 'GCP',
  },
  local: {
    id: 'projects.pretty.cloudProvider.local',
    defaultMessage: 'Local',
  },
})

export function getPrettyProjectType(type: ProjectType): MessageDescriptor {
  return projectTypes[type]
}

export function getPrettyCloudProvider(regionId: RegionID): MessageDescriptor {
  const cloudProviderId = regionId.split('-')[0] as CloudProviderId

  if (!CLOUD_PROVIDER_IDS.includes(cloudProviderId)) {
    return genericMessages.unknown
  }

  return cloudProviders[cloudProviderId]
}

export function getOnlyRegionId(regionId: RegionID): string {
  const cloudProviderPrefixMatcher = new RegExp(`^(${CLOUD_PROVIDER_IDS.join('|')})-`)
  return regionId.replace(cloudProviderPrefixMatcher, '')
}
