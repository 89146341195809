/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sortBy, find } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import {
  EuiIcon,
  EuiLink,
  EuiTitle,
  EuiText,
  EuiSpacer,
  EuiHealth,
  EuiFormLabel,
  EuiFlexItem,
  EuiFlexGroup,
  EuiSkeletonText,
  EuiToolTip,
} from '@elastic/eui'

import type { ProxyInfo } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, Runner, RegionProxies } from '@modules/ui-types'
import { CuiAlert, withErrorBoundary } from '@modules/cui'

import ResourceComments from '../../ResourceComments'
import { hasProxyRole } from '../../../lib/hostRoles'
import { getSliderPrettyName, getSliderColor, getSliderWeight } from '../../../lib/sliders'
import toPercentage from '../../../lib/toPercentage'
import schedule from '../../../lib/schedule'

import type { IntlShape } from 'react-intl'

import './proxy.scss'

type Props = {
  intl: IntlShape
  regionId: string
  hostId: string
  runner?: Runner
  proxies?: RegionProxies
  proxiesRequest: AsyncRequestState
  fetchProxies: () => void
  startManagingRoles: () => void
}

const messages = defineMessages({
  allocationCountToolTip: {
    id: 'proxy-host.allocation-count-tool-tip',
    defaultMessage: '{count} {name} {count, plural, one {allocation} other {allocations}}',
  },
})

class Proxy extends Component<Props> {
  render() {
    return (
      <Fragment>
        <EuiTitle>
          <h3>
            <FormattedMessage id='proxy-host.title' defaultMessage='Proxy' />
          </h3>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText color='subdued'>
          <FormattedMessage
            id='proxy-host.description'
            defaultMessage='Routes user requests to instances in Elastic Stack deployments.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        {this.renderAlerts()}

        {this.renderProxyDetails()}
      </Fragment>
    )
  }

  renderProxyDetails() {
    const { proxies } = this.props

    if (!proxies) {
      return <EuiSkeletonText />
    }

    const proxyInfo = this.getProxyInfo()

    if (!proxyInfo) {
      return null
    }

    return (
      <Fragment>
        {this.renderProxyStatus(proxyInfo)}

        <EuiSpacer size='xl' />

        {this.renderProxyConnections(proxyInfo)}

        <EuiSpacer size='xl' />

        {this.renderProxyRequestCharts(proxyInfo)}

        <EuiSpacer size='l' />

        {this.renderComments()}
      </Fragment>
    )
  }

  renderProxyStatus(proxyInfo: ProxyInfo) {
    const { healthy } = proxyInfo

    return (
      <EuiFlexGroup gutterSize='l' alignItems='flexStart'>
        <EuiFlexItem grow={false}>
          <EuiFormLabel>
            <FormattedMessage id='proxy-host.health-label' defaultMessage='Proxy status' />
          </EuiFormLabel>

          <EuiSpacer size='s' />

          <EuiHealth color={healthy ? 'success' : 'danger'}>
            {healthy ? (
              <FormattedMessage id='proxy-host.health-healthy-label' defaultMessage='Healthy' />
            ) : (
              <FormattedMessage id='proxy-host.health-unhealthy-label' defaultMessage='Unhealthy' />
            )}
          </EuiHealth>
        </EuiFlexItem>

        {/* TODO: proxy build hash and version */}
      </EuiFlexGroup>
    )
  }

  renderProxyConnections(proxyInfo: ProxyInfo) {
    const {
      intl: { formatMessage },
    } = this.props
    const { allocations } = proxyInfo
    const totalAllocations = allocations.reduce(
      (all, allocation) => all + allocation.counts.allocations,
      0,
    )

    const allocationCounts = sortBy(
      allocations.map(({ allocations_type: sliderInstanceType, counts }) => ({
        sliderInstanceType,
        color: getSliderColor(sliderInstanceType),
        name: <FormattedMessage {...getSliderPrettyName({ sliderInstanceType })} />,
        count: counts.allocations,
      })),
      (allocationCount) => getSliderWeight(allocationCount.sliderInstanceType),
    )

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiTitle size='s'>
              <h5>
                <FormattedMessage
                  id='proxy-host.allocated-proxy-connections-label'
                  defaultMessage='Allocated instance connections'
                />
              </h5>
            </EuiTitle>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiText size='s' color='subdued'>
              {totalAllocations}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <div className='hostProxy-allocationViz'>
          {allocationCounts.map(({ sliderInstanceType, color, name, count }) => (
            <span
              key={sliderInstanceType}
              className='hostProxy-allocationVizSection'
              style={{
                width: `${toPercentage(count / totalAllocations)}%`,
                backgroundColor: color,
              }}
            >
              <EuiToolTip
                content={formatMessage(messages.allocationCountToolTip, { name, count })}
                position='bottom'
              >
                <span />
              </EuiToolTip>
            </span>
          ))}
        </div>

        <EuiSpacer size='l' />

        {allocationCounts.map(({ sliderInstanceType, color, name, count }, index) => (
          <Fragment key={sliderInstanceType}>
            {index > 0 && <EuiSpacer size='s' />}

            <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiIcon type='dot' color={color} />
              </EuiFlexItem>

              <EuiFlexItem grow={false} style={{ width: '200px', maxWidth: '100%' }}>
                {name}
              </EuiFlexItem>

              <EuiFlexItem grow={false}>{count}</EuiFlexItem>
            </EuiFlexGroup>
          </Fragment>
        ))}
      </Fragment>
    )
  }

  renderProxyRequestCharts(_proxyInfo: ProxyInfo) {
    return <Fragment>{/* TODO: proxy request charts */}</Fragment>
  }

  renderAlerts() {
    return (
      <Fragment>
        {this.renderMissingRoleWarning()}

        {this.renderMissingProxyError()}
      </Fragment>
    )
  }

  renderMissingProxyError() {
    const { proxies } = this.props
    const proxyInfo = this.getProxyInfo()

    if (!proxies || proxyInfo) {
      return null
    }

    return (
      <Fragment>
        <CuiAlert type='error' iconType='alert'>
          <FormattedMessage
            id='proxy-host.proxy-not-found'
            defaultMessage="We couldn't find a proxy associated with this host."
          />
        </CuiAlert>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderMissingRoleWarning() {
    const { runner, startManagingRoles } = this.props

    if (hasProxyRole({ runner })) {
      return null
    }

    return (
      <Fragment>
        <CuiAlert type='warning' iconType='cloudStormy'>
          <FormattedMessage
            id='proxy-host.host-not-a-proxy'
            defaultMessage="This host doesn't have the Proxy role. You can add it in {manageRoles}."
            values={{
              manageRoles: (
                <EuiLink onClick={startManagingRoles}>
                  <FormattedMessage id='proxy-host.manage-roles' defaultMessage='Manage roles' />
                </EuiLink>
              ),
            }}
          />
        </CuiAlert>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderComments() {
    const { regionId, hostId } = this.props

    return (
      <Fragment>
        <EuiTitle size='s'>
          <h5>
            <FormattedMessage id='proxy-host.comments-label' defaultMessage='Comments' />
          </h5>
        </EuiTitle>

        <EuiSpacer size='s' />

        <EuiText size='s' color='subdued'>
          <FormattedMessage
            id='proxy-host.comments-description'
            defaultMessage='Leave notes for yourself for later or for other system administrators.'
          />
        </EuiText>

        <EuiSpacer size='m' />

        <ResourceComments
          spacerBefore={true}
          resourceType='proxy'
          regionId={regionId}
          resourceId={hostId}
        />
      </Fragment>
    )
  }

  getProxyInfo(): ProxyInfo | null {
    const { proxies, hostId } = this.props

    if (!proxies) {
      return null
    }

    const proxy = find(proxies.proxies, (proxy) => proxy.proxyInfo.runner_id === hostId)

    if (!proxy) {
      return null
    }

    return proxy.proxyInfo
  }
}

export default withErrorBoundary(
  schedule(injectIntl(Proxy), ({ fetchProxies }) => fetchProxies(), [[`regionId`]]),
)
