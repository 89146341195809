/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { css } from '@emotion/react'

import { EuiCallOut, EuiCheckbox, EuiHorizontalRule, EuiText, EuiTitle } from '@elastic/eui'

import type {
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  DeploymentTemplateInfoV2,
  ElasticsearchClusterPlan,
} from '@modules/cloud-api/v1/types'

import { setAutoscalingEnabled, setAutoscalingDisabled } from '@/lib/stackDeployments/autoscaling'
import { isAutoscalingEnabled } from '@/lib/stackDeployments/selectors'
import { getBlankNodeConfigurationPerTemplate } from '@/lib/stackDeployments'

import DocLink from '../DocLink'

import type { WrappedComponentProps } from 'react-intl'

interface Props extends WrappedComponentProps {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  deploymentTemplate?: DeploymentTemplateInfoV2
  onChangeAutoscaling: (deployment: DeploymentCreateRequest | DeploymentUpdateRequest) => void
  showToggle?: boolean
}

class AutoscalingToggle extends Component<Props> {
  render() {
    const { deployment, showToggle = true } = this.props
    const autoscalingEnabled = isAutoscalingEnabled({ deployment })

    return (
      <Fragment>
        {showToggle ? this.showCheckbox(autoscalingEnabled) : this.showAutoscalingNotAvailable()}
        <EuiHorizontalRule />
      </Fragment>
    )
  }

  showAutoscalingNotAvailable = () => (
    <EuiCallOut color='warning' data-test-id='autoscaling-not-available-on-snapshot-restore'>
      <FormattedMessage
        id='autoscaling-toggle.non-available'
        defaultMessage='Autoscaling is not available when creating a new deployment from a snapshot restore. Make sure that the deployment is correctly set to handle
        the size of the restored snapshot. You will be able to turn on autoscaling after the snapshot is successfully restored into your new deployment.'
      />
    </EuiCallOut>
  )

  showCheckbox = (autoscalingEnabled) => (
    <EuiCheckbox
      id='autoscaling-enable'
      data-test-id='autoscaling-enable'
      css={css({ zIndex: 0 })}
      label={
        <Fragment>
          <EuiTitle size='xxs'>
            <h3>
              <FormattedMessage
                id='autoscaling-toggle.label'
                defaultMessage='Autoscale this deployment'
              />
            </h3>
          </EuiTitle>
          <EuiText color='subdued' size='s'>
            <FormattedMessage
              id='autoscaling-toggle.description'
              defaultMessage='Scale the capacity of your data and machine learning nodes automatically. {learnMore}'
              values={{
                learnMore: (
                  <DocLink link='autoscalingEnableDocLink'>
                    <FormattedMessage
                      id='autoscaling-toggle.description.learn-more'
                      defaultMessage='Learn more'
                    />
                  </DocLink>
                ),
              }}
            />
          </EuiText>
        </Fragment>
      }
      checked={autoscalingEnabled}
      onChange={this.onChange}
    />
  )

  onChange = () => {
    const { deployment, deploymentTemplate, onChangeAutoscaling } = this.props

    const blankTemplate = getBlankNodeConfigurationPerTemplate({
      sliderInstanceType: 'elasticsearch',
      deploymentTemplate: deploymentTemplate!,
    }) as ElasticsearchClusterPlan

    const autoscalingEnabled = isAutoscalingEnabled({ deployment })
      ? setAutoscalingDisabled({ deployment })
      : setAutoscalingEnabled({
          deployment,
          blankTemplate,
        })

    if (!autoscalingEnabled.resources || !autoscalingEnabled.resources.elasticsearch) {
      return
    }

    onChangeAutoscaling(autoscalingEnabled)
  }
}

export default injectIntl(AutoscalingToggle)
