/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation } from 'react-query'

import {
  deleteElasticsearchProject,
  deleteObservabilityProject,
  deleteSecurityProject,
} from '@modules/project-api/v1/callers'
import type { ProjectID } from '@modules/project-api/v1/types'
import type { QueryFunctionReturnType } from '@modules/query/types'
import type { ProjectType } from '@modules/ui-types/projects'
import { queryClient } from '@modules/query'

import { makeQueryKey } from './helpers'

/**
 * Returns the appropriate hook for the provided project type.
 */
export function getDeleteProjectMutationHook(
  projectType: ProjectType,
):
  | typeof useDeleteElasticsearchProjectMutation
  | typeof useDeleteObservabilityProjectMutation
  | typeof useDeleteSecurityProjectMutation {
  switch (projectType) {
    case 'observability':
      return useDeleteObservabilityProjectMutation
    case 'security':
      return useDeleteSecurityProjectMutation
    default:
      return useDeleteElasticsearchProjectMutation
  }
}

const useDeleteElasticsearchProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof deleteElasticsearchProject>,
    unknown,
    { id: ProjectID }
  >({
    mutationFn: ({ id }) =>
      deleteElasticsearchProject({
        pathParameters: { id },
      }),
    onSuccess: (_, { id }) => onProjectDeleted('elasticsearch', id),
  })

const useDeleteObservabilityProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof deleteObservabilityProject>,
    unknown,
    { id: ProjectID }
  >({
    mutationFn: ({ id }) =>
      deleteObservabilityProject({
        pathParameters: { id },
      }),
    onSuccess: (_, { id }) => onProjectDeleted('observability', id),
  })

const useDeleteSecurityProjectMutation = () =>
  useMutation<QueryFunctionReturnType<typeof deleteSecurityProject>, unknown, { id: ProjectID }>({
    mutationFn: ({ id }) =>
      deleteSecurityProject({
        pathParameters: { id },
      }),
    onSuccess: (_, { id }) => onProjectDeleted('security', id),
  })

const onProjectDeleted = (type: ProjectType, id: ProjectID) => {
  const listQueryKey = makeQueryKey(
    (
      {
        elasticsearch: 'listElasticsearchProjects',
        observability: 'listObservabilityProjects',
        security: 'listSecurityProjects',
      } as const
    )[type],
  )
  queryClient.invalidateQueries(listQueryKey)

  const detailQueryKey = makeQueryKey(
    (
      {
        elasticsearch: 'getElasticsearchProject',
        observability: 'getObservabilityProject',
        security: 'getSecurityProject',
      } as const
    )[type],
    id,
  )
  queryClient.invalidateQueries(detailQueryKey)
}
