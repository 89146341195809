/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { PremiumProduct } from '@modules/cloud-api/v1/types'
import { CuiTable } from '@modules/cui'
import CuiPrice from '@modules/cui/formatters/CuiPrice'
import type { CuiTableColumn } from '@modules/cui'

import type { FunctionComponent } from 'react'

interface Props {
  premiumProducts: PremiumProduct[] | undefined
  initialLoading: boolean
}

const OrganizationPrepaidPremiumProductsTable: FunctionComponent<Props> = ({
  premiumProducts,
  initialLoading,
}) => {
  const columns: Array<CuiTableColumn<PremiumProduct>> = [
    {
      label: (
        <FormattedMessage
          id='organization.organization-premium-products-table.id'
          defaultMessage='ID'
        />
      ),
      render: ({ product_id }) => product_id,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-premium-products-table.identifier'
          defaultMessage='Identifier'
        />
      ),
      render: ({ identifier }) => identifier,
    },
    {
      label: (
        <span id='premium-products-table.price' data-test-id='premium-products-price-table-heading'>
          <FormattedMessage
            id='organization.organization-premium-products-table.price'
            defaultMessage='Price'
          />
        </span>
      ),
      render: ({ price }) => <CuiPrice value={price} />,
      sortKey: 'price',
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-premium-products-table.active-period'
          defaultMessage='Active period'
        />
      ),
      render: ({ start, end }) => `${start} - ${end}`,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-premium-products-table.premium-product-id'
          defaultMessage='Premium product ID'
        />
      ),
      render: ({ premium_product_id }) => premium_product_id,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organization-premium-products-table.data'
          defaultMessage='Data'
        />
      ),
      render: ({ data }) => JSON.stringify(data),
    },
  ]

  return (
    <CuiTable<PremiumProduct>
      rows={premiumProducts}
      columns={columns}
      pageSize={10}
      getRowTestSubj={({ product_id }) => `premium-product-${product_id}`}
      initialLoading={initialLoading}
    />
  )
}

export default OrganizationPrepaidPremiumProductsTable
