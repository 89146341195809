/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import { intersectionWith } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiTitle, EuiSpacer, EuiText, EuiToolTip } from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type {
  Allocator,
  AllocatorInstance,
  AsyncRequestState,
  VacateResult as VacateResultType,
} from '@modules/ui-types'
import { CuiAlert, CuiButton, CuiBasicFilterContext, CuiPermissibleControl } from '@modules/cui'
import type { CuiBasicFilterContextProps } from '@modules/cui'

import AllocatorInstancesTable from '../AllocatorInstancesTable'
import { hasPermission } from '../../../lib/requiresPermission'
import { getSupportedSliderInstanceTypes } from '../../../lib/sliders'

import { getFilters } from './filters'
import VacateResult from './VacateResult'
import VacateNodesModal from './VacateNodesModal'

import type { IntlShape } from 'react-intl'

import './vacate.scss'

export type Props = {
  intl: IntlShape
  allocator: Allocator
  vacateResult?: VacateResultType
  vacateAllocatorRequest: AsyncRequestState
}

type State = {
  selectedInstances: AllocatorInstance[]
  showOptionsModal: boolean
}

const messages = defineMessages({
  selectAnInstance: {
    id: 'allocator-instances-move.select-an-instance',
    defaultMessage: 'Select an instance to move it to a different allocator',
  },
})

class VacateAllocator extends Component<Props, State> {
  state: State = {
    selectedInstances: [],
    showOptionsModal: false,
  }

  componentDidUpdate(prevProps: Props) {
    const { allocator } = this.props
    const allocatorsWereRefreshed = allocator !== prevProps.allocator

    if (allocatorsWereRefreshed) {
      // Whenever there's an allocator change, make sure we
      // don't have nodes chosen that no longer live on
      // this allocator.
      this.ensureSelectedInstancesExistOnAllocator()
    }
  }

  render() {
    const { allocator, vacateResult } = this.props
    const { regionId, instances } = allocator

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiTitle size='s'>
              <h5>
                <FormattedMessage
                  id='allocator-overview.instances-label'
                  defaultMessage='Instances'
                />
              </h5>
            </EuiTitle>
          </EuiFlexItem>

          {instances.length && (
            <EuiFlexItem grow={false}>
              <EuiText color='subdued'>{instances.length}</EuiText>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        <EuiSpacer size='l' />

        {this.renderAllocatorInstancesFilterContext()}

        <VacateResult regionId={regionId} vacateResult={vacateResult} />

        {this.renderMoveNodesOptionsModal()}
      </Fragment>
    )
  }

  renderAllocatorInstancesFilterContext() {
    const { intl, allocator } = this.props

    const supportedTypes = getSupportedSliderInstanceTypes()
    const allocatorInstances = allocator.instances.filter((instance) =>
      supportedTypes.includes(instance.kind),
    )

    if (allocatorInstances.length === 0) {
      return (
        <CuiAlert type='info'>
          <FormattedMessage
            id='allocator-overview.no-nodes-running-on-this-allocator'
            defaultMessage='There are no nodes running on this allocator'
          />
        </CuiAlert>
      )
    }

    const schemaFields: CuiBasicFilterContextProps<AllocatorInstance>['schemaFields'] = [
      'id',
      { id: 'healthy', type: 'boolean' },
      { id: 'type', aliasOf: 'kind' },
      { id: 'name', aliasOf: 'instanceName' },
      { id: 'deployment', aliasOf: 'stackDeploymentId' },
      { id: 'cluster', aliasOf: 'clusterId' },
      { id: 'cluster_name', aliasOf: 'clusterDisplayName' },
      { id: 'healthy_cluster', aliasOf: 'clusterHealthy', type: 'boolean' },
    ]

    const schemaDefaultFields: CuiBasicFilterContextProps<AllocatorInstance>['schemaDefaultFields'] =
      [`id`, `type`, `name`, `deployment`, `cluster`, `cluster_name`]

    const filters = getFilters({
      intl,
    })

    return (
      <CuiBasicFilterContext<AllocatorInstance>
        rows={allocatorInstances}
        schemaFields={schemaFields}
        schemaDefaultFields={schemaDefaultFields}
        filters={filters}
        actions={this.renderMoveInstancesButton()}
      >
        {(filteredInstances?: AllocatorInstance[]) =>
          this.renderInstancesTable({ filteredInstances, unfilteredInstances: allocatorInstances })
        }
      </CuiBasicFilterContext>
    )
  }

  renderInstancesTable = ({
    filteredInstances,
    unfilteredInstances,
  }: {
    filteredInstances?: AllocatorInstance[]
    unfilteredInstances: AllocatorInstance[]
  }) => {
    const { allocator } = this.props
    const { regionId } = allocator
    const { selectedInstances } = this.state

    return (
      <AllocatorInstancesTable
        regionId={regionId}
        instances={filteredInstances}
        selectedInstances={selectedInstances}
        selectInstances={this.selectInstances}
        unfilteredInstances={unfilteredInstances}
        totalCount={unfilteredInstances ? unfilteredInstances.length : undefined}
      />
    )
  }

  renderMoveInstancesButton() {
    const {
      intl: { formatMessage },
    } = this.props
    const { selectedInstances } = this.state

    const isAllowed = hasPermission(Permission.moveClusters)
    const selectedAnyInstances = selectedInstances.length > 0
    const explainHowTo = isAllowed && !selectedAnyInstances

    const moveInstancesButton = (
      <CuiButton
        disabled={!isAllowed || !selectedAnyInstances}
        onClick={this.showOptionsModal}
        data-test-id='move-node-button'
      >
        <FormattedMessage
          id='allocator-vacate.move-instances-configure'
          defaultMessage='Move instances'
        />
      </CuiButton>
    )

    return (
      <CuiPermissibleControl permissions={Permission.moveClusters}>
        {explainHowTo ? (
          <EuiToolTip content={formatMessage(messages.selectAnInstance)} position='bottom'>
            {moveInstancesButton}
          </EuiToolTip>
        ) : (
          moveInstancesButton
        )}
      </CuiPermissibleControl>
    )
  }

  renderMoveNodesOptionsModal() {
    const { allocator } = this.props

    const { showOptionsModal, selectedInstances } = this.state

    if (!showOptionsModal) {
      return null
    }

    return (
      <VacateNodesModal
        allocator={allocator}
        nodes={selectedInstances}
        close={this.closeOptionsModal}
      />
    )
  }

  showOptionsModal = () => {
    this.setState({ showOptionsModal: true })
  }

  closeOptionsModal = ({ clearSelection }) => {
    this.setState({ showOptionsModal: false })

    if (clearSelection) {
      this.setState({ selectedInstances: [] })
    }
  }

  selectInstances = (selectedInstances) => {
    this.setState({ selectedInstances })
  }

  ensureSelectedInstancesExistOnAllocator() {
    const { allocator } = this.props
    const { selectedInstances } = this.state

    this.setState({
      selectedInstances: intersectionWith<AllocatorInstance, AllocatorInstance>(
        selectedInstances,
        allocator.instances,
        (left, right) => left.id === right.id,
      ),
    })
  }
}

export default injectIntl(VacateAllocator)
