/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import PricedArchitectureSummary from '../../../apps/userconsole/components/Topology/PricedArchitectureSummary'
import { resetUpdateDeployment } from '../../../actions/stackDeployments'
import { fetchVersion } from '../../../actions/elasticStack'
import { fetchRegionIfNeeded } from '../../../actions/regions'
import {
  fetchVersionRequest,
  getInstanceConfigurations,
  getRegion,
  getActiveVersionStacks,
  fetchProvidersRequest,
} from '../../../reducers'
import { withStackDeploymentRouteParams } from '../routing'
import { getConfigForKey, isFeatureActivated } from '../../../selectors'

import EditStackDeploymentEditor from './EditStackDeploymentEditor'

import type { ReduxState } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { regionId }: ConsumerProps): StateProps => ({
  hideAdvancedEdit: !isFeatureActivated(state, Feature.showAdvancedEditor),
  region: getRegion(state, regionId),
  esVersions: getActiveVersionStacks(state, regionId),
  esVersionsRequest: fetchVersionRequest(state, regionId),
  providersRequest: fetchProvidersRequest(state, regionId),
  hideConfigChangeStrategy: isFeatureActivated(state, Feature.hideConfigChangeStrategy),
  architectureSummary:
    getConfigForKey(state, `CLOUD_UI_APP`) === `saas-userconsole`
      ? PricedArchitectureSummary
      : undefined,
  instanceConfigurations: getInstanceConfigurations(state, regionId),
})

const mapDispatchToProps: DispatchProps = {
  fetchRegionIfNeeded,
  fetchVersion,
  resetUpdateDeployment,
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(EditStackDeploymentEditor),
)
