/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEqual } from 'lodash'

import type { EuiCallOutProps } from '@elastic/eui'
import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import type { ProcedureState } from '@/types/redux'
import type { FunctionComponent, ReactNode } from 'react'

type Props = {
  procedureId: string
  procedure?: ProcedureState
  successMessage?: ReactNode
  successColor?: EuiCallOutProps['color']
}

const StoredProcedureResult: FunctionComponent<Props> = ({
  procedureId,
  procedure,
  successMessage = (
    <FormattedMessage id='stored-procedure-result.success' defaultMessage='Success!' />
  ),
  successColor = `primary`,
}) => {
  if (!procedure || !procedure.result) {
    return null
  }

  const success = hasSuccessWithoutRows({ procedureId, procedure })

  if (success) {
    return (
      <Fragment>
        <EuiSpacer size='m' />

        <EuiCallOut color={successColor} title={successMessage} />
      </Fragment>
    )
  }

  const { result } = procedure

  // TODO actual table
  return (
    <Fragment>
      <EuiSpacer size='m' />

      <div>{JSON.stringify(result, null, 2)}</div>
    </Fragment>
  )
}

function hasSuccessWithoutRows({ procedureId, procedure }) {
  const { ok, result } = procedure

  if (!ok) {
    return false
  }

  if (!Array.isArray(result)) {
    return false
  }

  if (result.length > 1) {
    return false
  }

  const [firstRow] = result

  const emptySuccessProcedure = [`set_delete_request`].includes(procedureId)

  if (emptySuccessProcedure) {
    return true
  }

  const okayRow = isEqual(firstRow, { status: `ok` })

  return okayRow
}

export default StoredProcedureResult
