/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiEmptyPrompt,
  EuiText,
  EuiTitle,
  EuiSpacer,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import { CuiPermissibleControl } from '@modules/cui'

import GenerateApiKeyButton from '../ApiKeys/GenerateApiKeyButton'
import DocLink from '../DocLink'

import type { FunctionComponent } from 'react'

type Props = {
  loading: boolean
  onClick: () => void
}

const EmptyApiKeysTable: FunctionComponent<Props> = ({ loading, onClick }) => {
  if (loading) {
    return (
      <Fragment>
        <EuiSpacer size='l' />

        <EuiFlexGroup gutterSize='s' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size='s' />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <FormattedMessage
              id='empty-api-keys-table.loading-api-keys'
              defaultMessage='Loading API keys…'
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <EuiSpacer size='l' />

      <EuiEmptyPrompt
        style={{ maxWidth: `50em` }}
        title={
          <h3>
            <FormattedMessage
              id='empty-api-keys-table.create-api-keys'
              defaultMessage='Create an API key'
              data-test-id='empty-api-keys-table.create-api-keys'
            />
          </h3>
        }
        color='subdued'
        body={
          <EuiText>
            <p>
              <FormattedMessage
                id='api-keys.overview-description'
                defaultMessage='An API key allows you to perform most of the operations available in the UI console through API calls. You can create and manage deployments, configure remote clusters, set up traffic filters, manage extensions, and much more.'
              />
            </p>
          </EuiText>
        }
        actions={
          <CuiPermissibleControl permissions={[Permission.createApiKey]}>
            <GenerateApiKeyButton onClick={onClick} />
          </CuiPermissibleControl>
        }
        footer={
          <Fragment>
            <EuiTitle size='xxs'>
              <span>
                <FormattedMessage
                  id='api-keys.overview.want-learn-more'
                  defaultMessage='Want to learn more?'
                />
              </span>
            </EuiTitle>
            &nbsp;&nbsp;
            <DocLink link='apiKeysDocLink' showExternalLinkIcon={true}>
              <FormattedMessage
                id='api-keys.overview.documentation'
                defaultMessage='Read documentation'
              />
            </DocLink>
          </Fragment>
        }
      />
    </Fragment>
  )
}

export default EmptyApiKeysTable
