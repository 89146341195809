/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import type { PropsOf } from '@elastic/eui'
import { EuiCallOut, EuiCheckbox, EuiFormLabel, EuiSpacer, EuiText } from '@elastic/eui'

import type { RegionRolesState } from '@modules/ui-types'
import { CuiAlert, CuiLink } from '@modules/cui'

import RoleSwitch from '../RoleSwitch'
import {
  getNonControlPlaneBuiltInRoles,
  getControlPlaneRoles,
  getCustomRoles,
  getNamedRole,
} from '../../../lib/hostRoles'
import { hostAllocatorMoveNodesUrl } from '../../../lib/urlBuilder'

import type { Props as RoleSwitchProps } from '../RoleSwitch'

import './manageHostRolesForm.scss'

type EuiSpacerProps = PropsOf<typeof EuiSpacer>

interface Props {
  regionId: string
  hostId: string
  roles: RegionRolesState[]
  currentRoles: string[]
  toggleRole: (...roleIds: string[]) => void
  allocatorHoldsInstances: boolean
}

class ManageHostRolesForm extends Component<Props> {
  render() {
    const { roles, allocatorHoldsInstances } = this.props
    const builtInRoles = getNonControlPlaneBuiltInRoles({ roles })
    const controlPlaneRoles = getControlPlaneRoles({ roles })
    const customRoles = getCustomRoles({ roles })

    return (
      <Fragment>
        {builtInRoles.map((builtInRole) => (
          <Fragment key={builtInRole.id}>
            {this.renderRoleSwitch(builtInRole, {
              disabled: builtInRole.id === 'allocator' && allocatorHoldsInstances,
            })}

            {builtInRole.id === 'allocator' &&
              allocatorHoldsInstances &&
              this.isSelectedRole(builtInRole) &&
              this.renderUnremovableAllocatorRoleWarning()}
          </Fragment>
        ))}

        {controlPlaneRoles.length && (
          <Fragment>
            {this.renderControlPlaneRoleSwitch()}

            {controlPlaneRoles.map((controlPlaneRole) => (
              <div key={controlPlaneRole.id} className='rolesForm-controlPlaneRoles'>
                {this.renderRoleSwitch(controlPlaneRole, {
                  disabled: this.areAllControlPlaneRolesUnselected(),
                })}
              </div>
            ))}
          </Fragment>
        )}

        {this.areAnyBuiltInRolesSelected() && this.areAnyControlPlaneRolesSelected() && (
          <Fragment>
            <EuiSpacer size='xl' />

            <EuiCallOut
              color='warning'
              title={
                <FormattedMessage
                  id='roles-form.mixed-roles'
                  defaultMessage='We strongly recommend that the control plane roles be on a separate host than the host with the allocator and proxy roles.'
                />
              }
            />
          </Fragment>
        )}

        {Boolean(customRoles.length) && (
          <Fragment>
            {Boolean(builtInRoles.length || controlPlaneRoles.length) && (
              <Fragment>
                <EuiSpacer size='xxl' />
                <EuiSpacer size='xxl' />
              </Fragment>
            )}

            <EuiFormLabel>
              <FormattedMessage id='roles-form.custom-roles' defaultMessage='Custom roles' />
            </EuiFormLabel>

            {customRoles.map((customRole) => (
              <Fragment key={customRole.id}>
                {this.renderRoleSwitch(customRole, { spacerBeforeSize: 's' })}
              </Fragment>
            ))}
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderControlPlaneRoleSwitch() {
    const { roles, toggleRole } = this.props
    const controlPlaneRoles = getControlPlaneRoles({ roles })

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <EuiCheckbox
          id='roles-set-role-control-plane'
          label={
            <Fragment>
              <strong>
                <FormattedMessage
                  id='roles-form.control-plane-pseudo-role'
                  defaultMessage='Control plane'
                />
              </strong>

              <EuiSpacer size='s' />

              <EuiText size='s' color='subdued'>
                <FormattedMessage
                  id='roles-form.control-plane-pseudo-role-description'
                  defaultMessage='Includes the controller, director, and access roles.'
                />
              </EuiText>
            </Fragment>
          }
          checked={this.areAllControlPlaneRolesSelected()}
          indeterminate={this.areOnlySomeControlPlaneRolesSelected()}
          onChange={() =>
            toggleRole(...controlPlaneRoles.map((controlPlaneRole) => controlPlaneRole.id))
          }
        />
      </Fragment>
    )
  }

  renderRoleSwitch(
    role?: RegionRolesState,
    {
      disabled,
      spacerBefore = true,
      spacerBeforeSize = 'm',
    }: {
      disabled?: boolean
      spacerBefore?: boolean
      spacerBeforeSize?: EuiSpacerProps['size']
    } = {},
  ) {
    if (!role) {
      return null
    }

    const { currentRoles, toggleRole } = this.props
    const namedRole = getNamedRole(role.id)

    const roleSwitchProps: RoleSwitchProps = {
      role,
      currentRoles,
      toggleRole,
      disabled,
    }

    if (namedRole) {
      roleSwitchProps.customLabel = (
        <Fragment>
          <strong>{namedRole.label}</strong>

          <EuiSpacer size='s' />

          <EuiText size='s' color='subdued'>
            {namedRole.description}
          </EuiText>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {spacerBefore && <EuiSpacer size={spacerBeforeSize} />}

        <RoleSwitch {...roleSwitchProps} />
      </Fragment>
    )
  }

  renderUnremovableAllocatorRoleWarning() {
    const { regionId, hostId } = this.props

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <CuiAlert type='info'>
          <FormattedMessage
            id='roles-form.allocator-role-disabled'
            defaultMessage='The allocator role cannot be removed because there are active instances on this host. {moveThemOffTheHost} to remove the allocator role.'
            values={{
              moveThemOffTheHost: (
                <CuiLink to={hostAllocatorMoveNodesUrl(regionId, hostId)}>
                  <FormattedMessage
                    id='roles-form.allocator-role-disabled-link-to-vacate'
                    defaultMessage='Move them off the host'
                  />
                </CuiLink>
              ),
            }}
          />
        </CuiAlert>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  areAnyBuiltInRolesSelected(): boolean {
    const { roles } = this.props
    const builtInRoles = getNonControlPlaneBuiltInRoles({ roles })
    const someBuiltInRolesSelected = builtInRoles.some((builtInRole) =>
      this.isSelectedRole(builtInRole),
    )

    return someBuiltInRolesSelected
  }

  areAllControlPlaneRolesSelected(): boolean {
    const { roles } = this.props
    const controlPlaneRoles = getControlPlaneRoles({ roles })
    const allControlPlaneRolesSelected = controlPlaneRoles.every((controlPlaneRole) =>
      this.isSelectedRole(controlPlaneRole),
    )

    return allControlPlaneRolesSelected
  }

  areAnyControlPlaneRolesSelected(): boolean {
    const { roles } = this.props
    const controlPlaneRoles = getControlPlaneRoles({ roles })
    const someControlPlaneRolesSelected = controlPlaneRoles.some((controlPlaneRole) =>
      this.isSelectedRole(controlPlaneRole),
    )

    return someControlPlaneRolesSelected
  }

  areOnlySomeControlPlaneRolesSelected(): boolean {
    if (this.areAllControlPlaneRolesSelected()) {
      return false
    }

    return this.areAnyControlPlaneRolesSelected()
  }

  areAllControlPlaneRolesUnselected(): boolean {
    const { roles } = this.props
    const controlPlaneRoles = getControlPlaneRoles({ roles })
    const allControlPlaneRolesUnselected = controlPlaneRoles.every(
      (controlPlaneRole) => !this.isSelectedRole(controlPlaneRole),
    )

    return allControlPlaneRolesUnselected
  }

  isSelectedRole(role?: RegionRolesState): boolean {
    if (!role) {
      return false
    }

    const { currentRoles } = this.props
    const selected = currentRoles.includes(role.id)
    return selected
  }
}

export default ManageHostRolesForm
