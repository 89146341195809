/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiTabs, EuiTab, EuiText } from '@elastic/eui'

import type { Runner } from '@modules/ui-types'
import history from '@modules/utils/history'
import Header from '@modules/cui/Header'

import { hasAllocatorRole, hasProxyRole, hasControlPlaneRole } from '../../lib/hostRoles'
import { hostAllocatorUrl, hostProxyUrl, hostControlPlaneUrl, hostUrl } from '../../lib/urlBuilder'
import {
  hostAllocatorCrumbs,
  hostProxyCrumbs,
  hostControlPlaneCrumbs,
  hostCrumbs,
} from '../../lib/crumbBuilder'

import HostOverview from './HostOverview'
import HostMenu from './HostMenu'
import Allocator from './Allocator'
import Proxy from './Proxy'
import ControlPlane from './ControlPlane'
import ManageHostRoles from './ManageHostRoles'

type HostType = 'allocator' | 'proxy' | 'control-plane'

type Props = {
  regionId: string
  hostId: string
  hostType?: HostType
  runner?: Runner
}

type State = {
  managingRoles: boolean
}

const HOST_OVERVIEW = undefined

class Host extends Component<Props, State> {
  state: State = {
    managingRoles: false,
  }

  render() {
    const { regionId, hostId, hostType, runner } = this.props
    const { managingRoles } = this.state

    const allocatorRoleExists = hasAllocatorRole({ runner })
    const proxyRoleExists = hasProxyRole({ runner })
    const someControlPlaneRolesExist = hasControlPlaneRole({ runner })

    return (
      <Fragment>
        <Header name={hostId} breadcrumbs={getBreadcrumbs({ regionId, hostId, hostType })}>
          <HostMenu
            regionId={regionId}
            hostId={hostId}
            startManagingRoles={this.startManagingRoles}
          />
        </Header>

        <EuiSpacer size='m' />

        <EuiTabs>
          <EuiTab
            onClick={() => history.replace(hostUrl(regionId, hostId))}
            isSelected={hostType === HOST_OVERVIEW}
          >
            <FormattedMessage id='host.overview-tab' defaultMessage='Overview' />
          </EuiTab>

          <EuiTab
            onClick={() => history.replace(hostAllocatorUrl(regionId, hostId))}
            isSelected={hostType === 'allocator'}
          >
            {allocatorRoleExists ? (
              <FormattedMessage id='host.allocator-tab' defaultMessage='Allocator' />
            ) : (
              <EuiText color='subdued'>
                <FormattedMessage id='host.allocator-tab' defaultMessage='Allocator' />
              </EuiText>
            )}
          </EuiTab>

          <EuiTab
            onClick={() => history.replace(hostProxyUrl(regionId, hostId))}
            isSelected={hostType === 'proxy'}
          >
            {proxyRoleExists ? (
              <FormattedMessage id='host.proxy-tab' defaultMessage='Proxy' />
            ) : (
              <EuiText color='subdued'>
                <FormattedMessage id='host.proxy-tab' defaultMessage='Proxy' />
              </EuiText>
            )}
          </EuiTab>

          <EuiTab
            onClick={() => history.replace(hostControlPlaneUrl(regionId, hostId))}
            isSelected={hostType === 'control-plane'}
          >
            {someControlPlaneRolesExist ? (
              <FormattedMessage id='host.control-plane-tab' defaultMessage='Control plane' />
            ) : (
              <EuiText color='subdued'>
                <FormattedMessage id='host.control-plane-tab' defaultMessage='Control plane' />
              </EuiText>
            )}
          </EuiTab>
        </EuiTabs>

        <EuiSpacer size='l' />

        {this.renderTabContent()}

        {managingRoles && (
          <ManageHostRoles
            regionId={regionId}
            hostId={hostId}
            onClose={() => this.setState({ managingRoles: false })}
          />
        )}
      </Fragment>
    )
  }

  renderTabContent() {
    const { hostType, hostId, regionId } = this.props

    if (hostType === HOST_OVERVIEW) {
      return (
        <HostOverview
          // @ts-ignore ts is 🥜
          regionId={regionId}
          hostId={hostId}
          startManagingRoles={this.startManagingRoles}
        />
      )
    }

    if (hostType === 'allocator') {
      return (
        <Allocator
          // @ts-ignore ts is 🥜
          regionId={regionId}
          hostId={hostId}
          startManagingRoles={this.startManagingRoles}
        />
      )
    }

    if (hostType === 'proxy') {
      return (
        <Proxy regionId={regionId} hostId={hostId} startManagingRoles={this.startManagingRoles} />
      )
    }

    if (hostType === 'control-plane') {
      return (
        <ControlPlane
          regionId={regionId}
          hostId={hostId}
          startManagingRoles={this.startManagingRoles}
        />
      )
    }

    return null
  }

  startManagingRoles = () => {
    this.setState({ managingRoles: true })
  }
}

export default Host

function getBreadcrumbs({
  regionId,
  hostId,
  hostType,
}: {
  regionId: string
  hostId: string
  hostType?: HostType
}) {
  if (hostType === 'allocator') {
    return hostAllocatorCrumbs({ regionId, hostId })
  }

  if (hostType === 'proxy') {
    return hostProxyCrumbs({ regionId, hostId })
  }

  if (hostType === 'control-plane') {
    return hostControlPlaneCrumbs({ regionId, hostId })
  }

  return hostCrumbs({ regionId, hostId })
}
