/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'

import { executeSlmPolicyRequest, takeSnapshotRequest } from '../../../../reducers'
import { getFirstEsClusterFromGet, hasSlm } from '../../../../lib/stackDeployments/selectors'
import {
  takeSnapshot,
  resetExecuteSlmPolicyRequest,
  resetTakeSnapshotRequest,
  executeSlmPolicy,
} from '../../../../actions/snapshots'

import TakeSnapshotButton from './TakeSnapshotButton'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps: (state: ReduxState, consumerProps: ConsumerProps) => StateProps = (
  state,
  { deployment },
) => {
  const esResource = getFirstEsClusterFromGet({ deployment })!
  const { id: deploymentId } = deployment
  const useSlm = hasSlm({ resource: esResource })

  return {
    takeSnapshotRequest: useSlm
      ? executeSlmPolicyRequest(state, deploymentId)
      : takeSnapshotRequest(state, deploymentId),
  }
}

const mapDispatchToProps = (dispatch, { deployment }): DispatchProps => {
  const esResource = getFirstEsClusterFromGet({ deployment })!
  const { id: deploymentId } = deployment
  const useSlm = hasSlm({ resource: esResource })

  return {
    takeSnapshot: () =>
      dispatch(useSlm ? executeSlmPolicy({ deployment }) : takeSnapshot({ deployment })),
    resetTakeSnapshotRequest: () =>
      dispatch(
        useSlm
          ? resetExecuteSlmPolicyRequest(deploymentId)
          : resetTakeSnapshotRequest(deploymentId),
      ),
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TakeSnapshotButton)
