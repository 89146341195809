/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SaasUserResponse } from '@modules/cloud-api/v1/types'

import { AsyncAction } from '@/types/redux'

import { FETCH_SAAS_USER, UPDATE_SAAS_USER_PROFILE } from '../../constants/actions'
import { replaceIn } from '../../lib/immutability-helpers'

export interface State {
  [userId: string]: SaasUserResponse
}

interface FetchSaasUserAction extends AsyncAction<typeof FETCH_SAAS_USER, SaasUserResponse> {
  meta: { userId: string }
}

interface UpdateSaasUserProfileAction
  extends AsyncAction<typeof UPDATE_SAAS_USER_PROFILE, SaasUserResponse> {
  meta: { userId: string }
}

type Action = FetchSaasUserAction | UpdateSaasUserProfileAction

export default function saasUsers(state: State = {}, action: Action): State {
  if (action.type === FETCH_SAAS_USER || action.type === UPDATE_SAAS_USER_PROFILE) {
    if (!action.error && action.payload) {
      const { userId } = action.meta

      return replaceIn(state, [userId], action.payload)
    }
  }

  return state
}

export function getSaasUsers(state: State) {
  return state
}

export function getSaasUser(state: State, userId: string) {
  return state[userId]
}
