/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { flatten } from 'lodash'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Field } from 'formik'

import type { EuiComboBoxOptionOption } from '@elastic/eui'
import { EuiComboBox, EuiFormRow } from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui'

import { notNull } from '@/lib/arrays'

import { roles as roleMessages } from '../../ExternalAuthentication/authProviderMessages'

import type { FieldProps } from 'formik'
import type { FormattedMessage, IntlShape, WrappedComponentProps } from 'react-intl'
import type { CSSProperties, FunctionComponent, ReactElement } from 'react'

type RoleMapType = 'platform' | 'deployments'

export interface RoleMap {
  label: string
  value: string
  disabled?: boolean
  type: RoleMapType
}

interface RoleGroup {
  label: string
  options: Array<EuiComboBoxOptionOption<RoleMap>>
}

interface Props extends WrappedComponentProps {
  useFormik?: boolean
  fullWidth?: boolean
  placeholder?: string
  testSubj?: string
  label?: string | ReactElement<typeof FormattedMessage>
  helpText?: string | ReactElement<typeof FormattedMessage>
  style?: CSSProperties

  // Required for Formik
  name?: string

  // Required for non-Formik
  onChange?: (roles: RoleMap[]) => void
  selectedRoles?: string[]
  error?: string | ReactElement<typeof FormattedMessage>
}

const superUserRole = 'ece_platform_admin'
const platformRoleType = 'platform'
const deploymentsRoleType = 'deployments'

const UserRoleComboBox: FunctionComponent<Props> = (props) => {
  // Shared props
  const {
    intl: { formatMessage },
    label,
    helpText,
    placeholder,
    testSubj,
    fullWidth,
    useFormik,
    selectedRoles,
    ...rest
  } = props

  if (useFormik) {
    // Formik only props
    const { name } = props

    if (!name) {
      throw new Error('Name is required when useFormik is true')
    }

    return (
      <Field name={name}>
        {({
          field: { value },
          form: { touched, errors, setFieldValue, setFieldTouched },
        }: FieldProps) => {
          const onFormikChange = (newSelectedOptions: Array<EuiComboBoxOptionOption<RoleMap>>) => {
            setFieldValue(
              name,
              newSelectedOptions.map((o) => o.value),
            )
          }

          // field.name is just a string, the path to a field in the form values.
          // The structure of `touched` mirrors this, whereas `errors` is created
          // by a custom validation function. Our convention is use a flat structure
          // for errors, so that we can simply key on the field path.
          const formikError = touched[name] && errors[name]
          const selectedValues = Array.isArray(value) ? (value as string[]) : []

          return (
            <EuiFormRow
              fullWidth={fullWidth}
              label={label}
              helpText={helpText}
              isInvalid={formikError != null}
              error={formikError}
            >
              <EuiComboBox<RoleMap>
                fullWidth={fullWidth}
                isClearable={true}
                placeholder={placeholder}
                data-test-id={testSubj}
                options={getRoleStateForCombobox(formatMessage, selectedValues)}
                selectedOptions={getRoleMapsFromValues(formatMessage, selectedValues)}
                // @ts-ignore
                onChange={onFormikChange}
                onBlur={() => setFieldTouched(name)}
                {...rest}
              />
            </EuiFormRow>
          )
        }}
      </Field>
    )
  }

  // Standard props
  const { error, onChange } = props

  if (!selectedRoles) {
    throw new Error('props.selectedRoles is required when useFormik is false')
  }

  return (
    <EuiFormRow fullWidth={fullWidth} label={label} isInvalid={error != null} error={error}>
      <EuiComboBox<RoleMap>
        fullWidth={fullWidth}
        isClearable={true}
        placeholder={placeholder}
        data-test-id={testSubj}
        options={getRoleStateForCombobox(formatMessage, selectedRoles)}
        selectedOptions={getRoleMapsFromValues(formatMessage, selectedRoles)}
        // @ts-ignore
        onChange={onRegularChange}
        {...rest}
      />
    </EuiFormRow>
  )

  function onRegularChange(roles: Array<EuiComboBoxOptionOption<RoleMap>>) {
    if (!onChange) {
      return
    }

    const nextRoles = roles.map((role) => role.value).filter(notNull)
    onChange(nextRoles)
    return
  }
}

export default withErrorBoundary(injectIntl(UserRoleComboBox))

function getRoleMappings(
  formatMessage: IntlShape['formatMessage'],
  selectedRoles: string[] = [],
  typesToDisable: RoleMapType[] = [],
): RoleGroup[] {
  const disabledPlatform = shouldDisableRoleMap(selectedRoles, typesToDisable, platformRoleType)
  const disabledDeployments = shouldDisableRoleMap(
    selectedRoles,
    typesToDisable,
    deploymentsRoleType,
  )

  return [
    {
      label: formatMessage(roleMessages.platformTitle),
      options: [
        {
          // @ts-ignore
          value: superUserRole,
          label: formatMessage(roleMessages.platformAdmin),

          // @ts-ignore
          type: platformRoleType,
          disabled: disabledPlatform,
        },
        {
          // @ts-ignore
          value: 'ece_platform_viewer',
          label: formatMessage(roleMessages.platformViewer),

          // @ts-ignore
          type: platformRoleType,
          disabled: disabledPlatform,
        },
      ],
    },
    {
      label: formatMessage(roleMessages.deploymentsTitle),
      options: [
        {
          // @ts-ignore
          value: 'ece_deployment_manager',
          label: formatMessage(roleMessages.deploymentsManager),

          // @ts-ignore
          type: deploymentsRoleType,
          disabled: disabledDeployments,
        },
        {
          // @ts-ignore
          value: 'ece_deployment_viewer',
          label: formatMessage(roleMessages.deploymentsViewer),

          // @ts-ignore
          type: deploymentsRoleType,
          disabled: disabledDeployments,
        },
      ],
    },
  ]
}

export function shouldDisableRoleMap(
  selectedRoles: string[],
  typesToDisable: RoleMapType[],
  currentType: RoleMapType,
): boolean {
  // All roles are disabled if a superuser is selected
  const isSuperuser = selectedRoles.includes(superUserRole)

  // A user can't have more than 2 roles selected
  const atRoleLimit = selectedRoles.length >= 2

  // A user can only have a single selection from each type
  const isCurrentTypeOrSelected = typesToDisable.some(
    (disabledType) => currentType === disabledType,
  )

  return isSuperuser || atRoleLimit || isCurrentTypeOrSelected
}

function getRoleValues(
  formatMessage: IntlShape['formatMessage'],
): Array<EuiComboBoxOptionOption<RoleMap>> {
  return flatten(getRoleMappings(formatMessage).map((roleGroup) => roleGroup.options))
}

function getRoleMapsFromValues(
  formatMessage: IntlShape['formatMessage'],
  roles: string[],
): Array<EuiComboBoxOptionOption<RoleMap>> {
  // @ts-ignore
  return getRoleValues(formatMessage).filter((roleMap) => roles.includes(roleMap.value))
}

function getRoleStateForCombobox(
  formatMessage: IntlShape['formatMessage'],
  selectedRoles: string[],
): RoleGroup[] {
  const selectedRoleMappings = getRoleMapsFromValues(formatMessage, selectedRoles)
  const typesToDisable = selectedRoleMappings.map((roleMap) => roleMap.type as RoleMapType)

  return getRoleMappings(formatMessage, selectedRoles, typesToDisable)
}
