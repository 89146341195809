/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { OrganizationMembership, OrganizationInvitation } from '@modules/cloud-api/v1/types'
import type { UserProfile } from '@modules/ui-types'
import { addToast, parseError } from '@modules/cui'
import { asAjaxRequestError } from '@modules/utils/ajax'

import { getOrganizationInvitations, getOrganizationMembers } from '@/reducers'

import { formatMessageWithValues, errors } from '../messages'

import type { MessageValues } from '../messages'
import type { IntlShape, MessageDescriptor } from 'react-intl'
import type { OrganizationMemberRow } from '@/components/Organization/OrganizationMembers/types'
import type { ReduxState } from '@/types/redux'

function parseMemberError({
  intl,
  error,
  values,
}: {
  intl: IntlShape
  error: Error
  values: MessageValues
}): string {
  const errorCode = asAjaxRequestError(error)?.body?.errors[0]?.code

  if (errorCode === `organization.has_deployments`) {
    return formatMessageWithValues({ intl, message: errors.hasDeployments, values })
  }

  return parseError(error)
}

export const hasNoMember = ({
  userId,
  members,
}: {
  userId: string | undefined
  members: OrganizationMembership[]
}): boolean => !members.some((member) => member.user_id === userId)

export const getMemberEmail = ({
  userId,
  members,
}: {
  userId: string | undefined
  members: OrganizationMembership[]
}): string => {
  if (!userId) {
    return ''
  }

  if (userId.startsWith('saml:okta-saml:')) {
    return userId.slice(15)
  }

  const member = members.find(({ user_id }) => user_id === userId)

  if (member) {
    return member.email || member.name || userId
  }

  return userId
}

export const hasSameEmail = (
  profile: UserProfile | null,
  organizationMemberRow: OrganizationMemberRow,
): boolean => profile?.email === organizationMemberRow.email

export const removeMember = ({
  intl,
  deleteOrganizationMember,
  successMessage,
  failureMessage,
  values,
}: {
  intl: IntlShape
  deleteOrganizationMember: Promise<any>
  successMessage: MessageDescriptor
  failureMessage: MessageDescriptor
  values: MessageValues
}): void => {
  deleteOrganizationMember
    .then(() =>
      addToast({
        family: 'organization.organization-members.remove-member-button',
        color: 'success',
        iconType: 'check',
        title: formatMessageWithValues({ intl, message: successMessage, values }),
      }),
    )
    .catch((error) =>
      addToast({
        family: 'organization.organization-members.remove-member-button',
        color: 'danger',
        title: formatMessageWithValues({ intl, message: failureMessage, values }),
        text: parseMemberError({ intl, error, values }),
      }),
    )
}

const organizationInvitationToOrganizationMemberRow = ({
  token: id,
  email,
  created_at: addedDate,
  expired,
  role_assignments: roleAssignments,
}: OrganizationInvitation): OrganizationMemberRow => ({
  id,
  name: undefined,
  email,
  addedDate,
  isInvitation: true,
  status: expired ? 'expired' : 'pending',
  roleAssignments,
})

const organizationMembershipToOrganizationMemberRow = ({
  user_id: id,
  name,
  email = '',
  member_since: addedDate,
  role_assignments: roleAssignments,
}: OrganizationMembership): OrganizationMemberRow => ({
  id,
  name,
  email,
  addedDate,
  isInvitation: false,
  status: 'active',
  roleAssignments,
})

export const getOrganizationMemberRows = (
  state: ReduxState,
  organizationId: string | undefined,
): OrganizationMemberRow[] => {
  if (organizationId === undefined) {
    return []
  }

  const invitationRows = getOrganizationInvitations(state, organizationId).map(
    organizationInvitationToOrganizationMemberRow,
  )
  const activeMemberRows = getOrganizationMembers(state, organizationId).map(
    organizationMembershipToOrganizationMemberRow,
  )

  const organizationMemberRows = invitationRows.concat(activeMemberRows)

  return organizationMemberRows.sort(
    ({ name: name1, email: email1 }, { name: name2, email: email2 }) => {
      const nameOrEmail1 = name1 ?? email1
      const nameOrEmail2 = name2 ?? email2

      return nameOrEmail1.toLocaleLowerCase().localeCompare(nameOrEmail2.toLocaleLowerCase())
    },
  )
}
