/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'

import DeploymentsTable from './DeploymentsTable'
import DeploymentsCardList from './DeploymentsCardList'

import './deploymentList.scss'

type Props = {
  displayMode: 'grid' | 'table'
  deployments: DeploymentSearchResponse[]
  pageSize: number | null
  shownCount: number | null
  matchCount: number | null
  reQuery: () => void
  maintainOrder: boolean
}

export default class DeploymentList extends Component<Props> {
  static defaultProps = {
    pageSize: 30, // 10 rows, with 3 cards each
  }

  render() {
    return this.renderDeployments()
  }

  renderDeployments() {
    const { displayMode, reQuery, deployments, shownCount, matchCount, maintainOrder, pageSize } =
      this.props

    if (displayMode === `table`) {
      return (
        <DeploymentsTable
          deployments={deployments}
          onDeploymentAction={reQuery}
          pageSize={pageSize}
        />
      )
    }

    return (
      <DeploymentsCardList
        deployments={deployments}
        shownCount={shownCount}
        matchCount={matchCount}
        maintainOrder={maintainOrder}
        pageSize={pageSize}
      />
    )
  }
}
