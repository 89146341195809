/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import {
  EuiPanel,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIconTip,
  transparentize,
  withEuiTheme,
  euiBackgroundColor,
} from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import type { ReactNode, FunctionComponent } from 'react'

type Props = {
  children?: ReactNode
  title: ReactNode
  description: string
}

interface PropsWithTheme extends Props, WithEuiThemeProps {}

const PermissionsRolePanel: FunctionComponent<PropsWithTheme> = ({
  title,
  description,
  children,
  theme,
}) => (
  <EuiPanel
    hasShadow={false}
    hasBorder={true}
    css={css({
      borderColor: theme.euiTheme.colors.primary,
    })}
  >
    <EuiFlexGroup
      css={css({
        margin: `-${theme.euiTheme.size.base}`,
        padding: theme.euiTheme.size.base,
        backgroundColor:
          theme.colorMode === 'DARK'
            ? theme.euiTheme.colors.lightShade
            : transparentize(euiBackgroundColor(theme, 'primary'), 0.37),
      })}
      gutterSize='m'
      justifyContent='spaceBetween'
      responsive={false}
    >
      <EuiFlexItem>
        <EuiText size='m' data-test-id={title}>
          <strong>{title}</strong> <EuiIconTip content={description} color='subdued' />
        </EuiText>
      </EuiFlexItem>

      <EuiFlexItem grow={false} css={css({ alignItems: `start`, alignSelf: `center` })}>
        <EuiIcon type='checkInCircleFilled' />
      </EuiFlexItem>
    </EuiFlexGroup>

    {children}
  </EuiPanel>
)

export default withEuiTheme(PermissionsRolePanel)
