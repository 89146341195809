/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { cloneElement, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSkeletonText, EuiProgress, EuiSpacer } from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { SecurityDeployment } from '@modules/cloud-api/v1/types'
import { AjaxRequestError } from '@modules/ui-types'
import type { AsyncRequestState, RegionId } from '@modules/ui-types'
import { CuiAlert, CuiLink } from '@modules/cui'
import { isAjaxNotFound } from '@modules/utils/ajax'

import {
  deploymentActivityUrl,
  regionSettingsUrl,
  resolveDeploymentUrlForEsCluster,
} from '../../../../../lib/urlBuilder'
import { hasPermission } from '../../../../../lib/requiresPermission'

import NoSecurityCluster from './NoSecurityCluster'

import type { FunctionComponent, ReactElement } from 'react'

export interface Props {
  regionId: RegionId
  securityCluster: SecurityDeployment | undefined
  createSecurityCluster: () => Promise<any>
  createSecurityClusterRequest: AsyncRequestState
  fetchSecurityCluster: () => Promise<any>
  fetchSecurityClusterRequest: AsyncRequestState
  children: ReactElement<any>
}

const SecurityClusterWrapper: FunctionComponent<Props> = ({
  securityCluster,
  createSecurityCluster,
  createSecurityClusterRequest,
  fetchSecurityClusterRequest,
  regionId,
  children,
}) => {
  if (
    fetchSecurityClusterRequest.error instanceof AjaxRequestError &&
    !isAjaxNotFound(fetchSecurityClusterRequest.error)
  ) {
    return (
      <div data-test-id='fetching-security-cluster-failed'>
        <CuiAlert details={fetchSecurityClusterRequest.error} type='error'>
          <FormattedMessage
            id='security-cluster-wrapper.failed-to-load'
            defaultMessage='Failed to load security cluster details'
          />
        </CuiAlert>
      </div>
    )
  }

  if (securityCluster == null) {
    if (fetchSecurityClusterRequest.inProgress) {
      return (
        <div data-test-id='fetching-security-cluster'>
          <EuiSkeletonText />
        </div>
      )
    }

    return (
      <NoSecurityCluster
        regionId={regionId}
        createSecurityCluster={createSecurityCluster}
        createSecurityClusterRequest={createSecurityClusterRequest}
      />
    )
  }

  if (securityCluster.status === 'initializing') {
    return (
      <Fragment>
        <div data-test-id='security-cluster-pending-create'>
          <EuiProgress size='xs' color='success' />

          <EuiCallOut
            color='success'
            iconType='clock'
            title={
              <FormattedMessage
                id='security-cluster-wrapper.setting-up-security-cluster'
                defaultMessage='Setting up security cluster …'
              />
            }
          >
            <FormattedMessage
              id='security-cluster-wrapper.setting-up-security-cluster-description'
              defaultMessage='Your security cluster is being set up and will be available shortly. Once the creation process is complete, the management section will be fully functional.'
            />
          </EuiCallOut>
        </div>

        <EuiSpacer />

        {cloneElement(children, { isPlanChangePending: true })}
      </Fragment>
    )
  }

  if (!securityCluster.is_enabled) {
    return (
      <Fragment>
        <div data-test-id='security-cluster-disabled'>
          <EuiCallOut
            color='warning'
            iconType='alert'
            title={
              <FormattedMessage
                id='security-cluster-wrapper.security-deployment-is-disabled'
                defaultMessage='Security deployment is disabled'
              />
            }
          >
            <FormattedMessage
              id='security-cluster-wrapper.security-deployment-is-disabled-description'
              defaultMessage='To manage native users or external authentication providers, you must {enableRbac}.'
              values={{
                enableRbac: hasPermission(Permission.createSecurityDeployment) ? (
                  <CuiLink to={regionSettingsUrl(regionId)}>
                    <FormattedMessage
                      id='security-cluster-wrapper.enable-rbac'
                      defaultMessage='enable RBAC'
                    />
                  </CuiLink>
                ) : (
                  <FormattedMessage
                    id='security-cluster-wrapper.enable-rbac'
                    defaultMessage='enable RBAC'
                  />
                ),
              }}
            />
          </EuiCallOut>
        </div>

        <EuiSpacer />

        {cloneElement(children, { isSecurityDeploymentDisabled: true })}
      </Fragment>
    )
  }

  if (securityCluster.status === 'reconfiguring' || securityCluster.has_pending_plan) {
    return (
      <Fragment>
        <div data-test-id='security-cluster-pending-change'>
          <EuiProgress size='xs' color='primary' />

          <EuiCallOut
            color='primary'
            iconType='clock'
            title={
              <FormattedMessage
                id='security-cluster-wrapper.pending-change'
                defaultMessage='Configuration change pending'
              />
            }
          >
            <FormattedMessage
              id='security-cluster-wrapper.pending-change-description'
              defaultMessage='We are currently updating the authentication configuration. Further changes are not possible during this process.'
            />
          </EuiCallOut>
        </div>

        <EuiSpacer />

        {cloneElement(children, { isPlanChangePending: true })}
      </Fragment>
    )
  }

  if (!securityCluster.is_healthy) {
    return (
      <Fragment>
        <div data-test-id='security-cluster-failed-change'>
          <EuiCallOut
            color='danger'
            iconType='alert'
            title={
              <FormattedMessage
                id='security-cluster-wrapper.failed-change'
                defaultMessage='Configuration change failed'
              />
            }
          >
            <FormattedMessage
              id='security-cluster-wrapper.failed-change-description'
              defaultMessage="The latest attempt to change the configuration failed. See the security deployment's {activity}."
              values={{
                activity: (
                  <CuiLink
                    to={resolveDeploymentUrlForEsCluster(
                      deploymentActivityUrl,
                      regionId,
                      securityCluster.cluster_id,
                    )}
                  >
                    <FormattedMessage
                      id='security-cluster-wrapper.failed-change-description-activity'
                      defaultMessage='activity'
                    />
                  </CuiLink>
                ),
              }}
            />
          </EuiCallOut>
        </div>

        <EuiSpacer />

        {children}
      </Fragment>
    )
  }

  return children
}

export default SecurityClusterWrapper
