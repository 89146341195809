/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiCode } from '@elastic/eui'

import type { AllocatorInstance } from '@modules/ui-types'
import { CuiLink } from '@modules/cui'

import { topologyViewInstanceConfigurationUrl } from '../../../lib/urlBuilder'

import type { FunctionComponent } from 'react'

type Props = {
  regionId: string
  instance: AllocatorInstance
  isClusterHeader: boolean
}

const InstanceCapacity: FunctionComponent<Props> = ({ regionId, instance, isClusterHeader }) => {
  if (isClusterHeader) {
    return null
  }

  const instanceConfigurationId = instance.status.instance_configuration_id

  if (!instanceConfigurationId) {
    return null
  }

  return (
    <EuiCode>
      <CuiLink to={topologyViewInstanceConfigurationUrl(regionId, instanceConfigurationId)}>
        {instanceConfigurationId}
      </CuiLink>
    </EuiCode>
  )
}

export default InstanceCapacity
