/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText } from '@elastic/eui'

import type { ClusterInstanceInfo } from '@modules/cloud-api/v1/types'

import NodeInstanceStatsDisplay from '../NodeInstanceStatsDisplay'
import Percentage from '../../../../lib/Percentage'
import { getUsagePercent } from '../helpers'

import type { FunctionComponent } from 'react'

interface Props {
  instance: ClusterInstanceInfo
  tableView?: boolean
}

const NodeInstanceNativeMemoryPressure: FunctionComponent<Props> = ({
  instance,
  tableView = false,
}) => {
  const memoryPressure = instance.memory?.native_memory_pressure ?? 0
  const memoryCapacity = 100 // native_memory_pressure is already being returned as a percent
  const stressed = isUnderPressure(memoryPressure)

  if (tableView) {
    const color = stressed ? `danger` : `default`
    return (
      <EuiText size='s' color={color} data-test-id='instance-stats-native-memory-percentage'>
        <Percentage
          value={
            typeof memoryPressure === 'undefined'
              ? getUsagePercent(memoryPressure, memoryCapacity) / 100
              : memoryPressure / 100
          }
        />
      </EuiText>
    )
  }

  return (
    <NodeInstanceStatsDisplay
      available={memoryCapacity}
      stressed={stressed}
      status={
        stressed ? (
          <EuiText color='danger' size='s' data-test-id='native-memory-pressure-high'>
            <FormattedMessage id='native-memory-pressure.high' defaultMessage='High' />
          </EuiText>
        ) : (
          <EuiText size='s' data-test-id='native-memory-pressure-normal'>
            <FormattedMessage id='native-memory-pressure.normal' defaultMessage='Normal' />
          </EuiText>
        )
      }
      used={memoryPressure}
      title={
        <FormattedMessage
          id='native-memory-pressure.default-label'
          defaultMessage='Native memory pressure'
        />
      }
    />
  )
}

const isUnderPressure = (memoryPressure: number) => memoryPressure >= 80

export default NodeInstanceNativeMemoryPressure
