/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { jsx } from '@emotion/react'

import type { EuiTabbedContentTab } from '@elastic/eui'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiTabbedContent,
} from '@elastic/eui'

import type { DeploymentRoleAssignment } from '@modules/cloud-api/v1/types'

import AllDeploymentsRoles from './AllDeploymentsRoles'
import RolePanel from './RolePanel'
import SpecificDeploymentsRoles from './SpecificDeploymentsRoles'
import { OrganizationRole, DeploymentRole } from './types'
import {
  onChangeAllDeploymentsRoleAssignment,
  toggleOrganizationBillingRoleAssignment,
  toggleOrganizationOwnerRoleAssignment,
  deleteDeploymentsRoleAssignments,
  onChangeSpecificDeploymentsRoleAssignments,
  isOrganizationRoleSelected,
  getAllDeploymentsRoleAssignment,
  filterDeploymentRoleAssignmentByAll,
} from './lib'

import type { AllProps as Props, State } from './types'

class RoleAssignmentsPanel extends Component<Props, State> {
  state: State = {
    isDeploymentAccessSelected: (this.props.roleAssignments.deployment ?? []).length > 0,
  }

  render() {
    const { rolesScopes } = this.props

    if (rolesScopes === null) {
      return <EuiSkeletonText lines={8} />
    }

    return (
      <EuiFlexGroup direction='column' gutterSize='m'>
        {rolesScopes.has('organization') && (
          <Fragment>
            <EuiFlexItem>{this.renderOrganizationOwnerPanel()}</EuiFlexItem>
            <EuiFlexItem>{this.renderOrganizationBillingPanel()}</EuiFlexItem>
          </Fragment>
        )}
        {rolesScopes.has('deployment') && (
          <EuiFlexItem>{this.renderDeploymentAccessPanel()}</EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }

  renderOrganizationOwnerPanel(): JSX.Element {
    return (
      <RolePanel
        title={
          <FormattedMessage
            id='roles-assignments.organization-owner'
            defaultMessage='Organization owner'
          />
        }
        description={
          <FormattedMessage
            id='roles-assignments.organization-owner-description'
            defaultMessage='Highest role within the organization. Can manage members, access all features and all deployments.'
          />
        }
        isChecked={isOrganizationRoleSelected(
          this.props.roleAssignments,
          OrganizationRole.ORGANIZATION_OWNER,
        )}
        isDisabled={false}
        role={OrganizationRole.ORGANIZATION_OWNER}
        testId='organization-owner-switch'
        onChangeRole={this.onSwitchOrganizationOwner}
      />
    )
  }

  renderOrganizationBillingPanel(): JSX.Element {
    return (
      <RolePanel
        title={
          <FormattedMessage
            id='roles-assignments.organization-billing'
            defaultMessage='Billing and subscription'
          />
        }
        description={
          <FormattedMessage
            id='roles-assignments.organization-billing-description'
            defaultMessage='Access to all invoices and payment methods. Can make subscription changes.'
          />
        }
        isChecked={isOrganizationRoleSelected(
          this.props.roleAssignments,
          OrganizationRole.ORGANIZATION_BILLING,
        )}
        isDisabled={isOrganizationRoleSelected(
          this.props.roleAssignments,
          OrganizationRole.ORGANIZATION_OWNER,
        )}
        role={OrganizationRole.ORGANIZATION_BILLING}
        testId='organization-billing-switch'
        onChangeRole={this.onSwitchOrganizationBilling}
      />
    )
  }

  renderDeploymentAccessPanel(): JSX.Element {
    const { isDeploymentAccessSelected } = this.state

    const isOrganizationOwnerSelected = isOrganizationRoleSelected(
      this.props.roleAssignments,
      OrganizationRole.ORGANIZATION_OWNER,
    )

    const isChecked = isDeploymentAccessSelected && !isOrganizationOwnerSelected

    return (
      <RolePanel
        title={
          <FormattedMessage
            id='roles-assignments.deployment-access'
            defaultMessage='Deployment access'
          />
        }
        description={
          <FormattedMessage
            id='roles-assignments.deployment-access-description'
            defaultMessage='Grant access to one or more deployments.'
          />
        }
        isChecked={isChecked}
        isDisabled={isOrganizationOwnerSelected}
        role={DeploymentRole.DEPLOYMENT_ADMIN}
        testId='organization-deployment-access-switch'
        onChangeRole={this.onSwitchDeploymentAccess}
      >
        {isChecked && this.renderDeploymentAccessTabbedContent()}
      </RolePanel>
    )
  }

  renderDeploymentAccessTabbedContent(): JSX.Element {
    const tabs: EuiTabbedContentTab[] = []

    if (this.props.hasGetAllDeploymentsPermission) {
      tabs.push({
        id: 'all',
        name: (
          <FormattedMessage
            id='roles-assignments.deployment-access.all-deployments-tab'
            defaultMessage='All deployments'
          />
        ),
        content: (
          <AllDeploymentsRoles
            allDeploymentsRoleAssignment={getAllDeploymentsRoleAssignment(
              this.props.roleAssignments,
            )}
            onChangeAllDeploymentsRoleAssignment={this.onChangeAllDeploymentsRoleAssignment}
            onSwitchAllDeploymentsRoleAssignments={this.onSwitchAllDeploymentsRoleAssignments}
          />
        ),
      })
    }

    tabs.push({
      id: 'specific',
      name: (
        <FormattedMessage
          id='roles-assignments.deployment-access.specific-deployments-tab'
          defaultMessage='Specific deployments'
        />
      ),
      content: (
        <SpecificDeploymentsRoles
          organizationId={this.props.organizationId}
          roleAssignments={this.props.roleAssignments}
          onChangeSpecificDeploymentsRoleAssignments={
            this.onChangeSpecificDeploymentsRoleAssignments
          }
        />
      ),
      disabled: this.isAllDeploymentsRoleAssignmentsEnabled(),
    })

    const hasSpecificDeploymentRoleAssignments =
      filterDeploymentRoleAssignmentByAll(this.props.roleAssignments.deployment, false).length > 0

    const initialSelectedTab =
      tabs.length > 1 && hasSpecificDeploymentRoleAssignments ? tabs[1] : tabs[0]

    return (
      <Fragment>
        <EuiSpacer size='xl' />

        <EuiTabbedContent
          data-test-id='deployment-access-tabbed-content'
          tabs={tabs}
          initialSelectedTab={initialSelectedTab}
        />
      </Fragment>
    )
  }

  onSwitchOrganizationOwner = (checked: boolean): void => {
    this.setState((prevState) => ({
      isDeploymentAccessSelected: checked ? false : prevState.isDeploymentAccessSelected,
    }))

    const roleAssignments = toggleOrganizationOwnerRoleAssignment(
      this.props.roleAssignments,
      this.props.organizationId,
    )

    this.props.onChangeRoleAssignments(roleAssignments)
  }

  onSwitchOrganizationBilling = (): void => {
    const roleAssignments = toggleOrganizationBillingRoleAssignment(
      this.props.roleAssignments,
      this.props.organizationId,
    )

    this.props.onChangeRoleAssignments(roleAssignments)
  }

  onSwitchDeploymentAccess = (checked: boolean): void => {
    this.setState((prevState) => ({
      isDeploymentAccessSelected: !prevState.isDeploymentAccessSelected,
    }))

    this.onSwitchAllDeploymentsRoleAssignments(checked)
  }

  onChangeAllDeploymentsRoleAssignment = (role: DeploymentRole): void => {
    const roleAssignments = onChangeAllDeploymentsRoleAssignment(
      this.props.roleAssignments,
      this.props.organizationId,
      role,
    )

    this.props.onChangeRoleAssignments(roleAssignments)
  }

  deleteDeploymentsRoleAssignments = (): void => {
    const roleAssignments = deleteDeploymentsRoleAssignments(this.props.roleAssignments)
    this.props.onChangeRoleAssignments(roleAssignments)
  }

  onChangeSpecificDeploymentsRoleAssignments = (
    specificDeploymentRoleAssignments: DeploymentRoleAssignment[],
  ): void => {
    const roleAssignments = onChangeSpecificDeploymentsRoleAssignments(
      this.props.roleAssignments,
      specificDeploymentRoleAssignments,
    )

    this.props.onChangeRoleAssignments(roleAssignments)
  }

  onSwitchAllDeploymentsRoleAssignments = (enabled: boolean): void => {
    if (enabled) {
      this.onChangeAllDeploymentsRoleAssignment(DeploymentRole.DEPLOYMENT_VIEWER)
    } else {
      this.deleteDeploymentsRoleAssignments()
    }
  }

  isAllDeploymentsRoleAssignmentsEnabled = (): boolean => {
    const allDeploymentsRolesAssignments = getAllDeploymentsRoleAssignment(
      this.props.roleAssignments,
    )

    return allDeploymentsRolesAssignments !== null
  }
}

export default RoleAssignmentsPanel
