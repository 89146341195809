/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Component, Fragment, createRef } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { css, jsx } from '@emotion/react'

import type { WithEuiThemeProps } from '@elastic/eui'
import {
  EuiErrorBoundary,
  EuiPage,
  EuiPageBody,
  EuiPageContent_Deprecated as EuiPageContent,
  EuiPageContentBody_Deprecated as EuiPageContentBody,
  withEuiTheme,
} from '@elastic/eui'

import type { User } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, CloudAppType, RootConfig } from '@modules/ui-types'
import { themedBoxShadow } from '@modules/cui/styles/boxShadow'
import { BreadcrumbsContext } from '@modules/cui/Breadcrumbs'

import IntroduceRbac from '../IntroduceRbac'
import { AppRouterContext } from '../../../../components/AppRouter'
import AppLoadingRoot from '../../../../components/AppLoadingRoot'
import ChromeNavigation from '../../../../components/ChromeNavigation'
import ChromeHeader from '../../../../components/ChromeHeader'
import RefreshApiToken from '../../../../components/RefreshApiToken'
import Eula from '../../../../components/Eula'
import { EnsureApiBaseUrl } from '../../../../components/ApiBaseUrl'
import PhoneHomeData from '../../../../components/PhoneHome/Data'
import { UnhandledApplicationLoadError } from '../../../../components/ApplicationLoadError'
import scheduler from '../../../../lib/scheduler'
import { logoutUrl } from '../../../../lib/urlBuilder'

import type { RefObject, ReactNode } from 'react'
import type { AppConfig } from '../../../../../config/types'

const messages = defineMessages({
  rootFailed: {
    id: `app-index.fetching-the-root-resource-failed-link`,
    defaultMessage: `Fetching the root resource failed. {logOut}`,
  },
  permissionsFailed: {
    id: `app-index.fetching-user-permissions-failed-link`,
    defaultMessage: `Fetching user permissions failed. {logOut}`,
  },
  logOut: {
    id: `app-index.log-out`,
    defaultMessage: `Log out`,
  },
})

export interface Props {
  children?: ReactNode
  cloudAppType: CloudAppType
  config: AppConfig
  fetchCurrentUser: () => Promise<any>
  fetchCurrentUserRequest: AsyncRequestState
  fetchRootIfNeeded: (config: AppConfig) => Promise<any>
  fetchRootRequest: AsyncRequestState
  isEulaAccepted: boolean
  pollingInterval: number
  requireEula: boolean
  resetFetchCurrentUser: () => void
  fetchEolStatusIfNeeded: () => void
  root: RootConfig
  currentUser: User | null
  isCreateUrl: boolean
  isGettingStartedUrl: boolean
}

interface PropsWithTheme extends Props, WithEuiThemeProps {}
interface State {
  scheduler: {
    start: () => void
    stop: () => void
  }
}

class App extends Component<PropsWithTheme, State> {
  state: State = {
    scheduler: scheduler({
      interval: this.props.pollingInterval,
    }),
  }

  static childContextTypes: { scheduler: any }

  breadcrumbsRef: RefObject<HTMLDivElement> | null = createRef<HTMLDivElement>()

  getChildContext() {
    return {
      scheduler: this.state.scheduler,
    }
  }

  componentDidMount() {
    const {
      config,
      fetchCurrentUser,
      fetchRootIfNeeded,
      currentUser,
      fetchCurrentUserRequest,
      fetchEolStatusIfNeeded,
    } = this.props

    fetchRootIfNeeded(config)
    fetchEolStatusIfNeeded()

    if (this.isPollingEnabled()) {
      this.state.scheduler.start()
    }

    if (!currentUser && !fetchCurrentUserRequest.inProgress) {
      fetchCurrentUser()
    }
  }

  componentWillUnmount() {
    this.props.resetFetchCurrentUser()
    this.state.scheduler.stop()
  }

  render() {
    const { fetchCurrentUserRequest, fetchRootRequest, isEulaAccepted, requireEula, root } =
      this.props

    if (root.error && !fetchRootRequest.inProgress) {
      return (
        <UnhandledApplicationLoadError error={root.error}>
          <FormattedMessage
            id='app-index.fetching-the-root-resource-failed-link'
            defaultMessage='Fetching the root resource failed. {logOut}'
            values={{
              logOut: (
                <Link to={logoutUrl()}>
                  <FormattedMessage id='app-index.log-out' defaultMessage='Log out' />
                </Link>
              ),
            }}
          />
        </UnhandledApplicationLoadError>
      )
    }

    if (fetchCurrentUserRequest.error) {
      return (
        <UnhandledApplicationLoadError error={fetchCurrentUserRequest.error as Error | undefined}>
          <FormattedMessage
            {...messages.permissionsFailed}
            values={{
              logOut: (
                <Link to={logoutUrl()}>
                  <FormattedMessage id='app-index.log-out' defaultMessage='Log out' />
                </Link>
              ),
            }}
          />
        </UnhandledApplicationLoadError>
      )
    }

    if (!fetchRootRequest.inProgress && requireEula && !isEulaAccepted) {
      return <Eula />
    }

    return (
      <Fragment>
        <EnsureApiBaseUrl>
          <Fragment>
            {this.renderHeader()}
            {this.renderContent()}
          </Fragment>
        </EnsureApiBaseUrl>

        <RefreshApiToken />
      </Fragment>
    )
  }

  renderHeader() {
    const { cloudAppType } = this.props

    const isEce = cloudAppType === `cloud-enterprise-adminconsole`

    return (
      <Fragment>
        {isEce && <IntroduceRbac regionId='ece-region' />}

        <ChromeHeader ref={this.breadcrumbsRef} />
      </Fragment>
    )
  }

  renderContent() {
    const { children, currentUser, root, isCreateUrl, isGettingStartedUrl, theme } = this.props

    if (root.hrefs === undefined || !currentUser) {
      return <AppLoadingRoot />
    }

    const isGettingStartedRoute = isCreateUrl || isGettingStartedUrl

    const cloudContentStyle = css`
      background: ${!isGettingStartedRoute
        ? theme.euiTheme.colors.emptyShade
        : theme.euiTheme.colors.body};
      box-shadow: ${themedBoxShadow({ theme })};
    `

    return (
      <EuiPage paddingSize='none'>
        {!isGettingStartedRoute && (
          <aside className='cloudSidebar'>
            <AppRouterContext.Consumer>
              {({ routes }) => <ChromeNavigation routes={routes} />}
            </AppRouterContext.Consumer>
          </aside>
        )}

        <div
          className={cx('cloudContent', { createPage: isGettingStartedRoute })}
          id='cloudPortalPage'
          css={cloudContentStyle}
        >
          <EuiPageBody>
            <EuiPageContent className='cloudContentBody' paddingSize='m' color='transparent'>
              <EuiPageContentBody data-app='appContentBody'>
                <PhoneHomeData />

                <EuiErrorBoundary>
                  <BreadcrumbsContext.Provider value={{ breadcrumbsRef: this.breadcrumbsRef }}>
                    {children}
                  </BreadcrumbsContext.Provider>
                </EuiErrorBoundary>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </div>
      </EuiPage>
    )
  }

  isPollingEnabled() {
    return this.props.pollingInterval > 0
  }
}

App.childContextTypes = {
  scheduler: PropTypes.object.isRequired,
}

export default withEuiTheme(App)
