/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, SliderInstanceType } from '@modules/ui-types'

import { setDeploymentResourceMetadata } from '../../../actions/stackDeployments'
import { getStackDeployment, setDeploymentResourceMetadataRequest } from '../../../reducers'
import { withStackDeploymentRouteParams } from '../routing'

import EditStackDeploymentAdvancedDataEditor from './EditStackDeploymentAdvancedDataEditor'

import type { WithStackDeploymentRouteParamsProps } from '../routing'

type StateProps = {
  deploymentId: string
  deployment: DeploymentGetResponse | null
  setDeploymentResourceMetadataRequest: (
    deploymentId: string,
    resourceType: SliderInstanceType,
    resourceRefId: string,
  ) => AsyncRequestState
}

type DispatchProps = {
  setDeploymentResourceMetadata: (params: {
    deploymentId: string
    resourceType: SliderInstanceType
    resourceRefId: string
    version: string | null
    metadata?: unknown
  }) => void
}

type ConsumerProps = WithStackDeploymentRouteParamsProps

const mapStateToProps = (state, { stackDeploymentId }: ConsumerProps): StateProps => ({
  deploymentId: stackDeploymentId!,
  deployment: getStackDeployment(state, stackDeploymentId),
  setDeploymentResourceMetadataRequest: (
    deploymentId: string,
    resourceType: SliderInstanceType,
    resourceRefId: string,
  ) => setDeploymentResourceMetadataRequest(state, deploymentId, resourceType, resourceRefId),
})

const mapDispatchToProps: DispatchProps = {
  setDeploymentResourceMetadata,
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(EditStackDeploymentAdvancedDataEditor),
)
