/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { PureComponent, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModalBody,
  EuiModalHeader,
  EuiText,
  EuiSpacer,
  EuiPanel,
  EuiImage,
  EuiHorizontalRule,
} from '@elastic/eui'

import type { UserProfile, Theme, StackDeployment } from '@modules/ui-types'

import integrationsIllustration from '@/files/kibana-integrations-lightmode.svg'
import integrationsIllustrationDark from '@/files/kibana-integrations-darkmode.svg'
import { isTrialNotStartedUser } from '@/lib/billing'

import RemainingTrial from './RemainingTrial'
import SubscribeButton from './SubscribeButton'
import HelperLinks from './HelperLinks'
import ExtendTrialSection from './ExtendTrialSection'

import type { WrappedComponentProps } from 'react-intl'

export interface Props {
  closeModal: () => void
  profile: UserProfile
  theme: Theme
  deployment: StackDeployment | null
}

class TrialSummaryWithoutDeployment extends PureComponent<Props & WrappedComponentProps> {
  render() {
    const {
      theme,
      deployment,
      profile,
      closeModal,
      intl: { formatMessage },
    } = this.props
    const hasExpiredTrial = profile?.hasExpiredTrial

    return (
      <Fragment>
        <EuiModalHeader>
          <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize='none'>
            <EuiFlexItem grow={false}>
              <EuiText size='s' data-test-id='trial-summary-modal.title'>
                <h3>{this.renderModalTitle()}</h3>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <RemainingTrial profile={profile} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeader>

        <EuiSpacer size='s' />

        <EuiModalBody>
          <EuiFlexGroup gutterSize='xl'>
            <EuiFlexItem grow={7}>
              <HelperLinks
                deploymentId={deployment?.id}
                trialStarted={!isTrialNotStartedUser(profile)}
                trialExpired={profile?.hasExpiredTrial}
              />

              {hasExpiredTrial && (
                <Fragment>
                  <EuiHorizontalRule margin='m' />

                  <ExtendTrialSection profile={profile} onTrialExtensionSuccess={closeModal} />
                </Fragment>
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiPanel grow={false} css={css({ textAlign: 'center' })}>
                <EuiImage
                  src={
                    { dark: integrationsIllustrationDark, light: integrationsIllustration }[theme]
                  }
                  width={175}
                  alt={formatMessage({
                    id: 'trial-summary-modal.integrations-box.illustration',
                    defaultMessage: 'An illustration of a packed box with integrations.',
                  })}
                />

                <EuiSpacer />

                <EuiText size='m'>
                  <h4>
                    <FormattedMessage
                      id='trial-summary-modal.no-data.promo-section.title'
                      defaultMessage='Enjoying Elastic?'
                    />
                  </h4>
                </EuiText>

                <EuiSpacer size='m' />

                <EuiText size='s'>
                  <FormattedMessage
                    id='trial-summary-modal.no-data.promo-section.description'
                    defaultMessage='Subscribe now and access everything that Elastic has to offer.'
                  />
                </EuiText>

                <EuiSpacer />

                <SubscribeButton onSendBillingDetailsSuccess={closeModal} />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>
      </Fragment>
    )
  }

  renderModalTitle = (): JSX.Element => {
    const { profile } = this.props
    const hasExpiredTrial = profile?.hasExpiredTrial

    if (hasExpiredTrial) {
      return (
        <FormattedMessage
          id='trial-summary-modal.expired-trial-no-data-title'
          defaultMessage='Your trial has expired'
        />
      )
    }

    return (
      <FormattedMessage
        id='trial-summary-modal.active-trial-no-data-title'
        defaultMessage='Free trial'
      />
    )
  }
}

export default injectIntl(TrialSummaryWithoutDeployment)
