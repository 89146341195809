/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonIcon, EuiPopover, EuiText } from '@elastic/eui'

import DocLink from '../../../../../../DocLink'

import type { ReactElement } from 'react'

type State = {
  isOpen: boolean
}

class CpuInfo extends React.Component<unknown, State> {
  state = {
    isOpen: false,
  }

  render(): ReactElement | null {
    const button = (
      <EuiButtonIcon
        onClick={() => this.setState({ isOpen: true })}
        iconType='iInCircle'
        color='text'
        aria-label='Info'
      />
    )

    return (
      <EuiPopover
        anchorPosition='upCenter'
        button={button}
        isOpen={this.state.isOpen}
        closePopover={() => this.setState({ isOpen: false })}
        className='fs-unmask'
      >
        <EuiText>
          <FormattedMessage
            defaultMessage='Learn more about how {cpuAllocation}'
            id='cpu-popover-message'
            values={{
              cpuAllocation: (
                <DocLink link='vcpuBoost'>
                  <FormattedMessage
                    id='vcpuInfo.learnMore'
                    defaultMessage='CPU resources get allocated.'
                  />
                </DocLink>
              ),
            }}
          />
        </EuiText>
      </EuiPopover>
    )
  }
}

export default CpuInfo
