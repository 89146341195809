/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiCallOut, EuiCode, EuiForm, EuiLoadingSpinner } from '@elastic/eui'

import type { ActiveDirectorySettings } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, RegionId } from '@modules/ui-types'
import history from '@modules/utils/history'
import Header from '@modules/cui/Header'

import { authenticationProvidersUrl } from '../../../../../../../lib/urlBuilder'
import { editActiveDirectoryAuthProviderCrumbs } from '../../../../../../../lib/crumbBuilder'
import ActiveDirectoryProviderForm from '../ActiveDirectoryProviderForm'
import validateActiveDirectoryForm from '../validateActiveDirectoryForm'
import { messages } from '../../authProviderMessages'
import { formToSettings, settingsToForm } from '../requestMapping'

import type { WrappedComponentProps } from 'react-intl'

export interface Props extends WrappedComponentProps {
  fetchActiveDirectorySecurityRealm: () => void
  fetchActiveDirectorySecurityRealmRequest: AsyncRequestState
  activeDirectorySettings: ActiveDirectorySettings | undefined
  onSubmit: (payload: ActiveDirectorySettings) => Promise<any>
  updateActiveDirectorySecurityRealmRequest: AsyncRequestState
  regionId: RegionId
  realmId: string
  isPlanChangePending?: boolean
  isSecurityDeploymentDisabled?: boolean
}

class EditActiveDirectoryProvider extends Component<Props> {
  componentDidMount() {
    this.props.fetchActiveDirectorySecurityRealm()
  }

  render() {
    const {
      intl: { formatMessage },
      regionId,
      realmId,
    } = this.props

    return (
      <Fragment>
        <Header
          name={formatMessage(messages.editActiveDirectoryProvider)}
          breadcrumbs={editActiveDirectoryAuthProviderCrumbs({ regionId, realmId })}
        />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent() {
    const {
      intl,
      fetchActiveDirectorySecurityRealmRequest,
      activeDirectorySettings,
      regionId,
      realmId,
      onSubmit,
      updateActiveDirectorySecurityRealmRequest,
      isPlanChangePending = false,
      isSecurityDeploymentDisabled = false,
    } = this.props

    if (fetchActiveDirectorySecurityRealmRequest.inProgress) {
      return <EuiLoadingSpinner />
    }

    if (!activeDirectorySettings) {
      return (
        <EuiCallOut
          title={
            <FormattedMessage
              {...messages.noSuchActiveDirectoryRealmId}
              values={{ realmId: <EuiCode>{realmId}</EuiCode> }}
            />
          }
        />
      )
    }

    return (
      <EuiForm>
        <Formik
          initialValues={settingsToForm(activeDirectorySettings)}
          validate={validateActiveDirectoryForm(intl)}
          onSubmit={(values) =>
            onSubmit(formToSettings(values)).then(() =>
              history.push(authenticationProvidersUrl(regionId)),
            )
          }
        >
          {(props) => (
            <ActiveDirectoryProviderForm
              {...props}
              submitRequest={updateActiveDirectorySecurityRealmRequest}
              onCancel={() => history.push(authenticationProvidersUrl(regionId))}
              disabled={isPlanChangePending || isSecurityDeploymentDisabled}
              regionId={regionId}
              realmId={realmId}
            />
          )}
        </Formik>
      </EuiForm>
    )
  }
}

export default injectIntl(EditActiveDirectoryProvider)
