/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { AnyProject } from '@modules/project-api/types'
import { getDeleteProjectMutationHook } from '@modules/project-lib/hooks/delete'
import history from '@modules/utils/history'
import { projectsUrl } from '@modules/project-lib/urls'

import genericMessages from '../helpers/genericMessages'

export type Props = {
  project: AnyProject
}

const DeleteProjectButton: React.FunctionComponent<Props> = ({ project }) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const [enteredProjectName, setEnteredProjectName] = useState('')

  const deleteProjectMutation = getDeleteProjectMutationHook(project.type)()

  return (
    <Fragment>
      {renderButton()}
      {renderModal()}
    </Fragment>
  )

  function renderButton() {
    return (
      <EuiButtonEmpty color='danger' onClick={openModal}>
        <FormattedMessage {...genericMessages.delete} />
      </EuiButtonEmpty>
    )
  }

  function renderModal() {
    if (!isConfirming) {
      return
    }

    return (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus='[name=confirmation-input]'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='project.delete.title'
                defaultMessage='Delete {name}?'
                values={{
                  name: <strong>{project.name}</strong>,
                }}
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiText>
              <p>
                <FormattedMessage
                  id='project.delete.body'
                  defaultMessage='Deleting this project will remove all data. Type in {name} below to remove this project.'
                  values={{
                    name: <strong>{project.name}</strong>,
                  }}
                />
              </p>
            </EuiText>
            <EuiSpacer size='m' />
            <EuiFieldText
              name='confirmation-input'
              value={enteredProjectName}
              onChange={(e) => setEnteredProjectName(e.target.value)}
            />
          </EuiModalBody>

          <EuiModalFooter>
            {!deleteProjectMutation.isLoading && (
              <EuiButtonEmpty onClick={closeModal}>
                <FormattedMessage {...genericMessages.cancel} />
              </EuiButtonEmpty>
            )}

            <EuiButton
              isDisabled={enteredProjectName.trim() !== project.name.trim()}
              onClick={performDelete}
              fill={true}
              color='danger'
              isLoading={deleteProjectMutation.isLoading}
            >
              {deleteProjectMutation.isLoading ? (
                <FormattedMessage {...genericMessages.deleting} />
              ) : (
                <FormattedMessage {...genericMessages.delete} />
              )}
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  function openModal() {
    setIsConfirming(true)
  }

  function closeModal() {
    setIsConfirming(false)
    setEnteredProjectName('')
  }

  function performDelete() {
    deleteProjectMutation.mutate(
      { id: project.id },
      {
        onSuccess: () => {
          history.push(projectsUrl())
        },
      },
    )
  }
}

export default DeleteProjectButton
