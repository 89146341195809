/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import Markdown from 'markdown-it'
import { flatMap, capitalize } from 'lodash'
import React, { useState, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import mila from 'markdown-it-link-attributes'
import { css, jsx } from '@emotion/react'

import {
  EuiAccordion,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
  EuiDescriptionList,
  useEuiBackgroundColor,
} from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import type { Diagnosis } from '@modules/cloud-api/v1/types'

import sanitizeHtml from '@/lib/sanitizeHtml'

import HealthStatusDetailsTroubleshootingFlyout from './HealthStatusDetailsTroubleshootingFlyout'

import type { FunctionComponent } from 'react'

export interface TroubleshootingSectionProps extends WithEuiThemeProps {
  diagnosis?: Diagnosis
}

const MAX_RESOURCES_PREVIEW = 6
const UNCLASSIFIED_RESOURCES = 'unclassified_resources'

const md = new Markdown({
  linkify: true, // auto-convert URL-like text to links
  html: false, // disable HTML tags in source
})

// use markdown-it plugin to add target attributes to external links
md.use(mila, {
  attrs: {
    target: '_blank',
    'data-test-subj': 'troubleshooting-action_url',
  },
})

const computePreviewForResourcesList = (resourcesList: string[]) => ({
  preview: resourcesList.slice(0, MAX_RESOURCES_PREVIEW),
  hidden: resourcesList.slice(MAX_RESOURCES_PREVIEW, resourcesList.length),
})

const resourceKeyToName = (resourceKey: string) =>
  // SLM policies need to be specifically capitalized
  resourceKey === 'slm_policies' ? 'SLM policies' : capitalize(resourceKey.replace('_', ' '))

const createAffectedResourcesPreview = (affectedResources) =>
  Object.keys(affectedResources).reduce((list, resourceKey) => {
    const resource = affectedResources[resourceKey]
    const resources = resourceKey === 'nodes' ? resource.map((res) => res.name) : resource

    return {
      ...list,
      [resourceKey]: {
        name: resourceKeyToName(resourceKey),
        resources,
        ...computePreviewForResourcesList(resources),
      },
    }
  }, {})

export const HealthStatusDetailsTroubleshootingDiagnosis: FunctionComponent<
  TroubleshootingSectionProps
> = ({ diagnosis, theme }) => {
  const accordionId = useGeneratedHtmlId({
    prefix: 'health-status-details-accordion',
  })
  const [trigger, setTrigger] = useState<'open' | 'closed'>('closed')
  const [flyoutVisibleFor, setFlyoutVisibleFor] = useState<string>('closed')

  const [affectedResources, affectedResourcesPreview] = useMemo(() => {
    // Enhance affected resources with preview and hidden arrays to display in the UI
    const affectedResources = createAffectedResourcesPreview(diagnosis?.affected_resources)

    return [
      affectedResources,
      // When previewing the affected resources in the accordion, we want to compute them all together
      computePreviewForResourcesList(flatMap(affectedResources, 'resources')),
    ]
  }, [diagnosis?.affected_resources])

  // In order to render each affected resource list, we need to compute them as a list items
  // object that Eui can render
  const affectedResourcesAsListItems = useMemo(
    () =>
      Object.keys(affectedResources).reduce((list, key) => {
        const resource = affectedResources[key]
        return [
          ...list,
          {
            key,
            title: resource.name,
            description: (
              <span data-test-subj='affected_resource_description'>
                {resource.preview.join(', ')}{' '}
                {resource.hidden.length > 0 && (
                  <EuiLink
                    onClick={() => setFlyoutVisibleFor(key)}
                    data-test-subj='health=problem-diagnosis-more_affected_resources-button'
                  >
                    <FormattedMessage
                      id='health-status-details.troubleshooting-more_affected_resources'
                      defaultMessage='and {hiddenItemsCount} more.'
                      values={{ hiddenItemsCount: resource.hidden.length }}
                    />
                  </EuiLink>
                )}
              </span>
            ),
          },
        ]
      }, []),
    [affectedResources],
  )

  const hasOnlyUnclassifiedResources =
    affectedResourcesAsListItems.length === 1 &&
    affectedResourcesAsListItems[0].key === UNCLASSIFIED_RESOURCES

  // Create emotion styles for our title container and accordion container
  const isOpen = trigger === 'open'
  const bgColorActive = useEuiBackgroundColor('primary')
  const bgColorInactive = useEuiBackgroundColor('subdued')

  const titleContainerCss = css({
    backgroundColor: isOpen ? bgColorActive : bgColorInactive,
    padding: theme.euiTheme.size.m,
    border: theme.euiTheme.border.thin,
    borderTopLeftRadius: theme.euiTheme.size.s,
    borderTopRightRadius: theme.euiTheme.size.s,
    borderBottomLeftRadius: isOpen ? 'none' : theme.euiTheme.size.s,
    borderBottomRightRadius: isOpen ? 'none' : theme.euiTheme.size.s,
    borderBottom: isOpen ? 'none' : theme.euiTheme.border.thin,
    marginBottom: isOpen ? 0 : theme.euiTheme.size.m,
    transition: 'background-color .25s ease-out',
  })

  const accordionContainerCss = css({
    border: isOpen ? theme.euiTheme.border.thin : 'none',
    borderTop: 'none',
    borderBottomLeftRadius: theme.euiTheme.size.s,
    borderBottomRightRadius: theme.euiTheme.size.s,
    marginBottom: isOpen ? theme.euiTheme.size.m : 0,
  })

  const actionText = md.render((diagnosis?.cause || '') + ' ' + (diagnosis?.action || ''))

  return (
    <React.Fragment>
      <EuiFlexItem css={titleContainerCss}>
        <EuiFlexGroup responsive={false} alignItems='center'>
          <EuiFlexItem>
            <EuiFlexGroup responsive={false} alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiIcon type='wrench' size='l' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiTitle size='xs'>
                  <h5>
                    <FormattedMessage
                      id='health-status-details.troubleshooting-sub-title'
                      defaultMessage='How to fix'
                    />
                  </h5>
                </EuiTitle>
                {affectedResourcesPreview.preview.length > 0 && (
                  <React.Fragment>
                    <EuiSpacer size='s' />
                    <EuiText
                      size='s'
                      data-test-subj='health-problem-diagnosis-affected-resources-preview'
                    >
                      {sanitizeHtml(md.render(diagnosis?.action || ''))}
                    </EuiText>
                  </React.Fragment>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              size='s'
              iconType={isOpen ? 'arrowUp' : 'arrowDown'}
              iconSide='right'
              onClick={() => setTrigger(isOpen ? 'closed' : 'open')}
            >
              {isOpen ? (
                <FormattedMessage
                  id='health-status-details.troubleshooting-collapse'
                  defaultMessage='Collapse'
                />
              ) : (
                <FormattedMessage
                  id='health-status-details.troubleshooting-expand'
                  defaultMessage='Expand'
                />
              )}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem css={accordionContainerCss}>
        <EuiAccordion
          data-test-subj='health-problem-diagnosis-accordion'
          id={accordionId}
          paddingSize='l'
          arrowDisplay='none'
          forceState={trigger}
          css={css({ paddingLeft: 36, paddingRight: 36 })}
        >
          <React.Fragment>
            <EuiText size='s' data-test-subj='heath-problem-diagnosis-action-text'>
              {sanitizeHtml(actionText)}
            </EuiText>
            <EuiText size='s'>
              <EuiLink
                href={diagnosis?.help_url}
                target='_blank'
                data-test-subj='troubleshooting-help_url'
              >
                {diagnosis?.help_url}
              </EuiLink>
            </EuiText>
            {affectedResourcesPreview.preview.length > 0 && (
              <React.Fragment>
                <EuiSpacer size='m' />
                <EuiText size='s'>
                  <FormattedMessage
                    id='health-status-details.troubleshooting-affected_resources_label'
                    defaultMessage='This problem affects the following resources: '
                  />
                  {hasOnlyUnclassifiedResources ? (
                    affectedResourcesAsListItems[0].description
                  ) : (
                    <React.Fragment>
                      <EuiSpacer size='m' />
                      <EuiDescriptionList listItems={affectedResourcesAsListItems} />
                    </React.Fragment>
                  )}

                  {flyoutVisibleFor !== 'closed' && (
                    <HealthStatusDetailsTroubleshootingFlyout
                      onClose={() => setFlyoutVisibleFor('closed')}
                      resourceName={resourceKeyToName(flyoutVisibleFor)}
                      affectedResources={affectedResources[flyoutVisibleFor]?.resources}
                      actionText={actionText}
                    />
                  )}
                </EuiText>
              </React.Fragment>
            )}
          </React.Fragment>
        </EuiAccordion>
      </EuiFlexItem>
    </React.Fragment>
  )
}
