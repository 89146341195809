/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, sortBy } from 'lodash'
import moment from 'moment'

import type { SaasOrganizationResponse } from '@modules/cloud-api/v1/types'
import type {
  ProfileState,
  OrganizationFromApi,
  UserProfileFromApi,
  SaasUserDomain,
  UserProfileData,
  RecurlyBillingInfo,
  UserProfile,
} from '@modules/ui-types'

import { DEFAULT_TRIAL_DAYS } from '@/constants/trial'

interface GetUsernameParams {
  isHeroku: boolean
  profile: ProfileState
}

export function getUsername({ isHeroku, profile }: GetUsernameParams): string | null {
  const herokuEmail = profile?.data.heroku?.owner_email

  if (isHeroku && herokuEmail) {
    return herokuEmail
  }

  const username = profile && (profile.email || profile.user_id)

  if (!username) {
    return null
  }

  return String(username)
}

export function saasOrganizationToUserProfile({
  organization,
  subscription,
  rules,
  trials,
}: SaasOrganizationResponse): UserProfile {
  const profileFromApi: OrganizationFromApi = {
    ...organization,
    ...subscription,
    ...rules,
    // SaasOrganization doesn't have the user_id and email attributes.
    // Filling them with empty values since they are not being used by any component.
    // This won't be needed once the saasUserToUserProfile function is deleted and
    // saasOrganization is passed directly to the components that used to require a profile.
    user_id: 0,
    email: '',
    trials,
    domain: organization.domain as SaasUserDomain,
    data: organization.data as UserProfileData,
    recurly_billing_info: subscription.recurly_billing_info as RecurlyBillingInfo,
  }

  return enhanceUserProfileFromApi(profileFromApi, null) as UserProfile
}

export function enhanceUserProfileFromApi(
  userOrOrganization: UserProfileFromApi | OrganizationFromApi,
  userProfileState: ProfileState,
): ProfileState {
  const { oktaApplications = [], driftJwt } = userProfileState || {}
  const { level, role_assignments } = userOrOrganization
  const trialContext = getTrialContext(userOrOrganization)
  const isPremium = level !== `standard` && !trialContext.inTrial

  return {
    ...userOrOrganization,
    ...trialContext,
    oktaApplications,
    driftJwt,
    isPremium,
    level,
    canUploadPlugins:
      (userOrOrganization.allow_plugins || userOrOrganization.allow_bundles) === true,
    allow_plugins: userOrOrganization.allow_plugins,
    allow_bundles: userOrOrganization.allow_bundles,
    role_assignments,
  }
}

/**
 * Note about trials.
 * All users start off as trial users.
 * There is a `trials` property that is an array. A paying user or a new (non-expired) trial will have this array be
 * empty. However, an expired trial or one that has been extended will be populated with at least one object. This object
 * has the trial start/end dates and a type. The type seems to always be `elasticsearch`. So even though this is an array
 * we just find the first `elasticsearch` type trial and use the end date to confirm that they have either an expired
 * trial or have been extended.
 */
function getTrialContext(userOrOrganization: UserProfileFromApi | OrganizationFromApi) {
  const { trials, trial_length = DEFAULT_TRIAL_DAYS } = userOrOrganization
  const inTrial = userOrOrganization.is_trial
  const currentTrial = getCurrentTrial(userOrOrganization)
  const isTrialExpired = hasExpiredTrial(userOrOrganization)
  const canRestartTrial = hasRestartableTrial(userOrOrganization)
  const canExtendTrial = !!userOrOrganization.can_request_trial_extension
  const trialDaysRemaining = calculateTrialDaysRemaining(userOrOrganization)

  return {
    trials,
    trial_length,
    inTrial,
    hasExpiredTrial: isTrialExpired,
    canRestartTrial,
    canExtendTrial,
    currentTrial,
    trialDaysRemaining,
  }
}

function getCurrentTrial(userOrOrganization: UserProfileFromApi | OrganizationFromApi) {
  return (
    sortBy(userOrOrganization.trials, (trial) => trial.end)[userOrOrganization.trials.length - 1] ||
    null
  )
}

function calculateTrialDaysRemaining(
  userOrOrganization: UserProfileFromApi | OrganizationFromApi,
): number | undefined {
  const expired = hasExpiredTrial(userOrOrganization)

  if (expired) {
    return userOrOrganization.is_trial ? 0 : undefined
  }

  const currentTrial = getCurrentTrial(userOrOrganization)

  if (!currentTrial) {
    return
  }

  const end = moment(currentTrial.end)
  const date = moment().startOf('day')
  const duration = moment.duration(end.diff(date))
  return Math.floor(duration.asDays())
}

function hasExpiredTrial(userOrOrganization: UserProfileFromApi | OrganizationFromApi): boolean {
  if (!userOrOrganization.is_trial) {
    return false
  }

  const trials = userOrOrganization.trials.filter((trial) => trial.type === `elasticsearch`)

  if (isEmpty(trials)) {
    return false
  }

  // Users can have more than one trial, so we need to grab the most recent
  const currentTrial = getCurrentTrial(userOrOrganization)
  const ended = moment().isAfter(currentTrial.end)

  return ended
}

function hasRestartableTrial(
  userOrOrganization: UserProfileFromApi | OrganizationFromApi,
): boolean {
  if (!userOrOrganization.trials || userOrOrganization.trials.length < 1) {
    return false
  }

  const isTrialExpired = hasExpiredTrial(userOrOrganization)
  const currentTrial = getCurrentTrial(userOrOrganization)
  return isTrialExpired && Boolean(currentTrial.restartable)
}

export function getUserUsecase(profile: ProfileState | UserProfile): string {
  if (!profile?.data) {
    return ''
  }

  const { data } = profile

  if (data.discovery && data.discovery.use_case) {
    return data.discovery.use_case.toLowerCase()
  }

  return ''
}
