/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ElasticsearchLogResponse } from '@modules/cloud-api/v1/types'

import { ReduxState } from '@/types/redux'

import { FETCH_LOGS } from '../../constants/actions'

import type { ElasticsearchLogMessage, FetchLogsAction, LogsApiResponse, State } from './logTypes'

function mapFields(log: ElasticsearchLogResponse): ElasticsearchLogMessage {
  return {
    instanceId: parseInt(log.instance_id.replace(/^(?:instance|tiebreaker)-0*/, ``), 10) || 0,
    instanceName: log.instance_id.replace(/^(?:instance|tiebreaker)-/, ``),
    isTiebreaker: /^tiebreaker/.test(log.instance_id),
    level: log.log_level,
    timestamp: new Date(log.timestamp),
    loggerName: log.logger_name || ``,
    clusterName: log.cluster_name || ``,
    zone: log.logical_availability_zone,
    shortMessage: log.short_message || ``,
  }
}

function reduceLogs(body: LogsApiResponse) {
  return {
    total: {
      value: body.total,
    },
    entries: body.logs.map(mapFields),
  }
}

export default function logsReducer(state: State = {}, action: FetchLogsAction): State {
  if (action.type === FETCH_LOGS) {
    if (action.error == null && action.payload != null) {
      const payload = action.payload
      const { deploymentId, etag } = action.meta

      const currentLogState = state[deploymentId]

      if (currentLogState != null && currentLogState.etag === etag) {
        return state
      }

      return {
        ...state,
        [deploymentId]: {
          ...currentLogState,
          etag,
          logs: reduceLogs(payload),
        },
      }
    }
  }

  return state
}

export function getLogs(state: ReduxState, deploymentId: string) {
  return state.logs[deploymentId]?.logs
}
