/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { flatMap, without } from 'lodash'
import { withRouter } from 'react-router-dom'

import { fetchDeployment } from '../../../actions/stackDeployments'
import { fetchDeploymentHealth } from '../../../actions/deploymentHealth'
import { getDeploymentHealth, getStackDeployment } from '../../../reducers'
import { getAllKnownSliderInstanceTypes } from '../../../lib/sliders/sliders'
import withPolling from '../../../lib/withPolling'

import type { ConsumerProps } from './DeploymentDependencyLoader'

const mapStateToProps = (state, { match, stackDeploymentId }: ConsumerProps) => ({
  stackDeployment: getStackDeployment(state, stackDeploymentId),
  deploymentHealth: getDeploymentHealth(state, stackDeploymentId),
  match,
})

const mapDispatchToProps = {
  fetchDeployment,
  fetchDeploymentHealth,
}

// FIXME: the types in this file are non-existent :grimace:

const stackDeploymentPoller = (props) => {
  const {
    fetchDeployment,
    fetchDeploymentHealth,
    stackDeploymentId,
    deployment,
    kibana,
    apm,
    match,
    hasPermissions,
  } = props

  if (!hasPermissions) {
    return {
      onPoll: () => Promise.resolve(),
      pollImmediately: [],
      stopPolling: true,
    }
  }

  const anyPendingPlan =
    deployment?.plan?.isPending || kibana?.plan?.isPending || apm?.plan?.isPending

  const pollImmediatelyBecauseStack = [[`deployment`, `regionId`], `stackDeploymentId`]
  const pollImmediatelyBecauseEs = [`deploymentId`, [`deployment`, `wasDeleted`]]

  const pollImmediatelyBecauseKib = [[`pendingKibana`, `isCreating`]]

  const pollImmediatelyBecauseApm = [[`pendingApm`, `isCreating`]]

  const pollImmediatelyBecauseSliders = flatMap(
    without(getAllKnownSliderInstanceTypes(), `elasticsearch`),
    (sliderInstanceType) => [
      [`deployment`, sliderInstanceType, `enabled`],
      [`deployment`, sliderInstanceType, `id`],
      [sliderInstanceType, `plan`, `waitingForPending`],
    ],
  )

  return {
    onPoll: () =>
      Promise.all([
        fetchDeployment({ deploymentId: stackDeploymentId }),
        fetchDeploymentHealth({
          deploymentId: stackDeploymentId,
          elasticsearchVerbose: match.url.includes('/health'),
        }),
      ]),

    // We need to poll more frequently if a plan is in-flight, because messaging and health changes more often.
    interval: anyPendingPlan ? 15 : 30,
    pollImmediately: [
      ...pollImmediatelyBecauseStack,
      ...pollImmediatelyBecauseEs,
      ...pollImmediatelyBecauseKib,
      ...pollImmediatelyBecauseApm,
      ...pollImmediatelyBecauseSliders,
    ],
    stopPolling: deployment && deployment.wasDeleted,
  }
}

export default (wrappedComponent) =>
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(withPolling(wrappedComponent, stackDeploymentPoller)),
  )
