/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import {
  EuiPanel,
  EuiSpacer,
  EuiTitle,
  EuiText,
  EuiSkeletonText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  EuiLink,
} from '@elastic/eui'

import { CuiLink } from '@modules/cui/Link'

import { deploymentUrl } from '@/lib/urlBuilder'

import type { AllProps } from './types'

class AlsoTrustedByDeploymentsList extends Component<AllProps> {
  componentDidMount(): void {
    const { searchForAlsoTrustedByDeployments } = this.props

    searchForAlsoTrustedByDeployments(this.getAlsoTrustedByDeploymentIds())
  }

  componentDidUpdate(): void {
    const { searchForAlsoTrustedByDeployments, alsoTrustedByDeploymentsRequest } = this.props

    // only run once
    if (alsoTrustedByDeploymentsRequest.inProgress || alsoTrustedByDeploymentsRequest.isDone) {
      return
    }

    searchForAlsoTrustedByDeployments(this.getAlsoTrustedByDeploymentIds())
  }

  render(): JSX.Element | null {
    const { alsoTrustedByDeployments, trustRelationship } = this.props

    const alsoTrustedByDeploymentIds = this.getAlsoTrustedByDeploymentIds()
    const envName = trustRelationship ? trustRelationship.name : ''

    if (!alsoTrustedByDeployments) {
      return <EuiSkeletonText lines={1} />
    }

    if (!trustRelationship || alsoTrustedByDeploymentIds.length === 0) {
      return null
    }

    return (
      <div style={{ maxWidth: `15rem` }}>
        <EuiPanel color='subdued' paddingSize='l' hasShadow={false}>
          <EuiTitle size='xxs'>
            <h5>
              <FormattedMessage
                id='deploymentTrustManagement.direct.alsoTrustedByDeploymentsTable.label'
                defaultMessage='Deployments from this environment trusting {envName}'
                values={{ envName }}
              />
            </h5>
          </EuiTitle>
          <EuiSpacer size='m' />
          <EuiFlexGroup gutterSize='none' direction='column'>
            {alsoTrustedByDeploymentIds.map((deploymentId) => {
              const trustedDeployment = alsoTrustedByDeployments.find(
                (_deployment) => _deployment.id === deploymentId,
              )

              if (!trustedDeployment) {
                return null
              }

              const { id, name } = trustedDeployment

              return (
                <Fragment>
                  <EuiLink style={{ textDecoration: 'underline' }}>
                    <CuiLink to={deploymentUrl(id)}>
                      <EuiFlexItem grow={false}>
                        <EuiHighlight search={``}>{name}</EuiHighlight>
                      </EuiFlexItem>
                    </CuiLink>
                  </EuiLink>
                  <EuiSpacer size='s' />
                </Fragment>
              )
            })}
          </EuiFlexGroup>
          <EuiSpacer size='s' />
          <EuiText size='s'>
            <FormattedMessage
              id='deploymentTrustManagement.direct.alsoTrustedByDeploymentsTable.message.selfManaged'
              defaultMessage='Any changes applied here will also apply to the trust relationship that other deployments from this organization have with the {envName} environment.'
              values={{ envName }}
            />
          </EuiText>
        </EuiPanel>
      </div>
    )
  }

  getAlsoTrustedByDeploymentIds(): string[] {
    const { certificates } = this.props

    // only run if there are discrete deployments to query
    if (!certificates || certificates.length === 0) {
      return []
    }

    return certificates
      .filter((cert) => moment(cert.metadata?.valid_to).diff(moment(), 'days') >= 0) // filter out expired certificates
      .map((cert) => cert.metadata?.also_trusted_by)
      .filter((also_trusted_by): also_trusted_by is string[] => !!also_trusted_by) // filter out certificates without metadata
      .flat()
  }
}

export default AlsoTrustedByDeploymentsList
