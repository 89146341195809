/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { Runner } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { getRunner } from '../../../reducers'
import { isFeatureActivated } from '../../../selectors'

import RunnerOverview from './RunnerOverview'

import type { ReduxState } from '@/types/redux'

type StateProps = {
  runner?: Runner
  containerSetsFeature: boolean
}

interface DispatchProps {}

type ConsumerProps = {
  regionId: string
  hostId: string
}

const mapStateToProps = (state: ReduxState, { regionId, hostId }: ConsumerProps): StateProps => ({
  runner: getRunner(state, regionId, hostId),
  containerSetsFeature: isFeatureActivated(state, Feature.containerSets),
})

const mapDispatchToProps: DispatchProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RunnerOverview)
