/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { enrollSaasUserInOktaGroupUrl, getSaasUserOktaGroupsUrl } from '@modules/cloud-api/v1/urls'
import type {
  EnrollSaasUserInOktaGroupUrlParams,
  GetSaasUserOktaGroupsUrlParams,
} from '@modules/cloud-api/v1/urls'

import asyncRequest from '@/actions/asyncRequests'

import { FETCH_USER_OKTA_GROUPS, UPDATE_USER_OKTA_GROUPS } from '../constants/actions'

export function fetchUserOktaGroups(payload: GetSaasUserOktaGroupsUrlParams) {
  const { userId } = payload
  const url = getSaasUserOktaGroupsUrl(payload)

  return asyncRequest({
    method: `GET`,
    type: FETCH_USER_OKTA_GROUPS,
    url,
    meta: { userId },
    crumbs: [userId],
  })
}

export function postUserOktaGroups(payload: EnrollSaasUserInOktaGroupUrlParams) {
  const { userId } = payload
  const url = enrollSaasUserInOktaGroupUrl(payload)

  return asyncRequest({
    method: `POST`,
    type: UPDATE_USER_OKTA_GROUPS,
    payload,
    url,
    meta: { userId },
    crumbs: [userId],
  })
}
