/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState, Region } from '@modules/ui-types'

import { fetchRegionIfNeeded } from '../../../actions/regions'
import { fetchProvidersRequest, getRegion } from '../../../reducers'

import RegionWrapper from './RegionWrapper'

import type { ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  regionId: string
  providersRequest: AsyncRequestState
  region?: Region
}

type DispatchProps = {
  fetchRegionIfNeeded: (regionId: string) => void
}

type RouteParams = {
  regionId: string
}

type ConsumerProps = RouteComponentProps<RouteParams>

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  region: getRegion(state, regionId),
  providersRequest: fetchProvidersRequest(state, regionId),
})

const mapDispatchToProps: DispatchProps = { fetchRegionIfNeeded }

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RegionWrapper)
