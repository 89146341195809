/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  StackDeployment,
  SliderInstanceType,
  SliderInstanceDefinition,
} from '@modules/ui-types'

import { getSliderDefinition } from '../../../lib/sliders/definitions'
import { getEndpointForResource } from '../../../lib/serviceProviderEndpoints'
import { getKibanaBasedAppUrl } from '../../../lib/serviceProviderDeepLinks'
import { satisfies } from '../../semver'

import { getFirstAvailableSliderClusterFromGet } from './available'
import { getFirstSliderClusterFromGet, getResourceVersion, getVersion } from './fundamentals'

import type { GetDeepLinkFn } from '../../../lib/serviceProviderEndpoints'

export function getDeploymentResourceEndpoint({
  deployment,
  sliderInstanceType,
  getDeepLink,
  acceptSsoUrl = true,
  ignoreAlias = false,
}: {
  deployment: StackDeployment
  sliderInstanceType: SliderInstanceType
  getDeepLink?: GetDeepLinkFn
  acceptSsoUrl?: boolean
  ignoreAlias?: boolean
}): string {
  const resource = getFirstAvailableSliderClusterFromGet({ deployment, sliderInstanceType })

  if (!resource) {
    return ``
  }

  const hasKibanaBasedApp = acceptSsoUrl && getDefaultKibanaBasedAppDeepLink()

  if (hasKibanaBasedApp) {
    const kibanaAppEndpoint = getKibanaBasedAppUrl({
      deployment,
      getDeepLink: getDeepLink || getDefaultKibanaBasedAppDeepLink,
    })

    // fall through to the base case when Kibana is unavailable
    if (kibanaAppEndpoint) {
      return kibanaAppEndpoint
    }
  }

  const endpoint = getEndpointForResource({
    resource,
    resourceType: sliderInstanceType,
    getDeepLink: getSliderDeepLink,
    acceptSsoUrl,
    ignoreAlias,
  })

  return endpoint

  function getSliderDeepLink({ sso }: { sso: boolean }): string | null {
    if (getDeepLink) {
      return getDeepLink({ sso })
    }

    const sliderDefinition = getSliderDefinition({ sliderInstanceType }) as SliderInstanceDefinition
    const { applicationPath, applicationPathWhenUsingSso, applicationPathVersionSpecific } =
      sliderDefinition

    if (sso) {
      return applicationPathWhenUsingSso || null
    }

    if (applicationPathVersionSpecific && applicationPathVersionSpecific.length > 0) {
      const version = getResourceVersion({ resource }) || getVersion({ deployment })

      if (version) {
        const versionedPath = applicationPathVersionSpecific.find((path) =>
          satisfies(version, path.version),
        )

        if (versionedPath) {
          return versionedPath.path
        }
      }
    }

    return applicationPath || null
  }

  function getDefaultKibanaBasedAppDeepLink(): string | null {
    if (sliderInstanceType === `apm` || sliderInstanceType === `integrations_server`) {
      return `/app/apm`
    }

    if (sliderInstanceType === `enterprise_search`) {
      const entSearchResource = getFirstSliderClusterFromGet({
        deployment,
        sliderInstanceType: `enterprise_search`,
      })

      if (!entSearchResource) {
        return null
      }

      // source the version from Kibana if possible; otherwise fall back to the overall deployment version
      const version =
        getResourceVersion({ resource: entSearchResource }) || getVersion({ deployment })

      if (!version) {
        return null
      }

      // As of 8.0, the Enterprise Search UI is an app within Kibana instead of
      // a standalone UI within the Enterprise Search instance.
      if (satisfies(version, `>=8.0.0`)) {
        return `/app/enterprise_search/overview`
      }
    }

    return null
  }
}
