/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*
 * All the "get slider-related data from another business object" logic lives here.
 */

import { isEqual, pickBy, size } from 'lodash'

import type { InstanceConfiguration } from '@modules/cloud-api/v1/types'
import type { SliderType, SliderInstanceType, SliderNodeType } from '@modules/ui-types'

import { sortSliderTypes } from './sorting'
import { getSliderDefinition } from './definitions'

export function getSliderTypeId({ sliderInstanceType, sliderNodeType }: SliderType): string {
  return sliderNodeType != null ? `${sliderInstanceType}.${sliderNodeType}` : sliderInstanceType
}

export function getPrimarySliderTypeForInstanceConfiguration(
  instanceConfiguration: InstanceConfiguration,
): SliderType {
  const { instance_type: instanceType, node_types: nodeTypes = [] } = instanceConfiguration

  if (instanceType === `elasticsearch`) {
    const nodeType = nodeTypes.includes(`ml`) ? `ml` : nodeTypes[0]
    return {
      sliderInstanceType: instanceType as SliderInstanceType,
      sliderNodeType: nodeType as SliderNodeType,
    }
  }

  return {
    sliderInstanceType: instanceType as SliderInstanceType,
  }
}

/*
 * Helper function to return `SliderType`s for an instance configuration: one
 * SliderType per node type, or one with an undefined node type if the instance
 * type doesn't have explicit node types.
 */
export function getSliderTypesForInstanceConfiguration(
  instanceConfiguration: InstanceConfiguration,
): SliderType[] {
  // if the instance type doesn't have node types, add a mock node type so
  // the approach on the next line of looping through node types works regardless
  const nodeTypes: Array<SliderNodeType | undefined> =
    size(instanceConfiguration.node_types) > 0 ? instanceConfiguration.node_types! : [undefined]

  const sliderTypes = nodeTypes.map((nodeType) => ({
    sliderInstanceType: instanceConfiguration.instance_type,
    sliderNodeType: nodeType,
  }))

  return sortSliderTypes(sliderTypes)
}

export function getProductSliderTypeForInstanceConfiguration(
  instanceConfiguration: Pick<InstanceConfiguration, 'instance_type' | 'node_types'>,
): SliderType {
  const { instance_type: sliderInstanceType, node_types: nodeTypes } = instanceConfiguration

  const esMachineLearning = sliderInstanceType === `elasticsearch` && isEqual(nodeTypes, ['ml'])

  if (esMachineLearning) {
    // ML is the only product type that has a node type
    return {
      sliderInstanceType,
      sliderNodeType: nodeTypes![0],
    }
  }

  return { sliderInstanceType }
}

export function getDefaultNodeTypesForProductType(productType: SliderType): SliderNodeType[] {
  const { sliderNodeType } = productType

  // if product is specific to a node type, just return that
  if (sliderNodeType) {
    return [sliderNodeType]
  }

  const definition = getSliderDefinition(productType)

  // if the product has no node types, return empty
  if (definition.nodeTypeSets == null) {
    return []
  }

  // otherwise default to the first node type set in the list
  return Object.keys(pickBy(definition.nodeTypeSets[0].nodeTypes))
}
