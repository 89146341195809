/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { SearchRequest } from '@modules/cloud-api/v1/types'
import type {
  AllocatorSearchResult,
  AsyncRequestState,
  Region,
  RegionProxies,
  Runner,
} from '@modules/ui-types'

import { fetchProxies } from '../../../actions/proxies'
import {
  fetchProxiesRequest,
  getAllocatorSearchResults,
  getProxies,
  getRegion,
  searchAllocatorsRequest,
  searchRunnersRequest,
} from '../../../reducers'
import { getRunnerSearchResults } from '../../../apps/adminconsole/reducers'
import { searchRunners } from '../../../apps/adminconsole/actions/runnerSearch'
import withPolling from '../../../lib/withPolling'

import InfrastructureVisualization from './InfrastructureVisualization'

import type { ThunkDispatch, ReduxState } from '@/types/redux'

const runnerSearchQuery: SearchRequest = {
  size: 5000,
  query: {
    bool: {
      must_not: [
        {
          bool: {
            must: [
              // weird casts below due to lack of combination type support, https://github.com/elastic/cloud/pull/103751
              { term: { healthy: { value: true as unknown as string } } },
              { term: { connected: { value: false as unknown as string } } },
            ],
          },
        },
      ],
    },
  },
}

const pollingComponent = withPolling(
  InfrastructureVisualization,
  // eslint-disable-next-line no-shadow
  ({ fetchRunners, fetchProxies }) => ({
    onPoll: () => Promise.all([fetchRunners(), fetchProxies()]),
    pollImmediately: ['regionId'],
  }),
)

interface StateProps {
  allocatorSearchResults?: AllocatorSearchResult[]
  proxies?: RegionProxies
  proxiesRequest: AsyncRequestState
  region?: Region
  runnerSearchResults?: Runner[]
  searchAllocatorsRequest: AsyncRequestState
  searchRunnersRequest: AsyncRequestState
}

interface DispatchProps {
  fetchRunners: () => Promise<any>
  fetchProxies: () => Promise<any>
}

interface ConsumerProps {
  regionId: string
}

const mapStateToProps = (state: ReduxState, { regionId }: ConsumerProps): StateProps => ({
  allocatorSearchResults: getAllocatorSearchResults(state, regionId, 'allocators'),
  proxies: getProxies(state, regionId),
  proxiesRequest: fetchProxiesRequest(state, regionId),
  region: getRegion(state, regionId),
  runnerSearchResults: getRunnerSearchResults(state, regionId, 'runners'),
  searchAllocatorsRequest: searchAllocatorsRequest(state, regionId, 'allocators'),
  searchRunnersRequest: searchRunnersRequest(state, regionId, 'runners'),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { regionId }: ConsumerProps,
): DispatchProps => ({
  fetchRunners: () => dispatch(searchRunners('runners', regionId, runnerSearchQuery)),
  fetchProxies: () => dispatch(fetchProxies(regionId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(pollingComponent)
