/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText } from '@elastic/eui'

import type { ClusterInstanceInfo } from '@modules/cloud-api/v1/types'

import NodeInstanceStatsDisplay from '../NodeInstanceStatsDisplay'
import Percentage from '../../../../lib/Percentage'
import { getJVMMemoryPressure, getUsagePercent } from '../helpers'

import type { FunctionComponent } from 'react'

interface Props {
  instance: ClusterInstanceInfo
  tableView?: boolean
}

const NodeInstanceJVMMemoryPressure: FunctionComponent<Props> = ({
  instance,
  tableView = false,
}) => {
  const memoryPressure = getJVMMemoryPressure(instance)
  const stressed = isUnderPressure(memoryPressure)
  const available = 100

  if (tableView) {
    const color = stressed ? `danger` : `default`

    const memoryPressurePercent =
      typeof memoryPressure === 'undefined'
        ? getUsagePercent(memoryPressure, available) / 100
        : memoryPressure / 100

    return (
      <EuiText size='s' color={color} data-test-id='instance-stats-jvm-memory-percent'>
        <Percentage value={memoryPressurePercent} />
      </EuiText>
    )
  }

  return (
    <NodeInstanceStatsDisplay
      available={available}
      stressed={stressed}
      status={
        stressed ? (
          <EuiText color='danger' size='s' data-test-id='jvm-memory-pressure-high'>
            <FormattedMessage id='jvm-memory-pressure.high' defaultMessage='High' />
          </EuiText>
        ) : (
          <EuiText size='s' data-test-id='jvm-memory-pressure-normal'>
            <FormattedMessage id='jvm-memory-pressure.normal' defaultMessage='Normal' />
          </EuiText>
        )
      }
      used={memoryPressure}
      title={
        <FormattedMessage
          id='jvm-memory-pressure.default-label'
          defaultMessage='JVM memory pressure'
        />
      }
    />
  )
}

const isUnderPressure = (memoryPressure: number) => memoryPressure >= 75

export default NodeInstanceJVMMemoryPressure
