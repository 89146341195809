/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import type { WithEuiThemeProps } from '@elastic/eui'
import { EuiTitle, EuiText, EuiFlexItem, EuiFlexGroup, EuiSpacer, withEuiTheme } from '@elastic/eui'

import type {
  AnyResourceInfo,
  RegionId,
  SliderInstanceType,
  StackDeployment,
} from '@modules/ui-types'

import {
  isHiddenResource,
  countInstances,
  getPlanInfo,
  getResourceVersion,
  isSliderPlanActive,
  isStopped,
} from '@/lib/stackDeployments/selectors'

import SliderAppLinks from '../SliderAppLinks'
import NodesVisualization from '../../StackDeployments/StackDeploymentNodesVisualization'
import ResourceComments from '../../ResourceComments'
import DeploymentLockGate from '../../DeploymentLockGate'
import DocLink from '../../DocLink'
import { getSliderDefinition, getSliderDocLink } from '../../../lib/sliders'
import { isFleetServerAvailable } from '../../../lib/stackDeployments'
import { getConfigForKey } from '../../../store'

import ApmIntroNotification from './ApmIntroNotification'
import DeleteResource from './DeleteResource'
import RestartResource from './RestartResource'

import type { FunctionComponent } from 'react'

export interface Props {
  deployment: StackDeployment
  resource: AnyResourceInfo
  regionId: RegionId
  sliderInstanceType: SliderInstanceType
  showNativeMemoryPressure: boolean
  hideDelete: boolean
}

interface PropsWithTheme extends Props, WithEuiThemeProps {}

const OverviewEnabled: FunctionComponent<PropsWithTheme> = ({
  deployment,
  resource,
  regionId,
  sliderInstanceType,
  showNativeMemoryPressure,
  hideDelete,
  theme,
}) => {
  const plan = getPlanInfo({ resource })
  const version = getResourceVersion({ resource })

  const { messages } = getSliderDefinition({ sliderInstanceType, version })
  const docLink = getSliderDocLink({ sliderInstanceType, version })

  const active = isSliderPlanActive(plan, sliderInstanceType)
  const stopped = isStopped({ resource })
  const hidden = isHiddenResource({ resource })
  const { totalReported } = countInstances({ resource })

  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`
  const isApm = sliderInstanceType === `apm`

  const hasPendingPlan = getPlanInfo({ resource, state: 'pending' })

  const managementButtonsContainerStyle = css({
    border: `1px solid ${theme.euiTheme.colors.lightShade}`,
  })

  return (
    <div>
      <EuiFlexGroup alignItems='flexStart'>
        <EuiFlexItem>
          <EuiFlexGroup direction='column'>
            <EuiFlexItem>
              <EuiText grow={false}>
                <FormattedMessage
                  id='slider-description.message'
                  defaultMessage='{description} {docLink}'
                  values={{
                    description: <FormattedMessage {...messages.description} />,
                    docLink: docLink && (
                      <DocLink link={docLink}>
                        <FormattedMessage
                          id='slider-description.link'
                          defaultMessage='Learn more'
                        />
                        .
                      </DocLink>
                    ),
                  }}
                />
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              {isApm && !isFleetServerAvailable({ version }) && (
                <ApmIntroNotification deployment={deployment} />
              )}

              <SliderAppLinks
                deployment={deployment}
                resource={resource}
                sliderInstanceType={sliderInstanceType}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {!hasPendingPlan && !hidden && (
          <DeploymentLockGate>{renderManagementButtons()}</DeploymentLockGate>
        )}
      </EuiFlexGroup>

      <EuiSpacer size='xxl' />

      {totalReported === 0 || (
        <Fragment>
          <NodesVisualization
            title={
              <EuiTitle size='s'>
                <h3>
                  <FormattedMessage id='deployment-info.instances' defaultMessage='Instances' />
                </h3>
              </EuiTitle>
            }
            deployment={deployment}
            showNativeMemoryPressure={showNativeMemoryPressure}
            sliderInstanceType={sliderInstanceType}
          />
        </Fragment>
      )}

      <ResourceComments
        spacerBefore={true}
        resourceType={sliderInstanceType}
        regionId={regionId}
        resourceId={resource.id}
      />
    </div>
  )

  function renderManagementButtons() {
    const buttonsToRender: JSX.Element[] = []

    if (active && !stopped) {
      buttonsToRender.push(
        <div key='restartResource'>
          <RestartResource
            deployment={deployment}
            resource={resource}
            sliderInstanceType={sliderInstanceType}
          />
        </div>,
      )
    }

    // userconsole users only get to see the above button to force restart their deployment
    if (!isUserConsole) {
      if (!hideDelete) {
        buttonsToRender.push(
          <div key='deleteResource'>
            <DeleteResource
              deployment={deployment}
              resource={resource}
              sliderInstanceType={sliderInstanceType}
            />
          </div>,
        )
      }
    }

    if (buttonsToRender.length === 0) {
      return null
    }

    return (
      <EuiFlexItem grow={false} className='sliderOverview-managementButtons'>
        <fieldset
          className='sliderOverview-managementButtonsContainer'
          data-test-id='slider-management-panel'
          css={managementButtonsContainerStyle}
        >
          <legend className='sliderOverview-managementButtonsLegend'>
            <FormattedMessage
              id='slider-overview.legend'
              defaultMessage='{prettyName} Management'
              values={{ prettyName: <FormattedMessage {...messages.prettyName} /> }}
            />
          </legend>
          <Fragment>{buttonsToRender}</Fragment>
        </fieldset>
      </EuiFlexItem>
    )
  }
}

export default withEuiTheme(OverviewEnabled)
