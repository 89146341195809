/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type {
  DeploymentsSearchResponse,
  DeploymentSearchResponse,
} from '@modules/cloud-api/v1/types'
import type {
  AsyncRequestState,
  RegionId,
  RestoreSnapshot,
  ClusterSnapshot,
  RestorePayload,
  StackDeployment,
} from '@modules/ui-types'

import { fetchSnapshots } from '../../../../../../../actions/snapshots'
import { getConfigForKey } from '../../../../../../../selectors'
import {
  fetchSnapshotsRequest,
  getClusterSnapshots,
  searchStackDeploymentsRequest,
  getRegionName,
  getStackDeploymentsFromSearch,
} from '../../../../../../../reducers'

import SelectSnapshotSource from './SelectSnapshotSource'

import type { ReduxState } from '@/types/redux'

type StateProps = {
  deploymentSearchResults?: DeploymentsSearchResponse | null
  fetchSnapshotsRequest: (deploymentId: string) => AsyncRequestState
  getClusterSnapshots: (deploymentId: string) => ClusterSnapshot[] | null | undefined
  searchDeploymentsRequest: AsyncRequestState
  getRegionName: (regionId: RegionId) => void
  isUserconsole: boolean
}

type DispatchProps = {
  fetchSnapshots: (params: { deployment: StackDeployment }) => void
}

export interface ConsumerProps {
  asRestoreForm: boolean
  forceLastSnapshot: boolean
  onUpdateSnapshotSource: (value?: DeploymentSearchResponse | null) => void
  onRestoreSnapshot?: () => any
  onSelectSnapshot: (snapshot: ClusterSnapshot | null, id: string) => void
  onUpdateIndexRestore?: (payload: RestorePayload) => void
  regionId: string | null
  restoreSnapshotRequest?: AsyncRequestState
  showRegion: boolean
  snapshotRestoreSettings: RestoreSnapshot | undefined
  searchDeployments: (userInput: string) => void
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  deploymentSearchResults: getStackDeploymentsFromSearch(state, 'deployments'),
  fetchSnapshotsRequest: (deploymentId) => fetchSnapshotsRequest(state, deploymentId),
  getClusterSnapshots: (deploymentId) => getClusterSnapshots(state, deploymentId),
  searchDeploymentsRequest: searchStackDeploymentsRequest(state, 'deployments'),
  getRegionName: (regionId) => getRegionName(state, regionId),
  isUserconsole: getConfigForKey(state, `APP_NAME`) === `userconsole`,
})

const mapDispatchToProps: DispatchProps = {
  fetchSnapshots,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectSnapshotSource)
