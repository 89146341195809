/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { injectIntl, FormattedMessage } from 'react-intl'

import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'

import { filterDeploymentRoleAssignmentByAll } from '@/components/Users/RoleAssignmentsPanel/lib'

import messages from './messages'
import PermissionsRolePanel from './PermissionsRolePanel'
import AllRolesPanel from './AllRolesPanel'
import SpecificDeploymentsPanel from './SpecificDeploymentsPanel'

import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'
import type { DeploymentRole } from '@/components/Users/RoleAssignmentsPanel/types'

export type Props = {
  roleAssignments: RoleAssignments
  organizationId: string
  allOrganizationDeploymentsCount: number
} & WrappedComponentProps

const MemberRolesSummary: FunctionComponent<Props> = ({
  intl: { formatMessage },
  organizationId,
  allOrganizationDeploymentsCount,
  roleAssignments,
}) => {
  const totalRoleAssignments =
    (roleAssignments.organization?.length ?? 0) + (roleAssignments.deployment?.length ?? 0)

  if (totalRoleAssignments === 0) {
    return (
      <EuiEmptyPrompt
        titleSize='xs'
        title={
          <h4>
            <FormattedMessage
              id='organization.view-permissions-flyout.no-role-assignment'
              defaultMessage='This member is not assigned to any role yet.'
            />
          </h4>
        }
      />
    )
  }

  const allDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
    roleAssignments.deployment,
    true,
  )

  const specificDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
    roleAssignments.deployment,
    false,
  )

  return (
    <EuiFlexGroup direction='column' gutterSize='m'>
      {roleAssignments.organization?.map(({ role_id: roleId }) => (
        <EuiFlexItem key={roleId}>
          <PermissionsRolePanel
            title={formatMessage(messages.label[roleId])}
            description={formatMessage(messages.description[roleId])}
          />
        </EuiFlexItem>
      ))}

      {allDeploymentsRoleAssignments.map(({ role_id: roleId }) => (
        <EuiFlexItem key={roleId}>
          <PermissionsRolePanel
            title={formatMessage(messages.label.deploymentAccess)}
            description={formatMessage(messages.description.deploymentAccess)}
          >
            <AllRolesPanel
              roleId={roleId as DeploymentRole}
              currentAllDeploymentsCount={allOrganizationDeploymentsCount}
            />
          </PermissionsRolePanel>
        </EuiFlexItem>
      ))}

      {specificDeploymentsRoleAssignments.length > 0 && (
        <EuiFlexItem key='specific-deployments'>
          <PermissionsRolePanel
            title={formatMessage(messages.label.specificDeploymentsAccess)}
            description={formatMessage(messages.description.specificDeploymentsAccess)}
          >
            <SpecificDeploymentsPanel
              organizationId={organizationId}
              roleAssignments={roleAssignments}
            />
          </PermissionsRolePanel>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default injectIntl(MemberRolesSummary)
