/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Permission from '@modules/cloud-api/v1/permissions'

import { createSecurityCluster, fetchSecurityCluster } from '../../actions'
import {
  createSecurityClusterRequest,
  fetchSecurityClusterRequest,
  getSecurityCluster,
} from '../../reducers'
import requiresPermission from '../../../../lib/requiresPermission'

import IntroduceRbac from './IntroduceRbac'

const mapStateToProps = (state, { regionId }) => ({
  securityCluster: getSecurityCluster(state, regionId),
  createSecurityClusterRequest: createSecurityClusterRequest(state, regionId),
  fetchSecurityClusterRequest: fetchSecurityClusterRequest(state, regionId),
})

const mapDispatchToProps = (dispatch, { regionId }) => ({
  createSecurityCluster: () => dispatch(createSecurityCluster(regionId)),
  fetchSecurityCluster: () => dispatch(fetchSecurityCluster(regionId)),
})

const controlledComponent = requiresPermission(IntroduceRbac, Permission.createSecurityDeployment)

export default connect(mapStateToProps, mapDispatchToProps)(controlledComponent)
