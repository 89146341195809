/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFormLabel,
  EuiSkeletonText,
  EuiSpacer,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { TlsPublicCertChain, UpdatedTlsChain } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, RegionId } from '@modules/ui-types'
import {
  CuiAlert,
  CuiCodeBlock,
  CuiFileUploadButton,
  CuiButton,
  CuiButtonEmpty,
  CuiPermissibleControl,
} from '@modules/cui'
import CopyButton from '@modules/cui/CopyButton'

import { readFile } from '../../../../lib/fileHelpers'
import { hasPermission } from '../../../../lib/requiresPermission'

interface Props {
  regionId: RegionId
  target: UpdatedTlsChain
  resetUploadTlsCertificateRequest: (regionId: RegionId, target: UpdatedTlsChain) => void
  certificate?: TlsPublicCertChain
  fetchTlsCertificateRequest: AsyncRequestState
  uploadTlsCertificate: (regionId: RegionId, target: UpdatedTlsChain, certificate: string) => void
  uploadTlsCertificateRequest: AsyncRequestState
}

interface State {
  showingCertificateChain: boolean
}

class Certificate extends Component<Props, State> {
  state: State = {
    showingCertificateChain: false,
  }

  componentWillUnmount() {
    const { regionId, target, resetUploadTlsCertificateRequest } = this.props
    resetUploadTlsCertificateRequest(regionId, target)
  }

  render() {
    return (
      <div>
        <EuiFormLabel>{this.renderCertificateTargetTitle()}</EuiFormLabel>

        <EuiSpacer size='s' />

        {this.renderContent()}
      </div>
    )
  }

  renderCertificateTargetTitle() {
    const { target } = this.props
    const { service } = target

    if (service === 'ui') {
      return (
        <FormattedMessage
          id='region-settings.tls-settings.cloud-ui'
          defaultMessage='Cloud UI and API'
        />
      )
    }

    if (service === 'proxy') {
      return <FormattedMessage id='region-settings.tls-settings.proxy' defaultMessage='Proxy' />
    }

    return null
  }

  renderContent() {
    const { certificate, fetchTlsCertificateRequest, uploadTlsCertificateRequest } = this.props

    if (fetchTlsCertificateRequest.error) {
      return <CuiAlert type='error'>{fetchTlsCertificateRequest.error}</CuiAlert>
    }

    if (!certificate) {
      return <EuiSkeletonText lines={1} />
    }

    const { showingCertificateChain } = this.state
    const certificateChain = certificate.chain.join('\n')

    return (
      <Fragment>
        <div>
          <CuiPermissibleControl permissions={Permission.setTlsCertificate}>
            <CuiFileUploadButton
              disabled={uploadTlsCertificateRequest.inProgress}
              onChange={(file) => this.onFileReceived(file)}
            >
              <CuiButtonEmpty
                disabled={!hasPermission(Permission.setTlsCertificate)}
                color='primary'
                size='s'
                iconType='importAction'
                spin={uploadTlsCertificateRequest.inProgress}
              >
                <FormattedMessage
                  id='region-settings.tls-settings.upload-new-certificate'
                  defaultMessage='Upload new certificate'
                />
              </CuiButtonEmpty>
            </CuiFileUploadButton>
          </CuiPermissibleControl>

          <EuiSpacer size='xs' />

          <CuiButtonEmpty
            color='primary'
            size='s'
            iconType='eye'
            onClick={() => this.setState({ showingCertificateChain: true })}
          >
            <FormattedMessage
              id='region-settings.tls-settings.show-certificate-chain'
              defaultMessage='Show certificate chain'
            />
          </CuiButtonEmpty>
        </div>

        {uploadTlsCertificateRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{uploadTlsCertificateRequest.error}</CuiAlert>
          </Fragment>
        )}

        {!uploadTlsCertificateRequest.error && uploadTlsCertificateRequest.isDone && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='info'>
              <FormattedMessage
                id='region-settings.tls-settings.upload-successful'
                defaultMessage='Certificate uploaded successfully!'
              />
            </CuiAlert>
          </Fragment>
        )}

        {showingCertificateChain && (
          <EuiFlyout
            size='m'
            ownFocus={true}
            onClose={() => this.setState({ showingCertificateChain: false })}
          >
            <EuiFlyoutHeader>
              <EuiTitle>
                <h3>
                  <FormattedMessage
                    id='region-settings.tls-settings.certificate-chain-flyout-title'
                    defaultMessage='{certificateType} certificate chain'
                    values={{
                      certificateType: this.renderCertificateTargetTitle(),
                    }}
                  />
                </h3>
              </EuiTitle>
            </EuiFlyoutHeader>

            <EuiFlyoutBody>
              <CuiCodeBlock>{certificateChain}</CuiCodeBlock>
            </EuiFlyoutBody>

            <EuiFlyoutFooter>
              <EuiFlexGroup gutterSize='m' alignItems='center' justifyContent='spaceBetween'>
                <EuiFlexItem grow={false}>
                  <CopyButton value={certificateChain}>
                    {(copyButtonProps) => (
                      <CuiButton fill={true} {...copyButtonProps}>
                        <FormattedMessage
                          id='region-settings.tls-settings.copy-button-text'
                          defaultMessage='Copy'
                        />
                      </CuiButton>
                    )}
                  </CopyButton>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <CuiButtonEmpty onClick={() => this.setState({ showingCertificateChain: false })}>
                    <FormattedMessage
                      id='region-settings.tls-settings.certificate-chain-close-flyout'
                      defaultMessage='Close'
                    />
                  </CuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlyoutFooter>
          </EuiFlyout>
        )}
      </Fragment>
    )
  }

  onFileReceived(file) {
    const { regionId, target, uploadTlsCertificate } = this.props

    return readFile(file).then((certificate) => uploadTlsCertificate(regionId, target, certificate))
  }
}

export default Certificate
