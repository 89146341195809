/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getDeploymentResourceProxyRequestsUrl } from '@modules/cloud-api/v1/urls'
import type { StackDeployment } from '@modules/ui-types'

import { getFirstEsRefId } from '@/lib/stackDeployments/selectors/clusters'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { TAKE_SNAPSHOT } from '../../constants/actions'

import type { ThunkAction } from '@/types/redux'

export function takeSnapshot({ deployment }: { deployment: StackDeployment }): ThunkAction {
  const { id: deploymentId } = deployment
  const refId = getFirstEsRefId({ deployment })
  const snapshotName = makeSnapshotName()
  const proxyPath = `_snapshot/found-snapshots/${snapshotName}`

  const url = getDeploymentResourceProxyRequestsUrl({
    deploymentId,
    refId,
    proxyPath,
    resourceKind: 'elasticsearch',
  })

  return asyncRequest({
    type: TAKE_SNAPSHOT,
    url,
    method: 'POST',
    meta: { deploymentId, refId },
    crumbs: [deploymentId],
    requestSettings: {
      request: {
        headers: {
          'X-Management-Request': true,
        },
      },
    },
    handleUnauthorized: true,
  })
}

export function resetTakeSnapshotRequest(...crumbs) {
  return resetAsyncRequest(TAKE_SNAPSHOT, crumbs)
}

function makeSnapshotName() {
  const timestamp = (Date.now() / 1000).toFixed(0)
  return `snapshot-${timestamp}`
}
