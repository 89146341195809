/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { Organization } from '@modules/cloud-api/v1/types'
import { CuiTable, CuiLink } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import { organizationOverviewUrl } from '@/lib/urlBuilder'

import type { FunctionComponent } from 'react'

type Props = {
  organizations: Organization[]
}

const OrganizationsTable: FunctionComponent<Props> = ({ organizations }) => {
  const columns: Array<CuiTableColumn<Organization>> = [
    {
      label: (
        <FormattedMessage
          id='organization.organizations.organizations-table.name'
          defaultMessage='Name'
        />
      ),
      render: ({ id, name }) => <CuiLink to={organizationOverviewUrl(id)}>{name}</CuiLink>,
      sortKey: `name`,
    },
    {
      label: (
        <FormattedMessage
          id='organization.organizations.organizations-table.id'
          defaultMessage='ID'
        />
      ),
      render: ({ id }) => id,
      sortKey: `id`,
    },
  ]

  return <CuiTable<Organization> rows={organizations} columns={columns} />
}

export default OrganizationsTable
