/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { setLicenseUrl } from '@modules/cloud-api/v1/urls'
import type { LicenseObject } from '@modules/cloud-api/v1/types'

import { SET_LICENSE } from '../../constants/actions'
import asyncRequest from '../asyncRequests'

import { fetchLicense } from './fetchLicense'

export function setLicense(regionId: string, license: LicenseObject) {
  const url = setLicenseUrl({ regionId })

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: SET_LICENSE,
        method: `PUT`,
        url,
        payload: license,
        meta: { regionId, license },
        crumbs: [regionId],
      }),

      // We fetch the license after upload to receive the usage_stats
    ).then(dispatch(fetchLicense(regionId)))
}
