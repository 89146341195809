/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { AnyPlan, AnyTopologyElement } from '@modules/ui-types'

export function isTopologySized(topology: AnyTopologyElement[]) {
  for (const nodeConfiguration of topology) {
    const flexSize = (nodeConfiguration.size?.value ?? 0) > 0

    if (flexSize) {
      return true
    }

    // @ts-ignore AppSearchTopologyElement doesn't have this field
    const instanceCount = nodeConfiguration.node_count_per_zone ?? 0
    // @ts-ignore AppSearchTopologyElement doesn't have this field
    const instanceCapacity = nodeConfiguration.memory_per_node ?? 0
    const exactSize = instanceCount > 0 && instanceCapacity > 0

    if (exactSize) {
      return true
    }
  }

  return false
}

export function isPreDntPlan(plan: AnyPlan): boolean {
  const cluster_topology = plan.cluster_topology || []

  return cluster_topology.some((t) => t.node_configuration != null || t.allocator_filter != null)
}
