/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import type {
  DeploymentTemplateRequestBody,
  InstanceConfiguration,
} from '@modules/cloud-api/v1/types'

import { hasMasterEligibleInstance } from '../../../../lib/stackDeployments/selectors'

import type { FunctionComponent } from 'react'
import type { StepOwnProps } from './deploymentTemplateWizardTypes'

type Props = {
  pristine: boolean
  instanceConfigurations: InstanceConfiguration[]
  template: DeploymentTemplateRequestBody
}

enum ElasticsearchStepValidationErrors {
  MASTER_ELEGIBILITY = 'MASTER_ELEGIBILITY',
}

const ConfigureTemplateElasticsearchStepErrors: FunctionComponent<Props> = ({
  pristine,
  instanceConfigurations,
  template,
}) => {
  if (pristine) {
    return null
  }

  const errors = validateElasticsearchStep({
    template,
    instanceConfigurations,
  } as StepOwnProps)

  if (isEmpty(errors)) {
    return null
  }

  return (
    <Fragment>
      <EuiSpacer />

      {errors.includes(ElasticsearchStepValidationErrors.MASTER_ELEGIBILITY) && (
        <EuiCallOut
          data-test-id='no-master-eligible-instances'
          color='danger'
          title={
            <FormattedMessage
              id='configure-template-elasticsearch-step-errors.no-master-eligible-instances'
              defaultMessage='Your template must include at least one master-eligible instance configuration.'
            />
          }
        />
      )}
    </Fragment>
  )
}

export function validateElasticsearchStep({
  template,
}: StepOwnProps): ElasticsearchStepValidationErrors[] {
  const errors = [] as ElasticsearchStepValidationErrors[]

  const masterEligible = hasMasterEligibleInstance({
    deploymentTemplate: template.deployment_template,
  })

  if (!masterEligible) {
    errors.push(ElasticsearchStepValidationErrors.MASTER_ELEGIBILITY)
  }

  return errors
}

export default ConfigureTemplateElasticsearchStepErrors
