/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AccountResponse, TrustRelationshipGetResponse } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { resetFetchCurrentAccount, fetchCurrentAccount } from '../../../../actions/account'
import {
  fetchTrustRelationships,
  resetFetchTrustRelationships,
} from '../../../../actions/trustManagement'
import { localTrustRelationshipId } from '../../../../constants/trustRelationships'
import {
  fetchTrustRelationshipsRequest,
  getLocalTrustRelationships,
  getTrustRelationshipsWithoutLocal,
  getCurrentAccount,
} from '../../../../reducers'

import TrustManagement from './TrustManagement'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'

type StateProps = {
  regionId: string
  fetchTrustRelationshipsRequest: AsyncRequestState
  localTrustRelationships: TrustRelationshipGetResponse[] | null
  trustRelationshipsWithoutLocal: TrustRelationshipGetResponse[]
  currentAccount: AccountResponse | null
}

type DispatchProps = {
  fetchCurrentAccount: () => void
  fetchTrustRelationships: () => void
  resetFetchCurrentAccount: () => void
  resetFetchTrustRelationships: () => void
}

type QueryParams = {
  regionId: string
}

type ConsumerProps = RouteComponentProps<QueryParams>

export type AllProps = StateProps & DispatchProps & ConsumerProps

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): StateProps => ({
  regionId,
  localTrustRelationships: getLocalTrustRelationships(state, regionId),
  trustRelationshipsWithoutLocal: getTrustRelationshipsWithoutLocal(state, regionId),
  fetchTrustRelationshipsRequest: fetchTrustRelationshipsRequest(
    state,
    regionId,
    localTrustRelationshipId,
  ),
  currentAccount: getCurrentAccount(state),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { regionId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  fetchTrustRelationships: () =>
    dispatch(
      fetchTrustRelationships({
        regionId,
        includeCertificate: true,
      }),
    ),
  fetchCurrentAccount: () => dispatch(fetchCurrentAccount()),
  resetFetchTrustRelationships: () => dispatch(resetFetchTrustRelationships(regionId)),
  resetFetchCurrentAccount: () => dispatch(resetFetchCurrentAccount()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TrustManagement)
