/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle,
  EuiAvatar,
} from '@elastic/eui'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import { addToast, parseError } from '@modules/cui'

import RoleAssignmentsPanel from '@/components/Users/RoleAssignmentsPanel'
import { diffRoleAssignments } from '@/components/Users/RoleAssignmentsPanel/lib'
import SpinButton from '@/components/SpinButton'

import OrganizationMemberStatus from '../OrganizationMemberStatus'

import MemberRolesSummary from './MemberRolesSummary'

import type { AllProps as Props, State } from './types'

class OrganizationMemberRoleAssignments extends Component<Props, State> {
  state: State = this.createInitialState()

  componentWillUnmount(): void {
    this.props.resetUpdateOrganizationMemberRoleAssignmentsRequest()
  }

  render() {
    return (
      <EuiFlyout maxWidth='43rem' onClose={this.props.onClose} paddingSize='l'>
        <EuiFlyoutHeader hasBorder={true}>{this.renderFlyoutHeader()}</EuiFlyoutHeader>
        <EuiFlyoutBody>{this.renderFlyoutBody()}</EuiFlyoutBody>
        <EuiFlyoutFooter>{this.renderFlyoutFooter()}</EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderFlyoutHeader() {
    const { member } = this.props

    if (!member) {
      return null
    }

    return (
      <EuiFlexGroup gutterSize='s' responsive={false}>
        <EuiFlexItem>
          <EuiTitle>
            <h2>{member.name ?? member.email}</h2>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup
            direction='column'
            alignItems='flexEnd'
            gutterSize='s'
            style={{ paddingRight: '15px' }}
          >
            <EuiFlexItem>
              <strong>
                <FormattedMessage
                  id='organization.organization-member-role-assignments.user-id'
                  defaultMessage='User ID'
                />
              </strong>
            </EuiFlexItem>

            <EuiFlexItem>{member.id}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderFlyoutBody() {
    const { member, organizationId, allOrganizationDeploymentsCount } = this.props

    const { roleAssignments } = this.state

    return (
      <EuiFlexGroup direction='column' gutterSize='xl'>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiAvatar size='m' name={(member.name ?? member.email).toUpperCase()} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiFlexGroup direction='column' gutterSize='s'>
                <EuiFlexItem>
                  <strong>{member.name ?? member.email}</strong>
                </EuiFlexItem>

                {member.name !== undefined && <EuiFlexItem>{member.email}</EuiFlexItem>}

                <EuiFlexItem>
                  <OrganizationMemberStatus
                    organizationId={organizationId}
                    organizationMemberRow={member}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiTitle size='s'>
                <h3>
                  <FormattedMessage
                    id='organization.organization-member-role-assignments.roles'
                    defaultMessage='Roles'
                  />
                </h3>
              </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                size='s'
                onClick={() => {
                  this.setState((prevState) => ({ isEditing: !prevState.isEditing }))
                }}
                flush='left'
              >
                {this.state.isEditing ? (
                  <FormattedMessage
                    id='organization.organization-member-role-assignments.cancel-edit'
                    defaultMessage='Cancel edit'
                  />
                ) : (
                  <FormattedMessage
                    id='organization.organization-member-role-assignments.edit'
                    defaultMessage='Edit'
                  />
                )}
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          {this.state.isEditing ? (
            <RoleAssignmentsPanel
              organizationId={organizationId}
              roleAssignments={roleAssignments}
              onChangeRoleAssignments={this.onChangeRoleAssignments}
            />
          ) : (
            <MemberRolesSummary
              organizationId={organizationId}
              allOrganizationDeploymentsCount={allOrganizationDeploymentsCount}
              roleAssignments={roleAssignments}
            />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderFlyoutFooter() {
    return (
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty flush='left' onClick={this.props.onClose}>
            <FormattedMessage
              id='organization.organization-member-role-assignments.cancel'
              defaultMessage='Cancel'
            />
          </EuiButtonEmpty>
        </EuiFlexItem>

        {this.state.isEditing && (
          <EuiFlexItem grow={false}>
            <SpinButton
              onClick={this.onSave}
              fill={true}
              isDisabled={!this.isSaveButtonEnabled()}
              spin={this.props.updateOrganizationMemberRoleAssignmentsRequest.inProgress}
            >
              <FormattedMessage
                id='organization.organization-member-role-assignments.save'
                defaultMessage='Save'
              />
            </SpinButton>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }

  createInitialState(): State {
    return {
      isEditing: false,
      roleAssignments: this.props.member?.roleAssignments ?? {
        organization: [],
        deployment: [],
      },
    }
  }

  onChangeRoleAssignments = (roleAssignments: RoleAssignments) => {
    this.setState({ roleAssignments })
  }

  onSave = () => {
    const { onClose, updateOrganizationMemberRoleAssignments } = this.props

    const diff = diffRoleAssignments(this.getOriginalRoleAssignments(), this.state.roleAssignments)

    updateOrganizationMemberRoleAssignments(this.state.roleAssignments, diff)
      .then(() => onClose())
      .then(() =>
        addToast({
          family: 'organization.organization-member-role-assignments',
          color: 'success',
          iconType: 'check',
          title: (
            <FormattedMessage
              id='organization.organization-member-role-assignments.success'
              defaultMessage='Role changes successfully applied.'
            />
          ),
        }),
      )
      .catch((error) =>
        addToast({
          family: 'organization.organization-member-role-assignments',
          color: 'danger',
          title: (
            <FormattedMessage
              id='organization.organization-member-role-assignments.failure'
              defaultMessage="We couldn't update the roles of this member. Try again later."
            />
          ),
          text: parseError(error),
        }),
      )
  }

  isSaveButtonEnabled = () => {
    const {
      added: {
        organization: addedOrganizationRoleAssignments = [],
        deployment: addedDeploymentRoleAssignments = [],
      },
      removed: {
        organization: removedOrganizationRoleAssignments = [],
        deployment: removedDeploymentRoleAssignments = [],
      },
    } = diffRoleAssignments(this.getOriginalRoleAssignments(), this.state.roleAssignments)

    const totalRoleAssignments =
      addedOrganizationRoleAssignments.length +
      addedDeploymentRoleAssignments.length +
      removedOrganizationRoleAssignments.length +
      removedDeploymentRoleAssignments.length

    return totalRoleAssignments > 0
  }

  getOriginalRoleAssignments = (): RoleAssignments =>
    this.props.member?.roleAssignments ?? {
      organization: [],
      deployment: [],
    }
}

export default OrganizationMemberRoleAssignments
