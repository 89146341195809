/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRange } from '@elastic/eui'

import { CuiFormSection } from '@modules/cui'

import {
  MEMORY_TO_STORAGE_MULTIPLIER_MAX_GB,
  MEMORY_TO_STORAGE_MULTIPLIER_MIN_GB,
  ramToStorageMultiplier,
} from '../../../../../constants/fsMultiplier'

import type { IntlShape } from 'react-intl'

type Props = {
  ratio: number
  onChange: (ratio: number) => void
  intl: IntlShape
}

type State = {
  ratio: string
  isRatioInvalid: boolean
}

const messages = defineMessages({
  outOfRangeError: {
    id: `instance-configuration-choose-multipliers.out-of-range-error`,
    defaultMessage: `The multiplier must be a positive value no greater than {max} GB`,
  },
  rangeLabel: {
    id: `instance-configuration-choose-multipliers.memory-to-storage-label`,
    defaultMessage: `Memory-to-storage multiplier in GB`,
  },
  helpText: {
    id: `instance-configuration-choose-multipliers.memory-to-storage-description`,
    defaultMessage: `Determines how much storage space in GB gets allocated for every 1 GB of memory (RAM)`,
  },
})

class SelectMultipliersSubstep extends Component<Props, State> {
  state: State = {
    ratio: ramToStorageMultiplier.toString(),
    isRatioInvalid: false,
  } as State

  render() {
    const { ratio, isRatioInvalid } = this.state
    const {
      onChange,
      intl: { formatMessage },
    } = this.props

    const multiplierErrors: string[] = []

    if (isRatioInvalid) {
      const intlError = formatMessage(messages.outOfRangeError, {
        max: MEMORY_TO_STORAGE_MULTIPLIER_MAX_GB,
      })
      multiplierErrors.push(intlError)
    }

    return (
      <CuiFormSection help={formatMessage(messages.helpText)}>
        <EuiFormRow
          label={formatMessage(messages.rangeLabel)}
          isInvalid={multiplierErrors.length > 0}
          error={multiplierErrors}
        >
          <EuiFlexGroup alignItems='center'>
            <EuiFlexItem className='chooseMultipliersStep-range'>
              <EuiRange
                value={ratio.toString()}
                min={MEMORY_TO_STORAGE_MULTIPLIER_MIN_GB}
                max={MEMORY_TO_STORAGE_MULTIPLIER_MAX_GB}
                onChange={(e) => {
                  this.setState({
                    ratio: (e.target as HTMLInputElement).value,
                    isRatioInvalid: false,
                  })
                  return onChange(parseInt((e.target as HTMLInputElement).value, 10))
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false} className='chooseMultipliersStep-inputDisplay'>
              <EuiFieldNumber
                type='number'
                onChange={(e) => this.onChangeTextRange(e)}
                isInvalid={isRatioInvalid}
                value={parseInt(ratio, 10)}
                min={MEMORY_TO_STORAGE_MULTIPLIER_MIN_GB}
                max={MEMORY_TO_STORAGE_MULTIPLIER_MAX_GB}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      </CuiFormSection>
    )
  }

  onChangeTextRange({ target: { value } }) {
    const { onChange } = this.props
    const numericValueRegex = /^\d+$/

    if (value === ``) {
      this.setState({ ratio: `0` })
    }

    if (
      numericValueRegex.test(value) &&
      MEMORY_TO_STORAGE_MULTIPLIER_MIN_GB <= value &&
      value <= MEMORY_TO_STORAGE_MULTIPLIER_MAX_GB
    ) {
      this.setState({
        isRatioInvalid: false,
        ratio: value,
      })
      return onChange(parseInt(value, 10))
    }

    this.setState({
      isRatioInvalid: true,
    })
    return onChange(parseInt(value, 10))
  }
}

export default injectIntl(SelectMultipliersSubstep)
