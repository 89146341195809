/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  createLdapConfigurationUrl,
  deleteLdapConfigurationUrl,
  getLdapConfigurationUrl,
  updateLdapConfigurationUrl,
} from '@modules/cloud-api/v1/urls'
import type { LdapSettings } from '@modules/cloud-api/v1/types'
import type { RegionId } from '@modules/ui-types'

import {
  CREATE_LDAP_SECURITY_REALM,
  DELETE_LDAP_SECURITY_REALM,
  FETCH_LDAP_SETTINGS,
  UPDATE_LDAP_SECURITY_REALM,
} from '../constants/actions'
import asyncRequest from '../../../actions/asyncRequests'

export function fetchLdapSecurityRealm(regionId: RegionId, realmId: string) {
  const url = getLdapConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: FETCH_LDAP_SETTINGS,
    url,
    meta: { regionId, realmId },
    crumbs: [regionId, realmId],
  })
}

export function createLdapSecurityRealm(regionId: RegionId, payload: LdapSettings) {
  const url = createLdapConfigurationUrl({ regionId })

  return asyncRequest({
    type: CREATE_LDAP_SECURITY_REALM,
    method: 'POST',
    url,
    payload,
    meta: { regionId },
    crumbs: [regionId],
  })
}

export function updateLdapSecurityRealm(
  regionId: RegionId,
  realmId: string,
  payload: LdapSettings,
) {
  const url = updateLdapConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: UPDATE_LDAP_SECURITY_REALM,
    method: 'PUT',
    url,
    payload,
    meta: { regionId, realmId },
    crumbs: [regionId, realmId],
  })
}

export function deleteLdapSecurityRealm(regionId: RegionId, realmId: string) {
  const url = deleteLdapConfigurationUrl({ regionId, realmId })

  return asyncRequest({
    type: DELETE_LDAP_SECURITY_REALM,
    method: 'DELETE',
    url,
    meta: { regionId, realmId },
    crumbs: [regionId],
  })
}
