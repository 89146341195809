/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Form } from 'formik'

import { EuiAccordion, EuiCallOut, EuiHorizontalRule, EuiSpacer } from '@elastic/eui'

import { createLdapConfigurationUrl, updateLdapConfigurationUrl } from '@modules/cloud-api/v1/urls'
import Permission from '@modules/cloud-api/v1/permissions'
import type { LdapSecurityRealmRoleMappingRule } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'
import Feature from '@modules/utils/feature'

import ApiRequestExample from '../../../../../../components/ApiRequestExample'
import { messages } from '../authProviderMessages'
import { isFeatureActivated } from '../../../../../../store'
import { hasPermission } from '../../../../../../lib/requiresPermission'

import GeneralSettings from './components/GeneralSettings'
import TrustedCaCerts from './components/TrustedCaCerts'
import BindCredentials from './components/BindCredentials'
import SearchModeSettings from './components/SearchModeSettings'
import GroupSearchSettings from './components/GroupSearchSettings'
import RoleMapping from './components/RoleMapping'
import TestConfiguration from './components/TestConfiguration'
import SubmitButtons from './components/SubmitButtons'
import YamlConfiguration from './components/YamlConfiguration'

import type { FormikProps } from 'formik'
import type { FunctionComponent } from 'react'

import '../authProviders.scss'

export interface RoleMappingRule extends LdapSecurityRealmRoleMappingRule {
  index: number
}

export interface LdapProviderFormShape {
  form_mode: 'create' | 'edit'
  id: string
  order?: number
  name: string
  urls: string[]
  load_balancing: 'failover' | 'dns_failover' | 'round_robin' | 'dns_round_robin'
  trusted_ca_cert_url: string
  certificate_url_truststore_type: 'jks' | 'PKCS12'
  certificate_url_truststore_password: string
  bind_anonymously: boolean
  search_mode: 'user' | 'template'
  bind_dn: string
  bind_password: string
  user_search: {
    base_dn: string
    scope: 'sub_tree' | 'one_level' | 'base'
    filter: string
    group_attr: string
  }
  user_dn_templates: string[]
  group_search: {
    base_dn: string
    scope: 'sub_tree' | 'one_level' | 'base'
    filter: string
    user_attribute: string
  }
  advanced_settings_yaml: string
  test_credentials: {
    username: string
    password: string
  }
  role_mappings: {
    default_roles: string[]
    rules: RoleMappingRule[]
  }
}

type Props = FormikProps<LdapProviderFormShape> & {
  onCancel: () => void
  submitRequest: AsyncRequestState
  disabled: boolean
  regionId: string
  realmId?: string
}

function renderValidationCallOut(isValid, submitCount) {
  if (isValid || submitCount === 0) {
    return null
  }

  return (
    <Fragment>
      <EuiSpacer />
      <EuiCallOut
        data-test-id='ldap-provider-validation-problems'
        color='warning'
        title={<FormattedMessage {...messages.thereAreProblems} />}
      >
        <FormattedMessage {...messages.pleaseCorrectProblems} />
      </EuiCallOut>
    </Fragment>
  )
}

const LdapProviderForm: FunctionComponent<Props> = ({
  handleSubmit,
  values,
  values: { form_mode },
  setFieldValue,
  onCancel,
  submitRequest,
  isValid,
  submitCount,
  disabled,
  regionId,
  realmId = '',
}) => {
  const requiredPermission =
    form_mode === 'create' ? Permission.createLdapConfiguration : Permission.updateLdapConfiguration

  return (
    <Form className='defaultLineHeight externalAuthProviderForm'>
      <GeneralSettings values={values} />

      <EuiHorizontalRule />

      <TrustedCaCerts />

      <EuiHorizontalRule />

      <BindCredentials setFieldValue={setFieldValue} />

      <EuiHorizontalRule />

      <SearchModeSettings values={values} />

      <EuiHorizontalRule />

      <GroupSearchSettings />

      <EuiHorizontalRule />

      <RoleMapping />

      <EuiSpacer size='xl' />

      <EuiAccordion
        id='ldap-provider-form-advanced-settings-accordion'
        className='authProvider-advancedSettingsAccordion'
        buttonContent={<FormattedMessage {...messages.advancedSettings} />}
      >
        <EuiHorizontalRule />

        <YamlConfiguration setFieldValue={setFieldValue} />
      </EuiAccordion>

      {isFeatureActivated(Feature.testLdapConfiguration) && (
        <Fragment>
          <EuiHorizontalRule />
          <TestConfiguration />
        </Fragment>
      )}

      <EuiSpacer size='xl' />

      <SubmitButtons
        mode={form_mode}
        onSubmit={handleSubmit}
        inProgress={submitRequest.inProgress}
        onCancel={onCancel}
        disabled={!hasPermission(requiredPermission) || disabled}
      />

      {form_mode === 'create' ? (
        <ApiRequestExample
          method='POST'
          endpoint={createLdapConfigurationUrl({ regionId })}
          body={values}
        />
      ) : (
        <ApiRequestExample
          method='PUT'
          endpoint={updateLdapConfigurationUrl({ realmId, regionId })}
          body={values}
        />
      )}

      {renderValidationCallOut(isValid, submitCount)}

      {submitRequest.error && (
        <Fragment>
          <EuiSpacer />
          <CuiAlert type='danger'>{submitRequest.error}</CuiAlert>
        </Fragment>
      )}
    </Form>
  )
}

export default LdapProviderForm
