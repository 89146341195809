/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { lt } from 'semver'

import type { DeploymentResources, DeploymentMetadata } from '@modules/cloud-api/v1/types'

import { getConfigForKey } from '../../store'

import { isSystemOwned, getVersion } from './selectors'

export function getRecommendedVersion() {
  return getConfigForKey(`RECOMMENDED_MINIMUM_SYSTEM_DEPLOYMENT_VERSION`)
}

export function isSystemUpgradeRecommended({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
    metadata?: DeploymentMetadata
  }
}): boolean {
  const recommendedMinimumVersion = getRecommendedVersion()

  if (typeof recommendedMinimumVersion !== `string`) {
    return false
  }

  if (!isSystemOwned({ deployment })) {
    return false
  }

  const esVersion = getVersion({ deployment })

  if (!esVersion) {
    return false
  }

  return lt(esVersion, recommendedMinimumVersion)
}
