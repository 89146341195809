/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiRadio } from '@elastic/eui'

import { CuiFormSection } from '@modules/cui'

import {
  SIZING_UNIT_MEMORY,
  SIZING_UNIT_STORAGE,
} from '../../../../../lib/instanceConfigurations/multipliers'

import type { SizingUnit } from '../../../../../lib/instanceConfigurations/multipliers'
import type { FunctionComponent } from 'react'

type Props = {
  sizingUnit: SizingUnit
  setSizingUnit: (SizingUnit) => void
}

const SelectPrimaryUnitSubstep: FunctionComponent<Props> = ({ sizingUnit, setSizingUnit }) => (
  <CuiFormSection
    formLabel={false}
    help={
      <FormattedMessage
        id='instance-configuration-choose-multipliers.sizing-description'
        defaultMessage='Sets the unit of measurement for sizing the instance configuration.'
      />
    }
  >
    <EuiRadio
      id='sizing-unit-memory'
      name='sizing-unit'
      checked={sizingUnit === SIZING_UNIT_MEMORY}
      onChange={() => setSizingUnit(SIZING_UNIT_MEMORY)}
      label={
        <FormattedMessage
          id='instance-configuration-choose-multipliers.sizing-memory-label'
          defaultMessage='Memory (recommended)'
        />
      }
    />

    <EuiRadio
      id='sizing-unit-storage'
      name='sizing-unit'
      checked={sizingUnit === SIZING_UNIT_STORAGE}
      onChange={() => setSizingUnit(SIZING_UNIT_STORAGE)}
      label={
        <FormattedMessage
          id='instance-configuration-choose-multipliers.sizing-storage-label'
          defaultMessage='Storage'
        />
      }
    />
  </CuiFormSection>
)

export default SelectPrimaryUnitSubstep
