/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { isEqual, debounce } from 'lodash'
import { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import { CuiAlert } from '@modules/cui'
import type { OnFilterChangeParams } from '@modules/cui'

import { getEsQuery } from '../../../StackDeploymentSearch/DeploymentFilterContext'
import searchDeploymentsQuery from '../../../StackDeploymentSearch/searchDeploymentsQuery'
import { convertRoleAssignmentsToDeploymentRows } from '../lib'

import SpecificDeploymentsTable from './SpecificDeploymentsTable'
import SpecificDeploymentsFilterContext from './SpecificDeploymentsFilterContext'

import type { ReactElement } from 'react'
import type { AllProps as Props, State } from './types'

class SpecificDeploymentsRoles extends Component<Props, State> {
  state: State = {
    esQuery: null,
    query: '',
  }

  componentDidMount(): void {
    this.props.searchOrganizationDeployments(searchDeploymentsQuery())
  }

  componentDidUpdate(_oldProps: Props, oldState: State) {
    const { esQuery } = this.state

    if (!isEqual(esQuery, oldState.esQuery)) {
      this.props.searchDeployments(searchDeploymentsQuery({ esQuery }))
    }
  }

  componentWillUnmount(): void {
    this.props.resetDeploymentsSearchRequest()
  }

  render(): ReactElement {
    const {
      organizationId,
      organizationDeployments,
      organizationDeploymentsRequest,
      deployments,
      searchDeploymentsRequest,
      roleAssignments,
      onChangeSpecificDeploymentsRoleAssignments,
    } = this.props

    const { query } = this.state

    const organizationDeploymentRows = convertRoleAssignmentsToDeploymentRows(
      organizationDeployments,
      roleAssignments,
    )

    const deploymentRows = convertRoleAssignmentsToDeploymentRows(
      deployments,
      this.props.roleAssignments,
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <EuiText size='s'>
          <strong>
            <FormattedMessage
              id='roles-assignments.specific-deployments.inner-title'
              defaultMessage='Roles per deployment'
            />
          </strong>
        </EuiText>

        <EuiSpacer size='m' />

        <EuiText size='s' color='subdued'>
          <FormattedMessage
            id='roles-assignments.specific-deployments.description'
            defaultMessage='Give access to specific deployments, and with different roles if needed. To set a global role on all deployments instead, reset all roles to "No access" first.'
          />
        </EuiText>

        <SpecificDeploymentsFilterContext
          query={query}
          onChange={this.onSearchDeployments}
          deployments={deployments}
          isLoading={searchDeploymentsRequest.inProgress}
        />

        {organizationDeploymentsRequest.error && (
          <Fragment>
            <EuiSpacer size='l' />

            <CuiAlert type='error'>{organizationDeploymentsRequest.error}</CuiAlert>
          </Fragment>
        )}

        {searchDeploymentsRequest.error && (
          <Fragment>
            <EuiSpacer size='l' />

            <CuiAlert type='error'>{searchDeploymentsRequest.error}</CuiAlert>
          </Fragment>
        )}

        <SpecificDeploymentsTable
          organizationId={organizationId}
          isLoading={
            organizationDeploymentsRequest.inProgress || searchDeploymentsRequest.inProgress
          }
          organizationDeploymentRows={organizationDeploymentRows}
          deploymentRows={deploymentRows}
          onChangeSpecificDeploymentsRoleAssignments={onChangeSpecificDeploymentsRoleAssignments}
        />
      </Fragment>
    )
  }

  // eslint-disable-next-line react/sort-comp
  onSearchDeployments = debounce(
    ({ query, queryText }: OnFilterChangeParams<DeploymentSearchResponse>) => {
      this.setState({
        esQuery: getEsQuery(query),
        query: queryText,
      })
    },
    400,
  )
}

export default SpecificDeploymentsRoles
