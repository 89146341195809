/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiBadge, EuiSpacer } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import DangerButton from '../../DangerButton'

interface Props {
  // Required by redux wrapper
  userId: string
  email: string
  requestDeletion: () => void
  requestDeletionRequest: AsyncRequestState
  resetRequestDeletion: () => void
}

const messages = defineMessages({
  accountDeletionLabel: {
    id: `request-deletion-button.account-deletion-label`,
    defaultMessage: `Request account deletion`,
  },
  requestDeletion: {
    id: `request-deletion-button.request-deletion`,
    defaultMessage: `Request deletion`,
  },
  requestDeletionModalTitle: {
    id: `request-deletion-button.are-you-sure`,
    defaultMessage: `Request deletion?`,
  },
  requestDeletionModalBody: {
    id: `request-deletion-button.request-deletion-account`,
    defaultMessage: `You're about to submit a request to delete the account that's associated with { email }. This action cannot be undone.`,
  },
  confirmDeletion: {
    id: `request-deletion-button.request-deletion-confirm`,
    defaultMessage: `Yes, request account deletion`,
  },
  deletionRequested: {
    id: `request-deletion-button.deletion-requested`,
    defaultMessage: `Deletion successfully requested.`,
  },
})

class RequestDeletionButton extends Component<Props> {
  componentWillUnmount() {
    this.props.resetRequestDeletion()
  }

  render() {
    const { requestDeletion, requestDeletionRequest, email } = this.props

    return (
      <Fragment>
        <DangerButton
          buttonType={EuiButtonEmpty}
          buttonProps={{ flush: 'left' }}
          size='s'
          onConfirm={() => requestDeletion()}
          spin={requestDeletionRequest.inProgress}
          modal={{
            title: <FormattedMessage {...messages.requestDeletionModalTitle} />,
            body: (
              <FormattedMessage
                {...messages.requestDeletionModalBody}
                values={{
                  email: <EuiBadge color='accent'>{email}</EuiBadge>,
                }}
              />
            ),
            confirmButtonText: <FormattedMessage {...messages.confirmDeletion} />,
          }}
        >
          <FormattedMessage {...messages.accountDeletionLabel} />
        </DangerButton>

        {requestDeletionRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error' iconType='alert'>
              {requestDeletionRequest.error}
            </CuiAlert>
          </Fragment>
        )}

        {!requestDeletionRequest.error && requestDeletionRequest.isDone && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='warning' iconType='check'>
              <FormattedMessage {...messages.deletionRequested} />
            </CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default RequestDeletionButton
