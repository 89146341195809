/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type { SecurityDeployment } from '@modules/cloud-api/v1/types'
import type { AjaxRequestError, AsyncRequestState, RegionId } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import history from '@modules/utils/history'

import SpinButton from '../../../../components/SpinButton'
import { authenticationProvidersUrl } from '../../../../lib/urlBuilder'
import LocalStorageKey from '../../../../constants/localStorageKeys'

import userManagement from './user-management.svg'

const messages = defineMessages({
  title: {
    id: 'introduce-rbac.title',
    defaultMessage: 'Introducing user management',
  },
  descriptionOne: {
    id: 'introduce-rbac.description-one',
    defaultMessage:
      'We’ve made role-based access control possible! You can manage native users, configure your own external authentication provider, using SAML or LDAP, and then assign roles to users and teams.',
  },
  descriptionTwo: {
    id: 'introduce-rbac.description-two',
    defaultMessage:
      'These user management details are securely stored in a deployment dedicated to security.',
  },
  createSecurityCluster: {
    id: 'introduce-rbac.create-security-deployment',
    defaultMessage: 'Create security deployment',
  },
  notRightNow: {
    id: 'introduce-rbac.not-right-now',
    defaultMessage: 'Not right now',
  },
  openUserManagement: {
    id: 'introduce-rbac.open-user-management',
    defaultMessage: 'Open user management',
  },
  close: {
    id: 'introduce-rbac.close',
    defaultMessage: 'Later',
  },
  checkingForSecurityCluster: {
    id: 'introduce-rbac.checking-for-security-deployment',
    defaultMessage: 'Checking for security deployment ...',
  },
  beta: {
    id: `introduce-rbac.beta-feature`,
    defaultMessage: `Beta`,
  },
  betaDescription: {
    id: `introduce-rbac.beta-feature.description`,
    defaultMessage: `This feature is not yet GA. Please help us by reporting any issues!`,
  },
})

export interface Props {
  createSecurityCluster: () => Promise<any>
  createSecurityClusterRequest: AsyncRequestState
  fetchSecurityCluster: () => void
  fetchSecurityClusterRequest: AsyncRequestState
  regionId: RegionId
  securityCluster: SecurityDeployment | undefined
}

interface State {
  isDismissed: boolean
}

class IntroduceRbac extends Component<Props, State> {
  state: State = {
    isDismissed: localStorage.getItem(LocalStorageKey.introduceRbacDismissed) === 'true',
  }

  componentDidMount() {
    if (!this.state.isDismissed) {
      this.props.fetchSecurityCluster()
    }
  }

  render() {
    // The current user has previously dismissed this modal - show nothing.
    if (this.state.isDismissed) {
      return null
    }

    const { fetchSecurityClusterRequest, securityCluster } = this.props

    // If this installation already has a security cluster, then we assume
    // this is a new 2.3+ installation and don't show anything. If we're
    // still waiting to find out whether there's security cluster, also
    // show nothing.
    if (
      securityCluster ||
      fetchSecurityClusterRequest.inProgress ||
      (fetchSecurityClusterRequest.error &&
        (fetchSecurityClusterRequest.error as AjaxRequestError)?.response?.status !== 404)
    ) {
      return null
    }

    return (
      <EuiModal onClose={this.onDismiss} maxWidth='800px' data-test-id='introducing-rbac-modal'>
        <EuiModalBody>
          <EuiFlexGroup direction='column' alignItems='center'>
            <EuiFlexItem>
              <img alt='' src={userManagement} width={150} height={132} />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiTitle>
                <h3>
                  <FormattedMessage {...messages.title} />
                </h3>
              </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText textAlign='center'>
                <FormattedMessage {...messages.descriptionOne} />
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText textAlign='center'>
                <FormattedMessage {...messages.descriptionTwo} />
              </EuiText>
            </EuiFlexItem>

            {this.renderButtons()}
          </EuiFlexGroup>
        </EuiModalBody>
      </EuiModal>
    )
  }

  renderButtons = () => {
    const {
      securityCluster,
      regionId,
      createSecurityClusterRequest,
      createSecurityCluster,
      fetchSecurityClusterRequest,
    } = this.props

    if (securityCluster) {
      return (
        <Fragment>
          <EuiFlexItem>
            <EuiButton
              fill={true}
              onClick={() => {
                this.onDismiss()
                history.push(authenticationProvidersUrl(regionId))
              }}
            >
              <FormattedMessage {...messages.openUserManagement} />
            </EuiButton>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiLink data-test-id='close-rbac-intro' onClick={this.onDismiss}>
              <FormattedMessage {...messages.close} />
            </EuiLink>
          </EuiFlexItem>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <EuiFlexItem>
          <SpinButton
            data-test-id='create-security-cluster'
            // If we're still trying to fetch the current security cluster ID,
            // then we still show this button but we make it spin. When the request
            // finishes, then if there's an ID, the re-render will show the buttons
            // above instead.
            spin={fetchSecurityClusterRequest.inProgress || createSecurityClusterRequest.inProgress}
            fill={true}
            onClick={() =>
              createSecurityCluster().then(() => {
                this.onDismiss()
                history.push(authenticationProvidersUrl(regionId))
                return
              })
            }
          >
            <FormattedMessage {...messages.createSecurityCluster} />
          </SpinButton>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiLink onClick={this.onDismiss} data-test-id='close-rbac-intro'>
            <FormattedMessage {...messages.notRightNow} />
          </EuiLink>
        </EuiFlexItem>

        {createSecurityClusterRequest.error && (
          <Fragment>
            <EuiSpacer />
            <CuiAlert type='danger'>{createSecurityClusterRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  onDismiss = () => {
    localStorage.setItem(LocalStorageKey.introduceRbacDismissed, 'true')
    this.setState({ isDismissed: true })
  }
}

export default IntroduceRbac
