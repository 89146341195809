/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiPopover, EuiLink } from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

interface Props {
  buttonText: NonNullable<ReactNode>
  isOpen: boolean
  onClick: () => void
}

/**
 * Component representing a clickable in-line popover.
 * Similar to EuiPopover, but the clickable component is a EuiLink with some custom text.
 */
const CustomTextPopover: FunctionComponent<Props> = ({ buttonText, children, isOpen, onClick }) => {
  const button = <EuiLink onClick={onClick}>{buttonText}</EuiLink>

  return (
    <EuiPopover
      style={{ verticalAlign: 'baseline' }}
      anchorPosition='upCenter'
      ownFocus={true}
      button={button}
      isOpen={isOpen}
      closePopover={onClick}
    >
      {children}
    </EuiPopover>
  )
}

export default CustomTextPopover
