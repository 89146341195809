/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FieldArray } from 'formik'

import {
  EuiBadge,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,

  // @ts-ignore
  EuiFormErrorText,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from '@elastic/eui'

import { CuiTable, CuiHelpTipIcon } from '@modules/cui'

import DocLink from '../../../../../../../components/DocLink'
import UserRoleComboBox from '../../../Users/UserRoleCombobox'
import { messages, roleMappingMessages, sections } from '../../authProviderMessages'
import { translateAndSortRoles } from '../../../../../../../lib/roles'

import EditRoleMappingFlyout from './EditRoleMappingFlyout'

import type { RoleMappingRule, SamlProviderFormShape } from '../SamlProviderForm'
import type { IntlShape, WrappedComponentProps } from 'react-intl'

const getColumns = (
  formatMessage: IntlShape['formatMessage'],
  onEdit: (index: number, rule: RoleMappingRule) => void,
  onDelete: (index: number) => void,
) => [
  {
    label: formatMessage(roleMappingMessages.attributeLabel),
    render: (rule: RoleMappingRule) => rule.type,
    sortKey: `type`,
    width: `100px`,
  },
  {
    label: formatMessage(roleMappingMessages.valueLabel),
    render: (rule: RoleMappingRule) => rule.value,
    sortKey: `value`,
  },
  {
    label: formatMessage(roleMappingMessages.roleLabel),
    render: (rule: RoleMappingRule) => (
      <ul className='u-noPadding'>
        {translateAndSortRoles(formatMessage, rule.roles).map((role, index) => (
          <li key={index}>
            <EuiBadge color='hollow'>{role}</EuiBadge>
          </li>
        ))}
      </ul>
    ),
    width: '200px',
    sortKey: (rule: RoleMappingRule) => translateAndSortRoles(formatMessage, rule.roles).join(`, `),
  },
  {
    mobile: {
      label: formatMessage(roleMappingMessages.actionsLabel),
    },
    actions: true,
    render: (rule: RoleMappingRule) => (
      <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            key='edit'
            aria-label={formatMessage(roleMappingMessages.editRule)}
            data-test-id='editRoleMappingRule'
            iconType='pencil'
            onClick={() => onEdit(rule.index, rule)}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            aria-label={formatMessage(roleMappingMessages.deleteRule)}
            key='delete'
            iconType='trash'
            color='danger'
            onClick={() => onDelete(rule.index)}
            data-test-id='deleteRoleMappingRule'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    ),
    width: `80px`,
  },
]

const newRule: RoleMappingRule = { index: -1, type: 'username', value: '', roles: [] }

interface State {
  isFlyoutVisible: boolean
  ruleToEdit: RoleMappingRule | null
  ruleToEditIndex: number
}

class RoleMapping extends Component<WrappedComponentProps, State> {
  state: State = {
    isFlyoutVisible: false,
    ruleToEdit: null,
    ruleToEditIndex: -1,
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props

    const { isFlyoutVisible, ruleToEdit, ruleToEditIndex } = this.state

    return (
      <div style={{ maxWidth: 800 }}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size='xs'>
              <h3>{formatMessage(sections.roleMappings)}</h3>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiText color='subdued' size='s'>
              <FormattedMessage
                {...sections.roleMappingsDescription}
                values={{
                  learnMore: (
                    <DocLink link='samlProviderRoleMappingDocLink'>
                      {formatMessage(messages.learnMore)}
                    </DocLink>
                  ),
                }}
              />
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <UserRoleComboBox
          useFormik={true}
          label={
            <Fragment>
              {formatMessage(roleMappingMessages.defaultRolesLabel)}
              <CuiHelpTipIcon>{formatMessage(roleMappingMessages.defaultRolesHelp)}</CuiHelpTipIcon>
            </Fragment>
          }
          data-test-id='role_mappings.default_roles'
          name='role_mappings.default_roles'
        />

        <EuiSpacer size='l' />

        <FieldArray name='role_mappings.rules'>
          {({ name, push, replace, remove, form: { values, touched, errors } }) => {
            const {
              role_mappings: { rules },
            } = values as SamlProviderFormShape

            // We need to inject an index value so that we can delete rules
            const rulesWithIndex = rules.map((each, index) => ({ ...each, index }))

            const error = touched[name] && errors[name]

            const noItemsMessage = (
              <Fragment>
                <EuiSpacer size='s' />

                <strong>{formatMessage(roleMappingMessages.noRoleMappingRules)}</strong>

                <EuiSpacer size='s' />

                <EuiTextColor color='subdued'>
                  {formatMessage(roleMappingMessages.createFirstRoleMappingRule)}
                </EuiTextColor>

                <EuiSpacer size='s' />

                <EuiButton
                  onClick={this.addRule}
                  type='button'
                  data-test-id='add-role-mapping-rule'
                >
                  {formatMessage(roleMappingMessages.addRoleMappingRule)}
                </EuiButton>

                <EuiSpacer size='s' />
              </Fragment>
            )

            return (
              <Fragment>
                <CuiTable
                  emptyMessage={noItemsMessage}
                  data-test-id='role-mapping-rules-table'
                  rows={rulesWithIndex}
                  columns={getColumns(formatMessage, this.editRule, remove)}
                />

                {error && <EuiFormErrorText>{error}</EuiFormErrorText>}

                {rulesWithIndex.length > 0 && (
                  <Fragment>
                    <EuiSpacer size='xs' />
                    <EuiButtonEmpty
                      onClick={this.addRule}
                      type='button'
                      data-test-id='add-role-mapping-rule'
                    >
                      {'+ ' + formatMessage(roleMappingMessages.addRoleMappingRule)}
                    </EuiButtonEmpty>
                  </Fragment>
                )}

                {isFlyoutVisible && (
                  <EditRoleMappingFlyout
                    isNew={ruleToEdit == null}
                    rule={ruleToEdit || newRule}
                    onSave={(rule) => {
                      this.closeFlyout()

                      if (ruleToEdit == null) {
                        push(rule)
                      } else {
                        replace(ruleToEditIndex, rule)
                      }
                    }}
                    onClose={this.closeFlyout}
                  />
                )}
              </Fragment>
            )
          }}
        </FieldArray>

        <EuiSpacer size='xl' />
      </div>
    )
  }

  addRule = () => this.setState({ isFlyoutVisible: true })

  editRule = (ruleToEditIndex: number, ruleToEdit: RoleMappingRule) =>
    this.setState({ isFlyoutVisible: true, ruleToEditIndex, ruleToEdit })

  closeFlyout = () =>
    this.setState({ isFlyoutVisible: false, ruleToEdit: null, ruleToEditIndex: -1 })
}

export default injectIntl(RoleMapping)
