/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import type { OrganizationMembership } from '@modules/cloud-api/v1/types'
import type { ResourceChangeAttempt, AsyncRequestState } from '@modules/ui-types'
import { CuiTable, CuiTableLoadingCellContent, withErrorBoundary } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'
import Feature from '@modules/utils/feature'

import StackConfigurationChangeUser from '@/components/StackDeploymentConfigurationChange/StackConfigurationChangeUser'
import DetailRow from '@/components/StackDeploymentActivity/DeploymentActivityTable/DetailRow'
import {
  getPlanAttemptId,
  getPlanVersion,
  isAutoscalingGeneratedPlanAttempt,
  isAutoscalingTerminationPlanAttempt,
} from '@/lib/stackDeployments/selectors'
import { isFeatureActivated } from '@/store'

import AutoscalingBadge from '../../Autoscaling/AutoscalingBadge'
import {
  StackConfigurationChangeActions,
  StackConfigurationChangeAttribution,
  StackConfigurationChangeError,
  StackConfigurationChangeIcon,
  StackConfigurationChangeId,
  StackConfigurationChangePendingSteps,
  StackConfigurationChangeStatus,
  StackConfigurationChangeSummary,
  StackConfigurationChangeTime,
  StackConfigurationChangeWarnings,
} from '../../StackDeploymentConfigurationChange'

import type { ReactElement } from 'react'

import './deploymentActivityTable.scss'

type Props = {
  planAttempts: ResourceChangeAttempt[]
  emptyText: ReactElement
  totalCount: number
  filterByPlanAttempt?: (planAttemptId: string) => void
  organizationId: string | undefined
  organizationMembers: OrganizationMembership[]
  fetchOrganizationMemberships: ({ organizationId }: { organizationId: string }) => void
  fetchOrganizationMembersRequest: ({
    organizationId,
  }: {
    organizationId: string
  }) => AsyncRequestState
}

class DeploymentActivityTable extends Component<Props> {
  componentDidMount() {
    const { fetchOrganizationMemberships, organizationId } = this.props

    if (organizationId) {
      fetchOrganizationMemberships({ organizationId })
    }
  }

  render() {
    const {
      planAttempts,
      emptyText,
      totalCount,
      filterByPlanAttempt,
      organizationId,
      organizationMembers,
      fetchOrganizationMembersRequest,
    } = this.props

    if (planAttempts === null || planAttempts.length === 0) {
      return <EuiCallOut color='primary' title={emptyText} />
    }

    const showSimpleAttribution = isFeatureActivated(Feature.showSimpleAttribution)

    const isFetchingOrganizationMembers = organizationId
      ? fetchOrganizationMembersRequest({ organizationId }).inProgress
      : false

    const columns: Array<CuiTableColumn<ResourceChangeAttempt>> = [
      {
        mobile: {
          label: <FormattedMessage id='deployment-activity-table.status' defaultMessage='Status' />,
        },
        render: ({ resource, resourceType, planAttempt }) => (
          <StackConfigurationChangeStatus
            resource={resource}
            resourceType={resourceType}
            planAttempt={planAttempt}
          />
        ),
        sortKey: `healthy`,
        width: '40px',
      },
      {
        label: <FormattedMessage id='deployment-activity-table.change' defaultMessage='Change' />,
        render: ({ resourceType, resource, planAttempt }) => (
          <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <StackConfigurationChangeIcon
                kind={resourceType}
                version={getPlanVersion({ plan: planAttempt.plan })}
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <StackConfigurationChangeId
                kind={resourceType}
                id={getPlanAttemptId({ resource, planAttempt })}
                onClick={filterByPlanAttempt}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
        sortKey: `started`,
        width: '165px',
      },

      {
        label: <FormattedMessage id='deployment-activity-table.summary' defaultMessage='Summary' />,
        render: ({ deployment, resource, resourceType, planAttempt }, { isExpanded }) => (
          <Fragment>
            <EuiText size='xs' color='subdued'>
              <EuiFlexGroup gutterSize='s'>
                {isAutoscalingGeneratedPlanAttempt({ planAttempt }) &&
                  !isAutoscalingTerminationPlanAttempt({ planAttempt }) && (
                    <EuiFlexItem grow={false}>
                      <AutoscalingBadge />
                    </EuiFlexItem>
                  )}

                <EuiFlexItem grow={false}>
                  <StackConfigurationChangeTime
                    deployment={deployment}
                    resourceType={resourceType}
                    resource={resource}
                    planAttempt={planAttempt}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiText>
            {isAutoscalingTerminationPlanAttempt({ planAttempt }) && (
              <EuiCallOut>
                <FormattedMessage
                  id='deployment-activity-table.summary.disable-unused'
                  defaultMessage='Unused deployment configurations have been disabled automatically.'
                />
              </EuiCallOut>
            )}
            <StackConfigurationChangeSummary
              resourceType={resourceType}
              resource={resource}
              planAttempt={planAttempt}
              spacerBefore={true}
            />

            {isExpanded || (
              <Fragment>
                <StackConfigurationChangePendingSteps
                  deployment={deployment}
                  resourceType={resourceType}
                  resource={resource}
                  planAttempt={planAttempt}
                  spacerBefore={true}
                />

                <StackConfigurationChangeError
                  resourceType={resourceType}
                  resource={resource}
                  planAttempt={planAttempt}
                  spacerBefore={true}
                />
                <StackConfigurationChangeWarnings
                  resource={resource}
                  resourceType={resourceType}
                  planAttempt={planAttempt}
                  spacerBefore={true}
                />
              </Fragment>
            )}
          </Fragment>
        ),
      },

      ...(showSimpleAttribution
        ? [
            {
              label: (
                <FormattedMessage
                  id='deployment-activity-table.applied-by'
                  defaultMessage='Applied by'
                />
              ),
              render: ({ planAttempt }) =>
                isFetchingOrganizationMembers ? (
                  <CuiTableLoadingCellContent />
                ) : (
                  <StackConfigurationChangeUser
                    members={organizationMembers}
                    planAttempt={planAttempt}
                  />
                ),
              textOnly: false,
              width: '220px',
            },
          ]
        : [
            {
              label: (
                <FormattedMessage
                  id='deployment-activity-table.applied-by'
                  defaultMessage='Applied by'
                />
              ),
              render: ({ resourceType, planAttempt }) => (
                <StackConfigurationChangeAttribution
                  kind={resourceType}
                  planAttempt={planAttempt}
                />
              ),
              align: 'right' as const,
              width: '220px',
            },
          ]),

      {
        label: <FormattedMessage id='deployment-activity-table.actions' defaultMessage='Actions' />,
        render: ({ deployment, resource, resourceType, planAttempt }) => (
          <StackConfigurationChangeActions
            deployment={deployment}
            resource={resource}
            resourceType={resourceType}
            planAttempt={planAttempt}
          />
        ),
        align: 'right' as const,
        width: '280px',
        actions: true,
      },
    ]

    return (
      <CuiTable<ResourceChangeAttempt>
        rows={planAttempts}
        columns={columns}
        getRowId={(change) => `${change.resourceType}-${getPlanAttemptId(change)}`}
        getRowTestSubj={(change) => `${change.resourceType}-row`}
        hasDetailRow={true}
        renderDetailButton={CustomExpandButton}
        renderDetailRow={(change) => <DetailRow change={change} />}
        pageSize={10}
        showMatchCount={true}
        totalCount={totalCount}
        matchType={
          <FormattedMessage
            id='deployment-activity-table.match-type'
            defaultMessage='configuration change'
          />
        }
        matchTypePlural={
          <FormattedMessage
            id='deployment-activity-table.match-type-plural'
            defaultMessage='configuration changes'
          />
        }
      />
    )
  }
}

function CustomExpandButton({ isExpanded, toggleExpanded }) {
  return (
    <EuiButtonEmpty
      data-test-id='planAttemptMeta-showPlanDetails'
      size='s'
      iconSide='right'
      iconType={isExpanded ? `arrowUp` : `arrowDown`}
      onClick={toggleExpanded}
    >
      <FormattedMessage id='deployment-activity-table.show-details' defaultMessage='Details' />
    </EuiButtonEmpty>
  )
}

export default withErrorBoundary(DeploymentActivityTable)
