/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { FilterSchema } from '@modules/cui'

import type { ApiKey } from '../types'

export const schema: FilterSchema = {
  strict: true,
  fields: {
    name: {
      type: 'string',
    },
    user: {
      type: 'string',
    },
    created: {
      type: 'string',
    },
  },
}

export const defaultFields = ['name', 'user']

export function getQueryModel(apiKey: ApiKey) {
  return {
    name: apiKey.description,
    user: apiKey.user_id,
    created: apiKey.creation_date,
  }
}
