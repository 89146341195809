/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import moment from 'moment'

import type { ProfileStateImpl } from '@modules/ui-types'

import { isMarketPlaceUser } from '@/lib/marketPlace'

interface PlaybookIds {
  trialPlaybookId: number | null
  marketplacePlaybookId: number | null
  directPayingPlaybookId: number | null
  oldMarketplacePlaybookId: number | null
}

const isTrialPlaybook = (profile: ProfileStateImpl): boolean =>
  profile.inTrial && profile.trials?.length > 0

const isMarketplacePlaybook = (profile: ProfileStateImpl): boolean => {
  const marketplaceUserChatTurnOffDate = moment.utc(profile.created).add(15, 'days')
  return isMarketPlaceUser(profile) && moment.utc().isBefore(marketplaceUserChatTurnOffDate, 'days')
}

const isDirectPayingPlaybook = (profile: ProfileStateImpl): boolean => {
  const isDirectPayingUser = Boolean(
    profile.domain === 'found' && profile.is_paying && profile.conversion_timestamp,
  )
  const directPayingUserChatTurnOffDate = moment.utc(profile.conversion_timestamp).add(30, 'days')
  return isDirectPayingUser && moment.utc().isSameOrBefore(directPayingUserChatTurnOffDate)
}

const isOldMarketplacePlaybook = (profile: ProfileStateImpl): boolean => {
  const oldMarketplaceUserChatTurnOnDate = moment.utc(profile.created).add(15, 'days')
  const oldMarketplaceUserChatTurnOffDate = moment.utc(profile.created).add(31, 'days')
  return (
    isMarketPlaceUser(profile) &&
    moment
      .utc()
      .isBetween(oldMarketplaceUserChatTurnOnDate, oldMarketplaceUserChatTurnOffDate, 'days')
  )
}

type PlaybookIdByPredicate = (
  ids: PlaybookIds,
) => Map<number | null, (profile: ProfileStateImpl) => boolean>

const playbookIdByPredicate: PlaybookIdByPredicate = (playbookIds) =>
  new Map([
    [playbookIds.trialPlaybookId, isTrialPlaybook],
    [playbookIds.marketplacePlaybookId, isMarketplacePlaybook],
    [playbookIds.directPayingPlaybookId, isDirectPayingPlaybook],
    [playbookIds.oldMarketplacePlaybookId, isOldMarketplacePlaybook],
  ])

type GetPlaybookIdToOpen = (profile: ProfileStateImpl, ids: PlaybookIds) => number | null

export const getPlaybookIdToOpen: GetPlaybookIdToOpen = (profile, playbookIds) => {
  for (const [playbookId, predicate] of playbookIdByPredicate(playbookIds)) {
    if (predicate(profile)) {
      return playbookId
    }
  }

  return null
}
