/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'

import { EuiDescribedFormGroup, EuiSkeletonText } from '@elastic/eui'

import Header from '@modules/cui/Header'
import type { ProjectID } from '@modules/project-api/v1/types'
import { getGetProjectQueryHook } from '@modules/project-lib/hooks/get'
import { projectPerformanceCrumbs } from '@modules/project-lib/breadcrumbs'
import { CuiAlert } from '@modules/cui'
import type { ProjectType } from '@modules/ui-types/projects'

import SearchPower from './SearchPower'

const ProjectPerformancePage: React.FunctionComponent = () => {
  const { projectType, projectId } = useParams<{ projectType: ProjectType; projectId: ProjectID }>()
  const query = getGetProjectQueryHook(projectType)(projectId)

  return (
    <div>
      <Header
        name={
          <FormattedMessage id='project.performance.header.name' defaultMessage='Performance' />
        }
        breadcrumbs={projectPerformanceCrumbs({
          projectType,
          projectId,
          projectName: query.data?.name,
        })}
      />
      {renderContent()}
    </div>
  )

  function renderContent() {
    if (query.error) {
      return <CuiAlert type='danger'>{query.error}</CuiAlert>
    }

    if (query.isLoading) {
      return <EuiSkeletonText />
    }

    if (!query.isSuccess) {
      return null // discriminate
    }

    const { data } = query

    return (
      <div>
        {data.type === 'elasticsearch' && (
          <EuiDescribedFormGroup
            data-test-subj='searchPower'
            ratio='quarter'
            fullWidth={true}
            titleSize='xxs'
            title={
              <strong>
                <FormattedMessage id='project.label.searchPower' defaultMessage='Search Power' />
              </strong>
            }
          >
            <div>
              <SearchPower project={data} />
            </div>
          </EuiDescribedFormGroup>
        )}
      </div>
    )
  }
}

export default ProjectPerformancePage
