/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { difference, differenceWith, isEqual } from 'lodash'

import type {
  RoleAssignments,
  DeploymentRoleAssignment,
  DeploymentSearchResponse,
  OrganizationRoleAssignment,
} from '@modules/cloud-api/v1/types'

import { OrganizationRole, DeploymentRole } from '../types'

import type { DeploymentRow } from '../SpecificDeploymentsRoles/types'

export const convertDeploymentRowsToRoleAssignments = (
  organizationId: string,
  deploymentsRows: DeploymentRow[],
): DeploymentRoleAssignment[] => {
  const deploymentsRowsGroupedByRole: { [roleId: string]: DeploymentRow[] } =
    deploymentsRows.reduce(
      (prev, cur) => ({
        ...prev,
        [cur.roleId]: (prev[cur.roleId] ?? []).concat(cur),
      }),
      {},
    )

  return Object.entries(deploymentsRowsGroupedByRole)
    .filter(([roleId]) => roleId !== DeploymentRole.NO_ACCESS)
    .map(([roleId, deploymentRows]) => ({
      organization_id: organizationId,
      role_id: roleId,
      all: false,
      deployment_ids: deploymentRows.map(({ id }) => id),
    }))
}

export const convertRoleAssignmentsToDeploymentRows = (
  deployments: DeploymentSearchResponse[],
  roleAssignments: RoleAssignments,
): DeploymentRow[] =>
  deployments.map((deployment) => {
    const specificRoleAssignments = filterDeploymentRoleAssignmentByAll(
      roleAssignments.deployment,
      false,
    )

    const roleAssignment = specificRoleAssignments.find(
      (specificRoleAssignment) =>
        specificRoleAssignment.deployment_ids?.includes(deployment.id) ?? false,
    )

    const roleId =
      roleAssignment !== undefined
        ? (roleAssignment.role_id as DeploymentRole)
        : DeploymentRole.NO_ACCESS

    return {
      ...deployment,
      roleId,
    }
  })

export const changeDeploymentRowRole = (
  deploymentRows: DeploymentRow[],
  deploymentId: string,
  roleId: DeploymentRole,
): DeploymentRow[] =>
  deploymentRows.map((deploymentsRow) => {
    if (deploymentsRow.id === deploymentId) {
      return { ...deploymentsRow, roleId }
    }

    return deploymentsRow
  })

export const deleteDeploymentsRoleAssignments = (
  roleAssignments: RoleAssignments,
): RoleAssignments => ({
  ...roleAssignments,
  deployment: [],
})

export const toggleOrganizationBillingRoleAssignment = (
  roleAssignments: RoleAssignments,
  organizationId: string,
): RoleAssignments => {
  // shouldReplace must be true when ORGANIZATION_BILLING doesn't exist
  const shouldReplace =
    roleAssignments.organization?.every(
      ({ role_id }) => role_id !== OrganizationRole.ORGANIZATION_BILLING,
    ) ?? true

  // merge with existing roleAssignments since ORGANIZATION_BILLING doesn't
  // interfer with deployment roles assignments
  return {
    ...roleAssignments,
    organization: shouldReplace
      ? [
          {
            organization_id: organizationId,
            role_id: OrganizationRole.ORGANIZATION_BILLING,
          },
        ]
      : [],
  }
}

export const toggleOrganizationOwnerRoleAssignment = (
  roleAssignments: RoleAssignments,
  organizationId: string,
): RoleAssignments => {
  // shouldReplace must be true when ORGANIZATION_OWNER doesn't exist
  const shouldReplace =
    roleAssignments.organization?.every(
      ({ role_id }) => role_id !== OrganizationRole.ORGANIZATION_OWNER,
    ) ?? true

  // do not merge with existing roleAssignments since organization owner
  // replaces everything else, including deployment roles assignments
  return {
    organization: shouldReplace
      ? [
          {
            organization_id: organizationId,
            role_id: OrganizationRole.ORGANIZATION_OWNER,
          },
        ]
      : [],
    deployment: [],
  }
}

export const onChangeAllDeploymentsRoleAssignment = (
  roleAssignments: RoleAssignments,
  organizationId: string,
  roleId: DeploymentRole,
): RoleAssignments => ({
  ...roleAssignments,
  deployment: [
    {
      organization_id: organizationId,
      role_id: roleId,
      all: true,
    },
  ],
})

export const onChangeSpecificDeploymentsRoleAssignments = (
  roleAssignments: RoleAssignments,
  specificDeploymentRoleAssignments: DeploymentRoleAssignment[],
): RoleAssignments => ({
  ...roleAssignments,
  deployment: specificDeploymentRoleAssignments,
})

export const filterDeploymentRoleAssignmentByAll = (
  deploymentRoleAssignments: DeploymentRoleAssignment[] | undefined,
  all: boolean,
): DeploymentRoleAssignment[] =>
  deploymentRoleAssignments
    ?.filter(
      (deploymentRoleAssignment) =>
        (deploymentRoleAssignment.all ?? false) === all &&
        Object.values(DeploymentRole).includes(deploymentRoleAssignment.role_id as DeploymentRole),
    )
    .sort(sortDeploymentRoleAssignments) || []

export const isOrganizationRoleSelected = (
  roleAssignments: RoleAssignments,
  role: OrganizationRole,
): boolean =>
  roleAssignments.organization?.some((organization) => organization.role_id === role) ?? false

export const getAllDeploymentsRoleAssignment = (
  roleAssignments: RoleAssignments,
): DeploymentRole | null => {
  const allDeploymentRoleAssignments = filterDeploymentRoleAssignmentByAll(
    roleAssignments.deployment,
    true,
  )

  return (allDeploymentRoleAssignments[0]?.role_id as DeploymentRole) || null
}

const diffOrganizationRoleAssignments = (
  original: OrganizationRoleAssignment[] = [],
  changed: OrganizationRoleAssignment[] = [],
): {
  added: OrganizationRoleAssignment[]
  removed: OrganizationRoleAssignment[]
} => ({
  added: differenceWith(changed, original, isEqual),
  removed: differenceWith(original, changed, isEqual),
})

const diffAllDeploymentsRoleAssignments = (
  original: DeploymentRoleAssignment[] = [],
  changed: DeploymentRoleAssignment[] = [],
): {
  added: OrganizationRoleAssignment[]
  removed: OrganizationRoleAssignment[]
} => {
  const originalAllDeploymentsRoleAssignments: DeploymentRoleAssignment[] =
    filterDeploymentRoleAssignmentByAll(original, true)

  const changedAllDeploymentsRoleAssignments: DeploymentRoleAssignment[] =
    filterDeploymentRoleAssignmentByAll(changed, true)

  return {
    added: differenceWith(
      changedAllDeploymentsRoleAssignments,
      originalAllDeploymentsRoleAssignments,
      isEqual,
    ),
    removed: differenceWith(
      originalAllDeploymentsRoleAssignments,
      changedAllDeploymentsRoleAssignments,
      isEqual,
    ),
  }
}

const diffSpecificDeploymentsRoleAssignments = (
  original: DeploymentRoleAssignment[] = [],
  changed: DeploymentRoleAssignment[] = [],
): {
  added: DeploymentRoleAssignment[]
  removed: DeploymentRoleAssignment[]
} => {
  const originalSpecificDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
    original,
    false,
  )

  const originalDeploymentIdsByRoleId = getDeploymentIdsByRoleId(
    originalSpecificDeploymentsRoleAssignments,
  )

  const changedSpecificDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
    changed,
    false,
  )

  const changedDeploymentIdsByRoleId = getDeploymentIdsByRoleId(
    changedSpecificDeploymentsRoleAssignments,
  )

  const added = diffDeploymentIds(
    changedSpecificDeploymentsRoleAssignments,
    originalDeploymentIdsByRoleId,
  ).filter(({ deployment_ids = [] }) => deployment_ids.length > 0)

  const removed = diffDeploymentIds(
    originalSpecificDeploymentsRoleAssignments,
    changedDeploymentIdsByRoleId,
  ).filter(({ deployment_ids = [] }) => deployment_ids.length > 0)

  return {
    added,
    removed,
  }

  function getDeploymentIdsByRoleId(roleAssignments: DeploymentRoleAssignment[]): {
    [roleId: string]: string[]
  } {
    return roleAssignments.reduce(
      (prev, { role_id: roleId, deployment_ids: deploymentIds = [] }) => ({
        ...prev,
        [roleId]: (prev[roleId] ?? []).concat(deploymentIds),
      }),
      {},
    )
  }

  function diffDeploymentIds(
    roleAssignments: DeploymentRoleAssignment[],
    deploymentIdsByRoleId: { [roleId: string]: string[] },
  ): DeploymentRoleAssignment[] {
    return roleAssignments.map((roleAssignment) => ({
      ...roleAssignment,
      deployment_ids: difference(
        roleAssignment.deployment_ids,
        deploymentIdsByRoleId[roleAssignment.role_id],
      ),
    }))
  }
}

export type RoleAssignmentsDiff = {
  added: RoleAssignments
  removed: RoleAssignments
}

export const diffRoleAssignments = (
  original: RoleAssignments,
  changed: RoleAssignments,
): RoleAssignmentsDiff => {
  const organizationRoleAssignmentsDiff = diffOrganizationRoleAssignments(
    original.organization,
    changed.organization,
  )

  const allDeploymentsRoleAssignmentsDiff = diffAllDeploymentsRoleAssignments(
    original.deployment,
    changed.deployment,
  )

  const specificDeploymentsRoleAssignmentsDiff = diffSpecificDeploymentsRoleAssignments(
    original.deployment,
    changed.deployment,
  )

  return {
    added: {
      organization: organizationRoleAssignmentsDiff.added,
      deployment: allDeploymentsRoleAssignmentsDiff.added.concat(
        specificDeploymentsRoleAssignmentsDiff.added,
      ),
    },
    removed: {
      organization: organizationRoleAssignmentsDiff.removed,
      deployment: allDeploymentsRoleAssignmentsDiff.removed.concat(
        specificDeploymentsRoleAssignmentsDiff.removed,
      ),
    },
  }
}

const deploymentRolesOrder = {
  [DeploymentRole.DEPLOYMENT_ADMIN]: 1,
  [DeploymentRole.DEPLOYMENT_EDITOR]: 2,
  [DeploymentRole.DEPLOYMENT_VIEWER]: 3,
}

const sortDeploymentRoleAssignments = (
  deploymentRoleAssignment1: DeploymentRoleAssignment,
  deploymentRoleAssignment2: DeploymentRoleAssignment,
): number =>
  deploymentRolesOrder[deploymentRoleAssignment1.role_id] -
  deploymentRolesOrder[deploymentRoleAssignment2.role_id]
