/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import type { SnapshotRepositoryConfig } from '@modules/ui-types'
import { CuiCodeEditor } from '@modules/cui'

import stringify from '../../../lib/stringify'
import FormGroup from '../../FormGroup'

import type { OnChangeEventPropType } from './RepositoryEditor'

type Props = {
  config: SnapshotRepositoryConfig
  customEditorState: string
  onChange: (onChangeEventProp: OnChangeEventPropType) => void
}

function tryParse(text: string): SnapshotRepositoryConfig | null {
  try {
    return JSON.parse(text)
  } catch (e) {
    return null
  }
}

class AdvancedEditor extends Component<Props> {
  render() {
    const { config, customEditorState } = this.props

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <FormGroup
          label={
            <FormattedMessage
              id='snapshot-repository-management.configuration'
              defaultMessage='Configuration'
            />
          }
        >
          <CuiCodeEditor
            language='json'
            value={customEditorState || stringify(config)}
            onChange={(nextConfigRaw) => this.onChange(nextConfigRaw)}
          />
        </FormGroup>
      </Fragment>
    )
  }

  onChange(nextConfigRaw: string) {
    const { config, onChange } = this.props
    const nextConfig = tryParse(nextConfigRaw)
    const customEditorState = nextConfigRaw

    if (!nextConfig) {
      onChange({ customEditorState, config })
      return
    }

    onChange({ customEditorState, config: nextConfig })
  }
}

export default AdvancedEditor
