/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { Formik } from 'formik'

import { EuiForm } from '@elastic/eui'

import type { SamlSettings } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, RegionId } from '@modules/ui-types'
import history from '@modules/utils/history'
import Header from '@modules/cui/Header'

import SamlProviderForm from '../SamlProviderForm'
import validateSamlForm from '../validateSamlForm'
import { messages } from '../../authProviderMessages'
import { authenticationProvidersUrl } from '../../../../../../../lib/urlBuilder'
import { addSamlAuthProviderCrumbs } from '../../../../../../../lib/crumbBuilder'
import { formToSettings, getInitialFormValues } from '../requestMapping'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  onSubmit: (payload: SamlSettings) => Promise<any>
  submitRequest: AsyncRequestState
  regionId: RegionId
  isPlanChangePending?: boolean
  isSecurityDeploymentDisabled?: boolean
}

const AddSamlProvider: FunctionComponent<Props> = ({
  intl,
  intl: { formatMessage },
  regionId,
  onSubmit,
  submitRequest,
  isPlanChangePending = false,
  isSecurityDeploymentDisabled = false,
}) => (
  <Fragment>
    <Header
      name={formatMessage(messages.addSamlProvider)}
      breadcrumbs={addSamlAuthProviderCrumbs({ regionId })}
    />

    <EuiForm>
      <Formik
        initialValues={getInitialFormValues()}
        validate={validateSamlForm(intl)}
        onSubmit={(values) =>
          onSubmit(formToSettings(values)).then(() =>
            history.push(authenticationProvidersUrl(regionId)),
          )
        }
      >
        {(props) => (
          <SamlProviderForm
            {...props}
            submitRequest={submitRequest}
            onCancel={() => history.push(authenticationProvidersUrl(regionId))}
            disabled={isPlanChangePending || isSecurityDeploymentDisabled}
            regionId={regionId}
          />
        )}
      </Formik>
    </EuiForm>
  </Fragment>
)

export default injectIntl(AddSamlProvider)
