/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCodeBlock,
  EuiDescribedFormGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { TrustRelationshipGetResponse } from '@modules/cloud-api/v1/types'
import { CuiAlert, CuiTable, CuiTimeAgo } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import Header from '@modules/cui/Header'

import SetDefaultTrust from '../../../../components/TrustManagement/SetDefaultTrust'
import CreateTrustRelationshipFlyout from '../../../../components/TrustManagement/TrustRelationshipFlyout/create'
import TrustRelationshipTable from '../../../../components/TrustManagement/TrustRelationshipTable'
import DocLink from '../../../../components/DocLink'
import { platformTrustManagementCrumbs } from '../../../../lib/crumbBuilder'

import DownloadRootCertificate from './DownloadRootCertificate'

import type { AllProps } from './'

class TrustManagement extends Component<AllProps> {
  componentDidMount(): void {
    const { fetchTrustRelationships, fetchCurrentAccount } = this.props

    fetchCurrentAccount()
    fetchTrustRelationships()
  }

  componentWillUnmount(): void {
    const { resetFetchTrustRelationships, resetFetchCurrentAccount } = this.props

    resetFetchCurrentAccount()
    resetFetchTrustRelationships()
  }

  render(): JSX.Element {
    const { regionId } = this.props

    return (
      <Fragment>
        <Header
          name={<FormattedMessage id='trust-management.title' defaultMessage='Trust Management' />}
          breadcrumbs={platformTrustManagementCrumbs({ regionId })}
        />

        <EuiText>
          <FormattedMessage
            id='trust-management.description'
            defaultMessage='Establish trust within your current environment and with other Elastic Cloud Enterprise (ECE) environments to configure remote cross-cluster search and cross-cluster replication. {docLink}'
            values={{
              docLink: (
                <DocLink link='trustManagement'>
                  <FormattedMessage id='description.link' defaultMessage='Learn more' />
                </DocLink>
              ),
            }}
          />
        </EuiText>

        <EuiSpacer size='l' />

        {this.renderContent()}
      </Fragment>
    )
  }

  renderContent(): JSX.Element {
    const { fetchTrustRelationshipsRequest } = this.props

    if (fetchTrustRelationshipsRequest.error) {
      return (
        <CuiAlert type='error' data-test-id='fetch-trust-relationship-request-error'>
          {fetchTrustRelationshipsRequest.error}
        </CuiAlert>
      )
    }

    return (
      <Fragment>
        {this.renderDefaultTrustSection()}
        {this.renderTrustedEnvironmentsSection()}
        {this.renderTrustParameters()}
      </Fragment>
    )
  }

  renderDefaultTrustSection(): JSX.Element {
    return (
      <EuiDescribedFormGroup
        fullWidth={true}
        title={
          <h2>
            <FormattedMessage
              id='trust-management.default-trust-heading'
              defaultMessage='Default trust within your environment'
            />
          </h2>
        }
        description={
          <FormattedMessage
            id='trust-management.default-trust-description'
            defaultMessage='Choose the default trust level that a new deployment establishes with other deployments within this ECE installation'
          />
        }
      >
        <EuiFlexGroup>
          <EuiFlexItem grow={true}>
            <SetDefaultTrust />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiDescribedFormGroup>
    )
  }

  renderTrustedEnvironmentsSection(): JSX.Element {
    const { regionId, trustRelationshipsWithoutLocal } = this.props

    return (
      <EuiDescribedFormGroup
        fullWidth={true}
        title={
          <h2>
            <FormattedMessage
              id='trust-management.trusted-environments-heading'
              defaultMessage='Trusted environments'
            />
          </h2>
        }
        description={
          <FormattedMessage
            id='trust-management.trusted-environments-description'
            defaultMessage='Establish trust with other ECE environments and their deployments. Trust is confirmed only when the added environment establishes trust with you'
          />
        }
      >
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <CreateTrustRelationshipFlyout regionId={regionId} />
          </EuiFlexItem>
        </EuiFlexGroup>

        {trustRelationshipsWithoutLocal.length > 0 && (
          <Fragment>
            <EuiSpacer />

            <EuiFlexGroup>
              <EuiFlexItem grow={true}>
                <TrustRelationshipTable
                  trustRelationships={trustRelationshipsWithoutLocal}
                  regionId={regionId}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </Fragment>
        )}
      </EuiDescribedFormGroup>
    )
  }

  renderTrustParameters(): JSX.Element {
    const { localTrustRelationships, currentAccount } = this.props

    const columns: Array<CuiTableColumn<TrustRelationshipGetResponse>> = [
      {
        label: (
          <FormattedMessage
            id='trust-management.trust-parameters-table-name'
            defaultMessage='Environment name'
          />
        ),
        render: ({ name }) => name,
        width: '20%',
      },
      {
        label: (
          <FormattedMessage
            id='trust-management.trust-parameters-table-environment-id'
            defaultMessage='Environment ID'
          />
        ),
        render: () => (
          <PrivacySensitiveContainer>
            <EuiCodeBlock
              isCopyable={true}
              paddingSize='m'
              data-test-id='trust-management-environment-id'
            >
              {currentAccount?.id}
            </EuiCodeBlock>
          </PrivacySensitiveContainer>
        ),
        width: '40%',
      },
      {
        label: (
          <FormattedMessage
            id='trust-management.trust-parameters-table-last-modified'
            defaultMessage='Last modified'
          />
        ),
        render: ({ last_modified }) => <CuiTimeAgo date={last_modified} />,
        width: '20%',
      },
      {
        label: (
          <FormattedMessage
            id='trust-management.trust-parameters-table-download-button'
            defaultMessage='CA Certificate'
          />
        ),
        render: (trustRelationship) => (
          <DownloadRootCertificate localTrustRelationship={trustRelationship} />
        ),
        width: '130px',
        align: `right`,
      },
    ]

    return (
      <EuiDescribedFormGroup
        fullWidth={true}
        title={
          <h4>
            <FormattedMessage
              id='trust-management.params-heading'
              defaultMessage='Trust parameters'
            />
          </h4>
        }
        description={
          <FormattedMessage
            id='trust-management.params-description'
            defaultMessage='Use these parameters to establish trust with other ECE environments'
          />
        }
      >
        {localTrustRelationships ? (
          <CuiTable<TrustRelationshipGetResponse>
            rows={localTrustRelationships}
            getRowId={(trustRelationship) => trustRelationship.id}
            columns={columns}
          />
        ) : (
          <EuiSkeletonText lines={1} data-test-id='trust-management-loading' />
        )}
      </EuiDescribedFormGroup>
    )
  }
}

export default TrustManagement
