/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { BillingSubscriptionLevel } from '@modules/ui-types'

export type BillingActivity = Record<BillingSubscriptionLevel, number>

// Billing Details

// TODO update/add enums when API will have final form

export enum BillingModel {
  PrepaidConsumption = 'prepaid_consumption',
  OnDemand = 'on_demand',
  AnnualOverages = 'annual_overages',
  AnnualOveragesInstallments = 'annual_overages_installments',
  Drawdown = 'drawdown',
  CommittedConsumption = 'committed_consumption',
}

export enum PaymentMethod {
  CreditCard = 'credit_card',
  PurchaseOrder = 'purchase_order',
  ThirdPartyBilling = 'third_party_billing',
}

export enum PartnerSelling {
  Direct = 'direct',
  Reseller = 'reseller',
}

export enum BillingStatus {
  BalanceDue = 'balance_due',
  GoodStanding = 'good_standing',
  PendingSuspension = 'pending_suspension',
  Suspended = 'suspended',
  Terminated = 'terminated',
}

export interface DeploymentCostsAggregation {
  name: string
  id: string
  capacity: number
  dataTransfer: number
  storage: number
  total: number
}
