/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ClusterSnapshotStatus } from '@modules/ui-types'

import { FETCH_SNAPSHOT_STATUS } from '../../constants/actions'

import type { AsyncAction } from '@/types/redux'

export type State = { [descriptor: string]: ClusterSnapshotStatus }

export interface ClusterSnapshotStatusAction
  extends AsyncAction<typeof FETCH_SNAPSHOT_STATUS, { snapshots: ClusterSnapshotStatus[] }> {
  meta: { deploymentId: string; snapshotName: string }
}

const initialState = {}

function createDescriptor(deploymentId: string, snapshotName: string) {
  return `${deploymentId}/${snapshotName}`
}

export default function snapshotStatusReducer(
  state: State = initialState,
  action: ClusterSnapshotStatusAction,
): State {
  switch (action.type) {
    case FETCH_SNAPSHOT_STATUS: {
      if (!action.error && action.payload != null) {
        const { deploymentId, snapshotName } = action.meta
        const descriptor = createDescriptor(deploymentId, snapshotName)
        return {
          [descriptor]: action.payload.snapshots[0],
        }
      }

      break
    }

    default:
      break
  }

  return state
}

export function getSnapshotStatus(
  state: State,
  deploymentId: string,
  snapshotName: string,
): ClusterSnapshotStatus {
  return state[createDescriptor(deploymentId, snapshotName)]
}
