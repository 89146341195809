/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiIcon,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiSwitch,
  EuiText,
} from '@elastic/eui'

import type { SaasOrganizationResponse } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert, CuiTable } from '@modules/cui'

import type { ReactNode } from 'react'

export interface Props {
  saasOrganization: SaasOrganizationResponse
  updateOrganizationRequest: AsyncRequestState
  setPluginsAndBundlesEnabled: (bundles: boolean, plugins: boolean) => void
  resetUpdateOrganization: () => void
}

interface FeatureRow {
  id: string
  enabled: boolean
  name: ReactNode
  description?: ReactNode
  actions: ReactNode
}

const messages = defineMessages({
  enabled: {
    id: `organization.organization-overview.organization-features.enabled`,
    defaultMessage: `Enabled`,
  },
  enableFeature: {
    id: `organization.organization-overview.organization-features.enable-feature`,
    defaultMessage: `Enable feature {featureName}`,
  },
  disableFeature: {
    id: `organization.organization-overview.organization-features.disable-feature`,
    defaultMessage: `Disable feature {featureName}`,
  },
  bundleLabel: {
    id: 'organization.organization-overview.organization-features.bundle-extensions-label',
    defaultMessage: 'Bundle extensions',
  },
  pluginLabel: {
    id: 'organization.organization-overview.organization-features.plugin-extensions-label',
    defaultMessage: 'Plugin extensions',
  },
})

class OrganizationFeatures extends Component<Props> {
  componentWillUnmount() {
    this.props.resetUpdateOrganization()
  }

  render() {
    const { updateOrganizationRequest } = this.props
    const columns = this.getHeadings()
    const rows = this.getFeatureRows()

    return (
      <Fragment>
        <CuiTable<FeatureRow>
          rows={rows}
          columns={columns}
          getRowId={(feature) => feature.id}
          pageSize={10}
        />

        {updateOrganizationRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{updateOrganizationRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  getHeadings() {
    return [
      {
        label: (
          <FormattedMessage
            id='organization.organization-overview.organization-features.feature-label'
            defaultMessage='Feature'
          />
        ),
        render: (feature) => (
          <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiIcon type='dot' color={feature.enabled ? `success` : `subdued`} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <div>
                <EuiText size='s'>{feature.name}</EuiText>

                {feature.description && <EuiFormHelpText>{feature.description}</EuiFormHelpText>}
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
        sortKey: `id`,
      },

      {
        label: (
          <FormattedMessage
            id='organization.organization-overview.organization-features.actions-label'
            defaultMessage='Enabled'
          />
        ),
        render: (feature) => feature.actions,
        actions: true,
        width: `70px`,
      },
    ]
  }

  getFeatureRows(): FeatureRow[] {
    const {
      saasOrganization: {
        rules: { allow_bundles: allowBundles, allow_plugins: allowPlugins },
      },
      setPluginsAndBundlesEnabled,
      updateOrganizationRequest,
    } = this.props

    return ['plugin', 'bundle'].map((extensionType) => ({
      id: `__extension-${extensionType}`,
      enabled: extensionType === 'plugin' ? allowPlugins : allowBundles,
      name: <FormattedMessage {...messages[`${extensionType}Label`]} />,
      actions: (
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiSwitch
              checked={extensionType === 'plugin' ? allowPlugins : allowBundles}
              label={''}
              showLabel={false}
              onChange={(event) =>
                extensionType === 'plugin'
                  ? setPluginsAndBundlesEnabled(allowBundles, event.target.checked)
                  : setPluginsAndBundlesEnabled(event.target.checked, allowPlugins)
              }
              disabled={updateOrganizationRequest.inProgress}
            />
          </EuiFlexItem>

          {updateOrganizationRequest.inProgress && (
            <EuiFlexItem grow={false}>
              <EuiLoadingSpinner size='m' />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      ),
    }))
  }
}

export default OrganizationFeatures
