/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState } from '@modules/ui-types'

import { resendEmailVerificationLinkRequest } from '@/apps/userconsole/reducers'

import { resendEmailVerificationLink } from '../../../actions/emailVerification'

import ResendVerificationEmail from './ResendVerificationEmail'

import type { ThunkDispatch, ReduxState } from '@/types/redux'

type StateProps = {
  resendEmailVerificationLinkRequest: AsyncRequestState
}

type DispatchProps = {
  resendEmailVerificationLink: () => void
}

type ConsumerProps = {
  email: string
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  resendEmailVerificationLinkRequest: resendEmailVerificationLinkRequest(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch, { email }: ConsumerProps): DispatchProps => ({
  resendEmailVerificationLink: () => dispatch(resendEmailVerificationLink({ email })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ResendVerificationEmail)
