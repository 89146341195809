/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { definitions } from '@modules/cloud-api/v1/types'

import { hasHealthyShards, shardsStatus } from '@/lib/stackDeployments/selectors'

import type { Problem } from '../../problems'

/**
 * Reports on issues that are currently being reported by the Deployment Health API.
 * This reporter handles shard health issues.
 */
export function getShardProblems(resource: definitions['ElasticsearchResourceInfo']): Problem[] {
  const problems: Problem[] = []

  if (!hasHealthyShards({ resource })) {
    if (shardsStatus({ resource }) === 'red') {
      problems.push({
        'data-test-id': `es-shards-unhealthy`,
        kind: `elasticsearch`,
        id: `es-shards-unhealthy`,
        level: `danger`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-shards-unhealthy'
            defaultMessage='Some Elasticsearch shards are unhealthy'
          />
        ),
      })
    }
  }

  return problems
}
