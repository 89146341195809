/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState, RegionId, WorkingSnapshotRepository } from '@modules/ui-types'

import { upsertSnapshotRepository } from '../../../actions/snapshotRepositories'
import { upsertSnapshotRepositoryRequest } from '../../../reducers'

import RepositoryEditor from './RepositoryEditor'

import type { ReduxState } from '@/types/redux'
import type { Errors } from './RepositoryEditor'

interface StateProps {
  upsertSnapshotRepositoryRequest: AsyncRequestState
}

interface DispatchProps {
  upsertSnapshotRepository: typeof upsertSnapshotRepository
}

interface RequiredProps {
  regionId: RegionId
  repository: WorkingSnapshotRepository
  errors: Errors
  editMode: boolean
}

const mapStateToProps: (state: ReduxState) => StateProps = (state) => ({
  upsertSnapshotRepositoryRequest: upsertSnapshotRepositoryRequest(state),
})

export default connect<StateProps, DispatchProps, RequiredProps>(mapStateToProps, {
  upsertSnapshotRepository,
})(RepositoryEditor)
