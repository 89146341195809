/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import Permission from '@modules/cloud-api/v1/permissions'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiButtonEmpty, CuiPermissibleControl } from '@modules/cui'

import type { Constructor } from '@/types/redux'
import type { FunctionComponent } from 'react'

type Props = {
  isElasticCloudEnterprise: boolean
  ctor: Constructor
  startMaintenanceMode: () => void
  startMaintenanceModeRequest: AsyncRequestState
  stopMaintenanceMode: () => void
  stopMaintenanceModeRequest: AsyncRequestState
}

const ConstructorMaintenanceModeButton: FunctionComponent<Props> = ({
  isElasticCloudEnterprise,
  ctor,
  startMaintenanceMode,
  startMaintenanceModeRequest,
  stopMaintenanceMode,
  stopMaintenanceModeRequest,
}) => {
  const { maintenance } = ctor

  const ctorDisplayName = isElasticCloudEnterprise ? (
    <FormattedMessage
      id='constructor-maintenance-mode-button.controller-text'
      defaultMessage='controller'
    />
  ) : (
    <FormattedMessage
      id='constructor-maintenance-mode-button.constructor-text'
      defaultMessage='constructor'
    />
  )

  const ctorPluralDisplayName = isElasticCloudEnterprise ? (
    <FormattedMessage
      id='constructor-maintenance-mode-button.controller-plural-text'
      defaultMessage='controllers'
    />
  ) : (
    <FormattedMessage
      id='constructor-maintenance-mode-button.constructor-plural-text'
      defaultMessage='constructors'
    />
  )

  if (maintenance) {
    return (
      <CuiPermissibleControl permissions={Permission.stopConstructorMaintenanceMode}>
        <CuiButtonEmpty
          color='warning'
          size='s'
          spin={stopMaintenanceModeRequest.inProgress}
          onClick={stopMaintenanceMode}
          confirm={true}
          confirmModalProps={{
            title: (
              <FormattedMessage
                id='constructor-maintenance-mode-button.stop-maintenance-confirm-title'
                defaultMessage='Disable {ctorDisplayName} maintenance mode?'
                values={{
                  ctorDisplayName,
                }}
              />
            ),
            body: (
              <FormattedMessage
                id='constructor-maintenance-mode-button.stop-maintenance-confirm-body'
                defaultMessage='Taking a {ctorDisplayName} out of maintenance mode means that it begins accepting deployment configuration changes again.'
                values={{
                  ctorDisplayName,
                }}
              />
            ),
            confirmButtonText: (
              <FormattedMessage
                id='constructor-maintenance-mode-button.stop-maintenance-confirm-button'
                defaultMessage='Disable'
              />
            ),
          }}
        >
          <FormattedMessage
            id='constructor-maintenance-mode-button.stop-maintenance'
            defaultMessage='Disable {ctorDisplayName} maintenance'
            values={{
              ctorDisplayName,
            }}
          />
        </CuiButtonEmpty>
      </CuiPermissibleControl>
    )
  }

  return (
    <CuiPermissibleControl permissions={Permission.startConstructorMaintenanceMode}>
      <CuiButtonEmpty
        color='warning'
        size='s'
        spin={startMaintenanceModeRequest.inProgress}
        onClick={startMaintenanceMode}
        confirm={true}
        confirmModalProps={{
          title: (
            <FormattedMessage
              id='constructor-maintenance-mode-button.start-maintenance-confirm-title'
              defaultMessage='Enable {ctorDisplayName} maintenance mode?'
              values={{
                ctorDisplayName,
              }}
            />
          ),
          body: (
            <FormattedMessage
              id='constructor-maintenance-mode-button.start-maintenance-confirm-body'
              defaultMessage='Placing a {ctorDisplayName} in maintenance mode stops it from accepting new deployment configuration changes. Any deployment changes that are in progress continue processing. New deployment change requests get routed to other {ctorPluralDisplayName}, if available.'
              values={{
                ctorDisplayName,
                ctorPluralDisplayName,
              }}
            />
          ),
          confirmButtonText: (
            <FormattedMessage
              id='constructor-maintenance-mode-button.start-maintenance-confirm-button'
              defaultMessage='Enable'
            />
          ),
        }}
      >
        <FormattedMessage
          id='constructor-maintenance-mode-button.start-maintenance'
          defaultMessage='Enable {ctorDisplayName} maintenance'
          values={{
            ctorDisplayName,
          }}
        />
      </CuiButtonEmpty>
    </CuiPermissibleControl>
  )
}

export default ConstructorMaintenanceModeButton
