/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { acceptEulaUrl } from '@modules/cloud-api/v1/urls'

import asyncRequest from '@/actions/asyncRequests'
import { ACCEPT_EULA } from '@/constants/actions'

export const acceptEula = () => {
  const url = acceptEulaUrl()
  return asyncRequest({
    type: ACCEPT_EULA,
    method: `POST`,
    url,
  })
}
