/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiCheckbox, EuiCode } from '@elastic/eui'

import type { RegionRolesState } from '@modules/ui-types'

import type { FunctionComponent, ReactNode } from 'react'

export interface Props {
  role: RegionRolesState
  customLabel?: ReactNode
  currentRoles: string[]
  toggleRole: (roleId: string) => void
  disabled?: boolean
}

const RoleSwitch: FunctionComponent<Props> = ({
  role,
  customLabel,
  currentRoles,
  toggleRole,
  disabled,
}) => (
  <EuiCheckbox
    id={`roles-set-role-${role.id}`}
    label={customLabel || <EuiCode>{role.id}</EuiCode>}
    checked={currentRoles.includes(role.id)}
    disabled={disabled}
    onChange={() => toggleRole(role.id)}
  />
)

export default RoleSwitch
