/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'

import type { AnyResourceInfo, AnyClusterPlanInfo, SliderInstanceType } from '@modules/ui-types'

import { isSuccessfulShutdownAttempt } from '../../lib/stackDeployments/selectors/configurationChangeAttempts'

import PlanAttemptChangeExplain from './StackConfigurationChangeExplain/PlanAttemptChangeExplain'

import type { FunctionComponent } from 'react'

type Props = {
  resource: AnyResourceInfo
  resourceType: SliderInstanceType
  planAttempt: AnyClusterPlanInfo
  spacerBefore?: boolean
  spacerAfter?: boolean
}

const StackConfigurationChangeSummary: FunctionComponent<Props> = ({
  resource,
  resourceType,
  planAttempt,
  spacerBefore,
  spacerAfter,
}) => {
  if (isSuccessfulShutdownAttempt({ planAttempt })) {
    // structure is for parity with the StackConfigurationChangeExplain markup
    return (
      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem grow={false}>
          <FormattedMessage
            id='stackConfigurationChangeSummary.shutdownPlan'
            defaultMessage='Disabled due to deployment shutdown'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <Fragment>
      {spacerBefore && <EuiSpacer size='m' />}
      <PlanAttemptChangeExplain
        sliderInstanceType={resourceType}
        resource={resource}
        planAttempt={planAttempt}
        planToCompareWith={undefined}
      />
      {spacerAfter && <EuiSpacer size='m' />}
    </Fragment>
  )
}

export default StackConfigurationChangeSummary
