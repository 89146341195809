/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import { CuiEmptyPrompt, CuiPermissibleControl } from '@modules/cui'
import history from '@modules/utils/history'

import { createTopologyNodeConfigurationUrl } from '@/lib/urlBuilder'
import { sortInstanceConfigurations, withoutSoftDeleted } from '@/lib/instanceConfigurations'

import InstanceConfigurationsTable from './InstanceConfigurationsTable'

import type { FunctionComponent } from 'react'
import type { AllProps as Props } from './types'

type AddButtonProps = Pick<Props, 'regionId'>

const AddButton: FunctionComponent<AddButtonProps> = ({ regionId }) => (
  <CuiPermissibleControl permissions={Permission.createInstanceConfiguration}>
    <EuiButton
      fill={true}
      onClick={() => history.push(createTopologyNodeConfigurationUrl(regionId))}
    >
      <FormattedMessage
        id='instance-configuration-list.create-button'
        defaultMessage='Create instance configuration'
      />
    </EuiButton>
  </CuiPermissibleControl>
)

const InstanceConfigurations: FunctionComponent<Props> = ({ regionId, instanceConfigurations }) => {
  if (instanceConfigurations && instanceConfigurations.length === 0) {
    return (
      <CuiEmptyPrompt
        title={
          <h2>
            <FormattedMessage
              id='instance-configuration-list.empty-prompt'
              defaultMessage="You haven't created any instance configurations yet"
            />
          </h2>
        }
        addButton={<AddButton regionId={regionId} />}
      />
    )
  }

  const sortedInstanceConfigurations = withoutSoftDeleted(
    sortInstanceConfigurations(instanceConfigurations),
  )

  return (
    <Fragment>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem>
          <EuiTitle>
            <h3>
              <FormattedMessage
                id='instance-configuration-list.title'
                defaultMessage='Instance configurations'
              />
            </h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <AddButton regionId={regionId} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='l' />

      <InstanceConfigurationsTable
        regionId={regionId}
        instanceConfigurations={sortedInstanceConfigurations}
        initialLoading={!instanceConfigurations}
      />
    </Fragment>
  )
}

export default InstanceConfigurations
