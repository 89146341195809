/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { clamp, round } from 'lodash'

import type { EuiRangeTick } from '@elastic/eui'
import { EuiButtonEmpty, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiRange } from '@elastic/eui'

import type { ElasticsearchProject } from '@modules/project-api/v1/types'
import { getUpdateProjectMutationHook } from '@modules/project-lib/hooks/update'

import genericMessages from '../helpers/genericMessages'

export type Props = {
  project: ElasticsearchProject
}

const DEFAULT_VALUE = 100
const MIN_VALUE = 50
const MAX_VALUE = 200
const STEP = 10
const TICKS: EuiRangeTick[] = [50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200].map((x) => ({
  label: x.toString(),
  value: x,
}))

const SearchPower: React.FunctionComponent<Props> = ({ project }) => {
  const currentSearchPower = project.performance_control?.search_power ?? DEFAULT_VALUE

  const [isEditing, setIsEditing] = useState(false)
  const [searchPower, setSearchPower] = useState(currentSearchPower)

  const updateProjectMutation = getUpdateProjectMutationHook(project.type)()

  return (
    <EuiFlexGroup
      data-test-subj='searchPower'
      responsive={false}
      gutterSize='m'
      alignItems='center'
    >
      <EuiFlexItem grow={false} style={{ width: '32rem' }}>
        <EuiRange
          aria-label='Search power'
          compressed={true}
          fullWidth={true}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={STEP}
          ticks={TICKS}
          showTicks={true}
          showRange={true}
          showInput={true}
          disabled={!isEditing}
          value={searchPower}
          onChange={(e) => {
            setSearchPower(parseInt(e.currentTarget.value, 10))
          }}
          onBlur={() => {
            // snap to nearest 10 and ensure it's in bounds
            setSearchPower(clamp(round(searchPower, -1), MIN_VALUE, MAX_VALUE))
          }}
        />
      </EuiFlexItem>
      {renderEditButton()}
      {renderSaveCancelButtons()}
    </EuiFlexGroup>
  )

  function renderEditButton() {
    if (isEditing) {
      return null
    }

    return (
      <EuiButtonEmpty size='s' onClick={() => setIsEditing(true)}>
        <FormattedMessage {...genericMessages.edit} />
      </EuiButtonEmpty>
    )
  }

  function renderSaveCancelButtons() {
    if (!isEditing) {
      return null
    }

    return (
      <Fragment>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            data-test-subj='submitButton'
            iconType='check'
            iconSize='l'
            size='s'
            color='primary'
            display='fill'
            isLoading={updateProjectMutation.isLoading}
            onClick={() => {
              updateProjectMutation.mutate(
                {
                  id: project.id,
                  body: {
                    performance_control: {
                      ...project.performance_control,
                      search_power: searchPower,
                    },
                  },
                },
                {
                  onSuccess: () => setIsEditing(false),
                },
              )
            }}
          />
        </EuiFlexItem>
        {!updateProjectMutation.isLoading && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              data-test-subj='cancelButton'
              iconType='cross'
              iconSize='l'
              size='s'
              color='text'
              display='base'
              onClick={() => {
                setIsEditing(false)
                setSearchPower(currentSearchPower)
              }}
            />
          </EuiFlexItem>
        )}
      </Fragment>
    )
  }
}

export default SearchPower
