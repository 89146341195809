/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { setAllocatorMetadataItemUrl } from '@modules/cloud-api/v1/urls'
import type { MetadataItem } from '@modules/cloud-api/v1/types'
import type { Allocator } from '@modules/ui-types'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { ADD_ALLOCATOR_TAG } from '../../constants/actions'

export function addAllocatorTag(allocator: Allocator, { key, value }: MetadataItem) {
  const { regionId, id } = allocator
  const url = setAllocatorMetadataItemUrl({ allocatorId: id, regionId, key })

  return asyncRequest({
    type: ADD_ALLOCATOR_TAG,
    method: `PUT`,
    url,
    payload: { value },
    meta: { regionId, id, ip: id },
    crumbs: [regionId, id],
  })
}

export const resetAddAllocatorTagRequest = (...crumbs) =>
  resetAsyncRequest(ADD_ALLOCATOR_TAG, crumbs)
