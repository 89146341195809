/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  shutdownStackDeploymentRequest,
  getStackDeploymentsFromSearch,
  searchStackDeploymentsRequest,
} from '@/reducers'

import {
  resetShutdownStackDeployment,
  shutdownStackDeployment,
  fetchDeployment,
  searchDeployments,
} from '../../../../../actions/stackDeployments'
import { submitUserFeedbackForDeployment } from '../../../../../actions/user/submitUserFeedback'
import { getProfile } from '../../../../../apps/userconsole/reducers'

import HideDeploymentInUserconsole from './HideDeploymentInUserconsole'

import type { ConsumerProps, StateProps, DispatchProps } from './HideDeploymentInUserconsole'

const queryId = `all-user-deployments`

const mapStateToProps = (state, { deployment }: ConsumerProps): StateProps => ({
  shutdownStackDeploymentRequest: shutdownStackDeploymentRequest(state, deployment.id),
  searchResults: getStackDeploymentsFromSearch(state, queryId),
  searchResultsRequest: searchStackDeploymentsRequest(state, queryId),
  profile: getProfile(state)!,
})

const mapDispatchToProps = (dispatch, { deployment }: ConsumerProps): DispatchProps => ({
  stopAndHideDeployment: () =>
    dispatch(
      shutdownStackDeployment({
        deploymentId: deployment.id,
        hide: true,
        skipSnapshot: true,
        showAsDeleted: true,
      }),
    ),
  resetShutdownStackDeployment: () => dispatch(resetShutdownStackDeployment(deployment.id)),
  fetchDeployment: () => dispatch(fetchDeployment({ deploymentId: deployment.id })),
  fetchDeployments: () => dispatch(searchDeployments({ queryId, query: {} })),
  submitUserFeedback: (options) => dispatch(submitUserFeedbackForDeployment(options)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(HideDeploymentInUserconsole)
