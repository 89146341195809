/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiBadge,
  EuiTitle,
  EuiSkeletonText,
  EuiDescribedFormGroup,
  EuiFormRow,
} from '@elastic/eui'

import type { PlatformInfo } from '@modules/cloud-api/v1/types'

import type { FunctionComponent } from 'react'

interface Props {
  platform: PlatformInfo | null
}

const PlatformVersion: FunctionComponent<Props> = ({ platform }) => (
  <EuiDescribedFormGroup
    title={
      <EuiTitle size='xs'>
        <h3>
          <FormattedMessage
            id='region-admin-platform.platform-version'
            defaultMessage='Platform version'
          />
        </h3>
      </EuiTitle>
    }
    fullWidth={true}
  >
    <EuiFormRow fullWidth={true}>
      {platform ? (
        <EuiBadge color='hollow' data-test-id='platform-version'>
          {platform.version}
        </EuiBadge>
      ) : (
        <EuiSkeletonText lines={1} />
      )}
    </EuiFormRow>
  </EuiDescribedFormGroup>
)

export default PlatformVersion
