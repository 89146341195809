/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AccountResponse, AccountUpdateRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import {
  fetchCurrentAccount,
  resetFetchCurrentAccount,
  resetUpdateCurrentAccount,
  updateCurrentAccount,
} from '../../../actions/account'
import {
  fetchCurrentAccountRequest,
  getCurrentAccount,
  updateCurrentAccountRequest,
} from '../../../reducers'

import SetDefaultTrust from './SetDefaultTrust'

import type { ThunkDispatch, ReduxState } from '@/types/redux'

interface StateProps {
  currentAccount: AccountResponse | null
  fetchCurrentAccountRequest: AsyncRequestState
  updateCurrentAccountRequest: AsyncRequestState
}

interface DispatchProps {
  fetchCurrentAccount: () => Promise<unknown>
  updateCurrentAccount: (payload: AccountUpdateRequest) => Promise<unknown>
  resetFetchCurrentAccount: () => void
  resetUpdateCurrentAccount: () => void
}

interface ConsumerProps {}

const mapStateToProps = (state: ReduxState): StateProps => ({
  currentAccount: getCurrentAccount(state),
  fetchCurrentAccountRequest: fetchCurrentAccountRequest(state),
  updateCurrentAccountRequest: updateCurrentAccountRequest(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchCurrentAccount: () => dispatch(fetchCurrentAccount()),
  updateCurrentAccount: (payload) => dispatch(updateCurrentAccount({ payload })),
  resetFetchCurrentAccount: () => dispatch(resetFetchCurrentAccount()),
  resetUpdateCurrentAccount: () => dispatch(resetUpdateCurrentAccount()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SetDefaultTrust)
