/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { InstanceConfiguration } from '@modules/cloud-api/v1/types'

import {
  allocatorFilterToClauses,
  getAllocatorFilterPayload,
  removeMalformedClauses,
} from '../../../../lib/allocatorFilters'
import { SIZING_UNIT_MEMORY } from '../../../../lib/instanceConfigurations/multipliers'

import type { FauxInstanceConfiguration } from './instanceConfigurationWizardTypes'

type DeserializeParams = {
  instanceConfiguration: InstanceConfiguration
}

type SerializeParams = {
  regionId: string
  instanceConfiguration: FauxInstanceConfiguration
}

type Bounds = {
  min: number
  max: number
}

// These values are also set in the backend and any changes here must be reflected there.
// adminconsole/src/main/resources/reference.conf
const MIN_INSTANCE_SIZE = 512
const MAX_INSTANCE_SIZE = 65536

export function getInstanceBounds(): Bounds | null {
  return {
    min: MIN_INSTANCE_SIZE,
    max: MAX_INSTANCE_SIZE,
  }
}

export function deserializeInstanceConfiguration({
  instanceConfiguration,
}: DeserializeParams): FauxInstanceConfiguration {
  const {
    name,
    description = ``,
    allocator_filter,
    instance_type,
    node_types,
    discrete_sizes: { resource: sizingUnit, sizes: increments, default_size: defaultValue },
    storage_multiplier: ratio,
  } = instanceConfiguration

  return {
    name,
    description,
    clauses: allocatorFilterToClauses(allocator_filter),
    instance_type,
    node_types,
    sizingUnit,
    ratio,
    [`${sizingUnit}Increments`]: increments,
    [`${sizingUnit}DefaultValue`]: defaultValue,
  } as FauxInstanceConfiguration
}

export function serializeInstanceConfiguration({
  regionId,
  instanceConfiguration,
}: SerializeParams): InstanceConfiguration {
  const wellFormedClauses = removeMalformedClauses(instanceConfiguration.clauses)

  return {
    name: instanceConfiguration.name,
    description: instanceConfiguration.description,
    storage_multiplier: instanceConfiguration.ratio,
    instance_type: instanceConfiguration.instance_type,
    node_types: instanceConfiguration.node_types,
    discrete_sizes: {
      sizes:
        instanceConfiguration.sizingUnit === SIZING_UNIT_MEMORY
          ? instanceConfiguration.memoryIncrements
          : instanceConfiguration.storageIncrements,
      default_size:
        instanceConfiguration.sizingUnit === SIZING_UNIT_MEMORY
          ? instanceConfiguration.memoryDefaultValue
          : instanceConfiguration.storageDefaultValue,
      resource: instanceConfiguration.sizingUnit,
    },
    allocator_filter: getAllocatorFilterPayload(regionId, wellFormedClauses),
  } as InstanceConfiguration
}
