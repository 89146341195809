/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage } from 'react-intl'
import React, { Fragment } from 'react'

import ExternalLink from '../../../ExternalLink'

interface GetBillingPdfs {
  cloud_pdf_download?: string
  external_invoice_pdf_download?: string
  number: string | number
}

export function getBillingPdfs({
  cloud_pdf_download,
  external_invoice_pdf_download,
  number,
}: GetBillingPdfs): JSX.Element {
  if (!cloud_pdf_download && !external_invoice_pdf_download) {
    return <span>{number}</span>
  }

  const billing_pdfs: JSX.Element[] = []

  if (cloud_pdf_download) {
    billing_pdfs.push(
      <ExternalLink
        style={{ display: 'block' }}
        data-test-id='cloud-pdf-link'
        href={cloud_pdf_download}
        key={cloud_pdf_download}
      >
        {number}
      </ExternalLink>,
    )
  }

  if (external_invoice_pdf_download) {
    billing_pdfs.push(
      <ExternalLink
        style={{ display: 'block' }}
        data-test-id='external-pdf-link'
        href={external_invoice_pdf_download}
        key={external_invoice_pdf_download}
      >
        <FormattedMessage
          id='billing-statements.external-usage-pdf'
          defaultMessage='external {number}'
          values={{ number }}
        />
      </ExternalLink>,
    )
  }

  return <Fragment>{billing_pdfs}</Fragment>
}
