/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isNumber } from 'lodash'

import type { InstanceConfiguration, InstanceConfigurationInfo } from '@modules/cloud-api/v1/types'
import type { Region, StackDeployment } from '@modules/ui-types'

// This is also our maximum zone count, so we support this value and under
const DEFAULT_ZONES = 3

export function getMaxAvailableZonesForDeployment(deployment: StackDeployment): number {
  // We only expect a single entry in the `elasticsearch` array
  const maxZoneCounts =
    deployment.resources.elasticsearch[0]?.info.plan_info.current?.plan?.cluster_topology.map(
      (x) => x.zone_count ?? 0,
    ) ?? []

  return Math.max(...maxZoneCounts)
}

export function getNumberOfAvailableZones({
  region,
  instanceConfiguration,
}: {
  region: Region | undefined
  instanceConfiguration: InstanceConfiguration | InstanceConfigurationInfo | undefined
}): number {
  // An instance config will only have a max_zones field if requested with a query param.
  // We shouldn't be calling this in those cases, but we'll fall back to previous region
  // based zone calculations just in case.
  if (isNumber(instanceConfiguration?.max_zones)) {
    // Safety fallback
    return Math.min(
      getNumberOfAvailableZonesForInstanceConfig(instanceConfiguration!),
      getNumberOfAvailableZonesForRegion(region),
    )
  }

  return getNumberOfAvailableZonesForRegion(region)
}

function getNumberOfAvailableZonesForInstanceConfig(
  instanceConfig: InstanceConfiguration | InstanceConfigurationInfo,
): number {
  const total = isNumber(instanceConfig.max_zones) ? instanceConfig.max_zones : DEFAULT_ZONES
  return Math.min(total, DEFAULT_ZONES)
}

function getNumberOfAvailableZonesForRegion(region: Region | undefined): number {
  if (!region) {
    return DEFAULT_ZONES
  }

  const total = region.allocators.zones.count?.total || DEFAULT_ZONES

  // Capped at our maximum to be consistent with our logic retreiving zones by ic.
  return Math.min(total, DEFAULT_ZONES)
}
