/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiDescribedFormGroup, EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

import type { UpdatedTlsChain } from '@modules/cloud-api/v1/types'

import DocLink from '../../DocLink'

import Certificate from './Certificate'

import type { FunctionComponent } from 'react'

interface Props {
  regionId: string
  targets: UpdatedTlsChain[]
}

const TlsCertificates: FunctionComponent<Props> = ({ regionId, targets }) => (
  <EuiDescribedFormGroup
    id='tls'
    title={
      <EuiTitle size='xs'>
        <h3>
          <FormattedMessage
            id='region-settings.tls-settings.title'
            defaultMessage='TLS certificates'
          />
        </h3>
      </EuiTitle>
    }
    description={
      <FormattedMessage
        id='region-settings.tls-settings.description'
        defaultMessage='Provide your own certificates to establish secure connections. {learnMore}.'
        values={{
          learnMore: (
            <DocLink link='manageTlsCertsDocLink'>
              <FormattedMessage
                id='region-settings.tls-settings.learn-more'
                defaultMessage='Learn more'
              />
            </DocLink>
          ),
        }}
      />
    }
    fullWidth={true}
  >
    <EuiFormRow fullWidth={true}>
      <div>
        {targets.map((target, index) => (
          <Fragment key={target.service}>
            {index !== 0 && <EuiSpacer size='m' />}

            <Certificate target={target} regionId={regionId} />
          </Fragment>
        ))}
      </div>
    </EuiFormRow>
  </EuiDescribedFormGroup>
)

export default TlsCertificates
