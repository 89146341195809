/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'

import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiFormControlLayout,
  EuiButton,
  EuiButtonEmpty,
  EuiCopy,
  EuiFieldText,
  EuiSpacer,
} from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'

import type { FunctionComponent } from 'react'
import type { IntlShape } from 'react-intl'

interface Props {
  intl: IntlShape
  linkRequest: AsyncRequestState
  link: string
  onClose: () => void
}

const messages = defineMessages({
  linkLabel: {
    id: `password-link-modal.link-label`,
    defaultMessage: `Password reset link`,
  },
})

const PasswordLinkModal: FunctionComponent<Props> = ({
  intl: { formatMessage },
  linkRequest,
  link,
  onClose,
}) => {
  const isLoading = linkRequest.inProgress

  return (
    <EuiModal onClose={onClose} style={{ width: 500 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <FormattedMessage id='password-link-modal' defaultMessage='Reset password link' />
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody style={{ textAlign: 'center' }}>
        <EuiFormControlLayout
          fullWidth={true}
          isLoading={isLoading}
          append={
            <EuiCopy textToCopy={link}>
              {(copy) => (
                <EuiButtonEmpty onClick={copy} size='xs' iconType='copy' iconSide='right'>
                  <FormattedMessage id='password-link-modal.copy-text' defaultMessage='Copy link' />
                </EuiButtonEmpty>
              )}
            </EuiCopy>
          }
        >
          <EuiFieldText
            controlOnly={true}
            type='text'
            aria-label={formatMessage(messages.linkLabel)}
            value={link}
            readOnly={true}
          />
        </EuiFormControlLayout>

        {linkRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{linkRequest.error}</CuiAlert>
          </Fragment>
        )}
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={onClose} fill={true} isLoading={isLoading}>
          <FormattedMessage id='password-link-modal.close-button' defaultMessage='Close' />
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default injectIntl(PasswordLinkModal)
