/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { size } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect } from '@elastic/eui'

import type { ElasticsearchCuration } from '@modules/cloud-api/v1/types'

type Props = {
  curation: ElasticsearchCuration | undefined
  setCuration: (curation: ElasticsearchCuration | undefined) => void
  curationConfigurationOptions: Array<{ id: string; name: string }>
}

type State = {
  hotInstanceConfigurationId: string | undefined
  warmInstanceConfigurationId: string | undefined
}

class IndexCurationTargets extends React.Component<Props, State> {
  state: State = {
    hotInstanceConfigurationId: this.props.curation?.from_instance_configuration_id,
    warmInstanceConfigurationId: this.props.curation?.to_instance_configuration_id,
  }

  render() {
    const { curationConfigurationOptions } = this.props
    const { hotInstanceConfigurationId, warmInstanceConfigurationId } = this.state

    const options = [
      {
        value: undefined,
        text: ``,
      },
      ...curationConfigurationOptions.map(({ id, name }) => ({
        value: id,
        text: name,
      })),
    ]

    const same = hotInstanceConfigurationId === warmInstanceConfigurationId
    const invalidHot = same || size(hotInstanceConfigurationId) === 0
    const invalidWarm = same || size(warmInstanceConfigurationId) === 0

    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow
            label={
              <FormattedMessage id='index-curation-settings.from-label' defaultMessage='Hot node' />
            }
            helpText={
              <FormattedMessage
                id='index-curation-settings.from-help-text'
                defaultMessage='Select where to create new indices'
              />
            }
          >
            <EuiSelect
              onChange={(e) =>
                this.setState({ hotInstanceConfigurationId: e.target.value }, this.update)
              }
              value={hotInstanceConfigurationId}
              isInvalid={invalidHot}
              options={options}
              fullWidth={true}
              data-test-id='indexCuration-hotInstance'
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow
            label={
              <FormattedMessage id='index-curation-settings.to-label' defaultMessage='Warm node' />
            }
            helpText={
              <FormattedMessage
                id='index-curation-settings.to-help-text'
                defaultMessage='Select where to move old indices'
              />
            }
          >
            <EuiSelect
              onChange={(e) =>
                this.setState({ warmInstanceConfigurationId: e.target.value }, this.update)
              }
              value={warmInstanceConfigurationId}
              isInvalid={invalidWarm}
              options={options}
              fullWidth={true}
              data-test-id='indexCuration-warmInstance'
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  update = () => {
    const { hotInstanceConfigurationId, warmInstanceConfigurationId } = this.state

    // the ElasticsearchCuration object is atomic, so only trigger a change if both IDs exist
    this.props.setCuration(
      hotInstanceConfigurationId && warmInstanceConfigurationId
        ? {
            from_instance_configuration_id: hotInstanceConfigurationId,
            to_instance_configuration_id: warmInstanceConfigurationId,
          }
        : undefined,
    )
  }
}

export default IndexCurationTargets
