/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiDescriptionList,
  EuiTextColor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui'

import type { SaasUserResponse } from '@modules/cloud-api/v1/types'
import type { UserProfileData } from '@modules/ui-types'
import { CuiLink } from '@modules/cui'

import UserEmailDashboardLink from '@/components/User/UserEmailDashboardLink'
import { organizationOverviewUrl } from '@/lib/urlBuilder'
import PageSection from '@/components/PageSection'
import UserEmailForm from '@/components/User/UserEmailForm'
import MfaManagement from '@/components/User/MfaManagement'
import ClearPrivatelyIdentifiableInformationButton from '@/components/User/ClearPrivatelyIdentifiableInformationButton'
import RequestDeletionButton from '@/components/User/RequestDeletionButton'
import ClearTransactionalEmailSuppressionsButton from '@/components/User/ClearTransactionalEmailSuppressionsButton'
import ResendVerificationEmail from '@/components/User//ResendVerificationEmail'
import GenerateResetPasswordLinkButton from '@/components/User/GenerateResetPasswordLinkButton'
import OktaGroups from '@/components/User/OktaGroups'

import UserTrafficLogLink from '../UserTrafficLogLink'

import type { FunctionComponent } from 'react'

import './userOverview.scss'

type Props = {
  profile: SaasUserResponse
}

const UserOverview: FunctionComponent<Props> = ({ profile }) => {
  const { user } = profile
  const { email, domain, data, organization_id: organizationId, user_id } = user
  const userId = String(user_id)

  // `data` is just an opaque object in the API contracts :-(
  const herokuEmail = (data as UserProfileData | undefined)?.heroku?.owner_email

  return (
    <EuiFlexGroup
      className='user-overview'
      style={{ maxWidth: '900px' }}
      direction='column'
      gutterSize='s'
    >
      <EuiFlexItem>
        <EuiDescriptionList
          compressed={true}
          type='responsiveColumn'
          listItems={[
            {
              title: <FormattedMessage id='user-overview.user-id-label' defaultMessage='User ID' />,
              description: userId,
            },
            {
              title: (
                <FormattedMessage
                  id='user-overview.organization-id-label'
                  defaultMessage='Organization ID'
                />
              ),
              description: organizationId ? (
                <CuiLink to={organizationOverviewUrl(organizationId)}>{organizationId}</CuiLink>
              ) : (
                <FormattedMessage id='user-overview.no-organization' defaultMessage='None' />
              ),
            },
            {
              title: <FormattedMessage id='user-overview.user-email' defaultMessage='Email' />,
              description: <UserEmailForm userId={user_id} email={email} />,
            },

            ...(domain === `heroku`
              ? [
                  {
                    title: (
                      <FormattedMessage
                        id='user-overview.heroku-email'
                        defaultMessage='Email (Heroku)'
                      />
                    ),
                    description: (
                      <div>
                        {herokuEmail || (
                          <EuiTextColor color='subdued'>
                            <FormattedMessage
                              id='user-overview.empty-heroku-email'
                              defaultMessage='Not set'
                            />
                          </EuiTextColor>
                        )}
                      </div>
                    ),
                  },
                ]
              : []),
            {
              title: (
                <FormattedMessage
                  id='user-overview.mfa'
                  defaultMessage='Multi-factor authentication'
                />
              ),
              description: <MfaManagement userId={userId} email={email} />,
            },
            {
              title: (
                <FormattedMessage id='user-overview.user-okta-group' defaultMessage='Okta groups' />
              ),
              description: <OktaGroups userId={user_id.toString()} />,
            },
          ]}
        />
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <ClearPrivatelyIdentifiableInformationButton userId={userId} />
      </EuiFlexItem>

      <EuiFlexItem>
        <RequestDeletionButton userId={userId} email={email} />
      </EuiFlexItem>

      <EuiFlexItem>
        <GenerateResetPasswordLinkButton userId={userId} />
      </EuiFlexItem>

      <EuiFlexItem>
        <ClearTransactionalEmailSuppressionsButton userId={userId} />
      </EuiFlexItem>

      <EuiFlexItem>
        <ResendVerificationEmail email={email} />
      </EuiFlexItem>

      <EuiFlexItem style={{ maxWidth: '500px' }}>
        <EuiSpacer />

        <PageSection
          iconType='logoKibana'
          title={
            <FormattedMessage
              id='user-overview.kibana-section'
              defaultMessage='Kibana dashboards'
            />
          }
        >
          <UserEmailDashboardLink email={email} />

          <EuiSpacer size='l' />

          <UserTrafficLogLink userId={userId} />
        </PageSection>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default UserOverview
