/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'

import type { Organization, SaasOrganizationResponse } from '@modules/cloud-api/v1/types'

import { AsyncAction } from '@/types/redux'
import {
  CALL_STORED_PROCEDURE,
  CONVERT_ORGANIZATION_TO_INVOICING,
  FETCH_SAAS_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from '@/constants/actions'
import { mergeIn, replaceIn } from '@/lib/immutability-helpers'

import type { CallProcedureAction } from '../storedProcedures/storedProcedureTypes'

export type State = {
  [organizationId: string]: SaasOrganizationResponse
}

interface FetchSaasOrganizationAction
  extends AsyncAction<typeof FETCH_SAAS_ORGANIZATION, SaasOrganizationResponse> {
  meta: { organizationId: string }
}

interface UpdateOrganizationAction extends AsyncAction<typeof UPDATE_ORGANIZATION, Organization> {
  meta: { organizationId: string }
}

interface ConvertOrganizationToInvoicingAction
  extends AsyncAction<typeof CONVERT_ORGANIZATION_TO_INVOICING, void> {
  meta: { organizationId: string }
}

type Action =
  | FetchSaasOrganizationAction
  | CallProcedureAction
  | UpdateOrganizationAction
  | ConvertOrganizationToInvoicingAction

export default function organizationsReducer(state: State = {}, action: Action): State {
  if (action.type === FETCH_SAAS_ORGANIZATION) {
    if (action.payload && !action.error) {
      const { organizationId } = action.meta

      return replaceIn(state, [organizationId], action.payload)
    }
  }

  if (action.type === CALL_STORED_PROCEDURE) {
    if (!action.error && action.payload) {
      const { organizationId, procedureName } = action.meta
      const success = action.payload.ok

      if (!organizationId || !success) {
        return state
      }

      if (procedureName === `ensure_paying_level`) {
        const subscriptionPath = [organizationId, `subscription`, `level`]
        const [, level] = action.meta.parameters
        return replaceIn(state, subscriptionPath, level)
      }

      if (procedureName === `extend_organization_trial`) {
        const trials = state[organizationId]?.trials ?? []
        const lastTrialIndex = trials.length - 1

        if (lastTrialIndex < 0) {
          return state
        }

        const lastTrialEndPath = [organizationId, `trials`, String(lastTrialIndex), `end`]
        const [, rawExtendedTrialEnd] = action.meta.parameters
        const extendedTrialEnd = moment(rawExtendedTrialEnd, `YYYY-MM-DD`).toISOString()

        return replaceIn(state, lastTrialEndPath, extendedTrialEnd)
      }
    }
  }

  if (action.type === UPDATE_ORGANIZATION) {
    if (action.payload && !action.error) {
      const {
        meta: { organizationId },
        payload,
      } = action
      return mergeIn(state, organizationId, {
        rules: {
          allow_bundles: payload.custom_bundles_allowed,
          allow_plugins: payload.custom_plugins_allowed,
        },
        organization: {
          data: {
            user_warning: {
              disk: {
                enabled: payload.default_disk_usage_alerts_enabled,
              },
            },
          },
        },
      })
    }
  }

  if (action.type === CONVERT_ORGANIZATION_TO_INVOICING) {
    if (action.payload && !action.error) {
      const { organizationId } = action.meta
      return mergeIn(state, [organizationId, 'subscription'], {
        invoicable: true,
        is_paying: true,
        is_trial: false,
      })
    }
  }

  return state
}

export function getSaasOrganization(
  state: State,
  organizationId: string,
): SaasOrganizationResponse | undefined {
  return state[organizationId]
}
