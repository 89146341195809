/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'

import { lt } from '@/lib/semver'

import type { FunctionComponent } from 'react'

export type Props = {
  deploymentUpdateRequest: AsyncRequestState
  version: string
  onCancel: () => void
  onConfirm: () => void
}

const ConfirmUpgrade: FunctionComponent<Props> = ({
  deploymentUpdateRequest,
  version,
  onCancel,
  onConfirm,
}) => (
  <EuiOverlayMask>
    <EuiConfirmModal
      title={
        <FormattedMessage id='confirm-upgrade.title' defaultMessage='Upgrade this deployment?' />
      }
      cancelButtonText={
        <FormattedMessage id='confirm-upgrade.cancel' defaultMessage='Cancel upgrade' />
      }
      confirmButtonText={
        <FormattedMessage id='confirm-upgrade.confirm' defaultMessage='Confirm upgrade' />
      }
      onCancel={onCancel}
      onConfirm={onConfirm}
      isLoading={deploymentUpdateRequest.inProgress}
    >
      {version && lt(version, `6.7.0`) && (
        <FormattedMessage
          id='confirm-upgrade.full-restart'
          defaultMessage='To upgrade to a major version, the deployment goes through a full restart and is not accessible during this time.'
        />
      )}
    </EuiConfirmModal>
  </EuiOverlayMask>
)

export default ConfirmUpgrade
