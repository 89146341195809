/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

export type PathParameters = Record<string, string | number | boolean>

export const fetchAsJson = async <T>(
  url: string,
  opts: Parameters<typeof fetch>[1] = {},
): Promise<T> => {
  const response = await fetch(url, {
    ...opts,
    headers: {
      Accept: 'application/json',
      Authorization: 'ApiKey dummy-key',
      'Content-Type': 'application/json',
      ...opts.headers,
    },
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json() as Promise<T>
}

export const applyPathParameters = (
  parameterizedUrl: string,
  pathParameters: Record<string, string | number | boolean>,
): string =>
  parameterizedUrl.replace(
    /{(\w+)}/g,
    (_, paramName) => pathParameters[paramName]?.toString?.() || '',
  )
