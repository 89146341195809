/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui'
import history from '@modules/utils/history'
import Header from '@modules/cui/Header'

import { organizationsCrumbs } from '@/lib/crumbBuilder'
import { organizationOverviewUrl } from '@/lib/urlBuilder'

import OrganizationSearchForm from './OrganizationSearchForm'
import OrganizationsTable from './OrganizationsTable'

import type { AllProps as Props, SearchTypeOption, State } from './types'
import type { ReactElement } from 'react'

const initialState: State = {
  searchType: null,
  searchInput: null,
}

class Organizations extends Component<Props, State> {
  state: State = initialState

  componentWillUnmount(): void {
    this.onReset()
  }

  render(): ReactElement | null {
    const { lookupSaasUsers, organizations } = this.props
    const { searchType, searchInput } = this.state

    if (!lookupSaasUsers) {
      return null
    }

    const searchRequest = this.getSearchRequest()
    const inProgress = Boolean(searchRequest?.inProgress)

    return (
      <Fragment>
        <Header
          name={
            <FormattedMessage
              id='organization.organizations.title'
              defaultMessage='Organizations'
            />
          }
          breadcrumbs={organizationsCrumbs()}
        />

        <EuiSpacer size='m' />

        <OrganizationSearchForm
          inProgress={inProgress}
          onSearch={this.onSearch}
          onReset={this.onReset}
        />

        {searchRequest?.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error' data-test-id='organization.organizations.error'>
              {searchRequest.error}
            </CuiAlert>
          </Fragment>
        )}

        {searchRequest?.isDone && searchType === `name` && (
          <Fragment>
            <EuiSpacer size='l' />

            <OrganizationsTable organizations={Object.values(organizations)} />
          </Fragment>
        )}

        {searchRequest?.isDone && searchType === `deploymentId` && (
          <Fragment>
            <EuiSpacer size='l' />

            <EuiCallOut
              color='danger'
              title={
                <FormattedMessage
                  id='organization.organizations.no-deployment-found'
                  defaultMessage='Could not find a deployment with ID {deploymentId}'
                  values={{ deploymentId: searchInput }}
                />
              }
            />
          </Fragment>
        )}
      </Fragment>
    )
  }

  getSearchRequest = (): AsyncRequestState | null => {
    const {
      searchOrganizationByIdRequest,
      searchOrganizationsByNameRequest,
      searchOrganizationByDeploymentIdRequest,
    } = this.props
    const { searchType, searchInput } = this.state

    if (searchType === null || searchInput === null) {
      return null
    }

    if (searchType === `id`) {
      return searchOrganizationByIdRequest(searchInput)
    }

    if (searchType === `name`) {
      return searchOrganizationsByNameRequest
    }

    return searchOrganizationByDeploymentIdRequest
  }

  onSearch = (searchType: SearchTypeOption, searchInput: string): void => {
    this.setState({ searchType, searchInput }, () =>
      this.search(searchType, searchInput).then(() =>
        this.navigateAwayWhenFoundById(searchType, searchInput),
      ),
    )
  }

  onReset = (): void => {
    const {
      resetSearchOrganizationByIdRequest,
      resetSearchOrganizationsByNameRequest,
      resetSearchOrganizationByDeploymentIdRequest,
    } = this.props
    const { searchInput } = this.state

    if (searchInput !== null) {
      resetSearchOrganizationByIdRequest(searchInput)
    }

    resetSearchOrganizationsByNameRequest()
    resetSearchOrganizationByDeploymentIdRequest()

    this.setState(initialState)
  }

  search = (searchType: SearchTypeOption, searchInput: string): Promise<any> => {
    const { searchOrganizationById, searchOrganizationsByName, searchOrganizationByDeploymentId } =
      this.props

    if (searchType === `id`) {
      return searchOrganizationById(searchInput)
    }

    if (searchType === `name`) {
      return searchOrganizationsByName(searchInput)
    }

    return searchOrganizationByDeploymentId(searchInput)
  }

  navigateAwayWhenFoundById(searchType: SearchTypeOption, searchInput: string): void {
    if (searchType === `name`) {
      return
    }

    if (searchType === `id`) {
      const { organizations } = this.props
      const organization = organizations[searchInput]

      if (!organization) {
        return
      }

      history.push(organizationOverviewUrl(searchInput))

      return
    }

    if (searchType === `deploymentId`) {
      const { deployments } = this.props

      if (deployments.length === 0) {
        return
      }

      const [deployment] = deployments

      const organizationId = deployment.metadata?.organization_id

      if (organizationId) {
        history.push(organizationOverviewUrl(organizationId))
      }
    }
  }
}

export default Organizations
