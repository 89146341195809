/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { CuiFilterContext, createQueryExecutor } from '@modules/cui'
import type { ControlledFilterQuery } from '@modules/cui'

import { getQueryModel, schema, defaultFields } from './schema'
import { getFilters } from './filters'

import type { ControlPlane } from '../types'
import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = {
  intl: IntlShape
  query: ControlledFilterQuery
  onChange: (q: any) => void
  isLoading?: boolean
  controlPlanes?: ControlPlane[]
}

const messages = defineMessages({
  searchBarPlaceholder: {
    id: `control-plane-filter-context.placeholder`,
    defaultMessage: `e.g.: connected:y`,
  },
  emptyMessage: {
    id: `control-plane-filter-context.no-matches`,
    defaultMessage: `No control planes match your query`,
  },
})

const executeQuery = createQueryExecutor({
  defaultFields,
  getQueryModel,
})

const ControlPlaneFilterContext: FunctionComponent<Props> = ({
  intl,
  query,
  onChange,
  isLoading,
  controlPlanes,
}) => {
  const { formatMessage } = intl
  const filters = getFilters({
    intl,
  })
  const placeholder = formatMessage(messages.searchBarPlaceholder)
  const emptyMessage = formatMessage(messages.emptyMessage)

  return (
    <CuiFilterContext<ControlPlane>
      query={query}
      onChange={onChange}
      records={controlPlanes}
      schema={schema}
      filters={filters}
      isLoading={isLoading}
      executeQuery={executeQuery}
      placeholder={placeholder}
      emptyMessage={emptyMessage}
    />
  )
}

export default injectIntl(ControlPlaneFilterContext)
