/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getDeploymentResourceProxyRequestsUrl } from '@modules/cloud-api/v1/urls'
import type { StackDeployment } from '@modules/ui-types'

import { getFirstEsRefId } from '@/lib/stackDeployments/selectors/clusters'

import asyncRequest from '../asyncRequests'
import { FETCH_SNAPSHOT_STATUS } from '../../constants/actions'

import { REPOSITORY_NAME } from './constants'

export function fetchSnapshotStatus({
  deployment,
  snapshotName,
}: {
  deployment: StackDeployment
  snapshotName: string
}) {
  return (dispatch) => {
    const { id: deploymentId } = deployment
    const refId = getFirstEsRefId({ deployment })

    const url = getDeploymentResourceProxyRequestsUrl({
      deploymentId,
      resourceKind: 'elasticsearch',
      refId,
      proxyPath: `_snapshot/${REPOSITORY_NAME}/${snapshotName}/_status`,
    })

    return dispatch(
      asyncRequest({
        type: FETCH_SNAPSHOT_STATUS,
        url,
        meta: { deploymentId, snapshotName },
        crumbs: [deploymentId, snapshotName],
        requestSettings: {
          request: {
            headers: {
              'X-Management-Request': true,
            },
          },
        },
      }),
    )
  }
}
