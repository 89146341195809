/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { orderBy } from 'lodash'

import type { InstanceConfiguration } from '@modules/cloud-api/v1/types'

export const sortInstanceConfigurations = (
  instanceConfigurations: InstanceConfiguration[] | null,
): InstanceConfiguration[] =>
  // isDefault isn't declared on InstanceConfiguration, and I can't see where
  // we might manually set it. It may not exist.
  orderBy(instanceConfigurations, [`isDefault`, `id`], [`desc`, `asc`])

export function withoutSoftDeleted(
  instanceConfigurations: InstanceConfiguration[],
): InstanceConfiguration[] {
  return instanceConfigurations.filter(
    (instanceConfiguration) => instanceConfiguration.deleted_on == null,
  )
}
