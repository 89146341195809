/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { matchPath } from 'react-router'

import type { ElasticsearchCluster, StackDeployment, ApmCluster } from '@modules/ui-types'

import DeploymentHeader from '../DeploymentHeader'
import DeploymentHealthStatus from '../DeploymentHealthStatus'
import IlmMigrationSuccess from '../../IlmMigration/IlmMigrationSuccess'
import { shouldShowProblemInOverviewPage } from '../../../lib/healthProblems'
import {
  deploymentUrl,
  deploymentActivityUrl,
  deploymentHealthStatusUrl,
  deploymentGettingStartedUrl,
} from '../../../lib/urlBuilder'

import type { Location } from 'history'
import type { FunctionComponent } from 'react'

type Props = {
  deployment: ElasticsearchCluster
  stackDeployment: StackDeployment | null
  apm?: ApmCluster | null
  location: Location
  shouldShowRegion: boolean
}

const DeploymentWrapper: FunctionComponent<Props> = ({
  deployment,
  stackDeployment,
  location,
  shouldShowRegion,
  children,
}) => {
  const { id } = stackDeployment!
  const onOverviewPage = isOverviewPage(location)
  const onActivityPage = isActivityPage(location)
  const onHealthPage = isHealthPage(location)
  const isGettingStartedPage = onGettingStartedPage({ location, id })

  return (
    <section>
      {!isGettingStartedPage && (
        <Fragment>
          <DeploymentHeader
            stackDeployment={stackDeployment}
            deployment={deployment}
            shouldShowRegion={shouldShowRegion}
            isOverviewPage={onOverviewPage}
            isHealthPage={onHealthPage}
          />

          {onOverviewPage && (
            <DeploymentHealthStatus
              stackDeployment={stackDeployment}
              hideActivityBits={onActivityPage}
              filterProblems={shouldShowProblemInOverviewPage}
            />
          )}
        </Fragment>
      )}

      <IlmMigrationSuccess stackDeployment={stackDeployment} />

      <div className='cluster-content'>{children}</div>
    </section>
  )
}

export default DeploymentWrapper

function isOverviewPage({ pathname }: Location): boolean {
  return Boolean(matchPath(pathname, { path: deploymentUrl(':deploymentId'), exact: true }))
}

function isHealthPage({ pathname }: Location): boolean {
  return Boolean(
    matchPath(pathname, { path: deploymentHealthStatusUrl(':deploymentId'), exact: true }),
  )
}

export function isActivityPage({ pathname }: Location): boolean {
  return Boolean(matchPath(pathname, { path: deploymentActivityUrl(), exact: true }))
}

function onGettingStartedPage({ location, id }: { location: Location; id: string }): boolean {
  const { pathname } = location
  return Boolean(matchPath(pathname, { path: deploymentGettingStartedUrl(id), exact: true }))
}
