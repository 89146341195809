/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { AnyProject } from '@modules/project-api/types'
import { getResetProjectCredentialsMutationHook } from '@modules/project-lib/hooks/resetCredentials'
import CopyButton from '@modules/cui/CopyButton'

import genericMessages from '../helpers/genericMessages'

export type Props = {
  project: AnyProject
}

const ResetCredentialsButton: React.FunctionComponent<Props> = ({ project }) => {
  const [isConfirming, setIsConfirming] = useState(false)

  const resetCredentialsMutation = getResetProjectCredentialsMutationHook(project.type)()

  return (
    <Fragment>
      {renderButton()}
      {renderNewCredentials()}
      {renderModal()}
    </Fragment>
  )

  function renderButton() {
    if (resetCredentialsMutation.isSuccess) {
      return
    }

    return (
      <EuiButton color='warning' fill={true} onClick={openModal}>
        <FormattedMessage
          id='project.security.resetCredentials.button'
          defaultMessage='Reset credentials'
        />
      </EuiButton>
    )
  }

  function renderNewCredentials() {
    if (!resetCredentialsMutation.isSuccess) {
      return
    }

    const title = (
      <FormattedMessage
        id='project.security.resetCredentials.success.title'
        defaultMessage='Your credentials have been reset.'
      />
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <EuiCallOut color='success' style={{ maxWidth: '38rem' }} title={title}>
          <EuiText>
            <p>
              <FormattedMessage
                id='project.security.resetCredentials.success.body'
                defaultMessage='These credentials provide access to Elasticsearch for this project. The password won’t be shown again.'
              />
            </p>
          </EuiText>

          <EuiSpacer size='s' />

          <EuiFlexGroup gutterSize='s' alignItems='center'>
            <EuiFlexItem grow={false} style={{ width: `8rem` }}>
              <EuiText>
                <strong>
                  <FormattedMessage
                    id='project.security.resetCredentials.success.username'
                    defaultMessage='Username'
                  />
                  :
                </strong>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>{resetCredentialsMutation.data.username}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup gutterSize='s' alignItems='center'>
            <EuiFlexItem grow={false} style={{ width: `8rem` }}>
              <EuiText>
                <strong>
                  <FormattedMessage
                    id='project.security.resetCredentials.success.password'
                    defaultMessage='Password'
                  />
                  :
                </strong>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>{resetCredentialsMutation.data.password} </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <CopyButton value={resetCredentialsMutation.data.password} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiCallOut>
      </Fragment>
    )
  }

  function renderModal() {
    if (!isConfirming) {
      return
    }

    return (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus='[name=confirmation-input]'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='project.security.resetCredentials.title'
                defaultMessage='Are you sure?'
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiText>
              <p>
                <FormattedMessage
                  id='project.security.resetCredentials.body'
                  defaultMessage='Your existing credentials will be invalidated.'
                />
              </p>
            </EuiText>
          </EuiModalBody>

          <EuiModalFooter>
            {!resetCredentialsMutation.isLoading && (
              <EuiButtonEmpty onClick={closeModal}>
                <FormattedMessage {...genericMessages.cancel} />
              </EuiButtonEmpty>
            )}

            <EuiButton
              onClick={performReset}
              fill={true}
              color='warning'
              isLoading={resetCredentialsMutation.isLoading}
            >
              {resetCredentialsMutation.isLoading ? (
                <FormattedMessage
                  id='project.security.resetCredentials.resetting'
                  defaultMessage='Resetting...'
                />
              ) : (
                <FormattedMessage
                  id='project.security.resetCredentials.reset'
                  defaultMessage='Reset'
                />
              )}
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  function openModal() {
    setIsConfirming(true)
  }

  function closeModal() {
    setIsConfirming(false)
  }

  function performReset() {
    resetCredentialsMutation.mutate({ id: project.id }, { onSuccess: closeModal })
  }
}

export default ResetCredentialsButton
