/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { AuthzResourceAction, AuthzRole, RoleAssignments } from '@modules/cloud-api/v1/types'

import { filterDeploymentRoleAssignmentByAll } from '@/components/Users/RoleAssignmentsPanel/lib'
import { getProfile } from '@/apps/userconsole/reducers'
import { getAuthzRoles } from '@/reducers'
import { getConfigForKey } from '@/selectors'

import type { ReduxState } from '@/types/redux'

/*
 * For now, the ResourceType and Action types need to be manually updated from the server, we haven't automated this yet.
 *
 * In order to update these values:
 *
 * 1. Sign-in to Userconsole as an Organization Owner.
 * 2. In the browser network tab, look for the /api/v1/authorization/roles response.
 * 3. In the browser console, copy and paste the response of the call above to a variable called roles.
 * 4. Run the commands bellow in the console:
 *
 * const resourceTypes = Array.from(new Set(roles.roles.flatMap((role) => role.resource_actions.map((ra) => ra.resource.name)))).concat('*').sort().map((name) => `| '${name}'`).join(' ')
 * const actions = Array.from(new Set(roles.roles.flatMap((role) => role.resource_actions.map((ra) => ra.action.name)))).concat('*').sort().map((name) => `| '${name}'`).join(' ')
 *
 * Copy the results above and paste to the types bellow:
 */
type ResourceType =
  | '*'
  | 'account-info'
  | 'api-key'
  | 'billing-costs'
  | 'billing-history'
  | 'billing-lineitems'
  | 'billing-organization'
  | 'billing-payment-method'
  | 'billing-prepaids'
  | 'deployment'
  | 'deployment-logs'
  | 'deployment-metrics'
  | 'deployment-template'
  | 'extension'
  | 'feature-usage'
  | 'instance-configuration'
  | 'marketplace-subscription'
  | 'organization'
  | 'organization-invitation'
  | 'organization-members'
  | 'organization-members-role-assignments'
  | 'project'
  | 'role-assignment'
  | 'sso'
  | 'stack-version'
  | 'traffic-filter'
  | 'trusted-environment'

type Action =
  | '*'
  | 'activate'
  | 'claim'
  | 'create'
  | 'deactivate'
  | 'delete'
  | 'delete-all'
  | 'disable'
  | 'editor'
  | 'enable-ccr'
  | 'enable-ilm'
  | 'enable-slm'
  | 'extend-trial'
  | 'get'
  | 'leave'
  | 'list'
  | 'maintenance'
  | 'migrate'
  | 'migrate-template'
  | 'request-sub-level-change'
  | 'reset-password'
  | 'reset-token'
  | 'restart'
  | 'restart-stateless'
  | 'restore'
  | 'search'
  | 'secrets'
  | 'shutdown'
  | 'start'
  | 'stop'
  | 'subscribe'
  | 'superuser'
  | 'unclaim'
  | 'update'
  | 'upgrade'
  | 'upload'
  | 'viewer'

interface ResourcePermission {
  organizationId: string
  type: ResourceType
  action: Action
  id: string
}

/**
 * These are `ResourceTypes` and `Actions` that are scoped to deployments.
 * For example, deployment admins/editors/viewers can be assigned to specific deployment IDs,
 * and the permission should be checked only for these deployment IDs, not for all.
 *
 * Add here any other `ResourceType` and `Action` that can be scoped by deployment ID.
 */
const deploymentScopedResourceActions: { types: Set<ResourceType>; actions: Set<Action> } = {
  types: new Set<ResourceType>(['deployment', 'deployment-logs', 'deployment-metrics']),
  actions: new Set<Action>(['get', 'update', 'delete', 'activate', 'deactivate', 'disable']),
}

export type NonEmptyPermissionsArray = [
  Partial<ResourcePermission>,
  ...Array<Partial<ResourcePermission>>,
]

export type PermissionsCheckResult = {
  hasPermissions: boolean
  isLoading: boolean
}

/**
 * Checks if current user has all `permissionsToCheck`.
 */
export const hasResourcePermissions = (
  state: ReduxState,
  permissionsToCheck: NonEmptyPermissionsArray,
): PermissionsCheckResult => {
  const isAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`

  if (isAdminconsole) {
    return {
      hasPermissions: true,
      isLoading: false,
    }
  }

  const profile = getProfile(state)
  const authzRoles = getAuthzRoles(state)

  if (profile === null || authzRoles === null) {
    return {
      hasPermissions: false,
      isLoading: true,
    }
  }

  const { role_assignments: roleAssignments, organization_id: organizationId } = profile

  if (roleAssignments === undefined) {
    return {
      hasPermissions: false,
      isLoading: false,
    }
  }

  const resourcePermissionsToCheck: ResourcePermission[] = permissionsToCheck.map((permission) => ({
    organizationId: permission.organizationId || organizationId || '*',
    type: permission.type || '*',
    action: permission.action || '*',
    id: permission.id || '*',
  }))

  const assignedPermissions = getResourcePermissions(authzRoles, roleAssignments)

  const hasPermissions = resourcePermissionsToCheck.every((resourcePermissionToCheck) =>
    hasResourcePermission(assignedPermissions, resourcePermissionToCheck),
  )

  return {
    hasPermissions,
    isLoading: false,
  }
}

/**
 * Checks if `assignedPermissions` contains a given `permissionToCheck`.
 *
 * A `ResourcePermission` instance could be represented as:
 * ${organizationId}:${resourceType}:${resourceId}:${action}
 *
 * When an `assignedPermission` contains a '*' in any of the attributes above,
 * it means the user is authorized for all instances of that attribute.
 *
 * For example, if `organizationId` is '*', the user has permission over all
 * organizations.
 *
 * @param {ResourcePermission[]} assignedPermissions All permissions a user has.
 * @param {ResourcePermission} permissionToCheck The permissions that needs to be checked.
 * @returns {boolean} Returns `true` if the user has authorization for `permissionToCheck`.
 */
const hasResourcePermission = (
  assignedPermissions: ResourcePermission[],
  permissionToCheck: ResourcePermission,
): boolean =>
  assignedPermissions.some(
    (assignedPermission) =>
      (assignedPermission.organizationId === '*' ||
        assignedPermission.organizationId === permissionToCheck.organizationId) &&
      (assignedPermission.type === '*' || assignedPermission.type === permissionToCheck.type) &&
      (assignedPermission.id === '*' || assignedPermission.id === permissionToCheck.id) &&
      (assignedPermission.action === '*' || assignedPermission.action === permissionToCheck.action),
  )

/**
 * Generates an array of `ResourcePermission` based on which roles are assigned
 * to a user.
 *
 * @param {AuthzRole[]} roles All existing roles a user can see.
 * @param {RoleAssignments} roleAssignments Roles that are assigned to the user.
 * @returns {ResourcePermission[]} Returns an array of `ResourcePermission` that are assigned for a user.
 */
const getResourcePermissions = (
  roles: AuthzRole[],
  roleAssignments: RoleAssignments,
): ResourcePermission[] => {
  const resourceActionsByRoleId: { [roleId: string]: AuthzResourceAction[] } = roles.reduce(
    (prev, cur) => ({
      ...prev,
      [cur.id]: cur.resource_actions,
    }),
    {},
  )

  const { platform = [], organization = [], deployment = [] } = roleAssignments

  const platformResourcePermissions: ResourcePermission[] = platform.flatMap(
    ({ role_id: roleId }) => {
      const resourceActions = getResourceActionsForRole(roleId)

      return resourceActions.map(
        ({ resource: { name: resourceName = '*' }, action: { name: actionName = '*' } }) => ({
          organizationId: '*',
          type: resourceName as ResourceType,
          id: '*',
          action: actionName as Action,
        }),
      )
    },
  )

  const organizationResourcePermissions: ResourcePermission[] = organization.flatMap(
    ({ role_id: roleId, organization_id: organizationId }) => {
      const resourceActions = getResourceActionsForRole(roleId)

      return resourceActions.map(
        ({ resource: { name: resourceName = '*' }, action: { name: actionName = '*' } }) => ({
          organizationId,
          type: resourceName as ResourceType,
          id: '*',
          action: actionName as Action,
        }),
      )
    },
  )

  const allDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(deployment, true)

  const allDeploymentsResourcePermissions: ResourcePermission[] =
    allDeploymentsRoleAssignments.flatMap(
      ({ role_id: roleId, organization_id: organizationId }) => {
        const resourceActions = getResourceActionsForRole(roleId)

        return resourceActions.map(
          ({ resource: { name: resourceName = '*' }, action: { name: actionName = '*' } }) => ({
            organizationId,
            type: resourceName as ResourceType,
            id: '*',
            action: actionName as Action,
          }),
        )
      },
    )

  const specificDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(deployment, false)

  const specificDeploymentsResourcePermissions: ResourcePermission[] =
    specificDeploymentsRoleAssignments.flatMap(
      ({
        role_id: roleId,
        organization_id: organizationId,
        deployment_ids: deploymentIds = [],
      }) => {
        const resourceActions = getResourceActionsForRole(roleId)

        return resourceActions.flatMap(
          ({ resource: { name: resourceName = '*' }, action: { name: actionName = '*' } }) => {
            const type = resourceName as ResourceType
            const action = actionName as Action

            const { types: deploymentScopedTypes, actions: deploymentScopedActions } =
              deploymentScopedResourceActions

            if (deploymentScopedTypes.has(type) && deploymentScopedActions.has(action)) {
              return deploymentIds.map((deploymentId) => ({
                organizationId,
                type,
                id: deploymentId,
                action,
              }))
            }

            return {
              organizationId,
              type,
              id: '*',
              action,
            }
          },
        )
      },
    )

  return platformResourcePermissions.concat(
    organizationResourcePermissions,
    allDeploymentsResourcePermissions,
    specificDeploymentsResourcePermissions,
  )

  function getResourceActionsForRole(roleId: string): AuthzResourceAction[] {
    return resourceActionsByRoleId[roleId] ?? []
  }
}

export default hasResourcePermissions

export { getResourcePermissions, hasResourcePermission, Action, ResourcePermission, ResourceType }
