/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiToolTip,
  EuiEmptyPrompt,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { AsyncRequestState } from '@modules/ui-types'
import {
  addToast,
  CuiAlert,
  CuiPermissibleControl,
  CuiTable,
  CuiTimeAgo,
  getFilterQueryString,
  setFilterQueryString,
} from '@modules/cui'
import type { ControlledFilterQuery, OnFilterChangeParams } from '@modules/cui'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import Header from '@modules/cui/Header'

import RevokeKeyModal from '../../../../../components/ApiKeys/RevokeKeyModal'
import { hasPermission } from '../../../../../lib/requiresPermission'
import { accountApiKeysManagementCrumbs } from '../../../../../lib/crumbBuilder'

import { messages } from './messages'
import ManageApiKeysFilterContext from './ManageApiKeysFilterContext'

import type { ApiKey } from './types'
import type { IntlShape } from 'react-intl'

import './manageApiKeys.scss'

export type Props = {
  intl: IntlShape
  managedApiKeys?: any[]
  fetchManagedApiKeys: () => void
  revokeApiKeys: (keys: ApiKey[]) => Promise<any>
  fetchManagedKeysRequest: AsyncRequestState
  revokeApiKeysRequest: AsyncRequestState
  resetRevokeKeysRequest: () => void
  showApiKeys: boolean
}

type State = {
  selectedItems: ApiKey[]
  query: ControlledFilterQuery
  queryResults: ApiKey[]
  error: any
  showRevokeKeysModal: boolean
  selectedKey: ApiKey | null
}

class ManageApiKeys extends Component<Props, State> {
  state: State = {
    selectedItems: [],
    query: getFilterQueryString({ storageKey: `ManageApiKeys` }),
    queryResults: [],
    error: null,
    showRevokeKeysModal: false,
    selectedKey: null,
  }

  componentDidMount() {
    const { fetchManagedApiKeys } = this.props

    if (!hasPermission(Permission.getUserApiKeys)) {
      return
    }

    fetchManagedApiKeys()
  }

  componentWillUnmount() {
    const { resetRevokeKeysRequest } = this.props
    resetRevokeKeysRequest()
  }

  render() {
    const { fetchManagedKeysRequest, revokeApiKeysRequest, showApiKeys } = this.props

    const { showRevokeKeysModal, selectedItems, selectedKey } = this.state

    const keysToRevoke = selectedKey ? [selectedKey] : selectedItems

    if (!hasPermission(Permission.getUserApiKeys)) {
      return null
    }

    if (!showApiKeys) {
      return null
    }

    return (
      <Fragment>
        <Header
          name={<FormattedMessage id='keysManagement.title' defaultMessage='API key management' />}
          breadcrumbs={accountApiKeysManagementCrumbs()}
        />

        {fetchManagedKeysRequest.error ? (
          <CuiAlert type='danger'>{fetchManagedKeysRequest.error}</CuiAlert>
        ) : (
          <PrivacySensitiveContainer id='manage-api-keys'>
            <EuiText>
              <p>
                <FormattedMessage
                  id='keysManagement.description'
                  defaultMessage='View API keys for all users of your organization and revoke those that should no longer be used.'
                />
              </p>
            </EuiText>

            <EuiSpacer />

            {this.renderTable()}

            {showRevokeKeysModal && (
              <RevokeKeyModal
                revokeApiKeysRequest={revokeApiKeysRequest}
                keys={keysToRevoke}
                onCancel={this.closeRevokeKeysModal}
                onConfirm={this.confirmRevoke}
              />
            )}

            {revokeApiKeysRequest.error && (
              <Fragment>
                <EuiSpacer />
                <CuiAlert type='danger'>{revokeApiKeysRequest.error}</CuiAlert>
              </Fragment>
            )}
          </PrivacySensitiveContainer>
        )}
      </Fragment>
    )
  }

  renderFilterContext = () => {
    const { managedApiKeys } = this.props
    const { query } = this.state

    return (
      <ManageApiKeysFilterContext
        query={query}
        onChange={this.onChange}
        managedApiKeys={managedApiKeys}
      />
    )
  }

  renderTable = () => {
    const {
      intl: { formatMessage },
      fetchManagedKeysRequest,
    } = this.props

    const revokeEnabled = hasPermission(Permission.deleteUserApiKey)

    const columns = [
      {
        render: ({ description }) => description,
        label: formatMessage(messages.apiKeyName),
      },
      {
        label: formatMessage(messages.creationDate),
        render: ({ creation_date }) => <CuiTimeAgo longTime={true} date={creation_date} />,
      },
      {
        label: formatMessage(messages.ownedBy),
        render: ({ user_id }) => user_id,
      },
      {
        mobile: {
          label: formatMessage(messages.revoke),
        },
        actions: true,
        render: (key) => {
          const button = (
            <EuiButtonIcon
              color='danger'
              iconType='trash'
              aria-label={`${formatMessage(messages.revokeButton)}-${key.name}`}
              disabled={!revokeEnabled}
              onClick={() =>
                this.setState({
                  showRevokeKeysModal: true,
                  selectedItems: [key],
                })
              }
            />
          )
          return revokeEnabled && key ? (
            button
          ) : (
            <EuiToolTip content={formatMessage(messages.revokeButton)}>{button}</EuiToolTip>
          )
        },
      },
    ]

    const { selectedItems, queryResults } = this.state

    return (
      <Fragment>
        <EuiFlexGroup>
          {selectedItems.length > 0 && (
            <EuiFlexItem className='manageKeys-revoke'>
              <CuiPermissibleControl permissions={Permission.deleteUserApiKeys}>
                <EuiButton
                  data-test-id='manageKeys-revokeButton'
                  color='danger'
                  onClick={() => this.showRevokeKeysModal()}
                >
                  <FormattedMessage {...messages.revokeSelected} />
                </EuiButton>
              </CuiPermissibleControl>
            </EuiFlexItem>
          )}
          <EuiFlexItem className='manageKeys-search'>{this.renderFilterContext()}</EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <CuiTable
          rows={queryResults}
          getRowId={(apiKey) => apiKey.id}
          isSelectableRow={(_) => revokeEnabled}
          data-test-id='manageApiKeys-table'
          className='manageApiKeys-table'
          columns={columns}
          selectedRows={selectedItems}
          initialLoading={fetchManagedKeysRequest.inProgress}
          emptyMessage={
            <EuiEmptyPrompt
              title={
                <h3>
                  <FormattedMessage
                    id='cui-table-manage.no-items-match'
                    defaultMessage='No API keys'
                  />
                </h3>
              }
              titleSize='xs'
              body={
                <EuiText>
                  <p>
                    <FormattedMessage
                      id='cui-table-manage.no-items-in-use-or-match'
                      defaultMessage="Your organization doesn't have any API keys in use or that match your search."
                    />
                  </p>
                </EuiText>
              }
            />
          }
          onSelectionChange={(selectedItems) => this.setState({ selectedItems })}
        />
      </Fragment>
    )
  }

  showRevokeKeysModal = () => {
    this.setState({ showRevokeKeysModal: true })
  }

  closeRevokeKeysModal = () => {
    this.setState({ showRevokeKeysModal: false, selectedItems: [] })
  }

  confirmRevoke = () => {
    const {
      intl: { formatMessage },
      revokeApiKeys,
    } = this.props
    const { selectedItems } = this.state

    return revokeApiKeys(selectedItems)
      .then(() => {
        this.setState({ showRevokeKeysModal: false, selectedItems: [] })
        addToast({
          id: 'revokeKeysSuccess',
          family: 'api-keys.revoke-key-success',
          title: formatMessage(messages.revokeSuccess, {
            amount: selectedItems.length,
          }),
          color: 'success',
        })
        return
      })
      .catch(() => this.setState({ showRevokeKeysModal: false, selectedItems: [] }))
  }

  onChange = ({ queryText, queryResults }: OnFilterChangeParams<ApiKey>) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `ManageApiKeys`, queryText })
  }

  onSelectionChange = (selectedItems) => {
    this.setState({ selectedItems })
  }
}

export default injectIntl(ManageApiKeys)
