/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
// eslint-disable-next-line no-restricted-imports
import { get, isEqual } from 'lodash'

import type { DifferenceArgs, DifferencePredicate, FieldPathMapping } from './types'

export const hasCurrent: DifferencePredicate = ({ current }) => !!current
export const hasNext: DifferencePredicate = ({ next }) => !!next
export const isElasticsearch: DifferencePredicate = ({ sliderInstanceType }) =>
  sliderInstanceType === `elasticsearch`

type HasChangedPathBuilder = (args: {
  pathBuilder: (args: DifferenceArgs) => FieldPathMapping[]
  defaultValue?: any
}) => DifferencePredicate

export const hasChangedPath: HasChangedPathBuilder =
  ({ pathBuilder, defaultValue }) =>
  (args) => {
    const paths = pathBuilder(args)
    const { current, next } = args

    return paths.some(({ possiblePaths }) => {
      const foundCurrentValuePath = possiblePaths.find((possiblePath) => get(current, possiblePath))
      const currentValue = foundCurrentValuePath
        ? get(current, foundCurrentValuePath)
        : defaultValue

      const foundNextValuePath = possiblePaths.find((possiblePath) => get(next, possiblePath))
      const nextValue = foundNextValuePath ? get(next, foundNextValuePath) : defaultValue

      return !isEqual(currentValue, nextValue)
    })
  }
