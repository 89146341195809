/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedDate, FormattedMessage, injectIntl } from 'react-intl'
import { flatten } from 'lodash'

import {
  EuiBadge,
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiLink,
  EuiSearchBar,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiToolTip,
} from '@elastic/eui'

import Permission from '@modules/cloud-api/v1/permissions'
import type { User } from '@modules/cloud-api/v1/types'
import type { RegionId } from '@modules/ui-types'
import { CuiTable, CuiPermissibleControl } from '@modules/cui'

import { shortDate } from '../../../../../../config/dates'
import AddUserFlyout from '../AddUserFlyout'
import UpdateUserFlyout from '../UpdateUserFlyout'
import DeleteUserButton from '../DeleteUserButton'
import { translateAndSortRoles } from '../../../../../../lib/roles'

import type { WrappedComponentProps } from 'react-intl'

import './UsersTable.scss'

interface Props extends WrappedComponentProps {
  regionId: RegionId
  users: User[] | null
  currentUser: User | null
  disabled: boolean
}

interface State {
  queryText: string
  userToEdit: User | null
  addUser: boolean
}

const messages = defineMessages({
  placeholder: {
    id: 'manageUsersTable.filterPlaceholder',
    defaultMessage: 'Filter users',
  },
  reservedUser: {
    id: 'manageUsersTable.reservedUser',
    defaultMessage: 'This is a built-in user that you cannot edit or delete.',
  },
  currentUser: {
    id: 'manageUsersTable.currentUser',
    defaultMessage: 'you',
  },
  editUser: {
    id: 'manageUsersTable.editUser',
    defaultMessage: 'Edit user',
  },
  columnNameUsername: {
    id: 'manageUsersTable.username',
    defaultMessage: 'Username',
  },
  columnNameFullName: {
    id: 'manageUsersTable.fullname',
    defaultMessage: 'Full name',
  },
  columnNameRoles: {
    id: 'manageUsersTable.roles',
    defaultMessage: 'Roles',
  },
  columnNameStatus: {
    id: 'manageUsersTable.status',
    defaultMessage: 'Status',
  },
  columnNameLastSeen: {
    id: 'manageUsersTable.lastSeen',
    defaultMessage: 'Last seen',
  },
  columnNameUserSince: {
    id: 'manageUsersTable.userSince',
    defaultMessage: 'User since',
  },
  columnNameActions: {
    id: 'manageUsersTable.actions',
    defaultMessage: 'Actions',
  },
})

const userNotEditable = (user: User): boolean => !!user.builtin

class UsersTable extends Component<Props, State> {
  state: State = {
    queryText: '',
    addUser: false,
    userToEdit: null,
  }

  render() {
    const {
      users,
      intl: { formatMessage },
    } = this.props

    return (
      <div className='manageUsersTable'>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={3}>
            <EuiSearchBar
              box={{
                incremental: true,
                placeholder: formatMessage(messages.placeholder),
                'data-test-subj': `userFilterContext`,
              }}
              onChange={this.onFilterChange}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>{this.renderCreateUserButton()}</EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <CuiTable
          data-test-id='manageUsersTable'
          rows={users ? this.getFilteredUsers() : undefined}
          columns={this.getColumns()}
          initialLoading={users === null}
          emptyMessage={this.renderNoUsers()}
        />

        {this.renderFlyouts()}
      </div>
    )
  }

  renderCurrentUserNote = ({ user_name }) => {
    const {
      currentUser,
      intl: { formatMessage },
    } = this.props

    return (
      currentUser?.user_name === user_name && (
        <span className='manageUsersTable-currentUser'>
          ({formatMessage(messages.currentUser)})
        </span>
      )
    )
  }

  renderFlyouts = () => {
    const { regionId } = this.props
    const { addUser, userToEdit } = this.state

    return (
      <Fragment>
        {addUser && (
          <AddUserFlyout open={addUser} onClose={this.hideAddUserFlyout} regionId={regionId} />
        )}

        {userToEdit && (
          <UpdateUserFlyout
            open={!!userToEdit}
            onClose={this.hideUpdateUserFlyout}
            regionId={regionId}
            user={userToEdit}
          />
        )}
      </Fragment>
    )
  }

  renderNoUsers = () => {
    const { users } = this.props

    if (!users) {
      return null // sanity
    }

    if (users.length > 0) {
      return (
        <div data-test-id='no-native-users-match'>
          <EuiText size='s' color='subdued'>
            <FormattedMessage
              id='manageUsersTable.noUsersMatch'
              defaultMessage='No users match your filters.'
            />
          </EuiText>
        </div>
      )
    }

    return (
      <div data-test-id='no-native-users-found'>
        <EuiText size='s' color='subdued'>
          <FormattedMessage
            id='manageUsersTable.noUsersDescription'
            defaultMessage="It looks like you don't have any native users. Let's create some!"
          />
        </EuiText>
      </div>
    )
  }

  renderCreateUserButton = () => {
    const { disabled } = this.props

    return (
      <CuiPermissibleControl permissions={Permission.createUser}>
        <EuiButton
          disabled={disabled}
          fill={true}
          onClick={this.showAddUserFlyout}
          data-test-id='createUser-btn'
        >
          <FormattedMessage id='manageUsers.newUserButton' defaultMessage='Create user' />
        </EuiButton>
      </CuiPermissibleControl>
    )
  }

  getColumns = () => {
    const {
      regionId,
      intl: { formatMessage },
    } = this.props

    return [
      {
        label: formatMessage(messages.columnNameUsername),
        render: (user: User) => {
          if (userNotEditable(user)) {
            return (
              <EuiToolTip content={formatMessage(messages.reservedUser)}>
                <EuiTextColor color='subdued'>
                  {user.user_name}
                  {this.renderCurrentUserNote(user)}
                </EuiTextColor>
              </EuiToolTip>
            )
          }

          return (
            <Fragment>
              <CuiPermissibleControl permissions={Permission.updateUser}>
                <EuiLink
                  onClick={() => {
                    this.showUpdateUserFlyout(user)
                  }}
                  data-test-id='editUserLink'
                >
                  {user.user_name}
                </EuiLink>
              </CuiPermissibleControl>
              {this.renderCurrentUserNote(user)}
            </Fragment>
          )
        },
        sortKey: `user_name`,
      },
      {
        label: formatMessage(messages.columnNameFullName),
        render: (user: User) => user.full_name,
      },
      {
        label: formatMessage(messages.columnNameRoles),
        render: (user: User) => (
          <ul className='u-noPadding'>
            {translateAndSortRoles(formatMessage, user.security.roles || []).map((role, index) => (
              <li key={index}>
                <EuiBadge key={role} color='hollow'>
                  {role}
                </EuiBadge>
              </li>
            ))}
          </ul>
        ),
        sortKey: (user: User) =>
          translateAndSortRoles(formatMessage, user.security.roles || []).join(`, `),
        width: '200px',
      },
      {
        label: formatMessage(messages.columnNameStatus),
        render: (user: User) => (
          <EuiHealth color={user.security.enabled ? 'success' : 'subdued'}>
            {user.security.enabled ? (
              <FormattedMessage id='manageUsersTable.statusEnabled' defaultMessage='Enabled' />
            ) : (
              <FormattedMessage id='manageUsersTable.statusDisabled' defaultMessage='Disabled' />
            )}
          </EuiHealth>
        ),
        sortKey: `security.enabled`,
        width: '110px',
      },
      {
        label: formatMessage(messages.columnNameLastSeen),
        align: 'center' as const,
        width: '120px',
        render: (user: User) =>
          user.metadata && user.metadata.last_login_at ? (
            <FormattedDate value={user.metadata.last_login_at} {...shortDate} />
          ) : (
            <EuiTextColor color='subdued'>—</EuiTextColor>
          ),
        sortKey: `metadata.last_login_at`,
      },
      {
        label: formatMessage(messages.columnNameUserSince),
        align: 'center' as const,
        width: '120px',
        render: (user: User) =>
          user.metadata && user.metadata.created_at ? (
            <FormattedDate value={user.metadata.created_at} {...shortDate} />
          ) : (
            <EuiTextColor color='subdued'>—</EuiTextColor>
          ),
        sortKey: `metadata.created_at`,
      },
      {
        mobile: (user) =>
          userNotEditable(user)
            ? null
            : {
                label: formatMessage(messages.columnNameActions),
              },
        actions: true,
        width: '70px',
        render: (user: User) => {
          if (userNotEditable(user)) {
            return null
          }

          const disabled = !!user.builtin

          return (
            <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>
                <CuiPermissibleControl permissions={Permission.updateUser}>
                  <EuiButtonIcon
                    key='edit'
                    disabled={disabled}
                    aria-label={formatMessage(messages.editUser)}
                    data-test-id='editUserLink'
                    iconType='pencil'
                    onClick={() => this.showUpdateUserFlyout(user)}
                  />
                </CuiPermissibleControl>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <DeleteUserButton enabled={!disabled} icon={true} user={user} regionId={regionId} />
              </EuiFlexItem>
            </EuiFlexGroup>
          )
        },
      },
    ]
  }

  getFilteredUsers = (): User[] => {
    const { users } = this.props
    const { queryText } = this.state

    if (users === null) {
      return []
    }

    const filterFields = ['email', 'full_name', 'security.roles', 'user_name']

    const filteredUsers = users.filter((user) => {
      const values = flatten(filterFields.map((field) => user[field]))
      return values.some((value) => String(value).toLowerCase().includes(queryText.toLowerCase()))
    })

    return filteredUsers
  }

  onFilterChange = ({ queryText }) => {
    this.setState({ queryText })
  }

  showAddUserFlyout = () => {
    this.setState({ addUser: true })
  }

  hideAddUserFlyout = () => {
    this.setState({ addUser: false })
  }

  showUpdateUserFlyout = (user: User) => {
    this.setState({ userToEdit: user })
  }

  hideUpdateUserFlyout = () => {
    this.setState({ userToEdit: null })
  }
}

export default injectIntl(UsersTable)
