/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages } from 'react-intl'

import { createItem } from '@modules/utils/navigation'
import { getRouteParams, matchRoute } from '@modules/utils/matchRoute'
import type { ProjectID } from '@modules/project-api/v1/types'
import type { ProjectType } from '@modules/ui-types/projects'

import { projectPerformanceUrl, projectSecurityUrl, projectUrl, projectsUrl } from './urls'
import { getDisplayProjectId } from './display'
import { getGetProjectQueryHook } from './hooks/get'

import type { WrappedComponentProps } from 'react-intl'
import type { RouteComponentProps } from 'react-router'
import type { RouteConfig } from 'react-router-config'

const messages = defineMessages({
  projects: {
    id: `navigation.projects`,
    defaultMessage: `Projects`,
  },
  performance: {
    id: `navigation.projects.performance`,
    defaultMessage: `Performance`,
  },
  security: {
    id: `navigation.projects.security`,
    defaultMessage: `Security`,
  },
})

type RoutingInfo = WrappedComponentProps & {
  routes: RouteConfig[]
  location: RouteComponentProps['location']
}

const ProjectName: React.FunctionComponent<{ projectType: ProjectType; projectId: ProjectID }> = ({
  projectType,
  projectId,
}) => {
  const { data } = getGetProjectQueryHook(projectType)(projectId)
  return <span>{data?.name || getDisplayProjectId(projectId)}</span>
}

export function buildProjectsMenu(routingInfo: RoutingInfo) {
  const {
    intl: { formatMessage },
    routes,
    location,
  } = routingInfo

  const { projectType, projectId } = getRouteParams<{
    projectType: ProjectType
    projectId: ProjectID
  }>(routes, location.pathname)

  const params = { projectType, projectId }

  const projectsItem = createItem({
    'data-test-id': `projects-link`,
    name: formatMessage(messages.projects),
    href: projectsUrl(),
    items: [],
  })

  if (isProjectActive(routingInfo, params)) {
    projectsItem.items = [
      createItem({
        'data-test-id': `project-link`,
        name: <ProjectName projectType={params.projectType} projectId={params.projectId} />,
        href: projectUrl(params.projectType, params.projectId),
        items: [
          createItem({
            name: formatMessage(messages.performance),
            href: projectPerformanceUrl(params.projectType, params.projectId),
            'data-test-id': `project-performance-link`,
          }),
          createItem({
            name: formatMessage(messages.security),
            href: projectSecurityUrl(params.projectType, params.projectId),
            'data-test-id': `project-security-link`,
          }),
        ],
      }),
    ]
  }

  return projectsItem
}

function isProjectActive(
  { routes, location }: RoutingInfo,
  _params: {
    projectType: ProjectType | undefined
    projectId: ProjectID | undefined
  },
): _params is { projectType: ProjectType; projectId: ProjectID } {
  return matchRoute(routes, location.pathname, projectUrl(`:projectType`, `:projectId`), {
    exact: false,
  })
}
