/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { useMutation } from 'react-query'

import {
  resetElasticsearchProjectCredentials,
  resetObservabilityProjectCredentials,
  resetSecurityProjectCredentials,
} from '@modules/project-api/v1/callers'
import type { ProjectID } from '@modules/project-api/v1/types'
import type { ProjectType } from '@modules/ui-types/projects'
import type { QueryFunctionReturnType } from '@modules/query/types'

/**
 * Returns the appropriate hook for the provided project type.
 */
export function getResetProjectCredentialsMutationHook(
  projectType: ProjectType,
):
  | typeof useResetElasticsearchProjectCredentialsMutation
  | typeof useResetObservabilityProjectCredentialsMutation
  | typeof useResetSecurityProjectCredentialsMutation {
  switch (projectType) {
    case 'observability':
      return useResetObservabilityProjectCredentialsMutation
    case 'security':
      return useResetSecurityProjectCredentialsMutation
    default:
      return useResetElasticsearchProjectCredentialsMutation
  }
}

const useResetElasticsearchProjectCredentialsMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof resetElasticsearchProjectCredentials>,
    unknown,
    { id: ProjectID }
  >({
    mutationFn: ({ id }) =>
      resetElasticsearchProjectCredentials({
        pathParameters: { id },
      }),
  })

const useResetObservabilityProjectCredentialsMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof resetObservabilityProjectCredentials>,
    unknown,
    { id: ProjectID }
  >({
    mutationFn: ({ id }) =>
      resetObservabilityProjectCredentials({
        pathParameters: { id },
      }),
  })

const useResetSecurityProjectCredentialsMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof resetSecurityProjectCredentials>,
    unknown,
    { id: ProjectID }
  >({
    mutationFn: ({ id }) =>
      resetSecurityProjectCredentials({
        pathParameters: { id },
      }),
  })
