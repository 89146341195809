/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiSpacer, EuiSuperSelect, EuiText } from '@elastic/eui'
import type { EuiSuperSelectOption } from '@elastic/eui'

import type { DeploymentRoleAssignment } from '@modules/cloud-api/v1/types'
import { CuiTable, CuiLink } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import { deploymentUrl } from '@/lib/urlBuilder'

import messages from '../messages'
import { DeploymentRole } from '../types'
import { changeDeploymentRowRole, convertDeploymentRowsToRoleAssignments } from '../lib'

import type { WrappedComponentProps } from 'react-intl'
import type { DeploymentRow } from './types'

export type Props = {
  organizationId: string
  isLoading: boolean
  organizationDeploymentRows: DeploymentRow[]
  deploymentRows: DeploymentRow[]
  onChangeSpecificDeploymentsRoleAssignments: (roleAssignments: DeploymentRoleAssignment[]) => void
} & WrappedComponentProps

class SpecificDeploymentsTable extends Component<Props> {
  render() {
    const { deploymentRows } = this.props

    return (
      <Fragment>
        <EuiSpacer size='m' />

        <CuiTable<DeploymentRow>
          initialLoading={this.props.isLoading}
          pageSize={7}
          rows={deploymentRows}
          getRowId={({ id }) => id}
          columns={this.getColumns()}
          totalCount={deploymentRows.length}
          css={css({
            '.euiTableCellContent__text': {
              flexBasis: '100%',
            },
          })}
        />
      </Fragment>
    )
  }

  getColumns(): Array<CuiTableColumn<DeploymentRow>> {
    const options = this.getOptions()

    return [
      {
        label: (
          <FormattedMessage
            id='roles-assignments.deployment-access.specific-deployment-name'
            defaultMessage='Deployment name'
          />
        ),
        align: 'left',
        render: ({ id, name }) => (
          <CuiLink
            to={deploymentUrl(id)}
            title={name}
            css={css({
              display: '-webkit-box', // needed in combination with lineClamp for multiline ellipsis
              textOverflow: 'ellipsis',
              lineHeight: '2rem',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            })}
          >
            {name}
          </CuiLink>
        ),
        truncateText: false,
        width: '150px',
      },
      {
        label: (
          <FormattedMessage
            id='roles-assignments.deployment-access.specific-deployment-role'
            defaultMessage='Deployment role'
          />
        ),
        render: ({ id, roleId }) => (
          <EuiSuperSelect
            options={options}
            valueOfSelected={roleId}
            itemLayoutAlign='top'
            fullWidth={true}
            onChange={(updatedRoleId) => this.onChangeRole(id, updatedRoleId)}
          />
        ),
        truncateText: false,
        width: '100%',
      },
    ]
  }

  getOptions(): Array<EuiSuperSelectOption<DeploymentRole>> {
    const {
      intl: { formatMessage },
    } = this.props

    return [
      {
        value: DeploymentRole.NO_ACCESS,
        inputDisplay: formatMessage(messages.noAccess),
        dropdownDisplay: (
          <Fragment>
            <strong>{formatMessage(messages.noAccess)}</strong>

            <EuiText size='s' color='subdued'>
              {formatMessage(messages.noAccessDescription)}
            </EuiText>
          </Fragment>
        ),
      },
      {
        value: DeploymentRole.DEPLOYMENT_ADMIN,
        inputDisplay: formatMessage(messages.admin),
        dropdownDisplay: (
          <Fragment>
            <strong>{formatMessage(messages.admin)}</strong>

            <EuiText size='s' color='subdued'>
              {formatMessage(messages.adminDescription)}
            </EuiText>
          </Fragment>
        ),
      },
      {
        value: DeploymentRole.DEPLOYMENT_EDITOR,
        inputDisplay: formatMessage(messages.editor),
        dropdownDisplay: (
          <Fragment>
            <strong>{formatMessage(messages.editor)}</strong>

            <EuiText size='s' color='subdued'>
              {formatMessage(messages.editorDescription)}
            </EuiText>
          </Fragment>
        ),
      },
      {
        value: DeploymentRole.DEPLOYMENT_VIEWER,
        inputDisplay: formatMessage(messages.viewer),
        dropdownDisplay: (
          <Fragment>
            <strong>{formatMessage(messages.viewer)}</strong>

            <EuiText size='s' color='subdued'>
              {formatMessage(messages.viewerDescription)}
            </EuiText>
          </Fragment>
        ),
      },
    ]
  }

  onChangeRole = (deploymentId: string, role: DeploymentRole): void => {
    const updatedDeploymentRows = changeDeploymentRowRole(
      this.props.organizationDeploymentRows,
      deploymentId,
      role,
    )

    const roleAssignments = convertDeploymentRowsToRoleAssignments(
      this.props.organizationId,
      updatedDeploymentRows,
    )

    this.props.onChangeSpecificDeploymentsRoleAssignments(roleAssignments)
  }
}

export default injectIntl(SpecificDeploymentsTable)
