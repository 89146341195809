/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormHelpText } from '@elastic/eui'

import type { AllocatorMoveRequest } from '@modules/cloud-api/v1/types'

import AllocatorLink, { AllocatorLinkList } from './AllocatorLink'

import type { ExplainedChange, ExplainedChangeType } from './types'

export default function explainVacateAllocatorChanges({
  regionId,
  type,
  moveAllocators,
}: {
  regionId: string
  type: ExplainedChangeType
  moveAllocators: AllocatorMoveRequest[]
}): ExplainedChange[] {
  return moveAllocators.map((move: AllocatorMoveRequest): ExplainedChange => {
    const source = <AllocatorLink allocatorId={move.from} regionId={regionId} />

    if (!Array.isArray(move.to) || isEmpty(move.to)) {
      return {
        id: `${type}-move-nodes-off-of-${move.from}`,
        type,
        message: (
          <Fragment>
            <FormattedMessage
              id='explain-changes.move-nodes-off-of-allocator'
              defaultMessage='Move nodes off of allocator {source} due to routine system maintenance'
              values={{ source }}
            />

            <EuiFormHelpText>
              <FormattedMessage
                id='explain-changes.move-nodes-off-of-allocator-help-text'
                defaultMessage='Having replicas and multiple availability zones helps minimize service interruption'
              />
            </EuiFormHelpText>
          </Fragment>
        ),
      }
    }

    return {
      id: `${type}-move-nodes-from-allocator-${move.from}-to-targets`,
      testParams: [...move.to],
      type,
      message: (
        <FormattedMessage
          id='explain-changes.move-nodes-from-allocator-to-targets'
          defaultMessage='Move nodes from allocator {source} to {targets}'
          values={{
            source,
            targets: <AllocatorLinkList allocatorIds={move.to} regionId={regionId} />,
          }}
        />
      ),
    }
  })
}
