/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCode, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CuiAlert } from '@modules/cui'
import { getStatus } from '@modules/utils/error'

import ClusterShardCount from './ClusterShardCount'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

const code = (content: JSX.Element[]) => <EuiCode>{content}</EuiCode>

const ClusterShards: FunctionComponent<Props> = ({
  clusterHealth,
  fetchClusterHealthRequest: { error },
}) => {
  // If user is not authorized to view the cluster health, just hide the health details entirely,
  // we can't check for hasResourcePermissions here because the deployment:health resource action
  // does not exist in the backend.
  if (error && getStatus(error) === 403) {
    return null
  }

  return (
    <Fragment>
      <EuiTitle size='s'>
        <h3>
          <FormattedMessage id='cluster-shards.title' defaultMessage='Shards' />
        </h3>
      </EuiTitle>

      <EuiSpacer size='m' />

      {error && (
        <Fragment>
          <CuiAlert type='error' data-test-id='request-error'>
            {error}
          </CuiAlert>

          {/* We still have data from the last successful request */}
          {clusterHealth && (
            <Fragment>
              <EuiSpacer size='s' />
              <EuiText size='s' color='subdued' data-test-id='old-data'>
                <FormattedMessage
                  id='cluster-shards.error-old-data'
                  defaultMessage='Displaying data from the last successful request to <code>_cluster/health</code>'
                  values={{ code }}
                />
              </EuiText>
            </Fragment>
          )}

          <EuiSpacer size='m' />
        </Fragment>
      )}

      {/* If there's an error, don't render unless we still have existing cluster health data */}
      {!(error && !clusterHealth) && <ClusterShardsDetails clusterHealth={clusterHealth} />}
    </Fragment>
  )
}

const ClusterShardsDetails: FunctionComponent<Pick<Props, 'clusterHealth'>> = ({
  clusterHealth,
}) => (
  <EuiFlexGroup alignItems='flexStart' gutterSize='xl'>
    <EuiFlexItem grow={false}>
      <ClusterShardCount
        tooltip={
          <FormattedMessage
            id='cluster-shards.active-tooltip'
            defaultMessage='The total number of active shards within your deployment.'
          />
        }
        title={clusterHealth?.shards.active || 0}
        titleColor='success'
        description={<FormattedMessage id='cluster-shards.active' defaultMessage='Active' />}
        isLoading={
          !clusterHealth ||
          (clusterHealth.status === 'green' &&
            Object.values(clusterHealth.shards).every((count) => count === 0))
        }
        data-test-id='active-shards'
      />
    </EuiFlexItem>

    {clusterHealth && clusterHealth.shards.initializing > 0 && (
      <EuiFlexItem grow={false}>
        <ClusterShardCount
          tooltip={
            <FormattedMessage
              id='cluster-shards.initializing-tooltip'
              defaultMessage="A shard is in an <code>INITIALIZING</code> state before it's available for use."
              values={{ code }}
            />
          }
          title={clusterHealth.shards.initializing}
          titleColor='default'
          description={
            <FormattedMessage id='cluster-shards.initializing' defaultMessage='Initializing' />
          }
          data-test-id='initializing-shards'
        />
      </EuiFlexItem>
    )}

    {clusterHealth && clusterHealth.shards.relocating > 0 && (
      <EuiFlexItem grow={false}>
        <ClusterShardCount
          tooltip={
            <FormattedMessage
              id='cluster-shards.relocating-tooltip'
              defaultMessage="A shard is in a <code>RELOCATING</code> state when it's moving to another node."
              values={{ code }}
            />
          }
          title={clusterHealth.shards.relocating}
          titleColor='primary'
          description={
            <FormattedMessage id='cluster-shards.relocating' defaultMessage='Relocating' />
          }
          data-test-id='relocating-shards'
        />
      </EuiFlexItem>
    )}

    {clusterHealth && clusterHealth.shards.unassigned > 0 && (
      <EuiFlexItem grow={false}>
        <ClusterShardCount
          tooltip={
            <Fragment>
              <FormattedMessage
                id='cluster-shards.unassigned-tooltip'
                defaultMessage="A shard is in an <code>UNASSIGNED</code> state when it isn't allocated to a node."
                values={{ code }}
              />

              {clusterHealth.status === 'yellow' && (
                <Fragment>
                  <EuiSpacer size='xs' />
                  <FormattedMessage
                    id='cluster-shards.unassigned-tooltip-status'
                    defaultMessage='The only unassigned shards are replicas, so there is no data loss.'
                    data-test-id='unassigned-replicas'
                  />
                </Fragment>
              )}
            </Fragment>
          }
          title={clusterHealth.shards.unassigned}
          titleColor='danger'
          description={
            <FormattedMessage id='cluster-shards.unassigned' defaultMessage='Unassigned' />
          }
          data-test-id='unassigned-shards'
        />
      </EuiFlexItem>
    )}
  </EuiFlexGroup>
)

export default ClusterShards
