/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { RegionInfo } from '@modules/cloud-api/v1/types'

import { ReduxState } from '@/types/redux'

import { getRegionInfo } from '../../reducers'

import Hosts from './Hosts'

import type { RouteComponentProps } from 'react-router'

type HostsType = 'allocators' | 'proxies' | 'control-planes'

type StateProps = {
  hostsType?: HostsType
  regionId: string
  regionInfo: RegionInfo | null
}

type DispatchProps = unknown

type QueryParams = {
  regionId: string
  hostsType?: HostsType
}

type ConsumerProps = RouteComponentProps<QueryParams>

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { regionId, hostsType },
    },
  }: ConsumerProps,
): StateProps => ({
  hostsType,
  regionId,
  regionInfo: getRegionInfo(state, regionId),
})

const mapDispatchToProps: DispatchProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Hosts)
