/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'
import { flatMap, uniqBy, sortBy, partition } from 'lodash'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { Allocator, AllocatorSearchResult, AllocatorVizInstance } from '@modules/ui-types'

import { getMemoryCapacity } from '../../lib/allocatorInstanceCapacitiesViz'
import {
  getSupportedSliderInstanceTypes,
  getSliderPrettyName,
  getSliderColor,
} from '../../lib/sliders'
import prettySize from '../../lib/prettySize'

import { getCapacityColor } from './capacityVizColors'

import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

import './allocatorsTable-capacityVizLegend.scss'

type AllocatorViz = Allocator | AllocatorSearchResult

type CapacityColor = {
  memoryCapacity: number
  color: string
  title: string
}

type Props = {
  allocators: AllocatorViz[]
  intl: IntlShape
}

const unknownColor = getCapacityColor()

const unknownCapacity: CapacityColor = {
  memoryCapacity: -Infinity,
  color: unknownColor,
  title: `Other`,
}

const InstanceCapacitiesVizLegend: FunctionComponent<Props> = ({
  allocators,
  intl: { formatMessage },
}) => {
  const instances = flatMap<AllocatorViz[], AllocatorVizInstance>(
    allocators,
    (allocator: AllocatorViz) => allocator.instances,
  )

  return (
    <EuiFlexGroup gutterSize='s' alignItems='center' justifyContent='flexEnd' wrap={true}>
      {getCapacityColors(instances).map(({ memoryCapacity, color, title }) => (
        <EuiFlexItem key={memoryCapacity} grow={false}>
          <span className='allocatorCapacityLegend-item'>
            <span className='allocatorCapacityLegend-color' style={{ backgroundColor: color }} />
            {` `}
            <span className={`allocatorCapacityLegend-title`}>{title}</span>
          </span>
        </EuiFlexItem>
      ))}

      {getInstanceKinds(instances).map(({ kind, title }) => (
        <EuiFlexItem key={kind} grow={false}>
          <span className='allocatorCapacityLegend-item'>
            <span
              className={`allocatorCapacityLegend-kind allocatorCapacityKind`}
              style={{ backgroundColor: getSliderColor(kind) }}
            />
            {` `}
            <span className='allocatorCapacityLegend-title'>{formatMessage(title)}</span>
          </span>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  )
}

export default injectIntl(InstanceCapacitiesVizLegend)

function getCapacityColors(instances: AllocatorVizInstance[]): CapacityColor[] {
  const capacityColors: CapacityColor[] = uniqBy(instances, getMemoryCapacity)
    .map(getMemoryCapacity)
    .map((memoryCapacity) => ({
      memoryCapacity,
      color: getCapacityColor(memoryCapacity),
      title: prettySize(memoryCapacity),
    }))

  const [knownCapacities, unknownCapacities] = partition(capacityColors, isKnownColor)

  const normalizedCapacities =
    unknownCapacities.length > 0 ? [...knownCapacities, unknownCapacity] : capacityColors

  return sortBy(normalizedCapacities, (item) => -item.memoryCapacity)
}

function getInstanceKinds(instances: AllocatorVizInstance[]) {
  const allKinds = getSupportedSliderInstanceTypes().map((sliderInstanceType) => ({
    kind: sliderInstanceType,
    title: getSliderPrettyName({ sliderInstanceType }),
  }))

  return allKinds.filter((item) => instances.some(matchesKind(item.kind)))
}

function matchesKind(kind: AllocatorVizInstance['kind']) {
  return (instance: AllocatorVizInstance): boolean => instance.kind === kind
}

function isKnownColor(key: CapacityColor): boolean {
  return key.color !== unknownColor
}
