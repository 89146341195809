/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, size, groupBy, values, merge } from 'lodash'

import { EuiSpacer, EuiText } from '@elastic/eui'

import type { RegionInfo, CoordinatorSummary } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, Runner } from '@modules/ui-types'
import { CuiAlert, getFilterQueryString, setFilterQueryString } from '@modules/cui'
import type { ControlledFilterQuery } from '@modules/cui'

import { hasControlPlaneRole } from '../../lib/hostRoles'

import ControlPlaneFilterContext from './ControlPlaneFilterContext'
import ControlPlanesTable from './ControlPlanesTable'

import type { Constructors, Constructor } from '@/types/redux'
import type { ControlPlane } from './types'

type Props = {
  constructors?: Constructors
  fetchPlatformRequest: AsyncRequestState
  regionId: string
  regionInfo: RegionInfo | null
  runnersSearchRequest: AsyncRequestState
  runnersSearchResults: Runner[] | null
}

type State = {
  query: ControlledFilterQuery
  queryResults: ControlPlane[]
}

class ControlPlanes extends Component<Props, State> {
  state: State = {
    query: getFilterQueryString({ storageKey: `ControlPlanes` }),
    queryResults: [],
  }

  render() {
    const { fetchPlatformRequest, runnersSearchRequest } = this.props
    const { query } = this.state
    const controlPlanes = this.getControlPlanes()

    return (
      <Fragment>
        <EuiText color='subdued'>
          <FormattedMessage
            id='control-planes.description'
            defaultMessage='Control planes are hosts that provide management services to support access to the Cloud UI and the Cloud REST API for your installation.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        <ControlPlaneFilterContext
          query={query}
          onChange={this.onChange}
          isLoading={fetchPlatformRequest.inProgress || runnersSearchRequest.inProgress}
          controlPlanes={controlPlanes}
        />

        {runnersSearchRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{runnersSearchRequest.error}</CuiAlert>
          </Fragment>
        )}

        {this.renderControlPlanes()}
      </Fragment>
    )
  }

  renderControlPlanes() {
    const { regionId } = this.props
    const { queryResults } = this.state
    const controlPlanes = this.getControlPlanes()

    const loadedButNoMatches = controlPlanes && isEmpty(queryResults)

    if (loadedButNoMatches) {
      return null
    }

    const totalCount = size(controlPlanes)

    return (
      <Fragment>
        <EuiSpacer size='l' />

        <ControlPlanesTable
          regionId={regionId}
          controlPlanes={queryResults}
          initialLoading={!controlPlanes}
          totalCount={totalCount}
        />
      </Fragment>
    )
  }

  onChange = ({ queryText, queryResults }) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `ControlPlanes`, queryText })
  }

  getControlPlanes(): ControlPlane[] | undefined {
    const { constructors, regionId, regionInfo, runnersSearchResults } = this.props

    const constructorList = constructors?.constructors
    const coordinatorList = regionInfo?.coordinators?.coordinators

    if (!regionInfo || !runnersSearchResults || !constructorList || !coordinatorList) {
      return
    }

    const runnerControlPlanes: ControlPlane[] = runnersSearchResults
      .filter((runner) => hasControlPlaneRole({ runner }))
      .map((runner) => getRunnerControlPlane(runner.runner_id, runner))

    const constructorControlPlanes: ControlPlane[] = constructorList
      ? constructorList.map(getConstructorControlPlane)
      : []

    const coordinatorControlPlanes: ControlPlane[] = coordinatorList
      ? coordinatorList.map(getCoordinatorControlPlane)
      : []

    const potentiallyDuplicatedControlPlanes: ControlPlane[] = [
      ...runnerControlPlanes,
      ...constructorControlPlanes,
      ...coordinatorControlPlanes,
    ]

    const controlPlaneGroups = groupBy(potentiallyDuplicatedControlPlanes, 'hostId')
    const controlPlaneGroupValues = values(controlPlaneGroups)
    const controlPlanes: ControlPlane[] = controlPlaneGroupValues.map(
      ([firstControlPlane, ...rest]) => merge(firstControlPlane, ...rest),
    )

    return controlPlanes

    function getRunnerControlPlaneById(hostId: string): ControlPlane {
      const runner = runnersSearchResults?.find((runner) => runner.runner_id === hostId)

      return getRunnerControlPlane(hostId, runner)
    }

    function getRunnerControlPlane(hostId: string, runner?: Runner): ControlPlane {
      return {
        regionId,
        hostId,
        runner,
      }
    }

    function getConstructorControlPlane(ctor: Constructor): ControlPlane {
      const hostId = ctor.id

      return {
        ...getRunnerControlPlaneById(hostId),
        ctor,
      }
    }

    function getCoordinatorControlPlane(coordinator: CoordinatorSummary): ControlPlane {
      const hostId = coordinator.name

      return {
        ...getRunnerControlPlaneById(hostId),
        coordinator,
      }
    }
  }
}

export default ControlPlanes
