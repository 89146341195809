/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { ProfileState } from '@modules/ui-types'

import { getCommonProfile } from '@/reducers/profile'
import { fetchAccountActivity } from '@/apps/userconsole/actions/account'
import { getAccountActivity } from '@/apps/userconsole/reducers'
import withBillingDetails from '@/lib/withBillingDetails'

import PrepaidCreditActivationModal from './PrepaidCreditActivationModal'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state): StateProps => ({
  accountActivity: getAccountActivity(state)!,
  profile: getCommonProfile(state) as NonNullable<ProfileState>,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAccountActivity: (organizationId) => dispatch(fetchAccountActivity(organizationId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withBillingDetails(PrepaidCreditActivationModal))
