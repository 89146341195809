/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import { EuiConfirmModal } from '@elastic/eui'

import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import messages, { formatMessageWithValues } from '../messages'
import { removeMember } from '../lib'

import type { FunctionComponent } from 'react'
import type { AllProps as Props } from './types'

const RemoveMemberModal: FunctionComponent<Props> = ({
  intl,
  organization,
  organizationMemberRow,
  deleteOrganizationMembership,
  deleteOrganizationMembershipRequest,
  isLastMember,
  onCloseModal,
}) => {
  const { email } = organizationMemberRow
  const {
    modal: {
      member: modalMessages,
      lastMember: { body: lastMemberBody },
    },
    toast: { member: toastMessages },
  } = messages

  const values = {
    organizationName: organization.name,
    email,
  }

  return (
    <EuiConfirmModal
      buttonColor='danger'
      defaultFocusedButton='confirm'
      title={
        <PrivacySensitiveContainer>
          {formatMessageWithValues({ intl, message: modalMessages.title, values })}
        </PrivacySensitiveContainer>
      }
      cancelButtonText={formatMessageWithValues({
        intl,
        message: modalMessages.cancel,
        values,
      })}
      confirmButtonText={formatMessageWithValues({
        intl,
        message: modalMessages.confirm,
        values,
      })}
      onCancel={onCloseModal}
      onConfirm={() =>
        removeMember({
          intl,
          deleteOrganizationMember: deleteOrganizationMembership(),
          successMessage: toastMessages.success,
          failureMessage: toastMessages.failure,
          values,
        })
      }
      isLoading={deleteOrganizationMembershipRequest().inProgress}
    >
      <PrivacySensitiveContainer>
        {formatMessageWithValues({
          intl,
          message: isLastMember ? lastMemberBody : modalMessages.body,
          values,
        })}
      </PrivacySensitiveContainer>
    </EuiConfirmModal>
  )
}

export default injectIntl(RemoveMemberModal)
