/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import { isEmpty, capitalize } from 'lodash'
import React from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiHealth } from '@elastic/eui'

import type { ContainerInstance } from '@modules/cloud-api/v1/types'
import { CuiTable, CuiLink, CuiTimeAgo, withErrorBoundary } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import { hostUrl } from '../../lib/urlBuilder'

import ContainerInstancesTableDetailRow from './ContainerInstancesTableDetailRow'

import type { FunctionComponent } from 'react'
import type { IntlShape } from 'react-intl'

type Props = {
  intl: IntlShape
  regionId: string
  containerInstances: ContainerInstance[]
  totalCount: number
  initialLoading: boolean
}

type ContainerInstanceInspect = {
  [key: string]: any
}

const messages = defineMessages({
  actions: {
    id: 'container-overview.actions',
    defaultMessage: `Actions`,
  },
})

const ContainerInstancesTable: FunctionComponent<Props> = ({
  intl,
  regionId,
  containerInstances,
  totalCount,
  initialLoading,
}) => {
  const { formatMessage } = intl

  const columns: Array<CuiTableColumn<ContainerInstance>> = [
    {
      label: <FormattedMessage id='container-overview.host' defaultMessage='Host' />,
      render: (instance) => (
        <CuiLink to={hostUrl(regionId, instance.runner_id)}>{instance.runner_id}</CuiLink>
      ),
      sortKey: `runner_id`,
    },

    {
      label: <FormattedMessage id='container-overview.status' defaultMessage='Status' />,
      render: withInspect((inspect: ContainerInstanceInspect) => (
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiHealth color={getInstanceHealthColor(inspect)}>
              {capitalize(inspect.State?.Status)}
            </EuiHealth>
          </EuiFlexItem>
        </EuiFlexGroup>
      )),
      sortKey: `inspect.State.Status`,
    },

    {
      label: <FormattedMessage id='container-overview.created' defaultMessage='Created' />,
      render: withInspect((inspect: ContainerInstanceInspect) => (
        <CuiTimeAgo date={moment(inspect.Created)} />
      )),
      sortKey: `inspect.Created`,
    },

    {
      label: <FormattedMessage id='container-overview.started' defaultMessage='Started' />,
      render: withInspect((inspect: ContainerInstanceInspect) => (
        <CuiTimeAgo date={moment(inspect.State?.StartedAt)} />
      )),
      sortKey: `inspect.State.StartedAt`,
    },

    {
      mobile: {
        label: formatMessage(messages.actions),
      },
      actions: true,
      width: `40px`,
    },
  ]

  return (
    <CuiTable<ContainerInstance>
      rows={containerInstances}
      columns={columns}
      getRowId={(instance) => instance.runner_id}
      hasDetailRow={(instance) => !isEmpty(instance.inspect)}
      renderDetailButton={true}
      renderDetailRow={(instance) => <ContainerInstancesTableDetailRow instance={instance} />}
      totalCount={totalCount}
      pageSize={50}
      initialLoading={initialLoading}
    />
  )
}

export default withErrorBoundary(injectIntl(ContainerInstancesTable))

function withInspect(getResult) {
  return (instance: ContainerInstance) => {
    if (isEmpty(instance.inspect)) {
      return null
    }

    return getResult(instance.inspect as ContainerInstanceInspect, instance)
  }
}

function getInstanceHealthColor(inspect: ContainerInstanceInspect) {
  if (inspect.State?.Running) {
    return `success`
  }

  if (inspect.State?.Paused || inspect.State?.Restarting) {
    return `warning`
  }

  if (inspect.State?.Dead || inspect.State?.OOMKilled) {
    return `danger`
  }

  return `subdued`
}
