/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { setSnapshotRepositoryUrl } from '@modules/cloud-api/v1/urls'
import type { RegionId, SnapshotRepositoryConfig } from '@modules/ui-types'
import history from '@modules/utils/history'

import asyncRequest from '../asyncRequests'
import { UPSERT_SNAPSHOT_REPOSITORY } from '../../constants/actions'
import { snapshotRepositoriesUrl } from '../../lib/urlBuilder'

export function upsertSnapshotRepository(
  regionId: RegionId,
  id: string,
  payload: SnapshotRepositoryConfig,
) {
  const url = setSnapshotRepositoryUrl({ regionId, repositoryName: id })

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: UPSERT_SNAPSHOT_REPOSITORY,
        method: `PUT`,
        url,
        payload,
        meta: { regionId, id },
      }),
    ).then(() => history.push(snapshotRepositoriesUrl(regionId)))
}
