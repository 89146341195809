/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sumBy } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import type { PlatformInfo, RegionInfo } from '@modules/cloud-api/v1/types'
import { CuiTable } from '@modules/cui'
import type { CuiTableColumn } from '@modules/cui'

import RegionOverallStatus from './RegionOverallStatus'
import RegionAllocatorsStatus from './RegionAllocatorsStatus'
import RegionProxiesStatus from './RegionProxiesStatus'
import RegionZooKeeperStatus from './RegionZooKeeperStatus'
import UnhealthyAllocatorsRow from './UnhealthyAllocatorsRow'
import UnreachableRegionsCallOut from './UnreachableRegionsCallOut'

import type { FunctionComponent } from 'react'

type Props = {
  platform?: PlatformInfo | null
}

const RegionsTable: FunctionComponent<Props> = ({ platform }) => {
  const idColumn: CuiTableColumn<RegionInfo> = {
    label: <FormattedMessage id='platform-regions-table.region-label' defaultMessage='Region' />,
    render: (region) => <RegionOverallStatus region={region} />,
    sortKey: `region_id`,
  }

  const columns: Array<CuiTableColumn<RegionInfo>> = [
    idColumn,
    {
      label: (
        <FormattedMessage
          id='platform-regions-table.allocators-label'
          defaultMessage='Allocators'
        />
      ),
      render: (region) => <RegionAllocatorsStatus region={region} />,
      sortKey: (region) => sumBy(region.allocators.zone_summaries, `total_allocators`),
    },
    {
      label: (
        <FormattedMessage id='platform-regions-table.proxies-label' defaultMessage='Proxies' />
      ),
      render: (region) => <RegionProxiesStatus region={region} />,
      sortKey: `proxies.proxies_count`,
    },
    {
      label: <FormattedMessage id='platform-regions-table.zk-label' defaultMessage='ZooKeeper' />,
      render: (region) => <RegionZooKeeperStatus region={region} />,
      sortKey: `zookeeper_states.states.length`,
    },
  ]

  return (
    <Fragment>
      <UnreachableRegionsCallOut platform={platform} />

      <CuiTable<RegionInfo>
        rows={platform && platform.regions}
        initialSort={idColumn}
        columns={columns}
        getRowId={(region) => region.region_id}
        hasExpandedDetailRow={(region) => !region.allocators.healthy}
        renderDetailButton={false}
        renderDetailRow={(region) => <UnhealthyAllocatorsRow region={region} />}
      />
    </Fragment>
  )
}

export default RegionsTable
